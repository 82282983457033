/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ModalBox, CloseModal, ButtonControl, ButtonModal } from './style';
import theme from '../../../../utils/theme';

const AlertContent = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Grid container flexDirection='column' alignItems='center' fullWidth sx={{ marginTop: '60px' }}>
          <Grid item sx={{ marginBottom: '37px' }}>
            <MailOutlineIcon sx={{ width: '100px', height: '62px', fill: theme.GUIAVET }} />
          </Grid>

          <Grid item sx={{ marginBottom: '33px' }}>
            <Typography variant='h5' sx={{ fontFamily: 'Inter', color: theme.MEDIUM_NAVY }}>
              Solitaçāo enviada
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: '55px', width: '305px' }}>
            <Typography variant='body1' sx={{ fontFamily: 'Inter', color: '#666666' }}>
              Caso o convite não seja aceito, ele será reenviado:
              24h depois, 48h depois e 7 dias depois
            </Typography>
          </Grid>
        </Grid>

        <ButtonControl sx={{ marginBottom: '100px' }}>
          <Grid container justifyContent='flex-end'>
            <ButtonModal style={{ color: theme.GUIAVET, fontWeight: 500, marginRight: '30px' }} type='submit' mainButton onClick={closeModal}>Entendi</ButtonModal>
          </Grid>
        </ButtonControl>
      </ModalBox>
    </>
  );
};

export default AlertContent;
