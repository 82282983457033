/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Card, Chip, Grid } from '@mui/material';
import moment from 'moment';
import { StyledCardContent, ServicePhotosGrid, AppointmentDate, Service, ServiceLong, PetAndShop } from './style';
import { getPetPlaceholder } from '../../../../services/api';

const ServiceCard = ({ serviceScheduling }) => {
  const dispatch = useDispatch();
  const placeholder = getPetPlaceholder(serviceScheduling.animal);

  const [profileImgSrc, setProfileImgSrc] = useState(null);
  const [animalImgSrc, setAnimalImgSrc] = useState(null);

  const fetchAnimalImage = async () => {
    const response = await dispatch.Photo.getPhoto(serviceScheduling.animal.avatarImage);
    setAnimalImgSrc(response);
  };

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(serviceScheduling.serviceType.service.user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!serviceScheduling.serviceType.service?.user?.profileImage && fetchProfileImage();
    !!serviceScheduling?.animal?.avatarImage && fetchAnimalImage();
  }, []);

  return (
    <>
      <Card
        elevation={0}
        sx={{ marginTop: '16px', display: 'flex', justifyContent: 'center', bgcolor: 'transparent' }}
      >
        <StyledCardContent>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <>
                {
                serviceScheduling.serviceType?.service?.category === 'Adestramento' ? (
                  <>
                    <ServiceLong>
                      Adestramento
                    </ServiceLong>
                    <ServiceLong>
                      Consultoria Comportamental
                    </ServiceLong>
                  </>
                ) : (
                  <Service>
                    {serviceScheduling.serviceType?.service?.category || ''}
                  </Service>
                )
                }
              </>
              <AppointmentDate>
                {`${moment(serviceScheduling.eventStart).utc().format('HH:mm')} -
                      ${moment(serviceScheduling.eventEnd).utc().format('HH:mm')}
                      (${moment.duration(moment(serviceScheduling.eventEnd).utc().diff(moment(serviceScheduling.eventStart).utc())).asMinutes()} min.)`}

              </AppointmentDate>
            </Grid>

            <Grid item>
              <Chip
                label={moment(serviceScheduling.eventStart).utc().format('DD/MM')}
                sx={{
                  backgroundColor: '#6B48FE',
                  color: 'white',
                  marginTop: serviceScheduling.serviceType?.service?.category === 'Adestramento' ? '-33px' : '0px',
                }}
                size='small'
              />
            </Grid>

          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            sx={{ margin: serviceScheduling.serviceType?.service?.category === 'Adestramento' ? ('0px 0px') : ('12px 0px') }}
          >
            <ServicePhotosGrid>
              <Avatar
                sx={{ width: '40px', height: '40px' }}
                src={serviceScheduling.serviceType?.service?.user?.profileImage && profileImgSrc}
                alt={serviceScheduling.serviceType?.service?.user?.firstName}
              />
              <Avatar
                sx={{ width: '40px', height: '40px', position: 'absolute', left: '30px', top: '0' }}
                src={serviceScheduling.animal ? (animalImgSrc || placeholder) : undefined}
                alt={serviceScheduling.animal?.name || ''}
              />
            </ServicePhotosGrid>

            <PetAndShop>{`${serviceScheduling.serviceType?.service?.user?.firstName} + ${serviceScheduling.animal?.name}`}</PetAndShop>
          </Grid>

        </StyledCardContent>
      </Card>
    </>
  );
};

export default ServiceCard;
