import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../utils/mobile';
// import { isNative } from '../../../utils/mobile';
import { IntercomCustom } from '../../../utils/intercom';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const TriageAlertDialog = ({ open, setModalOpen }) => {
  const history = useHistory();
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const handleChat = () => {
    setModalOpen(false);
    if (isDefaultPlan === false) {
      window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
      IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });
      Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
      // if (isNative) {
      //   return false;
      // }
      return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);
    }

    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
    return history.push('/pricing');
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        paddingBottom: '10px',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            Olá
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>Para agilizar seu atendimento faremos algumas perguntas para triagem do seu animal</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: '#6B48FF' }}
          onClick={handleChat}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TriageAlertDialog;
