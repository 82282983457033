import { Grid, Typography, CardContent } from '@mui/material';
import styled from 'styled-components';

export const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 322px;
    min-height: 110px;
    height: auto;
    flex-shrink: 2;
    background-color: white;
    border: 4px dashed rgba(107, 72, 255, 0.37);
    opacity: 1.0;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
`;

export const Service = styled(Typography)`
  &.MuiTypography-root {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0px;
    color: #6B48FF;
    opacity: 0.4;
  }
`;

export const ServiceLong = styled(Typography)`
  &.MuiTypography-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0px;
    color: #6B48FF;
    opacity: 0.4;
  }
`;

export const AppointmentDate = styled(Typography)`
  &.MuiTypography-root {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: #9E9E9E;
  }
`;

export const ServicePhotosGrid = styled(Grid)`
  position: relative;
  margin-right: 36px;

  img {
    width: 40px;
    height: 40px;
    border: 3px solid #FDF9F5;
    border-radius: 50%;


    &:nth-child(2) {
      position: absolute;
      left: 30px;
    }
  }
`;

export const PetAndShop = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #00000061;
  }
`;
