import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { TableContainer } from '@mui/material';
import { animations } from '../../../utils/theme';

export const StyledContainer = styled(Container)`
  margin-top: 10px;
  animation: ${animations.fadeIn} .2s ease-in;
`;

export const StyledTableContainer = styled(TableContainer)`
  animation: ${animations.fadeIn} .2s ease-in-out;
  margin-bottom: 60px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0 0 15px;
  margin-bottom: 5px;
`;
