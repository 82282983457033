/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const getHeaders = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const DiscountCoupon = {
  state: {
    balance: 0,
    coupons: [],
    loading: {},
  },
  reducers: {
    SET_LOADING: (state, payload) => ({
      ...state,
      loading: { ...state.loading, ...payload },
    }),
    SET_BALANCE: (state, payload) => ({
      ...state,
      balance: payload,
    }),
    SET_COUPONS: (state, payload) => ({
      ...state,
      coupons: payload,
    }),
  },
  effects: (dispatch) => ({
    async discountCouponList({ pageNumber, pageLength }) {
      dispatch.DiscountCoupon.SET_LOADING({ discountCouponList: 'loading' });

      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : '';
      const pagelength = pageLength ? `pagelength=${pageLength}` : '';
      const query = [pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseURL}/discount-coupon/list${querystring}`, headers);

        if (response && response.data) {
          dispatch.DiscountCoupon.SET_COUPONS(response.data);
          dispatch.DiscountCoupon.SET_LOADING({
            discountCouponList: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.DiscountCoupon.SET_LOADING({
          discountCouponList: 'rejected',
        });
        return err;
      }
    },
    async discountBalance() {
      dispatch.DiscountCoupon.SET_LOADING({ discountBalance: 'loading' });

      try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseURL}/discount-coupon/balance`, headers);

        if (response && response.data) {
          dispatch.DiscountCoupon.SET_BALANCE(response.data.balance);
          dispatch.DiscountCoupon.SET_LOADING({
            discountBalance: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.DiscountCoupon.SET_LOADING({
          discountBalance: 'rejected',
        });
        return err;
      }
    },
    async discountCouponCount() {
      dispatch.DiscountCoupon.SET_LOADING({ discountCouponCount: 'loading' });

      try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseURL}/discount-coupon/count`, headers);

        if (response && response.data) {
          dispatch.DiscountCoupon.SET_LOADING({
            discountCouponCount: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.DiscountCoupon.SET_LOADING({
          discountCouponCount: 'rejected',
        });
        return err;
      }
    },
  }),
};
