import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CreatePetContent from './CreatePetContent';
import CreateProtocolContent from './CreateProtocolContent';

const PetAndProtocolModal = () => {
  const dispatch = useDispatch();

  const { animalsList } = useSelector((state) => state.Animals);
  const { petAndProtocolApply } = useSelector((state) => state.Animals.modal);
  const [modalStep, setModalStep] = useState('select-action');
  const userHasAnimal = animalsList.length;

  const closeModal = () => {
    setModalStep('select-action');
    dispatch.Animals.setModal({ petAndProtocolApply: false });
  };

  const renderModalContent = () => {
    if (!userHasAnimal || modalStep === 'create-pet') {
      return <CreatePetContent setModalStep={setModalStep} />;
    } if (modalStep === 'select-action' || modalStep === 'create-protocol') {
      return <CreateProtocolContent modalStep={modalStep} setModalStep={setModalStep} />;
    }
    return <CreateProtocolContent modalStep={modalStep} setModalStep={setModalStep} />;
  };

  return (
    <Modal
      open={petAndProtocolApply}
      onClose={closeModal}
      onBackdropClick='false'
      aria-labelledby='Modal de pet e protocolo'
      aria-describedby='Modal usado para adicionar, editar ou renovar um pet e seu protocolo'
    >
      <>
        {renderModalContent()}
      </>
    </Modal>
  );
};

export default PetAndProtocolModal;
