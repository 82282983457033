import React from 'react';

import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';

import SigningTimeCard from './SigningTimeCard';
import { isMobile } from '../../../../utils/mobile';

const PricingSigningTimeDialog = () => {
  const dispatch = useDispatch();
  const signingTimeModalOpen = useSelector((state) => state.SigningPlans.modal.signingTime.open);
  const prices = useSelector((state) => state.SigningPlans.modal.signingTime.data);
  const promotionTerms = useSelector((state) => state.SigningPlans.modal.signingTime.promotionTerms);
  const promotionMonthlyPrice = useSelector((state) => state.SigningPlans.modal.signingTime.promotionMonthlyPrice);
  const isPromotion = useSelector((state) => state.SigningPlans.modal.signingTime.isPromotion);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
        height: screen.height < 600 ? '300px' : '540px',
      },
      '& .MuiDialog-container': {
        marginTop: isMobile ? '55px' : '0px',
        height: isMobile ? '80vh' : '100vh',
        overflow: 'auto',
      } }}
      fullScreen={isMobile}
      open={signingTimeModalOpen}
      onBackdropClick={() => dispatch.SigningPlans.setModal({ signingTime: { open: false, data: prices } })}
      scroll={isMobile ? 'paper' : 'body'}
    >
      <DialogContent>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item>
            <Close sx={{ cursor: 'pointer' }} onClick={() => dispatch.SigningPlans.setModal({ signingTime: { open: false, data: prices } })} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center'>
          <Grid item>
            <Typography sx={{ fontSize: '28px', lineHeight: '35px', fontWeight: 600, textAlign: 'center' }}>Escolha seu tempo de assinatura</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='center' gap={2}>
          {prices.length && prices.filter((plan) => plan.price).map((plan) => (
            <Grid item>
              <SigningTimeCard
                isPromotion={isPromotion}
                promotionMonthlyPrice={promotionMonthlyPrice}
                promotionTerms={promotionTerms}
                {...plan}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PricingSigningTimeDialog;
