import React from 'react';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../utils/mobile';

import Vaccine from './Vaccine';
import BodyScore from './BodyScore';
import Medicines from './Medicines';
import BreastExam from './BreastExam';
import FoodSnacks from './FoodSnacks';
import RoutineCare from './RoutineCare';
import HeartControl from './HeartControl';
import VermifugeAndFlea from './VermifugeAndFlea';
import NutritionErgonomics from './NutritionErgonomics';

const titleModeMap = {
  create: 'Iniciar',
  edit: 'Editar',
};

const ProtocolContent = () => {
  const content = useSelector((state) => state.Modal.protocolDialog?.content);

  const routineCares = ['teeth_brushing', 'bath_grooming', 'hydration'];

  if (!content || routineCares.includes(content)) {
    return <RoutineCare />;
  }

  if (content && content.includes('vaccine')) {
    return <Vaccine />;
  }

  const mapping = {
    heart_control: <HeartControl />,
    nutrition_ergonomics: <NutritionErgonomics />,
    breast_exam: <BreastExam />,
    body_score: <BodyScore />,
    food: <FoodSnacks />,
    snacks: <FoodSnacks />,
    medicines: <Medicines />,
    vermifuge: <VermifugeAndFlea />,
    flea_tick_control: <VermifugeAndFlea />,
  };

  return (mapping[content] || <div />);
};

const ProtocolDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.Modal.protocolDialog?.open);
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const name = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.name);
  const lastDoseName = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastDoseName);
  const protocolMoment = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.moment);

  const handleCloseDialog = () => {
    dispatch.Modal.setDialog({ open: false });
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
        height: screen.height < 900 ? '95vh' : '540px',
      },
      '& .MuiDialog-container': {
        height: '100vh',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => undefined}
      scroll={isMobile ? 'paper' : 'body'}
    >
      <DialogContent sx={{ '&.MuiDialogContent-root': { padding: '60px 42px', position: 'relative', marginBottom: '10px' } }}>
        <Grid container direction='row' justifyContent='space-between' wrap='nowrap'>
          <Grid item>
            <Typography variant='h6'>
              {(mode && titleModeMap[mode]) || 'Iniciar'}
              {' '}
              protocolo
              {' '}
              {(protocolMoment === 'medicines' && lastDoseName && lastDoseName) || name}
            </Typography>
          </Grid>

          <Grid item>
            <Close
              sx={{ position: 'absolute', cursor: 'pointer', right: '15px', top: '15px' }}
              onClick={handleCloseDialog}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' gap={2}>
          <ProtocolContent />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProtocolDialog;
