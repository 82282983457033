import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, ReferAndWinText, ReferAndWinSubtext } from './style';

const ReferAndWin = () => {
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState(0);
  const couponQuantity = coupons > 1 ? 'cupons' : 'cupom';

  useEffect(() => {
    const fetchCouponCount = async () => {
      const response = await dispatch.DiscountCoupon.discountCouponCount();

      if (response?.data) {
        setCoupons(Number(response?.data?.count));
      }
    };

    fetchCouponCount();
  }, []);

  const redirectToReferralScreen = () => window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'REFERRAL_SCREEN', params: null }));

  return (
    <Container onClick={redirectToReferralScreen}>
      <div style={{ display: 'flex' }}>
        <img src={`${process.env.REACT_APP_WEB}/app/pngs/coupon.png`} style={{ marginRight: '8px', width: '65px', height: '65px' }} alt='refer' />

        <div style={{ width: '220px' }}>
          <ReferAndWinText>
            Indique e ganhe
          </ReferAndWinText>
          <ReferAndWinSubtext>
            {coupons > 0
              ? `Você possui ${coupons} ${couponQuantity} na conta`
              : 'Seus amigos patrocinando a saúde do seu pet'}
          </ReferAndWinSubtext>
        </div>
      </div>
    </Container>
  );
};

export default ReferAndWin;
