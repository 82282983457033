import React, { useCallback, useRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import Loading from '../../../global/Loading';
import OrderItem from '../OrderItem';

const OrdersList = ({ refetch, page, isFirstLoading }) => {
  const { voucherB2CMyList } = useSelector((state) => state.Vouchers);
  const { voucherB2CMyList: voucherB2CMyListLoading } = useSelector((state) => state.Vouchers.loading);
  const isVoucherListLoading = voucherB2CMyListLoading !== 'fulfilled';
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isFirstLoading) {
          refetch(page);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [page],
  );

  if (isVoucherListLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container flexDirection='column' spacing={2} sx={{ padding: '0px 20px', maxWidth: '1280px' }}>
      {voucherB2CMyList && voucherB2CMyList.length ? voucherB2CMyList.map((voucher, index) => {
        const isElementToRefresh = voucherB2CMyList.length === index + 3;

        return (
          <Grid
            item
            key={voucher.id}
            ref={isElementToRefresh ? lastElementRef : undefined}
          >
            <OrderItem {...voucher} />
          </Grid>
        );
      }) : <></>}
    </Grid>
  );
};

export default OrdersList;
