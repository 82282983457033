/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Grid, Typography, TextField, Divider, Fade } from '@mui/material';
import CalendarPicker from '@mui/lab/CalendarPicker';
import moment from 'moment';
import InputMask from 'react-input-mask';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalBox, CloseModal } from './style';
import { ReactComponent as AddIcon } from '../../../../assets/images/Services/add.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/Services/trash.svg';

const UnavailableDaysModalContent = () => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(moment());
  const name = useSelector((state) => state.Appointments.actualService.name);
  const unavailabilityTemp = useSelector((state) => state.Appointments.actualService.unavailabilityTemp);

  const closeModal = () => dispatch.Appointments.setModal({ calendarModal: { open: false, context: '' } });

  const addUnavailability = () => {
    dispatch.Appointments.addUnavailability();
  };

  const deleteUnavailability = (index) => {
    dispatch.Appointments.deleteUnavailabilityTemp({ index });
  };

  const handleForm = async (formData, { setFieldError }) => {
    const selectedDate = moment(date).format('MM/DD/YYYY');
    const { unavailability } = formData;

    const isValidationsSuccessful = unavailability
      .map(({ period_end, period_start }, index) => {
        const parsedStartHour = moment(`${selectedDate} ${period_start}`);
        const parsedEndHour = moment(`${selectedDate} ${period_end}`);

        if (!parsedStartHour.isValid() || (period_start.length !== 5 && period_start.length !== 0)) {
          setFieldError(`unavailability[${index}].period_start`, 'Horário inválido');
        }

        if (!parsedEndHour.isValid() || (period_end.length !== 5 && period_end.length !== 0)) {
          setFieldError(`unavailability[${index}].period_end`, 'Horário inválido');
        }

        if (!parsedStartHour.isValid() || !parsedEndHour.isValid()) {
          return false;
        }

        const isPeriodEndGreaterThanStart = parsedEndHour.isAfter(parsedStartHour);

        if (!isPeriodEndGreaterThanStart) {
          setFieldError(`unavailability[${index}].period_end`, 'Menor ou igual ao horário de início');
          return false;
        }

        return true;
      })
      .every((period) => period);

    if (isValidationsSuccessful) {
      dispatch.Appointments.saveUnavailability(date);
      closeModal();
    }
  };

  return (
    <ModalBox>
      <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
      <Typography variant='h5'>Janela de indisponibilidade</Typography>

      <Typography variant='h6' sx={{ color: '#CCC3FE' }}>
        { name }
      </Typography>

      <Formik
        initialValues={{}}
        onSubmit={handleForm}
        validateOnBlur
        validateOnChange
        validationSchema={Yup.object().shape({
          unavailability: Yup.array()
            .of(Yup.object().shape({
              period_start: Yup.string().required('Preencha o horário'),
              period_end: Yup.string().required('Preencha o horário'),
            })),
        })}
      >
        {({ setFieldValue, errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction='column'
              alignItems='flex-start'
              justifyContent='flex-start'
              spacing={2}
            >
              <Grid container justifyContent='center' alignItems='center' sx={{ marginTop: '30px' }}>
                <Grid item>
                  <Card elevation={0}>
                    <CalendarPicker date={date} onChange={(newDate) => setDate(newDate)} />
                  </Card>
                </Grid>
              </Grid>

              <Divider sx={{ margin: '26px 0', width: '100%' }} />

              <Grid
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
              >
                {unavailabilityTemp && unavailabilityTemp.map((unavailability, index) => (
                  <Grid item direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    <Fade in>
                      <Grid container direction='row' justifyContent='flex-start' alignItems='center' gap={1}>

                        <Typography variant='body1' sx={{ visibility: index === 0 ? 'visible' : 'hidden' }}>
                          Período:&nbsp;
                        </Typography>

                        <InputMask
                          mask='99:99'
                          name={`unavailability[${index}].period_start`}
                          onChange={(event) => {
                            setFieldValue(`unavailability[${index}].period_start`, event.target.value);
                            // eslint-disable-next-line no-param-reassign
                            unavailability.period_start = event.target.value;
                          }}
                          maskplaceholder='_'
                        >
                          {(inputProps) => (
                            <TextField
                              id={`unavailability[${index}].period_start`}
                              name={`unavailability[${index}].period_start`}
                              helperText={errors && errors.unavailability && errors.unavailability[index] && errors.unavailability[index].period_start}
                              error={errors && errors.unavailability && errors.unavailability[index] && errors.unavailability[index].period_start}
                              label='Hora Inicial'
                              sx={{ width: '170px', '& .MuiFormHelperText-root': { marginLeft: 0, position: 'absolute', bottom: '-22px', width: '200px' } }}
                              {...inputProps}
                            />
                          )}
                        </InputMask>

                        <Typography variant='body2'>-</Typography>

                        <InputMask
                          mask='99:99'
                          name={`unavailability[${index}].period_end`}
                          onChange={(event) => {
                            setFieldValue(`unavailability[${index}].period_end`, event.target.value);
                            // eslint-disable-next-line no-param-reassign
                            unavailability.period_end = event.target.value;
                          }}
                          maskplaceholder='_'
                        >
                          {(inputProps) => (
                            <TextField
                              id={`unavailability[${index}].period_end`}
                              name={`unavailability[${index}].period_end`}
                              label='Hora Final'
                              helperText={errors && errors.unavailability && errors.unavailability[index] && errors.unavailability[index].period_end}
                              error={errors && errors.unavailability && errors.unavailability[index] && errors.unavailability[index].period_end}
                              sx={{ width: '170px', '& .MuiFormHelperText-root': { marginLeft: 0, position: 'absolute', bottom: '-22px', width: '200px' } }}
                              {...inputProps}
                            />
                          )}
                        </InputMask>

                        {index > 0 && (
                        <Button
                          sx={{ color: '#6B48FF' }}
                          startIcon={<TrashIcon />}
                          onClick={() => { deleteUnavailability(index); }}
                        />
                        )}

                        {index === 0 && (
                        <Button
                          sx={{ color: '#6B48FF' }}
                          startIcon={<AddIcon />}
                          onClick={addUnavailability}
                        >
                          Adicionar
                        </Button>
                        )}
                      </Grid>
                    </Fade>
                  </Grid>
                ))}
              </Grid>

              <Grid container justifyContent='space-between' alignItems='center' sx={{ margin: '30px 0' }}>
                <Grid item>
                  <Button
                    size='large'
                    sx={{ color: '#FF505F' }}
                    onClick={closeModal}
                  >
                    Cancelar
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    type='submit'
                    size='large'
                    sx={{ color: '#6B48FF' }}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

    </ModalBox>
  );
};

export default UnavailableDaysModalContent;
