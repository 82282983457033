/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import moment from 'moment';
import { isNil } from 'lodash';
import { Formik } from 'formik';
import { useTheme } from '@mui/system';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import { ExpandLess, ExpandMore, Close } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { formatCPF } from '../../utils/format';
import { useQuery } from '../../hooks/useQuery';
import { maxLastDoseDate, validateNumbers, validateSymbols } from '../../utils/validators';
import { validateCNPJ } from '../../services/documentValidations';
import theme, { CustomLink, PrimaryButton } from '../../utils/theme';

import Loading from '../../components/global/Loading';

import { ReactComponent as ClipboardIcon } from '../../assets/images/clipboard.svg';

import {
  DialogTitle,
  Title,
  Text,
  LabelText,
  Subtitle,
  CardContainer,
  TitleContainer,
  FooterContainer,
  ErrorModalContainer,
  CardHeader,
} from './styles';

const errorCodeMessages = {
  400: 'O documento já foi assinado.',
  403: 'O token expirou. Você receberá um outro link via e-mail ou WhatsApp em breve.',
  404: 'O token de autenticação é inválido. Certifique-se de que acessou o link correto.',
  500: 'Ocorreu um erro ao buscar os dados do servidor. Tente novamente mais tarde',
};

const ErrorModal = ({ error }) => (
  <Stack alignItems='center' justifyContent='center' bgcolor={theme.BABY_POWDER} minHeight='100vh'>
    <ErrorModalContainer>
      <Text textAlign='center'>{error.message}</Text>
    </ErrorModalContainer>
  </Stack>
);

const VaccineForm = () => {
  const query = useQuery();
  const muiTheme = useTheme();
  const dispatch = useDispatch();

  const TOKEN = query.get('token');
  const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));

  const [protocol, setProtocol] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [protocolProducts, setProtocolProducts] = useState([]);
  const [errorModal, setErrorModal] = useState({ open: false, message: '' });
  const [alert, setAlert] = useState({ open: false, severity: 'error', message: '' });
  const [isPresentationModalVisible, setIsPresentationModalVisible] = useState(false);
  const [isReceiptModalVisible, setReceiptModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({
    lastDoseName: '',
    lastDoseManufactureName: protocol?.protocolAnimal?.lastDoseManufactureName || '',
    lastDoseExpirationYear: String(new Date().getFullYear()),
    lastDoseExpirationDay: 'N/A',
    lastDoseExpirationMonth: 'N/A',
    lastDoseKeyCode: isNil(protocol?.protocolAnimal?.lastDoseKeyCode) ? '' : protocol?.protocolAnimal?.lastDoseKeyCode,
    lastDoseManufactureYear: String(new Date().getFullYear()),
    lastDoseManufactureDay: 'N/A',
    lastDoseManufactureMonth: 'N/A',
    lastDoseDate: null,
    nextDoseDate: null,
    lastDose: 'Primeira',
    vetName: '',
    vetCRMV: '',
    vetCRMVUF: '',
    vetPhone: '',
    vetEmail: '',
    vetStreet: '',
    vetDistrict: '',
    vetNumber: null,
    uf: '',
    city: '',
    vetCorporateName: '',
    vetCNPJ: '',
    organizationWorkspaceCRMV: '',
    organizationWorkspaceCRMVUF: '',
  });

  const productOptions = protocolProducts.map((product) => ({ label: product.name, id: product.id }));

  const validationSchema = Yup.object().shape({
    lastDoseName: Yup.mixed().required('Preencha o nome da vacina'),
    lastDoseManufactureName: Yup.string().required('Preencha o fabricante'),
    lastDoseExpirationYear: Yup.string().required('Preencha o ano de validade'),
    lastDoseExpirationDay: Yup.string(),
    lastDoseExpirationMonth: Yup.string(),
    lastDoseKeyCode: Yup.string().nullable().required('Preencha o número da partida (lote)'),
    lastDoseManufactureYear: Yup.string().required('Preencha o ano de fabricação').test('maxYear', 'Data de fabricação não pode ser maior que data atual', (val) => Number(val) <= Number(new Date().getFullYear())),
    lastDoseManufactureDay: Yup.string(),
    lastDoseManufactureMonth: Yup.string(),
    lastDoseDate: Yup.string().nullable().required('Preencha a data da aplicação').test(maxLastDoseDate.name, maxLastDoseDate.error, maxLastDoseDate.validate),
    nextDoseDate: Yup.string().nullable(),
    lastDose: Yup.string().nullable().required('Preencha a dose da vacina'),
    vetName: Yup.string().required('Preencha seu nome completo'),
    vetCRMV: Yup.string().required('Preencha seu CRMV'),
    vetCRMVUF: Yup.string().required('Selecione o estado do CRMV'),
    vetPhone: Yup.string()
      .required('Preencha seu telefone')
      .test('len', 'Preencher número completo, incluindo DDD e dígito 9', (val) => {
        const withoutDashes = val && val.replace(/\D/g, '').length;
        return withoutDashes === 11;
      }),
    vetEmail: Yup.string().email('E-mail inválido').required('Preencha seu e-mail'),
    vetStreet: Yup.string().required('Preencha sua rua').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
    vetDistrict: Yup.string().required('Preencha seu bairro').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
    vetNumber: Yup.number().nullable().min(0).required('Preencha seu número')
      .test(validateNumbers.name, validateNumbers.error, validateNumbers.validate),
    vetComplement: Yup.string().test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
    uf: Yup.string().required('Preencha seu estado').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
    city: Yup.string().required('Preencha sua cidade').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
    vetCorporateName: Yup.string().nullable(),
    vetCNPJ: Yup.string().nullable().test('cnpjValidation', 'Este CNPJ é inválido', (value) => (!value || value.replace(/\D/g, '').trim() === '' ? true : validateCNPJ(value))),
    organizationWorkspaceCRMV: Yup.string().nullable(),
    organizationWorkspaceCRMVUF: Yup.string().nullable()
      .when('organizationWorkspaceCRMV', {
        is: (value) => Boolean(value),
        then: Yup.string().required('Selecione o estado do CRMV da empresa'),
      }),
  });

  const handleSubmitVaccineForm = async (formData, { setErrors }) => {
    const {
      lastDoseName,
      lastDoseManufactureName,
      lastDoseExpirationYear,
      lastDoseExpirationDay,
      lastDoseExpirationMonth,
      lastDoseKeyCode,
      lastDoseManufactureYear,
      lastDoseManufactureDay,
      lastDoseManufactureMonth,
      lastDoseDate,
      nextDoseDate,
      lastDose,
      vetName,
      vetCRMV,
      vetCRMVUF,
      vetPhone,
      vetEmail,
      uf,
      city,
      vetStreet,
      vetDistrict,
      vetNumber,
      vetComplement,
      vetCorporateName,
      vetCNPJ,
      organizationWorkspaceCRMV,
      organizationWorkspaceCRMVUF,
    } = formData;

    const vetAddress = `${vetStreet}, ${vetNumber || ''} ${vetComplement || ''} - ${vetDistrict}, ${city}, ${uf}`;

    const expirationMonths = {
      'N/A': '12',
      jan: '01',
      fev: '02',
      mar: '03',
      abr: '04',
      mai: '05',
      jun: '06',
      jul: '07',
      ago: '08',
      set: '09',
      out: '10',
      nov: '11',
      dez: '12',
    };

    const manufactureMonths = {
      'N/A': '01',
      jan: '01',
      fev: '02',
      mar: '03',
      abr: '04',
      mai: '05',
      jun: '06',
      jul: '07',
      ago: '08',
      set: '09',
      out: '10',
      nov: '11',
      dez: '12',
    };

    const expirationDay = lastDoseExpirationDay === 'N/A' ? '28' : lastDoseExpirationDay.padStart(2, '0');
    const expirationMonth = expirationMonths[lastDoseExpirationMonth] || '12';
    const expirationDate = new Date(`${lastDoseExpirationYear}-${expirationMonth}-${expirationDay}`).toISOString().slice(0, 10);

    const manufactureDay = lastDoseManufactureDay === 'N/A' ? '01' : lastDoseManufactureDay.padStart(2, '0');
    const manufactureMonth = manufactureMonths[lastDoseManufactureMonth] || '01';
    const manufactureDate = new Date(`${lastDoseManufactureYear}-${manufactureMonth}-${manufactureDay}`).toISOString().slice(0, 10);

    if (expirationDate < manufactureDate) {
      return setErrors({ lastDoseExpirationYear: 'Data de validade não pode ser menor a data de fabricação' });
    }

    if (manufactureDate >= expirationDate) {
      return setErrors({ lastDoseManufactureYear: 'Data de fabricação não pode ser maior ou igual a data de validade' });
    }

    const parsedLastDoseDate = JSON.stringify(lastDoseDate).slice(1, 11);
    const parsedNextDoseDate = nextDoseDate ? JSON.stringify(nextDoseDate).slice(1, 11) : '';

    if (expirationDate < parsedLastDoseDate) {
      return setErrors({ lastDoseDate: 'Data de validade não pode ser menor que a aplicação' });
    }

    if (manufactureDate > parsedLastDoseDate) {
      return setErrors({ lastDoseManufactureYear: 'Data de fabricação não pode ser maior que a aplicação' });
    }

    if (parsedNextDoseDate !== '') {
      if (parsedNextDoseDate <= parsedLastDoseDate) {
        return setErrors({ nextDoseDate: 'Próxima vacinação não pode ser menor que a data de aplicação' });
      }

      if (!moment(parsedNextDoseDate).isValid()) {
        return setErrors({ nextDoseDate: 'Data inválida' });
      }
    }

    if (!moment(parsedLastDoseDate).isValid()) {
      return setErrors({ lastDoseDate: 'Data inválida' });
    }

    const payload = {
      lastDoseName,
      lastDoseManufactureName,
      lastDoseExpirationDate: expirationDate,
      lastDoseExpirationDay: lastDoseExpirationDay !== 'N/A',
      lastDoseExpirationMonth: lastDoseExpirationMonth !== 'N/A',
      lastDoseKeyCode,
      lastDoseManufactureDate: manufactureDate,
      lastDoseManufactureDay: lastDoseManufactureDay !== 'N/A',
      lastDoseManufactureMonth: lastDoseManufactureMonth !== 'N/A',
      lastDoseDate: parsedLastDoseDate,
      nextDoseDate: parsedNextDoseDate === '' ? null : parsedNextDoseDate,
      lastDose,
      vetName,
      vetCRMV,
      vetCRMVUF,
      vetPhone,
      vetEmail,
      vetAddress,
      vetCorporateName,
      vetCNPJ,
      organizationWorkspaceCRMV,
      organizationWorkspaceCRMVUF,
    };

    const response = await dispatch.Animals.sendVaccineForm({ payload, token: TOKEN });

    if (response.data) {
      const { signatureURL } = response.data;
      window.location.href = signatureURL;
    } else {
      setAlert({
        open: true,
        severity: 'error',
        message: errorCodeMessages[response.response.status] || errorCodeMessages[500],
      });
    }
  };

  const fetchState = async () => {
    const response = await dispatch.User.getStateOptions();

    if (response?.data?.length) {
      setStateOptions(response.data);
    } else {
      setStateOptions([]);
    }
  };

  const fetchLastDoseReceiptImage = async (uuid) => {
    if (uuid) {
      const response = await dispatch.Animals.getLastDoseReceiptImage({ uuid, token: TOKEN });
      if (response) return response;
    }
    return null;
  };

  const fetchUserData = async () => {
    const response = await dispatch.Animals.getProtocolAnimal(TOKEN);

    if (response?.data) {
      const { lastDoseName, lastDoseKeyCode, lastDoseDate, nextDoseDate, vetName, vetEmail, vetPhone } = response?.data?.protocolAnimal;

      setInitialValues({ ...initialValues, lastDoseName, lastDoseKeyCode, lastDoseDate, nextDoseDate, vetName, vetPhone, vetEmail });

      const receiptUrl = await fetchLastDoseReceiptImage(response?.data?.protocolAnimal?.lastDoseReceiptImage);

      setProtocol({ lastDoseReceiptImage: receiptUrl, ...response.data });
      setIsPresentationModalVisible(true);
    } else {
      setErrorModal({
        open: true,
        message: errorCodeMessages[response.response.status] || errorCodeMessages[500],
      });
    }

    setIsLoading(false);
  };

  const fetchCityOfUF = async (uf, name) => {
    const response = await dispatch.User.getCityOptions({ uf, name });

    if (response.data?.length) {
      setCityOptions(response.data);
    } else {
      setCityOptions([]);
    }
  };

  const fetchProducts = async (name) => {
    setProtocolProducts([]);

    const { id: protocolId } = protocol?.protocolAnimal;
    const products = await dispatch.Animals.getAnimalProtocolProducts({ protocolId, name, token: TOKEN });

    if (products && products.data) {
      setProtocolProducts(products.data);
    }
  };

  const handleStateOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('city', '');
      setFieldValue('uf', event.uf);
      fetchCityOfUF(event.uf, '');
    }
  };

  const handleCityOptionsChange = (event, setFieldValue) => {
    if (event) setFieldValue('city', event.name);
  };

  const handleCityTextChange = (event, setFieldValue, uf) => {
    const { value } = event.target;
    setFieldValue('city', value);
    fetchCityOfUF(uf, value);
  };

  const handleExpirationYearChange = (event, setFieldValue) => {
    const parsedYear = JSON.stringify(event).slice(1, 5);
    if (event) setFieldValue('lastDoseExpirationYear', parsedYear);
  };

  const handleManufactureYearChange = (event, setFieldValue) => {
    const parsedYear = JSON.stringify(event).slice(1, 5);
    if (event) setFieldValue('lastDoseManufactureYear', parsedYear);
  };

  const getAnimalFur = () => {
    const { furColor, furSize, furSigns } = protocol;
    const fur = `${furColor?.join(', ') || ''} ${furSize ? `,${furSize}` : ''} ${furSigns ? `, ${furSigns}` : ''}`;
    return fur;
  };

  const getAnimalSex = () => {
    const sex = { F: 'Fêmea', M: 'Macho' };
    return sex[protocol?.sex] || '';
  };

  const getAnimalSpecies = () => {
    const species = { CAT: 'Gato', DOG: 'Cachorro' };
    return species[protocol?.breed?.species] || '';
  };

  const handleProductTextChange = async (event, setFieldValue) => {
    setFieldValue('lastDoseName', event.target.value);

    await fetchProducts(event.target.value);
  };

  const handleProductOptionChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('lastDoseName', event.label);
    }
  };

  const handleExpandImage = () => setExpanded(!expanded);

  const handleClosePresentationModal = () => setIsPresentationModalVisible(false);

  const handleVetCRMVUFStateOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('vetCRMVUF', event.uf);
    }
  };

  const handleOrganizationWorkspaceCRMVUFStateOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('organizationWorkspaceCRMVUF', event.uf);
    }
  };

  const PresentationModal = () => (
    <Dialog open={isPresentationModalVisible} onClose={handleClosePresentationModal}>
      <DialogTitle>
        <Subtitle fontWeight={500}>
          {`Olá ${protocol?.protocolAnimal?.vetName?.trim() || ''},`}
        </Subtitle>
        <IconButton onClick={handleClosePresentationModal}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography color={theme.MEDIUM_NAVY} fontSize={16}>
          {`Sua assinatura foi solicitada por ${protocol?.user?.fullName || ''}, tutor de ${protocol?.name || ''}, no Atestado de Vacinação - ${protocol?.protocolAnimal?.name || ''}.`}
          <br />
          A carteira de vacinação digital da Guiavet é válida, segura e gratuita.
          <br />
          Você também pode começar a utilizar para seus pacientes!
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button sx={{ color: theme.GUIAVET }} onClick={handleClosePresentationModal}>OK</Button>
      </DialogActions>
    </Dialog>
  );

  const ReceiptModal = () => (
    <Dialog open={isReceiptModalVisible} onClose={() => setReceiptModalVisible(false)} fullWidth>
      <DialogTitle>
        Comprovante de vacina
        <IconButton onClick={() => setReceiptModalVisible(false)}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <img src={protocol?.lastDoseReceiptImage} alt='Comprovante de vacina' width='100%' />
      </DialogContent>
    </Dialog>
  );

  useEffect(() => {
    fetchUserData();
    fetchState();
  }, []);

  if (errorModal.open) {
    return <ErrorModal error={errorModal} />;
  }

  if (isLoading) {
    return (
      <Stack bgcolor={theme.BABY_POWDER} alignItems='center' justifyContent='center' height='100vh'>
        <Loading size={48} />
      </Stack>
    );
  }

  return (
    <Stack alignItems='center' justifyContent='center' mb={4} bgcolor={theme.BABY_POWDER} zIndex={9999}>

      <PresentationModal />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alert.open}
        autoHideDuration={7000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      <TitleContainer>
        <ClipboardIcon color={theme.DARK_CYAN} style={{ minHeight: 24, minWidth: 24 }} />
        <Title>{`Atestado de vacinação - ${protocol?.protocolAnimal?.name || ''}`}</Title>
      </TitleContainer>

      <CardContainer my={3}>
        <Grid container mb={2}>
          <Grid item xs={6}>
            <Title>Informações</Title>
          </Grid>

          <Grid item xs={6} textAlign='right'>
            <Button onClick={handleExpandImage} variant='text'>
              <Typography color={theme.GUIAVET} fontSize={14}>
                {isDesktop && (expanded ? 'Menos informações' : 'Mais informações')}
                {!isDesktop && (expanded ? 'Recolher' : 'Expandir')}
              </Typography>
              {expanded ? <ExpandLess sx={{ color: theme.GUIAVET }} /> : <ExpandMore sx={{ color: theme.GUIAVET }} />}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 1, md: 3 }}>
          <Grid item xs={12} md={4}>
            <LabelText>Nome do animal</LabelText>
            <Text>{protocol?.name}</Text>
          </Grid>

          <Grid item xs={12} md={4}>
            <LabelText>Tutor</LabelText>
            <Text>{protocol?.user?.fullName}</Text>
          </Grid>

          {protocol?.lastDoseReceiptImage ? (
            <>
              <Grid item xs={12} md={4} onClick={() => setReceiptModalVisible(true)} sx={{ cursor: 'pointer' }}>
                <LabelText mb={1}>Comprovante de vacina</LabelText>
                <img src={protocol?.lastDoseReceiptImage} alt='Comprovante de vacina' width='50%' />
                <LabelText mt={1}>Clique para ampliar</LabelText>
              </Grid>

              <ReceiptModal />
            </>
          ) : null}

          <Grid item xs={12} md={12}>
            <Collapse in={expanded}>
              <Grid container spacing={{ xs: 1, md: 3 }}>
                <Grid item xs={12} md={4}>
                  <LabelText>Data de nascimento</LabelText>
                  <Text>{protocol?.birthdate?.split('-').reverse().join('/')}</Text>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LabelText>Pelagem</LabelText>
                  <Text>{getAnimalFur()}</Text>
                </Grid>

                <Grid item xs={12} md={4}>
                  <LabelText>Sexo</LabelText>
                  <Text>{getAnimalSex()}</Text>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LabelText>Espécie</LabelText>
                  <Text>{getAnimalSpecies()}</Text>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LabelText>Raça</LabelText>
                  <Text>{protocol?.breed?.name}</Text>
                </Grid>

                <Grid item xs={12} md={4}>
                  <LabelText>CPF do tutor</LabelText>
                  <Text>{formatCPF(protocol?.user?.cpfCnpj)}</Text>
                </Grid>

                <Grid item xs={12} md={12}>
                  <LabelText>Endereço</LabelText>
                  <Text>{protocol?.user?.address?.fullAddress}</Text>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </CardContainer>

      <CardContainer>

        <CardHeader>
          <Title>Preencha abaixo os campos do documento:</Title>
          <LabelText>
            Caso a data completa de validade e/ou fabricação não esteja disponível no rótulo, selecione a opção N/A (não se aplica)
          </LabelText>
        </CardHeader>

        <Grid item xs={12} md={12} mb={3}>
          <Subtitle>Sobre a vacina</Subtitle>
        </Grid>

        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}
          onSubmit={handleSubmitVaccineForm}
          validationSchema={validationSchema}
        >
          {({ handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue, isSubmitting }) => {
            const expirationSelectedMonth = moment().month(values.lastDoseExpirationMonth).format('M').padStart(2, '0');
            const parsedExpirationSelectedYear = JSON.stringify(values.lastDoseExpirationYear).slice(1, 5);
            const expirationSelectedYearAndMonth = `${parsedExpirationSelectedYear}-${expirationSelectedMonth}`;
            const expirationDaysInMonth = Array.from(Array(moment(expirationSelectedYearAndMonth).daysInMonth()), (_, i) => String(i + 1));
            const expirationMonthOptions = ['N/A', ...expirationDaysInMonth];

            const manufactureSelectedMonth = moment().month(values.lastDoseManufactureMonth).format('M').padStart(2, '0');
            const parsedManufactureSelectedYear = JSON.stringify(values.lastDoseManufactureYear).slice(1, 5);
            const manufactureSelectedYearAndMonth = `${parsedManufactureSelectedYear}-${manufactureSelectedMonth}`;
            const manufactureDaysInMonth = Array.from(Array(moment(manufactureSelectedYearAndMonth).daysInMonth()), (_, i) => String(i + 1));
            const manufactureMonthOptions = ['N/A', ...manufactureDaysInMonth];

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      blurOnSelect='touch'
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      disablePortal
                      id='lastDoseName'
                      name='lastDoseName'
                      onBlur={handleBlur}
                      value={values.lastDoseName}
                      openOnFocus
                      freeSolo
                      options={productOptions || []}
                      onChange={(e, value) => handleProductOptionChange(value, setFieldValue)}
                      renderInput={(params) => (
                        <TextField
                          id='lastDoseName'
                          name='lastDoseName'
                          error={!!errors.lastDoseName}
                          helperText={errors.lastDoseName}
                          onChange={(event) => handleProductTextChange(event, setFieldValue)}
                          label='Nome da vacina *'
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      label='Fabricante *'
                      id='lastDoseManufactureName'
                      name='lastDoseManufactureName'
                      touched={String(touched.lastDoseManufactureName)}
                      value={values.lastDoseManufactureName}
                      error={!!errors.lastDoseManufactureName}
                      helperText={errors.lastDoseManufactureName && errors.lastDoseManufactureName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item container mb={-2}>
                    <Text>Data de validade</Text>
                  </Grid>

                  <Grid item container spacing={1} xs={12} md={6} maxWidth='100%' wrap='nowrap'>
                    <Grid item width='100%'>
                      <DatePicker
                        onBlur={handleBlur}
                        views={['year']}
                        label='Ano *'
                        id='lastDoseExpirationYear'
                        name='lastDoseExpirationYear'
                        value={values.lastDoseExpirationYear}
                        touched={String(touched.lastDoseExpirationYear)}
                        onChange={(value) => handleExpirationYearChange(value, setFieldValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.lastDoseExpirationYear}
                            helperText={errors.lastDoseExpirationYear}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item width='100%'>
                      <FormControl fullWidth>
                        <InputLabel>Mês</InputLabel>
                        <Select
                          label='Mês'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='lastDoseExpirationMonth'
                          name='lastDoseExpirationMonth'
                          value={values.lastDoseExpirationMonth}
                          error={!!errors.lastDoseExpirationMonth}
                          disabled={!values.lastDoseExpirationYear}
                        >
                          {['N/A', ...moment.monthsShort()].map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item width='100%'>
                      <FormControl fullWidth>
                        <InputLabel>Dia</InputLabel>
                        <Select
                          label='Dia'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='lastDoseExpirationDay'
                          name='lastDoseExpirationDay'
                          value={values.lastDoseExpirationDay}
                          error={!!errors.lastDoseExpirationDay}
                          disabled={values.lastDoseExpirationMonth === 'N/A'}
                        >
                          {expirationMonthOptions.map((item) => (
                            <MenuItem key={item} value={item}>{item.padStart(2, '0')}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      id='lastDoseKeyCode'
                      name='lastDoseKeyCode'
                      label='Número da partida (lote)*'
                      touched={String(touched.lastDoseKeyCode)}
                      value={values.lastDoseKeyCode}
                      error={!!errors.lastDoseKeyCode}
                      helperText={errors.lastDoseKeyCode && errors.lastDoseKeyCode}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item container mb={-2}>
                    <Text>Data de fabricação</Text>
                  </Grid>

                  <Grid item container spacing={1} xs={12} md={6} maxWidth='100%' wrap='nowrap'>
                    <Grid item width='100%'>
                      <DatePicker
                        onBlur={handleBlur}
                        id='lastDoseManufactureYear'
                        name='lastDoseManufactureYear'
                        views={['year']}
                        label='Ano *'
                        maxDate={moment()}
                        value={values.lastDoseManufactureYear}
                        touched={String(touched.lastDoseManufactureYear)}
                        onChange={(value) => handleManufactureYearChange(value, setFieldValue)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            error={!!errors.lastDoseManufactureYear}
                            helperText={errors.lastDoseManufactureYear}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item width='100%'>
                      <FormControl fullWidth>
                        <InputLabel>Mês</InputLabel>
                        <Select
                          label='Mês'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='lastDoseManufactureMonth'
                          name='lastDoseManufactureMonth'
                          value={values.lastDoseManufactureMonth}
                          error={!!errors.lastDoseManufactureMonth}
                          disabled={!values.lastDoseManufactureYear}
                        >
                          {['N/A', ...moment.monthsShort()].map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item width='100%'>
                      <FormControl fullWidth>
                        <InputLabel>Dia</InputLabel>
                        <Select
                          label='Dia'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id='lastDoseManufactureDay'
                          name='lastDoseManufactureDay'
                          value={values.lastDoseManufactureDay}
                          error={!!errors.lastDoseManufactureDay}
                          disabled={values.lastDoseManufactureMonth === 'N/A'}
                        >
                          {manufactureMonthOptions.map((item) => (
                            <MenuItem key={item} value={item}>{item.padStart(2, '0')}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DatePicker
                      onBlur={handleBlur}
                      id='lastDoseDate'
                      name='lastDoseDate'
                      label='Data da aplicação *'
                      value={values.lastDoseDate}
                      touched={String(touched.lastDoseDate)}
                      onChange={(value) => setFieldValue('lastDoseDate', value)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={!!errors.lastDoseDate}
                          helperText={errors.lastDoseDate}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DatePicker
                      id='nextDoseDate'
                      name='nextDoseDate'
                      onBlur={handleBlur}
                      label='Próxima vacinação'
                      value={values.nextDoseDate}
                      touched={String(touched.nextDoseDate)}
                      onChange={(value) => setFieldValue('nextDoseDate', value)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={!!errors.nextDoseDate}
                          helperText={errors.nextDoseDate}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                      <Text>Dose da vacina *</Text>
                      <Grid item xs={12} ml={1} mt={1}>
                        <RadioGroup
                          row
                          name='lastDose'
                          defaultValue='Primeira'
                          onBlur={handleBlur}
                          onChange={(event) => setFieldValue('lastDose', event.target.value)}
                        >
                          {['Primeira', 'Segunda', 'Terceira', 'Quarta', 'Única'].map((item) => (
                            <FormControlLabel key={item} control={<Radio />} label={item} value={item} />
                          ))}
                        </RadioGroup>
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Subtitle>Sobre o veterinário</Subtitle>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      id='vetName'
                      name='vetName'
                      onBlur={handleBlur}
                      label='Nome completo *'
                      value={values.vetName}
                      error={!!errors.vetName}
                      touched={String(touched.vetName)}
                      helperText={errors.vetName && errors.vetName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id='vetCRMV'
                      name='vetCRMV'
                      label='CRMV *'
                      onBlur={handleBlur}
                      value={values.vetCRMV}
                      error={!!errors.vetCRMV}
                      touched={String(touched.vetCRMV)}
                      helperText={errors.vetCRMV && errors.vetCRMV}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onBlur={handleBlur}
                      name='vetCRMVUF'
                      disablePortal
                      freeSolo
                      value={stateOptions.find((a) => a.uf === values.vetCRMVUF) || { name: '' }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(e, value) => handleVetCRMVUFStateOptionsChange(value, setFieldValue)}
                      options={stateOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} value={option.id}>
                          <div>{option.name}</div>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          id='vetCRMVUF'
                          name='vetCRMVUF'
                          label='Estado do CRMV *'
                          error={!!errors.vetCRMVUF}
                          helperText={errors && errors.vetCRMVUF}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <InputMask
                      maskChar='_'
                      onBlur={handleBlur}
                      value={values.vetPhone}
                      onChange={handleChange}
                      mask='\(99\) 9 9999-9999'
                    >
                      {(inputProps) => (
                        <TextField
                          error={!!errors.vetPhone}
                          helperText={errors.vetPhone && errors.vetPhone}
                          label='Telefone *'
                          placeholder='(00) 0 0000-0000'
                          fullWidth
                          type='tel'
                          id='vetPhone'
                          name='vetPhone'
                          touched={String(touched.vetPhone)}
                          InputLabelProps={{ shrink: true }}
                          autoComplete='new-password'
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      type='email'
                      id='vetEmail'
                      name='vetEmail'
                      label='E-mail *'
                      onBlur={handleBlur}
                      value={values.vetEmail}
                      error={!!errors.vetEmail}
                      touched={String(touched.vetEmail)}
                      helperText={errors.vetEmail && errors.vetEmail}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Rua *'
                      id='vetStreet'
                      name='vetStreet'
                      onBlur={handleBlur}
                      value={values.vetStreet}
                      error={!!errors.vetStreet}
                      touched={String(touched.vetStreet)}
                      helperText={errors.vetStreet && errors.vetStreet}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Número *'
                      id='vetNumber'
                      name='vetNumber'
                      type='number'
                      onBlur={handleBlur}
                      value={values.vetNumber}
                      error={!!errors.vetNumber}
                      touched={String(touched.vetNumber)}
                      helperText={errors.vetNumber}
                      onChange={handleChange}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id='vetComplement'
                      onBlur={handleBlur}
                      name='vetComplement'
                      label='Complemento'
                      value={values.vetComplement}
                      error={!!errors.vetComplement}
                      touched={String(touched.vetComplement)}
                      helperText={errors.vetComplement && errors.vetComplement}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label='Bairro *'
                      id='vetDistrict'
                      name='vetDistrict'
                      onBlur={handleBlur}
                      value={values.vetDistrict}
                      error={!!errors.vetDistrict}
                      touched={String(touched.vetDistrict)}
                      helperText={errors.vetDistrict && errors.vetDistrict}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onBlur={handleBlur}
                      name='uf'
                      disablePortal
                      freeSolo
                      value={stateOptions.find((a) => a.uf === values.uf) || { name: '' }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(e, value) => handleStateOptionsChange(value, setFieldValue)}
                      options={stateOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} value={option.id}>
                          <div>{option.name}</div>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          id='uf'
                          name='uf'
                          label='Estado *'
                          error={!!errors.uf}
                          helperText={errors && errors.uf}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      blurOnSelect='touch'
                      disablePortal
                      id='city'
                      name='city'
                      error={!!errors.city}
                      openOnFocus
                      freeSolo
                      onChange={(e, value) => handleCityOptionsChange(value, setFieldValue)}
                      options={cityOptions}
                      value={{ name: values?.city || '' }}
                      disabled={!values.uf || !stateOptions?.length}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(props, option) => (
                        <MenuItem {...props} value={option.id}>
                          <div>{option.name}</div>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          id='city'
                          name='city'
                          label='Cidade *'
                          error={!!errors.city}
                          helperText={errors && errors.city}
                          onChange={(event) => handleCityTextChange(event, setFieldValue, values.uf)}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} mt={2}>
                    <Typography color={theme.DARK_CYAN} fontSize={20}>
                      Caso seja empresa, os campos abaixo são obrigatórios:
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      id='vetCorporateName'
                      name='vetCorporateName'
                      label='Razão social'
                      value={values.vetCorporateName}
                      error={!!errors.vetCorporateName}
                      touched={String(touched.vetCorporateName)}
                      helperText={errors.vetCorporateName && errors.vetCorporateName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <InputMask
                      maskChar='_'
                      onBlur={handleBlur}
                      value={values.vetCNPJ}
                      onChange={handleChange}
                      mask='99.999.999/9999-99'
                    >
                      {(inputProps) => (
                        <TextField
                          fullWidth
                          id='vetCNPJ'
                          name='vetCNPJ'
                          label='CNPJ'
                          value={values.vetCNPJ}
                          error={!!errors.vetCNPJ}
                          touched={String(touched.vetCNPJ)}
                          helperText={errors.vetCNPJ && errors.vetCNPJ}
                          onChange={handleChange}
                          placeholder='00.000.000/0000-00'
                          InputLabelProps={{ shrink: true }}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      onBlur={handleBlur}
                      id='organizationWorkspaceCRMV'
                      name='organizationWorkspaceCRMV'
                      label='CRMV da empresa'
                      value={values.organizationWorkspaceCRMV}
                      error={!!errors.organizationWorkspaceCRMV}
                      touched={String(touched.organizationWorkspaceCRMV)}
                      helperText={errors.organizationWorkspaceCRMV && errors.organizationWorkspaceCRMV}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      onBlur={handleBlur}
                      disablePortal
                      freeSolo
                      value={stateOptions.find((a) => a.uf === values.organizationWorkspaceCRMVUF) || { name: '' }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(e, value) => handleOrganizationWorkspaceCRMVUFStateOptionsChange(value, setFieldValue)}
                      options={stateOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} value={option.id}>
                          <div>{option.name}</div>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          id='organizationWorkspaceCRMVUF'
                          name='organizationWorkspaceCRMVUF'
                          label='Estado do CRMV da empresa'
                          error={!!errors.organizationWorkspaceCRMVUF}
                          helperText={errors && errors.organizationWorkspaceCRMVUF}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                </Grid>

                <Grid container display='flex' justifyContent='flex-end' mt={{ xs: 3, md: 9 }}>
                  <Grid item xs={12} md={6}>
                    <PrimaryButton fullWidth type='submit'>
                      {isSubmitting ? <Loading color={theme.WHITE} size={24} /> : 'Continuar'}
                    </PrimaryButton>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </CardContainer>

      <FooterContainer container>
        <Grid item xs={12} md={6} mb={{ xs: 2, md: 0 }}>
          <LabelText fontStyle='italic'>
            Esta carteirinha é oficial e está conforme a
            {' '}
            <CustomLink
              primary
              target='_blank'
              href='https://www.in.gov.br/web/dou/-/resolucao-n-1.321-de-24-de-abril-de-2020-253999246'
            >
              resolução nº 1321
            </CustomLink>
            {' '}
            expedida pelo Conselho Federal de Medicina Veterinária (CFMV)
          </LabelText>
        </Grid>
        <Grid item xs={12} md={6} textAlign={{ xs: 'left', md: 'right' }}>
          <LabelText fontStyle='italic'>emitido por</LabelText>
          <Typography color={theme.DARK_PLATINUM} fontSize={12}>
            GUIAVET MARKETPLACE SERVICOS DE INTERNET LTDA
          </Typography>
          <Typography color={theme.DARK_PLATINUM} fontSize={12}>CNPJ: 41.803.482/0001-03 / CRMV: 20164</Typography>
        </Grid>
      </FooterContainer>
    </Stack>
  );
};

export default VaccineForm;
