import styled from 'styled-components';
import { Grid, Fab } from '@mui/material';
import theme, { animations } from '../../../utils/theme';

export const PetControl = styled.div`
margin: 20px 0;
width: 100%;
display: flex;
align-items: center;
justify-content: ${({ emptyList }) => (emptyList ? 'flex-end' : 'space-between')};

& .div {
  justify-content: space-between;
}

@media screen and (max-width: 736px) {
	margin-top: -8px;
}
`;

export const StyledGrid = styled(Grid)`
	margin: 0 auto;
	animation: ${animations.fadeIn} 0.2s ease-in;
	@media screen and (max-width: 736px) {
	max-width: fit-content;
	margin: auto;
}
`;

export const StyledPetCardGrid = styled(Grid)`
	display: flex;

	@media screen and (max-width: 736px) {
		flex-direction: row;
		justify-content: flex-start;
	}
`;

export const StyledFabButton = styled(Fab)`
  &.MuiFab-root, &.MuiFab-root:hover {
    color: white;
    background-color: ${theme.primaryButton};
    width: 50px;
    height: 50px;

    @media screen and (max-width: 736px) {
      min-width: 50px;
      margin-left: 5px;
	  }
  }
`;
