import React from 'react';
import { AvatarContainer } from './style';

const NotificationAvatar = ({ warningSeverity, icon }) => (
  <AvatarContainer warningSeverity={warningSeverity}>
    {icon}
  </AvatarContainer>
);

export default NotificationAvatar;
