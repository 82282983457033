const today = new Date().toISOString().slice(0, 10);

export const parsedDate = (date) => JSON.stringify(new Date(date).toISOString()).slice(1, 11);

export const validateChars = (text) => !/[\u0023-\u0026]|[\u003A-\u003F]|[\u00A1-\u00AF]|[\u00B0-\u00BF]|\u0021|[\u007B-\u007D]|[\u002A-\u002B]/g.test(text);

export const validateNumber = (text) => /^[0-9]+$/.test(text);

export const validateMaxLastDoseDate = (date) => parsedDate(date) <= today;

export const validateMinNextDoseDate = (date) => parsedDate(date) >= today;

export const validateSymbols = {
  name: 'validateChars',
  error: 'Não é possível enviar símbolos no campo. Ex: !#*%',
  validate: validateChars,
};

export const validateNumbers = {
  name: 'validateNumbers',
  error: 'Não é possível enviar letras no campo',
  validate: validateNumber,
};

export const maxLastDoseDate = {
  name: 'maxLastDoseDate',
  error: 'Data de aplicação deve ser menor que a data atual',
  validate: validateMaxLastDoseDate,
};

export const minNextDoseDate = {
  name: 'minNextDoseDate',
  error: 'Próxima vacinação deve ser maior que a data atual',
  validate: validateMinNextDoseDate,
};
