/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import Grow from '@mui/material/Grow';
import InputMask from 'react-input-mask';
import DatePicker from '@mui/lab/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { AttachFile } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Grid,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  FormControlLabel,
  Autocomplete,
  Divider,
  Modal,
  Box } from '@mui/material';

import Alert from '../../global/Alert';
import theme from '../../../utils/theme';
import Loading from '../../global/Loading';
import useWindowSize from '../../../hooks/windowSize';
import { validateSymbols } from '../../../utils/validators';
import { furColorsUtils, furSizesUtils } from '../../../utils/pet';
import { convertBase64, validateImage, resizeImage } from '../../../utils/imageUtils';
import { StyledPetFormCard, InputBox, Form, Row, ButtonControl, SwitchFormGroup, PurpleButton, PrimaryButton, ButtonControlModal } from './style';

const PetEditForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, name, sex, neutered, weight, birthdateApproximate, birthdate, breed, furSigns, furSize, furColor } = useSelector((state) => state.Animals.actualPet) || {};
  const { createUpdatePet } = useSelector((state) => state.Animals.loading);
  const [idFromRedux] = useState(id);
  const [oldSpecies] = useState(breed ? breed.species : '');
  const [photoBase64, setPhotoBase64] = useState('');
  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [breedName, setBreedName] = useState(breed ? breed.name : '');
  const [breedPlaceholder, setBreedPlaceholder] = useState('Digite o nome da raça');
  const [breedOptions, setBreedOptions] = useState([]);
  const [species, setSpecies] = useState(breed ? breed.species : '');
  const [submitedStatus, setSubmitedStatus] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);
  const screen = useWindowSize();
  const [openModalInfoSpecies, setOpenModalInfoSpecies] = useState(false);
  const [payloadPet, setPayloadPet] = useState(null);
  const [openModalColors, setOpenModalColors] = useState(false);

  const [initialValues] = useState({
    id,
    name,
    sex,
    neutered,
    weight: weight ? parseFloat(weight).toFixed(3).toString().padStart(6, '0')
      .padEnd(5, '0') : null,
    species: breed ? breed.species : '',
    breed: breed ? { label: breed.name, id: breed.id } : null,
    birthdate: birthdate || null,
    birthdateApproximate,
    furColor: furColor || [],
    furSize: furSize || '',
    furSigns: furSigns || '',
  });

  const fetchBreed = async (name, newSpecie = null) => {
    setBreedOptions([]);
    const response = await dispatch.Animals.getBreedListSpecies({ species: newSpecie || species, name });

    if (response && response.data.length) {
      const parsedOptions = response.data.map((breed) => ({ label: breed.name, id: breed.id }));

      setBreedOptions([...new Set(parsedOptions)]);
    } else {
      setBreedOptions([]);
    }
  };

  useEffect(() => {
    if (species) {
      fetchBreed(breedName);
    }
  }, []);

  const handleConfirm = async (formData, { setErrors }) => {
    setIsSubmiting(true);

    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });

      const { furColor, furSize, furSigns } = formData;

      const payload = {
        id: formData.id,
        name: formData.name,
        sex: formData.sex,
        neutered: formData.neutered,
      };

      if (formData?.weight) {
        const weightValue = Number(String(formData.weight).replace(',', '.'));
        Object.assign(payload, { weight: weightValue });
      }
      if (formData.breed) { Object.assign(payload, { breedId: formData.breed.id }); }
      if (formData.birthdate) { Object.assign(payload, { birthdate: moment(formData.birthdate).format('YYYY-MM-DD') }); }
      if (formData.birthdateApproximate) { Object.assign(payload, { birthdateApproximate: formData.birthdateApproximate }); }
      if (photoBase64) { Object.assign(payload, { photoBase64 }); }
      if (furSize) { Object.assign(payload, { furSize }); }
      if (furColor) { Object.assign(payload, { furColor }); }
      if (furSigns) { Object.assign(payload, { furSigns }); }

      if (!!oldSpecies && formData?.species?.toLowerCase() !== oldSpecies?.toLowerCase()) {
        setIsSubmiting(false);
        setPayloadPet(payload);
        setOpenModalInfoSpecies(true);
        return;
      }

      const request = await dispatch.Animals.createUpdatePet(payload);

      if (request && request.data) {
        setSubmitedStatus('success');
        history.push(`/pet/${idFromRedux}`);
      }
    } else {
      setIsSubmiting(false);
      setSubmitedStatus('error');
      setErrors({ photoName: true });
    }
  };

  const handleSubmitEditPet = async () => {
    setOpenModalInfoSpecies(false);
    setIsSubmiting(true);
    try {
      const request = await dispatch.Animals.createUpdatePet(payloadPet);

      if (request && request.data) {
        setSubmitedStatus('success');
        setIsSubmiting(false);
        history.push(`/pet/${idFromRedux}`);
      }
      setIsSubmiting(false);
    } catch (err) {
      setIsSubmiting(false);
    }
  };

  const handleUpload = () => document.getElementById('imgupload').click();

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;

    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');

      const base64 = await convertBase64(rawFile);
      setPhotoBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);

      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setPhotoBase64(base64);
      }
    }
  };

  const handleSpeciesChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('species', value);
    setFieldValue('furSize', null);
    setFieldValue('furColor', []);
    setFieldValue('breed', '');
    setSpecies(value);
    fetchBreed('', value);
  };

  const handleChangeFurSize = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('furSize', value);
  };

  const handleColorsChange = (event, setFieldValue) => {
    const { value } = event.target;
    const parseValue = typeof value === 'string' ? value.split(',') : value;
    setFieldValue('furColor', parseValue);
    if (parseValue?.length === 3) setOpenModalColors(false);
  };

  const handleBreedOptionChange = (event, setFieldValue) => {
    if (event) {
      setBreedName(event.label);
      setFieldValue('breed', event);
    }
  };

  const handleBreedTextChange = (event, setFieldValue) => {
    const { value } = event.target;
    setBreedName(value);
    setFieldValue('breed', value);
    fetchBreed(value);
  };

  const handleSwitch = (field, event, setFieldValue) => {
    const { checked } = event.target;
    setFieldValue(field, checked);
  };

  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: screen.width < 739 ? 300 : 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <StyledPetFormCard elevation={0} sx={{ width: '315px', height: '200px' }}>
      <Modal
        open={openModalInfoSpecies}
        onClose={() => setOpenModalInfoSpecies(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ width: '100vw' }}
      >
        <div>
          <Box sx={styleModal}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Atenção
            </Typography>

            <Typography id='modal-modal-description' sx={{ my: 4, textAlign: 'center' }}>
              Ao alterar a espécie do pet, todos os protocolos cadastrados serão perdidos, deseja continuar?
            </Typography>

            <ButtonControlModal>
              <PurpleButton onClick={() => setOpenModalInfoSpecies(false)}>Não</PurpleButton>
              <PrimaryButton variant='outlined' onClick={handleSubmitEditPet}>
                Sim
              </PrimaryButton>
            </ButtonControlModal>
          </Box>
        </div>
      </Modal>
      <Typography variant='h6'>Perfil do pet</Typography>
      <InputBox>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={handleConfirm}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().required('Favor digitar o nome do pet').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
              sex: Yup.string().required('Favor selecionar o sexo do pet'),
              weight: Yup.number().nullable(true)
                .max(99.999, 'O valor máximo permitido é 99.999kg')
                .min(0.001, 'O valor mínimo permitido é 0.001kg')
                .transform((_, value) => (value !== null && value !== undefined ? Number(String(value).replace(',', '.')) : null))
                .test(
                  'is-decimal',
                  'invalid decimal',
                  (value) => !value || String(value).replace(',', '.').match(/[-+]?[0-9]*[.]?[0-9]+/),
                ),
              species: Yup.string().required('Favor selecionar a espécie do pet'),
              breed: Yup.mixed().required('Favor preencher a raça do pet'),
              furSize: Yup.string().nullable(),
              furColor: Yup.array().of(Yup.string()),
              furSigns: Yup.string().nullable(),
            })
          }
        >
          {({ handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue }) => (
            <Form onSubmit={handleSubmit} id='formPetEdit'>
              <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => uploadImage(e)} style={{ display: 'none' }} />
              <TextField
                fullWidth
                value={filename}
                error={!!errors.photoName}
                touched={touched.photoName}
                onClick={handleUpload}
                helperText='Imagens de até 20MB'
                sx={{ marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                id='photoName'
                name='photoName'
                label='Imagem'
                InputProps={{
                  readOnly: true,
                  endAdornment:
  <IconButton edge='end'>
    <AttachFile />
  </IconButton>,
                }}
              />
              <TextField
                fullWidth
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name && errors.name}
                touched={touched.name}
                sx={{ marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                autoComplete='off'
                label='Nome'
                id='name'
                name='name'
                disableUnderline
                inputProps={{ maxLength: 20 }}
              />

              <Row>
                <FormControl error={!!errors.sex} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <InputLabel id='demo-simple-select-helper-label'>Sexo</InputLabel>
                  <Select
                    defaultValue=''
                    id='sex'
                    name='sex'
                    label='Sexo'
                    value={values.sex}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.sex}
                  >
                    <MenuItem disabled value=''>Sexo</MenuItem>
                    <MenuItem value='M'>Macho</MenuItem>
                    <MenuItem value='F'>Fêmea</MenuItem>
                  </Select>
                  <FormHelperText>{errors.sex && errors.sex}</FormHelperText>
                </FormControl>

                <SwitchFormGroup>
                  <FormControlLabel
                    control={(
                      <Switch
                        id='neutered'
                        name='neutered'
                        checked={values.neutered}
                        onChange={(value) => handleSwitch('neutered', value, setFieldValue)}
                      />
                    )}
                    label='Castrado'
                  />
                </SwitchFormGroup>
              </Row>

              <Row>
                <FormControl error={!!errors.species} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <InputLabel id='demo-simple-select-helper-label'>Espécie</InputLabel>
                  <Select
                    defaultValue=''
                    id='species'
                    name='species'
                    label='Espécie'
                    value={values.species}
                    onChange={(event) => handleSpeciesChange(event, setFieldValue)}
                    onBlur={handleBlur}
                    touched={touched.species}
                  >
                    <MenuItem disabled value=''>Espécie</MenuItem>
                    <MenuItem value='DOG'>Cão</MenuItem>
                    <MenuItem value='CAT'>Gato</MenuItem>
                  </Select>
                  <FormHelperText>{errors.species && errors.species}</FormHelperText>
                </FormControl>
              </Row>

              <Autocomplete
                blurOnSelect='touch'
                isOptionEqualToValue={(option, value) => option.label === value.label}
                disablePortal
                name='breed'
                value={values.breed}
                onFocus={() => setBreedPlaceholder('Raça')}
                disableClearable
                onBlur={() => !values.breed && setBreedPlaceholder('Digite o nome da raça')}
                error={!!errors.breed}
                disabled={!species}
                freeSolo
                onChange={(e, value) => handleBreedOptionChange(value, setFieldValue)}
                options={breedOptions}
                sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                renderInput={(params) => (
                  <TextField
                    id='breed'
                    name='breed'
                    error={!!errors.breed}
                    onChange={(event) => handleBreedTextChange(event, setFieldValue)}
                    helperText={errors.breed && errors.breed}
                    label={breedPlaceholder}
                    {...params}
                  />
                )}
              />
              {/* <Autocomplete
                blurOnSelect='touch'
                isOptionEqualToValue={(option, value) => option.label === value.label}
                disablePortal
                name='breed'
                disabled={!species}
                onFocus={() => setBreedPlaceholder('Raça')}
                onBlur={() => !values.breed && setBreedPlaceholder('Digite o nome da raça')}
                error={!!errors.breed}
                openOnFocus
                freeSolo
                onChange={(e, value) => handleBreedOptionChange(value, setFieldValue)}
                options={breedOptions}
                sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                renderInput={(params) => (
                  <TextField
                    id='breed'
                    name='breed'
                    error={!!errors.breed}
                    onChange={(event) => handleBreedTextChange(event, setFieldValue)}
                    label={breedPlaceholder}
                    {...params}
                  />
                )}
              /> */}

              <Row>
                <DatePicker
                  openTo='year'
                  open={openDatePicker}
                  id='birthdate'
                  name='birthdate'
                  minDate={moment('1990/01/01')}
                  maxDate={moment()}
                  label='Data de nascimento'
                  allowSameDateSelection
                  defaultValue={null}
                  value={values.birthdate}
                  onChange={(value) => setFieldValue('birthdate', value)}
                  onClose={() => setOpenDatePicker(false)}
                  format='DD/MM/YYYY'
                  views={['year', 'month', 'day']}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      id='birthdate'
                      name='birthdate'
                      onClick={() => setOpenDatePicker(true)}
                      {...params}
                    />
                  )}
                />

              </Row>
              <FormHelperText>
                Se você não souber a data exata, marque a opção aproximada
              </FormHelperText>

              <SwitchFormGroup style={{ marginLeft: '0px', marginRight: 'auto' }}>
                <FormControlLabel
                  control={(
                    <Switch
                      id='birthdateApproximate'
                      name='birthdateApproximate'
                      checked={values.birthdateApproximate}
                      onChange={(value) => handleSwitch('birthdateApproximate', value, setFieldValue)}
                    />
                  )}
                  label='Aproximada'
                />
              </SwitchFormGroup>

              <Divider sx={{ marginTop: '30px', marginBottom: '20px' }} />

              <Typography variant='subtitle1' color={theme.title}>Dados de saúde</Typography>

              <InputMask mask='99,999' maskChar='0' onBlur={handleBlur} value={values.weight} onChange={handleChange}>
                {(inputProps) => (
                  <TextField
                    label='Peso atual (kg)'
                    placeholder='00,000'
                    type='text'
                    id='weight'
                    name='weight'
                    touched={String(touched.weight)}
                    InputLabelProps={{ shrink: true }}
                    autoComplete='new-password'
                    sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                    {...inputProps}
                    error={!!errors.weight}
                    helperText={errors.weight}
                  />
                )}
              </InputMask>

              <Divider sx={{ marginTop: '30px', marginBottom: '20px' }} />

              <Typography variant='subtitle1' color={theme.title}>Pelagem</Typography>

              <Row>
                <FormControl error={!!errors.furSize} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <InputLabel>Tamanho e aspecto dos fios</InputLabel>
                  <Select
                    id='furSize'
                    name='furSize'
                    value={values.furSize}
                    onChange={(event) => handleChangeFurSize(event, setFieldValue)}
                    onBlur={handleBlur}
                    input={<OutlinedInput label='Tamanho e aspecto dos fios' />}
                    MenuProps={{
                      PaperProps: { sx: { bottom: '100px !important' } },
                    }}
                  >
                    {values?.species && furSizesUtils[values.species]?.map((name) => (
                      <MenuItem key={name} value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors?.furSize && errors?.furSize}</FormHelperText>
                </FormControl>
              </Row>

              <Row>
                <FormControl error={!!errors.furColor} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <InputLabel>Cores</InputLabel>
                  <Select
                    labelId='demo-multiple-name-label'
                    id='furColor'
                    name='furColor'
                    multiple
                    value={values.furColor}
                    open={openModalColors}
                    onOpen={() => setOpenModalColors(true)}
                    onClose={() => setOpenModalColors(false)}
                    onChange={(event) => handleColorsChange(event, setFieldValue)}
                    renderValue={(selected) => selected?.join(', ')}
                    input={<OutlinedInput label='Cores' />}
                    MenuProps={{ PaperProps: { className: 'SelectPaddingBottom' } }}
                  >
                    {values.species && furColorsUtils[values.species].sort().map((name) => (
                      <MenuItem key={name} value={name} disabled={!(values?.furColor?.indexOf(name) > -1) && values?.furColor?.length >= 3}>
                        <Checkbox checked={values?.furColor?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.furColor && errors.furColor}</FormHelperText>
                </FormControl>
              </Row>

              <Row>
                <TextField
                  onChange={handleChange}
                  value={values.furSigns || ''}
                  error={!!errors.furSigns}
                  helperText={errors.furSigns && errors.furSigns}
                  touched={touched.furSigns || ''}
                  sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  autoComplete='off'
                  label='Sinais particulares da pelagem'
                  id='furSigns'
                  name='furSigns'
                />
              </Row>
              <FormHelperText>
                Ex.: bolinhas brancas, coração no nariz, escaminha
              </FormHelperText>

              <ButtonControl>
                {createUpdatePet === 'loading' || isSubmiting ? (
                  <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Loading />
                  </Grid>
                ) : (
                  <>
                    <PrimaryButton style={{ marginRight: '15px' }} type='submit'>Salvar</PrimaryButton>
                  </>
                )}

              </ButtonControl>
            </Form>
          )}

        </Formik>
      </InputBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Pet editado com sucesso
              </Alert>
            </div>

          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha na edição do pet
              </Alert>
            </div>
          )}

        </Snackbar>
      </Grid>
    </StyledPetFormCard>
  );
};

export default PetEditForm;
