import { Fade, Grid } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';
import Loading from '../../../global/Loading';
import VoucherProductCard from './VoucherProductCard';

const VoucherProductList = ({ vouchers, page, refetch }) => {
  const { promotionB2CList, updatePromotionB2CList } = useSelector((state) => state.Vouchers.loading);
  const isPromotionListLoading = promotionB2CList === 'loading';
  const isPromotionListBeingUpdated = updatePromotionB2CList === 'loading';
  const history = useHistory();
  const dispatch = useDispatch();
  const observer = useRef();

  const redirectToDetailPage = async (props) => {
    const { uuid, slug } = props;

    Mixpanel.track(MIXPANEL_TAGS.PRODUCT_BUY, { props });
    await dispatch.Vouchers.promotionB2CDetailUUID({ uuid, slug });
    history.push(`/vouchers/${uuid}/${slug}`);
  };

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          refetch();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [page],
  );

  if (isPromotionListLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container flexDirection='row' spacing={2} rowSpacing={5} columns={{ xs: 2, sm: 3, md: 4 }} sx={{ padding: '0px 16px', maxWidth: '1280px' }}>
      {vouchers.length ? (vouchers.map((props, index) => {
        const isElementToRefresh = vouchers.length === index + 3;

        return (
          <Fade in key={props.id}>
            <Grid
              key={props.id}
              ref={isElementToRefresh ? lastElementRef : undefined}
              onClick={() => redirectToDetailPage(props)}
              item
              xs={1}
              sm={1}
              md={1}
            >
              <VoucherProductCard {...props} />
            </Grid>
          </Fade>
        );
      })) : <></>}

      {isPromotionListBeingUpdated && (
        <Grid container justifyContent='center' sx={{ marginTop: '10px' }}>
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default VoucherProductList;
