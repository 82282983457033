/* eslint-disable no-unused-expressions */
import { Avatar, Fade, Card, Divider, Grid, Typography, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PrimaryButton } from '../../../../utils/theme';
import EventCancelDialog from './EventCancelDialog';
import { EventTitle, EventAsset, PartnerTitle } from './style';
// import TelemedicineClickToCall from '../../TelemedicineClickToCall';
import TelemedicineCallDialog from './TelemedicineCallDialog';
import { getParsedQueryString } from '../../../../utils/querystring';
import Loading from '../../../global/Loading';

const EventDetail = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [telemedicineModalOpen, setTelemedicineModalOpen] = useState(false);
  // const [isPossibleToCall, setIsPossibleToCall] = useState(false);
  const [event, setEvent] = useState({});
  // const [telemedicineCategory, setTelemedicineCategory] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const { detailSchedule } = useSelector((state) => state.Appointments.loading);
  const isLoading = detailSchedule !== 'fulfilled';
  let isPossibleToCallInterval;

  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(event.serviceType.service.user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!event.serviceType?.service?.user?.profileImage && fetchProfileImage();
  }, [event]);

  const redirectToLastPage = () => history.goBack();
  const redirectToSchedulePage = () => {
    if (event) {
      history.push(`/services/create/${event.serviceType?.service?.id}`);
    }
  };

  // eslint-disable-next-line consistent-return
  const origin = () => {
    const querystring = location.search.slice(1);

    if (!querystring) {
      return null;
    }

    const query = getParsedQueryString(location.search);

    return query.origin;
  };

  const [hasOrigin] = useState(origin());

  // const handleCallBanner = (startTime, endTime) => {
  //   const eventStart = moment(startTime).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-03:00]');
  //   const eventEnd = moment(endTime).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-03:00]');
  //   setIsPossibleToCall(moment().isBetween(eventStart, eventEnd));
  // };

  // const handleClickBanner = async () => {
  //   if (!isPossibleToCall) {
  //     setTelemedicineModalOpen(true);
  //   } else {
  //     const nuvidioDepartment = event.serviceType?.service?.user?.nuvidioDepartment;
  //     const telemedicineResponse = await dispatch.User.getUrlTelemedicineCallDepartment(nuvidioDepartment);

  //     if (telemedicineResponse && telemedicineResponse.data) {
  //       history.push(`/services/telemedicine-call?url=${telemedicineResponse.data.videoconferenceLink}&department=${nuvidioDepartment}`);
  //     }
  //   }
  // };

  useEffect(() => {
    const getSchedule = async () => {
      const schedule = await dispatch.Appointments.detailSchedule(params.id);

      if (schedule && schedule.data) {
        setEvent(schedule.data);

        // if (schedule.data?.serviceType?.service?.category === 'Guiavet Telemedicina') {
        //   setTelemedicineCategory(true);
        // }

        // const { eventStart, eventEnd } = schedule.data;
        // handleCallBanner(eventStart, eventEnd);

        // isPossibleToCallInterval = setInterval(() => {
        //   handleCallBanner(eventStart, eventEnd);
        // }, 5000);
      }
    };

    getSchedule();

    return () => {
      clearInterval(isPossibleToCallInterval);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ minHeight: '70vh' }}>
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Fade in>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              container
              direction='column'
              sx={{ padding: '0 24px', maxWidth: '1280px' }}
              gap={2}
            >
              <EventCancelDialog open={modalOpen} setModalOpen={setModalOpen} />
              <TelemedicineCallDialog open={telemedicineModalOpen} setModalOpen={setTelemedicineModalOpen} />
              <Grid onClick={redirectToLastPage} container direction='row' alignItems='center' sx={{ padding: '0px 0px 18px 0', marginLeft: '5px' }}>
                {!hasOrigin ? (
                  <>
                    <Grid item>
                      <ArrowBackIcon sx={{ color: '#6B48FF' }} />
                    </Grid>

                    <Grid item>
                      <Button size='large' sx={{ color: '#6B48FF', padding: '0px', marginRight: '5px' }}>Voltar</Button>
                    </Grid>
                  </>
                ) : <></>}

              </Grid>
              <Grid item>
                <Card elevation={0} sx={{ padding: '17px 16px' }}>
                  <Grid container wrap='nowrap' direction='row' gap={2}>
                    <Grid item>
                      <Avatar
                        sx={{ width: '80px', height: '80px' }}
                        src={event.serviceType?.service?.user?.profileImage && profileImgSrc}
                        alt={event.animal?.user?.firstName}
                      />
                    </Grid>

                    <Grid item>
                      <Grid container direction='column'>
                        <PartnerTitle onClick={redirectToSchedulePage}>{event.serviceType?.service?.user?.firstName || ''}</PartnerTitle>
                        <EventTitle>{event.serviceType?.service?.name || ''}</EventTitle>
                        <EventAsset sx={{ marginTop: '5px' }}>
                          <b>Animal:</b>
                          {' '}
                          {event.animal?.name || ''}
                        </EventAsset>
                        <EventAsset>
                          <b>Evento:</b>
                          {' '}
                          {event.serviceType?.name}
                        </EventAsset>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* <TelemedicineClickToCall handleCall={handleClickBanner} shortCard sx={{ display: telemedicineCategory ? 'flex' : 'none' }} /> */}

              <Grid item>
                <Card elevation={0} sx={{ padding: '17px 16px' }}>
                  <Grid container direction='column' alignItems='center' sx={{ width: '100%' }}>
                    <Typography variant='h6' color='#6B48FE'>
                      {`${moment(event.eventStart).utc().format('HH:mm')} - ${moment(event.eventEnd).utc().format('HH:mm')}`}

                    </Typography>
                    <Typography variant='body2'>{moment(event.eventStart).format('DD [de] MMMM [|] dddd')}</Typography>

                    <Grid container gap={2} sx={{ margin: '10px 0' }}>
                      <Divider style={{ width: '100%' }} />

                      <Grid item>
                        <Typography variant='body2'>{event.serviceType?.service?.description}</Typography>
                      </Grid>

                      <Divider style={{ width: '100%' }} />

                      <Grid item>
                        <Typography variant='body2'>{event.serviceType?.service?.address?.street || ''}</Typography>
                      </Grid>

                      <Divider style={{ width: '100%' }} />
                    </Grid>

                    <PrimaryButton onClick={() => setModalOpen(true)} style={{ width: '100%' }}>Cancelar</PrimaryButton>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  );
};

export default EventDetail;
