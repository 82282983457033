/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Avatar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { UserDetails } from './style';

const CardProfile = () => {
  const dispatch = useDispatch();
  const { profileImage, firstName, lastName } = useSelector((state) => state.User.userData);

  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!profileImage && fetchProfileImage();
  }, [profileImage]);

  return (
    <Card elevation={0}>
      <CardContent>
        <UserDetails>
          <Avatar src={profileImage && profileImgSrc} alt={firstName} sx={{ minWidth: '100px', minHeight: '100px' }} />
          <span className='username'>
            {`${firstName} ${lastName}`}
          </span>
        </UserDetails>
      </CardContent>
    </Card>
  );
};

export default CardProfile;
