import mixpanel from 'mixpanel-browser';

import { isNative } from './mobile';
import { getUtms } from '../services/api';

const sendData = process.env.REACT_APP_MIXPANEL_ON === 'TRUE';
mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, { track_pageview: true });
const isBusinessUrl = window.location.href.includes(process.env.REACT_APP_BUSINESS);

export const MIXPANEL_TAGS = {
  DASHBOARD_VIEW: 'DASHBOARD_VIEW',
  LOGIN_VIEW: 'LOGIN_VIEW',
  VALIDATE_CODE_ACCESS_VIEW: 'VALIDATE_CODE_ACCESS_VIEW',
  PET_CREATE_VIEW: 'PET_CREATE_VIEW',
  FOLLOWER_LIST_VIEW: 'FOLLOWER_LIST_VIEW',
  FOLLOWING_LIST_VIEW: 'FOLLOWING_LIST_VIEW',
  PAGE_VIEW: 'PAGE_VIEW',
  GAMIFICATION_LEVEL_VIEW: 'GAMIFICATION_LEVEL_VIEW',
  CREDENTIALED_SCREEN_VIEW: 'CREDENTIALED_SCREEN_VIEW',
  QOL_SCREEN_VIEW: 'QOL_SCREEN_VIEW',
  PRICING_VIEW: 'PRICING_VIEW',
  PETS_VIEW: 'PETS_VIEW',
  PETS_DETAILS_VIEW: 'PETS_DETAILS_VIEW',
  PROFILE_VIEW: 'PROFILE_VIEW',
  NOTIFICATIONS_VIEW: 'NOTIFICATIONS_VIEW',
  NOTIFICATIONS_CARD_CLICK: 'NOTIFICATIONS_CARD_CLICK',
  ANNOUNCEMENT_VIEW: 'ANNOUNCEMENT_VIEW',
  ANNOUNCEMENT_CARD_LOAD: 'ANNOUNCEMENT_CARD_LOAD',
  VIRTUAL_VACCINE_VIEW: 'VIRTUAL_VACCINE_VIEW',
  TELEMEDICINE_VIEW: 'TELEMEDICINE_VIEW',

  SIGNIN_GOOGLE: 'SIGNIN_GOOGLE',
  SIGNIN_GOOGLE_ONE_TAP: 'SIGNIN_GOOGLE_ONE_TAP',
  SIGNIN_FACEBOOK: 'SIGNIN_FACEBOOK',
  SIGNIN_APPLE: 'SIGNIN_APPLE',
  SIGNIN: 'SIGNIN',
  SENT_TOKEN: 'SENT_TOKEN',
  SIGNOUT: 'SIGNOUT',
  ADD_CLICK: 'ADD_CLICK',
  ICON_TOP_MENU: 'ICON_TOP_MENU',
  MESSAGE_TOP_MENU: 'MESSAGE_TOP_MENU',
  NOTIFICATION_MENU: 'NOTIFICATION_MENU',
  PROFILE_MENU: 'PROFILE_MENU',
  SUPPORT_MENU: 'SUPPORT_MENU',
  MANAGE_SUBSCRIPTION: 'MANAGE_SUBSCRIPTION',
  MENU_NETWORK: 'MENU_NETWORK',
  MENU_DISCOUNT: 'MENU_DISCOUNT',
  MENU_SERVICES: 'MENU_SERVICES',
  MENU_ORDERS: 'MENU_ORDERS',
  MENU_AGENDA: 'MENU_AGENDA',
  BUSINESS_INTELLIGENCE_MENU: 'BUSINESS_INTELLIGENCE_MENU',
  DASHBOARD_ASK_VET_CLICK: 'DASHBOARD_ASK_VET_CLICK',
  DASHBOARD_ASK_VET_LOAD: 'DASHBOARD_ASK_VET_LOAD',
  DASHBOARD_ASK_VACCINE_CLICK: 'DASHBOARD_ASK_VACCINE_CLICK',
  DASHBOARD_ASK_VACCINE_LOAD: 'DASHBOARD_ASK_VACCINE_LOAD',
  DASHBOARD_CREDENTIALED_CLICK: 'DASHBOARD_CREDENTIALED_CLICK',
  DASHBOARD_QUIZ_HEALTH_CLICK: 'DASHBOARD_QUIZ_HEALTH_CLICK',
  DASHBOARD_QUIZ_HEALTH_LOAD: 'DASHBOARD_QUIZ_HEALTH_LOAD',
  DASHBOARD_CREDENTIALED_LOAD: 'DASHBOARD_CREDENTIALED_LOAD',
  PLAN_CLICK: 'PLAN_CLICK',
  PRODUCT_BUY: 'PRODUCT_BUY',
  PRODUCT_QRCODE: 'PRODUCT_QRCODE',
  TRIAGE_BEGIN: 'TRIAGE_BEGIN',
  MENU_PET: 'MENU_PET',
  SERVICE_AGENDA_MESSAGE_CLICK: 'SERVICE_AGENDA_MESSAGE_CLICK',
  SERVICE_AGENDA_MESSAGE_LOAD: 'SERVICE_AGENDA_MESSAGE_LOAD',
  SERVICE_AGENDA_VIDEO_CLICK: 'SERVICE_AGENDA_VIDEO_CLICK',
  SERVICE_AGENDA_VIDEO_LOAD: 'SERVICE_AGENDA_VIDEO_LOAD',
  SERVICE_AGENDA_PROMOTION_CLICK: 'SERVICE_AGENDA_PROMOTION_CLICK',
  SERVICE_AGENDA_PROMOTION_LOAD: 'SERVICE_AGENDA_PROMOTION_LOAD',
  PROTOCOL_ANIMAL_NEW_CLICK: 'PROTOCOL_ANIMAL_NEW_CLICK',
  PROTOCOL_ANIMAL_EDIT_CLICK: 'PROTOCOL_ANIMAL_EDIT_CLICK',
  PROTOCOL_ANIMAL_RENEW_CLICK: 'PROTOCOL_ANIMAL_RENEW_CLICK',
  VIRTUAL_VACCINE_CARD_LOAD: 'VIRTUAL_VACCINE_CARD_LOAD',
  VIRTUAL_VACCINE_CARD_CLICK: 'VIRTUAL_VACCINE_CARD_CLICK',
  QUIZ_HEALTH_CARD_CLICK: 'VIRTUAL_VACCINE_CARD_CLICK',
  QUIZ_HEALTH_CARD_LOAD: 'VIRTUAL_VACCINE_CARD_LOAD',
  SEND_INVITE_MODAL_CLICK: 'SEND_INVITE_MODAL_CLICK',
  REPORT_CONTACT_CLIENT_CLICK: 'REPORT_CONTACT_CLIENT_CLICK',
  STATEMENT_MENU: 'STATEMENT_MENU',
  VOUCHER_REDEEM_MODAL_CLICK: 'VOUCHER_REDEEM_MODAL_CLICK',
  PET_EMPTY_CARD_CLICK: 'PET_EMPTY_CARD_CLICK',
  INVITE_LOGIN_CLICK: 'INVITE_LOGIN_CLICK',
  MENU_HOME: 'MENU_HOME',
  LANDING_PLAY_STORE_CLICK: 'LANDING_PLAY_STORE_CLICK',
  LANDING_APP_STORE_CLICK: 'LANDING_APP_STORE_CLICK',
  TAB_HOME_PRESS: 'TAB_HOME_PRESS',
  TAB_HOME_LOAD: 'TAB_HOME_LOAD',
  TAB_PETS_PRESS: 'TAB_PETS_PRESS',
  TAB_PETS_LOAD: 'TAB_PETS_LOAD',
  TAB_SERVICES_PRESS: 'TAB_SERVICES_PRESS',
  TAB_SERVICES_LOAD: 'TAB_SERVICES_LOAD',
  TAB_NETWORK_PRESS: 'TAB_NETWORK_PRESS',
  TAB_NETWORK_LOAD: 'TAB_NETWORK_LOAD',

  INVITE_MODAL_LOAD: 'INVITE_MODAL_LOAD',
  RESEND_INVITE_MODAL_LOAD: 'RESEND_INVITE_MODAL_LOAD',
  CANCEL_INVITE_MODAL_LOAD: 'CANCEL_INVITE_MODAL_LOAD',
  RECONNECT_MODAL_LOAD: 'RECONNECT_MODAL_LOAD',
  RESEND_INVITE_CLICK: 'RESEND_INVITE_CLICK',
  CANCEL_INVITE_CLICK: 'CANCEL_INVITE_CLICK',
  RECONNECT_CLICK: 'RECONNECT_CLICK',
  INVITE_SENT_SCHEDULED: 'INVITE_SENT_SCHEDULED',
  INVITE_SENT_NOW: 'INVITE_SENT_NOW',

  HEADER_LOGO_PRESS: 'HEADER_LOGO_PRESS',
  HEADER_CHAT_PRESS: 'HEADER_CHAT_PRESS',
  HEADER_NOTIFICATION_PRESS: 'HEADER_NOTIFICATION_PRESS',
  HEADER_AVATAR_PRESS: 'HEADER_AVATAR_PRESS',

  PROFILE_PRESS: 'PROFILE_PRESS',
  SUPPORT_PRESS: 'SUPPORT_PRESS',
  LOGOUT_PRESS: 'LOGOUT_PRESS',

  BANNER_TELL_VETS: 'BANNER_TELL_VETS',
  BANNER_VACCINE: 'BANNER_VACCINE',
  BANNER_CREDENTIALED: 'BANNER_CREDENTIALED',
  BANNER_QUIZ_HEALTH: 'BANNER_QUIZ_HEALTH',

  CREDENTIALED_CITY_PRESS: 'CREDENTIALED_CITY_PRESS',
  CREDENTIALED_FILTER_PRESS: 'CREDENTIALED_FILTER_PRESS',
  CREDENTIALED_CARD_PRESS: 'CREDENTIALED_CARD_PRESS',
  CREDENTIALED_PHONE_PRESS: 'CREDENTIALED_PHONE_PRESS',
  CREDENTIALED_EMAIL_PRESS: 'CREDENTIALED_EMAIL_PRESS',
  CREDENTIALED_WHATSAPP_PRESS: 'CREDENTIALED_EMAIL_PRESS',
  CREDENTIALED_CATEGORY_PRESS: 'CREDENTIALED_CATEGORY_PRESS',
  CREDENTIALED_SUBCATEGORY_PRESS: 'CREDENTIALED_SUBCATEGORY_PRESS',

  QOL_CALCULATION_TAB: 'QOL_CALCULATION_TAB',
  QOL_RESULTS_TAB: 'QOL_RESULTS_TAB',
  QOL_REGISTER_PET: 'QOL_REGISTER_PET',
  QOL_OPEN_PET_MODAL: 'QOL_OPEN_PET_MODAL',
  QOL_CLOSE_PET_MODAL: 'QOL_CLOSE_PET_MODAL',
  QOL_START_QUIZ: 'QOL_START_QUIZ',
  QOL_QUIT_QUIZ: 'QOL_QUIT_QUIZ',
  QOL_NEXT_QUESTION: 'QOL_NEXT_QUESTION',
  QOL_PREVIOUS_QUESTION: 'QOL_PREVIOUS_QUESTION',
  QOL_LEAVE_QUIZ: 'QOL_LEAVE_QUIZ',
  QOL_SUBMIT_QUIZ: 'QOL_SUBMIT_QUIZ',
  QOL_SHOW_RESULT: 'QOL_SHOW_RESULT',
  QOL_CLOSE_RESULT: 'QOL_CLOSE_RESULT',

  NETWORK_VIEW: 'NETWORK_VIEW',
  BUSINESS_VIEW: 'BUSINESS_VIEW',
  VOUCHER_VIEW: 'VOUCHER_VIEW',
  VOUCHER_DETAIL_VIEW: 'VOUCHER_DETAIL_VIEW',
  ORDERS_VIEW: 'ORDERS_VIEW',
  VOUCHER_CHECKOUT_VIEW: 'VOUCHER_CHECKOUT_VIEW',
  STATEMENTS_VIEW: 'STATEMENTS_VIEW',
  SERVICES_VIEW: 'SERVICES_VIEW',
  EVENT_DETAIL_VIEW: 'EVENT_DETAIL_VIEW',
  EVENT_PARTNERS_VIEW: 'EVENT_PARTNERS_VIEW',
  EVENT_CREATE_VIEW: 'EVENT_CREATE_VIEW',
  TELEMEDICINE_CALL_VIEW: 'TELEMEDICINE_CALL_VIEW',
  APPOINTMENTS_VIEW: 'APPOINTMENTS_VIEW',
  APPOINTMENT_SCHEDULE_VIEW: 'APPOINTMENT_SCHEDULE_VIEW',
  APPOINTMENT_EDIT_CREATE_VIEW: 'APPOINTMENT_EDIT_CREATE_VIEW',
  APPOINTMENT_DETAIL_VIEW: 'APPOINTMENT_DETAIL_VIEW',
  PET_EDIT_VIEW: 'PET_EDIT_VIEW',
  PUBLIC_SERVICES_VIEW: 'PUBLIC_SERVICES_VIEW',
  PUBLIC_SERVICES_SCHEDULE_VIEW: 'PUBLIC_SERVICES_SCHEDULE_VIEW',
  LOGIN_CALLBACK_FACEBOOK_VIEW: 'LOGIN_CALLBACK_FACEBOOK_VIEW',
  CHECK_REDIRECT_VIEW: 'CHECK_REDIRECT_VIEW',
  REDIRECT_SHORT_VIEW: 'REDIRECT_SHORT_VIEW',
  LANDING_VIEW: 'LANDING_VIEW',
  MODAL_APP_UPDATE_AVAILABLE_VIEW: 'MODAL_APP_UPDATE_AVAILABLE_VIEW',
  MODAL_APP_UPDATE_AVAILABLE_REDIRECT_CLICK: 'MODAL_APP_UPDATE_AVAILABLE_REDIRECT_CLICK',
};

const actions = {
  reset: () => {
    if (sendData) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
          type: 'RESET',
          data: {},
        } }));
      } else {
        if (!isBusinessUrl) {
          return;
        }
        mixpanel.reset();
        window?._cio.reset();
      }
    }
  },
  identify: (id) => {
    if (sendData) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
          type: 'IDENTIFY',
          data: { id },
        } }));
      } else {
        if (!isBusinessUrl) {
          return;
        }
        mixpanel.identify(id);
        window?._cio.identify({ id });
      }
    }
  },
  alias: (id) => {
    if (sendData) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
          type: 'ALIAS',
          data: { id },
        } }));
      }
    } else {
      if (!isBusinessUrl) {
        return;
      }
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    const utms = getUtms();
    const parsedUtms = JSON.parse(utms);
    const userData = localStorage.getItem('userData');
    const parsedUserData = JSON.parse(userData);

    if (sendData) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
          type: 'TRACK',
          eventTrackName: name,
          data: {
            name,
            ...parsedUtms,
            ...(userData && { distinct_id: parsedUserData?.id }),
            ...props,
          },
        } }));
      } else {
        if (!isBusinessUrl && name === MIXPANEL_TAGS.LANDING_VIEW) {
          mixpanel.track(MIXPANEL_TAGS.LANDING_VIEW, {
            ...parsedUtms,
            ...(userData && { distinct_id: parsedUserData?.id }),
            ...props,
          });
          return;
        }
        if (!isBusinessUrl) {
          return;
        }
        mixpanel.track(name, {
          ...parsedUtms,
          ...(userData && { distinct_id: parsedUserData?.id }),
          ...props,
        });

        window?._cio.track(name, {
          ...parsedUtms,
          ...(userData && { distinct_id: parsedUserData?.id }),
          ...props,
        });

        if (name.includes('_VIEW')) {
          window._cio.page(name, {
            ...parsedUtms,
            ...(userData && { distinct_id: parsedUserData?.id }),
            ...props,
          });
        }
      }
    }
  },
  register: (props) => {
    if (sendData) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
          type: 'REGISTER',
          data: { props },
        } }));
      }
    } else {
      if (!isBusinessUrl) {
        return;
      }
      mixpanel.register(props);
    }
  },
  people: {
    set: (props) => {
      if (sendData) {
        if (isNative) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ appUserTracker: {
            type: 'PEOPLE_SET',
            data: { props },
          } }));
        } else {
          mixpanel.people.set(props);
        }
      }
    },
  },
};

export const Mixpanel = actions;
