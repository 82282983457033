import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import Loading from '../../../global/Loading';
import { isMobile } from '../../../../utils/mobile';

const ServiceDeleteConfirmation = ({ open, setModalOpen }) => {
  const history = useHistory();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();

  const showError = (error, type) => {
    dispatch.Appointments.setModal({ appointmentErrorModal: { open: true, data: { error, type } } });
  };

  const deleteService = async () => {
    setDeleteLoading(true);

    const deleteId = await dispatch.Appointments.deleteService(params.id);

    if (deleteId && deleteId.data) {
      const fulfilledSchedules = deleteId.data.deleted && deleteId.data.deleted.length && deleteId.data.deleted.includes(params.id);

      if (!fulfilledSchedules) {
        showError('Você ainda possui agendamentos pendentes nesta agenda. Favor cancelá-los antes de remover sua agenda', 'deletar');
      } else {
        history.push('/appointments');
      }
    } else if (deleteId.response.data.reason) {
      showError(deleteId.response.data.reason, 'deletar');
    } else {
      showError('Você ainda possui agendamentos pendentes nesta agenda. Favor cancelá-los antes de remover sua agenda', 'deletar');
    }

    setTimeout(() => setModalOpen(false), 500);
    setDeleteLoading(false);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '350px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row'>
          <Grid item>
            Confirmar deleção de agenda
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Tem certeza que deseja cancelar esta agenda?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: '#6B48FE' }}
          onClick={() => setModalOpen(false)}
        >
          Cancelar
        </Button>

        {deleteLoading ? (
          <Grid direction='row'>
            <Grid item>
              <Loading size={30} sx={{ marginLeft: '15px' }} />
            </Grid>
          </Grid>
        ) : (
          <Button
            sx={{ color: '#8e0404' }}
            onClick={deleteService}
          >
            Deletar
          </Button>
        )}

      </DialogActions>
    </Dialog>
  );
};

export default ServiceDeleteConfirmation;
