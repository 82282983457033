export const datagridLocale = {
  // Root
  noRowsLabel: 'Sem dados',
  noResultsOverlayLabel: 'Nenhum resultado foi encontrado.',
  errorOverlayDefaultLabel: 'Um erro ocorreu.',

  // Density selector toolbar button text
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortável',

  // Columns selector toolbar button text
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar colunas',

  // Filters toolbar button text
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Esconder filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} filtros ativo` : `${count} filtro ativo`),

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Pesquisar...',
  toolbarQuickFilterLabel: 'Pesquisar',
  toolbarQuickFilterDeleteIconLabel: 'Limpar',

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Baixar em CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarExportExcel: 'Baixar como Excel',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Achar coluna',
  columnsPanelTextFieldPlaceholder: 'Título da coluna',
  columnsPanelDragIconLabel: 'Reordenar coluna',
  columnsPanelShowAllButton: 'Mostrar todos',
  columnsPanelHideAllButton: 'Esconder todos',

  // Filter panel text
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Deletar',
  filterPanelLinkOperator: 'Operador lógico',
  filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Filtrar valor',

  // Filter operators text
  filterOperatorContains: 'contém',
  filterOperatorEquals: 'igual',
  filterOperatorStartsWith: 'começa com',
  filterOperatorEndsWith: 'termina com',
  filterOperatorIs: 'é',
  filterOperatorNot: 'não é',
  filterOperatorAfter: 'é depois',
  filterOperatorOnOrAfter: 'está em ou depois de',
  filterOperatorBefore: 'é antes',
  filterOperatorOnOrBefore: 'está em ou antes de',
  filterOperatorIsEmpty: 'é vazio',
  filterOperatorIsNotEmpty: 'não é vazio',
  filterOperatorIsAnyOf: 'is any of',

  // Filter values text
  filterValueAny: 'qualquer',
  filterValueTrue: 'verdadeiro',
  filterValueFalse: 'falso',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar colunas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Esconder',
  columnMenuUnsort: 'Unsort',
  columnMenuSortAsc: 'Ordenar ASC',
  columnMenuSortDesc: 'Ordenar DESC',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} active filters` : `${count} active filter`),
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) => (count !== 1
    ? `${count.toLocaleString()} linhas selecionadas`
    : `${count.toLocaleString()} linha selecionada`),

  // Total row amount footer text
  footerTotalRows: 'Total de linhas:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Seleção de checkbox',
  checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
  checkboxSelectionUnselectAllRows: 'Deselecionar todas as linhas',
  checkboxSelectionSelectRow: 'Selecionar linha',
  checkboxSelectionUnselectRow: 'Deselecionar linha',

  // Boolean cell text
  booleanCellTrueLabel: 'sim',
  booleanCellFalseLabel: 'não',

  // Actions cell more text
  actionsCellMore: 'mais',

  // Column pinning text
  pinToLeft: 'Anexar à esquerda',
  pinToRight: 'Anexar à direita',
  unpin: 'Desanexar',

  // Tree Data
  treeDataGroupingHeaderName: 'Agrupar',
  treeDataExpand: 'ver filhos',
  treeDataCollapse: 'esconder filhos',

  // Grouping columns
  groupingColumnHeaderName: 'Agrupar',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Parar de agrupar por ${name}`,

  // Master/detail
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Recolher',

  MuiTablePagination: {},
  rowReorderingHeaderName: 'Reordenaçã de linha',
};
