import styled from 'styled-components';
import { Button, CardContent } from '@mui/material';

import theme from '../../../utils/theme';

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 5px solid ${theme.primaryButton};
  }

  &.MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;

export const PlanDetails = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  justify-content: center;
  padding: 8px 0;
  width: 100%;
`;

export const PlanTypeControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  @media screen and (max-width: 960px) {
    justify-content: center;
  }
`;

export const PlanType = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: ${theme.primaryButton};
  margin-left: 8px;

  @media screen and (max-width: 360px) {
    font-size: 16px;
  }
`;

export const PlanCorporateStatusLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #FFB931;
`;

export const PlanPrice = styled.h2`
  font-weight: 400;
  font-size: 22px;
  line-height: 133.4%;
  color: #0D3F67;

  @media screen and (max-width: 360px) {
    font-size: 18px;
  }
`;

export const InfoControl = styled.div`
  display: flex;
  justify-content: flex-end;

  h6 {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.5px;

    color: #7E8192;
  }
`;

export const PrimaryButton = styled(Button)`
  &.MuiButton-root {
    border-radius: 20px;
    border-color: ${theme.primaryButton};
    color: ${theme.primaryButton};

    &:hover {
      background-color: #6b48ff10;
      border-color: ${theme.primaryButton};
      color: ${theme.primaryButton};
    }
  }
`;
