/* eslint-disable no-console */
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const Appointments = {
  state: {
    actualService: {
      unavailabilityTemp: [
        {
          period_start: '',
          period_end: '',
        },
      ],
    },
    actualServiceBase: {
      id: '',
      name: '',
      description: '',
      category: '',
      periodStart: null,
      serviceTypes: [{ id: -1, name: '', serviceDurationMinutes: 30 }],
      address: {},
      timeBetweenServicesMinutes: 0,
      availability: [
        {
          weekday: 0,
          range: [
            {
              period_start: '09:00',
              period_end: '12:00',
            },
            {
              period_start: '13:00',
              period_end: '17:00',
            }],
        },
        {
          weekday: 1,
          range: [
            {
              period_start: '09:00',
              period_end: '12:00',
            },
            {
              period_start: '13:00',
              period_end: '17:00',
            }],
        },
        {
          weekday: 2,
          range: [
            {
              period_start: '09:00',
              period_end: '12:00',
            },
            {
              period_start: '13:00',
              period_end: '17:00',
            }],
        },
        {
          weekday: 3,
          range: [
            {
              period_start: '09:00',
              period_end: '12:00',
            },
            {
              period_start: '13:00',
              period_end: '17:00',
            }],
        },
        {
          weekday: 4,
          range: [
            {
              period_start: '09:00',
              period_end: '12:00',
            },
            {
              period_start: '13:00',
              period_end: '17:00',
            }],
        },
        {
          weekday: 5,
          range: [],
        },
        {
          weekday: 6,
          range: [],
        },
      ],
      unavailability: [],
      unavailabilityTemp: [
        {
          period_start: '',
          period_end: '',
        },
      ],
    },
    myServiceList: [],
    myServiceListCount: '',
    scheduleAnimalList: [],
    listConnectedServices: [],
    listAllSchedulesB2C: [],
    lastCreatedSchedule: {},
    monthScheduledB2BList: [],
    dayScheduledB2BList: [],
    dayScheduledB2CList: [],
    scheduleDetail: {},
    b2cCalendarAvailability: [],
    b2bCalendarAvailability: [],
    actualPublicService: {},
    publicSchedule: {},
    publicListScheduleB2CToB2B: [],
    publicServices: [],
    publicB2CCalendarAvailability: [],
    serviceCanBeSchedule: false,
    modal: {
      calendarModal: {
        open: false,
        context: 'unavailable',
        data: {},
      },
      attendanceModal: {
        open: false,
        data: {},
      },
      scheduleModal: {
        open: false,
        data: {},
        context: 'schedule',
      },
      appointmentErrorModal: {
        open: false,
        data: {},
      },
    },
    loading: {
      serviceDetail: '',
      createService: '',
      updateService: '',
      myServiceList: '',
      myServiceListCount: '',
      getAnimalsListToSchedule: '',
      monthScheduledB2BList: '',
      dayScheduledB2BList: '',
      dayScheduledB2CList: '',
      listConnectedServices: '',
      getB2CCalendarAvailability: '',
      getB2BCalendarAvailability: '',
      detailSchedule: '',
      publicListScheduleB2CToB2B: '',
      publicServices: '',
      publicB2CCalendarAvailability: '',
    },
  },
  reducers: {
    SET_SCHEDULE_ANIMAL_LIST: (state, payload) => ({ ...state, scheduleAnimalList: payload }),
    SET_MONTH_SCHEDULE_B2B_LIST: (state, payload) => ({ ...state, monthScheduledB2BList: payload }),
    SET_DAY_SCHEDULE_B2B_LIST: (state, payload) => ({ ...state, dayScheduledB2BList: payload }),
    ADD_SERVICE_TYPE: (state, payload) => ({ ...state, actualService: { ...state.actualService, serviceTypes: [...state.actualService.serviceTypes, payload] } }),
    DELETE_SERVICE_TYPE: (state, payload) => ({ ...state, actualService: { ...state.actualService, serviceTypes: payload } }),
    ADD_UNAVAILABILITY_TEMP: (state, payload) => ({ ...state, actualService: { ...state.actualService, unavailabilityTemp: [...state.actualService.unavailabilityTemp, payload] } }),
    ADD_UNAVAILABILITY: (state, payload) => ({ ...state, actualService: { ...state.actualService, unavailability: [...state.actualService.unavailabilityTemp, payload] } }),
    DELETE_UNAVAILABILITY: (state, payload) => ({ ...state, actualService: { ...state.actualService, unavailability: payload } }),
    DELETE_UNAVAILABILITY_TEMP: (state, payload) => ({ ...state, actualService: { ...state.actualService, unavailabilityTemp: payload } }),
    CLEAR_ACTUAL_SERVICE: (state, payload) => ({ ...state, actualService: payload }),
    SET_ACTUAL_SERVICE: (state, payload) => ({ ...state, actualService: Object.assign(state.actualService, payload) }),
    SET_SERVICE_LIST: (state, payload) => ({ ...state, myServiceList: payload }),
    SET_SERVICE_LIST_COUNT: (state, payload) => ({ ...state, myServiceListCount: payload }),
    SET_LIST_CONNECTED_SERVICES: (state, payload) => ({ ...state, listConnectedServices: payload }),
    SET_DAY_SCHEDULE_B2C_LIST: (state, payload) => ({ ...state, dayScheduledB2CList: payload }),
    SET_ALL_DAY_SCHEDULES_B2C_LIST: (state, payload) => ({ ...state, listAllSchedulesB2C: payload }),
    SET_SCHEDULE_AVAILABILITY: (state, payload) => ({ ...state, serviceCanBeSchedule: payload }),
    SET_SCHEDULE_DETAIL: (state, payload) => ({ ...state, scheduleDetail: payload }),
    SET_B2B_CALENDAR_AVAILABILITY: (state, payload) => ({ ...state, b2bCalendarAvailability: payload }),
    SET_CALENDAR_AVAILABILITY: (state, payload) => ({ ...state, b2cCalendarAvailability: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
    SET_MODAL: (state, payload) => ({ ...state, modal: { ...state.modal, ...payload } }),
    SET_AVAILABILITY: (state, payload) => ({ ...state, actualService: { ...state.actualService, availability: payload } }),
    SET_PUBLIC_SERVICES: (state, payload) => ({ ...state, publicServices: payload }),
    SET_ACTUAL_PUBLIC_SERVICE: (state, payload) => ({ ...state, actualPublicService: payload }),
    SET_PUBLIC_SCHEDULE: (state, payload) => ({ ...state, publicSchedule: payload }),
    SET_PUBLIC_LIST_SCHEDULE_B2C_TO_B2B: (state, payload) => ({ ...state, publicListScheduleB2CToB2B: payload }),
    SET_PUBLIC_B2C_CALENDAR_AVAILABILITY: (state, payload) => ({ ...state, publicB2CCalendarAvailability: payload }),
  },
  effects: (dispatch) => ({
    deleteAvailability(payload, rootState) {
      const { availability } = rootState.Appointments.actualService;

      if (availability && availability.length > 0) {
        availability[payload.weekDay].range.splice(payload.index, 1);
        dispatch.Appointments.SET_AVAILABILITY(availability);
      }
    },
    addAvailability(weekDay, rootState) {
      const { availability } = rootState.Appointments.actualService;

      if (availability && availability.length > 0) {
        availability[weekDay].range.push({
          period_start: '',
          period_end: '',
        });

        dispatch.Appointments.SET_AVAILABILITY(availability);
      }
    },
    deleteUnavailability(payload, rootState) {
      const { unavailability } = rootState.Appointments.actualService;

      if (unavailability && unavailability.length > 0) {
        unavailability.splice(payload, 1);

        dispatch.Appointments.DELETE_UNAVAILABILITY(unavailability);
      }
    },
    deleteUnavailabilityTemp(payload, rootState) {
      const { unavailabilityTemp } = rootState.Appointments.actualService;

      if (unavailabilityTemp && unavailabilityTemp.length > 0) {
        unavailabilityTemp.splice(payload.index, 1);

        dispatch.Appointments.DELETE_UNAVAILABILITY_TEMP(unavailabilityTemp);
      }
    },
    addUnavailability() {
      dispatch.Appointments.ADD_UNAVAILABILITY_TEMP({ period_start: '', period_end: '' });
    },
    saveUnavailability(payload, rootState) {
      const { actualService } = rootState.Appointments;

      if (actualService.unavailabilityTemp.length > 0) {
        // eslint-disable-next-line prefer-arrow-callback
        actualService.unavailability = actualService.unavailability.concat(actualService.unavailabilityTemp
          .filter((unavailability) => unavailability.period_start && unavailability.period_end)
          .map((unavailabilityTemp) => {
            const unavailabilityResp = {};
            unavailabilityResp.period_start = moment(`${payload.format('YYYY-MM-DD')} ${unavailabilityTemp.period_start}`).format();
            unavailabilityResp.period_end = moment(`${payload.format('YYYY-MM-DD')} ${unavailabilityTemp.period_end}`).format();
            return unavailabilityResp;
          }));
      }
      // eslint-disable-next-line no-debugger
      actualService.unavailabilityTemp = [{
        period_start: '',
        period_end: '',
      }];

      dispatch.Appointments.SET_ACTUAL_SERVICE(actualService);
    },
    deleteServiceType(index, rootState) {
      const { serviceTypes } = rootState.Appointments.actualService;

      if (serviceTypes && serviceTypes.length > 0) {
        serviceTypes.splice(index, 1);

        dispatch.Appointments.DELETE_SERVICE_TYPE(serviceTypes);
      }
    },
    addServiceType() {
      dispatch.Appointments.ADD_SERVICE_TYPE({ id: -1, name: '', serviceDurationMinutes: 30 });
    },
    async serviceDetail(payload) {
      dispatch.Appointments.SET_LOADING({ serviceDetail: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/service/detail/${payload}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ serviceDetail: 'fulfilled' });
          dispatch.Appointments.SET_ACTUAL_SERVICE(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ serviceDetail: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async publicServiceDetail({ userId, serviceId }) {
      dispatch.Appointments.SET_LOADING({ serviceDetail: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/service/public-detail/${userId}/${serviceId}`);

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ serviceDetail: 'fulfilled' });
          dispatch.Appointments.SET_ACTUAL_PUBLIC_SERVICE(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ serviceDetail: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async createService(payload, rootState) {
      const { actualService } = rootState.Appointments;

      dispatch.Appointments.SET_LOADING({ createService: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/service/create`, actualService, headers());
        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ createService: 'fulfilled' });
          dispatch.Appointments.SET_ACTUAL_SERVICE(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ createService: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async updateService(payload, rootState) {
      const { actualService } = rootState.Appointments;

      dispatch.Appointments.SET_LOADING({ updateService: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/service/update`, actualService, headers());
        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ updateService: 'fulfilled' });
          dispatch.Appointments.SET_ACTUAL_SERVICE(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ updateService: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async getAnimalsListToSchedule({ name }) {
      dispatch.Animals.SET_LOADING({ getAnimalsListToSchedule: 'loading' });

      const animalName = name ? `name=${name}&filter=animal` : '';
      const query = [animalName].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        dispatch.Appointments.SET_SCHEDULE_ANIMAL_LIST([]);
        const response = await axios.get(`${baseURL}/animal/my-animal-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ getAnimalsListToSchedule: 'fulfilled' });
          dispatch.Appointments.SET_SCHEDULE_ANIMAL_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ getAnimalsListToSchedule: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async myServiceList({ pageNumber, pageLength }) {
      dispatch.Appointments.SET_LOADING({ myServiceList: 'loading' });

      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : '';
      const pagelength = pageLength ? `pagelength=${pageLength}` : '';
      const query = [pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/service/my-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ myServiceList: 'fulfilled' });
          dispatch.Appointments.SET_SERVICE_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ myServiceList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async publicServices({ userId }) {
      dispatch.Appointments.SET_LOADING({ publicServices: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/service/public-list/${userId}`);

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ publicServices: 'fulfilled' });
          dispatch.Appointments.SET_PUBLIC_SERVICES(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ publicServices: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async myServiceListCount() {
      dispatch.Appointments.SET_LOADING({ myServiceListCount: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/service/my-list-count`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ myServiceListCount: 'fulfilled' });
          dispatch.Appointments.SET_SERVICE_LIST_COUNT(response.data.count);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ myServiceListCount: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listConnectedServices({ category }) {
      dispatch.Appointments.SET_LOADING({ listConnectedServices: 'loading' });

      const categoryId = category ? `category=${category}` : '';
      const query = [categoryId].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/service/list-connected${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ listConnectedServices: 'fulfilled' });
          dispatch.Appointments.SET_LIST_CONNECTED_SERVICES(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ listConnectedServices: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async createSchedule(payload) {
      dispatch.Appointments.SET_LOADING({ createSchedule: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/scheduling/create`, payload, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ createSchedule: 'fulfilled' });
          dispatch.Appointments.SET_DAY_SCHEDULE_B2B_LIST(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ createSchedule: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updateSchedule(payload) {
      dispatch.Appointments.SET_LOADING({ updateSchedule: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/scheduling/update`, payload, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ updateSchedule: 'fulfilled' });
          dispatch.Appointments.SET_DAY_SCHEDULE_B2B_LIST(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ updateSchedule: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async detailSchedule(payload) {
      dispatch.Appointments.SET_LOADING({ detailSchedule: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/scheduling/detail/${payload}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ detailSchedule: 'fulfilled' });
          dispatch.Appointments.SET_SCHEDULE_DETAIL(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ detailSchedule: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listScheduleB2B({ serviceToSchedule, startDate, endDate, dateType }) {
      const loadingName = dateType === 'day' ? 'dayScheduledB2BList' : 'monthScheduledB2BList';

      dispatch.Appointments.SET_LOADING({ [loadingName]: 'loading' });

      const service = serviceToSchedule ? `service=${serviceToSchedule}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [service, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/business/my-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ [loadingName]: 'fulfilled' });

          if (dateType === 'day') {
            dispatch.Appointments.SET_DAY_SCHEDULE_B2B_LIST(response.data);
          } else {
            dispatch.Appointments.SET_MONTH_SCHEDULE_B2B_LIST(response.data);
          }
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ [loadingName]: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async scheduleB2B({ serviceId, schedulingId }) {
      try {
        const url = `${baseURL}/schedule/business/schedule-b2b/${serviceId}/${schedulingId}`;
        return await axios.get(url, headers());
      } catch (err) {
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listScheduleB2C({ service, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ dayScheduledB2CList: 'loading' });

      const serviceId = service ? `service=${service}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [serviceId, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/customer/business-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ dayScheduledB2CList: 'fulfilled' });
          dispatch.Appointments.SET_DAY_SCHEDULE_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ dayScheduledB2CList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async publicListScheduleB2CToB2B({ userId, service, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ publicListScheduleB2CToB2B: 'loading' });

      const serviceId = service ? `service=${service}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [serviceId, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/customer/public-business-list/${userId}${querystring}`);

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ publicListScheduleB2CToB2B: 'fulfilled' });
          dispatch.Appointments.SET_PUBLIC_LIST_SCHEDULE_B2C_TO_B2B(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ publicListScheduleB2CToB2B: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listAllSchedulesB2C({ animal, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ listAllSchedulesB2C: 'loading' });

      const animalId = animal ? `animal=${animal}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [animalId, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/customer/my-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ listAllSchedulesB2C: 'fulfilled' });
          dispatch.Appointments.SET_ALL_DAY_SCHEDULES_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ listAllSchedulesB2C: 'rejected' });
        return err;
      }
    },
    async verifyScheduleAvailability({ serviceTypeId, animal, startDate }) {
      dispatch.Appointments.SET_LOADING({ verifyScheduleAvailability: 'loading' });

      const service = serviceTypeId ? `servicetype=${serviceTypeId}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const animalId = animal ? `animal=${animal}` : '';
      const query = [service, animalId, startdate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/verify-available${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ verifyScheduleAvailability: 'fulfilled' });
          dispatch.Appointments.SET_SCHEDULE_AVAILABILITY(response.data.serviceCanBeScheduled);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ verifyScheduleAvailability: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getB2BCalendarAvailability({ serviceId, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ getB2BCalendarAvailability: 'loading' });

      const service = serviceId ? `service=${serviceId}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [service, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/business/my-summarized-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ getB2BCalendarAvailability: 'fulfilled' });
          dispatch.Appointments.SET_B2B_CALENDAR_AVAILABILITY(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ getB2BCalendarAvailability: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getB2CCalendarAvailability({ serviceId, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ getB2CCalendarAvailability: 'loading' });

      const service = serviceId ? `service=${serviceId}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [service, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/customer/summarized-business-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ getB2CCalendarAvailability: 'fulfilled' });
          dispatch.Appointments.SET_CALENDAR_AVAILABILITY(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ getB2CCalendarAvailability: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async publicB2CCalendarAvailability({ userId, serviceId, startDate, endDate }) {
      dispatch.Appointments.SET_LOADING({ publicB2CCalendarAvailability: 'loading' });

      const service = serviceId ? `service=${serviceId}` : '';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [service, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/schedule/customer/public-summarized-business-list/${userId}${querystring}`);

        if (response && response.data) {
          dispatch.Appointments.SET_LOADING({ publicB2CCalendarAvailability: 'fulfilled' });
          dispatch.Appointments.SET_PUBLIC_B2C_CALENDAR_AVAILABILITY(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Appointments.SET_LOADING({ publicB2CCalendarAvailability: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async deleteService(payload, rootState) {
      const { actualServiceBase } = rootState.Appointments;
      // dispatch.Appointments.SET_LOADING({ createService: 'loading' });
      const payloadToSend = { id: [payload] };
      try {
        const response = await axios.post(`${baseURL}/service/delete`, payloadToSend, headers());
        if (response && response.data) {
          // dispatch.Appointments.SET_LOADING({ createService: 'fulfilled' });
          dispatch.Appointments.SET_ACTUAL_SERVICE(actualServiceBase);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Appointments.SET_LOADING({ createService: 'rejected' });
        console.log(err);
        return err;
      }
    },
    setActualPublicService(payload) {
      dispatch.Appointments.SET_ACTUAL_PUBLIC_SERVICE(payload);
    },
    setPublicSchedule(payload) {
      dispatch.Appointments.SET_PUBLIC_SCHEDULE(payload);
    },
    clearActualService() {
      const emptyService = {
        id: '',
        name: '',
        description: '',
        category: '',
        periodStart: null,
        serviceTypes: [{ id: -1, name: '', serviceDurationMinutes: 30 }],
        address: {},
        timeBetweenServicesMinutes: 0,
        availability: [
          {
            weekday: 0,
            range: [
              {
                period_start: '09:00',
                period_end: '12:00',
              },
              {
                period_start: '13:00',
                period_end: '17:00',
              }],
          },
          {
            weekday: 1,
            range: [
              {
                period_start: '09:00',
                period_end: '12:00',
              },
              {
                period_start: '13:00',
                period_end: '17:00',
              }],
          },
          {
            weekday: 2,
            range: [
              {
                period_start: '09:00',
                period_end: '12:00',
              },
              {
                period_start: '13:00',
                period_end: '17:00',
              }],
          },
          {
            weekday: 3,
            range: [
              {
                period_start: '09:00',
                period_end: '12:00',
              },
              {
                period_start: '13:00',
                period_end: '17:00',
              }],
          },
          {
            weekday: 4,
            range: [
              {
                period_start: '09:00',
                period_end: '12:00',
              },
              {
                period_start: '13:00',
                period_end: '17:00',
              }],
          },
          {
            weekday: 5,
            range: [],
          },
          {
            weekday: 6,
            range: [],
          },
        ],
        unavailability: [],
        unavailabilityTemp: [
          {
            period_start: '',
            period_end: '',
          },
        ],
      };

      dispatch.Appointments.CLEAR_ACTUAL_SERVICE(emptyService);
    },
    clearPublicSchedule() {
      dispatch.Appointments.SET_PUBLIC_SCHEDULE({});
    },
    updateMyServiceListCount() {
      dispatch.Appointments.SET_SERVICE_LIST_COUNT(1);
    },
    setActualAppointmentsData(payload) {
      dispatch.Appointments.SET_ACTUAL_SERVICE(payload);
    },
    setModal(status) {
      dispatch.Appointments.SET_MODAL(status);
    },
  }),
};
