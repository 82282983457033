import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../../utils/mobile';

const EventErrorDialog = ({ open, setModalOpen, errorMessage, setIsPeriodSelected }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': {
      minWidth: '350px',
    },
    '& .MuiDialog-container': {
      height: 'auto',
      marginTop: isMobile ? '55px' : '150px',
    } }}
    fullScreen={isMobile}
    open={open}
    onBackdropClick={() => setModalOpen(false)}
  >
    <DialogTitle>
      <Grid container direction='row'>
        <Grid item>
          Não foi possível agendar
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent dividers>
      <Typography>{errorMessage}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        sx={{ color: '#6B48FE' }}
        onClick={() => {
          setModalOpen(false);
          setIsPeriodSelected(false);
        }}
      >
        Voltar
      </Button>
    </DialogActions>
  </Dialog>
);

export default EventErrorDialog;
