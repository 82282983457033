import React from 'react';
import TableCell from '@mui/material/TableCell';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import ShareIcon from '@mui/icons-material/Share';
import Alert from '../../../global/Alert';
import { StyledTableRow, StyledTableActions } from './style';

import { getPublicUrlScheduleCompanyService } from '../../../../services/generatePublicUrl';

const AppointmentTableRow = ({ id, name, category, periodStart, pendingSchedulingsCount, cancelledSchedulingsCount, user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const redirectToAppointmentDetail = () => {
    history.push(`/appointments/${id}`);
  };

  const redirectToAppointmentSchedule = async () => {
    await dispatch.Appointments.clearActualService();
    await dispatch.Appointments.serviceDetail(id);
    history.push(`/appointments/schedule/${id}`);
  };

  const copyServiceLink = () => {
    const url = getPublicUrlScheduleCompanyService(user?.id, `${user?.firstName}${user?.lastName}`, id);
    setOpen(true);
    navigator.clipboard.writeText(url);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: url } }));
  };

  return (
    <>
      <StyledTableRow>
        <TableCell component='th' scope='row'>{name || '-'}</TableCell>
        <TableCell>{(periodStart && moment(periodStart).format('DD/MM/YYYY')) || ''}</TableCell>
        <TableCell>
          {
          category === 'Adestramento' ? (
            'Adestramento e Consultoria Comportamental'
          ) : (
            category
          )
          }
        </TableCell>
        <TableCell>{pendingSchedulingsCount}</TableCell>
        <TableCell>{cancelledSchedulingsCount}</TableCell>
        <TableCell>
          <StyledTableActions>
            <Button
              onClick={copyServiceLink}
              startIcon={<ShareIcon sx={{ color: '#6B48FF', fill: '#6B48FF' }} />}
              style={{ padding: '4px 12px', borderRadius: '20px', border: '1px solid #6B48FF', color: '#6B48FF' }}
            >
              Copiar link
            </Button>
            <Button
              onClick={redirectToAppointmentSchedule}
              startIcon={<CalendarTodayIcon />}
              style={{ padding: '4px 12px', borderRadius: '20px', border: '1px solid #6B48FF', color: '#6B48FF' }}
            >
              Agendar

            </Button>
            <InfoIcon
              onClick={redirectToAppointmentDetail}
              style={{ fill: 'white', stroke: '#6B48FF', strokeWidth: '1', cursor: 'pointer' }}
            />
          </StyledTableActions>
        </TableCell>
      </StyledTableRow>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose}>
            Link da agenda copiado com sucesso
          </Alert>
        </div>
      </Snackbar>
    </>
  );
};

export default AppointmentTableRow;
