import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { Update } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import { Container, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useHistory, useParams } from 'react-router-dom';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import Footer from '../../components/Footer';
import QuizHealthCard from './QuizHealthCard';
import VirtualVaccineCard from './VaccineCard';
import Loading from '../../components/global/Loading';
import PetCard from '../../components/Dashboard/PetCard';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { isMobile, isDesktop } from '../../utils/mobile';
import { PrimaryButton, SecondaryButton } from '../../utils/theme';
import ProtocolDialog from '../../components/Modals/ProtocolDialog';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import TransferPetModal from '../../components/Modals/TransferPetModal';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import CompletePetDialog from '../../components/Modals/CompletePetDialog';
import SectionControl from '../../components/PetManagement/SectionControl';
import ProtocolResultDialog from '../../components/Modals/ProtocolResultDialog';
import CompleteProfileDialog from '../../components/Modals/CompleteProfileDialog';
import RequestSignatureOnAppDialog from '../../components/Modals/RequestSignatureOnAppDialog';
import { MainWrapper, StyledGrid, ButtonGrid, AlertGrid, RefreshGrid, RefreshArea } from './style';
// import { getParsedQueryString } from '../../utils/querystring';

const PetPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // const [protocolDialog, setProtocolDialog] = useState({ open: false, mode: 'create', content: '' });
  // const [protocolResultDialog, setProtocolResultDialog] = useState({ open: false, result: 'create', content: '' });
  const history = useHistory();
  const { actualPet } = useSelector((state) => state.Animals) || {};
  const { treatments, preventives, vaccines, services, medicines } = useSelector((state) => state.Animals.actualPet.protocols);
  const { listResultsQuizHealth } = useSelector((state) => state.Animals);

  const { getActualPet, getAnimalProtocols, getResultsQuizHealth } = useSelector((state) => state.Animals.loading);
  const [isLoadingOpenVirtualCard, setIsLoadingOpenVirtualCard] = useState(false);
  const user = useSelector((state) => state.User.userData);
  const loadingModal = useSelector((state) => state.Modal.loadingOpenDialog.loading);

  const { editPermission } = {
    editPermission: actualPet.permissions && actualPet.permissions.editProfile,
    askQuestionToVetPermission: false,
  };

  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => dispatch.User.listFollowers(), []);
  useEffect(() => dispatch.User.listFollowing(), []);
  useEffect(() => dispatch.Animals.getResultsQuizHealth({ animalId: params?.id }), []);
  useEffect(() => dispatch.Animals.getAnimalProtocols(params?.id), []);
  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.PETS_DETAILS_VIEW);

    dispatch.Animals.getActualPet(params?.id);

    return () => {
      dispatch.Animals.clearActualPet();
    };
  }, []);

  useEffect(() => {
    const listenerEvent = (event) => {
      if (event?.origin === process.env.REACT_APP_WEB) {
        const { refreshBannerQuizHealth, petId } = event.data;

        if (refreshBannerQuizHealth && petId && petId === Number(params?.id)) {
          dispatch.Animals.getResultsQuizHealth({ animalId: petId });
        }
      }
    };

    document.addEventListener('message', listenerEvent);
    window.addEventListener('message', listenerEvent);

    return () => {
      document.removeEventListener('message', listenerEvent);
      window.removeEventListener('message', listenerEvent);
    };
  }, []);

  if (getActualPet !== 'fulfilled' && getAnimalProtocols !== 'fulfilled') {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  const openTransferPetModal = () => dispatch.User.setModal({ transferPetModal: { open: true, context: 'transferPet' } });

  const onClickVirtualVaccineCard = async () => {
    try {
      setIsLoadingOpenVirtualCard(true);
      Mixpanel.track(MIXPANEL_TAGS.VIRTUAL_VACCINE_CARD_CLICK);

      if (!editPermission) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
        setTimeout(() => {
          setIsLoadingOpenVirtualCard(false);
        }, 2000);
        return null;
      }

      const getUserData = await dispatch.User.getUserProfileData();
      const { email, phone, firstName, address, cpfCnpj } = getUserData.data;

      if (getUserData && getUserData.data) {
        const isAddressFilled = address && address?.code && address?.number && address?.city && address?.street && address?.district && address?.state;
        const isProfileFilled = firstName && email && phone && isAddressFilled && cpfCnpj;

        if (!isProfileFilled) {
          setIsLoadingOpenVirtualCard(false);
          dispatch.Modal.setProfileDialog({ open: true, desc: 'Para gerar a carteirinha digital, precisamos de algumas informações do seu perfil' });
          return null;
        }

        const { furColor, furSize, breed, name, sex, birthdate } = actualPet;
        const isPetFilled = furColor?.length && furSize && breed && name && sex && birthdate;

        if (!isPetFilled) {
          setIsLoadingOpenVirtualCard(false);
          dispatch.Modal.setPetDialog({ open: true });
          return null;
        }

        window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
        setTimeout(() => {
          setIsLoadingOpenVirtualCard(false);
        }, 2000);
        return null;
      }
      setIsLoadingOpenVirtualCard(false);
      return null;
    } catch (err) {
      setIsLoadingOpenVirtualCard(false);
      return null;
    }
  };

  const onClickQuizHealthCard = async () => {
    try {
      setIsLoadingOpenVirtualCard(true);
      Mixpanel.track(MIXPANEL_TAGS.QUIZ_HEALTH_CARD_CLICK);

      const hasResults = listResultsQuizHealth?.length >= 1;
      // const hasMoreOneResults = listResultsQuizHealth?.length > 1;

      if (!editPermission) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'QUIZ_HEALTH', params: { editPermission: false, showResult: true, petId: params?.id } }));
        setTimeout(() => {
          setIsLoadingOpenVirtualCard(false);
        }, 2000);
        return null;
      }

      if (hasResults) {
        // if (hasMoreOneResults) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'QUIZ_HEALTH', params: { showResult: true, petId: params?.id } }));
        // } else {
        //  window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'QUIZ_HEALTH_RESULT_DETAIL', params: { data: listResultsQuizHealth[0] } }));
        // }
        setTimeout(() => {
          setIsLoadingOpenVirtualCard(false);
        }, 2000);
        return null;
      }

      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'QUIZ_HEALTH', params: { petId: params?.id } }));
      setTimeout(() => {
        setIsLoadingOpenVirtualCard(false);
      }, 2000);
      return null;
    } catch (err) {
      setIsLoadingOpenVirtualCard(false);
      return null;
    }
  };

  return (
    <MainWrapper>
      <TransferPetModal />
      <DashboardHeader />
      <DashboardMenu />
      <ProtocolDialog />
      <ProtocolResultDialog />
      <CompleteProfileDialog />
      <CompletePetDialog />
      <RequestSignatureOnAppDialog />
      {(loadingModal || isLoadingOpenVirtualCard) && (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '100vh', background: 'white', opacity: '0.7', position: 'fixed', zIndex: 20 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      )}

      <Container>
        <StyledGrid container direction='column' justifyContent='flex-end' alignItems='flex-end' spacing={0}>
          <ButtonGrid container direction='column' justifyContent='center' alignItems='flex-end'>

            <RefreshGrid item style={{ marginBottom: '12px' }}>
              <RefreshArea>
                {(user.isB2B && actualPet?.user?.id === user.id) && !isMobile ? (
                  <SecondaryButton style={{ marginRight: !isMobile && '10px' }} disabled={actualPet && actualPet.transferedWait} onClick={openTransferPetModal} startIcon={<CompareArrowsIcon />}>
                    {isMobile ? 'Transferir' : 'Transferir pet'}
                  </SecondaryButton>
                ) : <></>}

                {isMobile && (
                  <Button
                    size='large'
                    sx={{ color: '#6B48FF', left: -15 }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push('/pets');
                    }}
                  />
                )}

                {editPermission ? (
                  <Link to={`/pet/edit/${params.id}`} style={{ textDecoration: 'none' }}>
                    <PrimaryButton startIcon={<Update />}>
                      {isMobile ? 'Editar dados' : 'Editar dados do pet'}
                    </PrimaryButton>
                  </Link>
                ) : <></>}
              </RefreshArea>
            </RefreshGrid>

            {actualPet && actualPet.transferedWait ? (
              <AlertGrid item>
                <Alert severity='warning'>
                  Este pet está em transferência para
                  {' '}
                  {actualPet.waitingTransferTo}
                </Alert>
              </AlertGrid>
            ) : <></> }
          </ButtonGrid>

          <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <PetCard {...actualPet} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9}>
              <Grid
                onClick={onClickVirtualVaccineCard}
                sx={{ display: !isMobile ? 'none' : 'block', cursor: 'pointer' }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <VirtualVaccineCard />
              </Grid>

              <Grid
                onClick={onClickQuizHealthCard}
                sx={{ display: getResultsQuizHealth === 'fulfilled' && !isDesktop ? 'block' : 'none', cursor: 'pointer' }}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
              >
                <QuizHealthCard result={listResultsQuizHealth?.length && listResultsQuizHealth[0] ? [listResultsQuizHealth[0]] : []} />
              </Grid>

              {services && services.length ? (
                <Box>
                  <SectionControl title='Cuidados de Rotina' list={services} />
                </Box>
              ) : <></>}

              {treatments && treatments.length ? (
                <Box mt={2}>
                  <SectionControl title='Controle de Tratamentos' list={treatments} />
                </Box>
              ) : <></>}

              {vaccines && vaccines.length ? (
                <Box mt={2}>
                  <SectionControl title='Carteira de Vacinação' list={vaccines} />
                </Box>
              ) : <></>}

              {preventives && preventives.length ? (
                <Box mt={2}>
                  <SectionControl title='Cuidados Preventivos' list={preventives} />
                </Box>
              ) : <></>}

              {medicines && medicines.length ? (
                <Box mt={2}>
                  <SectionControl title='Medicamentos' list={medicines} />
                </Box>
              ) : <></>}
            </Grid>
          </Grid>
        </StyledGrid>
      </Container>
      <Footer />
    </MainWrapper>
  );
};

export default PetPage;
