import styled from 'styled-components';
import { Button } from '@mui/material';

export const RedeemButton = styled(Button)`
  &.MuiButton-root {
    background: #6B48FF;
    color: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
  }

  &.MuiButton-root:hover {
    background: #6B48FF;
    color: white;
  }
`;
