/* eslint-disable consistent-return */
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import { Container } from '@material-ui/core';
import { TableContainer } from '@mui/material';
import theme, { animations } from '../../../utils/theme';

export const StyledContainer = styled(Container)`
  margin-top: 10px;
  animation: ${animations.fadeIn} .2s ease-in;
`;

export const StyledTableContainer = styled(TableContainer)`
  animation: ${animations.fadeIn} .2s ease-in-out;
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    transition: background-color .1s ease-in, box-shadow .1s ease-in;
    &:hover {
      background-color: #f2f0fa;
      -webkit-box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
    }
  }
`;

export const InviteControl = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EmptyNetwork = styled.img`
  width: 400px;
  height: 400px;
  animation: ${animations.fadeIn} .2s ease-in-out;

  @media screen and (max-width: 736px) {
      width: 250px;
      height: 250px;
    }
`;

export const TableMenu = styled.div`
  display: flex;
  width: 100px;
  margin-right: auto;
  flex-direction: column;
`;

export const TableItem = styled.div`
	opacity: ${({ selected }) => (selected ? '1.0' : '0.5')};
	transition: opacity 0.2s ease-in;
  cursor: pointer;
  user-select: none;

	&:hover {
		opacity: 1;
	}

	:nth-child(1) {
		margin-left: 0;
	}

	& {
		margin-left: 32px;
	}
`;

export const IndicatorLine = styled.div`
	width: 76px;
	height: 2px;
	border: 1px solid ${theme.menuIndicator};
	background-color: ${theme.menuIndicator};
	border-radius: 5px;
  margin-right: 150px;
	margin-top: 8px;
  left: 0;
	animation: ${animations.fadeIn} 0.2s ease-in;
  transition: all 0.2s ease-in-out;

  ${({ menu }) => {
    switch (menu) {
      case 'following':
        return 'transform: translate(110px); width: 70px';
      default:
        break;
    }
  }
};

  ${({ isTutor }) => !isTutor && 'transform: translate(0px); width: 70px'}
`;
