/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScriptTag from 'react-script-tag';
import store from './redux/rematch';
import App from './App';
import intercomJs from './assets/js/intercom';
import customerIODev from './assets/js/_cio_dev';

const ProductionScriptTags = () => {
  if (process.env.REACT_APP_ENV === 'PROD') {
    import('./assets/js/fb');
    import('./assets/js/google');
    import('./assets/js/hotjar');
    import('./assets/js/_cio_prod');

    return (
      <>
        <ScriptTag type='text/javascript' src='./assets/js/fb' />
        <ScriptTag type='text/javascript' src='./assets/js/fb-pixel' />
        <ScriptTag type='text/javascript' src='./assets/js/google' />
        <ScriptTag type='text/javascript' src='./assets/js/hotjar' />
        <ScriptTag type='text/javascript' src='./assets/js/_cio_prod.js' />
        <img alt='facebookImage' height='1' width='1' style={{ display: 'none' }} src='https://www.facebook.com/tr?id=363058788273331&ev=PageView&noscript=1' />
        <img alt='facebookImagePixel' height='1' width='1' style={{ display: 'none' }} src='https://www.facebook.com/tr?id=1373097146613936&ev=PageView&noscript=1' />
        <iframe title='googletag' height='1' width='1' style={{ display: 'none' }} src='https://www.googletagmanager.com/ns.html?id=GTM-KVQ5CKX' />
      </>
    );
  }
  return null;
};

ReactDOM.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    <ScriptTag type='text/javascript' src='./assets/js/intercom' />

    {process.env.REACT_APP_ENV !== 'PROD' ? (
      <ScriptTag type='text/javascript' src='./assets/js/_cio_dev' />
    ) : null}

    <ProductionScriptTags />
  </>,
  document.getElementById('root'),
);
