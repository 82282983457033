import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getDeviceData } from '../services/api';

const parseVersion = (version) => Number(String(version).replaceAll('.', ''));

const useCheckAppVersion = () => {
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const readyToUpdate = true;
  const isAndroid = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('Android');
  const isIOS = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('iOS');

  useEffect(() => {
    const checkDeviceVersionFromLocalStorage = () => {
      const deviceData = JSON.parse(getDeviceData()) || {};
      const version = deviceData && deviceData.version;

      const userAppIsUpdated = version && parseVersion(version) >= parseVersion(isIOS ? process.env.REACT_APP_NATIVE_VERSION_IOS : process.env.REACT_APP_NATIVE_VERSION);
      const userAppIsOutdated = version && parseVersion(version) < parseVersion(isIOS ? process.env.REACT_APP_NATIVE_VERSION_IOS : process.env.REACT_APP_NATIVE_VERSION);
      const showModalOnFront = version && parseVersion(version) < parseVersion(isIOS ? '1.1.7' : '1.12.7');
      const deviceDataIsNotEmpty = Object.keys(deviceData).length;

      if (deviceDataIsNotEmpty && version && userAppIsOutdated && readyToUpdate) {
        // if (process.env.REACT_APP_ENV === 'PROD') {
        // if (isUserLogged) history.push('/profile');
        window.ReactNativeWebView?.postMessage('UPDATE_APP_ON_STORE');
        dispatch.User.setModal({ appUpdateModal: { open: true, showModalOnFront } });
        // }
        clearInterval(intervalRef.current);
      } else if (deviceDataIsNotEmpty && version && userAppIsUpdated) {
        dispatch.User.setAppIsUpdated(true);
        clearInterval(intervalRef.current);
      }

      if (version) {
        clearInterval(intervalRef.current);
      }
    };

    if (isAndroid || isIOS) {
      const interval = setInterval(() => checkDeviceVersionFromLocalStorage(), 200);
      intervalRef.current = interval;

      return () => {
        clearInterval(intervalRef.current);
      };
    }

    return null;
  }, [location.pathname]);

  useEffect(() => {
    const checkDeviceVersionFromEvent = (event) => {
      const isSameOrigin = event?.origin === process.env.REACT_APP_WEB;

      if (isSameOrigin && event.data?.workflow) {
        document.removeEventListener('message', checkDeviceVersionFromEvent);
        window.removeEventListener('message', checkDeviceVersionFromEvent);

        const { workflow } = event.data;

        localStorage.setItem('workflow', workflow);
      }

      if (isSameOrigin && event.data?.version) {
        document.removeEventListener('message', checkDeviceVersionFromEvent);
        window.removeEventListener('message', checkDeviceVersionFromEvent);

        localStorage.setItem('dvData', JSON.stringify(event.data));

        const { version } = event.data;
        const userAppIsUpdated = version && parseVersion(version) >= parseVersion(isIOS ? process.env.REACT_APP_NATIVE_VERSION_IOS : process.env.REACT_APP_NATIVE_VERSION);
        const userAppIsOutdated = version && parseVersion(version) < parseVersion(isIOS ? process.env.REACT_APP_NATIVE_VERSION_IOS : process.env.REACT_APP_NATIVE_VERSION);
        const showModalOnFront = version && parseVersion(version) < parseVersion(isIOS ? '1.1.7' : '1.12.7');

        const deviceDataIsNotEmpty = Object.keys(event.data).length;

        if (deviceDataIsNotEmpty && userAppIsOutdated && readyToUpdate) {
          // if (process.env.REACT_APP_ENV === 'PROD') {
          // if (isUserLogged) history.push('/profile');
          window.ReactNativeWebView?.postMessage('UPDATE_APP_ON_STORE');
          dispatch.User.setModal({ appUpdateModal: { open: true, showModalOnFront } });
          // }
        } else if (deviceDataIsNotEmpty && userAppIsUpdated) {
          dispatch.User.setAppIsUpdated(true);
        }
      }
    };

    if (isAndroid || isIOS) {
      document.addEventListener('message', (event) => {
        checkDeviceVersionFromEvent(event);
      });
      window.addEventListener('message', (event) => {
        checkDeviceVersionFromEvent(event);
      });

      return () => {
        document.removeEventListener('message', checkDeviceVersionFromEvent);
        window.removeEventListener('message', checkDeviceVersionFromEvent);
      };
    }

    return null;
  }, [location.pathname]);
};

export default useCheckAppVersion;
