import React from 'react';
import { Typography } from '@mui/material';
import { FooterContainer, SupportPhone } from './style';

const Footer = () => (
  <FooterContainer>
    <Typography variant='subtitle2'>
      Precisa de ajuda? Entre em contato:
      <br />
      <Typography variant='subtitle2' sx={{ color: '#6B48FF' }}>
        suporte@guia.vet |
        {' '}
        <SupportPhone
          href='https://wa.me/+5531990851216'
          target='_blank'
          rel='noreferrer'
        >
          31 99085-1216
        </SupportPhone>
      </Typography>
    </Typography>
  </FooterContainer>
);

export default Footer;
