import React, { useEffect, useState } from 'react';
import { Tooltip, Fade, Card, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { StyledTypography, StyledChip, StyledContainer, StyledDataGrid, RedeemButton, StyledMoneyIcon } from './style';
import StatementCard from './StatementCard';
import StatementDarkCard from './StatementDarkCard';
import { ReactComponent as VoucherIcon } from '../../../assets/images/Statements/voucher.svg';
import RedeemDialog from './RedeemDialog';
import VoucherDetailDialog from './VoucherDetailDialog';
import { formatBRL, formatTextLimit } from '../../../utils/format';
import { datagridLocale } from '../../../utils/datagrid-locale-ptbr';
import Alert from '../../global/Alert';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const typeMap = (value) => {
  const typeTemplateLiteral = {
    credit: {
      name: 'resgate',
      icon: AttachMoneyIcon,
      symbol: '+',
    },
    debit: {
      name: 'transferência',
      icon: SwapHorizIcon,
      symbol: '-',
    },
    info: {
      name: 'cancelado',
      icon: MoneyOffIcon,
      symbol: '',
    },
  };

  const validOptions = ['credit', 'debit', 'info'];

  if (validOptions.includes(value)) {
    return typeTemplateLiteral[value];
  }
  return {
    name: 'outros',
    icon: AttachMoneyIcon,
    symbol: '',
  };
};

const renderIcon = (type, color) => {
  switch (type) {
    case 'credit':
      return <AttachMoneyIcon sx={{ color, marginRight: '12px' }} />;
    case 'debit':
      return <SwapHorizIcon sx={{ color, marginRight: '12px' }} />;
    case 'info':
      return <MoneyOffIcon sx={{ color, marginRight: '12px' }} />;
    default:
      return <div />;
  }
};

const columns = [
  {
    field: 'name',
    headerName: 'ITEM',
    renderCell: ({ value, row }) => (
      <Grid container alignItems='center' wrap='nowrap' sx={{ color: row?.type && row.type === 'info' ? '#D8D8D8' : 'black' }}>
        {renderIcon(row.type, '#6B48FF')}
        <Typography variant='body2'>
          {formatTextLimit(value, 33)}
        </Typography>
      </Grid>
    ),
    width: 290,
    sortable: false,
    filterable: false,
    hideable: false,
  },
  {
    field: 'transactionDatetime',
    headerName: 'DATA DE PROCESSAMENTO',
    renderCell: ({ value, row }) => (
      <Typography variant='body2' sx={{ color: row?.type && row.type === 'info' ? '#D8D8D8' : 'black' }}>
        {moment(value).format('DD/MM/YYYY')}
      </Typography>
    ),
    width: 205,
    sortable: false,
    filterable: false,
    hideable: false,
  },
  {
    field: 'amount',
    headerName: 'VALOR',
    renderHeader: () => (
      <>
        <strong>
          VALOR
        </strong>

        <Tooltip title='Esse é o valor de repasse para sua conta' placement='top'>
          <StyledMoneyIcon />
        </Tooltip>
      </>
    ),
    renderCell: ({ value, row }) => (value && (
      <StyledTypography $type={row?.type} variant='body2'>
        {typeMap(row.type).symbol}
        {formatBRL(value)}
      </StyledTypography>
    )),
    width: 128,
    sortable: false,
    filterable: false,
    hideable: false,
  },
  {
    field: 'type',
    headerName: ' ',
    renderCell: ({ value }) => value && (
      <StyledChip
        size='small'
        disabled
        label={typeMap(value).name}
      />
    ),
    width: 150,
    sortable: false,
    filterable: false,
    hideable: false,
  },
];

const StatementsDashboard = () => {
  const [redeemDialogOpen, setRedeemDialogOpen] = useState(false);
  const [voucherDetailDialog, setVoucherDetailDialog] = useState(false);
  const [clickFromRow, setClickFromRow] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    success: 'success',
  });

  const balance = useSelector((state) => state.Vouchers.balance.balance);
  const pendingRedeem = useSelector((state) => state.Vouchers.balance.pendingRedeem);
  const { balance: balanceLoading, voucherB2BTotals: voucherB2BTotalsLoading, extractList: extractListLoading } = useSelector((state) => state.Vouchers.loading);
  const { purchased, redeemed } = useSelector((state) => state.Vouchers.voucherB2BTotals);
  const extractList = useSelector((state) => state.Vouchers.extractList) || [];
  const extractCount = useSelector((state) => state.Vouchers.extractCount.count);
  const dispatch = useDispatch();
  const isBalanceLoading = balanceLoading === 'loading';
  const isTotalsLoading = voucherB2BTotalsLoading === 'loading';
  const isExtractLoading = extractListLoading === 'loading';

  const handleRowClick = async (row) => {
    if (row?.row?.voucherRedeemCode) {
      const response = await dispatch.Vouchers.voucherB2BDetail(row.row.voucherRedeemCode);

      if (response?.data?.status) {
        setClickFromRow(true);
        setVoucherDetailDialog(true);
      } else {
        setAlert({
          open: true,
          message: 'Não foi possível buscar os detalhes da oferta',
          success: 'error',
        });
      }
    }
  };

  const fetchExtract = async (nextPage) => {
    // eslint-disable-next-line prefer-const
    if (selectedPeriod === 0) {
      await dispatch.Vouchers.extractList({
        startDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    } else if (selectedPeriod === 7) {
      await dispatch.Vouchers.extractList({
        startDate: moment().subtract(7, 'days').format(),
        endDate: moment().format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    } else {
      await dispatch.Vouchers.extractList({
        startDate: moment(moment().startOf('month')).subtract(selectedPeriod, 'months').format(),
        endDate: moment().endOf('month').format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    }
  };

  const fetchUpdateExtract = async (nextPage) => {
    let response;

    if (selectedPeriod === 0) {
      response = await dispatch.Vouchers.updateExtractList({
        startDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    } else if (selectedPeriod === 7) {
      response = await dispatch.Vouchers.updateExtractList({
        startDate: moment().subtract(7, 'days').format(),
        endDate: moment().format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    } else {
      response = await dispatch.Vouchers.updateExtractList({
        startDate: moment(moment().startOf('month')).subtract(selectedPeriod, 'months').format(),
        endDate: moment().endOf('month').format(),
        page: nextPage,
        limit: rowsPerPage,
      });
    }

    if (response && response.data) {
      setPage(nextPage);
    }
  };

  const fetchCount = async () => {
    if (selectedPeriod === 0) {
      dispatch.Vouchers.extractCount({
        startDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(),
      });
    } else if (selectedPeriod === 7) {
      dispatch.Vouchers.extractCount({
        startDate: moment().subtract(7, 'days').format(),
        endDate: moment().format(),
      });
    } else {
      dispatch.Vouchers.extractCount({
        startDate: moment(moment().startOf('month')).subtract(selectedPeriod, 'months').format(),
        endDate: moment().endOf('month').format(),
      });
    }
  };

  const onClickRedeemDialog = () => {
    setClickFromRow(false);
    setRedeemDialogOpen(true);
    Mixpanel.track(MIXPANEL_TAGS.VOUCHER_REDEEM_MODAL_CLICK);
  };

  useEffect(() => {
    if (selectedPeriod === 0) {
      dispatch.Vouchers.voucherB2BTotals({
        startDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(),
      });
    } else if (selectedPeriod === 7) {
      dispatch.Vouchers.voucherB2BTotals({
        startDate: moment().subtract(7, 'days').format(),
        endDate: moment().format(),
      });
    } else {
      dispatch.Vouchers.voucherB2BTotals({
        startDate: moment(moment().startOf('month')).subtract(selectedPeriod, 'months').format(),
        endDate: moment().endOf('month').format(),
      });
    }

    dispatch.Vouchers.balance();
  }, [selectedPeriod]);

  useEffect(() => {
    setPage(0);
    fetchExtract();
    fetchCount();
  }, [selectedPeriod]);

  return (
    <StyledContainer sx={{ marginTop: '40px' }}>
      <RedeemDialog
        open={redeemDialogOpen}
        setRedeemDialogOpen={setRedeemDialogOpen}
        setVoucherDialogOpen={setVoucherDetailDialog}
        setAlert={setAlert}
      />
      <VoucherDetailDialog
        open={voucherDetailDialog}
        setRedeemDialogOpen={setRedeemDialogOpen}
        setVoucherDialogOpen={setVoucherDetailDialog}
        setAlert={setAlert}
        clickFromRow={clickFromRow}
      />
      <Grid
        container
        justifyContent='space-between'
        alignItems='flex-end'
        sx={{ width: '94%' }}
        wrap='wrap-reverse'
      >
        <Grid item>
          <Grid container gap={2}>
            <Grid item>
              <StatementDarkCard
                title='Saldo disponível'
                value={(balance && formatBRL(balance).replace('R$', '').replace(/\s/g, '')) || '0,00'}
                loading={isBalanceLoading}
              />
            </Grid>

            <Grid item>
              <StatementCard
                title='Resgates pendentes'
                value={(pendingRedeem?.sum && formatBRL(pendingRedeem.sum).replace('R$', '').replace(/\s/g, '')) || '0,00'}
                quantity={pendingRedeem?.count || 0}
                tooltip='Todos os vales compra pendentes de resgate'
                loading={isBalanceLoading}
                height='90px'
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <RedeemButton
            variant='contained'
            size='large'
            onClick={onClickRedeemDialog}
            startIcon={<VoucherIcon />}
            sx={{ marginBottom: '20px' }}
          >
            Resgatar vale-compras
          </RedeemButton>
        </Grid>
      </Grid>

      <Grid container flexDirection='column' sx={{ margin: '40px 0 20px 0' }}>
        <Grid item>
          <Typography sx={{ marginBottom: '20px' }}>Extrato</Typography>
        </Grid>

        <Grid item>
          <FormControl sx={{ width: '300px' }}>
            <InputLabel id='demo-simple-select-helper-label'>Período</InputLabel>
            <Select
              id='period'
              name='period'
              label='Período'
              onChange={(e) => setSelectedPeriod(e.target.value)}
              value={selectedPeriod}
              sx={{ '&.MuiInputBase-root': { backgroundColor: 'white' } }}
            >
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={0}>Este mês</MenuItem>
              <MenuItem value={2}>3 meses</MenuItem>
              <MenuItem value={5}>6 meses</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container flexDirection='row' columnGap={2} wrap='nowrap'>
        <Grid item>
          <Fade in>
            <Card elevation={0} sx={{ height: 485, width: '800px', padding: '15px' }}>
              <StyledDataGrid
                rows={extractList}
                columns={columns}
                onRowClick={(row) => handleRowClick(row)}
                hideFooterSelectedRowCount
                disableSelectionOnClick
                loading={isExtractLoading}
                localeText={datagridLocale}
                page={page}
                rowCount={extractCount}
                pageSize={10}
                pagination
                onPageChange={(nextPage) => fetchUpdateExtract(nextPage)}
                rowsPerPageOptions={[rowsPerPage]}
                disableColumnFilter
                disableColumnReorder
                disableColumnSelector
                disableColumnMenu
              />
            </Card>
          </Fade>
        </Grid>

        <Grid container flexDirection='column' spacing={2}>
          <Grid item>
            <StatementCard
              title='Vales-compra vendidos'
              value={((purchased?.sum && formatBRL(purchased.sum).replace('R$', '').replace(/\s/g, '')) || '0,00')}
              quantity={purchased?.count || 0}
              tooltip='Todos os vales compra vendidos durante o período selecionado'
              loading={isTotalsLoading}
            />
          </Grid>
          <Grid item>
            <StatementCard
              title='Vales-compra resgatados'
              value={((redeemed?.sum && formatBRL(redeemed.sum).replace('R$', '').replace(/\s/g, '')) || '0,00')}
              quantity={redeemed?.count || 0}
              tooltip='Todos os vales compra resgatados durante o período selecionado'
              loading={isTotalsLoading}
            />
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert.open}
        autoHideDuration={9000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <div>
          <Alert color={alert.success}>
            {alert.message}
          </Alert>
        </div>
      </Snackbar>
    </StyledContainer>
  );
};

export default StatementsDashboard;
