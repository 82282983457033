/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
export const validateCPF = (stringOrNumberCPF) => {
  const cpf = stringOrNumberCPF && stringOrNumberCPF.replace(/[^\d]+/g, '');
  if (!cpf) return false;

  if (cpf.length != 11
		|| cpf == '00000000000'
		|| cpf == '11111111111'
		|| cpf == '22222222222'
		|| cpf == '33333333333'
		|| cpf == '44444444444'
		|| cpf == '55555555555'
		|| cpf == '66666666666'
		|| cpf == '77777777777'
		|| cpf == '88888888888'
		|| cpf == '99999999999') { return false; }

  let add = 0;
  for (let i = 0; i < 9; i++) { add += parseInt(cpf.charAt(i), 10) * (10 - i); }
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) { rev = 0; }
  if (rev != parseInt(cpf.charAt(9), 10)) { return false; }

  add = 0;
  for (let i = 0; i < 10; i++) { add += parseInt(cpf.charAt(i), 10) * (11 - i); }
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) { rev = 0; }
  if (rev != parseInt(cpf.charAt(10), 10)) { return false; }
  return true;
};

export const validateCNPJ = (cnpj) => {
  const parsedCNPJ = cnpj && cnpj.replace(/[^\d]+/g, '');
  if (!parsedCNPJ) return false;

  if (parsedCNPJ.length != 14) { return false; }

  if (parsedCNPJ == '00000000000000'
      || parsedCNPJ == '11111111111111'
      || parsedCNPJ == '22222222222222'
      || parsedCNPJ == '33333333333333'
      || parsedCNPJ == '44444444444444'
      || parsedCNPJ == '55555555555555'
      || parsedCNPJ == '66666666666666'
      || parsedCNPJ == '77777777777777'
      || parsedCNPJ == '88888888888888'
      || parsedCNPJ == '99999999999999') { return false; }

  let size = parsedCNPJ.length - 2;
  let numbers = parsedCNPJ.substring(0, size);
  const digits = parsedCNPJ.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) { pos = 9; }
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(0)) { return false; }

  size += 1;
  numbers = parsedCNPJ.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) { pos = 9; }
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != digits.charAt(1)) { return false; }

  return true;
};
