import styled from 'styled-components';

export const WrapperHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperImageWithInfos = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`;

export const WrapperIconChat = styled.div`
`;

export const WrapperInfos = styled.div``;

export const WrapperContent = styled.div`
  width: 100%;
  margin-top: 4px;
`;
