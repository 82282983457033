import React, { useState, useEffect } from 'react';
import { Fade, Grid, Typography, Card, CardContent } from '@mui/material';

import { useDispatch } from 'react-redux';
import loginImg from '../../../assets/images/TelemedicineCall/telemedicine-call.svg';
import videocall from '../../../assets/images/TelemedicineCall/videocam.svg';
import { SecondaryButton } from '../../../utils/theme';

const TelemedicineClickToCall = ({ shortCard, handleCall }) => {
  const dispatch = useDispatch();
  const [isCardVisible, setIsCardVisible] = useState(false);

  useEffect(() => {
    const fetchAttendantsOnline = async () => {
      const responseAttendants = await dispatch.User.attendantsOnline();

      if (responseAttendants && responseAttendants.data) {
        setIsCardVisible(responseAttendants.data.status);
      } else {
        setIsCardVisible(false);
      }
    };

    fetchAttendantsOnline();
  }, []);

  if (shortCard) {
    return (
      <Fade in={isCardVisible} timeout={600}>
        <Grid
          container
          direction='row'
          sx={{ maxWidth: '1280px', marginBottom: '32px', height: isCardVisible ? 'auto' : '0px', transition: 'all 0.2s ease-in-out' }}
        >
          <Typography variant='h6' sx={{ margin: '12px 0px', marginTop: '32px' }}>Seja atendido agora</Typography>
          <Card elevation={0} sx={{ width: '100%', marginTop: '5px' }}>
            <CardContent>
              <Fade in>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{ width: '100%' }}
                  wrap='nowrap'
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img alt='Atendimento veterinário' className='video-call' src={loginImg} style={{ width: '65px' }} />
                    <Typography color='rgba(0, 0, 0, 0.38)' fontStyle='italic' sx={{ margin: '0px 12px' }}>Nossos vets estão online</Typography>
                  </div>
                  <SecondaryButton onClick={() => handleCall()}>
                    <img alt='Viedeochamada' src={videocall} style={{ marginRight: '12px', width: '20px' }} />
                    <Typography variant='caption'>CHAMAR</Typography>
                  </SecondaryButton>
                </Grid>
              </Fade>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
    );
  }

  return (
    <Fade in={isCardVisible} timeout={600}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ height: isCardVisible ? 'auto' : '0px', transition: 'all .2s ease-in-out' }}
      >
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
        >
          <Typography variant='h6' sx={{ margin: '12px 0px', marginTop: '32px' }}>Seja atendido agora</Typography>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ padding: '32px', backgroundColor: 'white' }}
          >
            <img alt='Atendimento veterinário' className='video-call-' src={loginImg} style={{ marginBottom: '20px' }} />
            <Typography color='rgba(0, 0, 0, 0.38)' fontStyle='italic' variant='h6' align='center' sx={{ margin: '22px 0px' }}>Clique em ligar para ser atendido por um nossos veterinários</Typography>
            <SecondaryButton onClick={() => handleCall()}>
              <img alt='Viedeochamada' src={videocall} style={{ marginRight: '12px' }} />
              <Typography variant='h6'>Chamar</Typography>
            </SecondaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default TelemedicineClickToCall;
