/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const FeatureSettings = {
  state: {
    permissions: {},
    loading: {
      getFeatureSettings: '',
    },
  },
  reducers: {
    SET_PERMISSIONS: (state, payload) => ({
      ...state,
      permissions: { ...payload },
    }),
    SET_LOADING: (state, payload) => ({
      ...state,
      loading: { ...state.loading, ...payload },
    }),
  },
  effects: (dispatch) => ({
    async getFeatureSettings() {
      dispatch.FeatureSettings.SET_LOADING({ getFeatureSettings: 'loading' });

      try {
        const response = await axios.get(
          `${baseURL}/feature-settings`,
          headers(),
        );

        if (response && response.data) {
          dispatch.FeatureSettings.SET_LOADING({
            getFeatureSettings: 'fulfilled',
          });
          dispatch.FeatureSettings.SET_PERMISSIONS(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.FeatureSettings.SET_LOADING({
          getFeatureSettings: 'rejected',
        });
        return err;
      }
    },
  }),
};
