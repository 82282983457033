import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

export const Home = () => {
  const history = useHistory();

  useEffect(() => {
    window?.google?.accounts?.id.cancel();
    window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'INICIO_SCREEN', params: null }));

    if (window.location.href.includes(process.env.REACT_APP_BUSINESS)) {
      history.push('/pets');
    }
  }, []);

  return null;
};
