import React from 'react';
import { Card, Grid } from '@mui/material';
import { StyledCardContent, ChoosePartnerText } from './style';

const ServiceEmptyCard = () => (
  <Card
    elevation={0}
    sx={{ marginTop: '16px', marginBottom: '16px', display: 'flex', justifyContent: 'center', bgcolor: 'transparent' }}
  >
    <StyledCardContent>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <ChoosePartnerText>Escolha abaixo uma categoria de serviço para começar</ChoosePartnerText>
      </Grid>

    </StyledCardContent>
  </Card>
);

export default ServiceEmptyCard;
