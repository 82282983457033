import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { HOME_URL } from '../utils/mobile';
import { isAuthenticated, isUserB2B } from '../services/api';

const PrivateB2BRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() && isUserB2B()
      ? <Component {...props} />
      : <Redirect to={{ pathname: HOME_URL, state: { from: window.location.pathname }, url: `${window.location.href}` }} />)}
  />
);

export default PrivateB2BRoute;
