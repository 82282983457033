/* eslint-disable no-console */
export const Modal = {
  state: {
    protocolDialog: {
      open: false,
      content: 'heart_control',
      mode: 'create',
      loading: false,
    },
    protocolResultDialog: {
      open: false,
      content: 'heart_control',
      result: 'good',
      loading: false,
    },
    completeProfileDialog: {
      open: false,
      content: null,
      result: 'good',
      loading: false,
      from: null,
      title: null,
      desc: null,
    },
    completePetDialog: {
      open: false,
      content: null,
      result: 'good',
      loading: false,
    },
    corporateSolicitationPlanDialog: {
      open: false,
      content: null,
      result: 'good',
      loading: false,
    },
    requestSignatureOnAppDialog: {
      open: false,
      content: null,
      result: 'good',
      loading: false,
      protocolId: null,
    },
    loadingOpenDialog: {
      loading: false,
    },
  },
  reducers: {
    SET_DIALOG: (state, payload) => ({ ...state, protocolDialog: { ...state.protocolDialog, ...payload } }),
    SET_RESULT_DIALOG: (state, payload) => ({ ...state, protocolResultDialog: { ...state.protocolResultDialog, ...payload } }),
    SET_PROFILE_DIALOG: (state, payload) => ({ ...state, completeProfileDialog: { ...state.completeProfileDialog, ...payload } }),
    SET_CORPORATE_PLAN_DIALOG: (state, payload) => ({ ...state, corporateSolicitationPlanDialog: { ...state.completeProfileDialog, ...payload } }),
    SET_PET_DIALOG: (state, payload) => ({ ...state, completePetDialog: { ...state.completePetDialog, ...payload } }),
    SET_REQUEST_SIGNATURE_ON_APP_DIALOG: (state, payload) => ({ ...state, requestSignatureOnAppDialog: { ...state.requestSignatureOnAppDialog, ...payload } }),
    SET_LOADING_DIALOG: (state, payload) => ({ ...state, loadingOpenDialog: { ...state.loadingOpenDialog, ...payload } }),
  },
  effects: (dispatch) => ({
    setDialog(payload) {
      dispatch.Modal.SET_DIALOG(payload);
    },
    setResultDialog(payload) {
      dispatch.Modal.SET_RESULT_DIALOG(payload);
    },
    setLoadingDialog(payload) {
      dispatch.Modal.SET_LOADING_DIALOG(payload);
    },
    setProfileDialog(payload) {
      dispatch.Modal.SET_PROFILE_DIALOG(payload);
    },
    setPetDialog(payload) {
      dispatch.Modal.SET_PET_DIALOG(payload);
    },
    setCorporatePlanDialog(payload) {
      dispatch.Modal.SET_CORPORATE_PLAN_DIALOG(payload);
    },
    setRequestSignatureOnAppModal(payload) {
      dispatch.Modal.SET_REQUEST_SIGNATURE_ON_APP_DIALOG(payload);
    },
  }),
};
