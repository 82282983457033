/* eslint-disable no-console */
import axios from 'axios';
import { getToken } from '../../services/api';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const baseURL = process.env.REACT_APP_API;
const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const SigningPlans = {
  state: {
    signingPlans: [],
    selectedPlan: {},
    signingPlanCorporate: {},
    modal: {
      signingTime: {
        open: false,
        data: {},
      },
      signingPayment: {
        open: false,
        data: {
          title: '',
          subtitle: '',
          status: 'error',
        },
      },
      signingConfirmation: {
        open: false,
        data: {},
      },
    },
    loading: {
      signingPlanCorporate: '',
    },
  },
  reducers: {
    SET_SELECTED_PLAN: (state, payload) => ({ ...state, selectedPlan: payload }),
    SET_USER_PLAN_CORPORATE: (state, payload) => ({ ...state, signingPlanCorporate: payload }),
    SET_SIGNIN_PLAN_CORPORATE: (state, payload) => ({ ...state, signingPlanCorporate: { ...state.signingPlanCorporate, signingPlanSubscription: payload } }),
    RESET_SIGNIN_PLAN_CORPORATE: (state) => ({ ...state, signingPlanCorporate: {} }),
    SET_SIGNING_PLAN_LIST: (state, payload) => ({ ...state, signingPlans: payload }),
    SET_MODAL: (state, payload) => ({ ...state, modal: { ...state.modal, ...payload } }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
  },
  effects: (dispatch) => ({
    async signingPlanList() {
      dispatch.SigningPlans.SET_LOADING({ signingPlanList: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/signing-plan/list`, headers());

        if (response && response.data) {
          dispatch.SigningPlans.SET_LOADING({ signingPlanList: 'fulfilled' });
          dispatch.SigningPlans.SET_SIGNING_PLAN_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async signingPlanUpdate(payload) {
      dispatch.SigningPlans.SET_LOADING({ signingPlanUpdate: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/signing-plan/update`, payload, headers());

        if (response && response.data) {
          dispatch.SigningPlans.SET_LOADING({ signingPlanUpdate: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanUpdate: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async signingPlanSubscribe(payload) {
      dispatch.SigningPlans.SET_LOADING({ signingPlanSubscribe: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/payment/signing-plan/subscribe`, payload, headers());

        if (response && response.data) {
          dispatch.SigningPlans.SET_LOADING({ signingPlanSubscribe: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanSubscribe: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async signingPlanManage() {
      dispatch.SigningPlans.SET_LOADING({ signingPlanManage: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/payment/signing-plan/manage`, headers());

        if (response && response.data) {
          Mixpanel.track(MIXPANEL_TAGS.MANAGE_SUBSCRIPTION);
          dispatch.SigningPlans.SET_LOADING({ signingPlanManage: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanManage: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async signingPlanCorporate() {
      dispatch.SigningPlans.SET_LOADING({ signingPlanCorporate: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/signing-plan/corporate`, headers());

        if (response && response.data) {
          // Mixpanel.track(MIXPANEL_TAGS.MANAGE_SUBSCRIPTION);
          dispatch.SigningPlans.SET_USER_PLAN_CORPORATE(response.data);
          dispatch.SigningPlans.SET_LOADING({ signingPlanCorporate: 'fulfilled' });
        } else {
          dispatch.SigningPlans.RESET_SIGNIN_PLAN_CORPORATE();
          dispatch.SigningPlans.SET_LOADING({ signingPlanCorporate: 'rejected' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanCorporate: 'rejected' });
        dispatch.SigningPlans.RESET_SIGNIN_PLAN_CORPORATE();
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async signingPlanCorporateRequest() {
      dispatch.SigningPlans.SET_LOADING({ signingPlanCorporateRequest: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/signing-plan/corporate/request`, {}, headers());

        if (response && response.data) {
          // Mixpanel.track(MIXPANEL_TAGS.MANAGE_SUBSCRIPTION);
          dispatch.SigningPlans.SET_SIGNIN_PLAN_CORPORATE(response.data);
          dispatch.SigningPlans.SET_LOADING({ signingPlanCorporateRequest: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ signingPlanCorporateRequest: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async checkPromotionCodeValid({ coupon, priceId }) {
      dispatch.SigningPlans.SET_LOADING({ checkPromotionCodeValid: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/payment/promotion-code/validate?code=${coupon}&priceId=${priceId}`, headers());

        if (response && response.data) {
          dispatch.SigningPlans.SET_LOADING({ checkPromotionCodeValid: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.SigningPlans.SET_LOADING({ checkPromotionCodeValid: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    setSelectedPlan(payload) {
      dispatch.SigningPlans.SET_SELECTED_PLAN(payload);
    },
    setModal(payload) {
      dispatch.SigningPlans.SET_MODAL(payload);
    },
  }),
};
