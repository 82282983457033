import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import NotificationCardList from '../../components/Notification/NotificationCardList';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Notification = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.NOTIFICATIONS_VIEW);
    dispatch.Notification.clearSummary();
  }, []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <NotificationCardList />
      <Footer />
    </MainWrapper>
  );
};

export default Notification;
