import { Typography } from '@mui/material';
import styled from 'styled-components';

export const PartnerPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 10px;
`;

export const PartnerTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
  }
 `;

export const PartnerDescription = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 166%;
    color: rgba(0, 0, 0, 0.38);
  }
`;

export const PartnerAddress = styled(Typography)`
  &.MuiTypography-root {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 5px;
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.38);
    text-overflow: ellipsis;
  }
`;
