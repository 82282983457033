/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const Business = {
  state: {
    reportData: {},
    reportDataCount: '',
    reportDataContext: 'protocolApplyToExpire',
    loading: {
      animalsWithProtocolNotStarted: '',
      animalsWithProtocolNotStartedCount: '',
      protocolApplyToExpire: '',
      protocolApplyToExpireCount: '',
    },
  },
  reducers: {
    SET_REPORT_COUNT: (state, payload) => ({ ...state, reportDataCount: payload }),
    SET_REPORT_DATA: (state, payload) => ({ ...state, reportData: payload }),
    SET_REPORT_DATA_CONTEXT: (state, payload) => ({ ...state, reportDataContext: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
  },
  effects: (dispatch) => ({
    async animalsWithProtocolNotStarted({ pageNumber, pageLength }) {
      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : 'pagenumber=0';
      const pagelength = pageLength ? `pagelength=${pageLength}` : 'pagelength=10';
      const query = [pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length && `?${query}`;

      dispatch.Business.SET_LOADING({ animalsWithProtocolNotStarted: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/my-business/reports/animals-with-protocol-not-started/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Business.SET_LOADING({ animalsWithProtocolNotStarted: 'fulfilled' });
          dispatch.Business.SET_REPORT_DATA(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Business.SET_LOADING({ animalsWithProtocolNotStarted: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async animalsWithProtocolNotStartedCount() {
      dispatch.Business.SET_LOADING({ animalsWithProtocolNotStartedCount: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/my-business/reports/animals-with-protocol-not-started/count`, headers());

        if (response && response.data) {
          dispatch.Business.SET_LOADING({ animalsWithProtocolNotStartedCount: 'fulfilled' });
          dispatch.Business.SET_REPORT_COUNT(response.data.count);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Business.SET_LOADING({ animalsWithProtocolNotStartedCount: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async protocolApplyToExpire({ pageNumber, pageLength, startDate, endDate }) {
      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : 'pagenumber=0';
      const pagelength = pageLength ? `pagelength=${pageLength}` : 'pagelength=10';
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [pagenumber, pagelength, startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length && `?${query}`;

      dispatch.Business.SET_LOADING({ protocolApplyToExpire: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/my-business/reports/protocol-apply-to-expire/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Business.SET_LOADING({ protocolApplyToExpire: 'fulfilled' });
          dispatch.Business.SET_REPORT_DATA(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Business.SET_LOADING({ protocolApplyToExpire: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async protocolApplyToExpireCount({ startDate, endDate }) {
      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';

      dispatch.Business.SET_LOADING({ protocolApplyToExpireCount: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/my-business/reports/protocol-apply-to-expire/count?${startdate}&${enddate}`, headers());

        if (response && response.data) {
          dispatch.Business.SET_LOADING({ protocolApplyToExpireCount: 'fulfilled' });
          dispatch.Business.SET_REPORT_COUNT(response.data.count);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Business.SET_LOADING({ protocolApplyToExpireCount: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    clearReportData() {
      dispatch.Business.SET_REPORT_DATA({});
    },
    setReportDataContext(payload) {
      dispatch.Business.SET_REPORT_DATA_CONTEXT(payload);
    },
  }),
};
