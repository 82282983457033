/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

const StoreRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://link.guia.vet/app-store';
  }, []);

  return null;
};

export default StoreRedirect;
