import styled from 'styled-components';
import { DialogTitle as MuiDialogTitle, Grid, Typography } from '@mui/material';

import theme from '../../utils/theme';

export const Title = styled(Typography)`
  color: ${theme.DARK_CYAN};
  font-size: 1.5rem;
`;

export const Subtitle = styled(Typography)`
  color: ${theme.MEDIUM_NAVY};
  font-size: 1.25rem;
`;

export const Text = styled(Typography)`
  color: ${theme.MEDIUM_NAVY};
  font-size: 1rem;
`;

export const LabelText = styled(Typography)`
  color: ${theme.DARK_PLATINUM};
  font-size: 0.875rem;
`;

export const DialogTitle = styled(MuiDialogTitle)`
  align-items: center;
  color: ${theme.MEDIUM_NAVY};
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  justify-content: space-between;
`;

export const TitleContainer = styled(Grid)`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 1.5rem;

  @media (min-width: 900px) {
    margin-top: 5rem;
    padding: 0 3.5rem;
  }
`;

export const CardContainer = styled(Grid)`
  background: ${theme.WHITE};
  border-radius: 1.25rem;
  max-width: 768px;
  padding: 0.75rem 1.5rem;

  @media (min-width: 900px) {
    padding: 1.5rem 3.5rem;
  }
  `;

export const CardHeader = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-block: 1rem;

  @media (min-width: 900px) {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
`;

export const FooterContainer = styled(CardContainer)`
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
`;

export const ErrorModalContainer = styled(Grid)`
  background: ${theme.WHITE};
  border-radius: 1.25rem;
  border: 1px solid ${theme.DARK_PLATINUM};
  margin: 2.5rem 1rem;
  max-width: 768px;
  padding: 2.5rem 0.5rem;

  @media (min-width: 900px) {
    padding-inline: 3.5rem;
    margin-inline: 0.5rem;
  }
`;
