import React from 'react';
import { Card } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import PlanAward from '../../../assets/images/UserProfile/award.png';

import { PlanCorporateStatusLabel, PlanDetails, PlanType, PlanTypeControl, PrimaryButton, StyledCardContent } from './style';

const PlanCard = () => {
  const signingPlan = useSelector((state) => state.User.userData.signingPlan);
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);
  const signingPlanCorporate = useSelector((state) => state.SigningPlans?.signingPlanCorporate);
  const userData = useSelector((state) => state.User.userData);

  // const userData = useSelector((state) => state.User.userData);

  const dispatch = useDispatch();
  const history = useHistory();

  const isPremiumSubscription = (!isDefaultPlan && signingPlan?.type === 'recurring');

  const fetchManageURL = async () => {
    if (isPremiumSubscription) {
      return window.ReactNativeWebView?.postMessage(JSON.stringify({ openSigningPlanCancellationModal: { modal: 'signingPlanCancellation' } }));
    }

    history.push('/pricing');

    return null;
  };

  const requestCorporatePlan = async () => {
    const { email, phone, firstName, address, cpfCnpj } = userData;

    if (userData) {
      const isAddressFilled = address && address?.code && (address?.number !== null || address?.number !== undefined) && address?.city && address?.street && address?.district && address?.state;
      const isProfileFilled = firstName && email && phone && isAddressFilled && cpfCnpj;

      if (!isProfileFilled) {
        dispatch.Modal.setProfileDialog({ open: true, from: 'corporate_plan', desc: 'Para solicitar a ativação precisamos de algumas informações do seu perfil' });
        return null;
      }
    }

    const response = await dispatch.SigningPlans.signingPlanCorporateRequest();
    if (response && response.data) {
      dispatch.Modal.setCorporatePlanDialog({ open: true });
    }
    return true;
  };

  return (
    <>
      <Card elevation={0}>
        <StyledCardContent>
          <PlanDetails>
            {
              !isEmpty(signingPlanCorporate) && userData?.signingPlanFromSource === true ? (
                <>
                  <PlanTypeControl>
                    <img src={PlanAward} alt='planAward' />
                    <PlanType>
                      {signingPlanCorporate?.name}
                    </PlanType>
                  </PlanTypeControl>
                  {
                    signingPlanCorporate?.signingPlanSubscription?.status === 'active' ? null : (
                      <>
                        {
                          signingPlanCorporate?.signingPlanSubscription?.status === 'pending' ? (
                            <PlanCorporateStatusLabel>
                              ATIVAÇÃO EM ANDAMENTO
                            </PlanCorporateStatusLabel>
                          ) : (
                            <PrimaryButton onClick={requestCorporatePlan} variant='outlined'>
                              Ativar assinatura
                            </PrimaryButton>
                          )
                        }
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  <PlanTypeControl>
                    <img src={PlanAward} alt='planAward' />
                    <PlanType>
                      {(signingPlan && signingPlan.name) || 'Básico'}
                    </PlanType>
                  </PlanTypeControl>
                  <PrimaryButton onClick={fetchManageURL} variant='outlined'>
                    Gerenciar assinatura
                  </PrimaryButton>
                </>
              )
            }

          </PlanDetails>
        </StyledCardContent>
      </Card>
    </>
  );
};

export default PlanCard;
