/* eslint-disable no-console */
import axios from 'axios';
import { orderBy, uniqBy } from 'lodash';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;
const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const Notification = {
  state: {
    notificationData: [],
    openNotifications: [],
    lastCreatedNotification: {},
    summary: {
      count: 0,
      warningSeverity: '',
      ts: null,
    },
    loading: {
      create: '',
      open: '',
      getSummary: '',
      listAll: '',
    },
  },
  reducers: {
    SET_LAST_CREATED_NOTIFICATION: (state, payload) => ({ ...state, lastCreatedNotification: payload }),
    SET_ALL_NOTIFICATIONS: (state, payload) => {
      let notificationData = [...state.notificationData];
      const isFirstPage = payload.page === 0;

      if (isFirstPage && notificationData.length > 0) {
        notificationData = notificationData.filter((item, index) => index > payload.notifications.length);
      }
      const data = [...notificationData, ...payload.notifications];

      return { ...state, notificationData: orderBy(uniqBy(data, 'id'), ['id'], ['desc']) };
    },
    CLEAR_NOTIFICATIONS: (state) => ({ ...state, notificationData: [] }),
    UPDATE_NOTIFICATIONS: (state, payload) => ({ ...state, notificationData: payload }),
    SET_SUMMARY: (state, payload) => ({ ...state, summary: payload }),
    SET_OPEN: (state, payload) => ({ ...state, openNotifications: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
    SET_READ_BY_ID: (state, payload) => ({ ...state, notificationData: state.notificationData.map((n) => ({ ...n, readDatetime: payload === n.id ? new Date() : n?.readDatetime || null })) }),
  },
  effects: (dispatch) => ({
    async create(payload) {
      dispatch.Notification.SET_LOADING({ create: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/notification/create`, payload, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ create: 'fulfilled' });
          dispatch.Notification.SET_LAST_CREATED_NOTIFICATION(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ create: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async open(payload) {
      dispatch.Notification.SET_LOADING({ open: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/notification/open`, payload, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ open: 'fulfilled' });
          dispatch.Notification.SET_SUMMARY(response.data);
          dispatch.Notification.SET_READ_BY_ID(payload?.id);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ open: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getSummary() {
      dispatch.Notification.SET_LOADING({ getSummary: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/notification/summary`, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ getSummary: 'fulfilled' });
          dispatch.Notification.SET_SUMMARY({ ...response.data, ts: new Date().getTime() });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ getSummary: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listAll({ page }) {
      dispatch.Notification.SET_LOADING({ listAll: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/notification/list-all?pagenumber=${page}&pagelength=10`, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ listAll: 'fulfilled' });
          dispatch.Notification.SET_ALL_NOTIFICATIONS({ notifications: response.data, page });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ listAll: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async markAsRead(payload) {
      dispatch.Notification.SET_LOADING({ read: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/notification/read`, payload, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ read: 'fulfilled' });
          dispatch.Notification.SET_SUMMARY(response.data);
          // const parseNotificationsRead = payload.allNotifications.map((notification) => ({ ...notification, readDatetime: new Date() }));
          // dispatch.Notification.UPDATE_NOTIFICATIONS(parseNotificationsRead);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ read: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updateNotificationList({ page }) {
      dispatch.Notification.SET_LOADING({ listAll: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/notification/list-all?pagenumber=${page}&pagelength=10`, headers());

        if (response && response.data) {
          dispatch.Notification.SET_LOADING({ listAll: 'fulfilled' });
          dispatch.Notification.UPDATE_NOTIFICATIONS(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Notification.SET_LOADING({ listAll: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    updateNotification(payload) {
      dispatch.Notification.UPDATE_NOTIFICATIONS(payload);
    },
    clearSummary() {
      dispatch.Notification.SET_SUMMARY({ count: 0, warningSeverity: '', ts: null });
    },
    clearNotificationList() {
      dispatch.Notification.CLEAR_NOTIFICATIONS();
    },
  }),
};
