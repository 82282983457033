import { Typography } from '@mui/material';
import styled from 'styled-components';
import Slider from 'react-slick';

export const StyledSlider = styled(Slider)`
  & .slick-dots li.slick-active button::before {
    color: #7D5FFE;
    width: 8px;
    height: 8px;
  }

  & .slick-dots li button::before {
    color: #E1DAFF;
  }

  & .slick-slide {
    width: 300px;
  }

  & .slick-list {
    padding: 0 50px;
  }
`;

export const NavigationStage = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 20px;
    color: #C4C4C4;

    small {
      font-size: 10px;
    }
  }
`;

export const SliderGrid = styled.div`
  width: 100%;
`;

export const PlanTypeControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  @media screen and (max-width: 960px) {
    justify-content: center;
  }
`;

export const PlanType = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #47B9AF;
  margin-left: 8px;

  @media screen and (max-width: 360px) {
    font-size: 16px;
  }
`;

export const PlanCorporateStatusLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #FFB931;
`;
