/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Settings, Logout, HelpOutline, ContentPasteOutlined } from '@mui/icons-material';
import { Avatar, Menu, MenuItem, ListItemIcon, Container, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

// import ChatMenu from './ChatMenu';
import { isNative } from '../../../utils/mobile';
import theme, { RouterLink } from '../../../utils/theme';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import NotificationBadge from '../../Notification/NotificationBadge';
import logoHeaderDark from '../../../assets/images/Header/logoHeaderDark.svg';
import { Header, DashboardSection, AvatarMenu, Username, MenuLink, StyledButton } from './style';
import { isAuthenticated } from '../../../services/api';

const DashboardHeader = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [profileImgSrc, setProfileImgSrc] = useState(null);
  const userData = useSelector((state) => state.User.userData);
  const userHasSource = useSelector((state) => state.User.userData?.source?.id);
  // const permissions = useSelector((state) => state?.FeatureSettings?.permissions) || {};

  const RETOOL_PASSWORD = process.env.REACT_APP_RETOOL_PASSWORD;

  const isUserAdmin = userData?.email?.includes('@guia.vet');
  // const isBusinessUrl = window.location.href.includes(process.env.REACT_APP_BUSINESS);

  const logoutUser = () => {
    Mixpanel.track(MIXPANEL_TAGS.SIGNOUT);
    dispatch.User.logout();
  };

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(userData.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!userData?.profileImage && fetchProfileImage();
  }, [userData]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDashboard = () => Mixpanel.track(MIXPANEL_TAGS.ICON_TOP_MENU);

  const onClickProfile = () => Mixpanel.track(MIXPANEL_TAGS.PROFILE_MENU);

  const onClickSupport = () => Mixpanel.track(MIXPANEL_TAGS.SUPPORT_MENU);

  const onClickAttendance = () => {
    if (isNative) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: RETOOL_PASSWORD } }));
      window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: {
        content: process.env.REACT_APP_RETOOL_URL,
      } }));
      setIsOpenDialog(false);
    } else {
      navigator.clipboard.writeText(RETOOL_PASSWORD)
        .then(() => window.open(process.env.REACT_APP_RETOOL_URL, '_blank'))
        .catch(() => window.open(process.env.REACT_APP_RETOOL_URL, '_blank'));
      setIsOpenDialog(false);
      return false;
    }
  };

  useEffect(() => {
    // const properties = {
    //   $first_name: userData.firstName,
    //   $last_name: userData.lastName,
    //   $email: userData.email,
    //   $phone: userData.phone,
    // };

    if (isAuthenticated) {
      dispatch.User.getUserProfileData();
      dispatch.FeatureSettings.getFeatureSettings();

      if (userHasSource) {
        dispatch.SigningPlans.signingPlanCorporate();
      }

      dispatch.Appointments.myServiceListCount();
      !location.pathname.includes('/notifications');
    }

    // Mixpanel.people.set(properties);
  }, []);

  return (
    <>
      <Dialog open={isOpenDialog}>
        <DialogTitle>Ir para Atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A senha para abrir o Prontuário foi copiada para a sua área de transferência, basta apenas colar na tela a seguir
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: theme.warningColor }} onClick={() => setIsOpenDialog(false)}>Cancelar</Button>
          <Button style={{ color: theme.primaryButton }} onClick={onClickAttendance}>Prosseguir</Button>
        </DialogActions>
      </Dialog>

      <Header>
        <Container>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className='container'
          >
            <RouterLink to='/pets' className='logo-desktop' onClick={onClickDashboard}>
              <img alt='Guiavet' src={logoHeaderDark} />
            </RouterLink>
            <RouterLink to='/dashboard' className='logo-mobile' onClick={onClickDashboard}>
              <img alt='Guiavet' src={logoHeaderDark} />
            </RouterLink>

            <DashboardSection>
              {/* {isBusinessUrl || permissions?.headerChatView !== true ? null : <ChatMenu />} */}
              <NotificationBadge />
              <AvatarMenu role='button' onClick={handleClick}>
                <Avatar
                  alt={userData.firstName}
                  src={userData && profileImgSrc}
                />
                <Username>
                  {userData.firstName}
                </Username>
              </AvatarMenu>
            </DashboardSection>

          </Grid>
        </Container>
        <Menu
          style={{ top: '40px' }}
          variant='menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{ elevation: 2 }}
        >
          {isUserAdmin && (
          <MenuItem>
            <StyledButton onClick={() => setIsOpenDialog(true)}>
              <ListItemIcon>
                <ContentPasteOutlined fontSize='small' />
              </ListItemIcon>
              Atendimento
            </StyledButton>
          </MenuItem>
          )}
          <MenuItem>
            <MenuLink to='/profile' onClick={onClickProfile}>
              <ListItemIcon>
                <Settings fontSize='small' />
              </ListItemIcon>
              Meu perfil
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink to={{ pathname: 'https://help.guia.vet/' }} target='_blank' onClick={onClickSupport}>
              <ListItemIcon>
                <HelpOutline fontSize='small' />
              </ListItemIcon>
              Suporte
            </MenuLink>
          </MenuItem>
          <MenuItem onClick={logoutUser}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Sair
          </MenuItem>
        </Menu>
      </Header>
    </>
  );
};

export default DashboardHeader;
