import { Button, CardContent } from '@mui/material';
import styled from 'styled-components';
import { animations, SecondaryButton } from '../../../../utils/theme';

export const EditArea = styled.div`
float: left;
padding: 10px;
`;

export const StyledCardContent = styled(CardContent)`
    &.MuiCardContent-root {
      width: 90%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      height: 370px;
      width: 325px;
      animation: ${animations.fadeIn} 0.3s ease-in-out;

      @media screen and (max-width: 736px) {
          height: 172px;
          width: 100%;
      }
    }

`;

export const HealthControlButton = styled(SecondaryButton)`
    margin-bottom: ${({ askQuestionPermission, isB2B }) => (askQuestionPermission || isB2B ? '0px' : '40px !important')};
    margin-top: ${({ askQuestionPermission, isB2B }) => (askQuestionPermission || isB2B ? '14px !important' : '30px !important')};
    transition: box-shadow .3s ease-in;

    &:hover {
        box-shadow: -1px 11px 8px -5px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 736px) {
        display: none !important;
    }
`;

export const TeleOrientationButton = styled(Button)`
  &.MuiButtonBase-root {
    display: ${({ askQuestionPermission }) => (askQuestionPermission ? 'flex' : 'none')};
    justify-content: space-around;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    font-weight: 500;
    padding: 7px 15px;
    margin-top: 30px;
    width: 215px;
    z-index: 5;
    height: 40px;
    transition: box-shadow .3s ease-in;

    &:hover {
        background-color: white;
        box-shadow: -1px 11px 8px -5px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 736px) {
        display: none;
        margin-top: 10px;
    }

    svg {
      padding: 5px 0px 5px 0px;
      width: 18px;
      height: 18px;
      min-height: 18px;
      min-width: 18px;
    }
  }
`;
