/* eslint-disable no-sparse-arrays */
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledCard, StyledCardContent, StyledStack, StyledChip, ContactInfoBox, MenuContainer } from './style';
import { formatPhone } from '../../../../utils/format';
import theme from '../../../../utils/theme';

const NetworkCard = ({ id, name, email, type, status, phone, inactivatedById }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState([]);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { id: userId } = useSelector((state) => state.User.userData);
  const inactivatePermission = userId === inactivatedById;

  const userTypeMap = {
    T: 'Tutor',
    V: 'Veterinário',
    C: 'Clínica',
    PS: 'Pet shop',
    DC: 'Day care',
    BT: 'Banho e Tosa',
    A: 'Adestrador',
    SI: 'Pet Sitter',
    OP: 'Operador',
    O: 'Outros',
  };

  const statusMap = {
    pending: {
      color: theme.pending,
      translation: 'pendente',
    },
    scheduled: {
      color: theme.caption,
      translation: 'agendado',
    },
    active: {
      color: theme.success,
      translation: 'ativo',
    },
    inactive: {
      color: theme.error,
      translation: 'inativo',
    },
    refused: {
      color: theme.error,
      translation: 'recusado',
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (context) => {
    setAnchorEl(null);

    let data = {};

    if (context === 'reinvite') {
      data = {
        inviteId: id,
        name,
        email,
        userType: type,
        status,
        phone: Number(phone) ? String(phone).replace(/[^A-Z0-9]/ig, '') : '',
      };
    } else if (context === 'reconnect' || context === 'disconnect' || context === 'cancel') {
      data = {
        id,
        username: name,
      };
    }

    dispatch.Network.setModal({ networkModal: { open: true, context, data } });
  };

  useEffect(() => {
    const menus = [];

    if (status === 'scheduled') {
      menus.push([
        ...menus,
        <MenuItem onClick={() => handleAction('reinvite')}>Reenviar convite</MenuItem>,
        <MenuItem onClick={() => handleAction('cancel')}>Cancelar convite</MenuItem>,
      ]);
    }

    if (status === 'pending' && (type === '' || type === 'T')) {
      menus.push([
        ...menus,
        <MenuItem onClick={() => handleAction('reinvite')}>Reenviar convite</MenuItem>,
      ]);
    }

    if (status === 'active') {
      menus.push([...menus, <MenuItem onClick={() => handleAction('disconnect')}>Desconectar</MenuItem>]);
    }

    if (status === 'inactive' && inactivatePermission) {
      menus.push([...menus, <MenuItem onClick={() => handleAction('reconnect')}>Reconectar contato</MenuItem>]);
    }

    setMenu(menus);
  }, [, status, userId]);

  return (
    <StyledCard elevation={0}>
      <StyledCardContent>
        <ContactInfoBox>
          <Typography variant='subtitle2'>
            {name || (email || (phone || ''))}
          </Typography>
          <Typography variant='body2' sx={{ marginBottom: '23px' }}>{userTypeMap[type]}</Typography>
          {(name && phone) && <Typography variant='body2'>{formatPhone(phone)}</Typography>}
          {(name && email) && <Typography variant='body2'>{email}</Typography>}
        </ContactInfoBox>

        <StyledStack>
          <StyledChip
            label={status && statusMap[status]?.translation ? statusMap[status].translation : ''}
            sx={status && statusMap[status]?.color ? { color: 'white', backgroundColor: statusMap[status].color } : {}}
            size='small'
          />
          {menu.length ? (
            <MenuContainer>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              >
                {menu}
              </Menu>
            </MenuContainer>
          ) : <></>}
        </StyledStack>
      </StyledCardContent>
    </StyledCard>
  );
};

export default NetworkCard;
