import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../services/api';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated()
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', search: `${window.location.search}`, state: { from: window.location.pathname }, url: `${window.location.href}` }} />)}
  />
);

export default PrivateRoute;
