import { Button, CardContent, Chip, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { PetPhoto, StyledCard } from './style';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/Appointments/calendar.svg';

const ScheduledAppointmentCard = ({ id, animalId, animalPhoto, tutorName, status, animalName, period, sentDatetime, communicationChannel, notificationList, serviceName }) => {
  const dispatch = useDispatch();
  const { native } = useSelector((state) => state.User.userData);

  const openAttendanceModal = () => dispatch.Appointments.setModal(
    { attendanceModal: { open: true, data: { id, animalPhoto, tutorName, status, animalName, period, sentDatetime, communicationChannel, notificationList, serviceName } } },
  );

  const redirectToPetPage = () => window.open(`/pet/${animalId}`, native ? '_self' : '_blank');

  const statusMap = {
    Cancelled: {
      label: 'Cancelado',
      color: '#F44336',
    },
    Done: {
      label: 'Feito',
      color: '#4CAF50',
    },
    'No Show': {
      label: 'Não compareceu',
      color: '#FF9800',
    },
    Pending: {
      label: 'Baixa pendente',
      color: '#AAAAAA',
    },
  };

  return (
    <StyledCard elevation={0} status={status}>
      <CardContent elevation={0}>
        <Grid container direction='row' sx={{ paddingTop: '20px' }}>
          <Grid container direction='row' justifyContent='space-between' sx={{ padding: '0px 10px' }}>
            <Grid item>
              <Grid container direction='row' gap={2} alignItems='center'>
                <Grid item>
                  <PetPhoto src={animalPhoto} alt='petPhoto' />
                </Grid>

                <Grid item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography variant='body1' sx={{ fontSize: '20px', fontWeight: '400px' }}>{animalName}</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant='body2'>{tutorName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction='column'>
                <Grid item>
                  <Grid container direction='row' alignItems='center' justifyContent='flex-end' gap={2}>
                    <Grid item>
                      <Chip size='small' label={statusMap[status].label} sx={{ padding: '3px 4px', verticalAlign: 'middle', color: 'white', backgroundColor: statusMap[status].color }} />
                    </Grid>

                    <Grid item>
                      <Typography variant='h5'>{moment(period).utc().format('HH:mm')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  {sentDatetime ? (
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                      <Grid item>
                        <NotificationsIcon sx={{ stroke: '#B3B3B3', strokeWidth: '2.5px', fill: 'transparent', marginRight: '8px' }} />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle2' sx={{ fontSize: '14px', color: '#B3B3B3' }}>
                          Notificado em:
                          {' '}
                          {moment(sentDatetime).format('DD/MM/YYYY HH:mm')}
                          {' '}
                          via
                          {' '}
                          {communicationChannel}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : <></>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '11px 0 19px 0', color: '#6B48FF' }} />

        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            <Button
              onClick={redirectToPetPage}
              size='large'
              sx={{ marginLeft: '10px', color: '#7E8192' }}
            >
              PERFIL DO PET
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled={status !== 'Pending'}
              onClick={openAttendanceModal}
              size='large'
              sx={{ color: '#6B48FF' }}
            >
              DETALHES DO ATENDIMENTO
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

const ScheduleAppointmentCard = ({ period }) => {
  const dispatch = useDispatch();
  const openScheduleModal = () => dispatch.Appointments.setModal({ scheduleModal: { open: true, data: { period }, context: 'schedule' } });

  return (
    <StyledCard style={{ background: 'white' }}>
      <CardContent>
        <Grid container direction='row' justifyContent='space-between' sx={{ padding: '30px 17px 0 17px' }}>
          <Grid item>
            <Typography variant='body1' sx={{ fontSize: '20px' }}>Livre</Typography>
          </Grid>

          <Grid item>
            <Typography variant='h5'>{moment(period).utc().format('HH:mm')}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '41px 0 19px 0', color: '#6B48FF' }} />

        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item>
            <Button
              onClick={openScheduleModal}
              startIcon={(
                <CalendarIcon
                  sx={{ fill: '#6B48FF' }}
                />
)}
              size='large'
              sx={{ color: '#6B48FF' }}
            >
              agendar compromisso
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

// eslint-disable-next-line react/destructuring-assignment
const AppointmentCard = (props) => (props.hasBeenScheduled ? <ScheduledAppointmentCard {...props} /> : <ScheduleAppointmentCard {...props} />);

export default AppointmentCard;
