import { Button } from '@mui/material';
import styled from 'styled-components';

import theme from '../../../../utils/theme';

export const Form = styled.form`
    @media screen and (max-width: 736px) {
      width: 89%;
      margin-top: 0px;
      max-height: 90vh;
      top: 0;
      left: 0;
      transform: none;
      overflow-y: scroll;
    }

    @media screen and (max-height: 845px) {
      height: auto;
      max-height: 60vh;
      overflow-y: scroll;
    }
`;

export const ApplyCoupon = styled(Button)`
  &.MuiButton-root {
    background: ${theme.primaryButton};
    color: ${theme.backgroundPaper};
    width: 90px;
    height: 40px;
  }

  &.MuiButton-root:hover {
    background: ${theme.primaryButton};
    color: ${theme.backgroundPaper};
  }

  &.MuiButton-root:disabled {
    background: ${theme.primaryButton};
    color: ${theme.backgroundPaper};
  }
`;

export const CouponButton = styled(Button)`
    &.MuiButton-root {
    background: rgba(26,26,26,.05);
    color: ${theme.fontColorDark};
    width: 250px;
    height: 50px;
    border-radius: 10px;
  }

  &.MuiButton-root:hover {
    background: rgba(26,26,26,.1);
    color: ${theme.fontColorDark};
  }
`;
