/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../../../global/Alert';
import { ModalBox, CloseModal, ButtonControl, ButtonModal } from './style';
import Loading from '../../../global/Loading';
import theme from '../../../../utils/theme';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const ReinviteContent = () => {
  const dispatch = useDispatch();
  const { inviteId, name, email, phone, status, animalIds, scheduled, sendDatetime } = useSelector((state) => state.Network.modal.networkModal.data) || '';

  const [submitedStatus, setSubmitedStatus] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  const handleSubmitDisconnect = async () => {
    setSubmitLoading(true);
    Mixpanel.track(MIXPANEL_TAGS.RESEND_INVITE_CLICK);

    const payload = {
      inviteId,
      name,
      email,
      phone,
      status,
      userType: 'T',
      animalId: animalIds,
      scheduled,
      sendDatetime,
    };

    const responseInvite = await dispatch.Network.sendInvite(payload);

    if (responseInvite && responseInvite.data) {
      dispatch.Network.updateNetworkContactStatus(responseInvite.data);
      setSubmitedStatus('success');
      setTimeout(() => closeModal(), 1000);
    } else {
      setSubmitedStatus('error');
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.RESEND_INVITE_MODAL_LOAD);
  }, []);

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Grid container flexDirection='column' alignItems='center' justifyContent='center' fullWidth sx={{ marginTop: '60px' }}>
          <Grid item sx={{ marginBottom: '37px' }}>
            <PeopleOutlineIcon sx={{ width: '100px', height: '62px', fill: '#47B9AF' }} />
          </Grid>

          <Grid item sx={{ marginBottom: '19px' }}>
            <Typography variant='h5' sx={{ fontFamily: 'Inter', color: theme.MEDIUM_NAVY }}>
              Reenviar convite agora?
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: '55px' }}>
            <Typography
              variant='body1'
              sx={{ fontFamily: 'Inter', color: theme.DARK_PLATINUM, textAlign: 'center' }}
            >
              Se ele não foi aceito, será reenviado automaticamente após 24 horas, 48 horas e 7 dias do primeiro envio
            </Typography>
          </Grid>
        </Grid>

        <ButtonControl sx={{ marginBottom: '100px' }}>
          {submitLoading ? (
            <Grid sx={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '45px' }}>
              <Loading size={30} />
            </Grid>
          ) : (
            <Grid container justifyContent='space-around'>
              <ButtonModal style={{ color: theme.error, fontWeight: 500 }} type='button' onClick={closeModal}>Sair</ButtonModal>
              <ButtonModal style={{ color: theme.GUIAVET, fontWeight: 500 }} type='submit' mainButton onClick={handleSubmitDisconnect}>Reenviar mesmo assim</ButtonModal>
            </Grid>
          )}
        </ButtonControl>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Reenvio realizado com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha ao reenviar convite
              </Alert>
            </div>
          )}
        </Snackbar>
      </Grid>
    </>
  );
};

export default ReinviteContent;
