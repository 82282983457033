import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    transition: background-color .1s ease-in, box-shadow .1s ease-in;
    &:hover {
      background-color: #f2f0fa;
      -webkit-box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
    }
  }
`;

export const StyledTableActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
`;
