import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const theme = {
  header: '#FDF9F5',
  menuIndicator: '#6B48FF',
  footerIndicator: '#6B48FF',
  facebookButton: '#1877F2',
  facebookButtonHover: '#1153a8',
  googleButton: '#FFFF',
  googleButtonHover: '#ffffff10',
  backgroundDefault: '#FDF9F5',
  backgroundPaper: '#FFFFFF',
  buttonRadius: '20px',
  buttonPadding: '8px 20px',
  primaryButton: '#6B48FF',
  secondaryButton: '#FFFFFF',
  linkColor: '#007DFF',
  warningColor: '#FF505F',
  successColor: '#4CAF50',
  fontColorPurple: '#6B48FF',
  fontColorFooter: '#757575',
  fontColorDark: '#000000',
  fontColorLight: '#FFFFFF',
  title: '#4A5056',
  caption: '#7e8192',
  pending: '#FFB931',
  success: '#91DA82',
  error: '#ED705C',

  GUIAVET: '#6B48FF',
  LIGHT_PLATINUM: '#EAEAEA',
  WHITE: '#FFFFFF',

  LIGHT_PURPLE: '#D9D0FF',
  MEDIUM_PURPLE: '#947BFF',
  LIGHT_NAVY: '#505DA7',
  MEDIUM_NAVY: '#434977',
  DARK_NAVY: '#33364E',
  LIGHT_CYAN: '#1FE0CC',
  MEDIUM_CYAN: '#47B9AF',
  DARK_CYAN: '#22978B',

  LIME_GREEN: '#C5CF64',
  HAZELNUT_ORANGE: '#F2A875',
  PENELOPE_PINK: '#EF82B0',
  CRAYOLA_YELLOW: '#F2C94B',
  SKY_BLUE: '#63DDFC',

  LIGHT_WINTER: '#D8F7FF',
  MEDIUM_WINTER: '#8698FC',
  MEDIUM_PLATINUM: '#D1D2E0',
  DARK_PLATINUM: '#9EA0BE',
  BABY_POWDER: '#FDFAF5',

  SUCCESS_PRIMARY: '#91DA82',
  SUCCESS_SECONDARY: '#E7FFDF',
  INFO_PRIMARY: '#FFB931',
  INFO_SECONDARY: '#FFF6DF',
  WARNING_PRIMARY: '#ED705C',
  WARNING_SECONDARY: '#FFE7DF',
};

export default theme;

export const datePickerErrorStyle = {
  width: '100%',
  marginTop: 3,
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
  '& .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f' },
  '& .MuiFormLabel-root': { color: '#d32f2f' },
  '& .MuiFormHelperText-root': { marginLeft: 0, color: '#d32f2f' },
};

export const PrimaryButton = styled(Button)`
  background-color: ${theme.primaryButton} !important;
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.fontColorLight} !important;
  opacity: ${(props) => (props.disabled ? '0.5' : '1.0')};
  padding: ${theme.buttonPadding} !important;
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${theme.secondaryButton} !important;
  color: ${theme.primaryButton} !important;
  border: 1px solid ${theme.primaryButton} !important;
  border-radius: 111px !important;
`;

export const CancelButton = styled(PrimaryButton)`
  background-color: transparent !important;
  color: rgba(238, 111, 91, 1) !important;
  border-radius: 111px !important;
`;

export const FacebookButton = styled(PrimaryButton)`
	width: 100%;
	font-size: 16px !important;
	font-weight: normal !important;
	background-color: ${theme.facebookButton} !important;
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: ${(props) => (props.primary ? theme.primaryButton : theme.linkColor)};
`;

export const TermsLink = styled.a`
  text-decoration: none;
  color: #6B48FF;
`;

export const RouterLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

export const animations = {
  fadeIn: keyframes`
		from { opacity: 0 }
		to { opacity: 1 }
	`,
  blink: keyframes`
		0% { background-color: white }
		50% { background-color: rgba(236, 244, 251, 1) }
		100% { background-color: white }
	`,
  bellRing: keyframes`
    0% { transform: rotate(0deg); }
    10% { transform: rotate(20deg); }
    20% { transform: rotate(-20deg); }
    30% { transform: rotate(20deg); }
    40% { transform: rotate(-20deg);}
    50% { transform: rotate(0deg);}
  `,
};
