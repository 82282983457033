export const handlePlatform = () => {
  const userAgent = navigator?.userAgent;

  if (userAgent?.includes('wv') && userAgent.includes('Android')) {
    return 'mobile_android';
  }

  if (userAgent?.includes('iPhone')) {
    return 'mobile_ios';
  }

  return 'web';
};
