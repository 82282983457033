import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleModalContent from './ScheduleModalContent';
import ErrorModalContent from './ErrorModalContent';

const AppointmentScheduleModal = () => {
  const dispatch = useDispatch();
  const { open, context } = useSelector((state) => state.Appointments.modal.scheduleModal) || {};

  const closeModal = () => dispatch.Appointments.setModal({ scheduleModal: { open: false } });

  const renderModalContent = () => {
    switch (context) {
      case 'schedule':
        return <ScheduleModalContent />;
      case 'error':
        return <ErrorModalContent />;
      default:
        return <div />;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default AppointmentScheduleModal;
