import React, { useEffect } from 'react';

import { Container, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../../components/Footer';
import Loading from '../../components/global/Loading';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import MobileAppDialog from '../../components/Login/LoginForm/MobileAppDialog';

import PlanCard from './PlanCard';
import CardProfile from './CardProfile';
import ProfileManageArea from './ProfileManageArea';

import { IntercomCustom } from '../../utils/intercom';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import CompleteProfileDialog from '../../components/Modals/CompleteProfileDialog';
import CorporateSolicitationPlanDialog from '../../components/Modals/CorporateSolicitationPlanDialog';

import { MainWrapper, StyledGrid, ProfileGrid } from './style';

const ProfileManage = () => {
  IntercomCustom.signoutIntercom();

  const dispatch = useDispatch();
  const { isB2B } = useSelector((state) => state.User.userData);
  const { getUserData } = useSelector((state) => state.User.loading);

  useEffect(() => {
    dispatch.User.getUserProfileData();
    Mixpanel.track(MIXPANEL_TAGS.PROFILE_VIEW);
  }, []);

  if (getUserData === 'loading') {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <CompleteProfileDialog />
      <CorporateSolicitationPlanDialog />
      <Container style={{ marginBlock: '20px' }}>
        <StyledGrid container direction='column' justifyContent='center' alignItems='flex-end' spacing={2}>
          <MobileAppDialog />
          <ProfileGrid container direction='row' justifyContent='center' alignItems='flex-start' spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CardProfile />

              {!isB2B ? (
                <Grid item style={{ marginTop: '20px' }}>
                  <PlanCard />
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <ProfileManageArea />
            </Grid>
          </ProfileGrid>
        </StyledGrid>
      </Container>
      <Footer />
    </MainWrapper>
  );
};

export default ProfileManage;
