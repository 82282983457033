/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import { IntercomCustom } from '../../utils/intercom';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { HelperText } from '../../components/Modals/ProtocolDialog/BodyScore/style';
import CancelOrSubmit from '../../components/global/CancelOrSubmit';
import { isNative } from '../../utils/mobile';

const TestPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.protocolApplyId);
  const editLastAmountPerDay = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastAmountPerDay);

  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const [isFirstProtocolOrRenew] = useState(mode && (mode === 'create' || mode === 'renew'));

  const chatButton = (chat) => {
    window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
    IntercomCustom.signinIntercom({ bot: chat });
    Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
    if (!isNative) {
      setTimeout(() => IntercomCustom.customButtonAndStart(chat), 300);
    }
  };

  const submitForm = async (formData, { setErrors }) => {
    setLoading(true);

    const { lastDoseDate, nextDoseDate, amountPerDay } = formData;

    setErrors({ photoName: false });

    const payload = {
      protocolId: id,
      ...(isFirstProtocolOrRenew && protocolApplyId && { protocolApplyId }),
      ...(nextDoseDate && { nextDoseDate }),
      amountPerDay,
      appliedDatetime: moment(lastDoseDate).toISOString(),
    };

    if (isFirstProtocolOrRenew) delete payload.protocolApplyId;

    const requestApplyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (requestApplyAnimalProtocol && requestApplyAnimalProtocol.data) {
      dispatch.Modal.setDialog({ open: false });
    }

    setLoading(false);
  };

  return (
    <MainWrapper>
      <Grid alignItems='center'>
        <Grid item>
          <Typography variant='h3'>Página de teste</Typography>

          <Button variant='small' sx={{ backgroundColor: '#FFFFFF' }} onClick={() => chatButton('chat-triage-app')}>Chat Triage App</Button>
          <Button variant='small' sx={{ backgroundColor: '#FFFFFF' }} onClick={() => chatButton('chat-triage-site')}>Chat Triage Site</Button>
        </Grid>

        <Grid sx={{ width: '100%' }}>
          <Grid container flexDirection='column' justifyContent='flex-start'>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={
            (isFirstProtocolOrRenew
                && { lastDoseDate: null, nextDoseDate: null })
                || { amountPerDay: editLastAmountPerDay }
          }
              onSubmit={submitForm}
              validationSchema={
            Yup.object().shape({
              amountPerDay: Yup.number().nullable().required('Favor digitar a quantidade'),
            })
          }
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <Grid sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                    <TextField
                      id='amountPerDay'
                      type='number'
                      name='amountPerDay'
                      label='Quantidade por dia (g)'
                      placeholder='Digite a quantidade dada por dia'
                      error={!!errors.amountPerDay}
                      helperText={errors.amountPerDay && errors.amountPerDay}
                      value={values.amountPerDay}
                      onChange={(event) => setFieldValue('amountPerDay', event.target.value)}
                      fullWidth
                    />
                    <HelperText>
                      Consultar na embalagem do produto
                    </HelperText>
                  </Grid>
                  <CancelOrSubmit loading={loading} />
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </MainWrapper>
  );
};

export default TestPage;
