import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { Select } from '@mui/material';
import theme from '../../../../utils/theme';

export const ModalBox = styled(Box)`
    &.MuiBox-root {
      background-color: white;
      margin-top: 3px;
      position: absolute;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 550px;
      height: auto;
      padding: 30px 0;

      @media screen and (max-width: 736px) {
        width: 100%;
        margin-top: 60px;
        max-height: 90vh;
        top: 0;
        left: 0;
        transform: none;
      }

      @media screen and (max-height: 736px) and (min-width: 736px) {
        top: 45%;
      }

    & .MuiTypography-h6 {
        margin-left: 30px;
    }
`;

export const DisconnectTextControl = styled(Box)`
    width: 347px;
    text-align: center;
    padding: 53px;
    margin: 0 auto;

    @media screen and (max-width: 736px) {
      padding: 53px 0px;
    }

    @media screen and (max-width: 400px) {
      width: 300px;
    }

    cursor: default;

    bold {
      color: ${theme.fontColorPurple}};
    }
`;

export const Form = styled.form`
    padding: 0 30px;

    @media screen and (max-width: 736px) {
      width: 89%;
      margin-top: 0px;
      max-height: 90vh;
      top: 0;
      left: 0;
      transform: none;
      overflow-y: scroll;
      padding-left: 20px;
    }

    @media screen and (max-height: 845px) {
      height: auto;
      max-height: 60vh;
      overflow-y: scroll;
    }
`;

export const ButtonControl = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const ResponsibleSelect = styled(Select)`
    & .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
`;

export const ResponsibleAvatar = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: #6B48FF;
  outline: 0;
  cursor: pointer;

  &:focus, &:active, &:hover {
    outline: 0;
  }
`;
