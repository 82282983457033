import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { StyledContainer, StyledTableContainer, TableHeader } from './style';
import UnfilledProtocolTableRow from './UnfilledProtocolTableRow';
import Loading from '../../global/Loading';

const UnfilledProtocolsTable = () => {
  const dispatch = useDispatch();
  const { reportData, reportDataCount } = useSelector((state) => state.Business);
  const { animalsWithProtocolNotStarted } = useSelector((state) => state.Business.loading);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isLoading = animalsWithProtocolNotStarted !== 'fulfilled';

  const fetchReportDataCount = async () => {
    await dispatch.Business.animalsWithProtocolNotStartedCount();
  };

  const fetchReportData = async (nextPage) => {
    dispatch.Business.clearReportData();
    dispatch.Business.setReportDataContext('');

    const response = await dispatch.Business.animalsWithProtocolNotStarted({ pageNumber: nextPage, pageLength: rowsPerPage });

    if (response && response.data) {
      setPage(nextPage);
    }
  };

  useEffect(() => {
    fetchReportDataCount();
    fetchReportData(page);
  }, [rowsPerPage]);

  return (
    <>
      <StyledContainer sx={{ width: '50%' }}>
        {isLoading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            sx={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            <StyledTableContainer component={Paper} sx={{ width: '100%' }}>
              <TableHeader>
                <Typography variant='h6'>Protocolos não preenchidos</Typography>
              </TableHeader>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component='div'
                count={reportDataCount}
                labelRowsPerPage='Linhas por página'
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={({ from, to, count: labelCount }) => (`${from}–${to} de ${labelCount !== -1 ? labelCount : `maior que ${to}`}`)}
                onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                page={page}
                onPageChange={(event, nextPage) => fetchReportData(nextPage)}
              />
              <Table sx={{ minHeight: 100 }} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Animal</TableCell>
                    <TableCell>Espécie</TableCell>
                    <TableCell>Raça</TableCell>
                    <TableCell>Idade</TableCell>
                    <TableCell>Protocolo</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.length ? reportData.map(({ customerId, ...reportProps }) => (
                    <UnfilledProtocolTableRow
                      key={customerId}
                      customerId={customerId}
                      {...reportProps}
                    />
                  )) : <></>}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default UnfilledProtocolsTable;
