import styled from 'styled-components';
import { Box } from '@mui/system';
import theme, { RouterLink } from '../../../utils/theme';

export const StyledBox = styled(Box)`
  min-height: 60px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  .wrapper {
    font-size: 16px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 739px) {
    display: none;
  }
`;

export const MenuLink = styled(RouterLink)`
	opacity: ${(props) => (props.route.isSame ? '1' : '0.5')};
  border-bottom: ${(props) => (props.route.isSame ? `4px solid ${theme.menuIndicator}` : 'none')};
  border-radius: 2px;
  padding-bottom: 5px;
	transition: opacity 0.2s ease-in;
  outline: 0;

  &:hover, &:active, &:focus {
    outline: 0;
  }

	&:hover {
		opacity: 1;
	}

	:nth-child(1) {
		margin-left: 0;
    opacity: ${(props) => (props.route.isSame || props.route.path.includes('/pet') ? '1' : '0.5')};
    border-bottom: ${(props) => (props.route.isSame || props.route.path.includes('/pet') ? `4px solid ${theme.menuIndicator}` : 'none')};
	}

	& {
		margin-left: 32px;
	}
`;
