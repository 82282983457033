import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../../../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../../../components/Dashboard/DashboardMenu';
import Footer from '../../../../components/Footer';
import { MainWrapper } from './style';
import EventDetail from '../../../../components/Services/Event/EventDetail';
import Loading from '../../../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const EventDetailPage = () => {
  const [loading] = useState(false);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.EVENT_DETAIL_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <EventDetail />
      )}

      <Footer />
    </MainWrapper>
  );
};

export default EventDetailPage;
