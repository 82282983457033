import { Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Pricing, PricingBanner, PricingTrophy, PricingCategory, PricingDescription, PricingDefault, PricingPrice, PricingSubscribe, PricingTitle, PricingSubscribeDefault, PricingSecondary } from './style';
import trophy from '../../../../assets/images/Pricing/trophy.png';
import trophyIcon from '../../../../assets/images/Pricing/trophyIcon.svg';
import collarIcon from '../../../../assets/images/Pricing/collarIcon.svg';
import houseIcon from '../../../../assets/images/Pricing/houseIcon.svg';
import foodIcon from '../../../../assets/images/Pricing/foodIcon.svg';
import { isAuthenticated } from '../../../../services/api';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const PricingCard = ({ name, defaultPrice, prices, description, selected, isBestOption, isPromotion, promotionMonthlyPrice, promotionTerms, isActual, width, center, setStep, index }) => {
  const integerPrice = (defaultPrice?.price && defaultPrice.price.toFixed(2).split('.')[0]) || '';
  const decimalPrice = (defaultPrice?.price && defaultPrice.price.toFixed(2).split('.')[1]) || '';
  const integerPromotionalPrice = (promotionMonthlyPrice && promotionMonthlyPrice.toFixed(2).split('.')[0]) || '';
  const decimalPromotionalPrice = (promotionMonthlyPrice && promotionMonthlyPrice.toFixed(2).split('.')[1]) || '';
  const dispatch = useDispatch();
  const history = useHistory();
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const iconMap = {
    'melhor amigo': foodIcon,
    campeão: trophyIcon,
    companheiro: houseIcon,
    'bom garoto': collarIcon,
    básico: collarIcon,
  };

  const handlePayment = async (priceId, isRecurrent) => {
    const planHasMultipleSubscriptionTime = prices.length > 1;

    const proceedPaymentFlow = () => {
      if (planHasMultipleSubscriptionTime) {
        dispatch.SigningPlans.setModal({ signingTime: { open: true, data: prices, promotionTerms, promotionMonthlyPrice, isPromotion } });
        return;
      }

      dispatch.SigningPlans.setSelectedPlan({ priceId, isRecurrent, price: prices[0].price });
      dispatch.SigningPlans.setModal({
        signingTime: { open: false, data: {} },
        signingConfirmation: { open: true, data: {} },
      });
    };

    const redirectAndLoginFlow = async () => {
      let modalToOpenAfterLogin;
      let eventTrigger;

      if (planHasMultipleSubscriptionTime) {
        modalToOpenAfterLogin = { signingTime: { open: true, data: prices, promotionTerms, promotionMonthlyPrice, isPromotion } };

        eventTrigger = {
          triggerName: 'checkout-payment',
          type: 'redirect',
          urlPattern: window.location.pathname,
          payload: {
            modalToOpenAfterLogin,
          },
        };
      } else {
        const selectedPlan = { priceId, isRecurrent };
        modalToOpenAfterLogin = {
          signingTime: { open: false, data: {} },
          signingConfirmation: { open: true, data: {} },
        };

        eventTrigger = {
          triggerName: 'checkout-payment',
          type: 'redirect',
          urlPattern: window.location.pathname,
          payload: {
            selectedPlan,
            modalToOpenAfterLogin,
          },
        };
      }

      await dispatch.User.clearEventTrigger();
      await dispatch.User.setLastEventTrigger(eventTrigger);

      history.push('/login');
    };

    if (isAuthenticated()) {
      proceedPaymentFlow();
    } else {
      redirectAndLoginFlow();
    }
  };

  const handleSubscribe = () => {
    Mixpanel.track(MIXPANEL_TAGS.PLAN_CLICK, { signingPlanName: name });
    if (isActual || !prices) {
      return;
    }

    handlePayment(prices[0].priceId, prices[0].isRecurrent);
  };

  return (
    <Pricing
      onMouseOver={() => setStep(index)}
      elevation={0}
      $selected={selected}
      $bestPlan={isBestOption}
      sx={{ '&.MuiCard-root': { width, alignItems: center && 'center' } }}
    >
      {isBestOption && <PricingBanner src={trophy} alt='bestOptionTrophy' />}
      <PricingTrophy
        // eslint-disable-next-line no-nested-ternary
        src={isBestOption ? trophyIcon : iconMap[name.toLowerCase()] ? iconMap[name.toLowerCase()] : collarIcon}
        alt='optionIcon'
      />

      <PricingTitle $bestPlan={isBestOption}>{name}</PricingTitle>

      <PricingCategory $bestPlan={isBestOption}>{(name && name.toLowerCase() === 'campeão' && 'Teleconsultas, checkups, prescrições online e muito mais. **') || description}</PricingCategory>

      <Grid>
        {defaultPrice?.price ? (
          <>
            <PricingDescription $bestPlan={isBestOption} sx={{ marginTop: '27px' }}>
              { defaultPrice?.isRecurrent ? 'A partir do segundo mês' : 'Pergunta avulsa' }
            </PricingDescription>

            <Grid item>
              <Grid container flexDirection='row' alignItems='flex-end'>
                <Grid item>
                  <PricingDescription $bestPlan={isBestOption}>R$</PricingDescription>
                </Grid>
                <Grid item sx={{ height: '50px' }}>
                  <PricingPrice $bestPlan={isBestOption}>
                    {isPromotion ? integerPromotionalPrice : integerPrice}
                    <small>
                      ,
                      {isPromotion
                        ? decimalPromotionalPrice && decimalPromotionalPrice.padEnd(2, '0')
                        : decimalPrice && decimalPrice.padEnd(2, '0')}
                    </small>
                  </PricingPrice>
                </Grid>
                <Grid item>
                  <PricingDescription $bestPlan={isBestOption}>{defaultPrice?.isRecurrent && ' / mês'}</PricingDescription>
                </Grid>
              </Grid>
            </Grid>

          </>
        ) : (
          <PricingDefault $bestPlan={isBestOption}>Gratuito</PricingDefault>
        )}

        {promotionTerms && (
          <Grid>
            <Grid>
              <PricingSecondary $bestPlan={isBestOption}>{promotionTerms}</PricingSecondary>
            </Grid>
          </Grid>
        )}
      </Grid>

      {(isDefaultPlan || isActual || !isAuthenticated()) && (
        <Grid container justifyContent='center'>
          {isActual ? (
            <PricingSubscribeDefault $bestPlan={isBestOption}>ASSINATURA ATUAL</PricingSubscribeDefault>
          ) : (
            <PricingSubscribe
              $selected={selected}
              $bestPlan={isBestOption}
              size='large'
              variant='outlined'
              onClick={handleSubscribe}
            >
              {(defaultPrice?.isRecurrent && 'ASSINAR') || (!defaultPrice?.price && '') || 'COMPRAR'}
            </PricingSubscribe>
          )}
        </Grid>
      )}
    </Pricing>
  );
};

export default PricingCard;
