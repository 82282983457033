/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import moment from 'moment';
import { omit } from 'lodash';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import { MenuItem } from '@material-ui/core';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { AttachFile } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CardContent, IconButton, Modal, Typography, TextField, InputLabel, FormControl, Select, Box, Grid, Autocomplete } from '@mui/material';
import { SaveButton, ButtonControl, ProfileManageContent, ProfileCard, ProfileRowColumn, DisableButton, PurpleButton, RedButton, Text, Divider } from './style';

import Alert from '../../../components/global/Alert';
import { resizeImage } from '../../../utils/imageUtils';
import Loading from '../../../components/global/Loading';
import { HOME_URL, isNative } from '../../../utils/mobile';
import { validateSymbols } from '../../../utils/validators';
import { validateCNPJ, validateCPF } from '../../../services/documentValidations';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: screen.width < 739 ? 300 : 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ProfileManageArea = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open: termsModalOpen } = useSelector((state) => state.User.modal.termsModal);
  const { id, firstName, email, birthdate, isB2B, isProfileFilled } = useSelector((state) => state.User.userData);
  const { userData, lastTriggeredEvent, redirectFromLoginURL } = useSelector((state) => state.User);
  const alreadyReferredBy = useSelector((state) => state.User.userData?.referredBy);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const Modals = useSelector((state) => state.Modal);

  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [imageBase64, setImageBase64] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);

  const signingPlan = useSelector((state) => state.User.userData.signingPlan);
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const isPremiumSubscription = (!isDefaultPlan && signingPlan?.type === 'recurring') && !(userData?.signingPlanFromSource === true);

  const isPhoneOrEmail = (_value = '') => {
    const value = _value.trim();
    const hasInitialNumbers = !isNaN(value[0]) && !isNaN(value[1]) && !isNaN(value[2]) && !isNaN(value[3]);
    return value.length === 11 && hasInitialNumbers && !_value.includes('@');
  };

  const fetchState = async () => {
    const response = await dispatch.User.getStateOptions();

    if (response && response.data.length) {
      setStateOptions(response.data);
    } else {
      setStateOptions([]);
    }
  };

  const fetchCityOfUF = async (uf, name) => {
    const response = await dispatch.User.getCityOptions({ uf, name });

    if (response.data.length) {
      setCityOptions(response.data);
    } else {
      setCityOptions([]);
    }
  };

  useEffect(() => {
    fetchState();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!Boolean(isProfileFilled) && !termsModalOpen) {
      setAlert({ open: true, message: 'Complete seus dados!', severity: 'warning' });
    }
  }, [termsModalOpen]);

  useEffect(() => {
    if (lastTriggeredEvent && lastTriggeredEvent.triggerName === 'purchase-voucher') {
      setAlert({ open: true, message: 'Para prosseguir com a compra, favor preencher seu CPF e telefone', severity: 'warning' });
    }
  }, []);

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;

    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');

      const base64 = await convertBase64(rawFile);
      setImageBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);

      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setImageBase64(base64);
      }
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleUpload = () => {
    const button = document.getElementById('imgupload');
    button.click();
  };

  const disableAccount = () => {
    dispatch.User.inactivateAccount();
  };

  const validateImage = (image) => {
    const FILE_SIZE = 20000000; // 20MB
    const SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
    ];

    if (image === undefined || image === {}) {
      return true;
    }

    if (image && SUPPORTED_FORMATS.includes(image.type) && image.size <= FILE_SIZE) {
      return true;
    }
    return false;
  };

  const submitForm = async (formData, { setErrors }) => {
    setSubmitting(true);
    setAlert({ ...alert, open: false });

    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });

      const payload = {
        id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        cpfCnpj: formData.cpfCnpj,
        type: formData.type,
        birthdate: formData.birthdate ? moment(formData.birthdate).format('YYYY-MM-DD') : '',
        address: {
          id: formData.addressId || null,
          street: formData.street,
          uf: formData.uf,
          city: formData.city,
          code: formData.code.replace(/[^A-Z0-9]/ig, ''),
          complement: formData.complement,
          number: formData.number,
          district: formData.district,
        },
        photoBase64: imageBase64,
      };

      const userHasAddress = userData?.address?.id;
      const updateUserProfileData = await dispatch.User.updateUserProfileData(payload);

      if (updateUserProfileData && updateUserProfileData.data) {
        setSubmitting(false);
        setAlert({ open: true, message: 'Sucesso na atualização', severity: 'success' });

        if (!userHasAddress && alreadyReferredBy) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ openReferredByModal: true }));
        }

        if (!userHasAddress && !alreadyReferredBy) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ openReferModal: true }));
        }

        if (Modals?.completeProfileDialog?.from === 'corporate_plan') {
          const { email: emailAux, phone, firstName: firstNameAux, address, cpfCnpj } = updateUserProfileData.data;
          const isAddressFilled = address && address?.code && address?.number && address?.city && address?.street && address?.district && address?.state;
          const isProfileFilledCheck = firstNameAux && emailAux && phone && isAddressFilled && cpfCnpj;
          if (isProfileFilledCheck) {
            const response = await dispatch.SigningPlans.signingPlanCorporateRequest();
            if (response && response.data) {
              dispatch.Modal.setCorporatePlanDialog({ open: true });
              dispatch.Modal.setProfileDialog({ from: null });
            }
            return null;
          }
          return null;
        }
        setTimeout(() => {
          if (lastTriggeredEvent.triggerName === 'checkout-payment') {
            history.push(lastTriggeredEvent.urlPattern);
          }

          if (lastTriggeredEvent.triggerName === 'service-scheduling') {
            const { serviceId } = lastTriggeredEvent.payload;
            history.push(`/services/create/${serviceId}`);
          }

          if (lastTriggeredEvent.triggerName === 'purchase-voucher') {
            const { voucherId } = lastTriggeredEvent.payload;
            history.push(`/vouchers/${voucherId}`);
          }

          if (redirectFromLoginURL && redirectFromLoginURL !== '/login') {
            const redirectURL = redirectFromLoginURL.valueOf();
            dispatch.User.setRedirectFromLoginURL('');
            history.push(redirectURL);
          }

          if (isPhoneOrEmail(firstName)) {
            history.push(HOME_URL);
          }

          setAlert({ ...alert, open: false });
        }, 3000);
      } else {
        setSubmitting(false);
      }
    } else {
      setErrors({ photoName: true });
      setSubmitting(false);
    }

    return null;
  };

  const openSearchCEP = () => {
    if (isNative) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: 'https://buscacepinter.correios.com.br/app/endereco/index.php' } }));
      return;
    }
    window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank');
  };

  const handleCEP = async (code, setFieldValue) => {
    const parsedCep = code && code.target.value.replace(/[^A-Z0-9]/ig, '');
    setFieldValue('code', code.target.value);

    if (parsedCep && parsedCep.length === 8) {
      setLoadingFields(true);
      const cepResponse = await dispatch.User.addressGetCepInfo(parsedCep);
      setLoadingFields(false);

      if (cepResponse && cepResponse.data) {
        const { street, district, state, city } = cepResponse.data;
        setFieldValue('street', street);
        setFieldValue('district', district);

        const findState = stateOptions.find((a) => a.state === state || a.uf === state) || { uf: '' };
        setFieldValue('uf', findState.uf);

        const response = await dispatch.User.getCityOptions({ uf: findState.uf, name: city });
        if (response.data.length) {
          const findCity = response.data.find((a) => a.name === city) || { name: '' };
          setFieldValue('city', findCity.name);
          setCityOptions(response.data);
        } else {
          setCityOptions([]);
          setFieldValue('city', '');
        }
      }
    }
  };

  const handleStateOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('city', '');
      setFieldValue('uf', event.uf);
      fetchCityOfUF(event.uf, '');
    }
  };

  const handleCityOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('city', event.name);
    }
  };

  const handleCityTextChange = (event, setFieldValue, uf) => {
    const { value } = event.target;
    setFieldValue('city', value);
    fetchCityOfUF(uf, value);
  };

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  }) => (
    <form onSubmit={handleSubmit}>
      <div className='wrapper'>
        <div className='image-upload'>
          <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => { uploadImage(e); }} style={{ display: 'none' }} />
          <TextField
            value={filename}
            error={!!errors.photoName}
            onBlur={handleBlur}
            touched={touched.photoName}
            onClick={handleUpload}
            helperText='Imagens de até 20MB'
            sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
            id='photoName'
            name='photoName'
            label='Sua imagem de perfil'
            autoComplete='new-password'
            InputProps={{
              readOnly: true,
              endAdornment:
  <IconButton
    aria-label='toggle password visibility'
    edge='end'
  >
    <AttachFile />
  </IconButton>,
            }}
          />
        </div>
        <ProfileRowColumn isB2B={isB2B}>
          <TextField
            helperText={errors.firstName ? errors.firstName : ''}
            error={!!errors.firstName}
            onBlur={handleBlur}
            touched={touched.firstName}
            value={values.firstName}
            onChange={handleChange}
            sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
            label='Nome'
            id='firstName'
            name='firstName'
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
          />

          {!isB2B ? (
            <TextField
              helperText={errors.lastName ? errors.lastName : ''}
              error={!!errors.lastName}
              onBlur={handleBlur}
              touched={touched.lastName}
              value={values.lastName}
              onChange={handleChange}
              sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
              label='Sobrenome'
              id='sobrenome'
              name='lastName'
              InputLabelProps={{ shrink: true }}
              autoComplete='new-password'
            />
          ) : <></>}

        </ProfileRowColumn>
        <ProfileRowColumn>
          {!isB2B ? (
            <InputMask
              mask='999.999.999-99'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cpfCnpj}
              disabled={false}
              maskplaceholder='_'
            >
              {(inputProps) => (
                <TextField
                  error={!!errors.cpfCnpj}
                  helperText={errors.cpfCnpj && errors.cpfCnpj}
                  touched={touched.cpfCnpj}
                  sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  label='CPF'
                  id='cpfCnpj'
                  placeholder='000.000.000-00'
                  name='cpfCnpj'
                  type='tel'
                  InputLabelProps={{ shrink: true }}
                  autoComplete='new-password'
                  {...inputProps}
                />
              )}
            </InputMask>
          ) : (
            <InputMask
              mask='99.999.999/9999-99'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cpfCnpj}
              disabled={false}
              maskplaceholder='_'
            >
              {(inputProps) => (
                <TextField
                  error={!!errors.cpfCnpj}
                  helperText={errors.cpfCnpj && errors.cpfCnpj}
                  touched={touched.cpfCnpj}
                  sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  label='CNPJ'
                  id='cpfCnpj'
                  placeholder='00.000.000/0000-00'
                  name='cpfCnpj'
                  type='tel'
                  InputLabelProps={{ shrink: true }}
                  autoComplete='new-password'
                  {...inputProps}
                />
              )}
            </InputMask>
          )}
          <InputMask
            mask='\(99\) 9 9999-9999'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            disabled={false}
            maskplaceholder='_'
          >
            {(inputProps) => (
              <TextField
                error={!!errors.phone}
                helperText={errors.phone && errors.phone}
                touched={touched.phone}
                sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                label='Celular'
                placeholder='(00) 0 0000-0000'
                id='phone'
                name='phone'
                type='tel'
                InputLabelProps={{ shrink: true }}
                autoComplete='new-password'
                {...inputProps}
              />
            )}
          </InputMask>
        </ProfileRowColumn>
        <ProfileRowColumn>
          <TextField
            helperText={errors.email ? errors.email : ''}
            sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
            label='E-mail'
            error={!!errors.email}
            onBlur={handleBlur}
            touched={touched.email}
            value={values.email}
            onChange={handleChange}
            disabled={email}
            id='email'
            name='email'
            InputLabelProps={{ shrink: true }}
            autoComplete='new-password'
          />
          {!isB2B ? (
            <DatePicker
              openTo='year'
              open={openDatePicker}
              id='birthdate'
              name='birthdate'
              maxDate={moment()}
              label='Data de nascimento'
              allowSameDateSelection
              defaultValue={null}
              value={values.birthdate}
              onChange={(value) => setFieldValue('birthdate', value)}
              onClose={() => setOpenDatePicker(false)}
              format='DD/MM/YYYY'
              views={['year', 'month', 'day']}
              renderInput={(params) => (
                <TextField
                  sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  id='birthdate'
                  name='birthdate'
                  onClick={() => setOpenDatePicker(true)}
                  InputLabelProps={{ shrink: true }}
                  {...params}
                />
              )}
            />
          ) : <></>}
        </ProfileRowColumn>
        <ProfileRowColumn isB2B>
          <Box sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <InputLabel id='profile'>Perfil</InputLabel>
              <Select
                id='profile'
                disabled
                name='type'
                defaultValue='T'
                value={values.type}
                label='Perfil'
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.type}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value='T'>Tutor</MenuItem>
                <MenuItem value='V'>Veterinário</MenuItem>
                <MenuItem value='C'>Clínica</MenuItem>
                <MenuItem value='PS'>Pet shop</MenuItem>
                <MenuItem value='DC'>Day care</MenuItem>
                <MenuItem value='BT'>Banho e Tosa</MenuItem>
                <MenuItem value='A'>Adestrador</MenuItem>
                <MenuItem value='SI'>Pet Sitter</MenuItem>
                <MenuItem value='OP'>Operador</MenuItem>
                <MenuItem value='O'>Outros</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </ProfileRowColumn>

        <span className='title'>Endereço</span>

        <Divider>
          <Grid container gap={3} alignItems='flex-start' fullWidth>
            <Grid item fullWidth flex={1}>
              <InputMask
                mask='99999-999'
                onChange={(value) => handleCEP(value, setFieldValue)}
                value={values.code}
                disabled={false}
                maskplaceholder='_'
                width='100%'
              >
                {(inputProps) => (
                  <TextField
                    error={!!errors.code}
                    helperText={errors.code && errors.code}
                    touched={touched.code}
                    label='CEP'
                    id='code'
                    placeholder='00000-000'
                    name='code'
                    type='tel'
                    fullWidth
                    autoComplete='new-password'
                    InputLabelProps={{ shrink: true }}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item onClick={openSearchCEP} sx={{ marginTop: '16px' }}>
              <Text>Não sei meu CEP</Text>
            </Grid>
          </Grid>
        </Divider>

        <Divider>
          <TextField
            id='street'
            name='street'
            label='Endereço'
            value={values.street}
            disabled={loadingFields}
            onChange={handleChange}
            error={errors && errors.street}
            helperText={errors && errors.street}
            fullWidth
            autoComplete='new-password'
            InputLabelProps={{ shrink: true }}
          />
        </Divider>

        <Divider>
          <TextField
            id='district'
            name='district'
            label='Bairro'
            value={values.district}
            disabled={loadingFields}
            onChange={handleChange}
            error={errors && errors.district}
            helperText={errors && errors.district}
            fullWidth
            autoComplete='new-password'
            InputLabelProps={{ shrink: true }}
          />
        </Divider>

        <Divider>
          <Grid container gap={3} alignItems='flex-start' fullWidth>
            <Grid item fullWidth flex={1}>
              <TextField
                id='number'
                name='number'
                label='Número'
                type='tel'
                value={values.number}
                onChange={handleChange}
                error={errors && errors.number}
                helperText={errors && errors.number}
                fullWidth
                autoComplete='new-password'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item fullWidth flex={1}>
              <TextField
                id='complement'
                name='complement'
                label='Complemento'
                value={values.complement}
                onChange={handleChange}
                fullWidth
                autoComplete='new-password'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Divider>

        <Divider>
          <Grid container gap={3} alignItems='flex-start' fullWidth>
            <Grid item fullWidth flex={1}>
              <Autocomplete
                name='uf'
                disablePortal
                freeSolo
                value={stateOptions.find((a) => a.uf === values.uf) || { name: '' }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                error={!!errors.uf}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, value) => handleStateOptionsChange(value, setFieldValue)}
                options={stateOptions}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.id}>
                    <div>{option.name}</div>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    id='uf'
                    name='uf'
                    label='Estado'
                    error={!!errors.uf}
                    helperText={errors && errors.uf}
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid item fullWidth flex={1}>
              <Autocomplete
                blurOnSelect='touch'
                disablePortal
                id='city'
                name='city'
                error={!!errors.city}
                openOnFocus
                freeSolo
                onChange={(e, value) => handleCityOptionsChange(value, setFieldValue)}
                options={cityOptions}
                value={{ name: values?.city || '' }}
                disabled={!values.uf || !stateOptions?.length}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.id}>
                    <div>{option.name}</div>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    id='city'
                    name='city'
                    label='Cidade'
                    error={!!errors.city}
                    helperText={errors && errors.city}
                    onChange={(event) => handleCityTextChange(event, setFieldValue, values.uf)}
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Divider>

        <div className='row-submit'>
          <DisableButton onClick={toggleModal}>
            {screen.width < 739 ? 'Deletar' : 'Deletar conta'}
          </DisableButton>
          {isSubmitting ? <Loading /> : (
            <SaveButton type='submit'>SALVAR</SaveButton>
          )}
        </div>
      </div>
    </form>
  );

  const fetchManageURL = async () => {
    if (isPremiumSubscription) {
      const response = await dispatch.SigningPlans.signingPlanManage();

      if (response && response.data) {
        const url = response.data.manageUrl;
        if (isNative) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: url } }));
          toggleModal();
        }

        return window.open(url, '_blank');
      }
    } else {
      history.push('/pricing');
    }

    return null;
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={toggleModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ width: '100vw' }}
      >
        <div>
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Deletar minha conta
            </Typography>

            <Typography id='modal-modal-description' sx={{ my: 4, textAlign: 'center' }}>
              {isPremiumSubscription
                ? 'Antes de deletar sua conta, primeiro você deve cancelar sua assinatura atual em Gerenciar'
                : 'Tem certeza que deseja deletar sua conta?'}
            </Typography>

            <ButtonControl>
              <PurpleButton onClick={toggleModal}>Voltar</PurpleButton>

              <RedButton variant='outlined' onClick={isPremiumSubscription ? fetchManageURL : disableAccount}>
                {isPremiumSubscription ? 'Gerenciar' : 'Sim'}
              </RedButton>

            </ButtonControl>
          </Box>
        </div>
      </Modal>
      <ProfileCard>
        <CardContent>
          <ProfileManageContent id='sectionProfile'>
            <span className='title'>Perfil</span>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              initialValues={{ ...userData,
                ...(omit(userData?.address || {}, ['id'])),
                addressId: userData?.address?.id || null,
                uf: userData?.address?.uf || '',
                city: userData?.address?.city || '',
                firstName: isPhoneOrEmail(firstName) ? '' : firstName,
                birthdate: birthdate || null,
                type: userData.type,
                photoBase64: imageBase64 }}
              onSubmit={submitForm}
              render={renderForm}
              validationSchema={
								Yup.object().shape({
								  photoName: Yup.string(),
								  firstName: Yup.string().required('Insira um nome').nullable().test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  lastName: isB2B ? Yup.string() : Yup.string().required('Insira um sobrenome').nullable().test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  cpfCnpj: !isB2B ? (Yup.string().nullable().test('cpfValidation', 'Este CPF é inválido', (value) => {
								    if (!value) return true;
								    return validateCPF(value);
								  })) : (Yup.string().nullable().test('cnpjValidation', 'Este CNPJ é inválido', (value) => {
								    if (!value) return true;
								    return validateCNPJ(value);
								  })),
								  email: Yup.string()
								    .when('phone', {
								      is: (phoneYup) => !phoneYup || phoneYup.length === 0,
								      then: Yup.string().email('Favor preencher com um e-mail válido')
								        .required('Favor preencher pelo menos o e-mail ou o telefone'),
								    }),
								  phone: Yup.string()
								    .nullable()
								    .required('Favor preencher o telefone')
								    .test('len', 'Preencher número completo, incluindo DDD e dígito 9', (val) => {
								      const withoutDashes = val && val.replace(/\D/g, '').length;
								      return withoutDashes === 11;
								    }),
								  code: Yup.string().required('Preencha o CEP'),
								  street: Yup.string().required('Preencha o endereço').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  district: Yup.string().required('Preencha o bairro').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  number: Yup.string().required('Preencha o número do endereço').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  uf: Yup.string().required('Preencha o estado').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								  city: Yup.string().required('Preencha a cidade').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
								}, ['email', 'phone'])
              }
            />
          </ProfileManageContent>
        </CardContent>
      </ProfileCard>
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        sx={{ marginTop: '50px' }}
      >
        <div>
          <Alert severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </div>
      </Snackbar>
    </>
  );
};

export default ProfileManageArea;
