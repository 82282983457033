/* eslint-disable no-unused-vars */
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Image } from './style';
import catGroup from '../../../../assets/images/ProtocolModal/cat_group.jpg';
import dogGroup from '../../../../assets/images/ProtocolModal/dog_group.jpg';
import CancelOrSubmit from '../../../global/CancelOrSubmit';

const speciesMap = {
  CAT: catGroup,
  DOG: dogGroup,
};

const BodyScore = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.protocolApplyId);
  const specie = useSelector((state) => state.Animals.actualPet?.breed?.species);
  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const lastBodyScore = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastBodyScore);

  const content = useSelector((state) => state.Modal.protocolDialog?.content);
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');

  const submitForm = async (formData) => {
    // setLoading(true);
    const { bodyScore } = formData;

    const payload = {
      protocolId: id,
      ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
      bodyScore,
      appliedDatetime: moment().toISOString(),
    };

    const applyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (applyAnimalProtocol && applyAnimalProtocol.data) {
      const result = applyAnimalProtocol.data[0]?.lastResult;
      dispatch.Modal.setDialog({ open: false });
      dispatch.Modal.setResultDialog({ open: true, content, result });
    }

    // setLoading(false);
  };

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container flexDirection='column' justifyContent='flex-start'>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={(!isProtocolBeingEdited && {}) || {
            bodyScore: lastBodyScore,
          }}
          onSubmit={submitForm}
          validationSchema={
            Yup.object().shape({
              bodyScore: Yup.string().required('Favor indicar score'),
            })
}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Image src={(specie && speciesMap[specie]) || speciesMap.DOG} alt='body_score' />

              <FormControl error={!!errors.bodyScore} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Selecione qual mais se parece com seu pet hoje</InputLabel>
                <Select
                  id='bodyScore'
                  name='bodyScore'
                  defaultValue='Selecione'
                  label='Selecione qual mais se parece com seu pet hoje'
                  error={!!errors.bodyScore}
                  helperText={errors.bodyScore && errors.bodyScore}
                  value={values.bodyScore || 'Selecione'}
                  onChange={handleChange}
                >
                  <MenuItem value='Selecione'>Selecione</MenuItem>
                  <MenuItem value='emaciated'>1</MenuItem>
                  <MenuItem value='underweight'>2</MenuItem>
                  <MenuItem value='regular'>3</MenuItem>
                  <MenuItem value='overweight'>4</MenuItem>
                  <MenuItem value='obese'>5</MenuItem>
                </Select>
              </FormControl>

              <CancelOrSubmit loading={loading === 'loading'} />
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>

  );
};

export default BodyScore;
