import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { StyledGrid, StyledFabButton } from './style';
import PetEmptyCard from '../PetEmptyCard';
import PetCardPreview from '../PetCard/PetCardPreview';
// import PetSearch from './PetSearch';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import useWindowSize from '../../../hooks/windowSize';
import MobileAppDialog from '../../Login/LoginForm/MobileAppDialog';
// import useEventTrigger from '../../../hooks/useEventTrigger';
import Loading from '../../global/Loading';

const PetManagement = () => {
  const dispatch = useDispatch();
  const { getAnimalsList } = useSelector((state) => state.Animals.loading);
  const screen = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(true);
  const [intercomLoading, setIntercomLoading] = useState(false);

  const { animalsList, animalsListSearch, animalsCount } = useSelector((state) => state.Animals);
  const observer = useRef();
  const [page, setPage] = useState(1);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);

  // useEventTrigger();

  useEffect(() => dispatch.Animals.getAnimalsList({}), []);
  useEffect(() => dispatch.Animals.getAnimalsCount(), []);
  useEffect(() => window.Intercom('onShow', () => setIntercomLoading(false)));

  const handleOpenModal = () => {
    Mixpanel.track(MIXPANEL_TAGS.ADD_CLICK);
    dispatch.Animals.setModal({ petAndProtocolApply: true });
  };

  useLayoutEffect(() => {
    setIsDesktop(screen.width > 736);
  }, [screen.width]);

  const fetchAnimalsList = async () => {
    setFirstLoading(false);

    if (hasMoreItems) {
      const response = await dispatch.Animals.addToAnimalsList({ pageNumber: page });

      const responseIsEmpty = response && response && !response.data.length;

      if (responseIsEmpty || animalsList.length === animalsCount) {
        setHasMoreItems(false);
      }

      if (response && response.data) {
        setPage(page + 1);
      }
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchAnimalsList();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [page],
  );

  if ((getAnimalsList === 'loading' && firstLoading)) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  const onClickPetEmptyCard = () => Mixpanel.track(MIXPANEL_TAGS.PET_EMPTY_CARD_CLICK);

  return (
    <>
      {intercomLoading && (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh', background: 'white', opacity: '0.7', position: 'fixed', zIndex: 20 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
      )}

      <Container style={{ marginBottom: 120 }}>

        <StyledGrid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
          sx={{ marginTop: '10px' }}
        >
          <MobileAppDialog />
          {/* <PetControl emptyList={animalsList && !animalsList.length}>
    {animalsList && animalsList.length !== 0 ? (
      <PetSearch />
    ) : <></>}
  </PetControl> */}
          { animalsList.length > 0 && (
          <>
            {animalsList
              .filter((pet) => (animalsListSearch.isAnimal && pet.name.toLowerCase().startsWith(animalsListSearch.term)) || `${pet.user.firstName.toLowerCase()} ${pet.user.lastName.toLowerCase()}`.startsWith(animalsListSearch.term))
              .map((pet, index) => {
                const isLastElement = animalsList.length === index + 1;

                return (
                  <Grid key={pet.id} item {...{ ref: isLastElement ? lastElementRef : undefined }} xs={12} sm={12} md={6} lg={4}>
                    <PetCardPreview key={pet.id} {...pet} />
                  </Grid>
                );
              })}
          </>
          )}
          { animalsList.length > 0 ? (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link to='/pet/create' style={{ textDecoration: 'none' }} onClick={onClickPetEmptyCard}>
                <PetEmptyCard />
              </Link>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Link to='/pet/create' style={{ textDecoration: 'none' }} onClick={onClickPetEmptyCard}>
                  <PetEmptyCard />
                </Link>
              </Grid>
            </>
          )}
          <div style={{ display: isDesktop ? 'none' : 'flex', position: 'fixed', bottom: '80px', right: '25px' }}>
            <StyledFabButton onClick={handleOpenModal}>
              <AddIcon fontSize='small' />
            </StyledFabButton>
          </div>
        </StyledGrid>
      </Container>
    </>

  );
};

export default PetManagement;
