import React from 'react';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../utils/mobile';
import CheckedBlue from '../../../assets/images/Pricing/checked-blue.png';

const CorporateSolicitationPlanDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.Modal.corporateSolicitationPlanDialog?.open);

  const handleCloseDialog = () => {
    dispatch.Modal.setCorporatePlanDialog({ open: false });
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => handleCloseDialog()}
    >
      <DialogTitle>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item>
            <Close sx={{ cursor: 'pointer' }} onClick={() => handleCloseDialog()} />
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '34px' }} gap={2}>
          <Grid item>
            <img src={CheckedBlue} alt='icon' />
          </Grid>

          <Grid item>
            <Typography sx={{ fontSize: '32px', lineHeight: '35px', fontWeight: 600, textAlign: 'center' }}>Sua solicitação para ativar a assinatura foi enviada</Typography>
          </Grid>

          <Grid item>
            <Typography sx={{ fontSize: '18px', lineHeight: '22px', fontWeight: 400, textAlign: 'center' }}>Não se preocupe, vamos te notificar quando ela for respondida</Typography>
          </Grid>

          <Grid item sx={{ marginTop: '68px' }}>
            <Button
              variant='large'
              sx={{ color: '#6B48FF' }}
              onClick={handleCloseDialog}
            >
              Entendi
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent />
    </Dialog>
  );
};

export default CorporateSolicitationPlanDialog;
