import React from 'react';

import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile, isNative } from '../../../../utils/mobile';

const CancelCallDialog = ({ open, setModalOpen }) => {
  const dispatch = useDispatch();

  const removeIframeNuvidio = () => {
    const iframe = document.querySelector('.nuvidio-iframe');
    if (iframe) {
      iframe.remove();
    }
    dispatch.User.setVideoConferenceData({});
  };

  const handleCancelCall = () => {
    removeIframeNuvidio();
    window.open(`${process.env.REACT_APP_DYNAMIC_LINK}/services`, isNative ? '_blank' : '_self');
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        paddingBottom: '10px',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            Tem certeza que deseja cancelar?
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>Aguarde mais um momento para ser atendido ou cancele a chamada</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: '#6B48FF' }}
          FF505F
          onClick={() => setModalOpen(false)}
        >
          Aguardar
        </Button>

        <Button
          sx={{ color: '#FF505F' }}
          onClick={handleCancelCall}
        >
          Cancelar chamada
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelCallDialog;
