import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TransferPetContent from './TransferPetContent';

const TransferPetModal = () => {
  const dispatch = useDispatch();
  const { open, context } = useSelector((state) => state.User.modal.transferPetModal) || {};

  const closeModal = () => dispatch.User.setModal({ transferPetModal: { open: false, context: '' } });

  const renderModalContent = () => {
    switch (context) {
      case 'transferPet':
        return <TransferPetContent />;
      default:
        return <div />;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      onBackdropClick='false'
    >
      {renderModalContent()}
    </Modal>
  );
};

export default TransferPetModal;
