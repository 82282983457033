/* eslint-disable no-unused-expressions */
import { Avatar, Fade, Card, Grid, Typography, CardContent, Divider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import { Snackbar } from '@material-ui/core';
import PublicServicePartnerCard from './PublicServicePartnerCard';
import Alert from '../../global/Alert';
import Loading from '../../global/Loading';

const PublicServicesDashboard = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const isLoading = false;
  const [successLink, setSuccessLink] = useState(false);

  const { publicServices } = useSelector((state) => state.Appointments) || [];

  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(publicServices[0].user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!publicServices[0]?.user?.profileImage && fetchProfileImage();
  }, [publicServices]);

  const redirectToSchedulePage = async (serviceId) => {
    const userId = params.id;
    const { companyName } = params;

    await dispatch.Appointments.publicServiceDetail({ serviceId, userId });

    history.push(`/public-services/${userId}/${serviceId}/${companyName}`);
  };

  const copyScheduleLink = () => {
    navigator.clipboard.writeText(window.location.href);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: window.location.href } }));
    setSuccessLink(true);
  };

  useEffect(() => {
    const getServices = () => {
      const userId = params.id;

      const payload = {
        userId,
      };

      dispatch.Appointments.publicServices(payload);
    };

    getServices();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessLink(false);
  };

  return (
    <>
      {isLoading ? (
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ minHeight: '70vh' }}>
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Fade in>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              container
              direction='column'
              alignItems='center'
              gap={2}
              sx={{ padding: '0 24px', maxWidth: '1280px' }}
            >
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={successLink}
                autoHideDuration={4000}
                onClose={handleClose}
              >
                <div>
                  <Alert onClose={handleClose}>
                    Link da agenda copiado com sucesso
                  </Alert>
                </div>
              </Snackbar>
              <Grid item sx={{ width: '100%' }}>
                <Card elevation={0} sx={{ padding: '32px 32px' }}>
                  <Grid container wrap='nowrap' direction='row' alignItems='center' gap={1}>
                    <Grid item>
                      <Avatar
                        sx={{ width: '56px', height: '56px' }}
                        src={publicServices[0]?.user?.profileImage && profileImgSrc}
                        alt={publicServices[0]?.user?.firstName}
                      />
                    </Grid>

                    <Grid item>
                      <Typography sx={{ color: 'black', fontWeight: 700, fontSize: '18px', minWidth: '200px' }}>
                        {publicServices[0]?.user?.firstName}
                      </Typography>
                    </Grid>

                    <Grid container justifyContent='flex-end'>
                      <Grid container justifyContent='flex-end'>
                        <ShareIcon onClick={copyScheduleLink} sx={{ color: '#6B48FF', cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{ marginTop: '24px', marginBottom: '10px', width: '100%' }}
                spacing={2}
              >

                <Grid
                  container
                  direction='column'
                >
                  <Typography variant='subtitle1' sx={{ color: '#9E9E9E' }}>Agendas</Typography>
                </Grid>

                <Card elevation={0} sx={{ width: '100%', marginTop: '5px' }}>
                  <CardContent>
                    <Grid
                      container
                      direction='column'
                      justifyContent='flex-start'
                      alignItems='flex-start'
                      gap={2}
                    >
                      {isLoading ? (
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          sx={{ width: '80%', minHeight: '10vh' }}
                        >
                          <Grid item>
                            <Loading />
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          {publicServices.map(({ id, ...props }, index) => (
                            <>
                              <Fade in onClick={() => redirectToSchedulePage(id)}>
                                <Grid key={index} item sx={{ width: '100%' }}>
                                  <PublicServicePartnerCard {...props} />
                                </Grid>
                              </Fade>

                              {index !== publicServices.length - 1 ? (
                                <Divider sx={{ width: '100%' }} />
                              ) : <></>}
                            </>
                          ))}
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  );
};

export default PublicServicesDashboard;
