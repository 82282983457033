/* eslint-disable consistent-return */
import styled from 'styled-components';
import { Grid, Container } from '@mui/material';
import theme, { animations } from '../../utils/theme';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;

export const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 25px;

    @media screen and (min-width: 1200px) {
      max-width: 1280px;
    }

    @media screen and (max-width: 520px) {
      margin-top: 0px;
      height: 150px;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: column;
    }
  }
`;

export const StyledBreadcrumbContainer = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 25px;

    @media screen and (min-width: 1200px) {
      max-width: 1280px;
    }

    @media screen and (max-width: 520px) {
      margin-top: 0px;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: column;
    }
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: auto;
  background-color: white;
  border: 2px solid #6B48FF;
  padding: 5px 30px;
  transition: box-shadow .2s ease-in;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 800;

  &:hover {
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 520px) {
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }
`;

export const ReportControl = styled.div`
  margin: 40px 0 25px 30px;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TableMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableItem = styled.div`
	opacity: ${({ selected }) => (selected ? '1.0' : '0.5')};
	transition: opacity 0.2s ease-in;
  cursor: pointer;

	&:hover {
		opacity: 1;
	}

	:nth-child(1) {
		margin-left: 0;
	}

	& {
		margin-left: 32px;
	}
`;

export const IndicatorLine = styled.div`
	width: 76px;
	height: 2px;
	border: 1px solid ${theme.menuIndicator};
	background-color: ${theme.menuIndicator};
	border-radius: 5px;
  margin-right: 150px;
	margin-top: 8px;
  left: 0;
	animation: ${animations.fadeIn} 0.2s ease-in;
  transition: all 0.2s ease-in-out;

  ${({ report }) => {
    switch (report) {
      case 'protocolApplyToExpire':
        return 'transform: translate(0px); width: 140px';
      case 'unfilledProtocols':
        return 'transform: translate(175px); width: 195px';
      default:
        break;
    }
  }
};
`;
