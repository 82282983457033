import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { HelperText, ShrinkMenuItem } from './style';
import CancelOrSubmit from '../../../global/CancelOrSubmit';

const HeartControl = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.protocolApply);

  const lastHadHeartProblems = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastHadHeartProblems);
  const lastBeatsPerMinute = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastBeatsPerMinute);
  const lastTirednessLevel = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastTirednessLevel);
  const lastPetSize = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastPetSize);
  const lastCoughLevel = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastCoughLevel);

  const content = useSelector((state) => state.Modal.protocolDialog?.content);
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');

  const submitForm = async (formData) => {
    // setLoading(true);
    const { tirednessLevel, hadHeartProblems, beatsPerMinute, petSize, coughLevel } = formData;

    const payload = {
      protocolId: id,
      tirednessLevel,
      hadHeartProblems: JSON.parse(hadHeartProblems),
      beatsPerMinute,
      petSize,
      coughLevel,
      appliedDatetime: moment().toISOString(),
      ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
    };

    const applyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (applyAnimalProtocol && applyAnimalProtocol.data) {
      const result = applyAnimalProtocol.data[0]?.lastResult;
      dispatch.Modal.setDialog({ open: false });
      dispatch.Modal.setResultDialog({ open: true, content, result });
    }

    // setLoading(false);
  };

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container flexDirection='column' justifyContent='flex-start'>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={(!isProtocolBeingEdited && {}) || {
            tirednessLevel: lastTirednessLevel,
            hadHeartProblems: String(lastHadHeartProblems),
            beatsPerMinute: lastBeatsPerMinute,
            petSize: lastPetSize,
            coughLevel: lastCoughLevel,
          }}
          onSubmit={submitForm}
          validationSchema={
            Yup.object().shape({
              petSize: Yup.string().required('Favor selecionar o porte do pet'),
              tirednessLevel: Yup.mixed().required('Favor selecionar uma das opções'),
              hadHeartProblems: Yup.string().nullable().required('Favor selecionar uma das opções'),
              beatsPerMinute: Yup.string().required('Favor digitar os batimentos cardíacos contados'),
              coughLevel: Yup.string().required('Favor selecionar nível de tosse'),
            })
}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }} key='form'>
              <FormControl error={!!errors.petSize} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Qual o porte do seu animal?</InputLabel>
                <Select
                  id='petSize'
                  name='petSize'
                  defaultValue='Selecione'
                  label='Qual o porte do seu animal?'
                  error={!!errors.petSize}
                  helperText={errors.petSize && errors.petSize}
                  value={values.petSize}
                  onChange={handleChange}
                >
                  <MenuItem value='Selecione'>Selecione</MenuItem>
                  <MenuItem value='mini'>Mini - menor que 25cm (1-4kg)</MenuItem>
                  <MenuItem value='small'>Pequeno - entre 25-41cm (4-8kg)</MenuItem>
                  <MenuItem value='medium'>Médio - entre 42-60cm (8-20kg)</MenuItem>
                  <MenuItem value='great'>Grande - entre 61-70cm (20-40kg)</MenuItem>
                  <MenuItem value='giant'>Gigante - maior que 70cm (mais de 40kg)</MenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.hadHeartProblems} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>O pet já teve ou tem problemas cardíacos?</InputLabel>
                <Select
                  id='hadHeartProblems'
                  name='hadHeartProblems'
                  key='hadHeartProblems'
                  label='O pet já teve ou tem problemas cardíacos?'
                  defaultValue='Selecione'
                  error={!!errors.hadHeartProblems}
                  helperText={errors.hadHeartProblems && errors.hadHeartProblems}
                  value={values.hadHeartProblems}
                  onChange={handleChange}
                >
                  <MenuItem value='Selecione'>Selecione</MenuItem>
                  <MenuItem value='true'>Sim</MenuItem>
                  <MenuItem value='false'>Não</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <TextField
                  id='beatsPerMinute'
                  name='beatsPerMinute'
                  type='number'
                  key='beatsPerMinute'
                  label='Quantos batimentos contou por minuto?'
                  error={!!errors.beatsPerMinute}
                  autoComplete='off'
                  placeholder='Digite quantos batimentos você contou'
                  helperText={errors.beatsPerMinute && errors.beatsPerMinute}
                  value={values.beatsPerMinute}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <HelperText>
                  Ao encostar sua mão no peito do pet, você consegue sentir o coração. Conte os batimentos durante 1 minuto (60 segundos)
                </HelperText>
              </FormControl>

              <FormControl error={!!errors.tirednessLevel} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Cansaço</InputLabel>
                <Select
                  id='tirednessLevel'
                  name='tirednessLevel'
                  defaultValue='Selecione'
                  label='Cansaço'
                  error={!!errors.tirednessLevel}
                  helperText={errors.tirednessLevel && errors.tirednessLevel}
                  value={values.tirednessLevel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione o estado de cansaço do seu pet</MenuItem>
                  <MenuItem value='no_fatigue'>Sem cansaço</MenuItem>
                  <ShrinkMenuItem value='tiredness_after_extreme_activity'>Cansaço após atividade física extrema</ShrinkMenuItem>
                  <ShrinkMenuItem value='tiredness_after_light_activity'>Cansaço após atividade física leve</ShrinkMenuItem>
                  <ShrinkMenuItem value='tiredness_at_rest'>Cansaço mesmo em repouso</ShrinkMenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.coughLevel} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Tosse</InputLabel>
                <Select
                  id='coughLevel'
                  name='coughLevel'
                  defaultValue='Selecione'
                  label='Tosse'
                  error={!!errors.coughLevel}
                  helperText={errors.coughLevel && errors.coughLevel}
                  value={values.coughLevel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione o estado de tosse do seu pet</MenuItem>
                  <MenuItem value='no_cough_at_night'>Sem tosse à noite</MenuItem>
                  <ShrinkMenuItem value='no_cough_after_shaking'>Sem tosse após agitação</ShrinkMenuItem>
                  <ShrinkMenuItem value='with_cough_at_night'>Com tosse à noite</ShrinkMenuItem>
                  <ShrinkMenuItem value='with_cough_after_shaking'>Com tosse após agitação</ShrinkMenuItem>
                </Select>
              </FormControl>

              <CancelOrSubmit loading={loading === 'loading'} />
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>

  );
};

export default HeartControl;
