import styled from 'styled-components';
import { Button, Grid, Typography } from '@mui/material';

export const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    height: 120px;
    border-radius: 12px;
    background: ${({ $color1, $color2 }) => `linear-gradient(265.93deg, ${$color1} 12.15%, ${$color2} 71.7%);`};
    padding: 16px 24px;
  }
`;

export const ServiceTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: ${({ telemedicine }) => (telemedicine ? '#6B48FF' : 'white')};
    width: 180px;
  }
`;

export const ServiceTelemedicineType = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 12px;
    color: #7E8192;
    width: ${({ telemedicine }) => (telemedicine === 'promotion' ? '145px' : '120px')};
    margin-left: 7px;
    white-space: ${({ telemedicine }) => (telemedicine === 'promotion' ? 'wrap' : 'nowrap')};
  }
`;

export const ServiceTypeControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ServiceCTA = styled(Button)`
  &.MuiButtonBase-root {
    padding: 4px 10px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    color: white;
    margin-bottom: 5px;
  }
`;

export const ServicePhoto = styled.img`
  width: 150px;
  height: 120px;
  object-fit: contain;
  z-index: 0;

  ${({ telemedicine }) => (telemedicine && 'object-fit: cover; position: absolute; right: 0px; top: 0px')}
`;

export const ServiceQuestions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #1EE0CC;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #1EE0CC;
  padding-left: 8px;
`;
