/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { Autocomplete, Avatar, Badge, Fade, TextField, Card, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Typography, Select, Chip, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { PickersDay } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as CatHelperIcon } from '../../../../assets/images/Services/cat_help.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/images/Services/arrow_right.svg';
import { EventTitle, PartnerTitle, Calendar, AvailablePeriod, UnavaiablePeriods } from './style';
import EventScheduleDialog from './EventScheduleDialog';
import { getPetPlaceholder } from '../../../../services/api';
import CalendarHelperDialog from './CalendarHelperDialog';
import EventErrorDialog from './EventErrorDialog';
import FillProfileDialog from './FillProfileDialog';
import Loading from '../../../global/Loading';
import theme from '../../../../utils/theme';

const EventCreate = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [helperModalOpen, setHelperModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [fillProfileModalOpen, setFillProfileModalOpen] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('Agendar');
  const [isLoading, setLoading] = useState(false);
  const [searchAvailabilityLoading] = useState(false);
  const [calendarDates, setCalendarDates] = useState([]);
  const [scheduleHasBeenCreated, setScheduleHasBeenCreated] = useState(false);
  const [scheduleErrorMessage, setScheduleErrorMessage] = useState('');
  const { scheduleAnimalList } = useSelector((state) => state.Appointments);
  const { dayScheduledB2CList: dayScheduledB2CListLoading } = useSelector((state) => state.Appointments.loading);
  const { dayScheduledB2CList, publicSchedule } = useSelector((state) => state.Appointments) || [];
  const { name, user, description, serviceTypes, periodStart, category } = useSelector((state) => state.Appointments.actualService);
  const { id: loggedUserId, isProfileFilled } = useSelector((state) => state.User.userData);
  const { lastTriggeredEvent } = useSelector((state) => state.User);
  const [isPeriodSelected, setIsPeriodSelected] = useState(!!((publicSchedule && publicSchedule.eventStart) || (isProfileFilled && lastTriggeredEvent.payload?.serviceId) || false));
  const [selectedPeriod, setSelectedPeriod] = useState((
    publicSchedule && publicSchedule.eventStart)
    || (lastTriggeredEvent && lastTriggeredEvent.payload && lastTriggeredEvent.payload.eventStart)
    || null);
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const isAvailabilityLoading = dayScheduledB2CListLoading !== 'fulfilled' && searchAvailabilityLoading;
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!user?.profileImage && fetchProfileImage();
  }, [user]);

  useEffect(() => {
    if (category === 'Guiavet Telemedicina' && isDefaultPlan === true) {
      return history.push('/services');
    }

    return true;
  });

  const [scheduleAnimalListAll, setScheduleAnimalListAll] = useState([]);

  const fetchAnimalImage = async () => {
    const animalList = [...scheduleAnimalList];
    await Promise.all(animalList.map(async (animal) => {
      const placeholder = getPetPlaceholder(animal);
      // eslint-disable-next-line no-param-reassign
      animal.animalImgSrc = (await dispatch.Photo.getPhoto(animal.avatarImage)) || placeholder;
    }));
    setScheduleAnimalListAll(animalList);
  };

  useEffect(() => {
    !!scheduleAnimalList?.length && fetchAnimalImage();
  }, [scheduleAnimalList]);

  const isMySchedule = (animalUserId) => animalUserId === loggedUserId;

  const checkIfDateIsBefore = (date) => (moment().isSameOrBefore(date) ? moment(date) : moment());

  const [dateSelected, setDateSelected] = useState(
    (publicSchedule && publicSchedule.eventStart && checkIfDateIsBefore(publicSchedule.eventStart))
      || (lastTriggeredEvent && lastTriggeredEvent.payload && lastTriggeredEvent.payload.eventStart && checkIfDateIsBefore(lastTriggeredEvent.payload.eventStart))
      || checkIfDateIsBefore(periodStart),
  );

  const [month, setMonth] = useState(moment(dateSelected).month());

  const handleSelectPeriod = async (newEventStart) => {
    if (!isProfileFilled) {
      const eventTrigger = {
        triggerName: 'service-scheduling',
        type: 'redirect',
        urlPattern: window.location.href,
        payload: {
          serviceId: params.id,
          eventStart: newEventStart,
        },
      };

      await dispatch.User.clearEventTrigger();
      await dispatch.User.setLastEventTrigger(eventTrigger);

      setFillProfileModalOpen(true);
    } else {
      setSelectedPeriod(newEventStart);
      setIsPeriodSelected(true);

      window.scroll({
        top: 0,
        left: 0,
        behaviour: 'smooth',
      });
    }
  };

  const fetchDayScheduleList = async (selectedDay) => {
    const payload = {
      service: params.id,
      startDate: moment(selectedDay).startOf('day').format(),
      endDate: moment(selectedDay).endOf('day').format(),
    };

    await dispatch.Appointments.listScheduleB2C(payload);
  };

  const handlePetSearch = async (event, setFieldValue) => {
    const { target: { value } } = event;

    await dispatch.Appointments.getAnimalsListToSchedule({ name: value });

    const findAnimalByName = scheduleAnimalListAll
      .filter((animal) => animal.user?.id === loggedUserId)
      .find((animal) => animal.name === value);

    if (findAnimalByName) {
      setFieldValue('animalId', findAnimalByName.id);
    }
  };

  const handlePetSelect = (event, option, setFieldValue) => {
    if (option) {
      setFieldValue('animalId', option.id);
    }
  };

  const fetchScheduleAnimals = (animalName) => {
    dispatch.Appointments.getAnimalsListToSchedule({ name: animalName });
  };

  useEffect(() => fetchScheduleAnimals(), []);
  useEffect(() => fetchDayScheduleList(dateSelected), [dateSelected, scheduleHasBeenCreated]);

  useEffect(async () => {
    const response = await dispatch.Appointments.getB2CCalendarAvailability({
      serviceId: params.id,
      startDate: moment(dateSelected).startOf('month').format(),
      endDate: moment(moment(moment(moment(dateSelected).endOf('month')).add(1, 'day'))).startOf('day').format(),
    });

    if (response && response.data && response.data.length) {
      const days = response.data.map((availability) => ({ ...availability, date: moment(availability.date) }));

      setCalendarDates(days);
    }
  }, [month, scheduleHasBeenCreated]);

  useEffect(() => dispatch.Appointments.serviceDetail(params.id), []);

  useEffect(() => {
    if (calendarDates && calendarDates.length > 0) {
      const findDate = calendarDates.find((calendar) => (calendar.hasPendingScheduling || calendar.hasTimeAvailable));
      if (findDate) {
        setDateSelected(moment(findDate.date).startOf('day').format());
        const buttonClick = '.badge-'.concat(moment(findDate.date).format('YYYY-MM-DD')).concat(' button');

        if (document.querySelector(buttonClick)) {
          setTimeout(() => { document.querySelector(buttonClick).click(); }, 500);
        }
      } else {
        // Caso nao exista disponibilidade clicar no botao de proximo
        setTimeout(() => { document.querySelector('button[title="Next month"]').click(); }, 500);
      }
    }
  }, [calendarDates]);

  const redirectToLastPage = () => {
    if (isPeriodSelected) {
      return setIsPeriodSelected(false);
    }

    return history.goBack();
  };

  const timeConvert = (n) => {
    const num = n;
    if (num < 60) {
      return `${num} minutos`;
    }
    const hours = (num / 60);
    let rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (rminutes === 0 && rhours === 1) {
      return `${rhours} hora`;
    }

    if (rminutes === 0 && rhours > 1) {
      return `${rhours} horas`;
    }

    if (rhours < 10) {
      rhours = `0${rhours}`;
    }

    if (rminutes < 10) {
      rminutes = `0${rminutes}`;
    }

    return `${rhours}:${rminutes}`;
  };

  const handleSchedule = async (formData) => {
    setLoading(true);

    setButtonMessage('Confirmando disponibilidade...');

    const { serviceTypeId, animalId: formDataAnimalId } = formData;

    const isPeriodAvailable = await dispatch.Appointments.verifyScheduleAvailability({
      startDate: moment(selectedPeriod).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-00:00]'),
      animal: formDataAnimalId,
      serviceTypeId,
    });

    if (isPeriodAvailable && isPeriodAvailable.data && isPeriodAvailable.data.serviceCanBeScheduled && !isPeriodAvailable.data.reason) {
      const payload = {
        serviceTypeId,
        animalId: formDataAnimalId,
        eventStart: moment(selectedPeriod).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-00:00]'),
      };

      const createdSchedule = await dispatch.Appointments.createSchedule(payload);

      if (createdSchedule && createdSchedule.data) {
        setModalOpen(true);
        setLoading(false);
        setScheduleHasBeenCreated(true);
        setButtonMessage('Agendado');
        dispatch.User.clearEventTrigger();
      } else {
        setScheduleErrorMessage('Houve uma falha durante o agendamento. Tente novamente');
        setLoading(false);
        setErrorModalOpen(true);
        setButtonMessage('Agendar');
      }
    } else {
      setScheduleErrorMessage(isPeriodAvailable.data.reason);
      setLoading(false);
      setErrorModalOpen(true);
      setTimeout(() => {
        setButtonMessage('Agendar');
      }, 3000);
    }
  };

  return (
    <Fade in>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
          spacing={1}
        >
          <CalendarHelperDialog open={helperModalOpen} setModalOpen={setHelperModalOpen} />
          <EventScheduleDialog open={modalOpen} setModalOpen={setModalOpen} />
          <EventErrorDialog open={errorModalOpen} setModalOpen={setErrorModalOpen} errorMessage={scheduleErrorMessage} setIsPeriodSelected={setIsPeriodSelected} />
          <FillProfileDialog open={fillProfileModalOpen} />
          <Grid onClick={redirectToLastPage} container direction='row' alignItems='center' sx={{ padding: '0px 0px 18px 0', marginLeft: '5px' }}>
            <Grid item>
              <ArrowBackIcon sx={{ color: theme.primaryButton }} />
            </Grid>

            <Grid item>
              <Button size='large' sx={{ color: theme.primaryButton, padding: '0px', marginRight: '5px' }}>Voltar</Button>
            </Grid>
          </Grid>
          <Grid item>
            <Card elevation={0} sx={{ padding: '17px 32px' }}>
              <Grid
                container
                direction='column'
                gap={2}
              >
                <Grid container justifyContent='space-between'>
                  <Typography variant='h6'>Novo agendamento</Typography>
                  {!isPeriodSelected ? <CatHelperIcon onClick={() => setHelperModalOpen(true)} /> : <></>}
                </Grid>

                <Grid container direction='row' wrap='nowrap'>
                  <Avatar
                    sx={{ width: '56px', height: '56px' }}
                    src={user?.profileImage && profileImgSrc}
                    alt={user?.firstName}
                  />

                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    sx={{ width: '50%', marginLeft: '12px' }}
                  >
                    <PartnerTitle>{user && user.firstName}</PartnerTitle>
                    <EventTitle>{name}</EventTitle>
                  </Grid>

                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                      {description || ' '}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />

                {!isPeriodSelected ? (
                  <Grid item>
                    <Calendar
                      value={dateSelected}
                      defaultCalendarMonth={moment(dateSelected)}
                      maxDate={moment().endOf('year')}
                      showDaysOutsideCurrentMonth={false}
                      outsideCurrentMonth={false}
                      onMonthChange={(monthDate) => {
                        setMonth(moment(monthDate).month());
                        setDateSelected(moment(monthDate).startOf('day').format());
                      }}
                      onChange={(newDate) => {
                        const nextDate = moment(newDate).startOf('day').format();
                        setDateSelected(nextDate);
                      }}
                      shouldDisableDate={(calendarDate) => {
                        const findDate = calendarDates.length && calendarDates.find((calendar) => moment(calendarDate).isSame(calendar.date));

                        const hasTimeAvailable = (findDate && !findDate.hasTimeAvailable);

                        return hasTimeAvailable;
                      }}
                      renderDay={(day, _value, DayComponentProps) => {
                        const renderedDay = moment(day);
                        const selected = moment(renderedDay).isSame(dateSelected);

                        const findDate = calendarDates.length && calendarDates.find((calendar) => moment(renderedDay).isSame(calendar.date));

                        const hasSchedule = findDate && findDate.hasPendingScheduling;

                        return (
                          <Badge
                            className={'badge-'.concat(moment(day).format('YYYY-MM-DD'))}
                            key={moment(day).toString()}
                            overlap='circular'
                            badgeContent={hasSchedule ? ' ' : undefined}
                            color='primary'
                            sx={{ '& .MuiBadge-badge': { backgroundColor: '#6B48FE' } }}
                            variant={hasSchedule ? 'dot' : undefined}
                          >
                            <PickersDay sx={selected && { backgroundColor: '#6B48FE', color: 'white' }} {...DayComponentProps} />
                          </Badge>
                        );
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid container direction='column' alignItems='center' sx={{ width: '100%' }}>
                    <Typography variant='h6' color='#6B48FE'>{moment(selectedPeriod).utc().format('HH:mm')}</Typography>
                    <Typography variant='body2'>{moment(selectedPeriod).utc().format('DD [de] MMMM [|] dddd')}</Typography>

                    <Formik
                      validateOnChange
                      validateOnBlur={false}
                      initialValues={{}}
                      onSubmit={handleSchedule}
                      validationSchema={Yup.object().shape({
                        animalId: Yup.string().required('Favor selecionar um pet'),
                        serviceTypeId: Yup.string().required('Favor selecionar um tipo de serviço'),
                      })}
                    >
                      {({ handleSubmit, setFieldValue, values, handleChange, errors }) => (
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                          <Autocomplete
                            blurOnSelect='touch'
                            disablePortal
                            name='animalId'
                            openOnFocus
                            freeSolo
                            getOptionLabel={(option) => option.name}
                            onChange={(event, option) => handlePetSelect(event, option, setFieldValue)}
                            options={scheduleAnimalListAll.filter((animal) => animal.user?.id === loggedUserId)}
                            sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 }, marginTop: '34px' }}
                            renderOption={(props, option) => (
                              <MenuItem {...props} value={option.id}>
                                <Avatar
                                  src={option.animalImgSrc}
                                  alt={option.name}
                                  sx={{ marginRight: '5px' }}
                                />
                                <div>{option.name}</div>
                              </MenuItem>
                            )}
                            renderInput={(props) => (
                              <TextField
                                id='animalId'
                                name='animalId'
                                error={!!errors.animalId}
                                helperText={errors.animalId ? errors.animalId : ''}
                                onChange={(event) => handlePetSearch(event, setFieldValue)}
                                label='Digite o nome do pet'
                                variant='outlined'
                                {...props}
                              />
                            )}
                          />

                          <Grid container justifyContent='flex-start'>
                            <FormHelperText>
                              Ainda não criou o perfil do pet?
                              {' '}
                              <a href='/pet/create' style={{ textDecoration: 'none', color: '#6B48FE' }}>Clique aqui</a>
                              {' '}
                              e cadastre um novo pet.
                              {' '}
                            </FormHelperText>
                          </Grid>

                          <Grid container direction='column' justifyContent='center' alignItems='center'>
                            <FormControl error={errors && errors.serviceTypeId} sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 }, marginTop: '24px' }}>
                              <InputLabel id='demo-simple-select-helper-label'>Evento</InputLabel>
                              <Select
                                id='serviceTypeId'
                                name='serviceTypeId'
                                label='Evento'
                                value={values.serviceTypeId}
                                onChange={handleChange}
                              >
                                {serviceTypes && serviceTypes.length ? serviceTypes.map((service) => (
                                  <MenuItem value={service.id}>
                                    {service.name}
                                  &nbsp;-&nbsp;
                                    {timeConvert(service.serviceDurationMinutes)}
                                  </MenuItem>
                                )) : <></>}
                              </Select>
                              <FormHelperText>{errors.serviceTypeId ? errors.serviceTypeId : ''}</FormHelperText>
                            </FormControl>

                            <LoadingButton
                              type='submit'
                              variant='contained'
                              loading={isLoading}
                              loadingPosition='start'
                              sx={{ width: '100%', margin: '24px 0', background: '#6B48FE', color: 'white', borderRadius: '20px' }}
                            >
                              {buttonMessage}
                            </LoadingButton>

                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                )}
              </Grid>
            </Card>

          </Grid>

          {!isPeriodSelected ? (
            <Grid item>
              <Card elevation={0} sx={{ padding: '17px 16px' }}>
                <Grid container gap={3}>

                  <Grid container direction='column' alignItems='center'>
                    <Typography variant='h6'>Horários livres</Typography>
                    <Typography variant='body2'>{moment(dateSelected).format('DD [de] MMMM [|] dddd')}</Typography>
                  </Grid>

                  {isAvailabilityLoading ? (
                    <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ minHeight: '40vh' }}>
                      <Grid item>
                        <Loading />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Grid container direction='column'>
                        {dayScheduledB2CList && dayScheduledB2CList.length ? dayScheduledB2CList
                          .map((service) => (
                            <AvailablePeriod
                              sx={{ '&.MuiListItem-root': { cursor: service.eventStatus === 'available' ? 'pointer' : 'default' } }}
                              onClick={service.eventStatus === 'available'
                                ? () => handleSelectPeriod(service.eventStart)
                                : undefined}
                            >
                              <Typography
                                sx={{ opacity: service.eventStatus === 'unavailable' || service.eventStatus === 'Pending' || isMySchedule(service.serviceScheduling?.animal?.user.id) ? 0.3 : 1 }}
                              >
                                {moment(service.eventStart).utc().format('HH:mm')}
                              </Typography>

                              {service.eventStatus === 'Pending' && isMySchedule(service.serviceScheduling?.animal?.user.id)
                                ? <Chip size='small' sx={{ color: 'white', background: '#6B48FE' }} label='Este é seu horário agendado' />
                                : service.eventStatus === 'available' && <ArrowRight />}
                            </AvailablePeriod>
                          )) : (
                            <Grid container justifyContent='center'>
                              <UnavaiablePeriods>
                                Nenhum horário disponível esse dia. Que tal
                                {' '}
                                <b>selecionar outra data</b>
                                ?
                              </UnavaiablePeriods>
                            </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>
            </Grid>
          ) : <></>}

        </Grid>
      </Grid>
    </Fade>
  );
};

export default EventCreate;
