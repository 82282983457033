/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../../../global/Alert';
import { ModalBox, CloseModal, ButtonControl, ButtonModal, ConnectTextControl } from './style';
import Loading from '../../../global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const ReconnectContent = () => {
  const dispatch = useDispatch();
  const { listType } = useSelector((state) => state.Network);
  const { id, username } = useSelector((state) => state.Network.modal.networkModal.data) || '';

  const [submitedStatus, setSubmitedStatus] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  const handleSubmitDisconnect = async () => {
    setSubmitLoading(true);
    Mixpanel.track(MIXPANEL_TAGS.RECONNECT_CLICK);

    const payload = {
      connectionId: id,
    };

    const activateContact = await dispatch.Network.activateContact(payload);

    if (activateContact && activateContact.data) {
      dispatch.Network.updateNetworkContactStatus(activateContact.data);
      setSubmitedStatus('success');
      setTimeout(() => closeModal(), 1000);
    } else {
      setSubmitLoading(false);
      setSubmitedStatus('error');
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.RECONNECT_MODAL_LOAD);
  }, []);

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Typography id='reconectar-contato' variant='h6' component='h2'>
          Compartilhamento dados dos pets
        </Typography>

        <ConnectTextControl>
          <Typography id='reconectar-contato' variant='body1'>
            {listType === 'follower' ? (
              <>
                Deseja voltar a compartilhar seus pets com
                {' '}
                <b>{username}</b>
                ?
              </>
            ) : (
              <>
                Deseja voltar a visualizar os pets de
                {' '}
                <b>{username}</b>
                ?
              </>
            )}
          </Typography>
        </ConnectTextControl>
        <ButtonControl>
          {submitLoading ? (
            <Grid sx={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '45px' }}>
              <Loading size={30} />
            </Grid>
          ) : (
            <>
              <ButtonModal style={{ marginRight: '42px' }} type='button' onClick={closeModal}>Cancelar</ButtonModal>
              <ButtonModal style={{ marginRight: '25px' }} type='submit' mainButton onClick={handleSubmitDisconnect}>Reconectar</ButtonModal>
            </>
          )}

        </ButtonControl>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Contato reconectado com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha ao reconectar contato
              </Alert>
            </div>

          )}

        </Snackbar>
      </Grid>
    </>
  );
};

export default ReconnectContent;
