import React from 'react';

import { Close } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import CircleIcon from '../../../../assets/images/Pricing/circle.png';
import WarningIcon from '../../../../assets/images/Pricing/warning.png';
import CheckedIcon from '../../../../assets/images/Pricing/checked.png';
import { isMobile, isNative } from '../../../../utils/mobile';

const PricingSigningPaymentDialog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { open } = useSelector((state) => state.SigningPlans.modal.signingPayment);
  const { title, subtitle, status } = useSelector((state) => state.SigningPlans.modal.signingPayment.data);

  const statusMap = {
    success: CheckedIcon,
    error: CircleIcon,
    pending: WarningIcon,
  };

  const handleConfirm = async () => {
    if (status === 'success') {
      dispatch.SigningPlans.setModal({ signingPayment: { open: false, data: { title, subtitle, status } } });

      const workflow = localStorage.getItem('workflow');

      if (isNative && workflow === 'triage') {
        localStorage.removeItem('workflow');
        window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'TRIAGE_SCREEN', params: { toStep: 'triage' } }));
      } else {
        history.push('/services');
      }
    } else {
      dispatch.SigningPlans.setModal({ signingPayment: { open: false, data: { title, subtitle, status } } });
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => dispatch.SigningPlans.setModal({ signingPayment: { open: false, data: { title, subtitle, status } } })}
    >
      <DialogTitle>
        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item>
            <Close sx={{ cursor: 'pointer' }} onClick={() => dispatch.SigningPlans.setModal({ signingPayment: { open: false, data: { title, subtitle, status } } })} />
          </Grid>
        </Grid>
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '34px' }} gap={2}>
          <Grid item>
            <img src={statusMap[status]} alt='icon' />
          </Grid>

          <Grid item>
            <Typography sx={{ fontSize: '32px', lineHeight: '35px', fontWeight: 600, textAlign: 'center' }}>{title}</Typography>
          </Grid>

          <Grid item>
            <Typography sx={{ fontSize: '18px', lineHeight: '22px', fontWeight: 400, textAlign: 'center' }}>{subtitle}</Typography>
          </Grid>

          <Grid item sx={{ marginTop: '68px' }}>
            <Button
              variant='large'
              sx={{ color: '#6B48FF' }}
              onClick={handleConfirm}
            >
              {status === 'success' ? 'DEU CERTO, VAMOS LA!' : 'OK'}
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent />
    </Dialog>
  );
};

export default PricingSigningPaymentDialog;
