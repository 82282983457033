/* eslint-disable consistent-return */
import styled from 'styled-components';
import { Grid } from '@mui/material';
import theme, { animations } from '../../../utils/theme';

export const StyledGridContainer = styled(Grid)`
  animation: ${animations.fadeIn} .2s ease-in;
`;

export const StyledGridItem = styled(Grid)`
  width: 90%;
`;

export const InviteControl = styled.div`
  margin: 40px 0 25px 0px;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EmptyNetwork = styled.img`
  width: 400px;
  height: 400px;
  animation: ${animations.fadeIn} .2s ease-in-out;

  @media screen and (max-width: 736px) {
      width: 250px;
      height: 250px;
    }
`;

export const TableMenu = styled.div`
  position: absolute;
  display: flex;
  width: 100px;
  left: 0;
  flex-direction: column;
  margin: 20px 0 0 30px;
  top: 75px;
`;

export const TableItem = styled.div`
	opacity: ${({ selected }) => (selected ? '1.0' : '0.5')};
  border-bottom: ${({ selected }) => (selected ? `4px solid ${theme.menuIndicator}` : 'none')};
  border-radius: 2px;
  padding-bottom: 5px;
	transition: opacity 0.2s ease-in;
  cursor: pointer;

	&:hover {
		opacity: 1;
	}

	:nth-child(1) {
		margin-left: 0;
	}

	& {
		margin-left: 32px;
	}
`;
