import React from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

import { RedeemButton } from './style';
import Loading from '../../../global/Loading';
import { isMobile } from '../../../../utils/mobile';

const RedeemDialog = ({ open, setAlert, setRedeemDialogOpen, setVoucherDialogOpen }) => {
  const dispatch = useDispatch();
  const voucherB2BDetailLoading = useSelector((state) => state.Vouchers.loading.voucherB2BDetail);
  const isRedeemLoading = voucherB2BDetailLoading === 'loading';

  const handleRedeemCodeSubmit = async ({ redeemCode }) => {
    const response = await dispatch.Vouchers.voucherB2BDetail(redeemCode.split(' ').join(''));

    if (response && response.data) {
      setVoucherDialogOpen(true);
      setRedeemDialogOpen(false);
    } else {
      setAlert({ open: true, message: 'Código de resgate inválido', success: 'error' });
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '650px',
        padding: '20px 15px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '220px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => undefined}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            Resgatar vale
          </Grid>

          <Grid item>
            <CloseIcon onClick={() => setRedeemDialogOpen(false)} sx={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={false}>
        <Typography>Digite o código do cliente</Typography>

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          onSubmit={handleRedeemCodeSubmit}
          validationSchema={Yup.object().shape({
            redeemCode: Yup.string().required('Favor preencher o campo'),
          })}
        >
          {({ handleChange, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>

              <Grid container flexDirection='column' justifyContent='center' alignItems='center'>
                <Grid item>
                  <InputMask
                    mask='999 999 999'
                    name='redeemCode'
                    onChange={handleChange}
                    maskplaceholder='_'
                  >
                    {(inputProps) => (
                      <TextField
                        id='redeemCode'
                        name='redeemCode'
                        helperText={errors && errors.redeemCode}
                        error={!!(errors && errors.redeemCode)}
                        label='Código de resgate'
                        sx={{ width: '300px', '& .MuiFormHelperText-root': { marginLeft: 0 }, margin: '60px 0' }}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </Grid>

                {isRedeemLoading ? (
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <Loading size={25} />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <RedeemButton
                      type='submit'
                      size='large'
                      sx={{ color: '#6B48FE', width: '300px' }}
                    >
                      Resgatar o vale compras
                    </RedeemButton>
                  </Grid>
                )}

              </Grid>
            </form>
          )}
        </Formik>

      </DialogContent>
    </Dialog>
  );
};

export default RedeemDialog;
