import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import VoucherCategoryFilterList from './VoucherCategoryFilterList';
import VoucherProductList from './VoucherProductList';
import { ReactComponent as EmptyPromotions } from '../../../assets/images/Vouchers/emptyVouchers.svg';
import MobileAppDialog from '../../Login/LoginForm/MobileAppDialog';
import { StyledGrid } from './style';

const VouchersDashboard = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [isFirstLoading, setFirstLoading] = useState(true);

  const { actualB2CCategory, promotionB2CList } = useSelector(({ Vouchers }) => Vouchers) || [];
  const isEmpty = promotionB2CList.length === 0 && !isFirstLoading;

  const fetchPromotionB2C = async () => {
    setHasMoreItems(true);
    setPage(0);

    const category = actualB2CCategory === 'Todos' ? '' : actualB2CCategory;

    const response = await dispatch.Vouchers.promotionB2CList({
      ...(category && { category }),
      limit: rowsPerPage,
    });

    const responseIsEmpty = response && response && !response.data.length;

    if (responseIsEmpty) {
      setHasMoreItems(false);
    }

    if (response && response.data) {
      setFirstLoading(false);
      setPage(1);
    }
  };

  const updatePromotionB2C = async () => {
    const category = actualB2CCategory === 'Todos' ? '' : actualB2CCategory;

    if (hasMoreItems) {
      const response = await dispatch.Vouchers.updatePromotionB2CList({
        ...(category && { category }),
        page,
        limit: rowsPerPage,
      });

      const responseIsEmpty = response && response.data && !response.data.length;

      if (responseIsEmpty) {
        setHasMoreItems(false);
      }

      if (response && response.data) {
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    fetchPromotionB2C();
  }, [actualB2CCategory]);

  return (
    <StyledGrid container direction='column' justifyContent='center' alignItems='center'>
      <MobileAppDialog />
      <Grid container direction='column' sx={{ maxWidth: '1280px', marginTop: '30px', paddingInline: '24px' }}>
        <Typography variant='h6'>Ofertas de vales-compra</Typography>
      </Grid>

      <VoucherCategoryFilterList />
      {isEmpty ? (
        <Grid container flexDirection='column' justifyContent='center' alignItems='center' sx={{ minHeight: '50vh' }}>
          <Grid item>
            <EmptyPromotions />
          </Grid>

          <Grid item>
            <Typography sx={{ width: '300px', fontSize: '16px', opacity: '0.4' }}>
              Não encontramos ofertas na sua região, se você gostaria de indicar seu pet shop favorito para cadastrar promoções envie uma mensagem para indicacao@guia.vet
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <VoucherProductList refetch={updatePromotionB2C} vouchers={promotionB2CList} page={page} />
      )}

    </StyledGrid>
  );
};

export default VouchersDashboard;
