import { Alert, Card, Fade, Grid, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UpdateIcon from '@mui/icons-material/Update';
import moment from 'moment';
import QRCode from 'react-qr-code';
import { PIXCopyButton, VoucherTitle, ExpireInfo, ExpireText, Price, PriceValue, QrCodeContainer } from './style';
import { formatBRL } from '../../../../utils/format';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const VoucherPendingCard = () => {
  const { name, promotionValidUntilDatetime, promotionPriceWithDiscount, receivableAccount, promotionVoucher } = useSelector((state) => state.Vouchers.purchasedVoucher);
  const qrCode = receivableAccount?.starkbankInvoice?.brCode;
  const [alert, setAlert] = useState(false);

  const copyQrCode = (code) => {
    if (code) {
      setAlert(true);
      navigator.clipboard.writeText(code);
      window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: code } }));
    }
  };

  const onClickPIXCopyButton = () => {
    copyQrCode(receivableAccount?.starkbankInvoice?.brCode);
    Mixpanel.track(MIXPANEL_TAGS.PRODUCT_QRCODE);
  };

  return (
    <>
      <Fade in>
        <Card elevation={0} sx={{ marginTop: '10px', minHeight: '70vh', padding: '10px 38px', position: 'relative' }}>
          <Grid container wrap='nowrap' flexDirection='column' alignItems='center' spacing={1} sx={{ margin: '20px 0' }}>
            <Grid item>
              <VoucherTitle>{name}</VoucherTitle>
            </Grid>

            <Grid item>
              <ExpireInfo>
                <p>
                  Promoção válida até a data limite ou até completarem
                  {' '}
                  {promotionVoucher.totalQuantity}
                  {' '}
                  unidades*
                </p>

                <Grid container sx={{ marginTop: '4px' }}>
                  <UpdateIcon sx={{ width: '12px', height: '12px', color: '#FF4874', marginRight: '4px' }} />
                  <p>
                    {moment(promotionValidUntilDatetime).format('DD MMM YYYY')}
                    {' '}
                    (
                    {moment(promotionValidUntilDatetime).fromNow()}
                    )
                  </p>
                </Grid>
              </ExpireInfo>
            </Grid>

            <Grid item>
              <ExpireText>
                Falta um dia para seu pedido expirar, lembre-se que após o pagamento podem levar alguns minutos para validar a sua compra.
              </ExpireText>
            </Grid>

            <Grid container flexDirection='row' justifyContent='space-between'>
              <Price>Total</Price>
              <PriceValue>{formatBRL(promotionPriceWithDiscount)}</PriceValue>
            </Grid>

            <Grid container justifyContent='center' alignItems='center' flexDirection='column'>
              <QrCodeContainer>
                <QRCode size={126} value={qrCode} />
              </QrCodeContainer>
              <PIXCopyButton onClick={onClickPIXCopyButton} variant='contained' size='large' disableElevation>
                Copiar código PIX
              </PIXCopyButton>
            </Grid>
          </Grid>
        </Card>
      </Fade>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert}
        autoHideDuration={7000}
        onClose={() => setAlert(false)}
      >
        <Alert severity='success'>
          Código copiado com sucesso!
        </Alert>
      </Snackbar>
    </>
  );
};

export default VoucherPendingCard;
