import React, { useState } from 'react';
import { Button, Card, CardContent, Fade, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import theme, { PrimaryButton } from '../../../../utils/theme';
import Loading from '../../../global/Loading';
import WeekDayRow from './WeekDayRow';
import { ReactComponent as AddIcon } from '../../../../assets/images/Services/add.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/Services/trash.svg';
import AppointmentDeleteConfirmation from '../AppointmentDeleteConfirmation';
import { DeleteButton } from './style';

const AppointmentAvailabilityCard = ({ setStep, setReadOnly, readOnly, errors, validateForm, setFieldValue }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const actualService = useSelector((state) => state.Appointments.actualService);
  const unavailability = useSelector((state) => state.Appointments.actualService.unavailability);
  const availability = useSelector((state) => state.Appointments.actualService.availability);

  const openModal = () => dispatch.Appointments.setModal({ calendarModal: { open: true, context: 'unavailable' } });

  const showError = (error, type) => {
    dispatch.Appointments.setModal({ appointmentErrorModal: { open: true, data: { error, type } } });
  };

  const submitData = async () => {
    setLoading(true);
    setReadOnly(true);

    const formValidation = await validateForm();

    if (Object.keys(formValidation).length !== 0) {
      setLoading(false);
      setReadOnly(false);
      return;
    }

    if (actualService && actualService.id) {
      const updatedService = await dispatch.Appointments.updateService();

      if (updatedService && updatedService.data) {
        window.scrollTo(0, 0);
        history.push(`/appointments/${actualService.id}`);
      } else if (updatedService.response.data.reason) {
        showError(updatedService.response.data.reason, 'editar');
      } else {
        showError('Houve uma falha ao editar sua agenda, favor verificar os dados inseridos.', 'editar');
      }

      setReadOnly(false);
      setLoading(false);
    } else {
      const createdService = await dispatch.Appointments.createService();

      if (createdService && createdService.data) {
        window.scrollTo(0, 0);
        dispatch.Appointments.updateMyServiceListCount();
        history.push(`/appointments/${actualService.id}`);
      } else if (createdService.response.data.reason) {
        showError(createdService.response.data.reason, 'criar');
      } else {
        showError('Você já possuí uma agenda com este nome, favor mudar o nome da agenda', 'criar');
      }

      setReadOnly(false);
      setLoading(false);
    }
  };
  const deleteUnavailability = (index) => {
    dispatch.Appointments.deleteUnavailability(index);
  };

  return (
    <Card elevation={0} style={{ marginTop: '30px' }}>
      <AppointmentDeleteConfirmation open={modalOpen} setModalOpen={setModalOpen} />
      <CardContent style={{ minHeight: '350px', padding: '30px' }}>
        <Typography variant='h6' sx={{ color: '#CCC3FE', visibility: readOnly ? 'hidden' : 'visible' }}>Adicionar agenda</Typography>

        <Typography variant='h4'>
          Disponibilidade da agenda&nbsp;
          { actualService?.name }
        </Typography>

        <Typography variant='subtitle1' sx={{ color: '#7E8192' }}>Marque os dias disponíveis na agenda e configure os horários de trabalho</Typography>

        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={3}
          sx={{ margin: '30px 0' }}
        >
          <Grid container sx={{ width: '70%', marginBottom: '30px' }} gap={2}>
            {availability && availability.length ? availability.map((availabilityUnit, day) => (
              <WeekDayRow
                errors={errors}
                readOnly={readOnly}
                weekdayNumber={day}
                setFieldValue={setFieldValue}
              />
            )) : <></>}
          </Grid>

          <Grid container direction='column' sx={{ width: '50%' }}>
            <Typography variant='h6'>Configurar janela de Indisponibilidade</Typography>

            <Typography variant='subtitle1' sx={{ color: '#7E8192' }}>Adicione dias específicos como: feriados, dias de treinamento, folgas e etc</Typography>

            <Grid container direction='column' sx={{ width: '80%', marginTop: '30px', marginLeft: '10px' }} gap={3}>
              <>
                {(!readOnly) ? (
                  <>
                    <Button
                      onClick={openModal}
                      sx={{ color: theme.primaryButton, width: '80px' }}
                      startIcon={<AddIcon />}
                    >
                      Adicionar
                    </Button>
                  </>
                ) : <></>}
                {unavailability && unavailability.length ? unavailability.map((period, index) => (
                  <Fade in timeout={!index ? 500 : index * 500}>
                    <Grid
                      key={index}
                      container
                      direction='row'
                      alignItems='center'
                      gap={3}
                      sx={{ padding: '16px', boxShadow: 'inset 0px -1px 0px #E0E0E0' }}
                    >
                      <Typography variant='body2'>{moment(period.period_start).format('DD/MM/YYYY')}</Typography>
                      <Typography variant='body2'>-</Typography>
                      <Typography variant='body2'>
                        {moment(period.period_start).format('HH:mm')}
                        {' '}
                        -
                        {' '}
                        {moment(period.period_end).format('HH:mm')}
                      </Typography>

                      {(!readOnly) && (
                      <>
                        <Button
                          sx={{ color: theme.primaryButton }}
                          startIcon={<TrashIcon />}
                          onClick={() => { deleteUnavailability(index); }}
                        />
                      </>
                      )}
                    </Grid>
                  </Fade>
                )) : <></>}
              </>
            </Grid>
          </Grid>
        </Grid>

        {loading ? (
          <Grid container justifyContent='flex-end' alignItems='flex-end' sx={{ width: '100%' }}>
            <Grid item>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            {!readOnly ? (
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Button
                    size='large'
                    sx={{ color: theme.primaryButton }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      setStep('info');
                      window.scrollTo(0, 0);
                    }}
                  >
                    Voltar
                  </Button>
                </Grid>

                <Grid item>
                  <PrimaryButton onClick={() => { submitData(); }}>Salvar</PrimaryButton>
                </Grid>
              </Grid>
            ) : <></>}
          </>
        )}

        {readOnly ? (
          <Grid container justifyContent='flex-start'>
            <Grid item>
              <DeleteButton onClick={() => setModalOpen(true)}>Deletar agenda</DeleteButton>
            </Grid>
          </Grid>
        )
          : <></>}

      </CardContent>
    </Card>
  );
};

export default AppointmentAvailabilityCard;
