import { Card, CardContent, Divider, FormControl, FormHelperText, Grid, Typography, InputLabel, MenuItem, Select, TextField, Button, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as TrashIcon } from '../../../../assets/images/Services/trash.svg';
import { ReactComponent as AddIcon } from '../../../../assets/images/Services/add.svg';
import { ReactComponent as MapPinIcon } from '../../../../assets/images/Services/map_pin_form.svg';
import { datePickerErrorStyle } from '../../../../utils/theme';

const AppointmentInfoCard = ({ setStep, readOnly, errors, handleBlur, touched, setFieldValue, validateForm }) => {
  const dispatch = useDispatch();

  const name = useSelector((state) => state.Appointments.actualService.name);
  const description = useSelector((state) => state.Appointments.actualService.description);
  const category = useSelector((state) => state.Appointments.actualService.category);
  const periodStart = useSelector((state) => state.Appointments.actualService.periodStart);
  const serviceTypes = useSelector((state) => state.Appointments.actualService.serviceTypes);
  const address = useSelector((state) => state.Appointments.actualService.address);
  const timeBetweenServicesMinutes = useSelector((state) => state.Appointments.actualService.timeBetweenServicesMinutes);

  const sendReduxData = (newData) => dispatch.Appointments.setActualAppointmentsData(newData);

  const validateAndProgress = async () => {
    const formValidate = await validateForm();
    const firstValidationNames = ['name', 'category', 'serviceTypes', 'periodStart', 'timeBetweenServicesMinutes'];
    const validationErrors = Object.keys(formValidate).filter((validationName) => firstValidationNames.includes(validationName));

    const validationSuccess = !validationErrors.length;

    if (validationSuccess) {
      setStep('availability');
      window.scrollTo(0, 0);
    }
  };

  const [openPeriodStartPicker, setOpenPeriodStartPicker] = useState(false);
  const [categoryOptions] = useState([
    { label: 'Adestramento e Consultoria Comportamental', value: 'Adestramento' },
    { label: 'Banho e tosa', value: 'Banho e tosa' },
    { label: 'Consulta', value: 'Consulta' },
    { label: 'Dog Walker', value: 'Dogwalker' },
    { label: 'Pet Sitter', value: 'Petsitter' },
  ]);

  const addServiceType = () => dispatch.Appointments.addServiceType();
  const deleteServiceType = (index) => dispatch.Appointments.deleteServiceType(index);

  return (
    <Card elevation={0} style={{ marginTop: '30px' }}>
      <CardContent style={{ padding: '30px' }}>
        <Typography variant='h6' sx={{ color: '#CCC3FE', visibility: readOnly ? 'hidden' : 'visible' }}>Adicionar agenda</Typography>

        <Typography variant='h4'>Informações básicas</Typography>

        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          sx={{ marginTop: '50px' }}
        >
          <Grid
            container
            direction='column'
            sx={{ width: '48%' }}
            spacing={3}
          >
            <Grid item>
              <TextField
                id='name'
                autoFocus='autoFocus'
                name='name'
                label={!readOnly ? 'Nome' : ''}
                disabled={readOnly}
                helperText={errors.name ? errors.name : ''}
                sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                error={!!errors.name}
                onBlur={handleBlur}
                touched={touched.name}
                value={name}
                onChange={(event) => {
                  setFieldValue('name', event.target.value);
                  sendReduxData({ name: event.target.value });
                }}
              />
            </Grid>

            <Grid item>
              <DatePicker
                open={openPeriodStartPicker}
                id='periodStart'
                name='periodStart'
                label='Início'
                format='DD/MM/YYYY'
                disabled={readOnly}
                allowSameDateSelection
                defaultValue={null}
                maxDate={moment(new Date()).add(1, 'year')}
                value={periodStart}
                onChange={(event) => {
                  setFieldValue('periodStart', event);
                  sendReduxData({ periodStart: moment(event).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-03:00]') });
                }}
                onClose={() => setOpenPeriodStartPicker(false)}
                error={!!errors.periodStart}
                touched={touched.periodStart}
                helperText={errors.periodStart && errors.periodStart}
                renderInput={(params) => (
                  <TextField
                    sx={errors.periodStart ? datePickerErrorStyle : { width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                    onClick={() => setOpenPeriodStartPicker(true)}
                    error={!!errors.periodStart}
                    touched={touched.periodStart}
                    helperText={errors.periodStart && errors.periodStart}
                    {...params}
                  />
                )}
              />
              <FormHelperText style={{ position: 'absolute' }}>Data de abertura da agenda</FormHelperText>
            </Grid>

            <Grid item>
              <FormControl error={!!errors.category} sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel id='demo-simple-select-helper-label'>Categoria</InputLabel>
                <Select
                  id='category'
                  name='category'
                  options
                  disabled={readOnly}
                  label='Categoria'
                  value={category}
                  onChange={(event) => {
                    setFieldValue('category', event.target.value);
                    sendReduxData({ category: event.target.value });
                  }}
                  onBlur={handleBlur}
                  touched={touched.category}
                >
                  {categoryOptions.map(({ label, value }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction='column'
            sx={{ width: '50%' }}
            spacing={3}
          >
            <Grid item>
              <TextField
                id='description'
                name='description'
                label={!readOnly ? 'Descrição' : ''}
                disabled={readOnly}
                multiline
                rows={5}
                helperText={errors.description ? errors.description : ''}
                sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                error={!!errors.description}
                onBlur={handleBlur}
                touched={touched.description}
                value={description}
                onChange={(event) => {
                  setFieldValue('description', event.target.value);
                  sendReduxData({ description: event.target.value });
                }}
              />
            </Grid>

            <Grid item>
              <TextField
                id='address.street'
                name='address.street'
                disabled={readOnly}
                label='Endereço'
                helperText={errors.address?.street ? errors.address.street : ''}
                sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                error={!!errors.address?.street}
                onBlur={handleBlur}
                touched={touched.address?.street}
                value={address?.street}
                onChange={(event) => {
                  setFieldValue('address.street', event.target.value);
                  sendReduxData({ address: { street: event.target.value } });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MapPinIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '50px 0' }} />

        <Grid
          container
          direction='column'
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={3}
          sx={{ padding: '0 30px' }}
        >
          <Typography variant='h6'>Eventos</Typography>
          <Typography variant='subtitle1'>Adicione eventos de serviços realizados pela empresa</Typography>
          <Grid container direction='column' sx={{ position: 'relative', width: '45%', marginTop: '24px', marginBottom: '10px' }} gap={3}>
            {serviceTypes && serviceTypes.length ? serviceTypes.map((serviceType, index) => (
              <Grid container wrap='nowrap' direction='row' alignItems='center' gap={2}>
                <Grid item>
                  <TextField
                    id={`serviceTextTypeId${(serviceType.id)}`}
                    name={`serviceTypes[${(index)}].name`}
                    error={errors.serviceTypes && !!errors.serviceTypes[index]?.name}
                    label={`Evento ${(index + 1)}`}
                    disabled={readOnly}
                    sx={{ width: '350px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                    value={serviceType.name}
                    onChange={(event) => {
                      setFieldValue(`serviceTypes[${(index)}].name`, event.target.value);
                      serviceTypes[index].name = event.target.value;
                    }}
                  />
                </Grid>

                <Grid item>
                  <FormControl sx={{ width: '120px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                    <InputLabel id='demo-simple-select-helper-label'>Duração</InputLabel>
                    <Select
                      id={`serviceTextTypeId${(serviceType.id)}`}
                      name={`serviceTypes[${(index)}].serviceDurationMinutes`}
                      label={`Duração ${(index + 1)}`}
                      disabled={readOnly}
                      value={serviceTypes[index].serviceDurationMinutes}
                      onChange={(event) => {
                        setFieldValue(`serviceTypes[${(index)}].serviceDurationMinutes`, event.target.value);
                        serviceTypes[index].serviceDurationMinutes = event.target.value;
                      }}
                    >
                      <MenuItem value={15}>15 min</MenuItem>
                      <MenuItem value={30}>30 min</MenuItem>
                      <MenuItem value={45}>45 min</MenuItem>
                      <MenuItem value={60}>1 hora</MenuItem>
                      <MenuItem value={90}>1 hora e meia</MenuItem>
                      <MenuItem value={120}>2 horas</MenuItem>
                      <MenuItem value={150}>2 horas e meia</MenuItem>
                      <MenuItem value={180}>3 horas</MenuItem>
                      <MenuItem value={210}>3 horas e meia</MenuItem>
                      <MenuItem value={240}>4 horas</MenuItem>
                      <MenuItem value={270}>4 horas e meia</MenuItem>
                      <MenuItem value={300}>5 horas</MenuItem>
                      <MenuItem value={330}>5 horas e meia</MenuItem>
                      <MenuItem value={360}>6 horas</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                { (!readOnly && serviceTypes.length > 1) && (
                <Grid item>
                  <Button
                    sx={{ color: '#6B48FF' }}
                    startIcon={<TrashIcon />}
                    onClick={() => { deleteServiceType(index); }}
                  />
                </Grid>
                )}

                { (!readOnly && index === 0) && (
                <Grid item>
                  <Button
                    sx={{ color: '#6B48FF' }}
                    startIcon={<AddIcon />}
                    onClick={addServiceType}
                  >
                    Adicionar
                  </Button>
                </Grid>
                )}
              </Grid>
            )) : <></>}
            <Grid container>
              <Grid item sx={{ width: '100%' }}>
                <FormControl error={errors.timeBetweenServicesMinutes && !!errors.timeBetweenServicesMinutes} sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <InputLabel id='demo-simple-select-helper-label'>Reserva de tempo após um evento</InputLabel>
                  <Select
                    id='timeBetweenServicesMinutes'
                    disabled={readOnly}
                    name='timeBetweenServicesMinutes'
                    label='Reserva de tempo após um evento'
                    value={timeBetweenServicesMinutes}
                    onChange={(event) => {
                      setFieldValue('timeBetweenServicesMinutes', event.target.value);
                      sendReduxData({ timeBetweenServicesMinutes: event.target.value });
                    }}
                    onBlur={handleBlur}
                    touched={touched.timeBetweenServicesMinutes}
                  >
                    <MenuItem value={0}>Sem intervalo</MenuItem>
                    <MenuItem value={5}>5 min</MenuItem>
                    <MenuItem value={10}>10 min</MenuItem>
                    <MenuItem value={15}>15 min</MenuItem>
                    <MenuItem value={30}>30 min</MenuItem>
                    <MenuItem value={45}>45 min</MenuItem>
                    <MenuItem value={60}>1 hora</MenuItem>
                    <MenuItem value={90}>1 hora e meia</MenuItem>
                    <MenuItem value={120}>2 horas</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText sx={{ position: 'absolute', '&.MuiFormHelperText-root': { marginLeft: 0 } }}>Qual o tempo mínimo que precisa para se organizar após um evento</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!readOnly && (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          sx={{ marginTop: '50px' }}
        >
          <Grid item>
            <Button
              size='large'
              sx={{ color: '#6B48FF', visibility: 'hidden' }}
              startIcon={<ArrowBackIcon />}
            >
              Voltar
            </Button>
          </Grid>

          <Grid item>
            <Button
              size='large'
              onClick={validateAndProgress}
              endIcon={<ArrowForwardIcon />}
              sx={{ color: '#6B48FF' }}
            >
              Próximo
            </Button>
          </Grid>

        </Grid>
        )}

      </CardContent>
    </Card>
  );
};

export default AppointmentInfoCard;
