import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 82%;
  height: auto;
  font-family: 'Inter';
  padding: 16px;
  margin: 0px 16px;
  border-radius: 10px;
  background-color: white;
  align-self: center;
  cursor: pointer;
  background-image: url(${process.env.REACT_APP_WEB}/app/pngs/coupon-background-pattern.png);
`;

export const ReferAndWinText = styled.p`
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 14px;
  font-size: 20px;
  color: #F2C94B;
`;

export const ReferAndWinSubtext = styled.p`
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 19.6px;
  font-size: 14px;
  margin-top: 8px;
  color: #434977;
`;
