import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useHistory } from 'react-router-dom';
import ServiceCard from '../ServiceCard';

const ServiceCarousel = ({ events }) => {
  const [selectedEvent, setSelectedEvent] = useState();
  const history = useHistory();

  const redirectToDetailPage = () => {
    history.push(`/services/event/${selectedEvent}`);
  };

  useEffect(() => {
    if (events.length && events[0].serviceScheduling.id) {
      setSelectedEvent(events.length && events[0].serviceScheduling.id);
    }
  }, []);

  return (
    <Grid container sx={{ minHeight: '215px', height: 'auto', width: '100%' }}>
      <Grid onClick={redirectToDetailPage} item sx={{ width: '100%' }}>
        <Carousel
          onChange={(index) => setSelectedEvent((events.length && events[index].serviceScheduling.id) || null)}
          interval={4000}
          animation='slide'
          activeIndicatorIconButtonProps={{
            style: {
              color: '#7D5FFE',
            },
          }}
          indicatorIconButtonProps={{
            style: {
              marginTop: '16px',
              marginBottom: '32px',
              color: '#E1DAFF',
            },
          }}
        >
          {events.map(({ ...props }, index) => <ServiceCard {...props} key={index} />)}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default ServiceCarousel;
