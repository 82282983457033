import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ErrorModalContent from './ErrorModalContent';

const AppointmentErrorModal = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.Appointments.modal.appointmentErrorModal) || {};

  const closeModal = () => dispatch.Appointments.setModal({ appointmentErrorModal: { open: false } });

  return (
    <Modal
      open={open}
      onClose={closeModal}
    >
      <ErrorModalContent />
    </Modal>
  );
};

export default AppointmentErrorModal;
