import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Fade, Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { MainWrapper } from './style';
import { HOME_URL } from '../../utils/mobile';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const RedirectShortLinkPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [url, setUrl] = useState();

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.REDIRECT_SHORT_VIEW);

    const getUrlRedirect = async () => {
      const redirectUrl = await dispatch.User.getRedirectUrl(params.hash);

      if (redirectUrl && redirectUrl.data) {
        setUrl(redirectUrl.data.url);
      } else {
        history.push(`${HOME_URL}${history.location.search}`);
      }
    };

    getUrlRedirect();
  }, []);

  const RenderRedirect = () => {
    if (url) {
      window.location.href = url;
    }

    return (
      <MainWrapper>
        <Fade in>
          <Grid item xs={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Fade>
      </MainWrapper>
    );
  };

  return (
    <RenderRedirect />
  );
};

export default RedirectShortLinkPage;
