import React, { useEffect, useState, useRef } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import PricingCard from './PricingCard';
import Loading from '../../global/Loading';
import { PrimaryButton, TermsLink } from '../../../utils/theme';
import PricingAccordions from './PricingAccordions';
import useWindowSize from '../../../hooks/windowSize';
import PricingSigningTimeDialog from './PricingSigningTimeDialog';
import PricingSigningConfirmation from './PricingSigningConfirmation';
import PricingSigningPaymentDialog from './PricingSigningPaymentDialog';
import PlanAward from '../../../assets/images/UserProfile/award-blue.png';
import CompleteProfileDialog from '../../Modals/CompleteProfileDialog';
import CorporateSolicitationPlanDialog from '../../Modals/CorporateSolicitationPlanDialog';

import { NavigationStage, PlanCorporateStatusLabel, PlanType, PlanTypeControl, SliderGrid, StyledSlider } from './style';
import { isNative } from '../../../utils/mobile';
import { MIXPANEL_TAGS, Mixpanel } from '../../../utils/mixpanel';

const PricingDashboard = () => {
  const dispatch = useDispatch();
  const { slug: urlPlanSlug } = useParams();

  const retrieveSigningPlans = (allPlans) => {
    const onePlan = allPlans && allPlans.filter((plan) => (urlPlanSlug && plan.slug && urlPlanSlug.toLowerCase() === plan.slug.toLowerCase()));

    if (onePlan.length) {
      Mixpanel.track(MIXPANEL_TAGS.PRICING_VIEW, onePlan[0]);
      return onePlan;
    }

    Mixpanel.track(MIXPANEL_TAGS.PRICING_VIEW);
    return allPlans || [];
  };

  const [step, setStep] = useState(0);

  const sliderRef = useRef();
  const allSigningPlans = useSelector((state) => state.SigningPlans.signingPlans);
  const [signingPlans, setSigningPlans] = useState([]);
  // const signingPlans = retrieveSigningPlans(allSigningPlans);
  const signingPlansLoading = useSelector((state) => state.SigningPlans.loading.signingPlanList);
  const { native } = useSelector((state) => state.User.userData);
  const isLoading = signingPlansLoading !== 'fulfilled';
  const screen = useWindowSize();
  const history = useHistory();
  const isMobileOrTablet = screen.width < 1006;
  const lastTriggeredEvent = useSelector((state) => state.User.lastTriggeredEvent);
  const userHasBasicPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);
  const userData = useSelector((state) => state.User.userData);
  const signingPlanCorporate = useSelector((state) => state.SigningPlans?.signingPlanCorporate);
  const signingPlanCorporateLoading = useSelector((state) => state.SigningPlans?.loading?.signingPlanCorporateRequest);
  const isLoadingSigningPlanCorporateRequest = signingPlanCorporateLoading === 'loading';

  useEffect(() => {
    const openPlanAfterLogin = async () => {
      if (userHasBasicPlan) {
        const { selectedPlan, modalToOpenAfterLogin } = { ...lastTriggeredEvent.payload };

        if (selectedPlan) {
          await dispatch.SigningPlans.setSelectedPlan(selectedPlan);
        }

        if (modalToOpenAfterLogin) {
          dispatch.SigningPlans.setModal(modalToOpenAfterLogin);
        }
      }

      dispatch.User.clearEventTrigger();
    };

    openPlanAfterLogin();
  }, []);

  useEffect(() => dispatch.SigningPlans.signingPlanList(), []);

  useEffect(() => {
    if (allSigningPlans?.length) {
      const plans = retrieveSigningPlans(allSigningPlans);
      setSigningPlans(plans);
    }
  }, [allSigningPlans]);

  useEffect(() => {
    const paymentStatus = window.location.href.split('?')[1];

    if (paymentStatus?.includes('success')) {
      if (!isNative) {
        window.location.href = `${process.env.REACT_APP_DYNAMIC_LINK}/pricing?success`;
      }
      dispatch.SigningPlans.setModal({ signingPayment: { open: true, data: { title: 'Pagamento feito com sucesso', subtitle: 'Já pode usar todos os benefícios da sua assinatura', status: 'success' } } });
    }

    if (paymentStatus?.includes('cancelled')) {
      if (!isNative) {
        window.location.href = `${process.env.REACT_APP_DYNAMIC_LINK}/pricing?cancelled`;
      }
      dispatch.SigningPlans.setModal({ signingPayment: { open: true, data: { title: 'Infelizmente seu pagamento não foi efetuado', subtitle: 'Por enquanto não poderá utilizar os serviços guiavet', status: 'error' } } });
    }
  }, []);

  useEffect(() => {
    const checkPaymentStatus = (event) => {
      const isSameOrigin = event?.origin === process.env.REACT_APP_WEB;

      if (isSameOrigin && event.data?.workflow) {
        const { workflow } = event.data;

        localStorage.setItem('workflow', workflow);
      }

      if (isSameOrigin && event.data?.paymentStatus) {
        document.removeEventListener('message', checkPaymentStatus);
        window.removeEventListener('message', checkPaymentStatus);

        const { paymentStatus } = event.data;

        if (paymentStatus === 'success') {
          dispatch.SigningPlans.setModal({ signingTime: { open: false, data: {} } });
          dispatch.SigningPlans.setModal({ signingPayment: { open: true, data: { title: 'Pagamento feito com sucesso', subtitle: 'Já pode usar todos os benefícios da sua assinatura', status: 'success' } } });
        }

        if (paymentStatus === 'cancelled') {
          dispatch.SigningPlans.setModal({ signingTime: { open: false, data: {} } });
          dispatch.SigningPlans.setModal({ signingPayment: { open: true, data: { title: 'Infelizmente seu pagamento não foi efetuado', subtitle: 'Por enquanto não poderá utilizar os serviços guiavet', status: 'error' } } });
        }
      }
    };

    document.addEventListener('message', (event) => {
      checkPaymentStatus(event);
    });
    window.addEventListener('message', (event) => {
      checkPaymentStatus(event);
    });

    return () => {
      document.removeEventListener('message', checkPaymentStatus);
      window.removeEventListener('message', checkPaymentStatus);
    };
  }, []);

  const requestCorporatePlan = async () => {
    const { email, phone, firstName, address, cpfCnpj } = userData;

    if (userData) {
      const isAddressFilled = address && address?.code && (address?.number !== null || address?.number !== undefined) && address?.city && address?.street && address?.district && address?.state;
      const isProfileFilled = firstName && email && phone && isAddressFilled && cpfCnpj;

      if (!isProfileFilled) {
        dispatch.Modal.setProfileDialog({ open: true, from: 'corporate_plan', desc: 'Para solicitar a ativação precisamos de algumas informações do seu perfil' });
        return null;
      }
    }

    const response = await dispatch.SigningPlans.signingPlanCorporateRequest();
    if (response && response.data) {
      dispatch.Modal.setCorporatePlanDialog({ open: true });
    }
    return true;
  };

  return (
    <>
      <PricingSigningTimeDialog />
      <PricingSigningPaymentDialog />
      <PricingSigningConfirmation />
      <CompleteProfileDialog />
      <CorporateSolicitationPlanDialog />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        {isMobileOrTablet && (
        <Grid
          container
          direction='column'
          alignItems='flex-start'
          sx={{ paddingLeft: '5px' }}
        >
          <Button
            size='large'
            sx={{ color: '#6B48FF' }}
            startIcon={<ArrowBack />}
            onClick={() => {
              window.scrollTo(0, 0);
              history.goBack();
            }}
          />
        </Grid>
        )}

        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px', display: !isEmpty(signingPlanCorporate) && userData?.signingPlanFromSource === true ? 'none' : 'block' }}
        >
          <Typography variant='h6'>Assinaturas</Typography>
        </Grid>

        {isLoading && !isEmpty(signingPlanCorporate) && userData?.signingPlanFromSource === true ? (
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ minHeight: '70vh' }}
          >
            <Grid item>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction='column'
            sx={{ padding: '0 17px', maxWidth: '1280px', pt: '40%', alignItems: 'center', display: !isEmpty(signingPlanCorporate) && userData?.signingPlanFromSource === true ? 'flex' : 'none' }}
            gap={2}
          >
            {
              signingPlanCorporate?.signingPlanSubscription?.status !== 'active' ? (
                <>
                  <Typography variant='h6' sx={{ textAlign: 'center' }}>Solicite a ativação da sua assinatura</Typography>
                  <PlanTypeControl>
                    <img src={PlanAward} alt='planAward' />
                    <PlanType>
                      {signingPlanCorporate?.name}
                    </PlanType>
                  </PlanTypeControl>

                  <Typography variant='p' sx={{ textAlign: 'center', mb: '10%' }}>Não se preocupe, vamos te notificar quando a solicitação for respondida.</Typography>

                  <Grid
                    container
                    direction='column'
                    sx={{ maxWidth: '80%' }}
                  >
                    <>
                      {
                        signingPlanCorporate?.signingPlanSubscription?.status === 'active' ? null : (
                          <>
                            {
                              signingPlanCorporate?.signingPlanSubscription?.status === 'pending' ? (
                                <PlanCorporateStatusLabel>
                                  ATIVAÇÃO EM ANDAMENTO
                                </PlanCorporateStatusLabel>
                              ) : (
                                <>
                                  {
                                    isLoadingSigningPlanCorporateRequest ? (
                                      <Grid container sx={{ maxWidth: '1280px', alignItems: 'center', justifyContent: 'center' }}>
                                        <Loading />
                                      </Grid>
                                    ) : (
                                      <PrimaryButton onClick={requestCorporatePlan} variant='outlined'>
                                        Ativar assinatura
                                      </PrimaryButton>
                                    )
                                  }
                                </>
                              )
                            }
                          </>
                        )
                      }

                    </>
                  </Grid>
                </>
              ) : (
                <>
                  <PlanTypeControl>
                    <img src={PlanAward} alt='planAward' />
                    <PlanType>
                      {signingPlanCorporate?.name}
                    </PlanType>
                  </PlanTypeControl>
                  <Typography variant='p' sx={{ textAlign: 'center', mb: '10%' }}>Você já possui uma assinatura Guiavet</Typography>
                </>
              )
            }

          </Grid>
        )}

        <Grid
          container
          direction='column'
          sx={{ padding: '0 17px', maxWidth: '1280px', display: !isEmpty(signingPlanCorporate) && userData?.signingPlanFromSource === true ? 'none' : 'block' }}
          gap={2}
        >
          {isLoading ? (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ minHeight: '70vh' }}
            >
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                direction='column'
                sx={{ margin: '16px 0 16px 0' }}
              >
                <Grid container justifyContent='center' sx={{ minHeight: '215px', height: 'auto', width: '100%' }}>
                  {isMobileOrTablet && (
                  <Grid container justifyContent='space-between' flexDirection='row' sx={{ marginBottom: '10px' }}>
                    <Grid item>
                      <Grid container sx={{ marginLeft: '10px' }}>
                        <NavigationStage>
                          <small>
                            {String(step + 1).padStart(2, '0')}
                            {' '}
                            / &nbsp;
                          </small>
                        </NavigationStage>
                        <NavigationStage>{String(signingPlans.length || '0').padStart(2, '0')}</NavigationStage>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container>
                        <ArrowBack
                          onClick={() => sliderRef.current.slickPrev()}
                          sx={{ color: step === 0 ? '#C4C4C4' : '#6B48FF', marginRight: '31px', cursor: 'pointer' }}
                        />
                        <ArrowForward
                          onClick={() => sliderRef.current.slickNext()}
                          sx={{ color: step + 1 === signingPlans.length ? '#C4C4C4' : '#6B48FF', cursor: 'pointer' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  )}

                  {isMobileOrTablet ? (
                    <SliderGrid>
                      <StyledSlider
                        ref={sliderRef}
                        slidesToScroll={1}
                        slidesToShow={1}
                        infinite={false}
                        centerMode
                        centerPadding='40px'
                        dots
                        swipe
                        afterChange={(index) => setStep(index)}
                      >
                        {signingPlans && signingPlans.map(({ ...props }, index) => (
                          <PricingCard
                            {...props}
                            selected={index === step}
                            key={props.id}
                            // eslint-disable-next-line no-mixed-operators
                            width={(screen.width > 450 && screen.width < 1006) && '85%' || '175px'}
                            center={screen.width > 450 && screen.width < 1006}
                            index={index}
                            setStep={setStep}
                          />
                        ))}
                      </StyledSlider>
                    </SliderGrid>
                  ) : (
                    <Grid
                      container
                      flexDirection='row'
                      sx={{ width: '100%' }}
                      justifyContent='space-between'
                      gap={2}
                    >
                      {signingPlans && signingPlans.map(({ ...props }, index) => (
                        <Grid
                          item
                          onClick={() => setStep(index)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <PricingCard
                            {...props}
                            selected={index === step}
                            key={props.id}
                            index={index}
                            setStep={setStep}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}

                </Grid>

                <Grid container sx={{ marginTop: '50px' }}>
                  <PricingAccordions features={signingPlans[step]?.features} />
                </Grid>

                <Grid container sx={{ marginTop: '27px' }}>
                  <Typography variant='body2' sx={{ fontSize: '14px', color: '#757575', whiteSpace: 'pre-wrap', cursor: 'default' }}>
                    {signingPlans[step]?.terms}
                  </Typography>
                </Grid>

                <Grid container sx={{ marginTop: '27px' }}>
                  <Typography variant='body2' sx={{ fontSize: '14px', color: '#757575' }}>
                    Ao assinar você concorda com nossos
                    <TermsLink href='https://www.guia.vet/politica-de-privacidade' target={native ? '_self' : '_blank'}> termos de serviço e política de privacidade</TermsLink>
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PricingDashboard;
