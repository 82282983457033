import { Typography } from '@mui/material';
import styled from 'styled-components';

export const PartnerPhoto = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

export const PartnerTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 166%;
    color: rgba(0, 0, 0, 0.38);
  }
`;

export const EventTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #6B48FE;
  }
 `;

export const EventAsset = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;

    bold {
      font-weight: bold;
    }
  }
 `;
