import React from 'react';
import { Grid } from '@mui/material';
import { StyledCard, CardTitle, CardMonetary, CardValue } from './style';
import Loading from '../../../global/Loading';

const StatementDarkCard = ({ title, value, loading }) => (
  <StyledCard elevation={0} sx={{ cursor: 'default' }}>
    <Grid container flexDirection='column'>
      <Grid container flexDirection='row' justifyContent='space-between'>
        <Grid item>
          <CardTitle>{title}</CardTitle>
        </Grid>
      </Grid>

      <Grid container justifyContent='flex-start' columnGap={1} sx={{ marginTop: '12px' }}>
        <Grid item>
          <CardMonetary variant='h4'>R$</CardMonetary>
        </Grid>

        {loading ? (
          <Grid item>
            <Loading />
          </Grid>
        ) : (
          <Grid item>
            <CardValue variant='h4'>{value}</CardValue>
          </Grid>
        )}
      </Grid>
    </Grid>
  </StyledCard>
);

export default StatementDarkCard;
