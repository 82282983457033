import styled from 'styled-components';
import { Typography, Button, Chip, Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as MoneyIcon } from '../../../assets/images/Statements/StatementCard/moneyIcon.svg';

export const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    border: 1px solid rgba(224, 224, 224, 0);
  }

  & .MuiDataGrid-iconSeparator {
    opacity: 0;
  }

  & .MuiDataGrid-cell {
    cursor: pointer;
  }

  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
`;

export const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    max-width: 1280px;
  }
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: transparent;
    cursor: pointer;
  }
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ $type }) => {
    switch ($type) {
      case 'credit':
        return '#3EB7B2';

      case 'info':
        return '#D8D8D8';

      case 'debit':
        return '#E36360';

      default:
        return '#000000DE';
    }
  }}
  }
`;

export const RedeemButton = styled(Button)`
  &.MuiButton-root {
    background: #6B48FF;
    color: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
  }

  &.MuiButton-root:hover {
    background: #6B48FF;
    color: white;
  }
`;

export const StyledMoneyIcon = styled(MoneyIcon)`
  border: 1px solid #E0E0E0;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
`;
