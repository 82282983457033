/* eslint-disable consistent-return */
import { Button, Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const StatusTypography = styled(Typography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    font-variant: small-caps;
    color: ${({ $status }) => {
    switch ($status) {
      case 'pending payment':
        return '#c9723c';
      case 'active':
        return '#3EB7B2';
      case 'redeemed':
        return '#3EB7B2';
      case 'cancelled':
        return '#FA0808';
      case 'expired':
        return '#7E8192';
      default:
        return '#3EB7B2';
    }
  }}
  }


`;

export const StyledCard = styled(Card)`
  &.MuiCard-root {
    min-height: 164px;
  }
`;

export const VoucherPhoto = styled.img`
  width: 115px;
  height: 152px;
  object-fit: cover;
`;

export const VoucherTitle = styled(Typography)`
  &.MuiTypography-root {
    color: #6B48FF;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
  }
`;

export const VoucherDate = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 10px;
    line-height: 9px;
    color: #5765AC;
    opacity: 0.4;
  }
`;

export const Price = styled(Typography)`
  &.MuiTypography-root {
    color: #000000;
    font-weight: 600;
    font-size: 26px;
    width: 180px;
  }
`;

export const RedeemButton = styled(Button)`
  &.MuiButton-root {
    width: 120px;
    height: 34px;
    border: 1px solid #6B48FF;
    color: #6B48FF;
    background: white;
    border-radius: 20px
  }

  &.MuiButton-root:disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
  }

  &.MuiButton-text {
    font-size: 16px;
  }

  &.MuiButton-root:hover {
    background: #6B48FF;
    color: white;
  }
`;

export const Address = styled(Typography)`
  &.MuiTypography-root {
    display: inline;
    color: #000000;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    width: 100%;
  }
`;

export const Company = styled(Address)`
  &.MuiTypography-root {
    font-weight: 700;
  }
`;
