/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { Fade } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { StyledGrid } from './style';
import Loading from '../../components/global/Loading';
import { HOME_URL, isNative } from '../../utils/mobile';
import LoginForm from '../../components/Login/LoginForm';
import CodeAccess from '../../components/Login/CodeAccess';
import Footer from '../../components/Login/LoginForm/Footer';
import { getParsedQueryString } from '../../utils/querystring';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { isAuthenticated, getDeviceData } from '../../services/api';
import MobileAppDialog from '../../components/Login/LoginForm/MobileAppDialog';
import LandingView from '../../components/LandingView';

const LoginPage = () => {
  const urlHasAccessToken = location.hash.includes('accessToken');
  const dispatch = useDispatch();
  const history = useHistory();
  const { validateEmailToken } = useSelector((state) => state.User.loading);
  const { loginStep } = useSelector((state) => state.User.userLoginData);
  const { redirectFromLoginURL } = useSelector((state) => state.User);
  const [isInviteOrigin, setInviteOrigin] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  const checkDevice = () => {
    const { userAgent } = navigator;

    dispatch.User.setDevice(userAgent);
  };

  const getQueryString = () => {
    const querystring = getParsedQueryString(location.search);

    setInviteOrigin(querystring.useraccess);
    if (querystring.useraccess) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ sendInviteOrigin: { useraccess: querystring.useraccess } }));
    }

    if (querystring.openbot) {
      dispatch.User.setBotToOpen(querystring.openbot);
    }

    return querystring;
  };

  const getUserInfoAndRedirect = async () => {
    const getUserData = await dispatch.User.getUserProfileData();

    if (getUserData && getUserData.data) {
      const { eventTrigger, countAnimals, id } = getUserData.data;

      let dateJoined = new Date(getUserData.data.dateJoined);
      dateJoined = [dateJoined.getDate(), dateJoined.getMonth() + 1, dateJoined.getFullYear()].join('/');
      const actualDate = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('/');
      const isFirstLogin = (dateJoined === actualDate);

      Mixpanel.track(MIXPANEL_TAGS.SIGNIN, { distinct_id: id, isFirstLogin });

      if (eventTrigger && eventTrigger.type === 'redirect') {
        if (countAnimals) {
          history.push(`/services/create/${eventTrigger.payload.serviceId}`);
          window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
          return true;
        }

        history.push(`/pet/create${history.location.search}`);
        window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
        return true;
      }

      // if (!isProfileFilled) {
      //   history.push(botToOpen ? `${HOME_URL}${history.location.search}` : `/profile${history.location.search}`);
      //   window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
      //   return true;
      // }

      if (redirectFromLoginURL) {
        history.push(redirectFromLoginURL);
        window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
        return true;
      }

      history.push(`${HOME_URL}${history.location.search}`);
      window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
      return true;
    }

    return null;
  };

  useEffect(() => {
    const retrieveLoginAccessToken = async (event) => {
      if (event?.origin === process.env.REACT_APP_WEB && event.data?.accessToken && event.data?.loginEmailOrPhone) {
        const validateToken = await dispatch.User.validateToken({
          userAccess: event.data?.accessToken,
          token: event.data?.token,
        });

        if (validateToken && validateToken.data && validateToken.data.access) {
          await getUserInfoAndRedirect();
        }

        if (validateToken && validateToken.response && validateToken.response?.status === 401) {
          window.ReactNativeWebView?.postMessage('ERROR_LOGIN_B2B');
          return;
        }

        if (validateToken && validateToken.response && validateToken.response.data && validateToken.response.data.message) {
          window.ReactNativeWebView?.postMessage('LOGIN_INVALID_TOKEN');
        }
      }
    };

    document.addEventListener('message', (event) => {
      retrieveLoginAccessToken(event);
    });
    window.addEventListener('message', (event) => {
      retrieveLoginAccessToken(event);
    });

    return () => {
      document.removeEventListener('message', retrieveLoginAccessToken);
      window.removeEventListener('message', retrieveLoginAccessToken);
    };
  }, []);

  useEffect(() => checkDevice(), []);
  useEffect(() => {
    const { slug } = params;

    if (slug && typeof slug === 'string') {
      localStorage.setItem('invitedBy', slug);
      window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'invitedBy', value: slug, from: 'web' } }));
    }

    if (slug && typeof slug === 'string' && history?.location?.pathname?.includes('referred-by')) {
      localStorage.setItem('referredBy', slug);
      window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'referredBy', value: slug, from: 'web' } }));
    }

    if (history?.location?.pathname?.includes('invite-link')) {
      Mixpanel.track(MIXPANEL_TAGS.INVITE_LOGIN_CLICK, { origin: 'link' });
    }
    if (history?.location?.pathname?.includes('invite-qrcode')) {
      Mixpanel.track(MIXPANEL_TAGS.INVITE_LOGIN_CLICK, { origin: 'qrcode' });
    }

    const lastPathname = history.location?.state?.from;

    if (lastPathname !== location.pathname) {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'redirectUrl', value: lastPathname, from: 'web' } }));
      }
      localStorage.setItem('redirectUrl', lastPathname);
      dispatch.User.setRedirectFromLoginURL(lastPathname);
    }

    if (isAuthenticated()) {
      history.push(`${HOME_URL}${history.location.search}`);
      window.ReactNativeWebView?.postMessage('USER_AUTHENTICATED');
      setIsLoading(false);
    } else {
      window.ReactNativeWebView?.postMessage('USER_UNAUTHENTICATED');
      if (isNative) setTimeout(() => setIsLoading(false), 1000);
      else setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const device = JSON.parse(getDeviceData());

    const payload = {
      action: 'pageview',
      value: 'login',
      extras: device || {},
    };

    getQueryString();
    dispatch.User.actionTrack(payload);
  }, []);

  if ((urlHasAccessToken && validateEmailToken === 'loading') || isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  return <LandingView />;

  // return (
  //   <StyledGrid
  //     container
  //     direction='row'
  //     justifyContent='center'
  //     alignItems='flex-end'
  //   >
  //     <MobileAppDialog />
  //     <Fade in>
  //       <Grid item xs={11} md={8} lg={8}>
  //         {loginStep === 'sendAuthData' && !isInviteOrigin
  //           ? <LoginForm />
  //           : <CodeAccess />}
  //       </Grid>
  //     </Fade>
  //     <Grid item xs={12} md={8} lg={8}>
  //       <Footer />
  //     </Grid>
  //   </StyledGrid>
  // );
};

export default LoginPage;
