import styled from 'styled-components';
import { Grid } from '@mui/material';
import { animations } from '../../utils/theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

export const ProfileGrid = styled(Grid)`
  animation: ${animations.fadeIn} 0.2s ease-in;
  overflow-x: hidden;

	@media screen and (min-width: 960px) {
    width: 1280px;
    padding: 0 8px;
  }
`;

export const StyledGrid = styled(Grid)`
  animation: ${animations.fadeIn} 0.4s ease-in;
`;
