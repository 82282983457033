/* eslint-disable no-sparse-arrays */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid } from '@mui/material';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import DueProtocolsTable from '../../components/Business/DueProtocolsTable';
import UnfilledProtocolsTable from '../../components/Business/UnfilledProtocolsTable';
import { MainWrapper, ReportControl, IndicatorLine, TableMenu, TableItem } from './style';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Business = () => {
  const dispatch = useDispatch();
  const [report, setReport] = useState('protocolApplyToExpire');

  const handleReport = (reportMenu) => {
    setReport(reportMenu);
  };

  useEffect(() => {
    dispatch.Business.clearReportData();
    dispatch.Business.setReportDataContext('');
  }, [, report]);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.BUSINESS_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <Container disableGutters style={{ marginBottom: 120, maxWidth: '1280px' }}>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={1}
        >
          <ReportControl>
            <TableMenu>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TableItem selected={report === 'protocolApplyToExpire'} onClick={() => handleReport('protocolApplyToExpire')}>Protocolos a vencer</TableItem>
                <TableItem selected={report === 'unfilledProtocols'} onClick={() => handleReport('unfilledProtocols')}>Protocolos não preenchidos</TableItem>
              </div>

              <IndicatorLine report={report} />
            </TableMenu>

          </ReportControl>

          {report === 'protocolApplyToExpire' ? <DueProtocolsTable /> : <UnfilledProtocolsTable />}
        </Grid>
      </Container>
      <Footer />
    </MainWrapper>
  );
};

export default Business;
