import React, { useEffect } from 'react';

import { Fade, Grid } from '@mui/material';

import { StyledGrid } from './style';
import { HOME_URL, STORE_ANDROID } from '../../utils/mobile';
import { isAuthenticated } from '../../services/api';
import Loading from '../../components/global/Loading';
import Footer from '../../components/Login/LoginForm/Footer';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const CheckRedirectPage = () => {
  const isUserLogged = isAuthenticated();

  useEffect(async () => {
    Mixpanel.track(MIXPANEL_TAGS.CHECK_REDIRECT_VIEW);

    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
      window.location.href = STORE_ANDROID;
    } else if (isUserLogged) {
      window.location.href = HOME_URL;
    } else {
      window.location.href = '/login';
    }
  }, []);

  return (
    <StyledGrid container direction='row' justifyContent='center' alignItems='flex-end'>
      <Fade in>
        <Grid item xs={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Fade>
      <Grid item xs={12} md={8} lg={8}>
        <Footer />
      </Grid>
    </StyledGrid>
  );
};

export default CheckRedirectPage;
