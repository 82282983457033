import { ListItem, Typography } from '@mui/material';
import { CalendarPicker } from '@mui/lab';
import styled from 'styled-components';

export const PartnerPhoto = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 50%;
`;

export const PartnerTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 166%;
    color: rgba(0, 0, 0, 0.38);
  }
`;

export const EventTitle = styled(Typography)`
  &.MuiTypography-root {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.87);
  }
 `;

export const Calendar = styled(CalendarPicker)`
  &.MuiCalendarPicker-root {

    @media screen and (max-width: 415px) {
      width: 275px;
    }
  }

  & .MuiPickersDay-root.MuiPickersDay-today {
    border: 1px solid #6B48FE;
    background-color: transparent;
    color: black;
  }

  & .MuiButtonBase-root.MuiPickersDay-root:focus {
    color: white;
    background: #6B48FE;
  }
 `;

export const AvailablePeriod = styled(ListItem)`
  &.MuiListItem-root {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    height: 48px;
    width: 100%;
  }
`;

export const UnavaiablePeriods = styled(Typography)`
  &.MuiTypography-root {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    width: 223px;
    color: #CCC3FE;

    bold {
      font-weight: bold;
    }
  }
`;
