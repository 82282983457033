import moment from 'moment';

export const formatPhone = (phone) => {
  const formattedPhone = String(phone).replace(/[^\d]/g, '');

  if (formattedPhone.length) {
    return formattedPhone.replace(
      /(\d{2})(\d{1})(\d{4})(\d{4})/,
      '($1) $2 $3-$4',
    );
  }

  return null;
};

export const formatCPF = (cpf) => {
  const formattedCPF = String(cpf).replace(/[^\d]/g, '');

  if (formattedCPF.length) {
    return formattedCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return null;
};

export const formatBRL = (value) => value
  && value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

export const formatAge = (dateString) => {
  const today = moment();
  const birthDate = moment(dateString);
  const diffInYears = moment.duration(today.diff(birthDate)).asYears();

  return diffInYears.toFixed(1);
};

export const formatTextLimit = (text, limit) => text && (text.length < limit ? text : `${text.slice(0, limit)}...`);

export const formatAddress = (fieldsAddress) => formatTextLimit(
  `${fieldsAddress?.street || ''} ${fieldsAddress?.number || ''} - ${
    fieldsAddress?.district ? `${fieldsAddress?.district}, ` : ''
  }${fieldsAddress?.city ? `${fieldsAddress?.city}, ` : ''}${
    fieldsAddress?.state || ''
  }`,
  70,
);

export const formatOnlyNumbers = (number) => String(number).replace(/\D+/g, '');
export const limitCharacters = (_text = '', _limit = 15) => _text.slice(0, _limit) + (_text.length > _limit ? '...' : '');

export const removeAccents = (value) => value
  .toLowerCase()
  ?.trim()
  ?.normalize('NFD')
  ?.replaceAll(/[\u0300-\u036f]/g, '')
  ?.replaceAll(' ', '-')
  ?.replace('/', '-');
