import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InviteContent from './InviteContent';
import ReconnectContent from './ReconnectContent';
import DisconnectContent from './DisconnectContent';
import CancelInviteContent from './CancelInviteContent';
import ReinviteContent from './ReinviteContent';
import AlertContent from './AlertContent';

const NetworkModal = () => {
  const dispatch = useDispatch();
  const { networkModal } = useSelector((state) => state.Network.modal) || {};

  const closeModal = () => dispatch.Network.setModal({ networkModal: { open: false, context: '' } });

  const renderModalContent = () => {
    switch (networkModal.context) {
      case 'alert':
        return <AlertContent />;
      case 'cancel':
        return <CancelInviteContent />;
      case 'invite':
        return <InviteContent />;
      case 'reinvite':
        return <ReinviteContent />;
      case 'reconnect':
        return <ReconnectContent />;
      case 'disconnect':
        return <DisconnectContent />;
      default:
        return <div />;
    }
  };

  return (
    <Modal
      open={networkModal.open}
      onClose={closeModal}
      onBackdropClick='false'
    >
      {renderModalContent()}
    </Modal>
  );
};

export default NetworkModal;
