import Compress from 'compress.js';

export const validateImage = (image) => {
  const FILE_SIZE = 20000000; // 20MB
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];

  if (image === undefined || image === {}) {
    return true;
  }

  if (image && SUPPORTED_FORMATS.includes(image.type) && image.size <= FILE_SIZE) {
    return true;
  }

  return false;
};

export const convertBase64 = (file) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);

  fileReader.onload = () => {
    resolve(fileReader.result);
  };

  fileReader.onerror = (error) => {
    reject(error);
  };
});

export const resizeImage = async (file) => {
  const compress = new Compress();

  const resizedImage = await compress.compress([file], {
    size: 1,
    quality: 1,
  });

  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);

  return resizedFile;
};
