/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { ModalBox, ButtonControl, ButtonModal, DisconnectTextControl, CustomLink } from './style';
import Loading from '../../../global/Loading';

const AcceptTermsContent = () => {
  const dispatch = useDispatch();
  const { native } = useSelector((state) => state.User.userData);

  const [submitedSuccess, setSubmitedSuccess] = useState(true);

  const closeModal = () => {
    window.ReactNativeWebView?.postMessage('HIDE_MODAL_ACCEPT_TERMS');
    dispatch.User.setModal({ termsModal: { open: false, context: '' } });
  };

  const logoutUser = () => dispatch.User.logout();

  const handleTerms = async (accept) => {
    setSubmitedSuccess(false);

    if (accept) {
      const response = await dispatch.User.acceptServiceTerms();

      if (response && response.data) {
        setTimeout(() => closeModal(), 1000);
      } else {
        setSubmitedSuccess(false);
      }
    } else {
      const response = await dispatch.User.refuseServiceTerms();

      if (response && response.data) {
        setTimeout(() => logoutUser(), 1000);
      } else {
        setSubmitedSuccess(false);
      }
    }
  };

  const openTermsLink = (link) => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: link } }));
  };

  return (
    <>
      <ModalBox>
        <Typography id='desconectar-contato' variant='h6' component='h2'>
          Política de privacidade e termos de serviço
        </Typography>

        <DisconnectTextControl>
          <Typography id='enviar-convite' variant='body1'>
            Você concorda com a nossa
            <CustomLink onClick={() => openTermsLink('https://www.guia.vet/politica-de-privacidade')} target={native ? '_self' : '_blank'}> política de privacidade e termos de serviço</CustomLink>
            ?
          </Typography>
        </DisconnectTextControl>
        <ButtonControl>
          {!submitedSuccess ? (
            <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '45px' }}>
              <Loading size={25} />
            </Grid>
          ) : (
            <>
              <ButtonModal style={{ marginRight: '42px' }} type='button' onClick={() => handleTerms(false)}>Não concordo</ButtonModal>
              <ButtonModal style={{ marginRight: '25px' }} type='submit' mainButton onClick={() => handleTerms(true)}>Concordo</ButtonModal>
            </>
          )}
        </ButtonControl>
      </ModalBox>
    </>
  );
};

export default AcceptTermsContent;
