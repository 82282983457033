import { Container } from '@mui/material';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const StyledContainer = styled(Container)`
  margin-top: 20px;

  @media screen and (min-width: 906px) {
    min-width: 1280px;
    padding: 0 24px;
  }
`;
