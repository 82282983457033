import { Button, Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const Pricing = styled(Card)`
  &.MuiCard-root {
    position: relative;
    display: flex;
    align-items: ${(props) => props.center && 'center'};
    flex-direction: column;
    width: 190px;
    min-height: 380px;
    background: ${({ $bestPlan, $selected }) => ($bestPlan && '#6B48FF') || ($selected && !$bestPlan && '#FFFFFF') || '#EAECEB'};
    border: ${({ $selected }) => ($selected ? '3px solid #FFC960' : '3px solid #A792FF')};
    border-radius: 12px;
    padding: 40px 24px;
    transition: all 0.2s ease-in-out;
  }
`;

export const PricingBanner = styled.img`
  position: absolute;
  width: 35px;
  height: 50px;
  top: 0;
  right: 10px;
`;

export const PricingTrophy = styled.img`
  position: absolute;
  top: 7px;
  right: 15px;
`;

export const PricingTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    font-weight: 700;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    line-height: 25px;
  }
`;

export const PricingDescription = styled(Typography)`
  &.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
  }
`;

export const PricingPrice = styled(Typography)`
  &.MuiTypography-root {
    font-size: 55px;
    font-weight: 600;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    line-height: 1.0;

    small {
      font-size: 20px;
    }
  }
`;

export const PricingCategory = styled(Typography)`
    &.MuiTypography-root {
    font-size: 18px;
    font-weight: 700;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    margin-top: 12px;
  }
`;

export const PricingSecondary = styled(PricingCategory)`
  &.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
  }
`;

export const PricingTerms = styled(Typography)`
    &.MuiTypography-root {
    font-size: 10px;
    font-weight: 400;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    margin-top: 16px;
    white-space: pre-wrap;
  }
`;

export const PricingDefault = styled(Typography)`
    &.MuiTypography-root {
    font-size: 40px;
    font-weight: 400;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    margin-top: 18px;
  }
`;

export const PricingSubscribe = styled(Button)`
    &.MuiButton-outlined {
      position: absolute;
      color: ${({ $bestPlan, $selected }) => ($bestPlan || $selected ? '#FFFFFF' : '#6B48FF')};
      border-color: ${({ $bestPlan, $selected }) => (($bestPlan && '#1EE0CC') || (($selected && '#FFFFFF')) || '#6B48FF')};
      background-color: ${({ $bestPlan, $selected }) => (($bestPlan && '#1EE0CC') || (($selected && '#6B48FF')) || 'transparent')};
      border-radius: 20px;
      min-width: 150px;
      white-space: nowrap;
      bottom: 12px;
    }

    &.MuiButton-outlined:hover {
      background-color: ${({ $bestPlan }) => ($bestPlan ? '#1EE0CC' : '#6B48FF')};
      border-color: ${({ $bestPlan }) => ($bestPlan ? '#1EE0CC' : '#6B48FF')};
    }

    &.MuiButton-text {
      position: absolute;
      color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
      min-width: 150px;
      white-space: nowrap;
      bottom: 12px;
    }
  `;

export const PricingSubscribeDefault = styled(Typography)`
    &.MuiTypography-root {
    position: absolute;
    width: 150px;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 500;
    color: ${({ $bestPlan }) => ($bestPlan ? '#FFFFFF' : '#6B48FF')};
    transition: all 0.2s ease-in-out;
    bottom: 25px;
    text-align: center;
  }
`;
