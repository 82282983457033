/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { Divider } from '@material-ui/core';
import { ChevronRight } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryButton } from '../../../utils/theme';
import { ContentCard, Title } from './style';
import { IntercomCustom } from '../../../utils/intercom';
import ProtocolProgress from '../ProtocolProgress';
import Attachment from '../../../assets/images/CardControl/attachment.svg';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const CardControl = ({
  id,
  name,
  lastDoseReceiptImage,
  lastDoseName,
  lastDoseDate,
  nextDoseDate,
  progress,
  severity,
  regularity,
  countdown,
  countdownUnity,
  hasStartedProtocol,
  hasFinishedProtocol,
  moment: protocolMoment,
  type,
  responsibleName,
  withTime = false,
  finishedProtocolDate = null,
}) => {
  const dispatch = useDispatch();
  const protocols = useSelector((state) => state.Animals.actualPet.protocols);
  const { actualPet } = useSelector((state) => state.Animals) || {};
  const editPermission = actualPet.hasOwnProperty('permissions') && actualPet.permissions.hasOwnProperty('editProtocolApply') && actualPet.permissions.editProtocolApply;
  const { lastCreatedProtocol } = useSelector((state) => state.Animals);
  const [highlight, setHighlight] = useState(false);
  const [protocolImgSrc, setProtocolImgSrc] = useState(null);
  const [buttonLabel] = useState(protocolMoment && protocolMoment === 'medicines' ? 'Adicionar protocolo' : 'Iniciar protocolo');
  const [types] = useState(['breast_exam', 'nutrition_ergonomics', 'heart_control', 'body_score']);
  const protocolData = {
    id,
    name,
    lastDoseReceiptImage,
    lastDoseName,
    lastDoseDate,
    nextDoseDate,
    progress,
    severity,
    regularity,
    countdown,
    countdownUnity,
    hasStartedProtocol,
    hasFinishedProtocol,
    moment: protocolMoment,
    type,
    responsibleName,
  };

  const fetchProtocolImage = async () => {
    if (lastDoseReceiptImage) {
      const response = await dispatch.Photo.getPhoto(lastDoseReceiptImage);
      setProtocolImgSrc(response);
    }
  };

  useEffect(() => {
    !!lastDoseReceiptImage && fetchProtocolImage();
  }, [lastDoseReceiptImage]);

  const handleHighlight = (e) => {
    e.preventDefault();
    setHighlight(!highlight);
  };

  const handleClickCard = async () => {
    if (editPermission) {
      IntercomCustom.signoutIntercom();

      const joinedProtocols = [...protocols.treatments, ...protocols.preventives, ...protocols.services, ...protocols.vaccines, ...protocols.medicines];
      const protocol = joinedProtocols.find((animal) => animal.id === id);

      if (protocol) {
        await dispatch.Modal.setLoadingDialog({ loading: true });
        await dispatch.Animals.setSelectedProtocol(protocol);
        await dispatch.Animals.getAnimalProtocolProducts({ protocolId: protocol.id, name: '', changeRedux: true });

        if (protocol.hasStartedProtocol) {
          await dispatch.Modal.setLoadingDialog({ loading: false });
          await dispatch.Modal.setResultDialog({ open: true, content: protocol.type, result: protocol.lastResult });
        } else {
          Mixpanel.track(MIXPANEL_TAGS.PROTOCOL_ANIMAL_NEW_CLICK, protocolData);
          await dispatch.Modal.setLoadingDialog({ loading: false });
          await dispatch.Modal.setDialog({ open: true, content: protocol.type, mode: 'create' });
        }
      }
    }
  };

  return (
    <>
      <ContentCard
        blink={id === lastCreatedProtocol.id}
        onClick={handleClickCard}
        onMouseOver={handleHighlight}
        onMouseOut={handleHighlight}
        highlight={highlight}
        severity={severity}
      >
        <div className='card-title'>
          <Title variant='h6' sx={{ top: '-12px' }}>
            {protocolMoment === 'medicines' && lastDoseName ? lastDoseName : name}
          </Title>
          <img
            alt='protocolImg'
            src={lastDoseReceiptImage ? protocolImgSrc : Attachment}
            style={{ visibility: ((type && !types.includes(type)) || !type) ? 'visible' : 'hidden' }}
          />
        </div>

        <div className='column'>
          {hasStartedProtocol ? (
            <div className='dose-info' style={{ marginLeft: ((type && !types.includes(type)) || !type) ? '30px' : '-37px' }}>
              <span className='last-dose'>
                {'Último: '}
                <span className='emphasis'>
                  {protocolMoment === 'service'
                    ? `${lastDoseDate && moment(lastDoseDate).format(withTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY')}`
                    : `${lastDoseName ? `${lastDoseName} - ` : ''} ${lastDoseDate && moment(lastDoseDate).format(withTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY')}`}
                </span>
              </span>
              {nextDoseDate && !hasFinishedProtocol ? (
                <span className='next-dose'>
                  {'Próximo: '}
                  <span className='emphasis'>
                    {`${moment(nextDoseDate).format(withTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY')}`}
                  </span>
                </span>
              ) : null}

              {hasFinishedProtocol && finishedProtocolDate ? (
                <span className='next-dose'>
                  {'Concluído: '}
                  <span className='emphasis'>
                    {`${moment(finishedProtocolDate).format(withTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY')}`}
                  </span>
                </span>
              ) : null}

              {responsibleName && (
                <span className='next-dose'>
                  {'Responsável: '}
                  <span className='emphasis'>
                    {responsibleName}
                  </span>
                </span>
              )}
            </div>
          ) : (
            <span className='last-dose-empty' style={{ marginLeft: ((type && !types.includes(type)) || !type) ? '30px' : '-37px' }}>
              Ainda não foi
              {' '}
              {(protocolMoment === 'medicines' && 'cadastrado') || 'cadastrada'}
            </span>
          )}
        </div>

        {hasStartedProtocol ? (
          <>
            {regularity !== 'once'
              && (
              <ProtocolProgress
                progress={progress}
                severity={severity}
                countdown={countdown}
                countdownUnity={countdownUnity}
                nextDoseDate={nextDoseDate}
                hasFinishedProtocol={hasFinishedProtocol}
              />
              )}
            <div className='details-btn'>
              <ChevronRight sx={{ color: '#C6CDD3' }} />
            </div>
          </>

        ) : (
          editPermission ? (
            <div className='details'>
              <SecondaryButton>
                {buttonLabel}
              </SecondaryButton>
            </div>
          ) : <></>
        )}
      </ContentCard>
      <Divider variant='middle' />
    </>
  );
};

export default CardControl;
