import { Alert, Box, Button, Chip, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 161px;
    height: 204px;
    background-color: #f2f2f2;
    border-radius: 10px;
    position: relative;
  }
`;

export const VoucherPhoto = styled.img`
  width: 115px;
  height: 152px;
  object-fit: cover;
`;

export const ProductImageContainer = styled(Box)`
  &.MuiBox-root {
    width: 115px;
    height: 152px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: cover;
    width: 115px;
    height: 152px;
    border-radius: 8px;
  }
`;

export const VoucherTitle = styled(Typography)`
  &.MuiTypography-root {
    color: #6B48FF;
    font-size: 15px;
    font-weight: 600;
    width: 180px;
  }
`;

export const OldPrice = styled(Typography)`
  &.MuiTypography-root {
    color: #000000;
    font-weight: 400;
    font-size: 13px;
    width: 124px;
    text-decoration: line-through;
  }
`;

export const Price = styled(Typography)`
  &.MuiTypography-root {
    color: #000000;
    font-weight: 600;
    font-size: 26px;
    width: 180px;
  }
`;

export const ExpireInfo = styled(Box)`
  &.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(255, 72, 116, 0.25);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px 14px;
    margin-top: 30px;

    p {
      color: #FF4874;
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
    }
  }
`;

export const InfoTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 16px;
    width: 218px;
  }
`;

export const OfferCondition = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7E8192;
    white-space: pre-wrap;
  }
`;

export const AddressInfo = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #7E8192;
    opacity: 0.7;
  }
`;

export const BuyButton = styled(Button)`
  &.MuiButton-root {
    width: 85%;
    height: 42px;
    margin: 30px 0;

    background: #6B48FF;
    border-radius: 20px
  }
`;

export const Redemption = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const RedemptionCode = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: #6B48FF;
    margin-top: 25px;
  }
`;

export const PhoneChip = styled(Chip)`
  &.MuiChip-root {
    background: #E0E0E0;
    color: rgba(0, 0, 0, 0.38);
    margin: 14px 0;
  }
`;

export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    background: #F44336;
    color: white;
    display: flex;
    align-items: center;
  }
`;
