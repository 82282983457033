import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import AppointmentTable from '../../components/Appointments/AppointmentTable';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Appointments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    Mixpanel.track(MIXPANEL_TAGS.APPOINTMENTS_VIEW);

    setLoading(true);
    const response = await dispatch.Appointments.myServiceListCount();
    const B2BDoesNotHaveServices = response && response.data && !response.data.count;

    if (B2BDoesNotHaveServices) {
      history.push('/appointments/create');
    }
    setLoading(false);
  }, []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <AppointmentTable />
      )}

      <Footer />
    </MainWrapper>
  );
};

export default Appointments;
