import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Container, Fade } from '@mui/material';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../../components/Dashboard/DashboardMenu';
import Footer from '../../../components/Footer';
import { MainWrapper } from './style';
import AppointmentScheduleCard from '../../../components/Appointments/AppointmentSchedule/AppointmentScheduleCard';
import AppointmentScheduleModal from '../../../components/Appointments/AppointmentSchedule/AppointmentScheduleModal';
import AppointmentAttendanceModal from '../../../components/Appointments/AppointmentSchedule/AppointmentAttendanceModal';
import Loading from '../../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const AppointmentSchedule = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading] = useState(false);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.APPOINTMENT_SCHEDULE_VIEW);
    dispatch.Appointments.serviceDetail(params.id);
  }, []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <AppointmentAttendanceModal />
      <AppointmentScheduleModal />

      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Container disableGutters style={{ marginBottom: 120, maxWidth: '1240px' }}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{}}
            onSubmit={{}}
            validationSchema={{}}
          >
            {() => (
              <Grid
                container
                justifyContent='center'
                spacing={1}
              >
                <Fade in>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AppointmentScheduleCard />
                  </Grid>
                </Fade>
              </Grid>
            )}
          </Formik>
        </Container>
      )}

      <Footer />
    </MainWrapper>
  );
};

export default AppointmentSchedule;
