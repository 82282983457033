import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import theme from '../../../../utils/theme';

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    transition: background-color .1s ease-in, box-shadow .1s ease-in;
    &:hover {
      background-color: #f2f0fa;
      -webkit-box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
    }
  }
`;

export const SendMessageTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    color: ${theme.fontColorPurple};
    cursor: pointer;
  }
`;

export const PetTableContent = styled.div`
  display: flex;
  min-width: 70px;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`;

export const CustomerTableContent = styled.div`
  display: flex;
  min-width: 70px;
  align-items: center;
  flex-direction: row;
`;
