import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import Grow from '@mui/material/Grow';
import InputMask from 'react-input-mask';
import DatePicker from '@mui/lab/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@material-ui/core';
import { AttachFile } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText, Autocomplete, IconButton, Grid } from '@mui/material';

import Alert from '../../../global/Alert';
import Loading from '../../../global/Loading';
import { validateSymbols } from '../../../../utils/validators';
import { furColorsUtils, furSizesUtils } from '../../../../utils/pet';
import { convertBase64, resizeImage, validateImage } from '../../../../utils/imageUtils';
import { ModalBox, HeaderModal, CloseModal, Form, ButtonControl, ButtonModal } from './style';

const CreatePetContent = ({ setModalStep }) => {
  const dispatch = useDispatch();

  const [submitedStatus, setSubmitedStatus] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [breedName, setBreedName] = useState('');
  const [breedPlaceholder, setBreedPlaceholder] = useState('Digite o nome da raça');
  const [breedOptions, setBreedOptions] = useState([]);
  const [species, setSpecies] = useState('');
  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [photoBase64, setPhotoBase64] = useState('');
  const { createUpdatePet } = useSelector((state) => state.Animals.loading);
  const [openModalColors, setOpenModalColors] = useState(false);

  const fetchBreed = async (name, newSpecie = null) => {
    setBreedOptions([]);
    const response = await dispatch.Animals.getBreedListSpecies({ species: newSpecie || species, name });

    if (response && response.data.length) {
      const parsedOptions = response.data.map((breed) => ({ label: breed.name, id: breed.id }));

      setBreedOptions([...new Set(parsedOptions)]);
    } else {
      setBreedOptions([]);
    }
  };

  useEffect(() => {
    if (species) {
      fetchBreed(breedName);
    }
  }, []);

  const handleSubmitPet = async (formData, { setErrors }) => {
    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });

      const { name, breed, sex, birthdate, furColor, furSize, furSigns } = formData;

      const breedIsCurated = breedOptions.find((item) => item.name === breed.label);

      const payload = {
        name,
        breedId: breedIsCurated ? breedIsCurated.id : breed.id,
        sex: sex.slice(0, 1),
        neutered: sex.slice(1) === 'N',
        photoBase64,
      };

      if (formData?.weight) {
        const weightValue = Number(String(formData.weight).replace(',', '.'));
        Object.assign(payload, { weight: weightValue });
      }
      if (birthdate) { Object.assign(payload, { birthdate: moment(birthdate).format('YYYY-MM-DD') }); }
      if (furSize) { Object.assign(payload, { furSize }); }
      if (furColor) { Object.assign(payload, { furColor }); }
      if (furSigns) { Object.assign(payload, { furSigns }); }

      const request = await dispatch.Animals.createUpdatePet(payload);

      if (request?.data) {
        setSubmitedStatus('success');

        setTimeout(() => setModalStep('create-protocol'), 500);
      }
    } else {
      setSubmitedStatus('error');
      setErrors({ photoName: true });
    }
  };

  const handleSpeciesChange = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('species', value);
    setFieldValue('furSize', null);
    setFieldValue('furColor', []);
    setFieldValue('breed', '');
    setSpecies(value);
    fetchBreed('', value);
  };

  const handleChangeFurSize = (event, setFieldValue) => {
    const { value } = event.target;
    setFieldValue('furSize', value);
  };

  const handleColorsChange = (event, setFieldValue) => {
    const { value } = event.target;
    const parseValue = typeof value === 'string' ? value.split(',') : value;
    setFieldValue('furColor', parseValue);
    if (parseValue?.length === 3) setOpenModalColors(false);
  };

  const handleBreedTextChange = (event, setFieldValue) => {
    const { value } = event.target;
    setBreedName(value);
    setFieldValue('breed', value);

    fetchBreed(value);
  };

  const handleBreedOptionChange = (event, setFieldValue) => {
    if (event) {
      setBreedName(event.label);
      setFieldValue('breed', event);
    }
  };

  const closeModal = () => {
    setModalStep('select-action');
    dispatch.Animals.setModal({ petAndProtocolApply: false });
  };

  const handleUpload = () => document.getElementById('imgupload').click();

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;

    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');

      const base64 = await convertBase64(rawFile);
      setPhotoBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);

      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setPhotoBase64(base64);
      }
    }
  };

  return (
    <>
      <ModalBox>
        <HeaderModal>
          <Typography style={{ marginLeft: '30px' }} id='perfil-do-pet' variant='h6' component='h2'>
            Cadastrar pet
          </Typography>
          <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        </HeaderModal>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ name: '', sex: '', species: '', breed: '', birthdate: null, furColor: [], furSize: '', furSigns: null, weight: null }}
          onSubmit={handleSubmitPet}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().required('Favor digitar o nome do pet').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
              sex: Yup.string().required('Favor selecionar o sexo do pet'),
              weight: Yup.number().nullable(true)
                .max(99.999, 'O valor máximo permitido é 99.999kg')
                .min(0.001, 'O valor mínimo permitido é 0.001kg')
                .transform((_, value) => (value !== null && value !== undefined ? Number(String(value).replace(',', '.')) : null))
                .test(
                  'is-decimal',
                  'invalid decimal',
                  (value) => !value || String(value).replace(',', '.').match(/[-+]?[0-9]*[.]?[0-9]+/),
                ),
              species: Yup.string().required('Favor selecionar a espécie do pet'),
              furSize: Yup.string().nullable(),
              furColor: Yup.array().of(Yup.string()),
              furSigns: Yup.string().nullable(),
              breed: Yup.mixed().required('Favor preencher a raça do pet'),
            })
          }
        >
          {({ handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => uploadImage(e)} style={{ display: 'none' }} />
              <TextField
                fullWidth
                value={filename}
                error={!!errors.photoName}
                touched={touched.photoName}
                onClick={handleUpload}
                helperText='Imagem de até 20MB'
                sx={{ marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                id='photoName'
                name='photoName'
                label='Imagem'
                InputProps={{
                  readOnly: true,
                  endAdornment:
  <IconButton edge='end'>
    <AttachFile />
  </IconButton>,
                }}
              />
              <TextField
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name && errors.name}
                touched={touched.name}
                sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                autoComplete='off'
                label='Nome'
                id='name'
                name='name'
                required
                inputProps={{ maxLength: 20 }}
              />

              <FormControl required error={!!errors.sex} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel id='demo-simple-select-helper-label'>Sexo</InputLabel>
                <Select
                  defaultValue=''
                  id='sex'
                  name='sex'
                  required
                  label='Sexo'
                  value={values.sex}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.sex}
                >
                  <MenuItem disabled value=''>Sexo</MenuItem>
                  <MenuItem value='MN'>Macho - Castrado</MenuItem>
                  <MenuItem value='MM'>Macho - Não castrado</MenuItem>
                  <MenuItem value='FN'>Fêmea - Castrada</MenuItem>
                  <MenuItem value='FF'>Fêmea - Não castrada</MenuItem>
                </Select>
                <FormHelperText>{errors.sex && errors.sex}</FormHelperText>
              </FormControl>

              <FormControl required error={!!errors.species} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel id='demo-simple-select-helper-label'>Espécie</InputLabel>
                <Select
                  defaultValue=''
                  id='species'
                  name='species'
                  required
                  label='Espécie'
                  value={values.species}
                  onChange={(event) => handleSpeciesChange(event, setFieldValue)}
                  onBlur={handleBlur}
                  touched={touched.species}
                >
                  <MenuItem disabled value=''>Espécie</MenuItem>
                  <MenuItem value='dog'>Cão</MenuItem>
                  <MenuItem value='cat'>Gato</MenuItem>
                </Select>
                <FormHelperText>{errors.species && errors.species}</FormHelperText>
              </FormControl>

              <Autocomplete
                blurOnSelect='touch'
                isOptionEqualToValue={(option, value) => option.label === value.label}
                disablePortal
                name='breed'
                disabled={!species}
                onFocus={() => setBreedPlaceholder('Raça')}
                onBlur={() => !values.breed && setBreedPlaceholder('Digite o nome da raça')}
                error={!!errors.breed}
                value={values.breed || ''}
                openOnFocus
                freeSolo
                onChange={(e, value) => handleBreedOptionChange(value, setFieldValue)}
                options={breedOptions}
                sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                renderInput={(params) => (
                  <TextField
                    id='breed'
                    name='breed'
                    error={!!errors.breed}
                    onChange={(event) => handleBreedTextChange(event, setFieldValue)}
                    label={breedPlaceholder}
                    required
                    {...params}
                  />
                )}
              />

              <InputMask mask='99,999' maskChar='0' onBlur={handleBlur} value={values.weight} onChange={handleChange}>
                {(inputProps) => (
                  <TextField
                    label='Peso atual (kg)'
                    placeholder='00,000'
                    type='text'
                    id='weight'
                    name='weight'
                    touched={String(touched.weight)}
                    InputLabelProps={{ shrink: true }}
                    autoComplete='new-password'
                    sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                    {...inputProps}
                    error={!!errors.weight}
                    helperText={errors.weight}
                  />
                )}
              </InputMask>

              <DatePicker
                openTo='year'
                open={openDatePicker}
                id='birthdate'
                name='birthdate'
                minDate={moment('1990/01/01')}
                maxDate={moment()}
                label='Data de nascimento'
                allowSameDateSelection
                defaultValue={null}
                value={values.birthdate}
                onChange={(value) => setFieldValue('birthdate', value)}
                onClose={() => setOpenDatePicker(false)}
                format='DD/MM/YYYY'
                views={['year', 'month', 'day']}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                    id='birthdate'
                    name='birthdate'
                    onClick={() => setOpenDatePicker(true)}
                    {...params}
                  />
                )}
              />

              <FormControl error={!!errors.furSize} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Tamanho e aspecto dos fios</InputLabel>
                <Select
                  id='furSize'
                  name='furSize'
                  value={values.furSize}
                  onChange={(event) => handleChangeFurSize(event, setFieldValue)}
                  onBlur={handleBlur}
                  input={<OutlinedInput label='Tamanho e aspecto dos fios' />}
                  MenuProps={{
                    PaperProps: { sx: { bottom: '100px !important' } },
                  }}
                >
                  {!!values?.species && furSizesUtils[values?.species.toUpperCase()]?.map((name) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.furSize && errors.furSize}</FormHelperText>
              </FormControl>

              <FormControl error={!!errors.furColor} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Cores</InputLabel>
                <Select
                  id='furColor'
                  name='furColor'
                  multiple
                  value={values.furColor}
                  open={openModalColors}
                  onOpen={() => setOpenModalColors(true)}
                  onClose={() => setOpenModalColors(false)}
                  onChange={(event) => handleColorsChange(event, setFieldValue)}
                  renderValue={(selected) => selected.join(', ')}
                  input={<OutlinedInput label='Cores' />}
                  MenuProps={{ PaperProps: { className: 'SelectPaddingBottom' } }}
                >
                  {!!values?.species && furColorsUtils[values?.species.toUpperCase()]?.sort()?.map((name) => (
                    <MenuItem key={name} value={name} disabled={!(values?.furColor?.indexOf(name) > -1) && values?.furColor?.length >= 3}>
                      <Checkbox checked={values?.furColor?.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.furColor && errors.furColor}</FormHelperText>
              </FormControl>

              <TextField
                onChange={handleChange}
                value={values.furSigns}
                error={!!errors.furSigns}
                helperText={errors.furSigns && errors.furSigns}
                touched={touched.furSigns}
                sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                autoComplete='off'
                label='Sinais particulares da pelagem'
                id='furSigns'
                name='furSigns'
              />

              <FormHelperText>
                Ex.: bolinhas brancas, coração no nariz, escaminha
              </FormHelperText>

              <ButtonControl>
                {createUpdatePet === 'loading' || isSubmitting ? (
                  <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Loading />
                  </Grid>
                ) : (
                  <>
                    <ButtonModal main onClick={() => setModalStep('select-action')}>Voltar</ButtonModal>
                    <ButtonModal mainButton type='submit'>Salvar e voltar ao controle</ButtonModal>
                  </>
                )}

              </ButtonControl>
            </Form>
          )}
        </Formik>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Pet criado com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha na criação do pet, escolha a raça pelos nomes sugeridos
              </Alert>
            </div>

          )}

        </Snackbar>
      </Grid>
    </>
  );
};

export default CreatePetContent;
