import styled from 'styled-components';
import { Avatar } from '@mui/material';
import theme, { SecondaryButton } from '../../../../utils/theme';

export const PetName = styled.span`
font-size: 20px;
font-weight: 500;
margin: 10px 0 5px 0;
`;

export const Specie = styled.span`
font-size: 14px;
font-weight: 400;
color: rgba(0,0,0,0.35);
margin-bottom: 16px;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-circular {
    width: 130px;
    height: 130px;
  }

  @media screen and (max-width: 425px) {
    &.MuiAvatar-circular {
      width: 80px;
      height: 80px;
    }
  }
`;

export const StyledChip = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  color: ${theme.fontColorLight};
  background-color: ${(props) => (props.healthy ? theme.successColor : theme.warningColor)};
  border-radius: 64px;
  font-size: 12px;
  width: 190px;
  position: relative;

  @media screen and (max-width: 736px) {
    position: relative;
    width: 90%;
  }
`;

export const PetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MobileHealthControlButton = styled(SecondaryButton)`
  display: none !important;

  &.MuiButton-text {
    font-size: 12px;
  }
`;
