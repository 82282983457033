import { Grid, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import VoucherPendingCard from './VoucherPendingCard';
import VoucherRedemptionCard from './VoucherRedemptionCard';

const VoucherCheckout = () => {
  const { purchasedVoucher } = useSelector((state) => state.Vouchers);
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();

  const fetchPurchasedDetail = async () => dispatch.Vouchers.voucherB2CDetail(params.id);

  useEffect(() => {
    window.scrollTo(0, 0);
    const interval = setInterval(() => fetchPurchasedDetail(), 3000);
    intervalRef.current = interval;

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (purchasedVoucher && purchasedVoucher.status === 'active') {
      clearInterval(intervalRef.current);
    }
  }, [purchasedVoucher]);

  const renderCheckoutCard = () => {
    switch (purchasedVoucher?.status) {
      case 'pending payment':
        return <VoucherPendingCard />;
      case 'active':
        return <VoucherRedemptionCard />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px', marginTop: '20px' }}
        >
          {purchasedVoucher && purchasedVoucher.status !== 'active' && (
            <Typography variant='h6'>Pagamento</Typography>
          )}

          <Grid container>
            <Grid item sx={{ width: '100%' }}>
              {renderCheckoutCard()}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default VoucherCheckout;
