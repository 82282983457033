import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UpdateTermsContent from './UpdateTermsContent';
import AcceptTermsContent from './AcceptTermsContent';

const TermsModal = () => {
  const dispatch = useDispatch();
  const { open, context } = useSelector((state) => state.User.modal.termsModal) || {};

  const closeModal = () => {
    window.ReactNativeWebView?.postMessage('HIDE_MODAL_ACCEPT_TERMS');
    dispatch.User.setModal({ termsModal: { open: false, context: '' } });
  };

  const renderModalContent = () => {
    switch (context) {
      case 'updateTerms':
        return <UpdateTermsContent />;
      case 'acceptTerms':
        return <AcceptTermsContent />;
      default:
        return <div />;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      onBackdropClick='false'
      BackdropProps={{ style: { backgroundColor: '#FDF9F5' } }}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default TermsModal;
