import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useCloseModals = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { transferPetModal } = useSelector((state) => state.User.modal);
  const { protocolApply, petAndProtocolApply } = useSelector((state) => state.Animals.modal);
  const { networkModal } = useSelector((state) => state.Network.modal);
  const { animalsListSearch } = useSelector((state) => state.Animals);

  const closeModals = () => {
    if (protocolApply) {
      dispatch.Animals.setModal({ protocolApply: false });
    }

    if (petAndProtocolApply) {
      dispatch.Animals.setModal({ petAndProtocolApply: false });
    }

    if (networkModal?.open && networkModal?.origin !== 'service') {
      dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
    }

    if (transferPetModal?.open) {
      dispatch.User.setModal({ transferPetModal: { open: false, context: '' } });
    }

    if (animalsListSearch?.term) {
      dispatch.Animals.setAnimalsListSearch({ isAnimal: true, term: '', option: false });
    }
  };

  useEffect(() => closeModals(), [location]);
};

export default useCloseModals;
