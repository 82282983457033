import { Card, Box, FormGroup } from '@mui/material';
import styled from 'styled-components';
import { animations } from '../../../utils/theme';

export const StyledPetFormCard = styled(Card)`
  &.MuiCard-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 20px 0;
    margin-bottom: 80px;
    width: 100%;
    animation: ${animations.fadeIn} 0.3s ease-in-out;
  }

  & .MuiTypography-h6 {
    margin: 10px 0 0 65px;

    @media screen and (max-width: 736px) {
      margin: 10px 0 0 30px;
    }
  }
`;

export const InputBox = styled(Box)`
  margin-top: 10px;
  padding: 0 35px;
  width: 88%;

  @media screen and (max-width: 736px) {
    padding: 0px;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 736px) {
    flex-direction: row;
  }
`;

export const SwitchFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  margin-left: 30px;

  @media screen and (max-width: 736px) {
    margin: 25px 0 5px 20px;
  }
`;

export const Form = styled.form`
    padding: 0px 30px 30px 30px;
`;

export const ButtonControl = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
`;

export const TransferPetControl = styled.div`
  animation: ${animations.fadeIn} 0.3s ease-in-out;
`;
