/* eslint-disable consistent-return */
import { Badge } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ReactComponent as NotificationsIcon } from '../../../assets/images/Dashboard/notification.svg';
import { animations } from '../../../utils/theme';

export const Container = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
`;

export const ringingAnimation = css`
	animation: ${animations.bellRing} 2.5s infinite;
`;

export const AnimatedNotificationsIcon = styled(NotificationsIcon)`
  ${(props) => props.ringing && ringingAnimation};
  margin-right: 5px;
`;

export const StyledBadge = styled(Badge)`
  cursor: pointer;
  margin-right: 20px;

  svg {
    fill: ${({ $active }) => ($active ? '#6B48FF' : 'black')};

    &:hover {
      fill: ${({ $active }) => ($active ? 'black' : '#6B48FF')};
    }
  }

  & .MuiBadge-badge {
    color: white;
    transition: background-color .2s ease-in-out;
    background-color: ${(props) => {
    switch (props.$warningSeverity) {
      case 'regular':
        return '#3B87D3';

      case 'warning':
        return '#FFB400';

      case 'critical':
        return '#E36360';

      default:
        break;
    }
  }}
  }
`;
