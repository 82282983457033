import styled from 'styled-components';
import { Card } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import theme from '../../../utils/theme';

export const CardWrapper = styled.div`
	line-height: 25px;
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-flow: column nowrap;
	@media screen and (max-width: 950px) {
		text-align: center;
	}

	.form-header {
		.login-logo {
			margin-bottom: 3vh;
			max-width: 190px;
			min-width: 100px;
			@media screen and (max-width: 950px) {
				margin: 3vh auto;
				display: flex;
			}
			@media screen and (max-width: 768px) {
				max-width: 120px;
			}
		}
	}

  .wrapper-image-login {
    .dog-image {
      @media screen and (max-width: 444px) {
        width: 90% !important;
      }
    }
  }

	.promo {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		margin-right: 15px;
		.dog-image {
			margin-bottom: 15px;
			max-width: 100%;
			@media screen and (max-width: 768px) {
				width: 80%;
			}

      @media screen and (max-width: 444px) {
				width: 50% !important;
			}
		}
		span {
			font-weight: 400;
			font-size: 16px;
			@media screen and (max-width: 950px) {
				padding: 0;
				margin: 0 0 15px;
			}
		}
	}

  .btnGoogle, .btnFacebook {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		border-radius: 30px !important;
		border: 0;
		outline: 0;
		padding: 10px;
    height: 48px !important;
		color: rgba(74, 80, 86, 1) !important;
		font-size: 16px !important;
		font-weight: 500 !important;
		background-color: ${theme.googleButton};
		transition: background-color .2s ease-in-out;
		text-align: center;
		vertical-align: middle;
    border: 1px solid rgba(218, 218, 218, 1) !important;
    box-shadow: none !important;

		svg {
			margin-right: 8px;
		}


		&:hover {
			background-color: rgba(244, 244, 244, 1);
		}
	}

  .btnFacebook {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .btnGoogle div {
    width: 0 !important;
    margin-right: 20px !important;
    margin-top: 5px !important;
    background-color: transparent !important;
  }

  .btnGoogle svg {
    transform: scale(1.02);
  }

  .btnFacebook.metro {
    color: rgba(74, 80, 86, 1) !important;
		font-size: 16px !important;
		font-weight: 500 !important;
    font-family: 'Roboto' !important;
  }

  .social-login {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    gap: 12px !important;
  }

  .social-login > div {
    width: 100%;
  }

	.facebook-block
	{
		display: block;
	}

  .labelLoginMobile {
    opacity: 0;
  }

  @media screen and (max-width: 1280px) {
    .labelLoginMobile {
      opacity: 1;
      width: 100% !important;
    }
    .wrapper-image-login {
      width: 100% !important;
    }
  }

  .wrapper-image-login {
    width: 49%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

	.form-login
	{
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		justify-content: center;

		@media screen and (max-width: 1280px) {
			align-items: center;

      .labelLoginWeb {
        display: none;
      }
		}

		@media screen and (min-width: 1280px) {
      align-items: left;
		}


		.title {
			font-size: 20px;
			font-weight: 500;
			margin-bottom: 25px;
		}

		.subTitle {
			color: #0000008A;
			margin: 6px 0;
			font-size: 16px;
      text-align: left;
		}

		.separator {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			opacity: 0.4;
      margin-bottom: 24px;
      color: #000 !important;

			.line {
				width: 60px;
				height: 0px;
				border: 1px solid #000000;
				opacity: .4;
				margin: 0 7px;
			}
		}



		.alert-message {
			margin-top: 20px;
		}

		.policies {
			margin-top: 20px;
			display: flex;
			flex-direction: row;

			.info {
				margin-top: 5px;
				width: 422px;
				height: 40px;
				font-size: 14px;
        text-align: left;
			}
		}

		.agreement-check{
			margin-top: -4px;
		}
		form {
			width: 100%;
		}
		.login-buttons {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			button {
				margin-top: 40px;
			}
			@media screen and (max-width: 950px) {
				min-height: 100px;
			}
			@media screen and (max-width: 768px) {
				min-height: auto;
				button {
					margin-top: 35px;
				}
			}
		}
	}
	.support {
		text-align: center;
		margin-top: 5vh;
		font-weight: 500;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
`;

export const StyledCard = styled(Card)`
	padding: 30px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	@media screen and (max-width: 950px) {
		flex-flow: column;
		padding: 35px 20px 35px;
	}
`;

export const CustomInput = styled(TextField)`
width: 100%;
`;

export const Checkbox = styled(TextField)`
width: 100%;
`;

export const ExpiredCode = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }

  @media screen and (max-width: 1280px) {
		height: auto;
	}
`;
