/* eslint-disable no-confusing-arrow */
import { Container, Grid } from '@mui/material';
import styled from 'styled-components';
import { animations } from '../../../utils/theme';

export const StyledGridItem = styled(Grid)`
  width: 100%;
  background-color: ${({ unRead }) => unRead ? 'white' : '#f2f2f2'};
`;

export const StyledGridContainer = styled(Grid)`
  &.MuiGrid-container {
    background-color: white;
    width: 70%;

    @media screen and (max-width: 736px) {
      width: 100%;
    }
  }
`;

export const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    animation: ${animations.fadeIn} .2s ease-in-out;

    @media screen and (max-width: 736px) {
      margin-top: 0;
      margin-bottom: 90px;
    }
  }

  & .MuiGrid-root.MuiGrid-item {
    padding-left: 7px;
    padding-top: 10px;
    padding-bottom: 10px;

  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
  width: 100%;
`;

export const EmptyNotification = styled.img`
  width: 400px;
  height: 400px;

  @media screen and (max-width: 736px) {
      width: 250px;
      height: 250px;
    }
`;
