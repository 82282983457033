import styled from 'styled-components';
import { PrimaryButton } from '../../../utils/theme';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;

export const AppointmentButton = styled(PrimaryButton)`
  width: 200px;
`;
