import styled from 'styled-components';

import theme, { RouterLink } from '../../../utils/theme';

export const Header = styled.div`
	background-color: ${theme.header};
	min-height: 65px;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	.logo-mobile {
		display: none;
	}

	@media screen and (max-width: 739px) {
		.logo-desktop {
			display: none;
		}

		.logo-mobile {
			display: block;
		}
	}
`;

export const MobileMenu = styled.div`
	color: ${theme.fontColorLight};
	display: none;
	@media screen and (max-width: 736px) {
		display: inline-flex;
		position: absolute;
		height: 65px;
	}
`;

export const DrawerHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 5px;
	justify-content: flex-end;
`;

export const DashboardSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

export const AvatarMenu = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const Username = styled.span`
  margin-left: 10px;
  font-weight: 500;
  color: ${theme.fontColorDark};

  @media screen and (max-width: 500px) {
		display: none;
	}
`;

export const MenuLink = styled(RouterLink)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled.div`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: normal;
  justify-content: space-between;
  width: 100%;
`;
