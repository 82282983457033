/* eslint-disable no-unused-expressions */
import { Fade, Card, Divider, Grid, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UpdateIcon from '@mui/icons-material/Update';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Price, OldPrice, VoucherTitle, ExpireInfo, InfoTitle, OfferCondition, AddressInfo, RedemptionCode, Redemption, StyledAlert, PhoneChip, ProductImageContainer } from './style';
import { formatBRL, formatPhone } from '../../../../utils/format';
import whatsappIcon from '../../../../assets/images/Network/whatsapp.png';

const VoucherRedemptionCard = () => {
  const dispatch = useDispatch();
  const { name, promotionVoucher, promotionDescription, promotionValidUntilDatetime, redeemCode, promotionPriceWithDiscount, promotionPriceFull } = useSelector((state) => state.Vouchers.purchasedVoucher);
  const parsedRedeemCode = redeemCode && `${redeemCode.slice(0, 3)} ${redeemCode.slice(3, 6)} ${redeemCode.slice(6, 9)}`;
  const whatsappMessage = `Olá ${promotionVoucher?.user?.firstName}, comprei ${name}. Quero fazer o resgate com o código ${parsedRedeemCode}`;
  const openWhatsapp = (phone) => phone && window.open(`https://wa.me/+55${phone}?text=${whatsappMessage}`);
  const history = useHistory();

  const [voucherImgSrc, setVoucherImgSrc] = useState(null);

  const fetchVoucherImage = async () => {
    const response = await dispatch.Photo.getPhoto(promotionVoucher.voucherImage);
    setVoucherImgSrc(response);
  };

  useEffect(() => {
    !!promotionVoucher?.voucherImage && fetchVoucherImage();
  }, [promotionVoucher]);

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <Button
            size='large'
            sx={{ color: '#6B48FF', '&.MuiButton-root': { padding: '8px 0' } }}
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              history.push('/orders');
              window.scrollTo(0, 0);
            }}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>

      <Fade in>
        <Card elevation={0} sx={{ marginTop: '10px', padding: '10px 24px', position: 'relative' }}>
          <Grid container wrap='nowrap' flexDirection='row' justifyContent='center' spacing={1}>
            <Grid item sx={{ marginTop: '20px' }}>
              <ProductImageContainer>
                <img src={promotionVoucher?.voucherImage && voucherImgSrc} alt='voucherPhoto' />
              </ProductImageContainer>
            </Grid>

            <Grid item>
              <Grid container flexDirection='column'>
                <Grid container flexDirection='column'>
                  <Grid item>
                    <VoucherTitle variant='h6'>{name}</VoucherTitle>
                    {promotionPriceFull !== promotionPriceWithDiscount && (
                    <OldPrice>
                      de
                      {' '}
                      {formatBRL(promotionPriceFull)}
                      {' '}
                      por
                    </OldPrice>
                    )}
                    <Price>{formatBRL(promotionPriceWithDiscount)}</Price>
                  </Grid>
                </Grid>

                <ExpireInfo>
                  <p>
                    Vale compra válido até a data limite
                  </p>

                  <Grid container sx={{ marginTop: '4px' }}>
                    <UpdateIcon sx={{ width: '12px', height: '12px', color: '#FF4874', marginRight: '4px' }} />
                    <p>
                      {moment(promotionValidUntilDatetime).format('DD MMM YYYY')}
                      {' '}
                      (
                      {moment(promotionValidUntilDatetime).fromNow()}
                      )
                    </p>
                  </Grid>

                </ExpireInfo>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />

          <Grid container flexDirection='column' alignItems='center'>
            <Grid item>
              <Redemption>Código de resgate</Redemption>
            </Grid>
            <Grid item>
              <RedemptionCode>{parsedRedeemCode || ''}</RedemptionCode>
            </Grid>
          </Grid>

          <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />

          <Grid container flexDirection='column' alignContent='center'>
            <Grid item>
              <InfoTitle>Descrição</InfoTitle>
              <OfferCondition>{promotionDescription}</OfferCondition>

              <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />

              <InfoTitle>
                {promotionVoucher.deliveryMode === 'pickup'
                  ? 'Endereço de retirada'
                  : 'Detalhes do frete'}
              </InfoTitle>
              <AddressInfo>
                {promotionVoucher.deliveryMode === 'pickup'
                  ? promotionVoucher?.address?.fullAddress
                  : promotionVoucher?.shippingDetail}

              </AddressInfo>

              <PhoneChip
                onClick={() => openWhatsapp(promotionVoucher?.user?.phone)}
                size='large'
                label={(
                  <>
                    {promotionVoucher?.user?.phone
                      ? (
                        <Grid container flexDirection='row' alignItems='center' gap={1}>
                          <Grid item>
                            <Typography>
                              {`Tel - ${formatPhone(promotionVoucher?.user?.phone)}`}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <img src={whatsappIcon} height='25px' width='25px' alt='whatsappIcon' />
                          </Grid>
                        </Grid>
                      )
                      : <Typography>Telefone não cadastrado</Typography>}
                  </>
)}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent='center'>
            <StyledAlert
              color='error'
              icon={<ErrorOutlineIcon sx={{ fill: 'white' }} />}
            >
              Para agendar serviços entre em
              contato diretamente com a loja.
            </StyledAlert>
          </Grid>

        </Card>
      </Fade>
    </>
  );
};

export default VoucherRedemptionCard;
