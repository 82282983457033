import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import NetworkTable from '../../components/Network/NetworkTable';
import NetworkCardList from '../../components/Network/NetworkCardList';
import NetworkModal from '../../components/Network/NetworkModal';
import ReferAndWin from '../../components/Referral/ReferAndWin';
import useWindowSize from '../../hooks/windowSize';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Network = () => {
  const { width: screenWidth } = useWindowSize();
  const { type } = useSelector((state) => state.User.userData);
  const { context } = useSelector((state) => state.Network);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!screenWidth) {
      return;
    }

    if (screenWidth > 739) {
      dispatch.Network.setNetworkContext('table');
      setLoading(false);
    } else {
      dispatch.Network.setNetworkContext('card');
      setLoading(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (!type || type === 'T') {
      dispatch.Network.setListType('follower');
    } else {
      dispatch.Network.setListType('following');
    }
  }, [type]);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.NETWORK_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <NetworkModal />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <>
          {context !== 'table' && <ReferAndWin />}
          {context === 'table' ? <NetworkTable /> : <NetworkCardList />}
        </>
      )}
      <Footer />
    </MainWrapper>
  );
};

export default Network;
