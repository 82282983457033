import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../../utils/mobile';

const TelemedicineCallDialog = ({ open, setModalOpen }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': {
      minWidth: '350px',
    },
    '& .MuiDialog-container': {
      height: 'auto',
      paddingBottom: '10px',
      marginTop: isMobile ? '55px' : '150px',
    } }}
    fullScreen={isMobile}
    open={open}
    onBackdropClick={() => setModalOpen(false)}
  >
    <DialogTitle>
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item>
          Ainda não está no horário
        </Grid>

        <Grid item>
          <CloseIcon onClick={() => setModalOpen(false)} />
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent dividers>
      <Typography>Aguarde mais um pouco para realizar uma chamada</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        sx={{ color: '#6B48FF' }}
        onClick={() => setModalOpen(false)}
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default TelemedicineCallDialog;
