/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import { Avatar } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import theme from '../../../../utils/theme';
import { formatPhone } from '../../../../utils/format';
import placeholderCat from '../../../../assets/images/placeholder-cat.svg';
import placeholderDog from '../../../../assets/images/placeholder-dog.svg';

import { StyledTableRow, StyledNameTableCell } from './style';

const NetworkTableRow = ({ id, name, email, type, status, phone, inactivatedById, animalsInTransfer, scheduled, sendDatetime }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState([]);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const loggedUserId = useSelector((state) => state.User.userData?.id);

  const inactivatePermission = loggedUserId === inactivatedById;

  const [animalsInTransferAll, setAnimalsInTransferAll] = useState([]);

  const fetchAnimalImage = async () => {
    await Promise.all(animalsInTransfer.map(async (animal) => {
      const placeholder = animal.species === 'CAT' ? placeholderCat : placeholderDog;
      // eslint-disable-next-line no-param-reassign
      animal.animalImgSrc = (await dispatch.Photo.getPhoto(animal.avatarImage)) || placeholder;
    }));
    setAnimalsInTransferAll(animalsInTransfer);
  };

  useEffect(() => {
    !!animalsInTransfer?.length && fetchAnimalImage();
  }, []);

  const userTypeMap = {
    T: 'Tutor',
    V: 'Veterinário',
    C: 'Clínica',
    PS: 'Pet shop',
    DC: 'Day care',
    BT: 'Banho e Tosa',
    A: 'Adestrador',
    SI: 'Pet Sitter',
    OP: 'Operador',
    O: 'Outros',
  };

  const statusMap = {
    pending: {
      color: theme.pending,
      translation: 'pendente',
    },
    scheduled: {
      color: theme.caption,
      translation: 'agendado',
    },
    active: {
      color: theme.success,
      translation: 'ativo',
    },
    inactive: {
      color: theme.error,
      translation: 'inativo',
    },
    refused: {
      color: theme.error,
      translation: 'recusado',
    },
  };

  const handleClick = (event) => {
    if (status === 'refused') {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleAction = (context) => {
    setAnchorEl(null);

    let data = {};

    if (context === 'reinvite') {
      data = {
        inviteId: id,
        name,
        email,
        userType: type,
        status,
        phone: Number(phone) ? String(phone).replace(/[^A-Z0-9]/ig, '') : '',
        scheduled,
        sendDatetime,
      };
    } else if (context === 'reconnect' || context === 'disconnect' || context === 'cancel') {
      data = {
        id,
        username: name,
      };
    }

    dispatch.Network.setModal({ networkModal: { open: true, context, data } });
  };

  const setMenuItems = () => {
    const menus = [];

    if (status === 'scheduled') {
      menus.push([
        ...menus,
        <MenuItem onClick={() => handleAction('reinvite')}>Reenviar convite</MenuItem>,
        <MenuItem onClick={() => handleAction('cancel')}>Cancelar convite</MenuItem>,
      ]);
    }

    if (status === 'pending' && (type === '' || type === 'T')) {
      menus.push([
        ...menus,
        <MenuItem onClick={() => handleAction('reinvite')}>Reenviar convite</MenuItem>,
      ]);
    }

    if (status === 'active') {
      menus.push([...menus, <MenuItem onClick={() => handleAction('disconnect')}>Desconectar</MenuItem>]);
    }

    if (status === 'inactive' && inactivatePermission) {
      menus.push([...menus, <MenuItem onClick={() => handleAction('reconnect')}>Reconectar contato</MenuItem>]);
    }

    return menus;
  };

  useEffect(() => {
    setMenu(setMenuItems);
  }, [, status, loggedUserId]);

  return (
    <StyledTableRow>
      <StyledNameTableCell component='th' scope='row'>
        {name || '-'}
      </StyledNameTableCell>
      <TableCell>{email || '-'}</TableCell>
      <TableCell>{(phone && formatPhone(phone)) || '-'}</TableCell>
      <TableCell>{userTypeMap[type] || '-'}</TableCell>
      <TableCell>
        {animalsInTransferAll && animalsInTransferAll.length ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '180px', flexWrap: 'wrap' }}>
            {animalsInTransferAll.map((transferAnimal) => (
              <Chip
                avatar={<Avatar alt='pet' src={transferAnimal.animalImgSrc} />}
                variant='outlined'
                label={transferAnimal.name}
                sx={{ border: 'dashed', borderWidth: '2px', borderColor: theme.primaryButton }}
              />
            ))}
          </div>
        ) : <div style={{ textAlign: 'center' }}>-</div>}
      </TableCell>

      <TableCell>
        <Chip
          label={status && statusMap[status]?.translation ? statusMap[status].translation : ''}
          sx={status && statusMap[status]?.color ? { color: 'white', backgroundColor: statusMap[status].color } : {}}
          size='small'
        />
      </TableCell>

      <TableCell>
        {menu.length ? (
          <div>
            <IconButton
              aria-label='more'
              id='long-button'
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              {menu}
            </Menu>
          </div>
        ) : <></>}
      </TableCell>
    </StyledTableRow>
  );
};

export default NetworkTableRow;
