/* eslint-disable no-unused-vars */
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import CancelOrSubmit from '../../../global/CancelOrSubmit';
import { HelperText, ShrinkMenuItem } from './style';

const BreastExam = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.protocolApply);
  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const content = useSelector((state) => state.Modal.protocolDialog?.content);
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');

  const lastBreastTemperature = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.lastBreastTemperature);
  const lastBreastColoring = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.lastBreastColoring);
  const lastBreastSize = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.lastBreastSize);
  const lastHadPuppies = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.lastHadPuppies);
  const lastBreastHadNode = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol.lastBreastHadNode);

  const submitForm = async (formData) => {
    // setLoading(true);
    const { hadPuppies, breastSize, breastTemperature, breastColoring, breastHadNode } = formData;

    const payload = {
      protocolId: id,
      ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
      hadPuppies: JSON.parse(hadPuppies),
      breastSize,
      breastTemperature,
      breastColoring,
      breastHadNode: JSON.parse(breastHadNode),
      appliedDatetime: moment().toISOString(),
    };

    const applyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (applyAnimalProtocol && applyAnimalProtocol.data) {
      const result = applyAnimalProtocol.data[0]?.lastResult;
      dispatch.Modal.setDialog({ open: false });
      dispatch.Modal.setResultDialog({ open: true, content, result });
    }

    // setLoading(false);
  };

  return (
    <Grid sx={{ width: '100%' }}>
      <Grid container flexDirection='column' justifyContent='flex-start'>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={(!isProtocolBeingEdited && {}) || {
            hadPuppies: String(lastHadPuppies),
            breastSize: lastBreastSize,
            breastColoring: lastBreastColoring,
            breastTemperature: lastBreastTemperature,
            breastHadNode: String(lastBreastHadNode),
          }}
          onSubmit={submitForm}
          validationSchema={
            Yup.object().shape({
              hadPuppies: Yup.string().required('Favor selecionar uma das opções'),
              breastSize: Yup.string().required('Favor selecionar uma das opções'),
              breastColoring: Yup.string().required('Favor selecionar uma das opções'),
              breastTemperature: Yup.string().required('Favor selecionar uma das opções'),
              breastHadNode: Yup.string().required('Favor selecionar uma das opções'),
            })
}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <FormControl error={!!errors.hadPuppies} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Já teve filhotes?</InputLabel>
                <Select
                  id='hadPuppies'
                  name='hadPuppies'
                  defaultValue='Selecione'
                  label='Já teve filhotes?'
                  error={!!errors.hadPuppies}
                  helperText={errors.hadPuppies && errors.hadPuppies}
                  value={values.hadPuppies || 'Selecione'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione uma opção</MenuItem>
                  <MenuItem value='true'>Sim</MenuItem>
                  <MenuItem value='false'>Não</MenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.breastSize} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Tamanho das mamas</InputLabel>
                <Select
                  id='breastSize'
                  name='breastSize'
                  defaultValue='Selecione'
                  label='Tamanho das mamas'
                  error={!!errors.breastSize}
                  helperText={errors.breastSize && errors.breastSize}
                  value={values.breastSize || 'Selecione'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione uma opção</MenuItem>
                  <MenuItem value='normal'>Normal</MenuItem>
                  <ShrinkMenuItem value='irregular'>Irregular (algumas maiores que as outras, inchaço, cores diferentes, machucado)</ShrinkMenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.breastColoring} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Coloração das mamas</InputLabel>
                <Select
                  id='breastColoring'
                  name='breastColoring'
                  defaultValue='Selecione'
                  label='Tamanho das mamas'
                  error={!!errors.breastColoring}
                  helperText={errors.breastColoring && errors.breastColoring}
                  value={values.breastColoring || 'Selecione'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione uma opção</MenuItem>
                  <MenuItem value='normal'>Normais</MenuItem>
                  <MenuItem value='reddish'>Avermelhadas</MenuItem>
                  <MenuItem value='purplish'>Arroxeada</MenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.breastTemperature} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Temperatura ao tato</InputLabel>
                <Select
                  id='breastTemperature'
                  name='breastTemperature'
                  defaultValue='Selecione'
                  label='Temperatura ao tato'
                  error={!!errors.breastTemperature}
                  helperText={errors.breastTemperature && errors.breastTemperature}
                  value={values.breastTemperature || 'Selecione'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione uma opção</MenuItem>
                  <MenuItem value='hot'>Mamas mais quentes</MenuItem>
                  <MenuItem value='cold'>Fria</MenuItem>
                </Select>
              </FormControl>

              <FormControl error={!!errors.breastHadNode} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel>Presença de nódulos ao apalpar</InputLabel>
                <Select
                  id='breastHadNode'
                  name='breastHadNode'
                  defaultValue='Selecione'
                  label='Presença de nódulos ao apalpar'
                  error={!!errors.breastHadNode}
                  helperText={errors.breastHadNode && errors.breastHadNode}
                  value={values.breastHadNode || 'Selecione'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='Selecione'>Selecione uma opção</MenuItem>
                  <MenuItem value='true'>Sim</MenuItem>
                  <MenuItem value='false'>Não</MenuItem>
                </Select>
                <HelperText>Ao redor da mama, você pode reparar uma ou mais massas, pequenas ou grandes, com formato arredondado (como uma bola), mas podendo ter outras formas. Essas massas podem ser macias, firmes ou mesmo duras.</HelperText>
              </FormControl>

              <CancelOrSubmit loading={loading === 'loading'} />
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>

  );
};

export default BreastExam;
