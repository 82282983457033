import { Fade, Card, CardContent, Grid, Typography, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ServicePartnerCard from '../ServicesDashboard/ServicePartnerCard';

import DashboardHeader from '../../Dashboard/DashboardHeader';
import DashboardMenu from '../../Dashboard/DashboardMenu';
import Footer from '../../Footer';
import TelemedicineClickToCall from '../TelemedicineClickToCall';
import Loading from '../../global/Loading';

const PartnersDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { listConnectedServices: listConnectedServicesLoading } = useSelector((state) => state.Appointments.loading);
  const { listConnectedServices } = useSelector((state) => state.Appointments);
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const isLoading = listConnectedServicesLoading !== 'fulfilled';

  const parsedSlug = () => {
    const slugWithSpaces = params.id.replaceAll('-', ' ');
    const capitalizedSlug = slugWithSpaces.charAt(0).toUpperCase() + slugWithSpaces.slice(1);

    if (capitalizedSlug === 'Adestramento') {
      return 'Adestramento e Consultoria Comportamental';
    }

    if (capitalizedSlug.includes('telemedicina')) {
      return 'Telemedicina';
    }

    return capitalizedSlug;
  };

  const redirectToSchedule = async (serviceId) => {
    await dispatch.Appointments.serviceDetail(serviceId);

    history.push(`/services/create/${serviceId}`);
  };

  const redirectToLastPage = () => history.push('/services');

  const category = parsedSlug();

  const handleCall = () => {
    history.push('/services/telemedicine-call');
  };

  useEffect(() => {
    const getServices = async () => {
      const parsedCategory = category.includes('Adestramento') ? 'Adestramento' : category;

      dispatch.Appointments.listConnectedServices({ category: parsedCategory });
    };

    getServices();
  }, []);

  useEffect(() => {
    const checkIfUserIsPremium = () => {
      if (isDefaultPlan && window.location.href.includes('telemedicina')) {
        history.push('/services');
      }
    };

    checkIfUserIsPremium();
  }, [isDefaultPlan]);

  return (
    <>
      <DashboardHeader />
      <DashboardMenu />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ padding: '0 24px', maxWidth: '1310px', marginBottom: '16px' }}
        >
          <Grid item sx={{ width: '100%' }}>
            <Grid
              container
              direction='column'
            >
              <Grid onClick={redirectToLastPage} container direction='row' alignItems='center' sx={{ margin: '0px 0px 24px 0' }}>
                <Grid item>
                  <ArrowBackIcon sx={{ color: '#6B48FF' }} />
                </Grid>

                <Grid item>
                  <Button size='large' sx={{ color: '#6B48FF', padding: '0px', marginRight: '5px' }}>Voltar</Button>
                </Grid>
              </Grid>
              {window.location.href.includes('guiavet-telemedicina')
              && <TelemedicineClickToCall shortCard handleCall={handleCall} />}
              <Typography variant='h6' sx={{ marginBottom: '5px' }}>
                Agendas de
                {' '}
                {category}
              </Typography>
            </Grid>

            <Card elevation={0} sx={{ width: '100%', marginTop: '5px', display: listConnectedServices.length ? 'flex' : 'none' }}>
              <CardContent>
                <Grid
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  gap={2}
                >
                  {isLoading ? (
                    <Grid
                      container
                      direction='column'
                      justifyContent='center'
                      alignItems='center'
                      sx={{ minHeight: '60vh' }}
                    >
                      <Grid item>
                        <Loading />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {listConnectedServices.map(({ id, ...props }, index) => (
                        <Fade in>
                          <Grid sx={{ width: '100%' }} onClick={() => redirectToSchedule(id)} key={index} item>
                            <ServicePartnerCard
                              {...props}
                            />
                          </Grid>
                        </Fade>
                      ))}
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default PartnersDashboard;
