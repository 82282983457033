import { Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';

import theme from '../../../../utils/theme';

export const StyledCard = styled(Card)`
  &.MuiPaper-root {
    padding: 10px 0;
    background-color: ${({ $resolved }) => ($resolved ? theme.backgroundPaper : '#E9F5FE')}
  }
`;

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    cursor: pointer;
  }

  &.MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }
`;

export const StyledCardContentNotification = styled(CardContent)`

  &.MuiCardContent-root, .wrapper-click{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    cursor: pointer;
  }

  .wrapper-click-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  &.MuiCardContent-root {
    display: block;
  }

  &.MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }
`;

export const RejectButton = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    padding: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    background-color: transparent;
    color: darkred;
    transition: color 0.1s ease-in;

    &:hover {
      color: red;
    }
`;

export const AcceptButton = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    background-color: transparent;
    color: ${theme.primaryButton};
    border: 1px solid ${theme.primaryButton};
    border-radius: 5px;
    transition: background-color 0.1s ease-in, color 0.1s ease-in;

    &:hover {
      background-color: ${theme.primaryButton};
      color: ${theme.backgroundPaper};
    }
`;

export const ButtonControl = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 10px 0;
`;

export const Description = styled(Typography)`
    &.MuiTypography-root {
      white-space: pre-wrap;
    }
`;
