import styled from 'styled-components';
import { Box, Card, CardContent, Chip, Stack } from '@mui/material';

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: flex-start;
  height: 169px;
`;

export const StyledCardContent = styled(CardContent)`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ContactInfoBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  max-width: 180px;
  height: auto;
`;

export const StyledStack = styled(Stack)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 135px;
  width: 80px;
`;

export const StyledChip = styled(Chip)`

`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
