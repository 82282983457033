import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { TableHeader, StyledContainer, StyledTableContainer, TableFilters, Filter } from './style';
import DueProtocolTableRow from './DueProtocolTableRow';
import Loading from '../../global/Loading';

const DueProtocolsTable = () => {
  const dispatch = useDispatch();
  const { reportData, reportDataCount } = useSelector((state) => state.Business);
  const { protocolApplyToExpire } = useSelector((state) => state.Business.loading);

  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'year').format());
  const [endDate, setEndDate] = useState(moment().add(367, 'days').format());
  const [daysToExpire, setDaysToExpire] = useState(0);
  const [daysToExpireList] = useState([
    { day: 1, label: 'Hoje' }, { day: 7, label: '7 dias' }, { day: 30, label: '30 dias' }, { day: 0, label: 'Todos' },
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isLoading = protocolApplyToExpire !== 'fulfilled';

  const fetchReportDataCount = async () => {
    await dispatch.Business.protocolApplyToExpireCount({ startDate, endDate });
  };

  const fetchReportData = async (nextPage) => {
    dispatch.Business.clearReportData();
    dispatch.Business.setReportDataContext('');

    const response = await dispatch.Business.protocolApplyToExpire({ pageNumber: nextPage, pageLength: rowsPerPage, startDate, endDate });

    if (response && response.data) {
      setPage(nextPage);
    }
  };

  const handleDaysToExpire = (newDaysToExpire) => {
    setDaysToExpire(newDaysToExpire);
    const lastYear = moment().subtract(1, 'year');

    if (newDaysToExpire === 0) {
      setStartDate(lastYear.format());
      setEndDate(moment().add(367, 'days').format());
    }

    if (newDaysToExpire === 1) {
      setStartDate(moment().subtract(1, 'year').format());
      setEndDate(moment().add(1, 'days').format());
    }

    if (newDaysToExpire === 7) {
      setStartDate(moment().subtract(1, 'year').format());
      setEndDate(moment().add(7, 'days').format());
    }

    if (newDaysToExpire === 30) {
      setStartDate(moment().subtract(1, 'year').format());
      setEndDate(moment().add(30, 'days').format());
    }
  };

  useEffect(() => {
    fetchReportDataCount();
    fetchReportData(page);
  }, [daysToExpire, rowsPerPage]);

  return (
    <>
      <StyledContainer sx={{ width: '50%' }}>
        {isLoading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            sx={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            <StyledTableContainer component={Paper} sx={{ width: '100%' }}>
              <TableHeader>
                <Typography variant='h6'>Protocolos a vencer</Typography>

                <TableFilters>
                  <Typography variant='body2'>Vence em até:</Typography>
                  {daysToExpireList.map(({ day, label }) => (
                    <Filter
                      selected={daysToExpire === day}
                      onClick={() => handleDaysToExpire(day)}
                      variant='body2'
                    >
                      {label}
                    </Filter>
                  ))}
                </TableFilters>

              </TableHeader>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component='div'
                count={reportDataCount}
                labelRowsPerPage='Linhas por página'
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={({ from, to, count: labelCount }) => (`${from}–${to} de ${labelCount !== -1 ? labelCount : `maior que ${to}`}`)}
                onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                page={page}
                onPageChange={(event, nextPage) => fetchReportData(nextPage)}
              />
              <Table sx={{ minHeight: 100 }} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Animal</TableCell>
                    <TableCell>Espécie</TableCell>
                    <TableCell>Raça</TableCell>
                    <TableCell>Idade</TableCell>
                    <TableCell>Protocolo</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Protocolo Data Aplicação</TableCell>
                    <TableCell>Protocolo Data Vencimento</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.length ? reportData.map(({ customerId, ...reportProps }) => (
                    <DueProtocolTableRow
                      key={customerId}
                      customerId={customerId}
                      {...reportProps}
                    />
                  )) : <></>}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default DueProtocolsTable;
