import React, { useState, useEffect } from 'react';

import { Fade, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { StyledGrid } from './style';
import { HOME_URL } from '../../utils/mobile';
import Loading from '../../components/global/Loading';
import Footer from '../../components/Login/LoginForm/Footer';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { getParsedQueryString } from '../../utils/querystring';

const FacebookCallbackPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const origin = () => {
    const querystring = location.search.slice(1);

    if (!querystring) {
      return;
    }

    const query = getParsedQueryString(location.search);

    // eslint-disable-next-line consistent-return
    return query;
  };

  const query = useState(origin());

  useEffect(async () => {
    Mixpanel.track(MIXPANEL_TAGS.LOGIN_CALLBACK_FACEBOOK_VIEW);

    const mobileFacebookLogin = async () => {
      if (query && query.length > 0) {
        if (query[0]?.code) {
          const { code } = query[0];
          const payload = { code };
          const loginWithFacebook = await dispatch.User.loginFacebookCode(payload);

          if (loginWithFacebook && loginWithFacebook.data && loginWithFacebook.data.access) {
            const getUserData = await dispatch.User.getUserProfileData();

            let dateJoined = new Date(getUserData.data.dateJoined);
            dateJoined = [dateJoined.getDate(), dateJoined.getMonth() + 1, dateJoined.getFullYear()].join('/');
            const actualDate = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('/');
            const isFirstLogin = (dateJoined === actualDate);

            Mixpanel.track(MIXPANEL_TAGS.SIGNIN_FACEBOOK, { distinct_id: getUserData.data.id, isFirstLogin });

            if (getUserData && getUserData.data) {
              const { eventTrigger, countAnimals } = getUserData.data;

              if (eventTrigger && eventTrigger.type === 'redirect') {
                if (countAnimals) {
                  return history.push(`/services/create/${eventTrigger.payload.serviceId}${history.location.search}`);
                }

                return history.push(`/pet/create${history.location.search}`);
              }

              // if (!isProfileFilled) {
              //   return history.push(botToOpen ? `${HOME_URL}${history.location.search}` : `/profile${history.location.search}`);
              // }

              return history.push(`${HOME_URL}${history.location.search}`);
            }
          }
        }
      }

      return null;
    };

    mobileFacebookLogin();
  }, [query]);

  return (
    <StyledGrid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-end'
    >
      <Fade in>
        <Grid item xs={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Fade>
      <Grid item xs={12} md={8} lg={8}>
        <Footer />
      </Grid>
    </StyledGrid>
  );
};

export default FacebookCallbackPage;
