import { Typography } from '@mui/material';
import styled from 'styled-components';

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const ResultImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 260px;

  @media screen and (max-width: 450px) {
    height: 210px;
  }
`;

export const ErgonomicImage = styled.div`
  width: 100%;
  min-height: 220px;
  height: auto;
  position: relative;
  overflow: hidden;
  background-color: #6649F6;
  padding-bottom: 30px;

  img {
    position: absolute;
    top: 0px;
    right: 0;
    width: 150px;
    height: 350px;

    @media screen and (max-width: 450px) {
      height: 300px;
    }
  }
`;

export const ErgonomicTitle = styled(Typography)`
  &.MuiTypography-root {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: white;
    margin: 22px;
  }
`;

export const ErgonomicDescription = styled(Typography)`
  &.MuiTypography-root {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 70%;
    margin: 0px 26px 5px 26px;
  }
`;
