import React from 'react';

import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import theme from '../../utils/theme';
import { isAuthenticated } from '../../services/api';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as PawIcon } from '../../assets/images/paw_outlined.svg';
// import { ReactComponent as OrdersIcon } from '../../assets/images/Footer/orders.svg';
// import { ReactComponent as VoucherIcon } from '../../assets/images/Footer/coupons.svg';
import { ReactComponent as PetStoreIcon } from '../../assets/images/Services/pet_store.svg';

import { StyledTabs, StyledTab } from './styles';

const Footer = () => {
  const routeMatch = useRouteMatch([
    '/dashboard', '/pet', '/business', '/profile', '/network',
    '/services', '/appointments', '/orders', '/notifications', '/vouchers',
    '/pets',
  ]);
  const currentTab = routeMatch?.path;
  const isUserLogged = isAuthenticated();
  const { isB2B } = useSelector((state) => state.User.userData);
  const isAppUrl = window.location.href.includes(process.env.REACT_APP_WEB);

  const onClickHome = () => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'INICIO_SCREEN', params: null }));
    Mixpanel.track(MIXPANEL_TAGS.MENU_HOME);
  };

  const onClickPets = () => Mixpanel.track(MIXPANEL_TAGS.MENU_PET);

  const onClickNetwork = () => Mixpanel.track(MIXPANEL_TAGS.MENU_NETWORK);

  // const onClickVouchers = () => Mixpanel.track(MIXPANEL_TAGS.MENU_DISCOUNT);

  const onClickServices = () => Mixpanel.track(MIXPANEL_TAGS.MENU_SERVICES);

  // const onClickOrders = () => Mixpanel.track(MIXPANEL_TAGS.MENU_ORDERS);

  // if (isNative) return null;

  return (
    <StyledTabs
      value={currentTab}
      aria-label='icon label tabs'
      TabIndicatorProps={{ style: { background: theme.footerIndicator, top: '0px', borderRadius: '4px' } }}
      variant='fullWidth'
      sx={{ display: isUserLogged ? 'initial' : 'none' }}
    >
      {isAppUrl && (
        <StyledTab
          value='/dashboard'
          icon={<HomeIcon />}
          label='Início'
          component={Link}
          to='/dashboard'
          onClick={onClickHome}
        />
      )}
      <StyledTab
        value={currentTab === '/pets' || currentTab === '/notifications' ? '/pets' : '/pet'}
        icon={<PawIcon />}
        label={isB2B ? 'Clientes' : 'Pets'}
        component={Link}
        to='/pets'
        onClick={onClickPets}
      />
      {/* {!isB2B && (
        <StyledTab
          value='/vouchers'
          icon={<VoucherIcon />}
          label='Descontos'
          component={Link}
          to='/vouchers'
          onClick={onClickVouchers}
        />
      )} */}
      {!isB2B && (
        <StyledTab
          value='/services'
          icon={<PetStoreIcon />}
          label='Serviços'
          component={Link}
          to='/services'
          onClick={onClickServices}
        />
      )}
      {/* {!isB2B && (
        <StyledTab
          value='/orders'
          icon={<OrdersIcon />}
          label='Pedidos'
          component={Link}
          to='/orders'
          onClick={onClickOrders}
        />
      )} */}
      {isB2B && (
        <StyledTab
          value='/business'
          icon={<AttachMoneyIcon />}
          label='Negócio'
          component={Link}
          to='/business'
        />
      )}
      {isB2B && (
        <StyledTab
          value='/profile'
          icon={<PersonOutlineIcon />}
          label='Perfil'
          component={Link}
          to='/profile'
        />
      )}
      <StyledTab
        value='/network'
        icon={<PeopleOutlineIcon />}
        label='Rede'
        component={Link}
        to='/network'
        onClick={onClickNetwork}
      />
      {/*
          <StyledTab value='/historico' icon={<HistoryIcon />} label="HISTÓRICO" component={Link} disabled  /> */}
    </StyledTabs>
  );
};

export default Footer;
