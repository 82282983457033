import { CalendarPicker } from '@mui/lab';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Calendar = styled(CalendarPicker)`
  & .MuiPickersDay-root.MuiPickersDay-today {
    border: 1px solid #6B48FE;
    background-color: transparent;
    color: black;
  }

  & .MuiButtonBase-root.MuiPickersDay-root:focus {
    color: white;
    background: #6B48FE;
  }

  & .MuiButtonBase-root.MuiPickersDay-root:active {
    color: white;
    background: #6B48FE;
  }
  & .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    color: white;
    background: #6B48FE;
  }
`;

export const CardsGrid = styled(Grid)`
  &.MuiGrid-root {
    position: relative;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(100, 50, 255, 0.2);
    }
  }
`;

export const HourRule = styled.div`
  position: absolute;
`;
