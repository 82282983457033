/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../../../global/Alert';
import { ModalBox, CloseModal, ButtonControl, ButtonModal, DisconnectTextControl } from './style';
import Loading from '../../../global/Loading';

const DisconnectContent = () => {
  const dispatch = useDispatch();
  const { listType } = useSelector((state) => state.Network);
  const { id, username } = useSelector((state) => state.Network.modal.networkModal.data) || '';

  const [submitedStatus, setSubmitedStatus] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  const handleSubmitDisconnect = async () => {
    setSubmitLoading(true);

    const payload = {
      connectionId: id,
    };

    const disconnectContact = await dispatch.Network.inactivateContact(payload);

    if (disconnectContact && disconnectContact.data) {
      dispatch.Network.updateNetworkContactStatus(disconnectContact.data);
      setSubmitedStatus('success');
      setTimeout(() => closeModal(), 1000);
    } else {
      setSubmitedStatus('error');
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Typography id='desconectar-contato' variant='h6' component='h2'>
          Compartilhamento dados dos pets
        </Typography>

        <DisconnectTextControl>
          <Typography id='enviar-convite' variant='body1'>
            {listType === 'follower' ? (
              <>
                Deseja parar de compartilhar seus pets com
                {' '}
                <b>{username}</b>
                ?
              </>
            ) : (
              <>
                Deseja parar de visualizar os dados dos pets de
                {' '}
                <b>{username}</b>
                ?
              </>
            )}

          </Typography>
        </DisconnectTextControl>
        <ButtonControl>
          {submitLoading ? (
            <Grid sx={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '45px' }}>
              <Loading size={30} />
            </Grid>
          ) : (
            <>
              <ButtonModal style={{ marginRight: '42px' }} type='button' onClick={closeModal}>Cancelar</ButtonModal>
              <ButtonModal style={{ marginRight: '25px', color: 'red' }} type='submit' mainButton onClick={handleSubmitDisconnect}>Desconectar</ButtonModal>
            </>
          )}

        </ButtonControl>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Contato desconectado com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha ao desconectar contato
              </Alert>
            </div>

          )}

        </Snackbar>
      </Grid>
    </>
  );
};

export default DisconnectContent;
