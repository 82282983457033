import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import banner from '../../../assets/images/PetCard/quality-of-life.jpg';
import chevron from '../../../assets/images/PetCard/Chevron_right-banner.png';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import { Image, FlexRow, ImageChevron } from './style';
import useWindowSize from '../../../hooks/windowSize';

const QuizHealthCard = ({ result }) => {
  const [isDesktop, setIsDesktop] = useState(true);
  const isNative = navigator?.userAgent && navigator.userAgent.includes('wv') && (navigator.userAgent.includes('Android') || navigator.userAgent.includes('iOS'));
  const screen = useWindowSize();
  const permissions = useSelector((state) => state?.FeatureSettings?.permissions) || {};

  useLayoutEffect(() => {
    setIsDesktop(screen.width > 736);
  }, [screen.width]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.QUIZ_HEALTH_CARD_LOAD);
  }, []);

  if (isNative && !isDesktop && permissions?.qualityOfLifeView === true) {
    return (
      <Grid container sx={{ width: '100%', minHeight: '22vw', background: '#22978B', borderRadius: 2, marginBottom: 2, overflow: 'hidden', position: 'relative', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 1.2, paddingBottom: 1.2 }}>
        <Image src={banner} />
        <FlexRow style={{ zIndex: 2, width: '64%', alignItems: 'center', paddingRight: 8 }}>
          <Box sx={{ mr: 0 }}>
            { result?.length ? (
              <Box sx={{ mr: 1.2 }}>
                <Typography sx={{ color: '#FFF', fontSize: '5vw', fontFamily: 'Inter', lineHeight: '4.9vw', fontWeight: 700, textAlign: 'right', p: 0, m: 0 }}>
                  Qualidade de Vida
                </Typography>

                {
                  result[0]?.createDatetime ? (
                    <Typography sx={{ color: '#FFF', fontSize: '2.8vw', fontFamily: 'Inter', lineHeight: '3.4vw', fontWeight: 400, textAlign: 'right', p: 0, m: 0, mt: 0.5 }}>
                      {`Calculada em ${moment(result[0]?.createDatetime).format('DD/MM/YYYY')}`}
                    </Typography>
                  ) : null
                }

                <Typography sx={{ color: '#F2C94B', fontSize: '3.4vw', fontFamily: 'Inter', lineHeight: '3.8vw', fontWeight: 700, textAlign: 'right', p: 0, m: 0, mt: 1.3 }}>
                  {`${result[0]?.attentionPoints || 'Sem'} pontos de atenção`}
                </Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ mr: 1.2 }}>
                  <Typography sx={{ color: '#FFF', fontSize: '5vw', fontFamily: 'Inter', lineHeight: '4.9vw', fontWeight: 700, textAlign: 'right', p: 0, m: 0 }}>
                    Calculadora de Qualidade de Vida
                  </Typography>
                </Box>
              </>
            ) }

          </Box>
          <ImageChevron src={chevron} alt='test' />
        </FlexRow>
      </Grid>
    );
  }

  return null;
};

export default QuizHealthCard;
