import { Button, Card, CardContent } from '@mui/material';
import styled from 'styled-components';
import { SecondaryButton } from '../../../utils/theme';

export const EditArea = styled.div`
float: left;
padding: 10px;
`;

export const StyledCardContent = styled(CardContent)`
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
`;

export const HealthControlButton = styled(SecondaryButton)`
    margin-top: 14px !important;
    transition: box-shadow .3s ease-in;

    &:hover {
        box-shadow: -1px 11px 8px -5px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 736px) {
        display: none !important;
    }

    display: none !important;
`;

export const TeleOrientationButton = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    font-weight: 500;
    padding: 7px 15px;
    margin-top: 30px;
    width: 215px;
    z-index: 5;
    height: 40px;
    transition: box-shadow .3s ease-in;

    &:hover {
        background-color: white;
        box-shadow: -1px 11px 8px -5px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 736px) {
        display: none;
        margin-top: 10px;
    }

    svg {
      padding: 5px 0px 5px 0px;
      width: 18px;
      height: 18px;
      min-height: 18px;
      min-width: 18px;
    }
  }

  display: none !important;
`;

export const Communication = styled.div`
    display: none;

    @media screen and (max-width: 736px) {
        display: flex;
        margin: 40px 120px 0 0;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #6B48FF;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 2;
        cursor: pointer;
        transition: background-color .2s ease-in;

        &:hover {
            background-color: #4e34ba;
        }
    }
`;

export const StyledPetCard = styled(Card)`
  &.MuiPaper-root {
  }
`;
