import { Grid, Typography } from '@mui/material';
import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledGrid, StyledChip } from './style';

const VoucherCategoryFilterList = () => {
  const dispatch = useDispatch();
  const lastChipRef = useRef(null);

  const { promotionB2CCategories } = useSelector(({ Vouchers }) => Vouchers) || [];
  const actualB2CCategory = useSelector((state) => state.Vouchers.actualB2CCategory);

  const setActualCategory = (newCategory) => dispatch.Vouchers.setActualB2CCategory(newCategory);
  const fetchPromotionCategories = async () => dispatch.Vouchers.promotionB2CCategories();

  useEffect(() => fetchPromotionCategories(), []);

  return (
    <StyledGrid
      ref={lastChipRef}
      container
      wrap='nowrap'
      flexDirection='row'
      sx={{ paddingLeft: '20px', paddingTop: '24px', paddingBottom: '24px', overflow: 'scroll', wrap: 'nowrap', maxWidth: '1280px' }}
      gap={1}
    >
      <Grid item>
        <StyledChip
          label={<Typography variant='body2'>Todos</Typography>}
          onClick={() => setActualCategory('Todos')}
          $selectedCategory={actualB2CCategory === 'Todos'}
        />
      </Grid>

      {promotionB2CCategories.length > 0 && promotionB2CCategories.map(({ id, category }, index) => (
        <Grid key={id} item>
          <StyledChip
            key={id}
            label={<Typography variant='body2'>{category}</Typography>}
            onClick={() => {
              setActualCategory(category);

              const isLastCategory = promotionB2CCategories.length === index + 1;

              if (isLastCategory) {
                lastChipRef.current.scrollTo(489, 0);
              }
            }}
            onDelete={actualB2CCategory === category ? () => setActualCategory('Todos') : undefined}
            $selectedCategory={actualB2CCategory.toLowerCase() === category.toLowerCase()}
          />
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default VoucherCategoryFilterList;
