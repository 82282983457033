/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloseModal, ModalBox, ButtonModal } from './style';

const ErrorModalContent = () => {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.Appointments.modal.scheduleModal.data) || {};
  const closeModal = () => dispatch.Appointments.setModal({ scheduleModal: { open: false, data: {} } });

  return (
    <ModalBox>
      <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: '0px 40px' }}
      >
        <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
          <Grid item>
            <Typography variant='h6' sx={{ marginTop: '20px', marginLeft: '40px' }}>Falha no agendamento</Typography>
          </Grid>

          <Grid item>
            <Typography sx={{ margin: '30px' }} variant='body1'>{error}</Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end' alignItems='flex-end'>
          <Grid item>
            <ButtonModal onClick={closeModal}>Voltar</ButtonModal>
          </Grid>
        </Grid>
      </Grid>

    </ModalBox>
  );
};

export default ErrorModalContent;
