import styled from 'styled-components';
import { Card, Typography } from '@mui/material';
import { ReactComponent as MoneyIcon } from '../../../../assets/images/Statements/StatementCard/moneyIcon.svg';

export const StyledCard = styled(Card)`
  &.MuiCard-root {
    position: relative;
    padding: 24px;
    width: 270px;
    height: ${({ height }) => (height || '107px')};
    min-height: ${({ height }) => (height || '107px')};
  }
`;

export const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    color: #C4C4C4;
  }
`;

export const CardValue = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 34px;
    color: #7E8192;
  }
`;

export const CardMonetary = styled(CardTitle)`
  &.MuiTypography-root {
    margin-top: 6px;
  }
`;

export const CardItens = styled(CardTitle)`
  &.MuiTypography-root {
    color: #CCC3FE;
    font-weight: 100;
    margin-left: 2px;
  }
`;

export const StyledMoneyIcon = styled(MoneyIcon)`
  position: absolute;
  top: 20px;
  right: 15px;
  border: 1px solid #E0E0E0;
  border-radius: 50%;
  padding: 5px;
`;
