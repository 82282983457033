import { Card } from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  &.MuiCard-root {
    background: linear-gradient(rgba(148, 123, 255, 1), rgba(175, 156, 255, 0.58));
    border-radius: 22px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    height: 89px;
    overflow: visible;
    position: relative;
  }
`;

export const Image = styled.img`
  margin-bottom: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ImageChevron = styled.img`
  margin-top:  12px;
  height: 10vw;
  width: 8vw;
`;
