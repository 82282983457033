/* eslint-disable no-unused-expressions */
import { TextField, Button, Badge, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PickersDay } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppointmentCard from '../AppointmentCard';
import { getPetPlaceholder } from '../../../../services/api';
import { Calendar, CardsGrid } from './style';
import Loading from '../../../global/Loading';

const AppointmentScheduleCard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { dayScheduledB2BList: dayScheduledB2BListLoading, serviceDetail: serviceDetailLoading } = useSelector((state) => state.Appointments.loading);
  const name = useSelector((state) => state.Appointments.actualService.name);
  const [isLoadingFetchServiceDetail, setLoadingFetchServiceDetail] = useState(true);
  const [dateSelected, setDateSelected] = useState(moment());
  const [isDateFromNotification, setIsDateFromNotification] = useState(false);
  const { dayScheduledB2BList } = useSelector((state) => state.Appointments) || [];

  const titleDate = `${moment(dateSelected).format('dddd')} - ${moment(dateSelected).format('DD [de] MMMM [de] YYYY')}`;

  const [calendarDates, setCalendarDates] = useState([]);
  const [actualDayLoading, setActualDayLoading] = useState(false);

  const isDayLoading = dayScheduledB2BListLoading !== 'fulfilled';
  const isServiceLoading = serviceDetailLoading !== 'fulfilled';

  useEffect(() => setActualDayLoading(true), [isDayLoading]);

  const fetchAnimalImgSrc = async (animal) => {
    const placeholder = getPetPlaceholder(animal);
    return (await dispatch.Photo.getPhoto(animal.avatarImage)) || placeholder;
  };

  const [dayScheduledB2BListAll, setDayScheduledB2BListAll] = useState([]);

  const fetchAnimalImage = async () => {
    const b2bList = [...dayScheduledB2BList];
    await Promise.all(b2bList.map(async (animal) => {
      // eslint-disable-next-line no-param-reassign
      animal.animalImgSrc = await fetchAnimalImgSrc(animal);
    }));
    setDayScheduledB2BListAll(b2bList);
  };

  useEffect(() => {
    !!dayScheduledB2BList?.length && fetchAnimalImage();
  }, [dayScheduledB2BList]);

  const fetchDayScheduleList = async () => {
    const payload = {
      serviceToSchedule: params.id,
      startDate: moment(dateSelected).startOf('day').format(),
      endDate: moment(dateSelected).endOf('day').format(),
      dateType: 'day',
    };

    const response = await dispatch.Appointments.listScheduleB2B(payload);

    return response;
  };

  const fetchScheduleB2B = async (schedulingId) => {
    const response = await dispatch.Appointments.detailSchedule(schedulingId);

    return response;
  };

  /* eslint-disable no-use-before-define */
  const checkDayAvailability = (daysOfMonth) => {
    if (daysOfMonth && daysOfMonth.length > 0 && !isDateFromNotification) {
      const findDate = daysOfMonth.find((calendar) => (!calendar.isUnavailable || calendar.hasPendingSchedule));
      if (findDate) {
        setDateSelected(moment(findDate.date).format());
      } else if (!findDate && isLoadingFetchServiceDetail) {
        const nextMonthDate = moment().add(1, 'M').startOf('month').format();
        fetchB2BCalendarAvailability(nextMonthDate);
      }
    }
    setIsDateFromNotification(false);
    setLoadingFetchServiceDetail(false);
  };

  const fetchB2BCalendarAvailability = async (monthDate) => {
    const response = await dispatch.Appointments.getB2BCalendarAvailability({
      serviceId: params.id,
      startDate: moment(monthDate).startOf('month').format(),
      endDate: moment(moment(moment(moment(monthDate).endOf('month')).add(1, 'day'))).startOf('day').format(),
    });

    if (response && response.data) {
      const dates = (response.data && response.data.length) ? response.data : [];
      setCalendarDates(dates);
      checkDayAvailability(dates);
    }
  };

  const fetchServiceDetail = async () => {
    setLoadingFetchServiceDetail(true);
    const detail = await dispatch.Appointments.serviceDetail(params.id);

    if (detail && detail.data) {
      if (!isDateFromNotification) {
        const nowIsBeforeScheduleStart = moment().isBefore(detail.data.periodStart);
        if (nowIsBeforeScheduleStart) {
          const newDateSelected = moment(detail.data.periodStart).format();
          fetchB2BCalendarAvailability(newDateSelected);
        } else {
          fetchB2BCalendarAvailability(moment().format());
        }
      }
    }

    setIsDateFromNotification(false);
  };

  const parseUsername = (userData) => (
    (userData.firstName
        && `${userData.firstName} ${userData.lastName}`)
        || userData.username || userData.email
  );

  const parseQueryString = async () => {
    const schedulingId = params?.schedulingId;

    const scheduleResp = await fetchScheduleB2B(schedulingId);

    if (scheduleResp && scheduleResp.data) {
      const schedule = scheduleResp.data;
      schedule.animalImgSrc = await fetchAnimalImgSrc(schedule.animal);
      const hasSchedule = Object.keys(schedule).length;

      if (hasSchedule) {
        setIsDateFromNotification(true);
        setDateSelected(schedule.eventStart);
        dispatch.Appointments.setModal(
          { attendanceModal: { open: true,
            data: {
              id: schedule?.id || '',
              animalPhoto: (schedule.animal && schedule.animalImgSrc) || '',
              tutorName: (schedule.serviceType.service?.user && parseUsername(schedule.serviceType.service.user)) || '',
              status: schedule.eventStatus || '',
              animalName: schedule && schedule.animal?.name,
              period: schedule.eventStart || '',
              notificationList: schedule.notificationList || [],
              sentDatetime: (schedule.notificationList && schedule.notificationList.length && schedule.notificationList[0].sentDatetime) || null,
              communicationChannel: (schedule.notificationList && schedule.notificationList.length && schedule.notificationList[0].communicationChannel) || 'email/whatsapp',
              serviceName: schedule.serviceType?.name,
            },
            context: 'schedule' } },
        );
      }
    }
  };

  useEffect(() => fetchServiceDetail(), []);

  useEffect(() => {
    if (params?.schedulingId) {
      parseQueryString();
    }
  }, []);

  useEffect(() => {
    fetchDayScheduleList();
  }, [dateSelected]);

  // useEffect(() => {
  //   fetchB2BCalendarAvailability(dateSelected);
  // }, [month]);

  return (
    <Card elevation={0} style={{ marginTop: '30px' }}>
      <CardContent sx={{ padding: '0px', '&.MuiCardContent-root:last-child': { paddingBottom: '0px' } }}>
        <Grid container direction='row'>
          <Grid item sx={{ width: '40%', padding: '25px 32px 38px 32px' }}>
            <Button
              size='large'
              sx={{ color: '#6B48FF', paddingRight: '0px', paddingLeft: '0px', marginBottom: '25px' }}
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push('/appointments')}
            >
              Voltar
            </Button>

            <Typography variant='h6'>
              Agenda
              {' '}
              {name}
            </Typography>

            <Grid container justifyContent='center'>
              <Grid item sx={{ marginLeft: '10px' }}>
                <Calendar
                  // value={dateSelected}
                  date={moment(dateSelected)}
                  defaultCalendarMonth={moment(dateSelected)}
                  openTo='day'
                  showDaysOutsideCurrentMonth={false}
                  outsideCurrentMonth={false}
                  onMonthChange={(monthDate) => {
                    if (!isDateFromNotification && !isLoadingFetchServiceDetail) {
                      const newDateSelected = moment(monthDate).startOf('day').format();
                      setDateSelected(newDateSelected);
                      fetchB2BCalendarAvailability(newDateSelected);
                    }
                  }}
                  minDate={moment().startOf('year')}
                  maxDate={moment().endOf('year')}
                  componentsProps={{
                    leftArrowButton: { disabled: (isDayLoading && isServiceLoading && !actualDayLoading) || isLoadingFetchServiceDetail },
                    rightArrowButton: { disabled: (isDayLoading && isServiceLoading && !actualDayLoading) || isLoadingFetchServiceDetail },
                  }}
                  loading={(isDayLoading && isServiceLoading && !actualDayLoading) || isLoadingFetchServiceDetail}
                  renderLoading={() => <Loading />}
                  renderInput={(parms) => <TextField {...parms} />}
                  shouldDisableDate={(dayDate) => {
                    const findDate = calendarDates.length && calendarDates.find((calendar) => moment(dayDate).isSame(calendar.date));

                    const isUnavailable = (findDate && findDate.isUnavailable);

                    return isUnavailable;
                  }}
                  onChange={(newDate) => {
                    const nextDate = moment(newDate).startOf('day').format();
                    if (!isDateFromNotification) {
                      setDateSelected(nextDate);
                    }
                  }}
                  renderDay={(dayDate, _value, DayComponentProps) => {
                    const findDate = calendarDates.length && calendarDates.find((calendar) => moment(dayDate).isSame(calendar.date));
                    const renderedDay = moment(dayDate).date();
                    const selected = renderedDay === moment(dateSelected).date();
                    const hasTimeAvailable = findDate && findDate.hasTimeAvailable;

                    return (
                      <Badge
                        className={'badge-'.concat(moment(dayDate).format('YYYY-MM-DD'))}
                        key={moment(dayDate).toString()}
                        overlap='circular'
                        badgeContent={hasTimeAvailable ? ' ' : undefined}
                        color='primary'
                        sx={{ '& .MuiBadge-badge': { backgroundColor: '#6B48FE' } }}
                        variant={hasTimeAvailable ? 'dot' : undefined}
                      >
                        <PickersDay sx={selected && { backgroundColor: '#6B48FE', color: 'white' }} {...DayComponentProps} />
                      </Badge>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <CardsGrid item sx={{ width: '60%', background: '#F4F4F4', minHeight: '400px', padding: '50px 32px 38px 32px', maxHeight: '100vh', overflow: 'auto' }}>
            <Typography variant='h5' sx={{ color: '#6B48FF80' }}>{titleDate}</Typography>

            {isDayLoading ? (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                sx={{ minHeight: '50vh' }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Loading />
                </Grid>
              </Grid>
            ) : (
              <Grid container direction='column' wrap='nowrap' sx={{ marginTop: '16px' }} gap={4}>
                {dayScheduledB2BListAll.length ? dayScheduledB2BListAll.map((event) => {
                  const { eventStart, eventStatus, serviceScheduling, notificationList } = event;

                  if (event && eventStatus === 'available') {
                    return (
                      <AppointmentCard
                        hasBeenScheduled={false}
                        period={eventStart}
                      />
                    );
                  }

                  if (event && eventStatus === 'unavailable') {
                    return <></>;
                  }

                  return (
                    <Grid item>
                      <AppointmentCard
                        id={(serviceScheduling && serviceScheduling.id) || ''}
                        hasBeenScheduled
                        animalId={(serviceScheduling.animal?.id) || ''}
                        animalName={(serviceScheduling.animal?.name) || ''}
                        animalPhoto={(serviceScheduling && serviceScheduling.animal && serviceScheduling.animalImgSrc) || ''}
                        tutorName={(serviceScheduling.animal?.user && parseUsername(serviceScheduling.animal.user)) || ''}
                        status={eventStatus || ''}
                        period={eventStart || null}
                        notificationList={notificationList && notificationList}
                        sentDatetime={(notificationList && notificationList.length && notificationList[0].sentDatetime) || null}
                        communicationChannel={(notificationList && notificationList.length && notificationList[0].communicationChannel) || 'email/whatsapp'}
                        serviceName={serviceScheduling.serviceType?.name}
                      />
                    </Grid>
                  );
                }) : <></>}
              </Grid>
            )}
          </CardsGrid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentScheduleCard;
