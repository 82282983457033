import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ShareOutlined } from '@mui/icons-material';
import Alert from '../../global/Alert';
import { AppointmentControl, StyledContainer, StyledTableContainer } from './style';
import { PrimaryButton } from '../../../utils/theme';
import AppointmentTableRow from './AppointmentTableRow';
import { getPublicUrlScheduleCompany } from '../../../services/generatePublicUrl';
import Loading from '../../global/Loading';

const AppointmentTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { myServiceList: myServiceListLoading } = useSelector((state) => state.Appointments.loading);
  const { myServiceList, myServiceListCount } = useSelector((state) => state.Appointments);

  const { userData } = useSelector((state) => state.User);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const isLoading = myServiceListLoading !== 'fulfilled';

  const fetchAppointmentList = async (nextPage) => {
    const response = await dispatch.Appointments.myServiceList({ pageNumber: nextPage });

    if (response && response.data) {
      setPage(nextPage);
    }
  };

  const fetchAppointmentListCount = () => {
    dispatch.Appointments.myServiceListCount();
  };

  useEffect(() => {
    fetchAppointmentList(page);
    fetchAppointmentListCount();
  }, [rowsPerPage]);

  const redirectToCreateService = () => {
    dispatch.Appointments.clearActualService();
    history.push('/appointments/create');
  };

  const copyServiceLinks = () => {
    const url = getPublicUrlScheduleCompany(userData?.id, `${userData?.firstName}${userData?.lastName}`);
    setOpen(true);
    navigator.clipboard.writeText(url);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: url } }));
  };

  return (
    <>
      <StyledContainer sx={{ width: '50%' }}>
        <AppointmentControl>
          <Button onClick={copyServiceLinks} sx={{ color: '#6B48FF' }} size='large' startIcon={<ShareOutlined sx={{ color: '#6B48FF', fill: '#6B48FF' }} />}>
            Copiar link das agendas
          </Button>
          <PrimaryButton onClick={redirectToCreateService} startIcon={<AddCircleOutlineIcon />}>
            Adicionar agenda
          </PrimaryButton>
        </AppointmentControl>
        {isLoading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            sx={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            <StyledTableContainer component={Paper} sx={{ width: '100%' }}>
              <Table sx={{ minHeight: 100 }} aria-label='customized table'>
                <TableHead>
                  <TableRow>
                    <TableCell>NOME</TableCell>
                    <TableCell>INÍCIO</TableCell>
                    <TableCell>CATEGORIA</TableCell>
                    <TableCell>AGENDAMENTOS</TableCell>
                    <TableCell>CANCELADOS</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myServiceList.length ? myServiceList.sort((a, b) => new Date(b.createDatetime) - new Date(a.createDatetime)).map(({ id, ...props }) => (
                    <AppointmentTableRow
                      key={id}
                      id={id}
                      {...props}
                    />
                  )) : <></>}
                </TableBody>
              </Table>
              <TablePagination
                component='div'
                count={myServiceListCount}
                labelRowsPerPage='Linhas por página'
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={({ from, to, count: labelCount }) => (`${from}–${to} de ${labelCount !== -1 ? labelCount : `maior que ${to}`}`)}
                onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                page={page}
                onPageChange={(event, nextPage) => fetchAppointmentList(nextPage)}
              />
            </StyledTableContainer>
          </>
        )}
      </StyledContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose}>
            Link da agenda copiado com sucesso
          </Alert>
        </div>
      </Snackbar>
    </>
  );
};

export default AppointmentTable;
