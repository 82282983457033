import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import { AnimatedNotificationsIcon, Container, StyledBadge } from './style';

const NotificationBadge = () => {
  const { count } = useSelector((state) => state.Notification.summary) || {};
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isNotificationRoute = location.pathname.includes('/notifications');

  const onClickBadge = () => {
    dispatch.Notification.clearSummary();
    Mixpanel.track(MIXPANEL_TAGS.NOTIFICATION_MENU);
    history.push('/notifications');
  };

  return (
    <Container type='button' onClick={onClickBadge}>
      <StyledBadge
        $warningSeverity='warning'
        $active={isNotificationRoute}
        badgeContent={count}
      >
        <AnimatedNotificationsIcon ringing={count > 0 && !isNotificationRoute} />
      </StyledBadge>
    </Container>
  );
};

export default NotificationBadge;
