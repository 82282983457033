import React, { useEffect } from 'react';

import LandingView from '../../components/LandingView';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { isAndroidBrowser, isIOSBrowser, isNative, STORE_ANDROID, STORE_IOS } from '../../utils/mobile';

const LandingPage = () => {
  const appUrl = process.env.REACT_APP_WEB;
  const isAppUrl = window.location.href.includes(appUrl);

  useEffect(() => {
    // Mixpanel.track(MIXPANEL_TAGS.LANDING_VIEW);

    if (isAppUrl && !isNative) {
      if (isAndroidBrowser) {
        window.location.href = STORE_ANDROID;
        Mixpanel.track(MIXPANEL_TAGS.LANDING_PLAY_STORE_CLICK);
      } else if (isIOSBrowser) {
        window.location.href = STORE_IOS;
        Mixpanel.track(MIXPANEL_TAGS.LANDING_APP_STORE_CLICK);
      }
    }
  }, []);

  return <LandingView />;
};

export default LandingPage;
