import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Fade, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { MainWrapper } from './style';
import Footer from '../../components/Footer';
import { IntercomCustom } from '../../utils/intercom';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import ServiceTypeCard from '../../components/Services/ServicesDashboard/ServiceTypeCard';
import TelemedicineClickToCall from '../../components/Services/TelemedicineClickToCall';
import UnattendedCallDialog from './UnattendedCallDialog';
import { getParsedQueryString } from '../../utils/querystring';
import TriageAlertDialog from './TriageAlertDialog';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
// import { isNative } from '../../utils/mobile';

const Telemedicine = () => {
  const history = useHistory();
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);
  const lastCompleteTriageDatetime = useSelector((state) => state.User.userData.lastCompleteTriageDatetime);
  const telemedicineCategory = 'Guiavet Telemedicina';
  const [modalOpen, setModalOpen] = useState(false);
  const [triageModalOpen, setTriageModalOpen] = useState(false);

  const handleCall = () => {
    if (lastCompleteTriageDatetime) {
      const lastCompleteLimit = moment(lastCompleteTriageDatetime)
        .utcOffset(0)
        .add(24, 'hours')
        .format('YYYY-MM-DD[T]HH:mm:ss[-03:00]');

      const now = moment();

      const userHasCompletedTriageRecently = moment(lastCompleteLimit).isAfter(now);

      if (userHasCompletedTriageRecently) {
        history.push('/services/telemedicine-call');
      } else {
        setTriageModalOpen(true);
      }
    } else {
      setTriageModalOpen(true);
    }
  };

  const openTextTelemedicineChat = () => {
    if (isDefaultPlan === false) {
      window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
      IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });
      Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
      // if (isNative) {
      //   return false;
      // }
      return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);
    }

    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
    return history.push('/pricing');
  };

  // const redirectToServicePage = (category) => {
  //   const parseCategoryToUrl = category.trim().toLowerCase().replaceAll(' ', '-');
  //   history.push(`/services/partners/${parseCategoryToUrl}`);
  // };

  const openVideoTelemedicineChat = () => {
    if (isDefaultPlan === false) {
      window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
      IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });
      Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
      // if (isNative) {
      //   return false;
      // }
      return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);
    }

    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
    return history.push('/pricing');
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.TELEMEDICINE_VIEW);

    if (isDefaultPlan === false) {
      history.push('/pricing');
    }

    const querystring = getParsedQueryString(location.search);

    if (querystring?.unattendedCall) {
      setModalOpen(true);
    }
  }, []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <TelemedicineClickToCall handleCall={handleCall} />
      <TriageAlertDialog open={triageModalOpen} setModalOpen={setTriageModalOpen} />
      <UnattendedCallDialog open={modalOpen} setModalOpen={setModalOpen} />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px', paddingBottom: '80px' }}
        >
          <Typography color='rgba(77, 78, 108, 1)' variant='h6' sx={{ margin: '12px 0px', marginTop: '32px' }}>Agende seu atendimento</Typography>
          <Fade in>
            <Grid item sx={{ marginBottom: '10px' }} onClick={openTextTelemedicineChat}>
              <ServiceTypeCard telemedicine='text' category={telemedicineCategory} />
            </Grid>
          </Fade>
          <Fade in>
            <Grid item sx={{ marginBottom: '35px' }} onClick={openVideoTelemedicineChat}>
              <ServiceTypeCard telemedicine='video' category={telemedicineCategory} />
            </Grid>
          </Fade>
        </Grid>
      </Grid>
      <Footer />
    </MainWrapper>
  );
};

export default Telemedicine;
