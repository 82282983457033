/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PetName, Specie, MobileHealthControlButton, PetContainer, PetInfo, StyledAvatar } from './style';
import placeholderDog from '../../../../assets/images/placeholder-dog.svg';
import placeholderCat from '../../../../assets/images/placeholder-cat.svg';
import { ReactComponent as HealthIcon } from '../../../../assets/images/PetCard/HealthControl.svg';

const PetHeader = ({ id, breed, name: petName, avatarImage, user }) => {
  const dispatch = useDispatch();
  const placeholder = breed && breed.species.toLowerCase() === 'dog' ? placeholderDog : placeholderCat;
  const history = useHistory();

  const [animalImgSrc, setAnimalImgSrc] = useState(null);

  const fetchAnimalImage = async () => {
    const response = await dispatch.Photo.getPhoto(avatarImage);
    setAnimalImgSrc(response);
  };

  useEffect(() => {
    if (avatarImage) {
      fetchAnimalImage();
    } else {
      setAnimalImgSrc(null);
    }
  }, [avatarImage]);

  const redirectPetPage = () => {
    history.push(`/pet/${id}`);
  };

  return (
    <PetContainer>
      <StyledAvatar src={animalImgSrc || placeholder} />
      <PetInfo>
        <PetName>
          {petName}
        </PetName>
        <Specie>
          {(user && (user.firstName || user.username || user.email)) || ''}
        </Specie>
        <MobileHealthControlButton onClick={redirectPetPage} startIcon={<HealthIcon />}>Controle da saúde</MobileHealthControlButton>
      </PetInfo>
    </PetContainer>
  );
};

export default PetHeader;
