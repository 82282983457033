/* eslint-disable no-unused-vars */
import { Autocomplete, Avatar, FormControl, Grid, IconButton, InputLabel, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { AttachFile } from '@mui/icons-material';
import { DateTimePicker } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { HelperText, ResponsibleSelect } from './style';
import { convertBase64, resizeImage, validateImage } from '../../../../utils/imageUtils';
import CancelOrSubmit from '../../../global/CancelOrSubmit';
import { datePickerErrorStyle } from '../../../../utils/theme';
import useWindowSize from '../../../../hooks/windowSize';
import { isNative } from '../../../../utils/mobile';

const FoodSnacks = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const screen = useWindowSize();
  const isMobile = screen.height < 700;

  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.protocolApplyId);
  const editLastDoseDate = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastDoseDate);
  const editNextDoseDate = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.nextDoseDate);
  const editLastAmountPerDay = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastAmountPerDay);
  const productRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productRequired);
  const responsibleRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.responsibleRequired);
  const productVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productVisible);
  const responsibleVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.responsibleVisible);
  const lastDoseName = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastDoseName);
  const type = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.type);
  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [photoBase64, setPhotoBase64] = useState('');
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const openDialog = useSelector((state) => state.Modal.protocolDialog?.open);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');
  const b2bFollowers = useSelector((state) => state.User.b2bFollowers);
  const [b2bFollowersAll, setB2bFollowersAll] = useState([]);
  const { id: loggedUserId } = useSelector((state) => state.User.userData);
  const products = useSelector((state) => state.Animals.actualPet.protocols.products) || [];
  const [productPlaceholder, setProductPlaceholder] = useState('Digite o nome do produto *');
  const [openLastDosePicker, setOpenLastDosePicker] = useState(false);
  const [openNextDosePicker, setOpenNextDosePicker] = useState(false);
  const [protocolProducts, setProtocolProducts] = useState(products);
  const [responsible, setResponsible] = useState((b2bFollowers && b2bFollowers.length === 1 && b2bFollowers[0].id === loggedUserId && b2bFollowers[0].id) || 'empty');
  const productOptions = protocolProducts.map((product) => ({ label: product.name, id: product.id }));

  const fetchProfileImage = async () => {
    const profiles = [...b2bFollowers];
    await Promise.all(profiles.map(async (profile) => {
      // eslint-disable-next-line no-param-reassign
      profile.profileImgSrc = await dispatch.Photo.getPhoto(profile.profileImage);
    }));
    setB2bFollowersAll(profiles);
  };
  const typeLabel = type && type === 'food' ? 'Quantidade por dia (g)' : 'Unidade';
  const typePlaceholder = type && type === 'food' ? 'Digite a quantidade por dia' : 'Digite a unidade';
  const typeValidation = type && type === 'food' ? 'Favor digitar a quantidade' : 'Favor digitar a unidade';
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!b2bFollowers?.length && fetchProfileImage();
  }, [b2bFollowers]);

  const fetchProducts = async (productSearch) => {
    const requestAnimalProducts = await dispatch.Animals.getAnimalProtocolProducts({ protocolId: id, name: productSearch });
    if (requestAnimalProducts && requestAnimalProducts.data) {
      setProtocolProducts(requestAnimalProducts.data);
    }
  };

  const handleProductTextChange = async (event, setFieldValue) => {
    setFieldValue('product', event.target.value);
    await fetchProducts(event.target.value);
  };

  const handleProductOptionChange = (event, setFieldValue) => {
    setFieldValue('product', event);
  };

  const handleResponsible = (event, setFieldValue) => {
    const { value } = event.target;
    setResponsible(value);
    setFieldValue('responsibleId', value);
  };

  const submitForm = async (formData, { setErrors }) => {
    // setLoading(true);
    const parseProduct = () => {
      const productsName = protocolProducts.map(((product) => product.name)) || [];
      const productsId = protocolProducts.map(((product) => product.id)) || [];
      const curatedProduct = protocolProducts.find((product) => product.name === formData.product);
      const parsedProduct = (formData.product && formData.product.id) || formData.product;
      const isCurated = Number(parsedProduct)
        ? productsId.includes(Number(parsedProduct))
        : productsName.includes(parsedProduct);
      const productId = { productId: curatedProduct && curatedProduct.id ? curatedProduct.id : parsedProduct };
      const productName = { productName: parsedProduct && parsedProduct.label ? parsedProduct.label : parsedProduct };
      const product = isCurated ? productId : productName;
      return product;
    };

    const { responsibleId, lastDoseDate, nextDoseDate, amountPerDay } = formData;

    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });
      const product = formData.product && parseProduct();
      const payload = {
        protocolId: id,
        ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
        ...(responsibleId && responsibleId !== 'empty' && { responsibleId }),
        ...(product && product),
        ...(nextDoseDate && { expirationDatetime: moment(nextDoseDate).toISOString() }),
        amountPerDay,
        appliedDatetime: moment(lastDoseDate).toISOString(),
        ...(photoBase64 && { photoBase64 }),
      };

      const requestApplyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);
      if (requestApplyAnimalProtocol && requestApplyAnimalProtocol.data) {
        dispatch.Modal.setDialog({ open: false });
        const protocol = requestApplyAnimalProtocol.data[0];
        if (protocol.showVaccinationCard && protocol.signatureRequired && !protocol.signatureRequested && isNative) {
          dispatch.Modal.setRequestSignatureOnAppModal({ open: true, protocolId: protocol.id });
        }
        if (protocol.showVaccinationCard && !protocol.signatureRequired && protocol.backToVaccineCard && isNative) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
        }
      }
      // setLoading(false);
    } else {
      setErrors({ photoName: true });
      // setLoading(false);
    }
  };

  const handleUpload = () => document.getElementById('imgupload').click();

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;
    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const base64 = await convertBase64(rawFile);
      setPhotoBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);
      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setPhotoBase64(base64);
      }
    }
  };

  useEffect(() => {
    if (openDialog) {
      fetchProducts('');
    }

    return () => {
      dispatch.Animals.clearProtocolProducts();
    };
  }, [openDialog]);

  return (
    <Grid sx={{ width: '100%' }}>
      <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => uploadImage(e)} style={{ display: 'none' }} />
      <Grid container flexDirection='column' justifyContent='flex-start'>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={
            (!isProtocolBeingEdited && { lastDoseDate: null, nextDoseDate: null, responsibleId: responsible }) || {
              lastDoseDate: editLastDoseDate, nextDoseDate: editNextDoseDate, amountPerDay: editLastAmountPerDay, product: { label: lastDoseName }, responsibleId: responsible,
            }
          }
          onSubmit={submitForm}
          validationSchema={
            Yup.object().shape({
              product: productRequired ? Yup.mixed().required('Favor digitar o nome do produto') : Yup.mixed(),
              lastDoseDate: Yup.string().nullable().required('Favor selecionar a data da última aplicação'),
              responsibleId: responsibleRequired && (b2bFollowers && b2bFollowers.length) ? Yup.string().required('Favor selecionar o responsável') : Yup.string(),
              photoBase64: Yup.string(),
              nextDoseDate: Yup.string().nullable(),
              amountPerDay: Yup.number().nullable().required(typeValidation),
            })
          }
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              {productVisible && (
                <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                  <Autocomplete
                    blurOnSelect='touch'
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    disablePortal
                    id='product'
                    name='product'
                    defaultValue={values.product}
                    onFocus={() => setProductPlaceholder('Produto *')}
                    onBlur={() => !values.product && setProductPlaceholder('Digite o nome do produto')}
                    error={!!errors.product}
                    openOnFocus
                    freeSolo
                    onChange={(e, value) => handleProductOptionChange(value, setFieldValue)}
                    options={productOptions || []}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        id='product'
                        name='product'
                        error={!!errors.product}
                        onChange={(event) => handleProductTextChange(event, setFieldValue)}
                        label={productPlaceholder}
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              )}

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                <DateTimePicker
                  open={openLastDosePicker}
                  id='lastDoseDate'
                  name='lastDoseDate'
                  label='Data e hora de início'
                  format='DD/MM/YYYY'
                  openTo='hours'
                  allowSameDateSelection
                  defaultValue={null}
                  maxDate={moment(new Date())}
                  value={values.lastDoseDate}
                  onChange={(value) => setFieldValue('lastDoseDate', value)}
                  onClose={() => setOpenLastDosePicker(false)}
                  renderInput={(params) => (
                    <TextField
                      sx={errors.lastDoseDate ? datePickerErrorStyle : {}}
                      onClick={() => setOpenLastDosePicker(true)}
                      error={!!errors.lastDoseDate}
                      autoComplete='off'
                      touched={touched.lastDoseDate}
                      helperText={errors.lastDoseDate && errors.lastDoseDate}
                      {...params}
                    />
                  )}
                />
              </FormControl>

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <TextField
                  id='amountPerDay'
                  type='number'
                  name='amountPerDay'
                  label={typeLabel}
                  placeholder={typePlaceholder}
                  error={!!errors.amountPerDay}
                  autoComplete='off'
                  helperText={errors.amountPerDay && errors.amountPerDay}
                  value={values.amountPerDay}
                  onChange={handleChange}
                  fullWidth
                />
                {type && type === 'food' && (
                  <HelperText>
                    Consultar na embalagem do produto
                  </HelperText>
                )}
              </FormControl>

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <TextField
                  value={filename}
                  error={!!errors.photoName}
                  touched={touched.photoName}
                  onClick={handleUpload}
                  id='photoName'
                  name='photoName'
                  label='Imagem'
                  InputProps={{
                    readOnly: true,
                    endAdornment:
  <IconButton edge='end'>
    <AttachFile />
  </IconButton>,
                  }}
                />
              </FormControl>

              {responsibleVisible && (
              <FormControl error={!!errors.responsibleId} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel id='demo-simple-select-helper-label'>Responsável</InputLabel>
                <ResponsibleSelect
                  id='responsibleId'
                  name='responsibleId'
                  value={responsible}
                  label='Responsável'
                  onChange={(value) => handleResponsible(value, setFieldValue)}
                  onBlur={handleBlur}
                  touched={touched.responsibleId}
                >
                  <MenuItem value='empty' style={{ height: '60px' }}>Nenhum</MenuItem>
                  {b2bFollowersAll && b2bFollowersAll.map((follower) => (
                    <MenuItem value={follower.id}>
                      <Avatar
                        alt={follower.firstName}
                        src={follower.profileImage && follower.profileImgSrc}
                        sx={{ marginRight: '5px' }}
                      />
                      <div>{(follower.firstName && `${follower.firstName} ${follower.lastName}`) || follower.username || follower.email}</div>
                    </MenuItem>
                  ))}
                </ResponsibleSelect>
              </FormControl>
              )}

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                <DateTimePicker
                  open={openNextDosePicker}
                  id='nextDoseDate'
                  name='nextDoseDate'
                  label='Próxima data e hora'
                  allowSameDateSelection
                  value={values.nextDoseDate}
                  onChange={(value) => setFieldValue('nextDoseDate', value)}
                  onClose={() => setOpenNextDosePicker(false)}
                  format='DD/MM/YYYY'
                  PopperProps={{
                    placement: isMobile ? 'top' : 'bottom',
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={errors.nextDoseDate ? datePickerErrorStyle : {}}
                      id='nextDoseDate'
                      name='nextDoseDate'
                      autoComplete='off'
                      error={!!errors.nextDoseDate}
                      touched={touched.nextDoseDate}
                      helperText={errors.nextDoseDate && errors.nextDoseDate}
                      onClick={() => setOpenNextDosePicker(true)}
                      {...params}
                    />
                  )}
                />
              </FormControl>

              <CancelOrSubmit loading={loading === 'loading'} />
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default FoodSnacks;
