/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { TextField, InputLabel, FormControl, MenuItem, Grid, Autocomplete, Chip, Avatar, FormControlLabel, RadioGroup, Radio, Select, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import InputMask from 'react-input-mask';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from '@mui/lab/DatePicker';
import Alert from '../../../global/Alert';
import { ModalBox, CloseModal, Form, ButtonControl, ButtonModal, PetAvatar } from './style';
import { getPetPlaceholder } from '../../../../services/api';
import { formatAge } from '../../../../utils/format';
import { validateSymbols } from '../../../../utils/validators';
import Loading from '../../../global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';
import theme, { datePickerErrorStyle } from '../../../../utils/theme';

const InviteContent = () => {
  const dispatch = useDispatch();

  const { email: loggedUserEmail, phone: loggedUserPhone, isB2B } = useSelector((state) => state.User.userData) || {};
  const animalToTransferList = useSelector((state) => state.Network.animalToTransferList) || [];
  const firstFieldRef = useRef(null);
  const [contactData, setContactData] = useState({});
  const [showDate, setShowDate] = useState(false);
  const [submitedSuccess, setSubmitedSuccess] = useState(true);
  const [submitedStatus, setSubmitedStatus] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Falha ao convidar contato');
  const title = 'Enviar convite';
  const initialValues = { name: '', email: '', phone: '', type: 'T', animalId: '', scheduledDate: new Date().toISOString(), scheduledHour: new Date().getHours() + 1 };

  const stringifiedHours = [...Array(24)].map((a, index) => String(index).padStart(2, 0));

  const [animalsWithPhoto, setAnimalsWithPhoto] = useState([]);

  const fetchAnimalImage = async () => {
    const animalList = await Promise.all(animalToTransferList.map(async (animal) => {
      const placeholder = getPetPlaceholder(animal);
      const petAvatar = (await dispatch.Photo.getPhoto(animal.avatarImage)) || placeholder;
      return { ...animal, parsedPhoto: petAvatar, parsedName: `${animal.name} - ${formatAge(animal.birthdate)}` };
    }));
    setAnimalsWithPhoto(animalList);
  };

  useEffect(() => {
    !!animalToTransferList?.length && fetchAnimalImage();
  }, [animalToTransferList]);

  useEffect(() => dispatch.Network.updateAnimalToTransferList(), []);

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  const handleSubmitInvite = async (formData, { setErrors }) => {
    Mixpanel.track(MIXPANEL_TAGS.SEND_INVITE_MODAL_CLICK, { formData });

    if (formData?.scheduled && new Date(formData?.scheduledDate).getDate() < new Date().getDate()) {
      setSubmitedSuccess(true);
      return;
    }

    if (formData?.scheduled && new Date(formData?.scheduledDate).getDate() === new Date().getDate() && new Date().getHours() === Number(formData?.scheduledHour)) {
      setSubmitedSuccess(true);
      return setErrors({ scheduledHour: 'Hora inválida' });
    }

    if (formData?.scheduled && new Date(formData?.scheduledDate).getDate() === new Date().getDate() && Number(formData?.scheduledHour) < new Date().getHours()) {
      setSubmitedSuccess(true);
      return setErrors({ scheduledHour: 'Hora menor que a hora do dia atual' });
    }

    if (formData?.scheduled) {
      Mixpanel.track(MIXPANEL_TAGS.INVITE_SENT_SCHEDULED, { formData });
    } else {
      Mixpanel.track(MIXPANEL_TAGS.INVITE_SENT_NOW, { formData });
    }

    setSubmitedSuccess(false);

    const { name, email, phone, animalId, scheduled, scheduledDate, scheduledHour } = formData;
    setContactData(formData);

    const formattedPhone = phone ? phone.replace(/[^A-Z0-9]/ig, '') : '';

    const ownerOfEmail = loggedUserEmail === email;
    const ownerOfPhone = Number(formattedPhone) === Number(loggedUserPhone);
    const userHasSamePhone = phone && loggedUserPhone && ownerOfPhone;

    if (ownerOfEmail) {
      setErrorMessage('O e-mail informado no convite é o seu próprio e-mail, informe outro e-mail');
      setSubmitedStatus('error');

      return setSubmitedSuccess(true);
    }

    if (userHasSamePhone) {
      setErrorMessage('O telefone informado no convite é o seu próprio telefone, informe outro telefone');
      setSubmitedStatus('error');

      return setSubmitedSuccess(true);
    }

    const animalIds = animalId && animalId.length ? [...new Set(animalId.map((animal) => animal.id))] : [];

    const payload = {
      name,
      email,
      phone: formattedPhone,
      userType: 'T',
      animalId: animalIds,
      scheduled: Boolean(scheduled),
      ...(scheduled && scheduledDate && scheduledHour && { sendDatetime: moment(scheduledDate).set('hour', Number(scheduledHour)).set('minute', 0).set('second', 0)
        .set('millisecond', 0)
        .toISOString() }),
    };

    const responseInvite = await dispatch.Network.sendInvite(payload);

    if (responseInvite && responseInvite.data) {
      dispatch.Network.updateNetworkListInvite(responseInvite.data);

      setSubmitedStatus('success');

      setSubmitedSuccess(true);

      dispatch.Network.setModal({ networkModal: { open: true, context: 'alert', data: {} } });
    }

    if (responseInvite.response.data.message === 'you cannot send the invite to this user') {
      setErrorMessage('Você não pode convidar esse usuário pois ele já possui uma assinatura ativa. Entre em contato conosco para mais informações.');
      setSubmitedStatus('error');
      setSubmitedSuccess(true);

      return setTimeout(() => setSubmitedStatus(''), 8000);
    }

    if (responseInvite.response.data.message === 'you already have a connection with this email') {
      setErrorMessage('Você já está conectado com este contato, favor preencher o convite com informações de outra pessoa');
      setSubmitedStatus('error');
      setSubmitedSuccess(true);

      return setTimeout(() => setSubmitedStatus(''), 8000);
    }

    if (responseInvite.response.data.message === 'you have already sent an invite to this user and it has not responded yet') {
      setErrorMessage('Você já enviou um convite para esse contato e ele ainda não respondeu. Favor aguardar resposta ou preencher com outras informações');
      setSubmitedStatus('error');
      setSubmitedSuccess(true);

      return setTimeout(() => setSubmitedStatus(''), 8000);
    }

    setSubmitedStatus('error');
    setSubmitedSuccess(true);

    return setTimeout(() => setSubmitedStatus(''), 8000);
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.INVITE_MODAL_LOAD);
  }, []);

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Typography id='enviar-convite' variant='h6' component='h2'>
          {title}
        </Typography>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          onSubmit={handleSubmitInvite}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
              type: Yup.string(),
              email: Yup.string().nullable()
                .when('phone', {
                  is: (phone) => !phone || phone.length === 0,
                  then: Yup.string().email('Favor preencher com um e-mail válido')
                    .required('Favor preencher pelo menos o e-mail ou o telefone'),
                }),
              phone: Yup.string()
                .when('email', {
                  is: (email) => !email || email.length === 0,
                  then: Yup.string()
                    .required('Favor preencher pelo menos o e-mail ou o telefone'),
                }),
            }, ['email', 'phone'])
}
        >
          {({ handleChange, setFieldValue, handleSubmit, handleBlur, values, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                <TextField
                  inputRef={firstFieldRef}
                  onChange={handleChange}
                  value={values.name}
                  error={!!errors.name}
                  helperText={errors.name && errors.name}
                  touched={touched.name}
                  sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  autoComplete='off'
                  label='Nome'
                  id='name'
                  name='name'
                />

                <TextField
                  onChange={handleChange}
                  value={values.email}
                  error={!!errors.email}
                  helperText={errors.email && errors.email}
                  touched={touched.email}
                  sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                  autoComplete='off'
                  label='E-mail'
                  id='email'
                  name='email'
                />

                <InputMask
                  mask='\(99\) 9 9999-9999'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  disabled={false}
                  maskplaceholder='_'
                >
                  {(inputProps) => (
                    <TextField
                      error={!!errors.phone}
                      helperText={errors.phone && errors.phone}
                      touched={touched.phone}
                      sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      label='Celular'
                      id='phone'
                      name='phone'
                      type='tel'
                      {...inputProps}
                    />
                  )}
                </InputMask>
                <FormControl sx={{ marginTop: 3, display: isB2B ? 'flow' : 'none' }} fullWidth>
                  <InputLabel id='transferPet' />
                  <Autocomplete
                    multiple
                    id='animalId'
                    name='animalId'
                    disabled={!animalToTransferList.length}
                    options={animalsWithPhoto}
                    onChange={(event, value) => {
                      setFieldValue('animalId', value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <MenuItem {...props} value={option.id}>
                        <PetAvatar src={option.parsedPhoto} alt='petPhoto' />
                        <div style={{ marginRight: '10px' }}>{option.name}</div>

                        {option.birthdate ? (
                          <>
                            -
                            <div>&nbsp;&nbsp;</div>
                            <div>
                              {formatAge(option.birthdate)}
                              {' '}
                              anos
                            </div>
                          </>
                        ) : <></>}
                      </MenuItem>
                    )}
                    renderTags={(value, getTagProps) => value.map(({ name, parsedPhoto }, index) => (
                      <Chip
                        avatar={<Avatar alt='pet' src={parsedPhoto} />}
                        variant='outlined'
                        label={name}
                        sx={{ border: 'dashed', borderWidth: '2px', borderColor: theme.GUIAVET }}
                        {...getTagProps({ index })}
                      />
                    ))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        label={animalToTransferList && animalToTransferList.length > 0 ? 'Transferir Pet' : 'Não há pets para transferência'}
                        variant='outlined'
                        placeholder={animalToTransferList && animalToTransferList.length > 0 ? 'Transferir Pet' : 'Não há pets para transferência'}
                      />
                    )}
                  />
                </FormControl>

                <FormControl sx={{ marginTop: 3, display: isB2B ? 'flow' : 'none' }}>
                  <Grid item>
                    <Typography sx={{ fontSize: '14px' }}>Quando enviar:</Typography>
                    <RadioGroup
                      row={false}
                      name='scheduled'
                      value={values.scheduled}
                      defaultValue={false}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue('scheduled', JSON.parse(event.target.value));
                        setShowDate(!showDate);
                      }}
                    >
                      <FormControlLabel id='scheduled' name='scheduled' key={1} value={false} control={<Radio sx={{ '&.Mui-checked': { color: theme.GUIAVET } }} />} label='Enviar agora' />
                      <FormControlLabel id='scheduled' name='scheduled' key={2} value control={<Radio sx={{ '&.Mui-checked': { color: theme.GUIAVET } }} />} label='Agendar envio' />
                    </RadioGroup>
                  </Grid>
                </FormControl>

                {values.scheduled && (
                  <>
                    <Grid container flexDirection='row' justifyContent='space-between'>
                      <FormControl required error={!!errors.scheduledDate} sx={{ width: '45%', marginTop: 3, marginBottom: 1, marginRight: 1 }}>
                        <DatePicker
                          open={openDatePicker}
                          id='scheduledDate'
                          name='scheduledDate'
                          label='Data*'
                          format='DD/MM/YYYY'
                          allowSameDateSelection
                          defaultValue={null}
                          minDate={moment(new Date())}
                          value={values.scheduledDate}
                          onChange={(value) => setFieldValue('scheduledDate', value?.toISOString())}
                          onClose={() => setOpenDatePicker(false)}
                          renderInput={(params) => (
                            <TextField
                              sx={errors.scheduledDate ? datePickerErrorStyle : {}}
                              onClick={() => setOpenDatePicker(true)}
                              error={!!errors.scheduledDate}
                              autoComplete='off'
                              touched={touched.scheduledDate}
                              helperText={errors.scheduledDate && errors.scheduledDate}
                              {...params}
                            />
                          )}
                        />
                      </FormControl>

                      <FormControl required error={!!errors.scheduledHour} sx={{ width: '45%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                        <InputLabel id='demo-simple-select-helper-label'>Hora</InputLabel>
                        <Select
                          defaultValue='08'
                          id='scheduledHour'
                          name='scheduledHour'
                          required
                          label='Hora'
                          value={values.scheduledHour}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched.scheduledHour}
                        >
                          <MenuItem disabled value=''>Hora</MenuItem>
                          {stringifiedHours.map((hour) => (
                            <MenuItem key={hour} value={hour}>
                              {hour}
                              :00
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Typography variant='body1' sx={{ color: theme.DARK_PLATINUM, fontSize: '14px' }}>
                      O envio pode sofrer uma diferença de até 30 minutos do horário escolhido
                    </Typography>
                  </>
                )}

                <ButtonControl>
                  {!submitedSuccess ? (
                    <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <Loading />
                    </Grid>
                  ) : (
                    <>
                      <ButtonModal type='button' style={{ marginRight: '42px' }} onClick={closeModal}>Cancelar</ButtonModal>
                      <ButtonModal mainButton type='submit'>Enviar convite</ButtonModal>
                    </>
                  )}

                </ButtonControl>
              </div>

            </Form>
          )}
        </Formik>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Snackbar
          open={!!submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
          sx={{ display: !submitedStatus ? 'none' : 'flex' }}
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                {contactData.name || contactData.email || contactData.phone || 'Contato'}
                {' '}
                convidado(a) com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                {errorMessage}
              </Alert>
            </div>
          )}
        </Snackbar>
      </Grid>

    </>
  );
};

export default InviteContent;
