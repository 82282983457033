import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';

import TelemedicineWaitingCall from '../../components/Services/TelemedicineWaitingCall';
import TelemedicineIframeCall from '../../components/Services/TelemedicineIframeCall';
import TelemedicineModalRequestPermissions from '../../components/Services/TelemedicineModalRequestPermissions';
import CancelCallDialog from '../../components/Services/TelemedicineWaitingCall/CancelCallDialog';
import ErrorTriageDialog from '../../components/Services/TelemedicineWaitingCall/ErrorTriageDialog';
import { isAuthenticated } from '../../services/api';
import PublicDashboardHeader from '../../components/Dashboard/PublicDashboardHeader';
import { getParsedQueryString } from '../../utils/querystring';
import Loading from '../../components/global/Loading';
import { isNative } from '../../utils/mobile';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Header = () => (isAuthenticated() ? (
  <>
    <DashboardHeader />
    <DashboardMenu />
  </>
) : <PublicDashboardHeader />);

const TelemedicineCall = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(true);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [statusCallWaiting, setStatusCallWaiting] = useState(true);
  const [queuePosition, setQueuePosition] = useState(0);
  const [hasPermissions, setHasPermissions] = useState({ cam: 'prompt', mic: 'prompt', camBlocked: false, micBlocked: false });
  const [urlRoom, setUrlRoom] = useState(null);
  const [isErrorTriage, setIsErrorTriage] = useState(false);
  const [messageErrorTriage, setErrorMessageTriage] = useState({ title: '', subtitle: '', showButtonTryAgain: true });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const type = location.pathname === '/services/triage-call' ? null : useSelector((state) => state.User.userData.signingPlan?.type);
  let callSong = new Audio('https://public-guiavet.s3.amazonaws.com/songs/song-twilio-no-voice.mp3');
  let unattendedCallTimeout;

  const isPublicRoute = location.pathname === '/public-services/triage-call';

  const requestMicAndCamera = () => {
    if (navigator?.mediaDevices) {
      const options = { video: true, audio: true };
      navigator.mediaDevices?.getUserMedia(options).then(() => {
        setIsLoading(false);
        setHasPermissions({ cam: 'granted', mic: 'granted' });
      }).catch(() => {
        setIsLoading(false);
        setHasPermissions({ cam: 'denied', mic: 'denied' });
      });
    }
  };

  const handleUnattendedCall = () => {
    if (statusCallWaiting && queuePosition === 1) {
      history.push('/services');
    }
  };

  const removeIframeNuvidio = () => {
    const iframe = document.querySelector('.nuvidio-iframe');
    if (iframe) {
      iframe.remove();
    }
    setUrlRoom(null);
    dispatch.User.setVideoConferenceData({});
  };

  const handleCancelCall = () => {
    setCancelModalOpen(true);
  };

  const handleFinalizateCall = async () => {
    removeIframeNuvidio();

    if (type === 'one_time' && !isPublicRoute) {
      await dispatch.User.vetQuestionUse();
    }

    if (isIOS || isMacOs || isSafari) {
      window.location.assign(`${process.env.REACT_APP_DYNAMIC_LINK}/services`);
    } else {
      const native = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('Android');
      window.open(`${process.env.REACT_APP_DYNAMIC_LINK}/services`, !native ? '_self' : '_blank');
    }
  };

  const handlePressOpenConfig = () => {
    window.ReactNativeWebView?.postMessage('openSettingsAndroid');
  };

  const handlePressRequestPermission = () => {
    window.ReactNativeWebView?.postMessage('requestAndroidPermissions');
  };

  useEffect(() => {
    const eventListener = (event) => {
      if (event.data && event.data.type === 'ANDROID_BACKGROUND_MODE') {
        if (statusCallWaiting) {
          history.push('/services');
        }
      }
    };
    window.addEventListener('message', eventListener);
    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [statusCallWaiting]);

  useEffect(() => {
    if (statusCallWaiting && queuePosition === 1) {
      clearTimeout(unattendedCallTimeout);
      unattendedCallTimeout = setTimeout(() => handleUnattendedCall(), 600000);
    }

    return () => {
      clearTimeout(unattendedCallTimeout);
    };
  }, [statusCallWaiting, queuePosition]);

  const handleOpenNewFlowTriage = () => {
    history.push('/login?openbot=chat-triage-app');
  };

  useEffect(() => {
    const playCallSong = async () => {
      callSong = new Audio('https://public-guiavet.s3.amazonaws.com/songs/song-twilio-no-voice.mp3');
      callSong.volume = 0.2;
      callSong.loop = true;
      callSong.play();
    };

    const getUrlTelemedicineCall = async () => {
      const responseUrl = await dispatch.User.getUrlTelemedicineCall();

      if (responseUrl && responseUrl.data) {
        setUrlRoom(responseUrl.data.videoconferenceLink);
        dispatch.User.setVideoConferenceData({
          url: responseUrl.data.videoconferenceLink,
          department: '',
        });
      }
    };

    const getUrlTriageCall = async (_token) => {
      const responseUrl = await dispatch.User.getUrlTriageCall(_token);

      if (responseUrl && responseUrl.data) {
        setUrlRoom(responseUrl.data.videoconferenceLink);
        dispatch.User.setVideoConferenceData({
          url: responseUrl.data.videoconferenceLink,
          department: '',
        });
      }

      if (responseUrl && responseUrl?.response?.data?.message === 'invalid token') {
        setErrorMessageTriage({ title: 'Sua chamada expirou!', subtitle: 'Faça outro novo fluxo de triagem', showButtonTryAgain: true });
        setIsErrorTriage(true);
        dispatch.User.setBotToOpen('chat-triage-app');
      }

      if (responseUrl && responseUrl?.response?.data?.message === 'no online attendant') {
        setErrorMessageTriage({ title: 'Sem atendentes online no momento', subtitle: 'Tente novamente em outro horário', showButtonTryAgain: false });
        setIsErrorTriage(true);
        dispatch.User.setBotToOpen('chat-triage-app');
      }
    };

    const querystring = getParsedQueryString(location.search);

    if (querystring?.url) {
      setUrlRoom(querystring.url);
      dispatch.User.setVideoConferenceData({
        url: querystring.url,
        department: querystring?.department || '',
      });
    } else if (!isPublicRoute) {
      getUrlTelemedicineCall();
    }

    if (isPublicRoute) {
      if (querystring?.token) {
        getUrlTriageCall(querystring?.token);
      } else {
        setErrorMessageTriage({ title: 'Sua chamada expirou!', subtitle: 'Faça outro novo fluxo de triagem', showButtonTryAgain: true });
        setIsErrorTriage(true);
      }
    }

    playCallSong();

    if (!isNative) {
      requestMicAndCamera();
    } else {
      window.ReactNativeWebView?.postMessage('requestAndroidPermissions');
    }

    const nuvidioEvents = (event) => {
      if (event.data && event.data.type === 'CALL_STARTED') {
        callSong.pause();
        setStatusCallWaiting(false);
        if (window?.navigator?.vibrate) {
          window.navigator?.vibrate([500, 500]);
        }
      }

      if (event.data && (event.data.type === 'RATING_SENT' || event.data.type === 'CALL_FINALIZED')) {
        callSong.pause();
        handleFinalizateCall();
      }

      if (event.data && (event.data.type === 'QUEUE_POSITION_CHANGED' || event.data.type === 'GOT_IN_QUEUE')) {
        setQueuePosition(event.data.position);
      }

      if (event.data && event.data.type === 'ANDROID_CHANGE_PERMISSIONS') {
        const response = event.data;
        setHasPermissions({
          cam: response.cam === false ? 'denied' : 'granted',
          camBlocked: response.camBlocked,
          mic: response.mic === false ? 'denied' : 'granted',
          micBlocked: response.micBlocked,
        });
        setIsLoading(false);
      }

      if (event.data && event.data.type === 'ANDROID_BACK_PRESS') {
        callSong.pause();
        handleFinalizateCall();
      }
    };

    window.addEventListener('message', nuvidioEvents);

    return () => {
      window.removeEventListener('message', nuvidioEvents);
      callSong.pause();
    };
  }, []);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.TELEMEDICINE_CALL_VIEW);

    window.history.pushState(null, null, document.URL);
    const alert = () => {
      window.history.pushState(null, null, document.URL);
      window.alert('Evite sair da página durante uma ligação');
    };

    window.addEventListener('popstate', alert);

    return () => {
      window.removeEventListener('popstate', alert);
    };
  }, []);

  if (loading) {
    return (
      <MainWrapper>
        <Header />
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
        {!isPublicRoute && <Footer />}
      </MainWrapper>
    );
  }

  return (
    <>
      {!isNative ? <TelemedicineModalRequestPermissions open={hasPermissions.cam === 'denied' || hasPermissions.mic === 'denied'} isTutorial title='Acesso a câmera e microfone' desc='Permita que a Guiavet tenha acesso a sua câmera e microfone para realizar videochamadas.' /> : <TelemedicineModalRequestPermissions open={hasPermissions.cam === 'denied' || hasPermissions.mic === 'denied'} title='Acesso a câmera e microfone' desc='Permita que o aplicativo tenha acesso a sua câmera e microfone para realizar videochamadas.' isBlockedOnMobile={hasPermissions.camBlocked || hasPermissions.micBlocked} handlePressOpenConfig={handlePressOpenConfig} handlePressRequestPermission={handlePressRequestPermission} /> }
      <CancelCallDialog open={cancelModalOpen} setModalOpen={setCancelModalOpen} />
      <ErrorTriageDialog open={isPublicRoute && isErrorTriage} message={messageErrorTriage} handleOpenNewFlowTriage={handleOpenNewFlowTriage} />
      <MainWrapper>
        <div style={{ width: '100%', height: '100%', zIndex: 9, display: !statusCallWaiting ? 'none' : 'initial' }}>
          <Header />
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ marginBottom: '50px' }}
          >
            {
              hasPermissions.cam === 'granted' && hasPermissions.mic === 'granted' && !isErrorTriage && <TelemedicineWaitingCall queuePosition={queuePosition} handleCancelCall={handleCancelCall} />
            }
          </Grid>
          {!isPublicRoute && <Footer />}
        </div>

        <div style={{ width: '100%', height: '100vh', zIndex: 99999, display: statusCallWaiting ? 'none' : 'initial' }}>
          <TelemedicineIframeCall urlRoom={urlRoom} />
        </div>
      </MainWrapper>
    </>
  );
};

export default TelemedicineCall;
