import { Grid, Button, Card, TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
	padding: 30px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	@media screen and (max-width: 950px) {
		flex-flow: column;
		padding: 35px 20px 35px;
	}

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledButtonGrid = styled(Grid)`
  display: flex;
`;

export const CardWrapper = styled.div`
	line-height: 25px;
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-flow: column nowrap;
	@media screen and (max-width: 950px) {
		text-align: center;
	}
`;

export const ImageContainer = styled.div`
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		margin-right: 15px;

    img {
			margin-bottom: 15px;
      height: 237px;
			max-width: 100%;
			@media screen and (max-width: 768px) {
				width: 80%;
			}
		}
`;

export const ResendCodeButton = styled(Button)`
  &.MuiButton-root {
    color: #6B48FF;
    border: 1px solid #6B48FF;
    border-radius: 20px;
    width: 100%;

    &:disabled {
      border: 1px solid lightgrey;
    }
  }
`;

export const CodeTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 60px;
    caret-color: transparent;
  }

  & .MuiOutlinedInput-input {
    font-weight: 600;
    font-size: 24px;
    padding: 13px;

      /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`;

export const LogoContainer = styled.div`
  margin: 3vh;
`;
