import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    const conditions = [window.location?.pathname?.includes('login') || window.location?.pathname?.includes('invite-qrcode') || window.location?.pathname?.includes('invite-link')];
    const isValid = conditions.some((a) => a);
    if (!isValid) {
      window.ReactNativeWebView?.postMessage('PUBLIC_ROUTE');
    } else {
      window.ReactNativeWebView?.postMessage('USER_UNAUTHENTICATED');
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Component {...props} />
      )}
    />
  );
};

export default PublicRoute;
