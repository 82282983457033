import React from 'react';
import { Fade, Grid, Typography } from '@mui/material';

import telemedicineImg from '../../../assets/images/TelemedicineCall/telemedicine-waiting.svg';
import animationCalling from '../../../assets/images/TelemedicineCall/animation-calling.gif';
import { CancelButton } from '../../../utils/theme';

const TelemedicineWaitingCall = ({ queuePosition, handleCancelCall }) => (
  <Fade in timeout={1500}>
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <Grid
        container
        direction='column'
        sx={{ padding: '0 24px', maxWidth: '1280px' }}
      >
        <img alt='Atendimento veterinário' className='video-call' src={telemedicineImg} style={{ marginTop: '40px', marginBottom: '20px' }} />
        <Typography color='rgba(0, 0, 0, 0.38)' variant='h5' align='center' sx={{ margin: '4px 0px', fontWeight: 600 }}>
          {!queuePosition ? 'Aguarde, você será atendido em breve...' : 'Em instantes vamos te atender...'}
        </Typography>
        <Typography color='rgba(0, 0, 0, 0.38)' fontStyle='italic' align='center' sx={{ marginBottom: '14px', fontSize: '16px' }}>
          {!queuePosition ? 'Em instantes vamos te atender...' : `Sua posição na fila: ${queuePosition}°`}
        </Typography>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <img src={animationCalling} alt='animation calling' />
          <CancelButton onClick={() => handleCancelCall()} style={{ marginTop: '25px' }}>
            <Typography variant='h7' color='rgba(238, 111, 91, 1)'>Cancelar e sair da fila</Typography>
          </CancelButton>
        </Grid>
      </Grid>
    </Grid>
  </Fade>
);

export default TelemedicineWaitingCall;
