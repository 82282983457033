/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '../../../global/Alert';
import { ModalBox, CloseModal, ButtonControl, ButtonModal } from './style';
import Loading from '../../../global/Loading';
import theme from '../../../../utils/theme';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const CancelInviteContent = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.Network.modal.networkModal.data) || '';

  const [submitedStatus, setSubmitedStatus] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  const closeModal = () => {
    dispatch.Network.setModal({ networkModal: { open: false, context: '' } });
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.CANCEL_INVITE_MODAL_LOAD);
  }, []);

  const handleCancel = async () => {
    Mixpanel.track(MIXPANEL_TAGS.CANCEL_INVITE_CLICK);
    setSubmitLoading(true);

    const cancelInvite = await dispatch.Network.cancelInvite({ inviteId: id, status: 'inactive' });

    if (cancelInvite?.data) {
      dispatch.Network.removeFromNetworkList({ id });
      setSubmitedStatus('success');
      setTimeout(() => closeModal(), 1000);
    }
  };

  return (
    <>
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Grid container flexDirection='column' alignItems='center' fullWidth sx={{ marginTop: '60px' }}>
          <Grid item sx={{ marginBottom: '37px' }}>
            <PeopleOutlineIcon sx={{ width: '100px', height: '62px', fill: theme.error }} />
          </Grid>

          <Grid item sx={{ marginBottom: '55px' }}>
            <Typography variant='h5' sx={{ fontFamily: 'Inter', color: theme.MEDIUM_NAVY }}>
              Deseja cancelar o convite?
            </Typography>
          </Grid>
        </Grid>

        <ButtonControl sx={{ marginBottom: '100px' }}>
          {submitLoading ? (
            <Grid sx={{ width: '60%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '45px' }}>
              <Loading size={30} />
            </Grid>
          ) : (
            <Grid container justifyContent='space-around'>
              <ButtonModal style={{ color: theme.error, fontWeight: 500 }} type='button' onClick={closeModal}>Sair</ButtonModal>
              <ButtonModal style={{ color: theme.GUIAVET, fontWeight: 500 }} type='submit' mainButton onClick={handleCancel}>Cancelar convite</ButtonModal>
            </Grid>
          )}
        </ButtonControl>
      </ModalBox>

      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
      >
        <Snackbar
          open={submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          key='growTransition.name'
        >
          {submitedStatus === 'success' ? (
            <div>
              <Alert severity='success' sx={{ width: '100%' }}>
                Convite cancelado com sucesso
              </Alert>
            </div>
          ) : (
            <div>
              <Alert severity='error' sx={{ width: '100%' }}>
                Falha ao cancelar convite
              </Alert>
            </div>
          )}
        </Snackbar>
      </Grid>
    </>
  );
};

export default CancelInviteContent;
