/* eslint-disable no-unused-vars */
/* eslint no-underscore-dangle: "off" */
import { Mixpanel } from '../utils/mixpanel';
import { getUtms } from './api';

const actions = {
  identify: (id) => {
    // const _hsq = window._hsq || [];
    // _hsq.push(['identify'], { id });

    const utms = getUtms();
    const parsedUtms = JSON.parse(utms);
    Mixpanel.identify(id);
    Mixpanel.people.set({ ...parsedUtms, distinct_id: id });
  },
  trackPage: (data) => {
    // const _hsq = window._hsq || [];
    // _hsq.push(['setPath', data.route]);
    // _hsq.push(['trackPageView']);
  },
};

export const UtmTracker = actions;
