/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, FormControl, FormHelperText, InputLabel, MenuItem, Avatar } from '@mui/material';

import Loading from '../../../global/Loading';
import { HOME_URL } from '../../../../utils/mobile';
import { ModalBox, ButtonControl, ButtonModal, Form, ResponsibleSelect, CustomLink } from './style';

const TransferPetContent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { id: loggedUserId, native } = useSelector((state) => state.User.userData);
  const { b2bFollowing } = useSelector((state) => state.User);
  const animalId = Number(params.id);
  const [submitedSuccess, setSubmitedSuccess] = useState(true);
  const [responsibleFirstOption, setResponsibleFirstOption] = useState(undefined);

  const [b2bFollowingAll, setB2bFollowingAll] = useState([]);

  const fetchProfileImage = async () => {
    const profiles = [...b2bFollowing];
    await Promise.all(profiles.map(async (profile) => {
      // eslint-disable-next-line no-param-reassign
      profile.profileImgSrc = await dispatch.Photo.getPhoto(profile.profileImage);
    }));
    setB2bFollowingAll(profiles);
  };

  useEffect(() => {
    !!b2bFollowing?.length && fetchProfileImage();
  }, [b2bFollowing]);

  const closeModal = () => dispatch.User.setModal({ transferPetModal: { open: false, context: '' } });

  const handleTransferPet = async (formData) => {
    setSubmitedSuccess(false);

    if (formData.responsibleId !== 'empty') {
      const response = await dispatch.Animals.transferToTutor({ userId: formData.responsibleId, animalId });

      if (response && response.data) {
        setTimeout(() => history.push(HOME_URL), 3000);
      } else {
        setSubmitedSuccess(false);
      }
    }
  };

  useEffect(() => {
    const handleResponsibleFirstOption = () => {
      if (b2bFollowingAll.length === 1 && b2bFollowingAll[0].id === loggedUserId) {
        setResponsibleFirstOption(b2bFollowingAll[0].id);
      }
    };

    handleResponsibleFirstOption();
  }, []);

  return (
    <ModalBox>
      <Typography id='transferir-pet' variant='h6' component='h2'>
        Transferir pet
      </Typography>

      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{}}
        onSubmit={handleTransferPet}
        validationSchema={Yup.object().shape({
          responsibleId: Yup.string().required('Favor escolher o responsável'),
        })}
      >
        {({ handleChange, handleSubmit, handleBlur, values, touched }) => (

          <Form onSubmit={handleSubmit}>
            <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
              <InputLabel id='demo-simple-select-helper-label'>{b2bFollowingAll && b2bFollowingAll.length ? 'Selecione o responsável *' : 'Não há tutores para transferência'}</InputLabel>
              <ResponsibleSelect
                id='responsibleId'
                name='responsibleId'
                disabled={b2bFollowingAll && !b2bFollowingAll.length}
                label={b2bFollowingAll && b2bFollowingAll.length ? 'Selecione o responsável *' : 'Não há tutores para transferência'}
                defaultValue={responsibleFirstOption}
                value={values.responsibleId}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.pet}
              >
                {b2bFollowingAll && b2bFollowingAll.map((follower) => (
                  <MenuItem value={follower.id}>
                    <Avatar
                      alt={follower.firstName}
                      src={follower.profileImage && follower.profileImgSrc}
                      sx={{ marginRight: '5px' }}
                    />
                    <div>{(follower.firstName && `${follower.firstName} ${follower.lastName}`) || follower.username || follower.email}</div>
                  </MenuItem>
                ))}
              </ResponsibleSelect>
              <FormHelperText>
                Caso não tenha responsável listado,
                {' '}
                <CustomLink href={`${window.location.origin}/network`} target={native ? '_self' : '_blank'}> convide-o para sua rede clicando aqui.</CustomLink>
              </FormHelperText>
            </FormControl>

            <ButtonControl>
              {!submitedSuccess ? (
                <Grid sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '15px' }}>
                  <Loading size={30} />
                </Grid>
              ) : (
                <>
                  <ButtonModal style={{ marginRight: '25px' }} type='button' mainButton onClick={closeModal}>Cancelar</ButtonModal>
                  <ButtonModal type='submit'>Transferir pet</ButtonModal>
                </>
              )}
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </ModalBox>
  );
};

export default TransferPetContent;
