import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import OrdersDashboard from '../../components/Orders/OrdersDashboard';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Orders = () => {
  const [loading] = useState(false);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.ORDERS_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <OrdersDashboard />
      )}
      <Footer />
    </MainWrapper>
  );
};

export default Orders;
