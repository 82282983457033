import placeholderDog from '../assets/images/placeholder-dog.svg';
import placeholderCat from '../assets/images/placeholder-cat.svg';

export const getPetPlaceholder = (animal) => (animal.breed && animal.breed.species.toLowerCase() === 'dog' ? placeholderDog : placeholderCat);

export const getToken = () => localStorage.getItem('userJWT');
export const getRedirectURLFromLocalStorage = () => localStorage.getItem('redirectUrl');

export const getDeviceData = () => localStorage.getItem('dvData');

export const getUtms = () => localStorage.getItem('utms');

export const isAuthenticated = () => localStorage.getItem('userJWT') !== null;

export const isUserB2B = () => {
  const { type } = JSON.parse(localStorage.getItem('userData'));

  return type && type !== 'T';
};
