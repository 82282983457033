import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../../components/Dashboard/DashboardMenu';
import Footer from '../../../components/Footer';
import { MainWrapper } from './style';
import AppointmentInfoCard from '../../../components/Appointments/AppointmentCreate/AppointmentInfoCard';
import AppointmentAvailabilityCard from '../../../components/Appointments/AppointmentCreate/AppointmentAvailabilityCard';
import AppointmentCalendarModal from '../../../components/Appointments/AppointmentCalendarModal';
import AppointmentErrorModal from '../../../components/Appointments/AppointmentCreate/AppointmentErrorModal';
import Loading from '../../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const AppointmentCreate = () => {
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [step, setStep] = useState('info');
  const [readOnly, setReadOnly] = useState(false);

  const actualService = useSelector((state) => state.Appointments.actualService);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.APPOINTMENT_EDIT_CREATE_VIEW);

    const emptyService = !actualService.id;

    if (emptyService) {
      dispatch.Appointments.clearActualService();
    }
  }, []);

  const CreateAppointmentCard = (formikProps) => {
    switch (step) {
      case 'info':
        return (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AppointmentInfoCard readOnly={readOnly} setStep={setStep} {...formikProps} />
          </Grid>
        );
      case 'availability':
        return (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AppointmentAvailabilityCard readOnly={readOnly} setReadOnly={setReadOnly} setStep={setStep} {...formikProps} />
          </Grid>
        );
      default:
        return <div />;
    }
  };

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <AppointmentCalendarModal />
      <AppointmentErrorModal />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Container disableGutters style={{ marginBottom: 120, maxWidth: '1240px' }}>
          <Formik
            initialValues={actualService}
            onSubmit={{}}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Favor digitar o nome da agenda'),
              category: Yup.string().required('Favor selecionar uma categoria'),
              timeBetweenServicesMinutes: Yup.string(),
              periodStart: Yup.string().nullable().required('Favor preencher a data de início da agenda'),
              serviceTypes: Yup.array()
                .of(
                  Yup.object().shape({
                    name: Yup.string().required('Favor preencher o nome do evento'),
                    serviceDurationMinutes: Yup.string(),
                  }),
                )
                .required(('required-field')),
              availability: Yup.array()
                .of(
                  Yup.object().shape({
                    range: Yup.array().of(
                      Yup.object().shape({
                        period_start: Yup.string().required('Favor colocar o início do período'),
                        period_end: Yup.string().required('Favor colocar o fim do período'),
                      }),
                    ),
                  }),
                ),
            })}
          >
            {({ ...formikProps }) => (
              <Grid
                container
                justifyContent='center'
                spacing={1}
              >
                <CreateAppointmentCard {...formikProps} />
              </Grid>
            )}
          </Formik>
        </Container>
      )}

      <Footer />
    </MainWrapper>
  );
};

export default AppointmentCreate;
