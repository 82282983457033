import { Card, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { ServiceTitle, ServiceTelemedicineType, StyledGrid, ServicePhoto, ServiceTypeControl } from './style';

import hotelPhoto from '../../../../assets/images/Services/ServiceTypeCards/hotelSvg.svg';
import bathPhoto from '../../../../assets/images/Services/ServiceTypeCards/bath.png';
import dogwalkerPhoto from '../../../../assets/images/Services/ServiceTypeCards/dogwalker.png';
import healthPhoto from '../../../../assets/images/Services/ServiceTypeCards/health.png';
import petsitterPhoto from '../../../../assets/images/Services/ServiceTypeCards/petsitter.png';
import shoppingPhoto from '../../../../assets/images/Services/ServiceTypeCards/shopping.png';
import tamingPhoto from '../../../../assets/images/Services/ServiceTypeCards/taming.png';
import telemedicineTextPhoto from '../../../../assets/images/Services/ServiceTypeCards/telemedicineText.png';
import telemedicineVideoPhoto from '../../../../assets/images/Services/ServiceTypeCards/telemedicineVideo.png';
import { ReactComponent as ChatIcon } from '../../../../assets/images/Services/ServiceTypeCards/chat.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/images/Services/ServiceTypeCards/video.svg';
import { ReactComponent as ShoppingIcon } from '../../../../assets/images/Services/ServiceTypeCards/shopping.svg';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const ServiceTypeCard = ({ category, telemedicine }) => {
  const categoryMap = {
    Consulta: {
      names: ['Consultas Veterinárias'],
      photo: healthPhoto,
      color1: '#59F4E4',
      color2: '#1EE0CC',
    },
    'Banho e tosa': {
      names: ['Banho e Tosa'],
      photo: bathPhoto,
      color1: '#ABEEFF',
      color2: '#63DDFB',
    },
    Dogwalker: {
      names: ['Dog Walker'],
      photo: dogwalkerPhoto,
      color1: '#F3A976',
      color2: '#EF904F',
    },
    Adestramento: {
      names: ['Adestramento', 'Consultoria Comportamental'],
      photo: tamingPhoto,
      color1: '#91DA82',
      color2: '#7FC770',
    },
    'Day Care': {
      names: ['Hotelzinho & Creche'],
      photo: hotelPhoto,
      color1: '#EE6F5B',
      color2: '#EB5757',
    },
    Petsitter: {
      names: ['Pet Sitter'],
      photo: petsitterPhoto,
      color1: '#FADB7F',
      color2: '#F2C94C',
    },
    Promotion: {
      names: ['Preços especiais para assinantes'],
      photo: shoppingPhoto,
      color1: '#F6C2B0',
      color2: '#F6C2B0',
    },
    'Guiavet Telemedicina': {
      names: ['Teleorientação Guiavet'],
      photo: telemedicine && telemedicine === 'text'
        ? telemedicineTextPhoto
        : telemedicineVideoPhoto,
      color1: telemedicine && telemedicine === 'text'
        ? '#DFE6E7'
        : '#ECDECD',
      color2: telemedicine && telemedicine === 'text'
        ? '#DFE6E7'
        : '#ECDECD',
    },
    default: {
      photo: petsitterPhoto,
      color: '#ABEEFF',
      color2: '#63DDFB',
    },
  };

  const telemedicineMap = {
    text: {
      icon: <ChatIcon />,
      text: 'Mensagem de texto',
    },
    video: {
      icon: <VideoIcon />,
      text: 'Videoconferência',
    },
    promotion: {
      icon: <ShoppingIcon />,
      text: 'Hospitais, especialistas, farmácias e outros',
    },
  };

  useEffect(() => {
    if (telemedicine === 'text') {
      Mixpanel.track(MIXPANEL_TAGS.SERVICE_AGENDA_MESSAGE_LOAD);
    }
    if (telemedicine === 'video') {
      Mixpanel.track(MIXPANEL_TAGS.SERVICE_AGENDA_VIDEO_LOAD);
    }
    if (telemedicine === 'promotion') {
      Mixpanel.track(MIXPANEL_TAGS.SERVICE_AGENDA_PROMOTION_LOAD);
    }
  }, []);

  return (
    <Card elevation={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <StyledGrid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        $color1={category ? categoryMap[category].color1 : categoryMap.default.color1}
        $color2={category ? categoryMap[category].color2 : categoryMap.default.color2}
        sx={{ position: 'relative', overflow: 'hidden', cursor: 'pointer' }}
      >
        <Grid
          container
          direction='column'
          alignItems='flex-start'
          justifyContent='space-around'
          spacing={2}
          wrap='nowrap'
          sx={{ height: '120px', width: '50%' }}
        >
          <Grid item sx={{ zIndex: '2' }}>
            {categoryMap[category].names && categoryMap[category].names.map((name) => (
              <>
                <ServiceTitle telemedicine={telemedicine}>{name}</ServiceTitle>
                <br />
                {telemedicine && (
                <ServiceTypeControl>
                  {telemedicineMap[telemedicine].icon}
                  <ServiceTelemedicineType telemedicine={telemedicine}>{telemedicineMap[telemedicine].text}</ServiceTelemedicineType>
                </ServiceTypeControl>
                )}
              </>
            ))}
          </Grid>
        </Grid>

        <Grid item sx={(category === 'Petsitter' || category === 'Banho e tosa') && { position: 'absolute', right: '-20px' }}>
          <ServicePhoto src={categoryMap[category].photo} alt={category} telemedicine={telemedicine} />
        </Grid>

      </StyledGrid>
    </Card>
  );
};

export default ServiceTypeCard;
