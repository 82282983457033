import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../utils/theme';

export const ModalBox = styled(Box)`
    &.MuiBox-root {
        background-color: white;
        margin-top: 3px;
        position: absolute;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -52%);
        width: 450px;
        height: auto;
        padding: 10px 0;

        @media screen and (max-width: 736px) {
		      width: 100%;
          margin-top: 60px;
          max-height: 90vh;
          top: 0;
          left: 0;
          transform: none;
	      }
    }

    & .MuiTypography-h6 {
        margin-left: 30px;
    }
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 60px;
  width: 430px;
  border-radius: 4px;

  @media screen and (max-width: 736px) {
      width: 100%;
    }
`;

export const Form = styled.form`
    padding: 0 30px;

    @media screen and (max-width: 736px) {
      width: 89%;
      max-height: 90vh;
      top: 0;
      left: 0;
      transform: none;
      overflow-y: scroll;
      padding-left: 20px;
    }

    @media screen and (max-height: 845px) {
      height: auto;
      max-height: 60vh;
      overflow-y: scroll;
    }
`;

export const ButtonControl = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const CloseModal = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ghostwhite;
    margin-top: -20px;
    left: 90%;
    cursor: pointer;
    opacity: 0.4;
    transition: background-color .2s ease-in, fill .8s ease-in;

    &:hover {
        svg {
          fill: white;
        }

        background-color: black;
    }

    @media screen and (max-width: 360px) {
      left: 85%;
    }

    @media screen and (max-width: 360px) {
      left: 85%;
    }
`;

export const ConfirmButton = styled(Button)`
  color: ${theme.primaryButton} !important;
  padding: 10px 20px !important;
`;

export const RedButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.backgroundPaper} !important;
  background-color: ${theme.warningColor} !important;
  border: 1px solid ${theme.warningColor} !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;

export const PurpleButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.primaryButton} !important;
  background-color: transparent !important;
  border: 1px solid ${theme.primaryButton} !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;

export const PrimaryButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: white !important;
  background-color: ${theme.primaryButton} !important;
  border: 1px solid transparent !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;
