/* eslint-disable import/no-extraneous-dependencies */
import { Select } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';

export const ModalBox = styled(Box)`
    &.MuiBox-root {
        background-color: white;
        margin-top: 40px;
        position: absolute;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        width: 430px;
        height: auto;
        padding: 0 0;

        @media screen and (max-width: 736px) {
		      width: 100%;
          margin-top: 60px;
          max-height: 90vh;
          top: 0;
          left: 0;
          transform: none;
	      }

        @media screen and (max-height: 736px) and (min-width: 736px) {
          top: 45%;
        }
    }

    & .MuiTypography-h6 {
        margin-left: 30px;
    }
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 60px;
  width: 430px;
  border-radius: 4px;

  @media screen and (max-width: 736px) {
      width: 100%;
    }
`;

export const Form = styled.form`
    padding: 0 30px;

    @media screen and (max-width: 736px) {
      width: 89%;
      margin-top: 0px;
      max-height: 90vh;
      top: 0;
      left: 0;
      transform: none;
      overflow-y: scroll;
      padding-left: 20px;
    }

    @media screen and (max-height: 845px) {
      height: auto;
      max-height: 80vh;
      overflow-y: scroll;
    }
`;

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const ButtonControl = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
`;

export const Separator = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;
    opacity: 0.4;
`;
export const Line = styled.div`
    width: 60px;
    height: 0px;
    border: 1px solid #000000;
    opacity: .3;
    margin: 0 7px;
`;

export const CloseModal = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ghostwhite;
    margin-top: -20px;
    left: 90%;
    cursor: pointer;
    opacity: 0.4;
    transition: background-color .2s ease-in, fill .8s ease-in;

    &:hover {
        svg {
          fill: white;
        }

        background-color: black;
    }

    @media screen and (max-width: 360px) {
      left: 85%;
    }

    @media screen and (max-width: 360px) {
      left: 88%;
    }
`;

export const ResponsibleSelect = styled(Select)`
    & .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
`;

export const ResponsibleAvatar = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: #6B48FF;
  outline: 0;
  cursor: pointer;

  &:focus, &:active, &:hover {
    outline: 0;
  }
`;
