import React from 'react';
import moment from 'moment';
import { Details, ProgressLine, Status, Time } from './style';

const ProtocolProgress = ({ progress, severity, countdown, countdownUnity, nextDoseDate, hasFinishedProtocol }) => {
  const parsedHours = moment(nextDoseDate).fromNow();

  return (
    <Details hide={hasFinishedProtocol}>
      <ProgressLine>
        <Status progress={progress} severity={severity} />
      </ProgressLine>
      <Time>
        {countdownUnity && countdownUnity === 'days' && countdown === 0 ? '0' : (countdownUnity && countdownUnity === 'days' && countdown) || ''}
      </Time>
      <span className='format' style={{ whiteSpace: 'nowrap', color: 'rgba(126, 129, 146, 1)' }}>
        {(!countdownUnity && ' dia(s)') || (countdownUnity === 'days' ? ' dia(s)' : parsedHours)}
      </span>
    </Details>
  );
};

export default ProtocolProgress;
