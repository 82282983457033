/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

import Loading from '../../../../global/Loading';
import AppointmentCancelConfirmation from '../../AppointmentCancelConfirmation';
import { ModalBox, PetPhoto, CloseModal } from './style';

const AttendanceModalContent = () => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch.Appointments.setModal({ attendanceModal: { open: false } });
  const { id, status, period, tutorName, animalPhoto, animalName, notificationList, serviceName } = useSelector((state) => state.Appointments.modal.attendanceModal.data);
  const titleDate = `${moment(period).format('dddd')} - ${moment(period).format('DD [de] MMMM [de] YYYY')}`;
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const canBeModified = moment().isSameOrAfter(moment(period));

  const handleUpdateSchedule = async (updateStatus) => {
    setLoading(true);
    await dispatch.Appointments.updateSchedule({ id, status: updateStatus });

    setTimeout(() => {
      closeModal();
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <AppointmentCancelConfirmation id={id} open={modalOpen} setModalOpen={setModalOpen} closeStatusModal={closeModal} />
      <ModalBox>
        <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ padding: '0px 80px' }}
        >
          <Typography variant='h6' sx={{ marginTop: '30px' }}>{serviceName}</Typography>

          <Typography variant='h6' sx={{ color: '#CCC3FE', marginTop: '24px' }}>{titleDate}</Typography>

          <Grid container direction='row' justifyContent='space-between' sx={{ border: '1px solid #CCC3FE', padding: '15px', marginTop: '24px' }}>
            <Grid item>
              <Grid container alignItems='center' gap={2}>
                <Grid item>
                  <PetPhoto src={animalPhoto} alt='petPhoto' />
                </Grid>

                <Grid item>
                  <Grid container direction='column'>
                    <Grid item>
                      <Typography variant='body1' sx={{ fontSize: '20px' }}>{animalName}</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant='body2'>{tutorName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant='h5'>{moment(period).utc().format('HH:mm')}</Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent='center' alignItems='center' sx={{ margin: '42px 0' }}>
            <Grid item>
              {notificationList?.length ? notificationList.map((notification) => (
                <Grid container direction='row' justifyContent='center' alignItems='flex-start'>
                  <Grid item>
                    <NotificationsIcon sx={{ stroke: '#B3B3B3', strokeWidth: '2.5px', fill: 'transparent', marginRight: '8px' }} />
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' sx={{ fontSize: '14px', color: '#B3B3B3' }}>
                      Notificado em:
                      {' '}
                      {moment(notification.sentDatetime).format('DD/MM/YYYY HH:mm')}
                      {' '}
                      via
                      {' '}
                      {notification.communicationChannel}
                    </Typography>
                  </Grid>
                </Grid>
              ))
                : <></>}
            </Grid>
          </Grid>

          {loading ? (
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              sx={{ margin: '30px 0' }}
            >
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent='space-between' sx={{ margin: '30px 0' }}>
              <Grid item>
                <Button
                  onClick={() => setModalOpen(true)}
                  size='large'
                  variant='contained'
                  disableElevation
                  sx={{ background: 'transparent', '&:hover': { background: 'transparent' }, borderRadius: '20px', border: '1px solid #FF505F', color: '#FF505F' }}
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disabled={!canBeModified}
                  onClick={() => handleUpdateSchedule('No Show')}
                  size='large'
                  variant='contained'
                  sx={{ background: '#FF9800', '&:hover': { background: '#FF9800' }, borderRadius: '20px', color: 'white' }}
                >
                  Não compareceu
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disabled={status === 'Cancelled' || !canBeModified}
                  onClick={() => handleUpdateSchedule('Done')}
                  size='large'
                  variant='contained'
                  sx={{ background: '#4CAF50', '&:hover': { background: '#4CAF50' }, borderRadius: '20px', color: 'white' }}
                >
                  Feito
                </Button>
              </Grid>
            </Grid>
          )}

        </Grid>
      </ModalBox>
    </>

  );
};

export default AttendanceModalContent;
