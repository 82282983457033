import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MainWrapper } from './style';
import PublicServicesDashboard from '../../components/PublicServices/PublicServicesDashboard';
import PublicDashboardHeader from '../../components/Dashboard/PublicDashboardHeader';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const PublicServices = () => {
  const [loading] = useState(false);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.PUBLIC_SERVICES_VIEW), []);

  return (
    <MainWrapper>
      <PublicDashboardHeader />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <PublicServicesDashboard />
      )}
    </MainWrapper>
  );
};

export default PublicServices;
