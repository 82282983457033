import { Card, Avatar } from '@mui/material';
import styled from 'styled-components';

export const StyledPetAvatarCard = styled(Card)`
  &.MuiCard-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;
    width: 100%;
  }
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-circular {
    width: 105px;
    height: 105px;
  }
`;
