import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AttendanceModalContent from './AttendanceModalContent';

const AppointmentCalendarModal = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.Appointments.modal.attendanceModal) || {};

  const closeModal = () => dispatch.Appointments.setModal({ attendanceModal: { open: false } });

  return (
    <Modal
      open={open}
      onClose={closeModal}
    >
      <AttendanceModalContent />
    </Modal>
  );
};

export default AppointmentCalendarModal;
