import { Card, Typography } from '@mui/material';
import React from 'react';
import PricingAccordion from './PricingAccordion';

const PricingAccordions = ({ features }) => (
  <Card elevation={0} sx={{ width: '100%', padding: '20px 12px', background: '#FFFBFB' }}>
    <Typography sx={{ color: '#6B48FF', textAlign: 'center', fontSize: '16px', fontWeight: '600', marginBottom: '22px' }}>Veja o que está incluso nessa assinatura</Typography>
    {features && features.map(({ name, description, available }) => (
      <PricingAccordion name={name} description={description} available={available} />
    ))}
  </Card>
);

export default PricingAccordions;
