/* eslint-disable no-unused-expressions */
import { Collapse, Card, Divider, Grid, Snackbar, Typography, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import UpdateIcon from '@mui/icons-material/Update';
import moment from 'moment';
import { Price, OldPrice, VoucherTitle, ProductImageContainer, ExpireInfo, InfoTitle, OfferCondition, AddressInfo, BuyButton } from './style';
import { formatBRL } from '../../../../utils/format';
import Alert from '../../../global/Alert';
import ShareButton from '../../../../assets/images/Vouchers/share.svg';
import Loading from '../../../global/Loading';
import { isNative } from '../../../../utils/mobile';

const VoucherDetailCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const { cpfCnpj, phone } = useSelector((state) => state.User.userData);

  const { promotionB2CDetail } = useSelector((state) => state.Vouchers) || {};
  const { id, name, deliveryMode, shippingDetail, description, priceWithDiscount, priceFull, address, voucherImage, purchaseUntilDatetime, totalQuantity, terms, shareUrl } = promotionB2CDetail;
  const promotionB2CDetailLoading = useSelector((state) => state.Vouchers.loading.promotionB2CDetailUUID);
  const voucherB2CConfirmPurchaseLoading = useSelector((state) => state.Vouchers.loading.voucherB2CConfirmPurchase);

  const isPurchaseConfirming = voucherB2CConfirmPurchaseLoading === 'loading';

  const isPromotionLoading = promotionB2CDetailLoading !== 'fulfilled';

  const [voucherImgSrc, setVoucherImgSrc] = useState(null);

  const fetchVoucherImage = async () => {
    const response = await dispatch.Photo.getPhoto(voucherImage);
    setVoucherImgSrc(response);
  };

  useEffect(() => {
    !!voucherImage && fetchVoucherImage();
  }, [voucherImage]);

  // eslint-disable-next-line consistent-return
  const handleConfirm = async () => {
    if (!cpfCnpj || !phone) {
      const eventTrigger = {
        triggerName: 'purchase-voucher',
        type: 'redirect',
        urlPattern: window.location.href,
        payload: {
          voucherId: params.id,
        },
      };

      await dispatch.User.clearEventTrigger();
      await dispatch.User.setLastEventTrigger(eventTrigger);

      history.push('/profile');
    }

    const response = await dispatch.Vouchers.voucherB2CConfirmPurchase({ id });

    if (response && response.data) {
      history.push(`/orders/${response.data.id}`);
    } else {
      setAlert({
        open: true,
        message: response?.response?.data?.reason || 'Falha ao confirmar compra',
        severity: 'error',
      });
    }
  };

  const handleShareVoucherOnWhatsapp = () => {
    const message = `Oi! Encontrei essa oferta de *${encodeURIComponent(name)}* na *Guiavet*. Acho que seu Pet vai adorar:%0A${encodeURIComponent(shareUrl)}`;
    const url = `https://web.whatsapp.com:/send?text=${message}`;
    if (isNative) window.open(`whatsapp://send?text=${message}`);
    else window.open(`${url}_blank`);
  };

  if (isPromotionLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Loading />
        </Grid>
      </Grid>
    );
  }

  return (
    <>

      <Card elevation={0} sx={{ marginTop: '10px', padding: '10px 24px', position: 'relative' }}>
        <Stack direction='row' justifyContent='end' sx={{ marginTop: '6px' }}>
          <IconButton onClick={() => handleShareVoucherOnWhatsapp()} justifyContent='flex-end'>
            <img src={ShareButton} height='24px' width='24px' alt='ShareButton' />
          </IconButton>
        </Stack>
        <Grid container wrap='nowrap' flexDirection='row' sx={{ minHeight: '220px', marginTop: '5px' }} spacing={1} justifyContent='center'>
          <Grid item sx={{ marginTop: '20px' }}>
            <ProductImageContainer>
              <img src={voucherImage && voucherImgSrc} alt='voucherPhoto' />
            </ProductImageContainer>
          </Grid>

          <Grid item>
            <Grid container flexDirection='column'>
              <Grid container flexDirection='column'>
                <Grid item>
                  <Grid item>
                    <VoucherTitle>{name}</VoucherTitle>
                  </Grid>
                  <Grid item>
                    {priceFull !== priceWithDiscount && (
                    <OldPrice>
                      de
                      {' '}
                      {formatBRL(priceFull)}
                      {' '}
                      por
                    </OldPrice>
                    )}
                  </Grid>

                  <Grid item>
                    <Price>{formatBRL(priceWithDiscount)}</Price>
                  </Grid>
                </Grid>
              </Grid>
              <ExpireInfo>
                <p>
                  Vale compra válido até a data limite  ou até completarem
                  {' '}
                  {totalQuantity}
                  {' '}
                  unidades*
                </p>
                <Grid container wrap='nowrap' sx={{ marginTop: '4px' }}>
                  <UpdateIcon sx={{ width: '12px', height: '12px', color: '#FF4874', marginRight: '4px' }} />
                  <p>
                    {moment(purchaseUntilDatetime).format('DD MMM YYYY')}
                    {' '}
                    (
                    {moment(purchaseUntilDatetime).fromNow()}
                    )
                  </p>
                </Grid>
              </ExpireInfo>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />

        <Grid container flexDirection='column' alignItems='center'>

          <Grid item flexDirection='column'>
            {description && (
              <>
                <InfoTitle>Descrição</InfoTitle>
                <Collapse in={isDescriptionOpen} timeout='auto' collapsedSize={62}>
                  <OfferCondition>{description}</OfferCondition>
                </Collapse>

                {description.length > 190 && (
                  <Grid item sx={{ marginTop: '5px' }}>
                    <Typography
                      variant='caption'
                      onClick={() => setIsDescriptionOpen((open) => !open)}
                      sx={{ color: '#6B48FF' }}
                    >
                      Ver
                      {' '}
                      {isDescriptionOpen ? 'menos' : 'mais'}
                    </Typography>
                  </Grid>
                )}

                <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />
              </>
            )}

            {terms && (
              <>
                <InfoTitle>Termos</InfoTitle>
                <Collapse in={isTermsOpen} timeout='auto' collapsedSize={62}>
                  <OfferCondition>{terms}</OfferCondition>
                </Collapse>

                {terms.length > 190 && (
                  <Grid item sx={{ marginTop: '5px' }}>
                    <Typography
                      variant='caption'
                      onClick={() => setIsTermsOpen((open) => !open)}
                    >
                      Ver
                      {' '}
                      {isTermsOpen ? 'menos' : 'mais'}
                    </Typography>
                  </Grid>
                )}

                <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />
              </>
            )}

            <InfoTitle>
              {deliveryMode === 'pickup'
                ? 'Endereço de retirada'
                : 'Detalhes do frete'}
            </InfoTitle>
            <AddressInfo>
              {deliveryMode === 'pickup'
                ? address?.fullAddress
                : shippingDetail}
            </AddressInfo>
          </Grid>
        </Grid>

        <Divider sx={{ width: '100%', opacity: 0.5, margin: '14px 0' }} />

        {isPurchaseConfirming ? (
          <Grid container justifyContent='center' sx={{ minHeight: '10vh' }}>
            <Grid item>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent='center'>
            <BuyButton
              variant='contained'
              size='large'
              disableElevation
              onClick={handleConfirm}
            >
              Confirmar Compra
            </BuyButton>
          </Grid>
        )}
      </Card>

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ ...alert, open: false })}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        sx={{ marginTop: '50px' }}
      >
        <div>
          <Alert severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </div>
      </Snackbar>

    </>

  );
};

export default VoucherDetailCard;
