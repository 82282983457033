var _cio = _cio || [];
(function() {
    var a,b,c;a=function(f){return function(){_cio.push([f].
    concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
    "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
    var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id    = 'cio-tracker';
    t.setAttribute('data-site-id', '3a9f53a17adc05d0184f');
    t.setAttribute('data-base-url', 'https://customerioforms.com');
    t.setAttribute('data-use-array-params', 'true');


    // To disable in-app messages, remove the two lines below
    t.setAttribute('data-in-app-org-id', '28579e84-9303-447a-ac3f-a28708da0256');
    t.setAttribute('data-use-in-app', 'true');
    // If data-in-app-org-id was not filled above, go to
    // the following link to find it: https://fly.customer.io/env/last/settings/actions/in_app'

    t.src = 'https://assets.customer.io/assets/track.js';

    s.parentNode.insertBefore(t, s);
})();
window._cio = _cio;
