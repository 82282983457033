/* eslint-disable no-shadow */
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatePicker from '@mui/lab/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import useWindowSize from '../../../../hooks/windowSize';
import { RedeemButton } from './style';
import { formatBRL, formatCPF, formatPhone } from '../../../../utils/format';
import Loading from '../../../global/Loading';

const VoucherDetailDialog = ({ open, setVoucherDialogOpen, setRedeemDialogOpen, setAlert, clickFromRow }) => {
  const screen = useWindowSize();
  const dispatch = useDispatch();
  const isMobile = screen.width < 1000;
  const { voucherB2BDetail } = useSelector((state) => state.Vouchers) || {};
  const [loading, setLoading] = useState('');
  const [closeButtonText, setCloseButtonText] = useState('Cancelar');

  const renderConfirmButton = () => {
    switch (loading) {
      case '':
        return (
          <Grid item>
            <RedeemButton type='submit' sx={{ color: '#6B48FE', width: '300px' }}>
              Confirmar o resgate
            </RedeemButton>
          </Grid>
        );
      case 'loading':
        return (
          <Grid item>
            <Grid container>
              <Grid item>
                <Loading size={25} sx={{ marginRight: '150px' }} />
              </Grid>
            </Grid>
          </Grid>
        );
      case 'fulfilled':
        return (
          <Grid item>
            <Typography sx={{ color: '#69c769' }}>
              Vale compra resgatado com sucesso
            </Typography>
          </Grid>
        );
      default:
        return <div />;
    }
  };

  const handleConfirmRedeeem = async ({ id, redeemCode, user }) => {
    setLoading('loading');
    const response = await dispatch.Vouchers.voucherB2BRedeem({
      voucherId: id,
      redeemCode,
      customerCpfCnpj: user?.cpfCnpj,
    });

    if (response?.data) {
      setLoading('fulfilled');
      setCloseButtonText('Fechar');
      setAlert({
        open: true,
        message: 'O vale compra foi resgatado com sucesso',
        success: 'success',
      });
    } else {
      setLoading('rejected');
      setCloseButtonText('Fechar');
      setAlert({
        open: true,
        message: response?.response?.data?.reason || 'Não foi possível resgatar o vale compra',
        success: 'error',
      });
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '854px',
        padding: '0 18px',
        height: isMobile ? '750px' : '950px',
      },
      '& .MuiDialog-container': {
        marginTop: isMobile ? '55px' : '2px',
        overflow: 'scroll',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setVoucherDialogOpen(!clickFromRow)}
      scroll={!isMobile ? 'paper' : ''}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            Detalhes do pedido
          </Grid>

          <Grid item>
            <CloseIcon onClick={() => setVoucherDialogOpen(false)} sx={{ display: clickFromRow ? 'block' : 'none', cursor: 'pointer' }} />
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent dividers={false}>
        <Button
          size='large'
          sx={{ visibility: clickFromRow ? 'hidden' : 'visible', color: '#6B48FF', paddingRight: '0px', paddingLeft: '0px', margin: '2px 0' }}
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            setVoucherDialogOpen(false);
            setRedeemDialogOpen(true);
          }}
        >
          Voltar
        </Button>

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            ...voucherB2BDetail,
            username: `${voucherB2BDetail?.user?.firstName || ''} ${voucherB2BDetail?.user?.lastName || ''}`,
            cpf: `${voucherB2BDetail?.user?.cpfCnpj || ''}`,
          }}
          onSubmit={handleConfirmRedeeem}
          validationSchema={Yup.object().shape({
            redeemCode: Yup.string().required('Favor preencher o campo'),
          })}
        >
          {({ handleChange, handleSubmit, touched, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <Grid container flexDirecton='column'>
                <Typography variant='subtitle1' sx={{ marginBottom: '24px' }}>Dados da oferta</Typography>

                <Grid container flexDirection='column' sx={{ width: '800px' }} rowGap={4}>
                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='name'
                          value={values.name}
                          helperText={errors && errors.name}
                          error={!!(errors && errors.name)}
                          label='Item'
                          placeholder='000000000'
                          onChange={handleChange}
                          disabled
                          sx={{ minWidth: '550px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>

                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='redeemCode'
                          value={values.redeemCode}
                          helperText={errors && errors.redeemCode}
                          error={!!(errors && errors.redeemCode)}
                          label='ID'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='promotionDescription'
                          value={values.promotionDescription}
                          helperText={errors && errors.promotionDescription}
                          error={!!(errors && errors.promotionDescription)}
                          label='Descrição'
                          onChange={handleChange}
                          disabled
                          sx={{ minWidth: '550px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>

                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='promotionVoucher.totalQuantity'
                          value={values.promotionVoucher?.totalQuantity}
                          helperText={errors && errors.promotionVoucher?.totalQuantity}
                          error={!!(errors && errors.promotionVoucher?.totalQuantity)}
                          label='Quantidade'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='promotionVoucher.address'
                          value={values.promotionVoucher.deliveryMode === 'pickup'
                            ? values.promotionVoucher?.address?.fullAddress
                            : values.promotionVoucher?.shippingDetail}
                          helperText={errors?.promotionVoucher?.address}
                          error={!!(errors?.address)}
                          label='Endereço'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <DatePicker
                          id='createDatetime'
                          name='createDatetime'
                          label='Data da compra'
                          format='DD/MM/YYYY'
                          disabled
                          allowSameDateSelection
                          defaultValue={null}
                          value={values.createDatetime}
                          error={!!errors.createDatetime}
                          touched={touched.createDatetime}
                          helperText={errors.createDatetime && errors.createDatetime}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: '100%' }}
                              error={!!errors.createDatetime}
                              touched={touched.createDatetime}
                              helperText={errors.createDatetime && errors.createDatetime}
                              {...params}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item sx={{ width: '100%' }}>
                        <DatePicker
                          id='promotionValidUntilDatetime'
                          name='promotionValidUntilDatetime'
                          label='Validade da oferta'
                          format='DD/MM/YYYY'
                          disabled
                          allowSameDateSelection
                          defaultValue={null}
                          value={values.promotionValidUntilDatetime}
                          error={!!errors.promotionValidUntilDatetime}
                          touched={touched.promotionValidUntilDatetime}
                          helperText={errors?.promotionValidUntilDatetime}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: '100%' }}
                              error={!!errors.promotionValidUntilDatetime}
                              touched={touched.promotionValidUntilDatetime}
                              helperText={errors.promotionValidUntilDatetime && errors.promotionValidUntilDatetime}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='promotionPriceToRepass'
                          value={formatBRL(values.promotionPriceToRepass)}
                          helperText={errors && errors.promotionPriceToRepass}
                          error={!!(errors && errors.promotionPriceToRepass)}
                          label='Valor de repasse'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>

                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='promotionPriceWithDiscount'
                          value={formatBRL(values.promotionPriceWithDiscount)}
                          helperText={errors && errors.promotionPriceWithDiscount}
                          error={!!(errors && errors.promotionPriceWithDiscount)}
                          label='Valor pago'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider sx={{ width: '100%', margin: '20px 0 10px 0' }} />

                <Typography variant='subtitle1' sx={{ marginBottom: '24px' }}>Dados do cliente</Typography>

                <Grid container flexDirection='column' sx={{ width: '800px' }} rowGap={4}>
                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='name'
                          value={values.username}
                          helperText={errors && errors.username}
                          error={!!(errors && errors.username)}
                          label='Nome'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>

                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='user.phone'
                          value={formatPhone(values.user?.phone)}
                          helperText={errors?.user?.phone}
                          error={!!(errors?.user?.phone)}
                          label='Celular'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid container spacing={2} wrap='nowrap'>
                      <Grid item sx={{ width: '100%' }}>
                        <TextField
                          name='cpf'
                          value={formatCPF(values.cpf)}
                          helperText={errors && errors.cpf}
                          error={!!(errors && errors.cpf)}
                          label='CPF'
                          onChange={handleChange}
                          disabled
                          sx={{ width: '49%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container flexDirection='row' justifyContent='space-between' sx={{ margin: '30px 0' }}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        setVoucherDialogOpen(false);
                        window.location.reload();
                      }}
                      sx={{ color: '#00000080' }}
                    >
                      {closeButtonText}
                    </Button>
                  </Grid>
                  {!clickFromRow && <>{renderConfirmButton()}</>}
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default VoucherDetailDialog;
