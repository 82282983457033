/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Grid, Avatar, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGemoji from 'remark-gemoji';
import remarkGFM from 'remark-gfm';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';

import { WrapperHeader, WrapperImageWithInfos, WrapperInfos, WrapperIconChat, WrapperContent } from './style';
import { ReactComponent as ChatIcon } from '../../../assets/images/Announcement/chat.svg';
import { MIXPANEL_TAGS, Mixpanel } from '../../../utils/mixpanel';

const CardAnnouncement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const notification = location?.state?.notification || {};

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(notification?.announcement?.sender?.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    if (notification?.announcement?.sender?.profileImage) fetchProfileImage();
  }, [notification?.announcement?.sender?.profileImage]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.ANNOUNCEMENT_CARD_LOAD, notification);
  }, []);

  const handleOpenLinks = (link) => {
    const urlHttpToHttps = link.replace(/^http:\/\//i, 'https://');
    const transformToLink = link.includes('https://') ? urlHttpToHttps : `https://${urlHttpToHttps?.trim()}`;

    window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: transformToLink } }));
  };

  return (
    <Grid
      container
      direction='column'
      sx={{ padding: '16px', maxWidth: '1280px', alignItems: 'center', background: '#FFF', borderRadius: 4 }}
      gap={2}
      mb={10}
    >
      <WrapperHeader>
        <WrapperImageWithInfos>
          <Avatar
            src={profileImgSrc}
            sx={{ marginRight: '8px' }}
          />
          <WrapperInfos>
            <Typography variant='body1'>{notification?.announcement?.sender?.fullName}</Typography>
            <Typography variant='caption'>{moment(notification?.announcement?.createDatetime).format('DD [de] MMMM[,] YYYY')}</Typography>
          </WrapperInfos>
        </WrapperImageWithInfos>
        <WrapperIconChat>
          <ChatIcon />
        </WrapperIconChat>
      </WrapperHeader>

      <WrapperContent>
        <Typography variant='h6' mb={1} color='#47B9AF'>{notification?.title}</Typography>
        <div id='ignore-css'>
          <ReactMarkdown
            rehypePlugins={[rehypeHighlight, remarkGemoji]}
            remarkPlugins={[remarkGFM]}
            components={{
              a: (props) => <Link onClick={() => handleOpenLinks(props.href)}>{props.children}</Link>,
            }}
          >
            {notification?.announcement?.description}
          </ReactMarkdown>
        </div>
      </WrapperContent>
    </Grid>
  );
};

export default CardAnnouncement;
