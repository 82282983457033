/* eslint-disable consistent-return */
import { Card, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';
import theme, { animations } from '../../../utils/theme';

export const blinkAnimation = css`
	animation: ${animations.blink} .7s;
`;

export const fadeAnimation = css`
	animation: ${animations.fadeIn} .5s ease-in;
`;

export const Container = styled(Card)``;

export const Title = styled(Typography)`
  color: ${theme.title};
  font-weight: 400;
  padding-right: 12px;
`;

export const CardMedia = styled.img`
  background-color: ${theme.backgroundDefault};
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  font-size: 10px;
  height: 55px;
  object-fit: cover;
  overflow: hidden;
  width: 55px;
`;

export const ContentCard = styled.div`
	align-items: center;
	background-color: ${(props) => (props.highlight ? 'rgba(236, 244, 251, 1)' : 'transparent')};
	box-shadow: ${(props) => (props.highlight ? '1px 1px 8px rgba(74, 80, 86, 0.4)' : 'transparent')};
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: 45px 50px 25px 50px;

	img {
		background-color: ${theme.backgroundDefault};
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    font-size: 10px;
    height: 55px;
    object-fit: cover;
    overflow: hidden;
    width: 55px;
	}

	${(props) => props.blink && fadeAnimation};

@media screen and (max-width: 736px) {
	flex-flow: row wrap;
	padding: 40px 10px 20px 20px;

	svg {
		margin-left: 10px;
		margin-top: 10px;
	}
}

.card-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: absolute;

  @media screen and (max-width: 736px) {
    h6 {
      position: relative;
      top: -14px;
    }
  }

  .title {
    position: relative;
    color: rgba(74, 80, 86, 1);
    font-size: 20px;
    font-weight: 400;
    margin: 8px 0;
    @media screen and (max-width: 736px) {
      margin: 8px 0;
	  }
  }

}

.dose-info {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 85%;

  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
}

.last-dose {
	color: ${theme.caption};
	font-size: 13px;

	.emphasis {
		font-weight: 300;
	}
}

.last-dose-empty {
	color: ${theme.caption};
	font-size: 16px;
  margin-left: 30px;

	.emphasis {
		font-weight: 500;
	}
}

.next-dose {
	color: ${theme.caption};
	font-size: 13px;
	margin-top: 5px;

	.emphasis {
		font-weight: 300;
	}
}

.column {
	align-items: flex-start;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
  flex-shrink: 2;
  margin-left: 40px;
  width: 700px;
  /* height: 40px; */

	@media screen and (max-width: 736px) {
    margin-top: 30px;
    margin-bottom: 10px;
		margin-left: 40px;
	}
}

.details {
	align-items: center;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-end;
	width: 40%;
	color: ${(props) => {
    switch (props.severity) {
      case 'regular':
        return '#3B87D3';

      case 'warning':
        return '#FFB400';

      case 'critical':
        return '#E36360';

      case 'success':
        return '#25BB2B';

      default:
        break;
    }
  }
};
	.time {
		margin: 0 15px 0;
	}
	@media screen and (max-width: 736px) {
		&-btn {
			display: none;
		}
		width: 100%;
	}
}
`;

export const ProgressLine = styled.div`
  background-color: #C6CDD3;
  border-radius: 10px;
  height: 8px;
  width: 150px;
`;

export const Status = styled.div`
  border-radius: 10px;
  height: 8px;
  width: ${(props) => `${props.progress}%`};
  background-color: ${(props) => {
    switch (props.severity) {
      case 'regular':
        return '#3B87D3';

      case 'warning':
        return '#FFB400';

      case 'critical':
        return '#E36360';

      case 'success':
        return '#25BB2B';

      default:
        break;
    }
  }
};
`;
