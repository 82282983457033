/* eslint-disable no-console */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;
const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

const standardApiCall = async (dispatch, name, model, method, getOrPost, url, setLoading) => {
  dispatch[model][setLoading]({ [name]: 'loading' });

  try {
    const response = await axios[getOrPost](`${baseURL}${url}`, headers());

    if (response && response.data) {
      dispatch[model][setLoading]({ [name]: 'fulfilled' });
      dispatch[model][method](response.data);
    }

    return response;
  } catch (err) {
    console.log(err);
    dispatch[model][setLoading]({ [name]: 'rejected' });
    return err;
  }
};

export const Vouchers = {
  state: {
    balance: {},
    extractList: [],
    extractCount: 0,
    promotionB2CCategories: [],
    promotionB2CList: [],
    actualB2CCategory: 'Todos',
    promotionB2CDetail: {},
    purchasedVoucher: {},
    redeemedVoucher: {},
    purchasedVoucherStatus: '',
    voucherB2CMyList: {},
    voucherB2BTotals: {},
    voucherB2BDetail: {},
    orderStatusTab: 'active',
    loading: {},
  },
  reducers: {
    SET_ORDER_STATUS_TAB: (state, payload) => ({ ...state, orderStatusTab: payload }),
    SET_REDEEMED_VOUCHER: (state, payload) => ({ ...state, redeemedVoucher: payload }),
    SET_VOUCHER_B2B_DETAIL: (state, payload) => ({ ...state, voucherB2BDetail: payload }),
    SET_VOUCHER_B2B_TOTALS: (state, payload) => ({ ...state, voucherB2BTotals: payload }),
    SET_EXTRACT: (state, payload) => ({ ...state, extractList: payload }),
    SET_EXTRACT_COUNT: (state, payload) => ({ ...state, extractCount: payload }),
    UPDATE_EXTRACT: (state, payload) => ({ ...state, extractList: [...state.extractList, ...payload] }),
    SET_BALANCE: (state, payload) => ({ ...state, balance: payload }),
    SET_VOUCHER_B2C_LIST: (state, payload) => ({ ...state, voucherB2CMyList: payload }),
    UPDATE_VOUCHER_B2C_LIST: (state, payload) => ({ ...state, voucherB2CMyList: [...state.voucherB2CMyList, ...payload] }),
    SET_PURCHASED_VOUCHER: (state, payload) => ({ ...state, purchasedVoucher: payload }),
    SET_PURCHASED_VOUCHER_STATUS: (state, payload) => ({ ...state, purchasedVoucherStatus: payload }),
    SET_PROMOTION_B2C_DETAIL: (state, payload) => ({ ...state, promotionB2CDetail: payload }),
    UPDATE_PROMOTION_B2C_LIST: (state, payload) => ({ ...state, promotionB2CList: [...state.promotionB2CList, ...payload] }),
    SET_PROMOTION_B2C_LIST: (state, payload) => ({ ...state, promotionB2CList: payload }),
    SET_PROMOTION_B2C_CATEGORIES: (state, payload) => ({ ...state, promotionB2CCategories: payload }),
    SET_ACTUAL_B2C_CATEGORY: (state, payload) => ({ ...state, actualB2CCategory: payload }),
    SET_OPEN: (state, payload) => ({ ...state, openNotifications: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
  },
  effects: (dispatch) => ({
    async promotionB2CCategories() {
      dispatch.Vouchers.SET_LOADING({ promotionB2CCategories: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/promotions/categories`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ promotionB2CCategories: 'fulfilled' });
          dispatch.Vouchers.SET_PROMOTION_B2C_CATEGORIES(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ promotionB2CCategories: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async promotionB2CList({ category: promotionCategory, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ promotionB2CList: 'loading' });

      const category = promotionCategory ? `category=${promotionCategory}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [category, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/promotions/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ promotionB2CList: 'fulfilled' });
          dispatch.Vouchers.SET_PROMOTION_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ promotionB2CList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updatePromotionB2CList({ category: promotionCategory, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ updatePromotionB2CList: 'loading' });

      const category = promotionCategory ? `category=${promotionCategory}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [category, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/promotions/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ updatePromotionB2CList: 'fulfilled' });
          dispatch.Vouchers.UPDATE_PROMOTION_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ updatePromotionB2CList: 'rejected' });
        return err;
      }
    },
    async voucherB2CMyList({ status, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ voucherB2CMyList: 'loading' });

      const statusbar = status ? `statustab=${status}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [statusbar, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/my-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2CMyList: 'fulfilled' });
          dispatch.Vouchers.SET_VOUCHER_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2CMyList: 'rejected' });
        return err;
      }
    },
    async updateVoucherB2CMyList({ status, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ updateVoucherB2CMyList: 'loading' });

      const statusbar = status ? `statustab=${status}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [statusbar, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/my-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ updateVoucherB2CMyList: 'fulfilled' });
          dispatch.Vouchers.UPDATE_VOUCHER_B2C_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ updateVoucherB2CMyList: 'rejected' });
        return err;
      }
    },
    async promotionB2CDetailUUID({ uuid, slug }) {
      dispatch.Vouchers.SET_LOADING({ promotionB2CDetailUUID: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/promotions/detail/${uuid}/${slug}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ promotionB2CDetailUUID: 'fulfilled' });

          if (response.data.active) {
            dispatch.Vouchers.SET_PROMOTION_B2C_DETAIL(response.data);
          } else {
            window.location.href = `${process.env.REACT_APP_DYNAMIC_LINK}/vouchers`;
          }
        }

        return response;
      } catch (err) {
        console.log(err);
        window.location.href = `${process.env.REACT_APP_DYNAMIC_LINK}/vouchers`;
        dispatch.Vouchers.SET_LOADING({ promotionB2CDetailUUID: 'rejected' });
        return err;
      }
    },
    async promotionB2CDetail(id) {
      dispatch.Vouchers.SET_LOADING({ promotionB2CDetail: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/promotions/detail/${id}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ promotionB2CDetail: 'fulfilled' });
          dispatch.Vouchers.SET_PROMOTION_B2C_DETAIL(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ promotionB2CDetail: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherB2CDetail(id) {
      dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/detail/${id}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'fulfilled' });
          dispatch.Vouchers.SET_PURCHASED_VOUCHER(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherB2CB2BDetail(id) {
      dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/detail/${id}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'fulfilled' });
          dispatch.Vouchers.SET_VOUCHER_B2B_DETAIL(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2CDetail: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherPaymentStatus(id) {
      dispatch.Vouchers.SET_LOADING({ voucherPaymentStatus: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/customer/detail/${id}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherPaymentStatus: 'fulfilled' });
          dispatch.Vouchers.SET_PURCHASED_VOUCHER_STATUS(response.data.status);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherPaymentStatus: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherB2CConfirmPurchase(payload) {
      dispatch.Vouchers.SET_LOADING({ voucherB2CConfirmPurchase: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/voucher/customer/promotions/purchase`, payload, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2CConfirmPurchase: 'fulfilled' });
          dispatch.Vouchers.SET_PURCHASED_VOUCHER(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2CConfirmPurchase: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async balance() {
      await standardApiCall(dispatch, 'balance', 'Vouchers', 'SET_BALANCE', 'get', '/financial/balance', 'SET_LOADING');
    },
    async extractList({ startDate, endDate, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ extractList: 'loading' });

      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [startdate, enddate, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/financial/extract/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ extractList: 'fulfilled' });
          dispatch.Vouchers.SET_EXTRACT(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ extractList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updateExtractList({ startDate, endDate, page, limit }, rootState) {
      dispatch.Vouchers.SET_LOADING({ extractList: 'loading' });

      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [startdate, enddate, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/financial/extract/list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ extractList: 'fulfilled' });

          const firstExtractId = response.data[0].id;
          const filteredExtractList = rootState.Vouchers.extractList.filter((extract) => extract.id === firstExtractId);

          if (!filteredExtractList.length) {
            dispatch.Vouchers.UPDATE_EXTRACT(response.data);
          }
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ extractList: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async extractCount({ startDate, endDate, page, limit }) {
      dispatch.Vouchers.SET_LOADING({ extractCount: 'loading' });

      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const pagenumber = page ? `pagenumber=${page}` : '';
      const pagelength = limit ? `pagelength=${limit}` : '';
      const query = [startdate, enddate, pagenumber, pagelength].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/financial/extract/count${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ extractCount: 'fulfilled' });
          dispatch.Vouchers.SET_EXTRACT_COUNT(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ extractCount: 'rejected' });
        return err;
      }
    },
    async voucherB2BTotals({ startDate, endDate }) {
      dispatch.Vouchers.SET_LOADING({ voucherB2BTotals: 'loading' });

      const startdate = startDate ? `startdate=${startDate}` : '';
      const enddate = endDate ? `enddate=${endDate}` : '';
      const query = [startdate, enddate].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/voucher/business/totals${querystring}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2BTotals: 'fulfilled' });
          dispatch.Vouchers.SET_VOUCHER_B2B_TOTALS(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2BTotals: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherB2BDetail(code) {
      dispatch.Vouchers.SET_LOADING({ voucherB2BDetail: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/voucher/business/detail/${code}`, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2BDetail: 'fulfilled' });
          dispatch.Vouchers.SET_VOUCHER_B2B_DETAIL(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2BDetail: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async voucherB2BRedeem(payload) {
      dispatch.Vouchers.SET_LOADING({ voucherB2BRedeem: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/voucher/business/redeem`, payload, headers());

        if (response && response.data) {
          dispatch.Vouchers.SET_LOADING({ voucherB2BRedeem: 'fulfilled' });
          dispatch.Vouchers.SET_REDEEMED_VOUCHER(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Vouchers.SET_LOADING({ voucherB2BRedeem: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    setStatusTab(payload) {
      dispatch.Vouchers.SET_ORDER_STATUS_TAB(payload);
    },
    setActualB2CCategory(payload) {
      dispatch.Vouchers.SET_ACTUAL_B2C_CATEGORY(payload);
    },
  }),
};
