/* eslint-disable no-console */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;
const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const Network = {
  state: {
    animalToTransferList: [],
    networkCount: 0,
    networkData: [],
    listType: 'follower',
    context: 'table',
    table: 'follower',
    modal: {
      networkModal: {
        open: false,
        context: '',
        data: {},
      },
    },
    loading: {
      getNetworkList: '',
      getNetworkCount: '',
      sendInvite: '',
      activateContact: '',
      inactivateContact: '',
      acceptInvite: '',
      refuseInvite: '',
    },
  },
  reducers: {
    UPDATE_NETWORK_LIST_CONTACT_STATUS: (state, payload) => {
      const networkList = state.networkData;
      const contact = networkList.find((data) => data.id === payload.id);
      const contactWithUpdatedStatus = contact?.status && { ...contact, ...payload, status: payload?.status };

      const updatedList = networkList.map((data) => (data.id !== payload.id ? data : contactWithUpdatedStatus));

      return { ...state, networkData: updatedList };
    },
    UPDATE_NETWORK_LIST: (state, payload) => {
      const networkList = state.networkData;
      const updatedList = networkList.map((data) => (data.id !== payload.id ? data : payload));

      return { ...state, networkData: updatedList };
    },
    REMOVE_FROM_NETWORK_LIST: (state, payload) => {
      const networkList = state.networkData;

      const updatedList = networkList.map((data) => (data.id !== payload.id ? data : {})).filter((value) => Object.keys(value).length !== 0);

      return { ...state, networkData: updatedList };
    },
    SET_ANIMAL_TRANSFER_LIST: (state, payload) => ({ ...state, animalToTransferList: [...payload] }),
    ADD_TO_NETWORK_LIST: (state, payload) => ({ ...state, networkData: [...state.networkData, ...payload] }),
    RESET_NETWORK_LIST: (state) => ({ ...state, networkData: [] }),
    UPDATE_NETWORK_LIST_WITH_INVITE: (state, payload) => ({ ...state, networkData: [...state.networkData, payload] }),
    SET_NETWORK_LIST_TYPE: (state, payload) => ({ ...state, listType: payload }),
    SET_NETWORK_CONTEXT: (state, payload) => ({ ...state, context: payload }),
    SET_NETWORK_LIST: (state, payload) => ({ ...state, networkData: [...payload] }),
    SET_NETWORK_COUNT: (state, payload) => ({ ...state, networkCount: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
    SET_MODAL: (state, payload) => ({ ...state, modal: { ...state.modal, ...payload } }),
    SET_MODAL_DATA: (state, payload) => ({ ...state, modal: { networkModal: { ...state.modal.networkModal, data: payload } } }),
    SET_TABLE: (state, payload) => ({ ...state, table: payload }),
    CLEAR_NETWORK_LIST: (state) => ({ ...state, networkData: [] }),
  },
  effects: (dispatch) => ({

    async getNetworkList({ page, limit, listType }) {
      const listtype = listType ? `listtype=${listType}&` : '';
      dispatch.Network.SET_LOADING({ getNetworkList: 'loading' });
      try {
        const networkResponse = await axios.get(`${baseURL}/social/my-network/list?${listtype}pagenumber=${page}&pagelength=${limit}`, headers());

        if (networkResponse && networkResponse.data) {
          dispatch.Network.SET_LOADING({ getNetworkList: 'fulfilled' });
          dispatch.Network.SET_NETWORK_LIST(networkResponse.data);
          return networkResponse;
        }

        return null;
      } catch (err) {
        dispatch.Network.SET_LOADING({ getNetworkList: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updateAnimalToTransferList() {
      try {
        const animalResponse = await axios.get(`${baseURL}/animal/my-list-to-transfer`, headers());

        if (animalResponse && animalResponse.data) {
          dispatch.Network.SET_ANIMAL_TRANSFER_LIST(animalResponse.data);
          return animalResponse;
        }

        return null;
      } catch (err) {
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async addToNetworkList({ page, limit, listType }) {
      const listtype = listType ? `listtype=${listType}&` : '';
      dispatch.Network.SET_LOADING({ getNetworkList: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/social/my-network/list?${listtype}pagenumber=${page}&pagelength=${limit}`, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ getNetworkList: 'fulfilled' });
          dispatch.Network.ADD_TO_NETWORK_LIST(response.data);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Network.SET_LOADING({ getNetworkList: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async getNetworkCount(payload) {
      const listtype = payload && payload.listType ? `?listtype=${payload.listType}` : '';
      dispatch.Network.SET_LOADING({ getNetworkCount: 'loading' });
      try {
        const response = await axios.get(`${baseURL}/social/my-network/count${listtype}`, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ getNetworkCount: 'fulfilled' });
          dispatch.Network.SET_NETWORK_COUNT(response.data.count);
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Network.SET_LOADING({ getNetworkCount: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    // eslint-disable-next-line consistent-return
    async sendInvite(payload) {
      dispatch.Network.SET_LOADING({ sendInvite: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/social/invite/send`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ sendInvite: 'fulfilled' });
          return response;
        }
      } catch (err) {
        dispatch.Network.SET_LOADING({ sendInvite: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async activateContact(payload) {
      dispatch.Network.SET_LOADING({ activateContact: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/social/connection/activate`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ activateContact: 'fulfilled' });
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Network.SET_LOADING({ activateContact: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async inactivateContact(payload) {
      dispatch.Network.SET_LOADING({ inactivateContact: 'loading' });
      try {
        const response = await axios.post(`${baseURL}/social/connection/inactivate`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ inactivateContact: 'fulfilled' });
          return response;
        }

        return null;
      } catch (err) {
        dispatch.Network.SET_LOADING({ inactivateContact: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async acceptInvite(payload) {
      dispatch.Network.SET_LOADING({ acceptInvite: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/social/invite/accept`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ acceptInvite: 'fulfilled' });
          dispatch.Notification.updateNotification(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Network.SET_LOADING({ acceptInvite: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async refuseInvite(payload) {
      dispatch.Network.SET_LOADING({ refuseInvite: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/social/invite/refuse`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ refuseInvite: 'fulfilled' });
          dispatch.Notification.updateNotification(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Network.SET_LOADING({ refuseInvite: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async cancelInvite(payload) {
      dispatch.Network.SET_LOADING({ cancelInvite: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/social/invite/cancel`, payload, headers());

        if (response && response.data) {
          dispatch.Network.SET_LOADING({ cancelInvite: 'fulfilled' });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Network.SET_LOADING({ cancelInvite: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    setListType(payload) {
      dispatch.Network.SET_NETWORK_LIST_TYPE(payload);
    },
    setTable(payload) {
      dispatch.Network.SET_TABLE(payload);
    },
    clearNetworkList() {
      dispatch.Network.CLEAR_NETWORK_LIST();
    },
    setNetworkContext(payload) {
      dispatch.Network.SET_NETWORK_CONTEXT(payload);
    },
    setModal(status) {
      dispatch.Network.SET_MODAL(status);
    },
    setModalData(payload) {
      dispatch.Network.SET_MODAL_DATA(payload);
    },
    setResetNetworkList() {
      dispatch.Network.RESET_NETWORK_LIST();
    },
    updateNetworkContactStatus(payload) {
      dispatch.Network.UPDATE_NETWORK_LIST_CONTACT_STATUS(payload);
    },
    updateNetworkListInvite(payload) {
      dispatch.Network.UPDATE_NETWORK_LIST_WITH_INVITE(payload);
    },
    updateNetworkList(payload) {
      dispatch.Network.UPDATE_NETWORK_LIST(payload);
    },
    removeFromNetworkList(payload) {
      dispatch.Network.REMOVE_FROM_NETWORK_LIST(payload);
    },
  }),
};
