/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/system';
import styled from 'styled-components';
import { Select } from '@mui/material';

export const UpdateModalBox = styled(Box)`
    &.MuiBox-root {
        background-color: white;
        margin-top: 3px;
        position: absolute;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 641px;
        height: auto;
        padding: 30px 0;

        @media screen and (max-width: 736px) {
		      width: 100%;
          margin-top: 60px;
          max-height: 90vh;
          top: 0;
          left: 0;
          transform: none;
	      }

        @media screen and (max-height: 736px) and (min-width: 736px) {
          top: 45%;
        }

        & .MuiTypography-h6 {
          margin-left: 30px;
        }
    }
`;

export const DetailModalBox = styled(Box)`
    &.MuiBox-root {
      background-color: white;
      margin-top: 3px;
      position: absolute;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      min-width: 200px;
      height: auto;
      padding: 30px;


      @media screen and (max-width: 736px) {
        width: 100%;
        margin-top: 60px;
        min-height: 50vh;
        max-height: 80vh;
        overflow-y: auto;
        top: 0;
        left: 0;
        transform: none;
        padding: 30px 0;
      }

      @media screen and (max-height: 845px) {
        overflow-y: scroll;
      }

      & .MuiTypography-h6 {
        margin-left: 15px;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      margin-top: 30px;
    }
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 60px;
  width: 430px;
  border-radius: 4px;

  @media screen and (max-width: 736px) {
      width: 100%;
    }
`;

export const Form = styled.form`
    padding: 30px;

    @media screen and (max-width: 736px) {
      width: 95%;
      margin-top: 0px;
      max-height: 90vh;
      top: 0;
      left: 0;
      transform: none;
      overflow-y: scroll;
      padding-right: 60px;
      padding-left: 20px;
    }

    @media screen and (max-width: 500px) {
      width: 90%;
    }

    @media screen and (max-height: 845px) {
      height: auto;
      max-height: 60vh;
      overflow-y: scroll;
    }
`;

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const DetailButtonModal = styled(ButtonModal)`
    & {
        margin-right: 10px;
    }
`;

export const ButtonControl = styled.div`
    margin: 20px 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 736px) {
        margin: 20px 0 0 0;
	}
`;

export const DetailButtonControl = styled.div`
    margin: 30px 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
`;

export const DosageInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 5px 5px;

    @media screen and (max-width: 736px) {
	    width: 95%;
	}
`;

export const LastDosageInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const ProtocolImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FDF9F5;
    width: 100%;
    height: 240px;
    margin: 25px 0;

    img {
        object-fit: cover;
        width: 100%;
        height: 240px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    img.placeholder {
        width: 24px;
        height: 48px;
        margin-bottom: 20px;
        opacity: 0.8;
        transition: opacity .2s ease-in;

        &:hover {
            opacity: 1;
        }
    }
`;

export const CloseModal = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ghostwhite;
    margin-top: -20px;
    left: 90%;
    cursor: pointer;
    opacity: 0.4;
    transition: background-color .2s ease-in, fill .8s ease-in;

    &:hover {
        svg {
          fill: white;
        }

        background-color: black;
    }

    @media screen and (max-width: 360px) {
      left: 85%;
    }
`;

export const CloseModalDetail = styled(CloseModal)`
    @media screen and (max-width: 736px) {
        margin-right: 10px;
	}
`;

export const ResponsibleSelect = styled(Select)`
    & .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
`;

export const ResponsibleAvatar = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const CustomLink = styled.a`
  text-decoration: none;
  color: #6B48FF;
  outline: 0;
  cursor: pointer;

  &:focus, &:active, &:hover {
    outline: 0;
  }
`;
