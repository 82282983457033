import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../../components/Dashboard/DashboardMenu';
import Footer from '../../../components/Footer';
import { MainWrapper } from './style';
import VoucherDetail from '../../../components/Voucher/VoucherDetail';
import Loading from '../../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const VoucherDetailPage = () => {
  const [loading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.VOUCHER_DETAIL_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <VoucherDetail />
      )}
      <Footer />
    </MainWrapper>
  );
};

export default VoucherDetailPage;
