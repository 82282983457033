import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Loading from '../../../../global/Loading';

const EventCancelDialog = ({ open, setModalOpen }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [title, setTitle] = useState('Cancelar agendamento');
  const [description, setDescription] = useState('Cancelando um agendamento você irá perder a reserva do horário. Essa ação é irreversível. Deseja realmente continuar?');
  const [buttonMessage, setButtonMessage] = useState('CANCELAR AGENDAMENTO');
  const [buttonColor, setButtonColor] = useState('#FF0000');
  const [isScheduleCancelled, setIsScheduleCancelled] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancelScheduling = async () => {
    setLoading(true);

    const cancel = await dispatch.Appointments.updateSchedule({
      status: 'Cancelled',
      id: params.id,
    });

    if (cancel && cancel.data) {
      setLoading(false);
      setIsScheduleCancelled(true);
      setTitle('Agendamento cancelado');
      setDescription('O horário para esse evento está livre novamente. Não esqueça de remarcar seu agendamento.');
      setButtonMessage('Finalizar');
      setButtonColor('#6B48FE');
    }

    setLoading(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    history.push('/services');
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-container': { height: 'auto', marginTop: '55px' } }}
      open={open}
      fullScreen
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            {title}
          </Grid>

          <Grid item>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Grid container direction='column' justifyContent='center' alignItems='flex-end'>
            <Grid item sx={{ marginRight: '10px' }}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <Button
            sx={{ color: buttonColor }}
            onClick={isScheduleCancelled ? closeModal : cancelScheduling}
          >
            {buttonMessage}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EventCancelDialog;
