import styled from 'styled-components';
import theme from '../../../utils/theme';

export const Header = styled.div`
	background-color: ${theme.header};
	min-height: 65px;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	.logo-mobile {
		display: none;
	}

	@media screen and (max-width: 739px) {
		margin-bottom: 20px;

		.logo-desktop {
			display: none;
		}

		.logo-mobile {
			display: block;
		}
	}
`;
