import { FormControl, Grid, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Image } from './style';
import ergonomicsDog from '../../../../assets/images/ProtocolModal/ergonomics_dog.jpg';
import ergonomicsCat from '../../../../assets/images/ProtocolModal/ergonomics_cat.jpg';
import CancelOrSubmit from '../../../global/CancelOrSubmit';

const speciesMap = {
  CAT: ergonomicsCat,
  DOG: ergonomicsDog,
};

const NutritionErgonomics = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);

  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.protocolApplyId);
  const specie = useSelector((state) => state.Animals.actualPet?.breed?.species);

  const lastPawHeight = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastPawHeight);

  const content = useSelector((state) => state.Modal.protocolDialog?.content);
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');

  const submitForm = async (formData) => {
    // setLoading(true);
    const { pawHeight } = formData;

    const payload = {
      protocolId: id,
      ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
      pawHeight,
      appliedDatetime: moment().toISOString(),
    };

    const applyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (applyAnimalProtocol && applyAnimalProtocol.data) {
      const result = applyAnimalProtocol.data[0]?.lastResult;
      dispatch.Modal.setDialog({ open: false });
      dispatch.Modal.setResultDialog({ open: true, content, result });
    }

    // setLoading(false);
  };

  return (
    <Grid container flexDirection='column' justifyContent='flex-start'>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={(!isProtocolBeingEdited && {}) || {
          pawHeight: lastPawHeight,
        }}
        onSubmit={submitForm}
        validationSchema={
            Yup.object().shape({
              pawHeight: Yup.string().required('Favor indicar altura'),
            })
}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Image src={(specie && speciesMap[specie]) || speciesMap.DOG} alt='ergonomics' />

            <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
              <TextField
                id='pawHeight'
                name='pawHeight'
                type='number'
                label='Altura do cotovelo até o chão (em cm)'
                error={!!errors.pawHeight}
                placeholder='Meça a altura indicada na imagem'
                helperText={errors.pawHeight && errors.pawHeight}
                value={values.pawHeight}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>

            <CancelOrSubmit loading={loading === 'loading'} />
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default NutritionErgonomics;
