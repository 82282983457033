import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../utils/mobile';

const ErrorTriageDialog = ({ open, message, handleOpenNewFlowTriage }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': {
      minWidth: '350px',
    },
    '& .MuiDialog-container': {
      height: 'auto',
      paddingBottom: '10px',
      marginTop: isMobile ? '55px' : '150px',
    } }}
    fullScreen={isMobile}
    open={open}
  >
    <DialogTitle>
      <Grid container direction='row' justifyContent='flex-end'>
        <Grid item sx={{ marginTop: '10px' }} />
      </Grid>
      <Grid container direction='row' justifyContent='center'>
        <Grid item>
          {message?.title}
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <Grid container direction='row' justifyContent='center'>
        <Typography>{message?.subtitle}</Typography>
      </Grid>
    </DialogContent>

    <DialogActions>
      <Button
        sx={{ color: '#6B48FF' }}
        onClick={() => handleOpenNewFlowTriage()}
      >
        { message?.showButtonTryAgain ? 'Tentar novamente' : 'Ok' }
      </Button>
    </DialogActions>
  </Dialog>
);

export default ErrorTriageDialog;
