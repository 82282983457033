import React from 'react';
import { Card } from '@mui/material';
import { StyledCardContent, EmptyPetInfo } from './style';
import { ReactComponent as EmptyPetIcon } from '../../../assets/images/Dashboard/emptypetcard.svg';
import useWindowSize from '../../../hooks/windowSize';

const PetEmptyCard = () => {
  const screen = useWindowSize();

  return (
    <Card
      elevation={0}
      sx={{ mb: 2, display: 'flex', justifyContent: 'center', bgcolor: 'transparent' }}
    >
      <StyledCardContent>
        {screen.width >= 739 && (
        <p>
          Quer
          {' '}
          <strong>fazer perguntas ao veterinário</strong>
          {' '}
          e controlar a saúde do seu pet?
        </p>
        )}
        <EmptyPetIcon />
        <EmptyPetInfo>
          <h1>Adicionar Novo Pet</h1>
          {screen.width < 739 && (
          <p>
            Quer
            {' '}
            <strong>fazer perguntas ao veterinário</strong>
            {' '}
            e controlar a saúde do seu pet?
          </p>
          )}
        </EmptyPetInfo>
      </StyledCardContent>
    </Card>
  );
};

export default PetEmptyCard;
