import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import theme from '../../utils/theme';
import Footer from '../../components/Footer';
import { isMobile } from '../../utils/mobile';
import PetEditForm from '../../components/PetEdit/PetEditForm';
import PetEditAvatar from '../../components/PetEdit/PetEditAvatar';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';

import { MainWrapper, StyledContainer } from './style';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const PetEditPage = () => {
  const history = useHistory();

  const handleBackButton = () => {
    window.scrollTo(0, 0);
    history.push('/pets');
  };

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.PET_EDIT_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <StyledContainer>
        <Grid container direction='row' justifyContent='space-between' alignItems='flex-start' spacing={2}>
          {isMobile && (
          <Button
            size='large'
            sx={{ color: theme.primaryButton }}
            startIcon={<ArrowBackIcon />}
            onClick={handleBackButton}
          />
          )}
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <PetEditAvatar />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <PetEditForm />
          </Grid>
        </Grid>
      </StyledContainer>
      <Footer />
    </MainWrapper>
  );
};

export default PetEditPage;
