import { Button, Fade, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import VoucherDetailCard from './VoucherDetailCard';

const VoucherDetail = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { promotionB2CDetail } = useSelector((state) => state.Vouchers);

  const fetchPromotionDetail = async () => {
    if (!Object.keys(promotionB2CDetail).length) {
      await dispatch.Vouchers.promotionB2CDetailUUID({ uuid: params.uuid, slug: params.slug });
    }
  };

  useEffect(() => fetchPromotionDetail(), []);

  return (
    <>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >

        <Grid
          container
          direction='column'
          alignItems='flex-start'
          sx={{ paddingLeft: '5px', maxWidth: '1280px' }}
        >
          <Button
            size='large'
            sx={{ color: '#6B48FF' }}
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              window.scrollTo(0, 0);
              history.push('/vouchers');
            }}
          />
        </Grid>

        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
        >
          <Typography variant='h6'>Check out</Typography>
          <Typography variant='body2' sx={{ color: '#757575' }}>Confirmação de compra</Typography>

          <Grid container>
            <Fade in>
              <Grid item sx={{ width: '100%' }}>
                <VoucherDetailCard />
              </Grid>
            </Fade>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default VoucherDetail;
