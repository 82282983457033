/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { Typography, IconButton, TextField, Autocomplete, Select, FormControl, FormHelperText, InputLabel, MenuItem, Avatar } from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton, datePickerErrorStyle } from '../../../../utils/theme';
import { convertBase64, validateImage, resizeImage } from '../../../../utils/imageUtils';
import { ModalBox, CloseModal, Form, ButtonModal, ButtonControl, Separator, Line, HeaderModal, CustomLink, ResponsibleSelect } from './style';
import Loading from '../../../global/Loading';

const CreateProtocolContent = ({ modalStep, setModalStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { animalsList } = useSelector((state) => state.Animals);
  const { applyAnimalProtocol } = useSelector((state) => state.Animals.loading);

  const [productPlaceholder, setProductPlaceholder] = useState('Digite o nome do produto *');
  const [openLastDosePicker, setOpenLastDosePicker] = useState(false);
  const [openNextDosePicker, setOpenNextDosePicker] = useState(false);
  const [productSearchName, setProductSearchName] = useState('');
  const [protocolProducts, setProtocolProducts] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [product, setProduct] = useState({ label: '', id: '' });
  const productEl = useRef(null);
  const [petId, setPetId] = useState(null);
  const [protocolId, setProtocolId] = useState();
  const { lastCreatedPet } = useSelector((state) => state.Animals);

  const productOptions = protocolProducts.length ? protocolProducts.map((product) => ({ label: product.name, id: product.id })) : [];
  const [protocolOptions, setProtocolOptions] = useState([]);
  const [selectedProtocol, setSelectedProtocol] = useState(protocolOptions.find((protocol) => protocol.id === protocolId));
  const [petOptions, setPetOptions] = useState(modalStep === 'create-protocol' && lastCreatedPet && lastCreatedPet.id);

  const { b2bFollowers } = useSelector((state) => state.User);
  const { id: loggedUserId, native } = useSelector((state) => state.User.userData);
  const [responsible, setResponsible] = useState(b2bFollowers && b2bFollowers.length === 1 && b2bFollowers[0].id === loggedUserId && b2bFollowers[0].id);

  const [b2bFollowersAll, setB2bFollowersAll] = useState([]);

  const fetchProfileImage = async () => {
    const profiles = [...b2bFollowers];
    await Promise.all(profiles.map(async (profile) => {
      // eslint-disable-next-line no-param-reassign
      profile.profileImgSrc = await dispatch.Photo.getPhoto(profile.profileImage);
    }));
    setB2bFollowersAll(profiles);
  };

  useEffect(() => {
    !!b2bFollowers?.length && fetchProfileImage();
  }, [b2bFollowers]);

  const [formValues] = useState({
    pet: '',
    protocolId: '',
    product: '',
    lastDoseDate: null,
    nextDoseDate: null,
    responsibleId: responsible || null,
    keyCode: '',
    photoBase64: '',
  });

  useEffect(() => {
    fetchProtocols(lastCreatedPet.id);

    setPetId(lastCreatedPet.id);
  }, [lastCreatedPet]);

  useEffect(() => {
    if (protocolId) {
      setProduct({ label: '', id: '' });

      if (productEl.current) {
        productEl.current.focus();
      }

      fetchProducts('');
    }
  }, [protocolId]);

  const closeModal = () => {
    setModalStep('select-action');
    dispatch.Animals.setModal({ petAndProtocolApply: false });
  };

  const handleSubmitProtocolApply = async (formData, { setErrors }) => {
    const { protocolId, product, lastDoseDate, nextDoseDate, keyCode } = formData;

    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });
    } else {
      setErrors({ photoName: true });
    }

    const productsName = protocolProducts.map(((product) => product.name)) || [];
    const productsId = protocolProducts.map(((product) => product.id)) || [];

    const curatedProduct = protocolProducts.find((product) => product.name === product);
    const parsedProduct = (product && product.id) || product;

    const isCurated = Number(parsedProduct)
      ? productsId.includes(Number(parsedProduct))
      : productsName.includes(parsedProduct);

    const productId = { productId: curatedProduct && curatedProduct.id ? curatedProduct.id : parsedProduct };
    const productName = { productName: parsedProduct && parsedProduct.label ? parsedProduct.label : parsedProduct };

    const productObject = isCurated ? productId : productName;

    let payload = {
      protocolId,
      photoBase64,
      keyCode,
      responsibleId: responsible !== 'empty' ? responsible : null,
      appliedDatetime: moment(lastDoseDate).toISOString(),
      ...productObject,
    };

    if (nextDoseDate && !isUniqueDoseProtocol()) {
      payload = Object.assign(
        payload,
        {
          expirationDatetime: moment(nextDoseDate).toISOString(),
        },
      );
    }

    const applyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

    if (applyAnimalProtocol && applyAnimalProtocol.data) {
      dispatch.Animals.setModal({ protocolApply: false });
      history.push(`/pet/${petId}`);
    }
  };

  const fetchProtocols = async (pet) => {
    if (pet) {
      const response = await dispatch.Animals.getAnimalProtocolsVaccineCard(pet);

      if (response && response.data) {
        const protocols = response.data.map((protocol) => ({ id: protocol.id, label: protocol.name, ...protocol }));

        setProtocolOptions(protocols);
      }
    }
  };

  const fetchProducts = async (name) => {
    setProtocolProducts([]);
    const products = await dispatch.Animals.getAnimalProtocolProducts({ protocolId, name });

    if (products && products.data) {
      setProtocolProducts(products.data);
    }
  };

  // const forecastNextDoseDate = (value, setFieldValue) => {
  //   const selectedProtocol = protocolOptions.find((protocol) => protocol.id === protocolId);

  //   const predictedNextDoseDate = moment(value).add(selectedProtocol.forecastNextDoseHours, 'hours');

  //   if (predictedNextDoseDate) {
  //     return setFieldValue('nextDoseDate', predictedNextDoseDate);
  //   }

  //   return null;
  // };

  const handleLastDoseDate = (value, setFieldValue) => {
    setFieldValue('lastDoseDate', value);

    // forecastNextDoseDate(value, setFieldValue);
  };

  const handleNextDoseDate = (value, setFieldValue) => {
    setFieldValue('nextDoseDate', value);
  };

  const handleProductTextChange = (event, setFieldValue) => {
    setProductSearchName(event.target.value);
    setFieldValue('product', event.target.value);

    fetchProducts(event.target.value);
  };

  const handleProductOptionChange = (event, setFieldValue) => {
    setFieldValue('product', event);
  };

  const handleNewPet = () => setModalStep('create-pet');

  const handlePetChange = (event, setFieldValue) => {
    const { value } = event.target;

    setModalStep('create-protocol');
    setPetOptions(value);
    setPetId(value);
    setFieldValue('pet', value);

    fetchProtocols(value);
  };

  const isUniqueDoseProtocol = () => {
    const protocol = protocolOptions.find((protocol) => protocol.id === protocolId);

    if (protocol && protocol.regularity === 'once') {
      return true;
    }

    return false;
  };

  const handleProtocolChange = (event, setFieldValue) => {
    const { value } = event.target;
    setProtocolId(value);
    setFieldValue('protocolId', value);

    setSelectedProtocol(protocolOptions.find((protocol) => protocol.id === value));
  };

  const handleResponsible = (event, setFieldValue) => {
    const { value } = event.target;
    setResponsible(value);
    setFieldValue('responsibleId', value);
  };

  const handleUpload = () => document.getElementById('imgupload').click();

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;

    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');

      const base64 = await convertBase64(rawFile);
      setPhotoBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);

      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setPhotoBase64(base64);
      }
    }
  };

  return (
    <>
      <ModalBox>
        <HeaderModal>
          <Typography style={{ marginLeft: '30px' }} id='perfil-do-pet' variant='h6' component='h2'>
            Cadastrar protocolo
          </Typography>
          <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
        </HeaderModal>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={formValues}
          onSubmit={handleSubmitProtocolApply}
          validationSchema={Yup.object().shape({
            protocolId: Yup.string().required('Favor escolher o protocolo'),
            product: selectedProtocol && selectedProtocol.productRequired ? Yup.mixed().required('Favor digitar o nome do produto') : Yup.mixed(),
            lastDoseDate: Yup.date().nullable().required('Favor selecionar a data da última aplicação'),
            keyCode: selectedProtocol && selectedProtocol.keyCodeRequired ? Yup.string().nullable().required('Favor inserir o número do lote') : Yup.string().nullable(),
            photoBase64: Yup.string(),
            responsibleId: selectedProtocol && selectedProtocol.responsibleRequired && b2bFollowersAll && b2bFollowersAll.length ? Yup.string().required('Favor selecionar o responsável') : Yup.string(),
            nextDoseDate: !isUniqueDoseProtocol() ? Yup.string().nullable().required('Favor selecionar a data da próxima aplicação') : Yup.string().nullable(),
          })}
        >
          {({ handleSubmit, handleBlur, values, errors, touched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div>
                <div className='image-upload'>
                  <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => uploadImage(e)} style={{ display: 'none' }} />

                  {modalStep === 'select-action' && <PrimaryButton fullWidth onClick={handleNewPet}>Adicionar novo pet</PrimaryButton>}

                  {modalStep === 'select-action' && (
                  <Separator>
                    <Line />
                    OU
                    <Line />
                  </Separator>
                  )}

                  <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                    <InputLabel id='demo-simple-select-helper-label'>Selecione o seu pet *</InputLabel>
                    <Select
                      id='pet'
                      name='pet'
                      label='Selecione o seu pet'
                      value={petOptions}
                      onChange={(event) => handlePetChange(event, setFieldValue)}
                      onBlur={handleBlur}
                      touched={touched.pet}
                    >
                      <MenuItem disabled value=''>Pet</MenuItem>
                      {animalsList.length && animalsList.map((animal) => (
                        <MenuItem key={animal.id} value={animal.id}>{animal.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {modalStep === 'create-protocol' && (
                  <>
                    <FormControl error={!!errors.protocolId} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                      <InputLabel id='demo-simple-select-helper-label'>Protocolo *</InputLabel>
                      <Select
                        id='protocolId'
                        name='protocolId'
                        label='protocolId'
                        value={values.protocolId}
                        onChange={(event) => handleProtocolChange(event, setFieldValue)}
                        onBlur={handleBlur}
                        touched={touched.protocolId}
                      >
                        <MenuItem disabled value=''>Protocolo</MenuItem>
                        {protocolOptions.length && protocolOptions.map((protocol) => <MenuItem key={protocol.id} value={protocol.id}>{protocol.label}</MenuItem>)}

                      </Select>
                      <FormHelperText>{errors.protocolId && errors.protocolId}</FormHelperText>
                    </FormControl>

                    {selectedProtocol && selectedProtocol.productVisible ? (
                      <Autocomplete
                        blurOnSelect='touch'
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        disablePortal
                        id='product'
                        name='product'
                        value={product || values.product}
                        onFocus={() => setProductPlaceholder('Produto *')}
                        onBlur={() => !values.product && setProductPlaceholder('Digite o nome do produto')}
                        error={!!errors.product}
                        openOnFocus
                        freeSolo
                        onChange={(e, value) => handleProductOptionChange(value, setFieldValue)}
                        options={productOptions || []}
                        sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        renderInput={(params) => (
                          <TextField
                            id='product'
                            name='product'
                            inputRef={productEl}
                            error={!!errors.product}
                            value={productSearchName}
                            onChange={(event) => handleProductTextChange(event, setFieldValue)}
                            label={productPlaceholder}
                            {...params}
                          />
                        )}
                      />
                    ) : <></>}

                    <DatePicker
                      open={openLastDosePicker}
                      id='lastDoseDate'
                      name='lastDoseDate'
                      label='Data de aplicação *'
                      format='DD/MM/YYYY'
                      allowSameDateSelection
                      defaultValue={null}
                      maxDate={moment(new Date())}
                      value={values.lastDoseDate}
                      onChange={(value) => handleLastDoseDate(value, setFieldValue)}
                      onClose={() => setOpenLastDosePicker(false)}
                      error={!!errors.lastDoseDate}
                      touched={touched.lastDoseDate}
                      helperText={errors.lastDoseDate && errors.lastDoseDate}
                      renderInput={(params) => (
                        <TextField
                          sx={errors.lastDoseDate ? datePickerErrorStyle : { width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                          onClick={() => setOpenLastDosePicker(true)}
                          error={!!errors.lastDoseDate}
                          touched={touched.lastDoseDate}
                          helperText={errors.lastDoseDate && errors.lastDoseDate}
                          {...params}
                        />
                      )}
                    />
                    {selectedProtocol && selectedProtocol.keyCodeVisible ? (
                      <TextField
                        type='number'
                        value={values.keyCode}
                        onChange={(event) => setFieldValue('keyCode', event.target.value)}
                        error={!!errors.keyCode}
                        helperText={errors.keyCode && errors.keyCode}
                        touched={touched.keyCode}
                        sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                        autoComplete='off'
                        label='Lote'
                        id='keyCode'
                        name='keyCode'
                      />
                    ) : <></>}

                    <TextField
                      value={filename}
                      error={!!errors.photoName}
                      touched={touched.photoName}
                      onClick={handleUpload}
                      helperText='Se for vacina, envie imagem do rótulo com a assinatura do médico veterinário. Imagem até 20MB'
                      sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      id='photoName'
                      name='photoName'
                      label='Imagem'
                      InputProps={{
                        readOnly: true,
                        endAdornment:
  <IconButton edge='end'>
    <AttachFile />
  </IconButton>,
                      }}
                    />

                    {!isUniqueDoseProtocol() ? (
                      <DatePicker
                        open={openNextDosePicker}
                        id='nextDoseDate'
                        name='nextDoseDate'
                        label='Data da próxima aplicação *'
                        allowSameDateSelection
                        value={values.nextDoseDate}
                        onChange={(value) => handleNextDoseDate(value, setFieldValue)}
                        onClose={() => setOpenNextDosePicker(false)}
                        format='DD/MM/YYYY'
                        renderInput={(params) => (
                          <TextField
                            sx={errors.nextDoseDate ? datePickerErrorStyle : { width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                            id='nextDoseDate'
                            name='nextDoseDate'
                            error={!!errors.nextDoseDate}
                            touched={touched.nextDoseDate}
                            helperText={errors.nextDoseDate && errors.nextDoseDate}
                            onClick={() => setOpenNextDosePicker(true)}
                              // helperText='Se não souber, nós calculamos para você'
                            {...params}
                          />
                        )}
                      />
                    ) : <></>}

                    {selectedProtocol && selectedProtocol.responsibleVisible ? (
                      <FormControl error={!!errors.responsibleId} sx={{ width: '100%', marginTop: 3, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                        <InputLabel id='demo-simple-select-helper-label'>Responsável</InputLabel>
                        <ResponsibleSelect
                          id='responsibleId'
                          name='responsibleId'
                          value={responsible}
                          label='Responsável'
                          onChange={(value) => handleResponsible(value, setFieldValue)}
                          onBlur={handleBlur}
                          touched={touched.responsibleId}
                        >
                          <MenuItem value='empty' style={{ height: '60px' }}>Nenhum</MenuItem>
                          {b2bFollowersAll && b2bFollowersAll.map((follower) => (
                            <MenuItem value={follower.id}>
                              <Avatar
                                alt={follower.firstName}
                                src={follower.profileImage && follower.profileImgSrc}
                                sx={{ marginRight: '5px' }}
                              />
                              <div>{(follower.firstName && `${follower.firstName} ${follower.lastName}`) || follower.username || follower.email}</div>
                            </MenuItem>
                          ))}
                        </ResponsibleSelect>
                        <FormHelperText>
                          Caso não tenha responsável listado,
                          {' '}
                          <CustomLink href={`${window.location.origin}/network`} target={native ? '_self' : '_blank'}>convide-o para sua rede clicando aqui.</CustomLink>
                        </FormHelperText>
                      </FormControl>
                    ) : <></>}
                  </>
                  )}

                  <Stack direction='row-reverse' spacing={2} style={{ marginTop: 13 }}>
                    <ButtonControl>

                      {applyAnimalProtocol === 'loading'
                        ? <Loading size={30} />
                        : (
                          <>
                            <ButtonModal style={{ marginRight: 25 }} onClick={closeModal}>Cancelar</ButtonModal>
                            {modalStep === 'create-protocol' && <ButtonModal mainButton type='submit'>Salvar</ButtonModal>}
                          </>
                        )}
                    </ButtonControl>
                  </Stack>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBox>

    </>
  );
};

export default CreateProtocolContent;
