import styled from 'styled-components';
import { Avatar } from '@mui/material';
import theme, { SecondaryButton } from '../../../../../utils/theme';

export const PetName = styled.span`
font-size: 20px;
font-weight: 500;
margin: 10px 0 5px 0;
max-width: 160px;

@media screen and (min-width: 736px) {
    text-align: center;
  }
`;

export const Specie = styled.span`
font-size: 14px;
font-weight: 400;
color: rgba(0,0,0,0.35);
margin-bottom: 16px;
`;

export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-circular {
    width: 130px;
    height: 130px;
  }

  @media screen and (max-width: 736px) {
    &.MuiAvatar-circular {
      width: 105px;
      height: 105px;
    }
  }
`;

export const StyledChip = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 10px;
  color: ${theme.fontColorLight};
  background-color: ${(props) => (props.healthy ? theme.successColor : theme.warningColor)};
  border-radius: 64px;
  font-size: 12px;
  width: 190px;
  position: relative;

  @media screen and (max-width: 736px) {
    position: relative;
    width: 90%;
  }
`;

export const PetContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 736px) {
      flex-direction: row;
    }

    @media screen and (max-width: 580px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
    }
`;

export const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 736px) {
    margin-left: 20px;
    align-items: flex-start;
  }
`;

export const MobileHealthControlButton = styled(SecondaryButton)`
  display: none !important;

  &.MuiButton-text {
    font-size: 12px;
  }

  @media screen and (max-width: 736px) {
    margin-top: 20px !important;
    display: flex !important;
  }

  @media screen and (max-width: 420px) {
    padding: 2px 10px !important;
  }
`;

export const Communication = styled.div`
    display: none;

    @media screen and (max-width: 736px) {
        display: flex;
        margin: 0px 0 0 70px;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #6B48FF;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 2;
        cursor: pointer;
    }
`;
