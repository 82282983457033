import styled from 'styled-components';

export const Title = styled.div`
font-size: 20px;
font-weight: 500;
display: flex;
flex-flow: row nowrap;
align-items: center;
justify-content: flex-start;
margin: 25px 50px 45px;
color: rgba(74, 80, 86, 1);

img {
	margin-right: 10px;
}

@media screen and (max-width: 736px) {
	margin: 25px;
	justify-content: center;
}
`;
