import styled from 'styled-components';
import { Container } from '@mui/material';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
`;

export const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: 1280px;
    }
  }
`;
