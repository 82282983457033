import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import theme from '../../../../utils/theme';
import Loading from '../../../global/Loading';
import { isMobile } from '../../../../utils/mobile';

const AppointmentCancelConfirmation = ({ id, open, setModalOpen, closeStatusModal }) => {
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteService = async () => {
    setDeleteLoading(true);

    await dispatch.Appointments.updateSchedule({ id, status: 'Cancelled' });

    setTimeout(() => {
      setModalOpen(false);
      closeStatusModal();
    }, 300);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '350px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row'>
          <Grid item>
            Confirmar cancelamento de agenda
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Tem certeza que deseja cancelar este agendamento?</Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: theme.primaryButton }} onClick={() => setModalOpen(false)}>
          Voltar
        </Button>

        {deleteLoading ? (
          <Grid direction='row'>
            <Grid item>
              <Loading size={30} sx={{ marginLeft: '15px' }} />
            </Grid>
          </Grid>
        ) : (
          <Button sx={{ color: '#8e0404' }} onClick={deleteService}>
            Cancelar
          </Button>
        )}

      </DialogActions>
    </Dialog>
  );
};

export default AppointmentCancelConfirmation;
