import React, { useEffect, useLayoutEffect } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import PrivateB2BRoute from './PrivateB2BRoute';
import VaccineForm from '../pages/VaccineForm';

import useCloseModals from '../hooks/useCloseModals';
import useCheckAppVersion from '../hooks/useCheckAppVersion';

import { IntercomCustom } from '../utils/intercom';
import { UtmTracker } from '../services/utmTracker';
import { HOME_URL, isNative } from '../utils/mobile';
import { getParsedQueryString } from '../utils/querystring';
import { Mixpanel, MIXPANEL_TAGS } from '../utils/mixpanel';
import { isAuthenticated, getRedirectURLFromLocalStorage } from '../services/api';

import MyPets from '../pages/MyPets';
import Orders from '../pages/Orders';
import Network from '../pages/Network';
import PetPage from '../pages/PetPage';
import Pricing from '../pages/Pricing';
import Business from '../pages/Business';
import Services from '../pages/Services';
import TestPage from '../pages/TestPage';
import Vouchers from '../pages/Vouchers';
import Dashboard from '../pages/Dashboard';
import LoginPage from '../pages/LoginPage';
import PetCreate from '../pages/PetCreate';
import Statements from '../pages/Statements';
import PetEditPage from '../pages/PetEditPage';
import LandingPage from '../pages/LandingPage';
import Appointments from '../pages/Appointments';
import Notification from '../pages/Notification';
import Telemedicine from '../pages/Telemedicine';
import ProfileManage from '../pages/ProfileManage';
import PublicServices from '../pages/PublicServices';
import TelemedicineCall from '../pages/TelemedicineCall';
import CheckRedirectPage from '../pages/checkRedirectPage';
import FacebookCallbackPage from '../pages/FacebookCallback';
import VoucherDetailPage from '../pages/Vouchers/VoucherDetail';
import EventPage from '../pages/Services/Event/EventDetailPage';
import PartnersPage from '../pages/Services/Partners/PartnersPage';
import RedirectShortLinkPage from '../pages/RedirectShortLinkPage';
import VoucherCheckoutPage from '../pages/Vouchers/VoucherCheckout';
import PublicServicesSchedule from '../pages/PublicServicesSchedule';
import EventCreatePage from '../pages/Services/Event/EventCreatePage';
import AppointmentDetail from '../pages/Appointments/AppointmentDetail';
import AppointmentEditCreate from '../pages/Appointments/AppointmentCreate';
import AppointmentSchedule from '../pages/Appointments/AppointmentSchedule';
import Announcement from '../pages/Announcement';
import PetsListenerProtocols from '../pages/PetsListenerProtocols';

const Routes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserLogged = isAuthenticated();
  const { publicSchedule } = useSelector((state) => state.Appointments);
  const redirectFromLoginURL = useSelector((state) => state.User.redirectFromLoginURL);

  const appUrl = process.env.REACT_APP_WEB;

  useCloseModals();
  useCheckAppVersion();

  const getQueryString = (_urlSearch, _url) => {
    const querystring = getParsedQueryString(_urlSearch);
    let utmObject = {};

    /* eslint-disable camelcase */
    if (_url.includes('utm')) {
      const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = querystring;
      const utm_object = {
        utm_source: utm_source || null,
        utm_medium: utm_medium || null,
        utm_campaign: utm_campaign || null,
        utm_term: utm_term || null,
        utm_content: utm_content || null,
        url: _url,
      };
      utmObject = utm_object;

      Mixpanel.people.set({ ...utmObject });
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'utms', value: JSON.stringify(utm_object), from: 'web' } }));
      }

      localStorage.setItem('utms', JSON.stringify(utm_object));
    }

    const { pathname, search } = new URL(_url);
    UtmTracker.trackPage({ url: _url, pathname, search, route: pathname + search, ...utmObject });
  };

  useLayoutEffect(() => {
    const urlLocationSearch = history.location?.url ? `?${history.location?.url.split(/[?#]/)[1]}` : location.search;
    const url = history.location?.url || location.href;
    getQueryString(urlLocationSearch, url);
    const { pathname } = new URL(url);
    const tab = pathname && pathname.split('/')[1] ? pathname.split('/')[1] : 'dashboard';
    window.ReactNativeWebView?.postMessage(JSON.stringify({ CHANGE_URL_LOCATION: { tab } }));
    Mixpanel.track(MIXPANEL_TAGS.PAGE_VIEW, { url: window.location.href, pathname });
  }, [location.href]);

  useLayoutEffect(() => {
    const querystring = getParsedQueryString(location.search);

    if (querystring?.openbot) {
      localStorage.setItem('openbot', querystring.openbot);
    }
  }, []);

  useLayoutEffect(() => {
    IntercomCustom.signoutIntercom();
    IntercomCustom.startHiddenBot();
    if (isNative) window.ReactNativeWebView?.postMessage('fetchDeviceData');
  }, [location.href]);

  useEffect(() => dispatch.User.authRedirect(history), [history]);

  useEffect(() => {
    if (isUserLogged) {
      dispatch.Appointments.myServiceListCount();
    }
  }, []);

  // useEffect(() => setInterval(() => {
  //   if (isUserLogged) {
  //     dispatch.Notification.getSummary();
  //   }
  // }, 10000), []);

  useEffect(() => {
    if (isUserLogged) {
      dispatch.User.utmTrack();
    }
  }, [isUserLogged]);

  const openChat = async () => {
    const getUserData = await dispatch.User.getUserProfileData();
    if (getUserData && getUserData.data) {
      if (getUserData?.data?.signingPlan?.defaultPlan === false) {
        window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
        IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });
        Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
        return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);
      }

      Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
      return history.push('/pricing');
    }

    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
    return history.push('/pricing');
  };

  const onOpenModalCreateProtocol = async ({ protocol }) => {
    if (protocol) {
      await dispatch.Modal.setLoadingDialog({ loading: true });
      await dispatch.Animals.setSelectedProtocol(protocol);
      await dispatch.Animals.getAnimalProtocolProducts({ protocolId: protocol.id, name: '', changeRedux: true });
      Mixpanel.track(MIXPANEL_TAGS.PROTOCOL_ANIMAL_NEW_CLICK, protocol);
      await dispatch.Modal.setLoadingDialog({ loading: false });
      await dispatch.Modal.setDialog({ open: true, content: protocol.type, mode: 'create' });
    }
  };

  useEffect(() => {
    const listenerEvent = async (event) => {
      if (event?.origin === appUrl) {
        const { pathRedirect, logoutUser, clickOpenChat, openModalCreateProtocol, loadWebviewFromApp, updateRematch, updateRematchFromMobile } = event.data;

        if (updateRematchFromMobile) {
          dispatch(updateRematchFromMobile?.action);
        }

        if (loadWebviewFromApp) {
          const newUserJWT = loadWebviewFromApp?.userJWT;
          if (newUserJWT) localStorage.setItem('userJWT', newUserJWT);
          const referredBy = loadWebviewFromApp?.referredBy;
          if (referredBy) localStorage.setItem('referredBy', referredBy);
          const petId = loadWebviewFromApp?.petId;
          if (petId) history.push(`/pet/${petId}`);
        }

        if (updateRematch && updateRematch?.state && updateRematch?.function) {
          await dispatch[updateRematch.state][updateRematch?.function](updateRematch.paramsFunction || null);
        }

        if (pathRedirect) {
          if (pathRedirect.includes('#')) {
            location.href = `${location.origin}${pathRedirect}`;
          } else {
            history.push(pathRedirect);
          }
        }

        if (logoutUser) {
          Mixpanel.track(MIXPANEL_TAGS.SIGNOUT);
          dispatch.User.logout();
        }

        if (clickOpenChat) {
          openChat();
        }

        if (openModalCreateProtocol) {
          onOpenModalCreateProtocol(event.data);
        }
      }
    };

    document.addEventListener('message', listenerEvent);
    window.addEventListener('message', listenerEvent);

    return () => {
      document.removeEventListener('message', listenerEvent);
      window.removeEventListener('message', listenerEvent);
    };
  }, []);

  const onGoogleLoginSuccess = async (_data) => {
    await dispatch.User.SET_LOADING({ loginGoogle: 'loading' });

    if (window.location.pathname !== '/login') {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'redirectUrl', value: window.location.pathname, from: 'web' } }));
      }
      localStorage.setItem('redirectUrl', window.location.pathname);
      dispatch.User.setRedirectFromLoginURL(window.location.pathname);
    }

    const response = { idToken: _data?.credential };

    if (publicSchedule && publicSchedule.serviceId) {
      const eventTrigger = publicSchedule?.serviceId && {
        triggerName: 'service-scheduling',
        type: 'redirect',
        urlPattern: publicSchedule?.url,
        payload: {
          userB2BId: publicSchedule?.userB2BId,
          serviceId: publicSchedule?.serviceId,
          eventStart: publicSchedule?.eventStart,
        },
      };

      const slug = localStorage.getItem('invitedBy');
      const referredBy = localStorage.getItem('referredBy');
      const slugResponse = { invitedBy: slug, referredBy };

      const googleResponse = { ...response, eventTrigger, slugResponse };

      try {
        await dispatch.User.loginGoogle(googleResponse);
        const getUserData = await dispatch.User.getUserProfileData();

        let dateJoined = new Date(getUserData.data.dateJoined);
        dateJoined = [dateJoined.getDate(), dateJoined.getMonth() + 1, dateJoined.getFullYear()].join('/');
        const actualDate = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('/');
        const isFirstLogin = (dateJoined === actualDate);

        Mixpanel.track(MIXPANEL_TAGS.SIGNIN_GOOGLE_ONE_TAP, { distinct_id: getUserData.data.id, isFirstLogin });

        history.push(`${HOME_URL}${history.location.search}`);
        window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
      } catch (err) {
        if (err?.response?.status === 401) {
          window.ReactNativeWebView?.postMessage('ERROR_LOGIN_B2B');
        }
        window.ReactNativeWebView?.postMessage('GOOGLE_LOGIN_ERROR');
        return null;
      } finally {
        await dispatch.User.SET_LOADING({ loginGoogle: 'fulfilled' });
      }
    } else {
      try {
        const slug = localStorage.getItem('invitedBy');
        const referredBy = localStorage.getItem('referredBy');
        const slugResponse = { ...response, invitedBy: slug, referredBy };
        await dispatch.User.loginGoogle(slugResponse);
        const getUserData = await dispatch.User.getUserProfileData();

        let dateJoined = new Date(getUserData.data.dateJoined);
        dateJoined = [dateJoined.getDate(), dateJoined.getMonth() + 1, dateJoined.getFullYear()].join('/');
        const actualDate = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()].join('/');
        const isFirstLogin = (dateJoined === actualDate);

        Mixpanel.track(MIXPANEL_TAGS.SIGNIN_GOOGLE_ONE_TAP, { distinct_id: getUserData.data.id, isFirstLogin });

        if (getUserData && getUserData.data) {
          const { eventTrigger, countAnimals } = getUserData.data;

          const redirectUrlAfterLogin = redirectFromLoginURL || getRedirectURLFromLocalStorage();

          if (eventTrigger && eventTrigger.type === 'redirect') {
            if (countAnimals) {
              history.push(`/services/create/${eventTrigger.payload.serviceId}`);
              window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
              return true;
            }
            history.push(`/pet/create${history.location.search}`);
            window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
            return true;
          }

          // if (!isProfileFilled) {
          //   history.push(botToOpen ? `${HOME_URL}${history.location.search}` : `/profile${history.location.search}`);
          //   window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
          //   return true;
          // }

          if (redirectUrlAfterLogin && redirectUrlAfterLogin !== 'undefined') {
            history.push(redirectUrlAfterLogin);
            window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
            return true;
          }
          history.push(`${HOME_URL}${history.location.search}`);
          window.ReactNativeWebView?.postMessage('LOGIN_SUCCESS');
          return true;
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          window.ReactNativeWebView?.postMessage('ERROR_LOGIN_B2B');
        }
        window.ReactNativeWebView?.postMessage('GOOGLE_LOGIN_ERROR');
        return null;
      } finally {
        await dispatch.User.SET_LOADING({ loginGoogle: 'fulfilled' });
      }
    }
    return null;
  };

  useEffect(() => {
    const vaccineFormUrl = window.location.href.includes('vaccine-form');

    if (vaccineFormUrl) return;
    if (window.location?.search?.includes('useraccess')) return;

    window?.google?.accounts?.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: onGoogleLoginSuccess,
      cancel_on_tap_outside: false,
    });
  }, []);

  useEffect(() => {
    const isBusinessUrl = window.location.href.includes(process.env.REACT_APP_BUSINESS) || window.location.href.includes(process.env.REACT_APP_SIGN);

    if (isBusinessUrl) {
      if (isUserLogged) {
        window?.google?.accounts?.id.cancel();
      } else {
        if (window.location?.search?.includes('useraccess')) return;
        window?.google?.accounts?.id.disableAutoSelect();
        window?.google?.accounts?.id.prompt();
      }
    }
  }, [isUserLogged]);

  return (
    <Switch>
      <PrivateRoute exact path='/' component={Dashboard} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} />
      <PrivateRoute exact path='/network' component={Network} />
      <PrivateRoute exact path='/profile' component={ProfileManage} />
      <PrivateB2BRoute exact path='/business' component={Business} />
      <PrivateRoute exact path='/vouchers' component={Vouchers} />
      <PrivateRoute exact path='/vouchers/:id' component={VoucherDetailPage} />
      <PrivateRoute exact path='/vouchers/:uuid/:slug' component={VoucherDetailPage} />
      <PrivateRoute exact path='/orders' component={Orders} />
      <PrivateRoute exact path='/orders/:id' component={VoucherCheckoutPage} />
      <PrivateB2BRoute exact path='/statements' component={Statements} />
      <PrivateRoute exact path='/services' component={Services} />
      <PrivateRoute exact path='/services/event/:id' component={EventPage} />
      <PrivateRoute exact path='/services/partners/:id' component={PartnersPage} />
      <PrivateRoute exact path='/services/create/:id' component={EventCreatePage} />
      <PrivateRoute exact path='/services/telemedicine' component={Telemedicine} />
      <PrivateRoute exact path='/services/telemedicine-call' component={TelemedicineCall} />
      <PrivateRoute exact path='/appointments' component={Appointments} />
      <PrivateRoute exact path='/appointments/schedule/:id/:schedulingId?/:startdate?' component={AppointmentSchedule} />
      <PrivateRoute exact path='/appointments/edit/:id' component={AppointmentEditCreate} />
      <PrivateRoute exact path='/appointments/create' component={AppointmentEditCreate} />
      <PrivateRoute exact path='/appointments/:id' component={AppointmentDetail} />
      <PrivateRoute exact path='/pet/create' component={PetCreate} />
      <PrivateRoute exact path='/pet/edit/:id' component={PetEditPage} />
      <PrivateRoute exact path='/pet/:id' component={PetPage} />
      <PrivateRoute exact path='/notifications' component={Notification} />
      <PublicRoute exact path='/public-services/triage-call' component={TelemedicineCall} />
      <PublicRoute exact path='/public-services/:id/:companyName' component={PublicServices} />
      <PublicRoute exact path='/public-services/:id/:serviceId/:companyName' component={PublicServicesSchedule} />
      <PublicRoute exact path='/login' component={LoginPage} />
      <PublicRoute exact path='/fb/callback' component={FacebookCallbackPage} />
      <PublicRoute exact path='/check-redirect' component={CheckRedirectPage} />
      <PublicRoute exact path='/r/:hash' component={RedirectShortLinkPage} />
      <PublicRoute exact path='/pricing' component={Pricing} />
      <PublicRoute exact path='/pricing/:slug' component={Pricing} />
      <PrivateRoute exact path='/test-route' component={TestPage} />
      <PublicRoute exact path='/pets-listener-protocols' component={PetsListenerProtocols} />
      <PublicRoute exact path='/invite-link/:slug' component={LoginPage} />
      <PublicRoute exact path='/invite-qrcode/:slug' component={LoginPage} />
      <PublicRoute exact path='/referred-by/:slug' component={LoginPage} />
      <PublicRoute exact path='/public-services/vaccine-form:token?' component={VaccineForm} />
      <PrivateRoute exact path='/pets' component={MyPets} />
      <PublicRoute exact path='/landing' component={LandingPage} />
      <PublicRoute exact path='/announcements' component={Announcement} />
      <Route path='*' component={Dashboard} />
    </Switch>
  );
};

export default Routes;
