import React from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';
import { BrowserRouter } from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Routes from './routes';

import LandingView from './components/LandingView';
import TermsModal from './components/Modals/TermsModal';
import StoreRedirect from './components/StoreRedirect';

const App = () => {
  const isNative = navigator?.userAgent && navigator.userAgent.includes('wv') && (navigator.userAgent.includes('Android') || navigator.userAgent.includes('iOS'));

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <BrowserRouter>
      <LocalizationProvider dateAdapter={DateAdapter} locale={moment.locale('pt-br')}>
        {isNative ? <StoreRedirect /> : (
          <>
            <TermsModal />
            {/* <LandingView /> */}
            <Routes />
          </>
        )}
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
