import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Grid } from '@mui/material';

import banner from '../../../assets/images/PetCard/carteirinhavirtual.png';

import { isNative } from '../../../utils/mobile';
import useWindowSize from '../../../hooks/windowSize';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

import { Image } from './style';

const VirtualVaccineCard = () => {
  const [isDesktop, setIsDesktop] = useState(true);

  const screen = useWindowSize();

  useLayoutEffect(() => {
    setIsDesktop(screen.width > 736);
  }, [screen.width]);

  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.VIRTUAL_VACCINE_CARD_LOAD);
  }, []);

  if (isNative) {
    return (
      <Grid container sx={{ width: '100%', display: isDesktop ? 'none' : 'block' }}>
        <Grid item>
          <Image src={banner} alt='carteirinha de vacinação digital' />
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default VirtualVaccineCard;
