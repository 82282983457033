import React from 'react';
import { Fade, Grid, Tooltip } from '@mui/material';
import { StyledCard, CardTitle, CardMonetary, CardValue, CardItens, StyledMoneyIcon } from './style';
import Loading from '../../../global/Loading';

const StatementCard = ({ title, value, quantity, loading, tooltip, height }) => (
  <Fade in>
    <StyledCard elevation={0} height={height} sx={{ cursor: 'default' }}>
      <Grid container flexDirection='column'>
        <Grid container flexDirection='row' justifyContent='space-between'>
          <Grid item>
            <CardTitle>{title}</CardTitle>
          </Grid>

          <Tooltip title={tooltip} placement='top'>
            <StyledMoneyIcon />
          </Tooltip>
        </Grid>

        <Grid container columnGap={1} sx={{ marginTop: '12px' }}>
          <Grid item>
            <CardMonetary variant='h4'>R$</CardMonetary>
          </Grid>

          {loading ? (
            <Grid item>
              <Loading />
            </Grid>
          ) : (
            <Grid item>
              <Grid container flexDirection='column'>
                <Grid item>
                  <CardValue variant='h4'>{value}</CardValue>
                </Grid>

                <Grid item>
                  <CardItens variant='subtitle1'>
                    {quantity}
                    {' '}
                    vale(s)
                  </CardItens>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

      </Grid>
    </StyledCard>
  </Fade>
);

export default StatementCard;
