import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper, StyledContainer } from './style';
import PetCreateForm from '../../components/PetCreate/PetCreateForm';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const PetCreate = () => {
  useEffect(() => {
    Mixpanel.track(MIXPANEL_TAGS.PET_CREATE_VIEW);
  }, []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      <StyledContainer style={{ marginTop: '20px' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <PetCreateForm style={{ marginRight: '40px' }} />
          </Grid>
        </Grid>
      </StyledContainer>
      <Footer />
    </MainWrapper>
  );
};

export default PetCreate;
