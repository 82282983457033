import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { TableContainer, Typography } from '@mui/material';
import { animations } from '../../../utils/theme';

export const StyledContainer = styled(Container)`
  margin-top: 10px;
  animation: ${animations.fadeIn} .2s ease-in;
`;

export const StyledTableContainer = styled(TableContainer)`
  margin-bottom: 60px;
  animation: ${animations.fadeIn} .2s ease-in-out;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0 0 15px;
  margin-bottom: 5px;
`;

export const TableFilters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: auto;
  width: 450px;
`;

export const Filter = styled(Typography)`
  &.MuiTypography-root {
    cursor: pointer;
    opacity: ${({ selected }) => (selected ? '1' : '0.5')};
    transition: opacity .2s, border-bottom .2s;
    border-bottom:  ${({ selected }) => (selected ? '2px solid #6B48FF' : '2px solid transparent')};
    user-select: none;

    &:hover {
      opacity: ${({ selected }) => (selected ? '1' : '0.7')};
    }
  }
`;
