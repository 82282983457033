import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../../utils/mobile';
import { ReactComponent as EventsIcon } from '../../../../../assets/images/Services/CalendarHelper/event.svg';
import { ReactComponent as ActiveIcon } from '../../../../../assets/images/Services/CalendarHelper/active.svg';
import { ReactComponent as SelectedIcon } from '../../../../../assets/images/Services/CalendarHelper/selected.svg';
import { ReactComponent as UnavailableIcon } from '../../../../../assets/images/Services/CalendarHelper/unavailable.svg';

const CalendarHelperDialog = ({ open, setModalOpen }) => (
  <Dialog
    sx={{ '& .MuiDialog-paper': {
      minWidth: '350px',
    },
    '& .MuiDialog-container': {
      height: 'auto',
      marginTop: isMobile ? '55px' : '150px',
    } }}
    fullScreen={isMobile}
    open={open}
    onBackdropClick={() => setModalOpen(false)}
  >
    <DialogTitle>
      <Grid container direction='row' justifyContent='space-between'>
        <Grid item>
          Entenda o calendário
        </Grid>

        <Grid item>
          <CloseIcon onClick={() => setModalOpen(false)} />
        </Grid>
      </Grid>
    </DialogTitle>
    <DialogContent dividers>
      <Grid container direction='column' gap={1}>
        <Grid container direction='row' alignItems='center' gap={1}>
          <Grid item>
            <ActiveIcon />
          </Grid>

          <Grid item>
            <Typography variant='body2'>Dia atual</Typography>
          </Grid>
        </Grid>

        <Grid container direction='row' alignItems='center' gap={1}>
          <Grid item>
            <UnavailableIcon />
          </Grid>

          <Grid item>
            <Typography variant='body2'>Dia sem horários disponíveis</Typography>
          </Grid>
        </Grid>

        <Grid container direction='row' alignItems='center' gap={1}>
          <Grid item>
            <SelectedIcon />
          </Grid>

          <Grid item>
            <Typography variant='body2'>Dia selecionado</Typography>
          </Grid>
        </Grid>

        <Grid container direction='row' alignItems='center' gap={1}>
          <Grid item>
            <EventsIcon />
          </Grid>

          <Grid item>
            <Typography variant='body2'>Dias com eventos</Typography>
          </Grid>
        </Grid>
      </Grid>

    </DialogContent>
    <DialogActions>
      <Button sx={{ color: '#6B48FE' }} onClick={() => setModalOpen(false)}>Ok, entendi</Button>
    </DialogActions>
  </Dialog>
);

export default CalendarHelperDialog;
