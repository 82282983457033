/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../hooks/windowSize';
import { ButtonControl, PurpleButton, PrimaryButton } from './style';

const RequestSignatureOnAppDialog = () => {
  const screen = useWindowSize();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.Modal.requestSignatureOnAppDialog?.open);
  const protocolId = useSelector((state) => state.Modal.requestSignatureOnAppDialog?.protocolId);
  const userData = useSelector((state) => state.User.userData);
  const { actualPet } = useSelector((state) => state.Animals) || {};

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: screen.width < 739 ? 300 : 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const handleCloseDialog = () => {
    dispatch.Modal.setRequestSignatureOnAppModal({ open: false });
  };

  const handleConfirm = async () => {
    handleCloseDialog();

    const getUserData = await dispatch.User.getUserProfileData();
    const { email, phone, firstName, address, cpfCnpj } = getUserData.data;

    const editPermission = actualPet.permissions && actualPet.permissions.editProfile;
    if (!editPermission) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
      return null;
    }

    if (getUserData && getUserData.data) {
      const isAddressFilled = address && address?.code && (address?.number !== null || address?.number !== undefined) && address?.city && address?.street && address?.district && address?.state;
      const isProfileFilled = firstName && email && phone && isAddressFilled && cpfCnpj;

      if (!isProfileFilled) {
        dispatch.Modal.setProfileDialog({ open: true, desc: 'Para gerar a carteirinha digital, precisamos de algumas informações do seu perfil' });
        return null;
      }

      const { furColor, furSize, breed, name, sex, birthdate } = actualPet;
      const isPetFilled = furColor?.length && furSize && breed && name && sex && birthdate;

      if (!isPetFilled) {
        dispatch.Modal.setPetDialog({ open: true });
        return null;
      }
      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: { requestSignature: protocolId } }));
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      style={{ width: '100vw' }}
    >
      <div>
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Carteirinha de vacinas digital
          </Typography>

          <Typography id='modal-modal-description' sx={{ my: 4, textAlign: 'center' }}>
            Deseja solicitar uma assinatura do seu veterinário para o controle cadastrado?
          </Typography>

          <ButtonControl>
            <PurpleButton onClick={handleCloseDialog}>Voltar</PurpleButton>

            <PrimaryButton variant='outlined' onClick={handleConfirm}>
              Solicitar
            </PrimaryButton>

          </ButtonControl>
        </Box>
      </div>
    </Modal>
  );
};

export default RequestSignatureOnAppDialog;
