/* eslint-disable no-sparse-arrays */
import React, { useState, useRef, useEffect } from 'react';

import * as Yup from 'yup';
import { omit } from 'lodash';
import { Form, Formik } from 'formik';
import Grow from '@mui/material/Grow';
import { Close } from '@mui/icons-material';
import { MenuItem } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Autocomplete, FormHelperText, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { ApplyCoupon } from './style';
import Alert from '../../../global/Alert';
import Loading from '../../../global/Loading';
import { formatBRL } from '../../../../utils/format';
import { handlePlatform } from '../../../../utils/platform';
import theme, { CustomLink } from '../../../../utils/theme';
import { isMobile, isNative } from '../../../../utils/mobile';
import { validateSymbols } from '../../../../utils/validators';
import { validateCPF } from '../../../../services/documentValidations';

const PricingSigningConfirmation = () => {
  const dispatch = useDispatch();

  const { native } = useSelector((state) => state.User.userData);
  const selectedPlan = useSelector((state) => state.SigningPlans.selectedPlan);
  const { open } = useSelector((state) => state.SigningPlans.modal.signingConfirmation);
  const { fullName, cpfCnpj, phone: phoneNumber, address } = useSelector((state) => state.User.userData);

  const couponFieldRef = useRef(null);
  const isAndroid = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('Android');

  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [couponTag, setCouponTag] = useState('');
  const [useCoupon, setUseCoupon] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cpfInputValue, setCpfInputValue] = useState(null);
  const [submitedStatus, setSubmitedStatus] = useState('');
  const [couponLoading, setCouponLoading] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [promotionCodeId, setPromotionCodeId] = useState('');
  const [couponValidated, setCouponValidated] = useState(false);
  const [couponApplyVisible, setCouponApplyVisible] = useState(false);
  const [errorMessage] = useState('Essa assinatura não está disponível para sua região!');

  useEffect(() => {
    setCpfInputValue(cpfCnpj);
    scrollTo(0, 0);
  }, [, cpfCnpj]);

  const fetchState = async () => {
    const response = await dispatch.User.getStateOptions();

    if (response && response.data.length) {
      setStateOptions(response.data);
    } else {
      setStateOptions([]);
    }
  };

  const fetchCityOfUF = async (uf, name) => {
    const response = await dispatch.User.getCityOptions({ uf, name });

    if (response.data.length) {
      setCityOptions(response.data);
    } else {
      setCityOptions([]);
    }
  };

  const getDiscountCouponBalance = async () => {
    const { price } = selectedPlan;

    const response = await dispatch.User.getDiscountCouponBalance();

    setBalance(response.balance > price ? price : response.balance);
  };

  useEffect(() => {
    fetchState();
  }, []);

  useEffect(() => {
    getDiscountCouponBalance();
  }, [selectedPlan.priceId]);

  // eslint-disable-next-line consistent-return
  const validateCoupon = async (coupon) => {
    if (coupon) {
      setCouponValidated(false);
      setCouponLoading(true);

      const couponResponse = await dispatch.SigningPlans.checkPromotionCodeValid({ coupon, priceId: selectedPlan.priceId });

      if (couponResponse && couponResponse.data) {
        setPromotionCodeId(couponResponse.data.id);
        setIsCouponValid(true);
        setCouponValidated(true);
        setCouponLoading(false);
        setCouponApplyVisible(false);
        setCouponTag(coupon);
        couponFieldRef.current.value = '';
        return true;
      }

      setCouponValidated(true);
      setCouponLoading(false);
      setIsCouponValid(false);
      setCouponTag('');
      return false;
    }
  };

  const handleCouponDelete = (setFieldValue) => {
    couponFieldRef.current.value = '';
    setFieldValue('coupon', '');
    setCouponTag('');
    setPromotionCodeId('');
    setIsCouponValid(false);
    setCouponValidated(false);
  };

  const handleStateOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('city', '');
      setFieldValue('uf', event.uf);
      fetchCityOfUF(event.uf, '');
    }
  };

  const handleCityOptionsChange = (event, setFieldValue) => {
    if (event) {
      setFieldValue('city', event.name);
    }
  };

  const handleCityTextChange = (event, setFieldValue, uf) => {
    const { value } = event.target;
    setFieldValue('city', value);
    fetchCityOfUF(uf, value);
  };

  // eslint-disable-next-line consistent-return
  const handleConfirm = async ({ sameUser, name, coupon, cpf, phone, street, addressId, uf, district, city, cep, complement, number }) => {
    setLoading(true);

    if (coupon && !couponValidated && !promotionCodeId) {
      const validCoupon = await validateCoupon(coupon);

      if (!validCoupon) {
        setLoading(false);
        return;
      }
    }

    const payload = {
      sameUser,
      fullName: name,
      priceId: selectedPlan.priceId,
      isRecurrent: selectedPlan.isRecurrent,
      platform: handlePlatform(),
      successUrl: `${process.env.REACT_APP_WEB}/pricing?success`,
      cancelUrl: `${process.env.REACT_APP_WEB}/pricing?cancelled`,
      cpfCnpj: cpf.replace(/[^A-Z0-9]/ig, ''),
      phone: phone?.toString()?.replace(/[^A-Z0-9]/ig, ''),
      address: {
        id: addressId,
        street,
        state: uf,
        city,
        code: cep.replace(/[^A-Z0-9]/ig, ''),
        complement,
        number,
        district,
      },
      myCoupons: useCoupon,
      ...(promotionCodeId && { promotionCode: promotionCodeId }),
    };

    const createCheckout = await dispatch.SigningPlans.signingPlanSubscribe(payload);

    if (createCheckout?.response?.data?.message === 'signinplan is not available for your region') {
      setSubmitedStatus('error');
      setLoading(false);
      setTimeout(() => setSubmitedStatus(''), 8000);
      return;
    }

    if (createCheckout && createCheckout.status === 204) {
      return;
    }

    if (createCheckout && createCheckout.data) {
      if (isAndroid && process.env.REACT_APP_NATIVE_ENV === 'NATIVE') {
        window.ReactNativeWebView?.postMessage(JSON.stringify(createCheckout.data));
      } else if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ redirectUrl: { url: createCheckout.data.checkoutUrl } }));
      } else {
        window.location.href = createCheckout.data.checkoutUrl;
      }
    }

    setLoading(false);
  };

  const handleCEP = async (cep, setFieldValue) => {
    const parsedCep = cep && cep.target.value.replace(/[^A-Z0-9]/ig, '');
    setFieldValue('cep', cep.target.value);

    if (parsedCep && parsedCep.length === 8) {
      setLoadingFields(true);
      const cepResponse = await dispatch.User.addressGetCepInfo(parsedCep);
      setLoadingFields(false);

      if (cepResponse && cepResponse.data) {
        const { street, district, state, city } = cepResponse.data;
        setFieldValue('street', street);
        setFieldValue('district', district);
        const findState = stateOptions.find((a) => a.state === state || a.uf === state) || { uf: '' };
        setFieldValue('uf', findState.uf);

        const response = await dispatch.User.getCityOptions({ uf: findState.uf, name: city });
        if (response.data.length) {
          const findCity = response.data.find((a) => a.name === city) || { name: '' };
          setFieldValue('city', findCity.name);
          setCityOptions(response.data);
        } else {
          setCityOptions([]);
          setFieldValue('city', '');
        }
      }
    }
  };

  const handleRadio = (value, setFieldValue) => {
    setFieldValue('sameUser', value);
    setFieldValue('name', value ? fullName : '');
    setFieldValue('cpf', value ? cpfCnpj : '');
    setFieldValue('phone', value ? phoneNumber : '');
    setFieldValue('cep', value ? address?.code || '' : '');
    setFieldValue('street', value ? address?.street || '' : '');
    setFieldValue('district', value ? address?.district || '' : '');
    setFieldValue('uf', value ? address?.uf || '' : '');
    setFieldValue('city', value ? address?.city || '' : '');
    setFieldValue('number', value ? address?.number || '' : '');
    setFieldValue('complement', value ? address?.complement || '' : '');
    setFieldValue('addressId', value ? address?.id || null : null);
  };

  const openSearchCEP = () => {
    if (isNative) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: 'https://buscacepinter.correios.com.br/app/endereco/index.php' } }));
      return;
    }
    window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank');
  };

  return (
    <>

      <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center'>
        <Snackbar
          open={!!submitedStatus}
          onClose={() => setSubmitedStatus('')}
          autoHideDuration={8000}
          TransitionComponent={(props) => <Grow {...props} />}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          key='growTransition.name'
          sx={{ marginTop: 5, display: !submitedStatus ? 'none' : 'flex' }}
        >
          <div>
            <Alert severity='error' sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </div>
        </Snackbar>
      </Grid>

      <Dialog
        sx={{ '& .MuiDialog-paper': {
          minWidth: '350px',
        },
        '& .MuiDialog-container': {
          marginTop: isMobile ? '55px' : '0px',
          height: isMobile ? '85vh' : '100vh',
          overflow: 'auto',
        } }}
        fullScreen={isMobile}
        open={open}
        onBackdropClick={() => undefined}
        scroll='paper'
      >
        <DialogTitle>
          <Grid container justifyContent='flex-end' alignItems='flex-end'>
            <Grid item>
              <Close sx={{ cursor: 'pointer' }} onClick={() => dispatch.SigningPlans.setModal({ signingConfirmation: { open: false, data: {} } })} />
            </Grid>
          </Grid>
          <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '34px' }} gap={2}>
            <Grid item>
              <Typography sx={{ fontSize: '32px', lineHeight: '35px', fontWeight: 600, textAlign: 'center' }}>Dados para pagamento</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            validateOnChange={false}
            initialValues={{
              sameUser: true,
              cpf: cpfInputValue,
              ...(omit(address || {}, ['id'])),
              addressId: address?.id || null,
              cep: address?.code || '',
              uf: address?.uf || '',
              city: address?.city || '',
              name: fullName,
              phone: phoneNumber,
            }}
            onSubmit={handleConfirm}
            validationSchema={
              Yup.object().shape({
                sameUser: Yup.boolean(),
                name: Yup.string().required('Preencha o nome').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                cpf: Yup.string().required('Preencha o CPF').nullable().test('cpfValidation', 'Este CPF é inválido', (value) => {
                  if (!value) return true;
                  return validateCPF(value);
                }),
                cep: Yup.string().required('Preencha o CEP'),
                street: Yup.string().required('Preencha o endereço').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                district: Yup.string().required('Preencha o bairro').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                number: Yup.string().required('Preencha o número do endereço').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                uf: Yup.string().required('Preencha o estado').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                city: Yup.string().required('Preencha a cidade').test(validateSymbols.name, validateSymbols.error, validateSymbols.validate),
                phone: Yup.string()
                  .required('Favor preencher o telefone')
                  .test('len', 'Preencher número completo, incluindo DDD e dígito 9', (val) => {
                    const withoutDashes = val && val.replace(/\D/g, '').length;
                    return withoutDashes === 11;
                  }),
              })
              }
          >
            {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Grid item sx={{ margin: '20px 0' }}>
                  <FormControl>
                    <Typography sx={{ fontSize: '14px' }}>Os dados de pagamento são os mesmo dados do usuário</Typography>
                    <RadioGroup
                      id='sameUser'
                      defaultValue
                      value={values.sameUser}
                      onChange={(event) => handleRadio(JSON.parse(event.target.value), setFieldValue)}
                      row
                    >
                      <FormControlLabel id='sameUser' name='sameUser' label='Sim' value control={(<Radio />)} />
                      <FormControlLabel id='sameUser' name='sameUser' label='Não' value={false} control={(<Radio />)} />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid container flexDirection='column' gap={4}>
                  <Grid item fullWidth>
                    <TextField
                      id='name'
                      name='name'
                      label='Nome'
                      touched={touched.name}
                      error={errors && errors.name}
                      helperText={errors && errors.name}
                      value={values.name}
                      onChange={handleChange}
                      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      autoComplete='new-password'
                    />
                  </Grid>

                  <Grid item fullWidth>
                    <ReactInputMask
                      mask='999.999.999-99'
                      onChange={handleChange}
                      value={values.cpf}
                      maskplaceholder='_'
                    >
                      {(inputProps) => (
                        <TextField
                          error={!!errors.cpf}
                          helperText={errors.cpf && errors.cpf}
                          touched={touched.cpf}
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                          label='CPF'
                          id='cpf'
                          placeholder='000.000.000-00'
                          name='cpf'
                          type='tel'
                          InputLabelProps={{ shrink: true }}
                          autoComplete='new-password'
                          defaultValue={cpfInputValue}
                          {...inputProps}
                        />
                      )}
                    </ReactInputMask>
                  </Grid>

                  <Grid item fullWidth>
                    <ReactInputMask
                      mask='\(99\) 9 9999-9999'
                      onChange={handleChange}
                      value={values.phone}
                      maskplaceholder='_'
                    >
                      {(inputProps) => (
                        <TextField
                          error={!!errors.phone}
                          helperText={errors.phone && errors.phone}
                          touched={touched.phone}
                          sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                          label='Celular'
                          placeholder='(00) 0 0000-0000'
                          id='phone'
                          name='phone'
                          type='tel'
                          InputLabelProps={{ shrink: true }}
                          autoComplete='new-password'
                          {...inputProps}
                        />
                      )}
                    </ReactInputMask>
                  </Grid>

                  <Grid container gap={3} wrap='nowrap' alignItems='center'>
                    <Grid item fullWidth>
                      <ReactInputMask
                        mask='99999-999'
                        onChange={(value) => handleCEP(value, setFieldValue)}
                        value={values.cep}
                        disabled={false}
                        maskplaceholder='_'
                      >
                        {(inputProps) => (
                          <TextField
                            error={!!errors.cep}
                            helperText={errors.cep && errors.cep}
                            touched={touched.cep}
                            sx={{ width: '80%', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                            label='CEP'
                            id='cep'
                            placeholder='00000-000'
                            name='cep'
                            type='tel'
                            InputLabelProps={{ shrink: true }}
                            autoComplete='new-password'
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                    </Grid>

                    <Grid item sx={{ width: '60%' }}>
                      <Typography
                        onClick={() => openSearchCEP()}
                        sx={{ fontSize: '12px', color: theme.GUIAVET, textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        Não sei meu CEP
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item fullWidth>
                    <TextField
                      id='street'
                      name='street'
                      label='Endereço'
                      value={values.street}
                      disabled={loadingFields}
                      onChange={handleChange}
                      error={errors && errors.street}
                      helperText={errors && errors.street}
                      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      autoComplete='new-password'
                    />
                  </Grid>

                  <Grid item fullWidth>
                    <TextField
                      id='district'
                      name='district'
                      label='Bairro'
                      value={values.district}
                      disabled={loadingFields}
                      onChange={handleChange}
                      error={errors && errors.district}
                      helperText={errors && errors.district}
                      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      autoComplete='new-password'
                    />
                  </Grid>

                  <Grid item fullWidth>
                    <TextField
                      id='number'
                      name='number'
                      label='Número'
                      type='tel'
                      value={values.number}
                      onChange={handleChange}
                      error={errors && errors.number}
                      helperText={errors && errors.number}
                      sx={{ '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      autoComplete='new-password'
                    />
                  </Grid>

                  <Grid item fullWidth>
                    <TextField
                      id='complement'
                      name='complement'
                      label='Complemento (Opcional)'
                      value={values.complement}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      autoComplete='new-password'
                    />
                  </Grid>

                  <Grid container gap={3} wrap='nowrap'>
                    <Grid item fullWidth flex={1}>
                      <Autocomplete
                        name='uf'
                        disablePortal
                        freeSolo
                        value={stateOptions.find((a) => a.uf === values.uf) || { name: '' }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        error={!!errors.uf}
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(e, value) => handleStateOptionsChange(value, setFieldValue)}
                        options={stateOptions}
                        renderOption={(props, option) => (
                          <MenuItem {...props} value={option.id}>
                            <div>{option.name}</div>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            id='uf'
                            name='uf'
                            label='Estado'
                            error={!!errors.uf}
                            helperText={errors && errors.uf}
                            {...params}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item fullWidth flex={1}>
                      <Autocomplete
                        blurOnSelect='touch'
                        disablePortal
                        id='city'
                        name='city'
                        error={!!errors.city}
                        openOnFocus
                        freeSolo
                        onChange={(e, value) => handleCityOptionsChange(value, setFieldValue)}
                        options={cityOptions}
                        value={{ name: values?.city || '' }}
                        disabled={!values.uf || !stateOptions?.length}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(props, option) => (
                          <MenuItem {...props} value={option.id}>
                            <div>{option.name}</div>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            id='city'
                            name='city'
                            label='Cidade'
                            error={!!errors.city}
                            helperText={errors && errors.city}
                            onChange={(event) => handleCityTextChange(event, setFieldValue, values.uf)}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container flexDirection='column' sx={{ marginTop: '30px' }}>
                    <Grid item fullWidth>
                      <TextField
                        id='coupon'
                        name='coupon'
                        disabled={isCouponValid || useCoupon}
                        inputRef={couponFieldRef}
                        label='Cupom de desconto'
                        value={isCouponValid ? '' : values.coupon}
                        onChange={(event) => {
                          const couponValue = event.target.value.toUpperCase().replace(/\s/g, '');
                          setFieldValue('coupon', couponValue);
                          setCouponApplyVisible(!!couponValue);
                        }}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <Chip label={couponTag.toUpperCase()} sx={{ display: isCouponValid ? 'flex' : 'none' }} onDelete={() => handleCouponDelete(setFieldValue)} />,
                          endAdornment:
  <InputAdornment position='end' sx={{ display: couponApplyVisible ? 'flex' : 'none' }}>
    <ApplyCoupon variant='large' disabled={couponLoading} onClick={() => validateCoupon(values.coupon)}>
      {couponLoading ? <Loading color={theme.backgroundPaper} size={20} /> : 'Aplicar'}
    </ApplyCoupon>
  </InputAdornment>,
                        }}
                        autoComplete='new-password'
                      />
                    </Grid>

                    {couponValidated && (
                      <Grid item>
                        <FormHelperText sx={{ color: isCouponValid ? theme.successColor : theme.warningColor }}>
                          {isCouponValid ? 'Cupom validado' : 'Este cupom não é válido'}
                        </FormHelperText>
                      </Grid>
                    )}

                    <Grid item sx={{ marginTop: '18px' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              sx={{ color: theme.GUIAVET }}
                              checked={acceptedTerms}
                              onChange={() => setAcceptedTerms(!acceptedTerms)}
                            />
                          )}
                          label={(
                            <Typography variant='body2'>
                              Aceito os
                              {' '}
                              <CustomLink
                                style={{ color: theme.GUIAVET, textDecoration: 'underline' }}
                                href='https://www.guia.vet/politica-de-privacidade'
                                target={native ? '_self' : '_blank'}
                              >
                                termos de serviço
                              </CustomLink>
                              {' '}
                              da assinatura
                            </Typography>
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {balance > 0 ? (
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            disabled={false}
                            control={<Checkbox value={useCoupon} sx={{ color: theme.GUIAVET }} onClick={() => setUseCoupon(!useCoupon)} />}
                            label={<Typography variant='body2'>Usar meus cupons da plataforma</Typography>}
                          />
                        </Grid>

                        <Grid item container justifyContent='space-between' sx={{ opacity: useCoupon ? 1 : 0.4 }}>
                          <Grid item>
                            <Typography variant='body2'>Total de descontos</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='body2'>{formatBRL(balance)}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}

                  </Grid>

                  <Grid container justifyContent='flex-end' sx={{ marginTop: '15px' }}>
                    {loading ? (
                      <Grid item>
                        <Loading size={25} />
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button type='submit' variant='large' disabled={!acceptedTerms} sx={{ color: theme.GUIAVET }}>
                          Continuar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PricingSigningConfirmation;
