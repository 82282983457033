import styled from 'styled-components';
import { Alert } from '@mui/material';

export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    @media screen and (min-width: 700px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      font-size: 22px;
    }

    @media screen and (min-width: 380px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 330px;
      font-size: 22px;
    }
  }
`;
