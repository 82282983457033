import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from 'styled-components';

import theme from '../../utils/theme';

export const StyledTabs = styled(Tabs)`
  background-color: white;
  visibility: hidden;
  width: 0;
  height: 0;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  z-index: 100;

  @media screen and (max-width: 739px) {
    visibility: visible;
    width: 100%;
    height: 56px;
  }
`;

export const StyledTab = styled(Tab)`
  opacity: 0.7;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  /* height: auto; */

  &.MuiTab-root {
    min-height: 0px;
    white-space: nowrap;
    font-size: 9px;
    min-width: 30px;
  }

  svg {
    opacity: 0.7;
    min-width: 30px;
    min-height: 30px;

    &.MuiTab-iconWrapper {
      margin-bottom: 0px;
    }
  }

  &.MuiTab-textColorPrimary.Mui-selected {
    color: ${theme.GUIAVET};
  }

  &.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.Mui-selected {
    background-color: white;
    opacity: 1;

    svg {
      opacity: 1;
    }
  }
`;
