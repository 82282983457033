import { isNative } from './mobile';

export class IntercomCustom {
  static INTERCOM_ID = 'avzy2hbm'

  static PARAM = {
    PET_NAME: 'pet_name',
  }

  static openLauncher() {
    const launcher = document.getElementsByClassName('intercom-lightweight-app-launcher');

    const checkLauncherExistence = setInterval(() => {
      if (launcher.length) {
        clearInterval(checkLauncherExistence);

        launcher[0].click();
      }
    }, 100);
  }

  static showLauncherIcon() {
    window.intercomSettings = { ...window.intercomSettings, hide_default_launcher: false };
    window.Intercom('update');
  }

  static startHiddenBot(props) {
    if (localStorage.hasOwnProperty('userData')) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const settings = {
          app_id: this.INTERCOM_ID,
          name: userData.fullName || userData.username,
          email: userData.email,
          phone: userData.phone,
          user_id: this.generateId(userData.id, userData?.intercomHash),
          intercom_user_id: this.generateId(userData.id, userData?.intercomHash),
          user_hash: userData?.intercomHash,
          is_subscriber: userData?.signingPlan && !userData?.signingPlan.defaultPlan,
          signing_plan: userData?.signingPlan,
          hide_default_launcher: true,
          ...props,
        };

        window.intercomSettings = settings;
        window.Intercom('update');
      }
    }
  }

  static updateUserIntercomNative(props) {
    if (localStorage.hasOwnProperty('userData') && isNative) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const settings = {
          app_id: this.INTERCOM_ID,
          name: userData.fullName || userData.username,
          email: userData.email,
          phone: userData.phone,
          user_id: this.generateId(userData.id, userData?.intercomHash),
          intercom_user_id: this.generateId(userData.id, userData?.intercomHash),
          user_hash: userData?.intercomHash,
          is_subscriber: userData?.signingPlan && !userData?.signingPlan.defaultPlan,
          signing_plan: userData?.signingPlan,
          hide_default_launcher: true,
          ...props,
        };

        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateUserIntercom: true, intercomSettings: settings }));
      }
    }
  }

  static customButtonAndStart(classParam, props) {
    if (localStorage.hasOwnProperty('userData')) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const settings = {
          app_id: this.INTERCOM_ID,
          name: userData.fullName || userData.username,
          email: userData.email,
          phone: userData.phone,
          user_id: this.generateId(userData.id, userData?.intercomHash),
          intercom_user_id: this.generateId(userData.id, userData?.intercomHash),
          user_hash: userData?.intercomHash,
          is_subscriber: userData?.signingPlan && !userData?.signingPlan.defaultPlan,
          signing_plan: userData?.signingPlan,
          ...props,
        };

        window.intercomSettings = settings;
        window.Intercom('update');

        setTimeout(() => {
          const div = document.createElement('div');
          const isMobile = window.innerWidth < 736;
          div.setAttribute('class', classParam);
          document.body.appendChild(div);

          setTimeout(() => {
            div.click();
            div.remove();
          }, 900);

          if (isMobile) {
            setTimeout(() => {
              const launcher = document.getElementsByName('intercom-launcher-frame');

              if (launcher.length) {
                launcher[0].style.display = 'none';
              }
            }, 3000);
          }
        }, 700);
      }
    }
  }

  static customButton(classParam) {
    const div = document.createElement('div');
    div.setAttribute('class', classParam);
    document.body.appendChild(div);
    setTimeout(() => {
      div.click();
      div.remove();
      this.openLauncher();
    }, 500);
  }

  static signinIntercom(props) {
    window.Intercom('shutdown');
    if (localStorage.hasOwnProperty('userData')) {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        const settings = {
          app_id: this.INTERCOM_ID,
          name: userData.fullName || userData.username,
          email: userData.email,
          phone: userData.phone,
          user_id: this.generateId(userData.id, userData?.intercomHash),
          user_hash: userData?.intercomHash,
          is_subscriber: userData?.signingPlan && !userData?.signingPlan.defaultPlan,
          signing_plan: userData?.signingPlan,
          intercom_user_id: this.generateId(userData.id, userData?.intercomHash),
          ...props,
        };

        if (isNative && props?.bot === 'chat-vaccine-app') {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ openIntercom: true, intercomSettings: settings }));
          return;
        }
        window.intercomSettings = settings;
        window.Intercom('update');
      }
    }
  }

  static hideIntercom() {
    window.Intercom('hide');
  }

  static signoutIntercom() {
    window.Intercom('shutdown');
  }

  static bootLazyIntercom(time, props) {
    window.Intercom('shutdown');
    window.intercomSettings = {
      app_id: this.INTERCOM_ID,
      ...props,
    };
    setTimeout(() => {
      window.Intercom('update');
    }, time);
  }

  static generateId(userId, intercomHash) {
    if (process.env.REACT_APP_ENV === 'PROD') {
      return intercomHash || userId;
    }

    if (process.env.REACT_APP_ENV === 'DEVELOP') {
      return `D_${intercomHash || userId}`;
    }

    return `L_${intercomHash || userId}`;
  }
}
