import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonModal } from './style';

const CancelOrSubmit = ({ loading }) => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);

  const buttonModeMap = {
    create: 'Cadastrar',
    edit: 'Salvar',
  };

  const handleCloseDialog = () => {
    dispatch.Modal.setDialog({ open: false });
  };

  return (
    <Stack direction='row-reverse' spacing={2} style={{ marginTop: 13 }}>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <ButtonModal mainButton type='submit'>{(mode && buttonModeMap[mode]) || 'Cadastrar'}</ButtonModal>
          <ButtonModal type='button' onClick={handleCloseDialog}>Cancelar</ButtonModal>
        </>
      )}
    </Stack>
  );
};

export default CancelOrSubmit;
