import { Card, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  &.MuiCard-root {
    position: relative;
    width: 298px;
    min-height: 135px;
    background: #6B48FF;
    box-shadow: 0px 4px 4px rgba(92, 69, 93, 0.2);
    border-radius: 13px;
    padding: 23px;
  }
`;

export const StyledPrice = styled(Typography)`
  &.MuiTypography-root {
    font-size: 32px;
    font-weight: 600;
    line-height: 25px;
    color: white;

    small {
      font-size: 20px;
    }
  }
`;

export const DiscountBanner = styled.img`
  position: absolute;
  right: -2px;
  top: -2px;
  z-index: 50;
`;

export const DiscountBannerText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 11px;
    font-weight: 600;
    position: absolute;
    z-index: 51;
    top: 30px;
    right: -10px;
    transform: rotate(48deg);
    color: white;
  }
`;

export const DiscountBannerPercentage = styled(Typography)`
  &.MuiTypography-root {
    font-size: 29px;
    font-weight: 600;
    position: absolute;
    z-index: 51;
    top: 45px;
    right: -10px;
    transform: rotate(48deg);
    color: white;
    line-height: 20px;
  }
`;
