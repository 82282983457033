import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import { StyledGrid } from './style';
import { isMobile, STORE_ANDROID_UPGRADE_APP, STORE_IOS_UPGRADE_APP } from '../../../../utils/mobile';
import { ReactComponent as Background } from '../../../../assets/images/MobileAppDialog/modalBackground.svg';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const MobileAppDialog = () => {
  const open = useSelector((state) => state.User.modal.appUpdateModal.open);
  const showModalOnFront = useSelector((state) => state?.User?.modal?.appUpdateModal?.showModalOnFront) || false;
  const isIOS = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('iOS');

  const redirectToStore = () => {
    const link = isIOS ? STORE_IOS_UPGRADE_APP : STORE_ANDROID_UPGRADE_APP;
    Mixpanel.track(MIXPANEL_TAGS.MODAL_APP_UPDATE_AVAILABLE_REDIRECT_CLICK, { link, from: 'web' });
    window.ReactNativeWebView?.postMessage(JSON.stringify({ redirectUrl: { url: link } }));
  };

  useEffect(() => {
    if (open && showModalOnFront) {
      Mixpanel.track(MIXPANEL_TAGS.MODAL_APP_UPDATE_AVAILABLE_VIEW, { from: 'web' });
    }
  }, [open, showModalOnFront]);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '354px',
        height: '485px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        position: 'absolute',
        top: isMobile ? '20%' : '50%',
        left: isMobile ? '0' : '50%',
        transform: isMobile ? 'none' : 'translate(-50%, -50%)',
      } }}
      fullScreen={isMobile}
      open={open && showModalOnFront}
    >
      <Grid container justifyContent='center'>
        <StyledGrid container direction='column' alignItems='center'>
          <Grid item>
            <Typography variant='h6' sx={{ marginTop: '20px' }}>Atenção</Typography>
          </Grid>
          <Grid item>
            <Background />
          </Grid>
        </StyledGrid>
      </Grid>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography sx={{ opacity: 0.4 }}>{`Temos uma atualização importante do app. Para continuar com a melhor experiência clique no botão e faça a atualização na ${isIOS ? 'Apple Store' : 'Play Store'}.`}</Typography>

        <Grid container justifyContent='center' sx={{ margin: '30px 0' }}>
          <Grid item>
            <Button onClick={() => redirectToStore()} sx={{ color: '#6B48FE' }}>
              ATUALIZAR MEU APP
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MobileAppDialog;
