import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { Grid, Typography } from '@mui/material';
import { StyledGridContainer, StyledGridItem, InviteControl, EmptyNetwork, TableMenu, TableItem } from './style';
import NetworkCard from './NetworkCard';
import { PrimaryButton } from '../../../utils/theme';
import EmptyNetworkImage from '../../../assets/images/Network/empty_network.svg';
import Loading from '../../global/Loading';

const NetworkCardList = () => {
  const dispatch = useDispatch();
  const observer = useRef();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { type: userType } = useSelector((state) => state.User.userData);
  const { networkData, networkCount, context, listType } = useSelector((state) => state.Network);
  const { getNetworkList } = useSelector((state) => state.Network.loading);

  const isTutor = !userType || userType === 'T';
  const hasData = networkData.length;
  const isLoading = getNetworkList !== 'fulfilled';

  const [menu, setMenu] = useState(listType);

  const getNetworkCount = async () => {
    if (context !== 'table') {
      dispatch.Network.getNetworkCount({ listType });
    }
  };

  const fetchNetworkList = async () => {
    if (hasMoreItems && context !== 'table') {
      let response;
      if (page === 0) {
        response = await dispatch.Network.getNetworkList({ page, limit: rowsPerPage, listType });
      } else {
        response = await dispatch.Network.addToNetworkList({ page, limit: rowsPerPage, listType });
      }

      const responseIsEmpty = response && response && !response.data.length;

      if (responseIsEmpty || networkData.length === networkCount) {
        setHasMoreItems(false);
      }

      if (response && response.data) {
        setPage(page + 1);
      }
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNetworkList();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [page],
  );

  const handleListType = (value) => {
    if (!isTutor || value === listType) {
      return;
    }

    setHasMoreItems(true);
    setPage(0);
    setMenu(value);
    dispatch.Network.setListType(value);
  };

  useEffect(() => {
    setHasMoreItems(true);
    setPage(0);
    dispatch.Network.clearNetworkList();
    getNetworkCount();
    fetchNetworkList();
  }, [context, listType]);

  return (
    <>
      <InviteControl>
        <TableMenu>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '80px' }}>
            {isTutor ? (
              <>
                <TableItem selected={menu === 'follower'} onClick={() => handleListType('follower')}>Seguidores</TableItem>
                <TableItem selected={menu === 'following'} onClick={() => handleListType('following')}>Seguindo</TableItem>
              </>
            ) : (
              <TableItem selected={menu === 'following'} style={{ opacity: '1.0' }} onClick={() => handleListType('following')}>Seguindo</TableItem>
            )}
          </div>
        </TableMenu>

        {menu === 'follower' || (menu === 'following' && !isTutor) ? (
          <PrimaryButton style={{ marginTop: '40px' }} onClick={() => dispatch.Network.setModal({ networkModal: { open: true, context: 'invite' } })} startIcon={<SendIcon />}>
            { isTutor ? 'Convidar para minha rede' : 'Convidar novo cliente'}
          </PrimaryButton>
        ) : <></>}
      </InviteControl>
      {isLoading ? (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
          sx={{ minHeight: '50vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <>
          {hasData ? (
            <StyledGridContainer
              container
              spacing={2}
              direction='column'
              alignItems='center'
              justifyContent='space-around'
              sx={{ marginBottom: '70px' }}
            >
              {hasData ? networkData.sort((a, b) => new Date(b.createDatetime) - new Date(a.createDatetime)).map(({ id, email, name, phone, type, createDatetime, isSent, status, permission, inactivatedById }, i) => {
                const isLastElement = networkData.length === i + 1;

                return (
                  <>
                    {isLastElement ? (
                      <StyledGridItem key={id} ref={lastElementRef} item>
                        <NetworkCard
                          id={id}
                          email={email}
                          name={name}
                          phone={phone}
                          type={type}
                          createDatetime={createDatetime}
                          isSent={isSent}
                          status={status}
                          permission={permission}
                          inactivatedById={inactivatedById}
                        />
                      </StyledGridItem>
                    ) : (
                      <StyledGridItem key={id} item>
                        <NetworkCard
                          id={id}
                          email={email}
                          name={name}
                          phone={phone}
                          type={type}
                          createDatetime={createDatetime}
                          isSent={isSent}
                          status={status}
                          permission={permission}
                          inactivatedById={inactivatedById}
                        />
                      </StyledGridItem>
                    )}
                  </>
                );
              }) : <></>}
            </StyledGridContainer>
          ) : (
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              sx={{ minHeight: '60vh', textAlign: 'center' }}
            >
              <EmptyNetwork src={EmptyNetworkImage} alt='Empty notification' />
              <Typography variant='h5'>Você ainda não possui conexões</Typography>
              <Typography variant='body2'>Envie um convite para outro tutor(a) ou um profissional que conheça!</Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default NetworkCardList;
