import { CardContent } from '@material-ui/core';
import styled from 'styled-components';

export const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 322px;
    height: 355px;
    flex-shrink: 2;
    border: 6px dashed rgba(107, 72, 255, 0.37);
    opacity: 1.0;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;

    h1 {
        font-weight: 900;
        font-size: 25px;
        color: rgba(107, 72, 255, 0.37);
        text-transform: uppercase;
        margin-top: 15px;
    }

    p {
        margin: 15px 0 15px 0;
        text-align: center;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: rgba(107, 72, 255, 0.37);

        @media screen and (max-width: 739px) {
            font-size: 16px;
        }
    }

    strong {
        font-weight: 500;
    }

    @media screen and (max-width: 739px) {
	    height: 140px;
        width: 88%;
        flex-direction: row;
        margin-top: 25px;

        svg {
            margin-left: 10px;
            width: 100px;
            height: 100px;
        }

        h1 {
            font-size: 18px;
        }
    }
`;

export const EmptyPetInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    @media screen and (max-width: 739px) {
        margin-left: 20px;
	    flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;
