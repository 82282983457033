import { Chip, Grid } from '@mui/material';
import styled from 'styled-components';

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: #FFFFFF;
    border: ${({ $selectedCategory }) => ($selectedCategory ? '1px solid rgba(0, 0, 0, 0.5)' : '1px solid rgba(207, 207, 207, 0.5)')};
    box-sizing: border-box;
    border-radius: 16px;
    transition: .all 0.2s ease-in-out;
    ${({ $selectedCategory }) => !$selectedCategory && `
      color: #C4C4C4;

      & .MuiTypography-root {
        font-weight: 100;
      }
      `
}
    ${({ $selectedCategory }) => $selectedCategory && 'box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);'}

    &:hover {
      background-color: #FFFFFF;
      border: ${({ $selectedCategory }) => ($selectedCategory ? '1px solid rgba(0, 0, 0, 0.5)' : '1px solid rgba(207, 207, 207, 0.5)')};
    }
  }

  &.MuiChip-deleteIcon {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.10);
  }
`;

export const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    overflow: scroll;
    wrap: nowrap;
    max-width: 1280px;
    padding: 24px 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
