/* eslint-disable no-unused-vars */
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { StyledCard, StyledPrice, DiscountBanner, DiscountBannerText, DiscountBannerPercentage } from './style';
import discountBanner from '../../../../../assets/images/Pricing/discountBanner.png';

const SigningTimeCard = ({ name, price, isDefault, isPromotion, monthlyPrice, priceId, promotionTerms, isRecurrent, recurringIntervalCount, discountFromDefault }) => {
  const integerPrice = price && price.toFixed(2).split('.')[0];
  const decimalPrice = price && price.toFixed(2).split('.')[1];
  const integerMonthlyPrice = monthlyPrice && monthlyPrice.toFixed(2).split('.')[0];
  const decimalMonthlyPrice = monthlyPrice && monthlyPrice.toFixed(2).split('.')[1];

  const dispatch = useDispatch();

  const handlePayment = async () => {
    dispatch.SigningPlans.setSelectedPlan({ priceId, isRecurrent, price });
    dispatch.SigningPlans.setModal({
      signingTime: { open: false, data: {} },
      signingConfirmation: { open: true, data: {} },
    });
  };

  return (
    <Grid
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={handlePayment}
    >
      {discountFromDefault > 0 && !isDefault && (
        <>
          <DiscountBanner src={discountBanner} alt='discountBanner' />
          <DiscountBannerText>MAIS POPULAR</DiscountBannerText>
          <DiscountBannerPercentage>
            {(discountFromDefault * 100).toFixed(0)}
            % OFF
          </DiscountBannerPercentage>
        </>
      )}

      <StyledCard elevation={0}>
        <Typography sx={{ fontSize: '30px', fontWeight: 600, color: 'white' }}>{name || 'ASSINATURA'}</Typography>

        <Grid container sx={{ marginTop: '15px' }}>
          <Typography sx={{ fontSize: '20px', marginRight: '7px', color: 'white' }}>R$</Typography>
          <StyledPrice>
            {integerMonthlyPrice || integerPrice}
            {','}
            <small>
              {decimalMonthlyPrice || decimalPrice || '00'}
              {recurringIntervalCount === 1 && promotionTerms && <sup>*</sup>}
            </small>
          </StyledPrice>
          <Typography sx={{ fontSize: '12px', marginLeft: '7px', lineHeight: '35px', color: 'white' }}>/mês</Typography>
        </Grid>

        {recurringIntervalCount > 1 && (
        <Typography sx={{ fontSize: '16px', marginTop: '15px', color: 'white' }}>
          R$
          {' '}
          {integerPrice}
          ,
          {decimalPrice || '00'}
          {' '}
          {`pago a cada ${recurringIntervalCount} meses`}
        </Typography>
        )}

        {recurringIntervalCount === 1 && isDefault && isPromotion && (
          <Typography sx={{ fontSize: '14px', marginTop: '15px', color: 'white', whiteSpace: 'pre-wrap', width: '250px' }}>
            {promotionTerms}
          </Typography>
        )}

        <ArrowForward sx={{ background: '#A792FF', color: 'white', padding: '10px', borderRadius: '50%', position: 'absolute', right: '25px', bottom: '20px' }} />
      </StyledCard>
    </Grid>
  );
};

export default SigningTimeCard;
