/* eslint-disable no-unused-vars */
import { Autocomplete, Avatar, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { AttachFile } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsibleSelect } from './style';
import { convertBase64, resizeImage, validateImage } from '../../../../utils/imageUtils';
import CancelOrSubmit from '../../../global/CancelOrSubmit';
import { datePickerErrorStyle } from '../../../../utils/theme';
import useWindowSize from '../../../../hooks/windowSize';
import { isNative } from '../../../../utils/mobile';

const RoutineCare = () => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const screen = useWindowSize();
  const isMobile = screen.height < 700;

  const loading = useSelector((state) => state.Animals.loading?.protocolDialogLoading);

  const id = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.id);
  const protocolApplyId = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.protocolApplyId);
  const protocolMoment = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.moment);
  const regularity = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.regularity);
  const editLastDoseDate = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastDoseDate);
  const editNextDoseDate = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.nextDoseDate);
  const editLastDoseKeyCode = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastDoseKeyCode);
  const editProductEnvironment = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastProductEnvironment);

  const keyCodeRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.keyCodeRequired);
  const productRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productRequired);
  const productEnvironmentRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productEnvironmentRequired);
  const responsibleRequired = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.responsibleRequired);

  const keyCodeVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.keyCodeVisible);
  const productVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productVisible);
  const productEnvironmentVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.productEnvironmentVisible);
  const responsibleVisible = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.responsibleVisible);

  const [fileUpload, setFileUpload] = useState();
  const [filename, setFilename] = useState('');
  const [photoBase64, setPhotoBase64] = useState('');

  const mode = useSelector((state) => state.Modal.protocolDialog?.mode);
  const openDialog = useSelector((state) => state.Modal.protocolDialog?.open);
  const [isProtocolBeingEdited] = useState(mode && mode === 'edit');

  const b2bFollowers = useSelector((state) => state.User.b2bFollowers);
  const [b2bFollowersAll, setB2bFollowersAll] = useState([]);
  const { id: loggedUserId } = useSelector((state) => state.User.userData);
  const products = useSelector((state) => state.Animals.actualPet.protocols.products) || [];

  const [productPlaceholder, setProductPlaceholder] = useState('Digite o nome do produto *');
  const [openLastDosePicker, setOpenLastDosePicker] = useState(false);
  const [openNextDosePicker, setOpenNextDosePicker] = useState(false);
  const [protocolProducts, setProtocolProducts] = useState(products);
  const [responsible, setResponsible] = useState((b2bFollowers && b2bFollowers.length === 1 && b2bFollowers[0].id === loggedUserId && b2bFollowers[0].id) || 'empty');

  const productOptions = protocolProducts.map((product) => ({ label: product.name, id: product.id }));
  const lastDoseName = useSelector((state) => state.Animals.actualPet.protocols.selectedProtocol?.lastDoseName);

  const fetchProfileImage = async () => {
    const profiles = [...b2bFollowers];
    await Promise.all(profiles.map(async (profile) => {
      // eslint-disable-next-line no-param-reassign
      profile.profileImgSrc = await dispatch.Photo.getPhoto(profile.profileImage);
    }));
    setB2bFollowersAll(profiles);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!b2bFollowers?.length && fetchProfileImage();
  }, [b2bFollowers]);

  const fetchProducts = async (productSearch) => {
    const requestAnimalProducts = await dispatch.Animals.getAnimalProtocolProducts({ protocolId: id, name: productSearch });

    if (requestAnimalProducts && requestAnimalProducts.data) {
      setProtocolProducts(requestAnimalProducts.data);
    }
  };

  const handleProductTextChange = async (event, setFieldValue) => {
    setFieldValue('product', event.target.value);

    await fetchProducts(event.target.value);
  };

  const handleProductOptionChange = (event, setFieldValue) => {
    setFieldValue('product', event);
  };

  const handleResponsible = (event, setFieldValue) => {
    const { value } = event.target;
    setResponsible(value);
    setFieldValue('responsibleId', value);
  };

  const submitForm = async (formData, { setErrors }) => {
    // setLoading(true);

    const parseProduct = () => {
      const productsName = protocolProducts.map(((product) => product.name)) || [];
      const productsId = protocolProducts.map(((product) => product.id)) || [];

      const curatedProduct = protocolProducts.find((product) => product.name === formData.product);
      const parsedProduct = (formData.product && formData.product.id) || formData.product;

      const isCurated = Number(parsedProduct)
        ? productsId.includes(Number(parsedProduct))
        : productsName.includes(parsedProduct);

      const productId = { productId: curatedProduct && curatedProduct.id ? curatedProduct.id : parsedProduct };
      const productName = { productName: parsedProduct && parsedProduct.label ? parsedProduct.label : parsedProduct };

      const product = isCurated ? productId : productName;

      return product;
    };

    const { responsibleId, lastDoseDate, keyCode, nextDoseDate, productEnvironment } = formData;

    if (validateImage(fileUpload)) {
      setErrors({ photoName: false });

      const product = formData.product && parseProduct();

      const payload = {
        protocolId: id,
        ...(isProtocolBeingEdited && protocolApplyId && { protocolApplyId }),
        ...(responsibleId && responsibleId !== 'empty' && { responsibleId }),
        ...(product && product),
        ...(nextDoseDate && { expirationDatetime: moment(nextDoseDate).toISOString() }),
        ...(productEnvironment && { productEnvironmentName: productEnvironment }),
        appliedDatetime: moment(lastDoseDate).toISOString(),
        keyCode,
        photoBase64,
      };

      const requestApplyAnimalProtocol = await dispatch.Animals.applyAnimalProtocol(payload);

      if (requestApplyAnimalProtocol && requestApplyAnimalProtocol.data) {
        dispatch.Modal.setDialog({ open: false });
        const protocol = requestApplyAnimalProtocol.data[0];
        if (protocol.showVaccinationCard && protocol.signatureRequired && !protocol.signatureRequested && isNative) {
          dispatch.Modal.setRequestSignatureOnAppModal({ open: true, protocolId: protocol.id });
        }
        if (protocol.showVaccinationCard && !protocol.signatureRequired && protocol.backToVaccineCard && isNative) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
        }
      }
      // setLoading(false);
    } else {
      setErrors({ photoName: true });
      // setLoading(false);
    }
  };

  const handleUpload = () => document.getElementById('imgupload').click();

  const uploadImage = async (e) => {
    const rawFile = e.target.files[0];
    const imageLessThan1MB = rawFile && rawFile.size > 0 && rawFile.size < 1000000;

    if (imageLessThan1MB) {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');

      const base64 = await convertBase64(rawFile);
      setPhotoBase64(base64);
    } else {
      setFileUpload(rawFile);
      setFilename('Arquivo escolhido');
      const resizedFile = await resizeImage(e.target.files[0]);

      if (resizedFile && resizedFile.size > 0) {
        const base64 = await convertBase64(resizedFile);
        setPhotoBase64(base64);
      }
    }
  };

  useEffect(() => {
    if (openDialog) {
      fetchProducts('');
    }

    return () => {
      dispatch.Animals.clearProtocolProducts();
    };
  }, [openDialog]);

  return (
    <Grid sx={{ width: '100%' }}>
      <input name='filePhoto' type='file' accept='.jpg, .jpeg, .png' id='imgupload' onChange={(e) => uploadImage(e)} style={{ display: 'none' }} />
      <Grid container flexDirection='column' justifyContent='flex-start'>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={
            (!isProtocolBeingEdited
                && { lastDoseDate: null, nextDoseDate: null, responsibleId: responsible })
                || { lastDoseDate: editLastDoseDate, nextDoseDate: editNextDoseDate, product: { label: lastDoseName }, productEnvironment: editProductEnvironment, keyCode: editLastDoseKeyCode, responsibleId: responsible }
          }
          onSubmit={submitForm}
          validationSchema={
            Yup.object().shape({
              product: productRequired ? Yup.mixed().required('Favor digitar o nome do produto') : Yup.mixed(),
              lastDoseDate: Yup.string().nullable().required('Favor selecionar a data da última aplicação'),
              responsibleId: responsibleRequired && (b2bFollowers && b2bFollowers.length) ? Yup.string().required('Favor selecionar o responsável') : Yup.string(),
              keyCode: keyCodeRequired ? Yup.string().nullable().required('Favor inserir o número do lote') : Yup.string().nullable(),
              photoBase64: Yup.string(),
              nextDoseDate: Yup.string().nullable(),
              productEnvironment: productEnvironmentRequired ? Yup.string().required('Favor digitar o nome do produto') : Yup.string(),
            })
          }
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              {productVisible && (
                <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                  <Autocomplete
                    blurOnSelect='touch'
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    disablePortal
                    id='product'
                    name='product'
                    defaultValue={values.product}
                    onFocus={() => setProductPlaceholder('Produto *')}
                    onBlur={() => !values.product && setProductPlaceholder('Digite o nome do produto')}
                    error={!!errors.product}
                    openOnFocus
                    freeSolo
                    onChange={(e, value) => handleProductOptionChange(value, setFieldValue)}
                    options={productOptions || []}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        id='product'
                        name='product'
                        error={!!errors.product}
                        onChange={(event) => handleProductTextChange(event, setFieldValue)}
                        label={productPlaceholder}
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              )}

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                <DatePicker
                  open={openLastDosePicker}
                  id='lastDoseDate'
                  name='lastDoseDate'
                  label='Data de aplicação*'
                  format='DD/MM/YYYY'
                  allowSameDateSelection
                  defaultValue={null}
                  maxDate={moment(new Date())}
                  value={values.lastDoseDate}
                  onChange={(value) => setFieldValue('lastDoseDate', value)}
                  onClose={() => setOpenLastDosePicker(false)}
                  renderInput={(params) => (
                    <TextField
                      sx={errors.lastDoseDate ? datePickerErrorStyle : {}}
                      onClick={() => setOpenLastDosePicker(true)}
                      error={!!errors.lastDoseDate}
                      autoComplete='off'
                      touched={touched.lastDoseDate}
                      helperText={errors.lastDoseDate && errors.lastDoseDate}
                      {...params}
                    />
                  )}
                />
              </FormControl>

              {keyCodeVisible && (
              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <TextField
                  id='keyCode'
                  name='keyCode'
                  label='Lote'
                  error={!!errors.keyCode}
                  helperText={errors.keyCode && errors.keyCode}
                  value={values.keyCode}
                  onChange={handleChange}
                  autoComplete='off'
                  fullWidth
                />
              </FormControl>
              )}

              <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <TextField
                  value={filename}
                  error={!!errors.photoName}
                  touched={touched.photoName}
                  onClick={handleUpload}
                  id='photoName'
                  name='photoName'
                  label='Imagem'
                  InputProps={{
                    readOnly: true,
                    endAdornment:
  <IconButton edge='end'>
    <AttachFile />
  </IconButton>,
                  }}
                />
              </FormControl>

              {responsibleVisible && (
              <FormControl error={!!errors.responsibleId} sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                <InputLabel id='demo-simple-select-helper-label'>Responsável</InputLabel>
                <ResponsibleSelect
                  id='responsibleId'
                  name='responsibleId'
                  value={responsible}
                  label='Responsável'
                  onChange={(value) => handleResponsible(value, setFieldValue)}
                  onBlur={handleBlur}
                  touched={touched.responsibleId}
                >
                  <MenuItem value='empty' style={{ height: '60px' }}>Nenhum</MenuItem>
                  {b2bFollowersAll && b2bFollowersAll.map((follower) => (
                    <MenuItem value={follower.id}>
                      <Avatar
                        alt={follower.firstName}
                        src={follower.profileImage && follower.profileImgSrc}
                        sx={{ marginRight: '5px' }}
                      />
                      <div>{(follower.firstName && `${follower.firstName} ${follower.lastName}`) || follower.username || follower.email}</div>
                    </MenuItem>
                  ))}
                </ResponsibleSelect>
              </FormControl>
              )}

              {regularity !== 'once' && (
                <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1 }}>
                  <DatePicker
                    open={openNextDosePicker}
                    id='nextDoseDate'
                    name='nextDoseDate'
                    label={protocolMoment === 'service' ? 'Data de vencimento' : 'Data da próxima aplicação'}
                    allowSameDateSelection
                    value={values.nextDoseDate}
                    onChange={(value) => setFieldValue('nextDoseDate', value)}
                    onClose={() => setOpenNextDosePicker(false)}
                    format='DD/MM/YYYY'
                    PopperProps={{
                      placement: isMobile ? 'top' : 'bottom',
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={errors.nextDoseDate ? datePickerErrorStyle : {}}
                        id='nextDoseDate'
                        name='nextDoseDate'
                        autoComplete='off'
                        error={!!errors.nextDoseDate}
                        touched={touched.nextDoseDate}
                        helperText={errors.nextDoseDate && errors.nextDoseDate}
                        onClick={() => setOpenNextDosePicker(true)}
                        {...params}
                      />
                    )}
                  />
                </FormControl>
              )}

              {productEnvironmentVisible && (
                <FormControl sx={{ width: '100%', marginTop: 3, marginBottom: 1, '& .MuiFormHelperText-root': { marginLeft: 0 } }}>
                  <TextField
                    id='productEnvironment'
                    name='productEnvironment'
                    label='Produto aplicado no ambiente'
                    error={!!errors.productEnvironment}
                    helperText={errors.productEnvironment && errors.productEnvironment}
                    value={values.productEnvironment}
                    onChange={handleChange}
                    autoComplete='off'
                    fullWidth
                  />
                </FormControl>
              )}

              <CancelOrSubmit loading={loading === 'loading'} />
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>

  );
};

export default RoutineCare;
