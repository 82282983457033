/* eslint-disable no-console */
import axios from 'axios';
import { getToken } from '../../services/api';
import { isNative } from '../../utils/mobile';

const baseURL = process.env.REACT_APP_API;

const headers = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const token = params?.token || getToken();

  return (
    getToken() && {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const Animals = {
  state: {
    animalsListSearch: {
      term: '',
      option: '',
      isAnimal: true,
    },
    animalsCount: '',
    animalsList: [],
    listResultsQuizHealth: [],
    actualPet: {
      protocols: {
        products: [],
        treatments: [],
        preventives: [],
        services: [],
        vaccines: [],
        medicines: [],
        selectedProtocol: {},
      },
    },
    lastCreatedProtocol: {},
    lastCreatedPet: {},
    modal: {
      petAndProtocolApply: false,
      protocolApply: false,
    },
    protocolDialog: {
      open: false,
      content: 'heart_control',
      mode: 'create',
    },
    protocolDialogData: {},
    protocolResultDialog: {
      open: false,
      content: 'heart_control',
      result: 'good',
    },
    loading: {
      getAnimalsList: '',
      getAnimals: '',
      getAnimalsCount: '',
      getAnimalProtocolProducts: '',
      getActualPet: '',
      getSurveyDomainsRanges: '',
      createUpdatePet: '',
      editPet: '',
      applyAnimalProtocol: '',
      getProtocolApplyUserData: '',
      protocolDialogLoading: '',
    },
  },
  reducers: {
    SET_ACTUAL_PET: (state, payload) => {
      const actualPet = payload;

      return { ...state, actualPet: { ...actualPet, protocols: { ...state.actualPet.protocols } } };
    },
    SET_ACTUAL_PET_PROTOCOLS: (state, payload) => {
      const treatments = payload.filter((protocol) => protocol.moment === 'treatment');
      const preventives = payload.filter((protocol) => protocol.moment === 'preventive');
      const vaccines = payload.filter((protocol) => protocol.moment === 'vaccine');
      const services = payload.filter((protocol) => protocol.moment === 'service');
      const medicines = payload.filter((protocol) => protocol.moment === 'medicines');

      return { ...state, actualPet: { ...state.actualPet, protocols: { treatments, preventives, services, vaccines, medicines } } };
    },
    SET_ACTUAL_PET_PROTOCOL_PRODUCTS: (state, payload) => ({ ...state, actualPet: { ...state.actualPet, protocols: { ...state.actualPet.protocols, products: payload } } }),
    SET_ACTUAL_PET_PROTOCOL: (state, payload) => ({ ...state, actualPet: { ...state.actualPet, protocols: { ...state.actualPet.protocols, selectedProtocol: payload } } }),
    ADD_TO_ANIMAL_LIST: (state, payload) => ({ ...state, animalsList: [...state.animalsList, payload], lastCreatedPet: payload, actualPet: { ...payload, protocols: { ...state.actualPet.protocols } } }),
    ADD_TO_ANIMAL_LIST_SCROLL: (state, payload) => ({ ...state, animalsList: [...state.animalsList, ...payload] }),
    SET_RESULTS_QUIZ_HEALTH: (state, payload) => ({
      ...state,
      listResultsQuizHealth: payload,
    }),
    UPDATE_ANIMAL_LIST: (state, payload) => {
      const { id } = payload;
      const animalList = state.animalsList;

      const animalsListWithoutOutdatedAnimal = animalList.filter((animal) => animal.id !== id);
      const animalsListWithUpdatedAnimal = [...animalsListWithoutOutdatedAnimal, payload];

      return { ...state, animalList: animalsListWithUpdatedAnimal };
    },
    UPDATE_PET_PROTOCOL: (state, payload) => {
      const protocols = [...state.actualPet.protocols.treatments, ...state.actualPet.protocols.preventives, ...state.actualPet.protocols.services, ...state.actualPet.protocols.vaccines, ...state.actualPet.protocols.medicines];
      const filteredProtocols = protocols.filter((protocol) => protocol.id !== payload.id);
      const updatedProtocols = [...filteredProtocols, payload];

      const treatments = updatedProtocols.filter((protocol) => protocol.moment === 'treatment');
      const preventives = updatedProtocols.filter((protocol) => protocol.moment === 'preventive');
      const services = updatedProtocols.filter((protocol) => protocol.moment === 'service');
      const vaccines = updatedProtocols.filter((protocol) => protocol.moment === 'vaccine');
      const medicines = updatedProtocols.filter((protocol) => protocol.moment === 'medicines');

      return { ...state,
        lastCreatedProtocol: payload,
        actualPet: {
          ...state.actualPet,
          protocols: {
            treatments,
            preventives,
            services,
            vaccines,
            medicines,
            products: state.actualPet.protocols.products,
            selectedProtocol: payload,
          },
        } };
    },
    SET_ANIMALS_LIST: (state, payload) => ({ ...state, animalsList: payload }),
    SET_ANIMALS_COUNT: (state, payload) => ({ ...state, animalsCount: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
    SET_MODAL: (state, payload) => ({ ...state, modal: { ...state.modal, ...payload } }),
    SET_DIALOG: (state, payload) => ({ ...state, protocolDialog: { ...state.protocolDialog, ...payload } }),
    SET_DIALOG_DATA: (state, payload) => ({ ...state, protocolDialogData: { ...state.protocolDialogData, ...payload } }),
    SET_RESULT_DIALOG: (state, payload) => ({ ...state, protocolResultDialog: { ...state.protocolResultDialog, ...payload } }),
    SET_ANIMAL_SEARCH: (state, payload) => ({ ...state, animalsListSearch: payload }),
    CLEAR_PROTOCOL_PRODUCTS: (state, payload) => ({ ...state, actualPet: { ...state.actualPet, protocols: { ...state.actualPet.protocols, products: payload } } }),
  },
  effects: (dispatch) => ({
    async getAnimalsList({ name, pageNumber, pageLength }) {
      dispatch.Animals.SET_LOADING({ getAnimalsList: 'loading' });

      const animalName = name ? `name=${name}` : '';
      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : '';
      const pagelength = pageLength ? `pagelength=${pageLength}` : '';
      const query = [pagenumber, pagelength, animalName].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/animal/my-animal-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getAnimalsList: 'fulfilled' });
          dispatch.Animals.SET_ANIMALS_LIST(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Animals.SET_LOADING({ getAnimalsList: 'rejected' });
        return err;
      }
    },
    async getResultsQuizHealth({ animalId }) {
      dispatch.Animals.SET_LOADING({ getResultsQuizHealth: 'loading' });
      const animalid = animalId ? `animalId=${animalId}` : '';
      const query = [animalid].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(
          `${baseURL}/survey/quality-of-life/list-results${querystring}`,
          headers(),
        );

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getResultsQuizHealth: 'fulfilled' });
          dispatch.Animals.SET_RESULTS_QUIZ_HEALTH(response.data);
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getResultsQuizHealth: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getSurveyDomainsRanges() {
      dispatch.Animals.SET_LOADING({ getSurveyDomainsRanges: 'loading' });

      try {
        const response = await axios.get(
          `${baseURL}/survey/quality-of-life/list-domains`,
          headers(),
        );

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getSurveyDomainsRanges: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getSurveyDomainsRanges: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async addToAnimalsList({ name, pageNumber, pageLength }) {
      dispatch.Animals.SET_LOADING({ getAnimalsList: 'loading' });

      const animalName = name ? `name=${name}` : '';
      const pagenumber = pageNumber ? `pagenumber=${pageNumber}` : '';
      const pagelength = pageLength ? `pagelength=${pageLength}` : '';
      const query = [pagenumber, pagelength, animalName].filter((notEmpty) => notEmpty).join('&');
      const querystring = query.length ? `?${query}` : '';

      try {
        const response = await axios.get(`${baseURL}/animal/my-animal-list${querystring}`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getAnimalsList: 'fulfilled' });
          dispatch.Animals.ADD_TO_ANIMAL_LIST_SCROLL(response.data);
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Animals.SET_LOADING({ getAnimalsList: 'rejected' });

        return err;
      }
    },
    async getAnimalsCount() {
      dispatch.Animals.SET_LOADING({ getAnimalsCount: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/animal/my-animal-count`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getAnimalsCount: 'fulfilled' });
          dispatch.Animals.SET_ANIMALS_COUNT(response.data.count);
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getAnimalsCount: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getAnimalProtocols(payload) {
      dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/protocol-animal/list/${payload}`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'fulfilled' });
          dispatch.Animals.SET_ACTUAL_PET_PROTOCOLS(response.data);
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async getAnimalProtocolsVaccineCard(payload) {
      dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/protocol-animal/list/${payload}`, { ...headers(), params: { showVaccinationCard: true } });

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'fulfilled' });
          dispatch.Animals.SET_ACTUAL_PET_PROTOCOLS(response.data);
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getAnimalProtocols: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getActualPet(payload) {
      dispatch.Animals.SET_LOADING({ getActualPet: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/animal/${payload}`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_ACTUAL_PET(response.data);
          dispatch.Animals.SET_LOADING({ getActualPet: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ getActualPet: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async createUpdatePet(payload) {
      dispatch.Animals.SET_LOADING({ createUpdatePet: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/animal/create-update`, payload, headers());

        if (response && response.data) {
          if (payload.id) {
            dispatch.Animals.UPDATE_ANIMAL_LIST(response.data);
          } else {
            dispatch.Animals.ADD_TO_ANIMAL_LIST(response.data);
          }

          dispatch.Animals.SET_LOADING({ createUpdatePet: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ createUpdatePet: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async transferToTutor(payload) {
      dispatch.Animals.SET_LOADING({ transferToTutor: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/animal/transfer-to-tutor`, payload, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ transferToTutor: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ transferToTutor: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getAnimalProtocolProducts(payload) {
      const { protocolId, name, token } = payload;

      try {
        const response = await axios.get(`${baseURL}/product/list-protocol/${protocolId}`, {
          ...headers(),
          params: { name, token },
        });

        if (response && response.data && payload?.changeRedux) {
          dispatch.Animals.SET_ACTUAL_PET_PROTOCOL_PRODUCTS(response.data);
        }
        return response;
      } catch (err) {
        console.log(err);

        return err;
      }
    },
    async applyAnimalProtocol(payload) {
      if (isNative) {
        return window.ReactNativeWebView?.postMessage(JSON.stringify({ protocolApplyPayload: payload }));
      }

      const { protocolId, ...payloadRest } = payload;
      dispatch.Animals.SET_LOADING({ protocolDialogLoading: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/protocol-apply/create-update/${protocolId}`, payloadRest, headers());

        if (response && response.data) {
          dispatch.Animals.UPDATE_PET_PROTOCOL(response.data[0]);
          dispatch.Animals.SET_LOADING({ protocolDialogLoading: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ protocolDialogLoading: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getBreedListSpecies(payload) {
      const { species, name } = payload;
      dispatch.Animals.SET_LOADING({ breedListSpecies: 'loading' });
      if (!species) return null;

      try {
        const response = await axios.get(`${baseURL}/breed/list-species/${species}?name=${name}`, headers());

        if (response && response.data) {
          dispatch.Animals.SET_LOADING({ breedListSpecies: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.Animals.SET_LOADING({ breedListSpecies: 'rejected' });
        console.log(err);

        return err;
      }
    },
    async getProtocolAnimal(token) {
      dispatch.User.SET_LOADING({ getProtocolApplyUserData: 'loading' });

      try {
        const url = `${baseURL}/protocol-apply/validate-signature/${token}`;
        const response = await axios.get(url);
        return response;
      } catch (error) {
        console.error(error);
        dispatch.User.SET_LOADING({ getProtocolApplyUserData: 'rejected' });
        return error;
      }
    },
    async sendVaccineForm({ payload, token }) {
      try {
        const url = `${baseURL}/protocol-apply/sign-document/${token}`;
        const response = await axios.post(url, payload, headers());
        return response;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    async getLastDoseReceiptImage({ uuid, token }) {
      try {
        const url = `${baseURL}/photo/${uuid}?token=${token}`;
        const response = await axios.get(url, { ...headers(), responseType: 'arraybuffer' });

        if (response && response.data) {
          return `data:${response.headers['content-type']};base64,${Buffer.from(response.data).toString('base64')}`;
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    updatePetProtocol(payload) {
      dispatch.Animals.UPDATE_PET_PROTOCOL(payload);
    },
    setSelectedProtocol(payload) {
      dispatch.Animals.SET_ACTUAL_PET_PROTOCOL(payload);
    },
    setModal(status) {
      dispatch.Animals.SET_MODAL(status);
    },
    setDialog(payload) {
      dispatch.Animals.SET_DIALOG(payload);
    },
    setDialogData(payload) {
      dispatch.Animals.SET_DIALOG_DATA(payload);
    },
    setResultDialog(payload) {
      dispatch.Animals.SET_RESULT_DIALOG(payload);
    },
    setAnimalsListSearch(payload) {
      dispatch.Animals.SET_ANIMAL_SEARCH(payload);
    },
    clearActualPet() {
      dispatch.Animals.SET_ACTUAL_PET({});
    },
    clearProtocolProducts() {
      dispatch.Animals.CLEAR_PROTOCOL_PRODUCTS([]);
    },
  }),
};
