/* eslint-disable no-unused-expressions */
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RedeemIcon from '@mui/icons-material/Redeem';
import UpdateIcon from '@mui/icons-material/Update';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useSelector, useDispatch } from 'react-redux';
import { VoucherPhoto, VoucherTitle, Price, StatusTypography, StyledCard, RedeemButton, Address, Company, VoucherDate } from './styles';
import { ReactComponent as MapPinIcon } from '../../../../assets/images/Services/map_pin.svg';
import { formatBRL } from '../../../../utils/format';

const OrderItem = ({ id, name, promotionPriceWithDiscount, promotionValidUntilDatetime, createDatetime, expiredDatetime, redeemDatetime, redeemCode, cancelledDatetime, status, promotionVoucher }) => {
  const isReclaimDisabled = status === 'cancelled' || status === 'redeemed';
  const history = useHistory();
  const orderStatusTab = useSelector((state) => state.Vouchers.orderStatusTab);
  const dispatch = useDispatch();

  const [voucherImgSrc, setVoucherImgSrc] = useState(null);

  const fetchVoucherImage = async () => {
    const response = await dispatch.Photo.getPhoto(promotionVoucher.voucherImage);
    setVoucherImgSrc(response);
  };

  useEffect(() => {
    !!promotionVoucher?.voucherImage && fetchVoucherImage();
  }, []);

  const orderStatusMap = {
    'pending payment': 'Aguardando pagamento',
    cancelled: `Pedido cancelado ${cancelledDatetime ? `em ${moment(cancelledDatetime).format('DD/MM/YYYY')}` : ''}`,
    redeemed: `Pedido resgatado ${redeemDatetime ? `em ${moment(redeemDatetime).format('DD/MM/YYYY')}` : ''}`,
    expired: `Pedido expirado ${expiredDatetime ? `em ${moment(expiredDatetime).format('DD/MM/YYYY')}` : ''}`,
    active: 'Pedido confirmado',
  };

  const redirectToDetail = async (voucherId) => {
    await dispatch.Vouchers.voucherB2CDetail(voucherId);
    history.push(`/orders/${voucherId}`);
  };

  return (
    <Grid container flexDirection='column'>
      <Grid container flexDirection='row' justifyContent='space-between' sx={{ margin: '6px 0' }}>
        <Grid item>
          <StatusTypography $status={status}>
            <RedeemIcon sx={{ width: '14px', height: '14px', marginRight: '6px' }} />
            {status && orderStatusMap[status].toUpperCase()}
          </StatusTypography>
        </Grid>

        <Grid item>
          {status && (status === 'active') ? (
            <StatusTypography $status={status} style={{ fontVariant: 'normal' }}>
              <UpdateIcon sx={{ width: '14px', height: '14px', marginRight: '6px' }} />
              Resgatar até
              {' '}
              {promotionValidUntilDatetime && moment(promotionValidUntilDatetime).format('DD/MM/YYYY')}
              {' '}
              (
              {promotionValidUntilDatetime && moment(promotionValidUntilDatetime).fromNow()}
              )
            </StatusTypography>
          ) : (
            <>
              {status === 'redeemed' && (
              <StatusTypography $status={status} style={{ fontVariant: 'normal' }}>
                <VpnKeyIcon sx={{ width: '14px', height: '14px', marginRight: '6px' }} />
                <Typography variant='subtitle' sx={{ textDecoration: 'line-through' }}>
                  {redeemCode}
                </Typography>
              </StatusTypography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <StyledCard elevation={0} sx={{ padding: '6px 8px' }}>
        <Grid container wrap='nowrap' flexDirection='row' sx={{ minHeight: '164px', marginTop: '5px' }} spacing={1}>
          <Grid item>
            <VoucherPhoto src={promotionVoucher?.voucherImage && voucherImgSrc} alt='voucherPhoto' />
          </Grid>

          <Grid item>
            <Grid container flexDirection='column'>
              <Grid container flexDirection='column'>
                <Grid item>
                  <VoucherDate>
                    Adquirido em
                    {' '}
                    {createDatetime && moment(createDatetime).format('DD/MM/YYYY')}
                  </VoucherDate>
                  <VoucherTitle>{name}</VoucherTitle>
                  <Price>{formatBRL(promotionPriceWithDiscount)}</Price>
                </Grid>

                {orderStatusTab === 'active' && (status !== 'cancelled' || status !== 'redeemed' || status !== 'expired') && (
                  <Grid item sx={{ margin: '23px 0 20px 0' }}>
                    <RedeemButton
                      onClick={!isReclaimDisabled ? (() => redirectToDetail(id)) : undefined}
                      disabled={isReclaimDisabled}
                    >
                      {status !== 'pending payment' ? 'RESGATAR' : 'PAGAR'}
                    </RedeemButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledCard>

      <Grid container flexDirection='row' alignItems='center' wrap='nowrap' sx={{ marginTop: '6px' }}>
        <Grid item sx={{ margin: '0 4px' }}>
          <MapPinIcon style={{ width: '12px', height: '14px' }} />
        </Grid>

        <Grid container>
          <Company>
            {(promotionVoucher?.user && (promotionVoucher.user.firstName || promotionVoucher.user.username || promotionVoucher.user.email))}
            {' '}
            -
            {' '}
            <Address>
              {promotionVoucher.deliveryMode === 'pickup'
                ? promotionVoucher?.address?.fullAddress
                : promotionVoucher?.shippingDetail}
            </Address>
          </Company>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderItem;
