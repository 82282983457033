import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { InviteControl, StyledContainer, EmptyNetwork, TableMenu, TableItem, IndicatorLine, StyledTableContainer } from './style';
import { PrimaryButton } from '../../../utils/theme';
import NetworkTableRow from './NetworkTableRow';
import EmptyNetworkImage from '../../../assets/images/Network/empty_network.svg';
import Loading from '../../global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const NetworkTable = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { type: userType } = useSelector((state) => state.User.userData);
  const { networkData, networkCount, context, listType } = useSelector((state) => state.Network);
  const { getNetworkList } = useSelector((state) => state.Network.loading);

  const isTutor = !userType || userType === 'T';
  const hasData = networkData.length;
  const isLoading = getNetworkList !== 'fulfilled';

  const [menu, setMenu] = useState(listType);

  const getNetworkCount = async () => {
    if (context !== 'card') {
      dispatch.Network.getNetworkCount({ listType });
    }
  };

  const fetchNetworkList = async (nextPage) => {
    if (context !== 'card') {
      const response = await dispatch.Network.getNetworkList({ page: nextPage, limit: rowsPerPage, listType });

      if (response && response.data) {
        setPage(nextPage);
      }
    }
  };

  const handleListType = (value) => {
    if (value === 'follower') Mixpanel.track(MIXPANEL_TAGS.FOLLOWER_LIST_VIEW);
    if (value === 'following') Mixpanel.track(MIXPANEL_TAGS.FOLLOWING_LIST_VIEW);

    if (!isTutor) {
      return;
    }

    setMenu(value);
    dispatch.Network.setListType(value);
  };

  useEffect(() => dispatch.Network.updateAnimalToTransferList(), []);

  useEffect(() => fetchNetworkList(page), [context, rowsPerPage, listType]);

  useEffect(() => getNetworkCount(), [networkData, listType]);

  return (
    <>
      <StyledContainer sx={{ width: '50%' }}>
        <InviteControl>
          <TableMenu style={{ marginBottom: '100px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isTutor ? (
                <>
                  <TableItem selected={menu === 'follower'} onClick={() => handleListType('follower')}>Seguidores</TableItem>
                  <TableItem selected={menu === 'following'} onClick={() => handleListType('following')}>Seguindo</TableItem>
                </>
              ) : (
                <TableItem selected={menu === 'following'} style={{ opacity: '1.0' }} onClick={() => handleListType('following')}>Seguindo</TableItem>
              )}
            </div>
            <IndicatorLine isTutor={isTutor} menu={menu} />
          </TableMenu>

          {menu === 'follower' || (menu === 'following' && !isTutor) ? (
            <PrimaryButton style={{ marginTop: '50px' }} onClick={() => dispatch.Network.setModal({ networkModal: { open: true, context: 'invite' } })} startIcon={<SendIcon />}>
              { isTutor ? 'Convidar para minha rede' : 'Convidar novo cliente'}
            </PrimaryButton>
          ) : <></>}
        </InviteControl>

        {isLoading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            sx={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            {hasData ? (
              <StyledTableContainer component={Paper} sx={{ width: '100%' }}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component='div'
                  count={networkCount}
                  labelRowsPerPage='Linhas por página'
                  rowsPerPage={rowsPerPage}
                  labelDisplayedRows={({ from, to, count: labelCount }) => (`${from}–${to} de ${labelCount !== -1 ? labelCount : `maior que ${to}`}`)}
                  onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
                  page={page}
                  onPageChange={(event, nextPage) => fetchNetworkList(nextPage)}
                />
                <Table sx={{ minHeight: 100 }} aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>NOME</TableCell>
                      <TableCell>E-MAIL</TableCell>
                      <TableCell>CELULAR</TableCell>
                      <TableCell>PERFIL</TableCell>
                      <TableCell>ANIMAL</TableCell>
                      <TableCell>STATUS</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {networkData.length ? networkData.sort((a, b) => new Date(b.createDatetime) - new Date(a.createDatetime)).map(({ id, email, name, phone, type, createDatetime, isSent, status, permission, inactivatedById, animal, animalsInTransfer, scheduled, sendDatetime }) => (
                      <NetworkTableRow
                        key={id}
                        id={id}
                        email={email}
                        phone={phone}
                        name={name}
                        type={type}
                        createDatetime={createDatetime}
                        isSent={isSent}
                        status={status}
                        permission={permission}
                        animal={animal}
                        animalsInTransfer={animalsInTransfer}
                        inactivatedById={inactivatedById}
                        scheduled={scheduled}
                        sendDatetime={sendDatetime}
                      />
                    )) : <></>}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            ) : (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                sx={{ minHeight: '50vh' }}
              >
                <EmptyNetwork src={EmptyNetworkImage} alt='Empty notification' />
                <Typography variant='h5'>Você ainda não possui conexões</Typography>
                <Typography variant='body2'>Envie um convite para outro tutor(a) ou um profissional que conheça!</Typography>
              </Grid>
            )}
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default NetworkTable;
