import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 161px;
    height: 204px;
    background-color: #f2f2f2;
    border-radius: 10px;
    position: relative;
  }
`;

export const VoucherTitle = styled(Typography)`
  &.MuiTypography-root {
    color: #6B48FF;
    font-size: 17px;
    font-weight: 600;
  }
`;

export const Price = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 15px;
    color: #212529;

    b {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

export const PriceValue = styled(Price)`
  &.MuiTypography-root {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const ExpireInfo = styled(Box)`
  &.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border: 1px solid rgba(255, 72, 116, 0.25);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px 14px;
    margin-top: 18px;

    p {
      color: #FF4874;
      font-size: 10px;
      font-weight: 300;
      line-height: 12px;
    }
  }
`;

export const ExpireText = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #7E8192;
    margin: 23px 0 44px 0;
  }
`;

export const PIXCopyButton = styled(Button)`
  &.MuiButton-root {
    width: 85%;
    height: 42px;

    border: 1px solid #6B48FF;
    color: #6B48FF;
    background: white;
    border-radius: 20px;
  }

  &.MuiButton-root:hover {
    background: #6B48FF;
    color: white;
  }
`;

export const QrCodeContainer = styled.div`
  width: 124px;
  height: 124px;
  margin: 28px 0;
`;
