/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { StyledAlert } from './style';

const Alert = (props) => (
  <div>
    <StyledAlert {...props}>{props.children}</StyledAlert>
  </div>
);

export default Alert;
