/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const getHeaders = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const Referral = {
  state: {
    loading: {},
  },
  reducers: {
    SET_LOADING: (state, payload) => ({
      ...state,
      loading: { ...state.loading, ...payload },
    }),
  },
  effects: (dispatch) => ({
    async checkCode(payload) {
      dispatch.Referral.SET_LOADING({ checkCode: 'loading' });

      try {
        const headers = await getHeaders();
        const response = await axios.get(`${baseURL}/referral/code/${payload}`, headers);
        console.log(`${baseURL}/referral/code/${payload}`);

        if (response && response.data) {
          dispatch.Referral.SET_LOADING({
            checkCode: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Referral.SET_LOADING({
          checkCode: 'rejected',
        });
        return err;
      }
    },
    async updateCode(payload) {
      dispatch.Referral.SET_LOADING({ updateCode: 'loading' });

      try {
        const headers = await getHeaders();
        const response = await axios.post(`${baseURL}/referral/code`, payload, headers);

        if (response && response.data) {
          dispatch.Referral.SET_LOADING({
            updateCode: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Referral.SET_LOADING({
          updateCode: 'rejected',
        });
        return err;
      }
    },
    async referredBy(payload) {
      dispatch.Referral.SET_LOADING({ referredBy: 'loading' });

      try {
        const headers = await getHeaders();
        console.log(payload);
        const response = await axios.post(`${baseURL}/referral/referred-by`, payload, headers);

        if (response && response.data) {
          dispatch.Referral.SET_LOADING({
            referredBy: 'fulfilled',
          });
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.Referral.SET_LOADING({
          referredBy: 'rejected',
        });
        return err;
      }
    },
  }),
};
