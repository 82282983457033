import React from 'react';

import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../../utils/mobile';

const EventScheduleDialog = ({ open, setModalOpen }) => {
  const history = useHistory();

  const redirectToServices = () => {
    history.push('/services');
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row'>
          <Grid item>
            Agendamento Concluído!
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Seu agendamento foi concluído com sucesso. Iremos te mandar notificações para te lembrar, ok?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: '#6B48FE' }}
          onClick={() => {
            setModalOpen(false);
            redirectToServices();
          }}
        >
          Finalizar

        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventScheduleDialog;
