import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';

const PricingAccordion = ({ name, description, available }) => {
  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      // onChange={() => setExpanded(!expanded)}
      sx={{ boxShadow: 'none' }}
      square
    >
      <AccordionSummary sx={{ cursor: 'default' }}>
        {available
          ? <CheckCircleOutline sx={{ color: '#4CAF50', marginRight: '10px' }} />
          : <HighlightOff sx={{ color: '#D9D9D9', marginRight: '10px' }} />}
        <Typography variant='body1' sx={{ opacity: available ? '1.0' : '0.3' }}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ background: '#FFFBFB' }}>
        <Typography variant='body2' sx={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>
          {description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default PricingAccordion;
