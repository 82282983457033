import { Fade, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import theme from '../../../utils/theme';
import OrdersList from './OrdersList';
import { ReactComponent as EmptyOrders } from '../../../assets/images/Vouchers/emptyOrders.svg';

const OrdersDashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const dispatch = useDispatch();
  const [isFirstLoading, setFirstLoading] = useState(true);
  const voucherB2CMyList = useSelector((state) => state.Vouchers.voucherB2CMyList);
  const orderStatusTab = useSelector((state) => state.Vouchers.orderStatusTab);
  const isEmpty = voucherB2CMyList.length === 0 && !isFirstLoading;

  const fetchMyList = async () => {
    setHasMoreItems(true);
    setPage(0);

    const response = await dispatch.Vouchers.voucherB2CMyList({
      status: orderStatusTab,
      limit: rowsPerPage,
    });

    if (response && response.data) {
      setFirstLoading(false);
      setPage((state) => state + 1);
    }
  };

  const updateVoucherList = async () => {
    if (hasMoreItems) {
      const response = await dispatch.Vouchers.updateVoucherB2CMyList({
        status: orderStatusTab,
        page,
        limit: rowsPerPage,
      });

      const responseIsEmpty = response && response.data && !response.data.length;

      if (responseIsEmpty) {
        setHasMoreItems(false);
      }

      if (response && response.data) {
        setPage((state) => state + 1);
      }
    }
  };

  useEffect(() => fetchMyList(), [orderStatusTab]);

  return (
    <Grid container direction='column' justifyContent='center' alignItems='center'>
      <Grid container direction='column' sx={{ maxWidth: '1280px', marginTop: '20px', paddingInline: '24px' }}>
        <Typography variant='h6'>Pedidos</Typography>

        <Grid container sx={{ marginTop: '17px' }}>
          <Grid item sx={{ width: '100%' }}>
            <Tabs
              value={orderStatusTab}
              aria-label='icon label tabs'
              TabIndicatorProps={{ style: { background: theme.footerIndicator, borderRadius: '4px' } }}
              variant='fullWidth'
            >
              <Tab
                value='active'
                label='Ativos'
                onClick={() => dispatch.Vouchers.setStatusTab('active')}
                sx={{ '&.Mui-selected': { color: theme.footerIndicator } }}
              />
              <Tab
                value='redeemed'
                label='Resgatados'
                onClick={() => dispatch.Vouchers.setStatusTab('redeemed')}
                sx={{ '&.Mui-selected': { color: theme.footerIndicator } }}
              />
            </Tabs>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: '30px' }}>
          {isEmpty ? (
            <Fade in>
              <Grid container flexDirection='column' justifyContent='center' alignItems='center' sx={{ minHeight: '50vh' }}>
                <Grid item>
                  <EmptyOrders />
                </Grid>

                <Grid item>
                  <Typography sx={{ width: '300px', fontSize: '16px', opacity: '0.4', textAlign: 'center' }}>
                    Você ainda não tem nenhum vale compra resgatado, garanta sua oferta na aba &quot;Descontos&quot;!
                  </Typography>
                </Grid>
              </Grid>
            </Fade>
          ) : (
            <Fade in>
              <Grid item sx={{ width: '100%' }}>
                <OrdersList refetch={updateVoucherList} page={page} isFirstLoading={isFirstLoading} />
              </Grid>
            </Fade>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrdersDashboard;
