import styled from 'styled-components';
import { Card, Typography } from '@mui/material';

export const StyledCard = styled(Card)`
  &.MuiCard-root {
    position: relative;
    padding: 24px;
    width: 270px;
    min-height: 90px;
  }

  &.MuiCard-root {
    background-color: #0D3F67;
  }
`;

export const CardTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 16px;
    color: #CCC3FE;
  }
`;

export const CardValue = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 400;
    font-size: 34px;
    color: #FFFFFF;
  }
`;

export const CardMonetary = styled(CardTitle)`
  &.MuiTypography-root {
    opacity: 0.5;
    margin-top: 6px;
  }
`;
