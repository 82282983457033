import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../../utils/mobile';

const ServiceCategoryDialog = ({ open, setModalOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToNetwork = async () => {
    await dispatch.Network.setModal({ networkModal: { open: true, context: 'invite', origin: 'service' } });
    history.push('/network');
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        width: '450px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row'>
          <Grid item>
            Convide seu prestador de serviço
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          Não há nenhum prestador de serviço cadastrado para esta categoria.
          <br />
          <br />
          Você pode convidar um prestador de serviços para a plataforma através da sua rede.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Button
              sx={{ color: '#6B48FE' }}
              onClick={() => {
                redirectToNetwork();
              }}
            >
              Convidar para minha rede
            </Button>
          </Grid>

          <Grid item>
            <Button
              sx={{ color: 'black' }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Ok
            </Button>
          </Grid>

        </Grid>

      </DialogActions>
    </Dialog>
  );
};

export default ServiceCategoryDialog;
