export const furSizesUtils = {
  CAT: [
    'Curta e lisa',
    'Curta e ondulada',
    'Média e lisa',
    'Média e ondulada',
    'Longa e lisa',
    'Longa e ondulada',
    'Sem pelos',
  ],
  DOG: [
    'Curta e lisa',
    'Curta e cacheada',
    'Curta e ondulada',
    'Média e lisa',
    'Média e cacheada',
    'Média e ondulada',
    'Longa e lisa',
    'Longa e cacheada',
    'Longa e ondulada',
    'Sem pelos',
  ],
};

export const furColorsUtils = {
  CAT: [
    'Preta',
    'Branca',
    'Laranja',
    'Cinza',
    'Azul',
    'Dourada',
    'Marrom',
  ],
  DOG: [
    'Branca',
    'Creme',
    'Amarela',
    'Caramelo',
    'Dourada',
    'Marrom',
    'Cinza',
    'Azul',
    'Preta',
  ],
};
