import React from 'react';
import { Card } from '@material-ui/core';
import { Title } from './style';
import CareIcon from '../../../assets/images/PetManagement/care.svg';
import TreatmentIcon from '../../../assets/images/PetManagement/medicine.svg';
import MedicineIcon from '../../../assets/images/PetManagement/medicines.svg';
import VaccineIcon from '../../../assets/images/PetManagement/vaccine.svg';
import PreventiveCareIcon from '../../../assets/images/PetManagement/love.svg';
import CardControl from '../CardControl';

const iconMap = {
  'Cuidados de Rotina': CareIcon,
  'Controle de Tratamentos': TreatmentIcon,
  'Carteira de Vacinação': VaccineIcon,
  'Cuidados Preventivos': PreventiveCareIcon,
  Medicamentos: MedicineIcon,
};

const SectionControl = ({ title, list }) => {
  const showTime = (item) => (title === 'Medicamentos' || item.name === 'Petiscos' || item.name === 'Ração');

  return (
    <Card elevation={0} sx={{ my: 2 }} id={title.replace(/\s/g, '').toLowerCase()}>
      <Title>
        <img src={iconMap[title]} alt={title} />
        {title}
      </Title>
      {list && list
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((service, index, arr) => <CardControl key={index} {...service} isLastCard={arr.length - 2 === index} withTime={showTime(service)} />)}
    </Card>
  );
};

export default SectionControl;
