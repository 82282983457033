import React from 'react';
import { Stack } from '@mui/material';

import ProtocolDialog from '../../components/Modals/ProtocolDialog';
import ProtocolResultDialog from '../../components/Modals/ProtocolResultDialog';

const PetsListenerProtocols = () => (
  <Stack width='100%' minHeight='100vh'>
    <ProtocolDialog />
    <ProtocolResultDialog />
  </Stack>
);

export default PetsListenerProtocols;
