import { Card } from '@mui/material';
import styled from 'styled-components';
import { animations } from '../../../../utils/theme';

export const StyledCard = styled(Card)`
  animation: ${animations.fadeIn} 0.2s ease-in-out;
  &.MuiCard-root {
    background: #F4F4F4;
    border: ${({ status }) => (status === 'Cancelled' ? '3px solid rgba(255, 0, 0, 0.3)' : '3px solid #FFFFFF')};
    border-radius: 6px;
  }
`;

export const PetPhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;
