import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Header } from './style';

import logoHeaderDark from '../../../assets/images/Header/logoHeaderDark.svg';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const PublicDashboardHeader = () => {
  const onClickDashboard = () => Mixpanel.track(MIXPANEL_TAGS.ICON_TOP_MENU);

  return (
    <Header>
      <Container>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className='container'
        >
          <Link to='/login' className='logo-desktop' onClick={onClickDashboard}>
            <img alt='Guiavet' src={logoHeaderDark} />
          </Link>
          <Link to='/login' className='logo-mobile' onClick={onClickDashboard}>
            <img alt='Guiavet' src={logoHeaderDark} />
          </Link>
        </Grid>
      </Container>
    </Header>
  );
};

export default PublicDashboardHeader;
