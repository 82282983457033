import { Button } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../utils/theme';

export const DeleteButton = styled(Button)`
border-radius: ${theme.buttonRadius} !important;
color: ${theme.warningColor} !important;
background-color: transparent !important;
border: 1px solid ${theme.warningColor} !important;
padding: 4px 20px !important;
width: 180px;
`;
