/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../utils/mobile';
import { PrimaryButton } from '../../../utils/theme';
import imgHelp from '../../../assets/images/TelemedicineCall/help-active-permissions-call.png';

const TelemedicineModalRequestPermissions = ({ title, isTutorial = false, desc = null, open = false, isBlockedOnMobile = false, handlePressOpenConfig, handlePressRequestPermission }) => {
  useEffect(() => scrollTo(0, 0), []);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
        maxWidth: '350px',
      },
      '& .MuiDialog-container': {
        marginTop: isMobile ? '55px' : '0px',
        height: isMobile ? '90vh' : '100vh',
        overflow: 'auto',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => undefined}
      scroll='paper'
    >
      <DialogTitle>
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '16px' }} gap={2}>
          <Grid item>
            <Typography sx={{ fontSize: '32px', lineHeight: '35px', fontWeight: 600, textAlign: 'center' }}>{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ marginTop: '16px' }} gap={2}>
          {isTutorial ? (
            <>
              <Typography>Seu navegador está bloqueado o acesso à câmera e microfone.</Typography>
              <img src={imgHelp} alt='Ativar camera bloqueada' />
              <Typography>Para habilitá-la, clique no ícone da câmera que fica ao lado da estrela, como na imagem acima.</Typography>
              <Typography>Então clique em “Sempre permitir que NuVidio acesse a câmera” e aguarde que seu vídeo irá iniciar em seguida.</Typography>
            </>
          ) : desc}

          <div style={{ marginTop: '64px' }}>
            {isBlockedOnMobile ? (
              <PrimaryButton onClick={() => handlePressOpenConfig()}>Abrir configurações</PrimaryButton>
            ) : (
              <PrimaryButton onClick={() => handlePressRequestPermission()}>Verificar novamente</PrimaryButton>
            )}
          </div>

        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TelemedicineModalRequestPermissions;
