import React from 'react';

const TelemedicineIframeCall = ({ urlRoom }) => (
  <iframe
    title='Atendimento'
    className='nuvidio-iframe'
    allow='camera;microphone;display-capture'
    style={{ width: '100%', height: '100%', zIndex: 99999 }}
    allowFullScreen
    src={urlRoom}
  />
);

export default TelemedicineIframeCall;
