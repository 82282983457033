import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import Footer from '../../components/Footer';
import { MainWrapper } from './style';
import ServicesDashboard from '../../components/Services/ServicesDashboard';
import Loading from '../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const Services = () => {
  const [loading] = useState(false);
  // const dispatch = useDispatch();

  // const botToOpen = useSelector((state) => state.User.botToOpen);

  // const openChatBot = () => {
  //   const chatBot = botToOpen;

  //   if (chatBot) {
  //     IntercomCustom.signinIntercom();
  //     setTimeout(() => {
  //       IntercomCustom.customButtonAndStart(chatBot);
  //       dispatch.User.setBotToOpen('');
  //     }, 500);
  //   }
  // };

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.SERVICES_VIEW), []);

  return (
    <MainWrapper>
      <DashboardHeader />
      <DashboardMenu />
      {loading ? (
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <ServicesDashboard />
      )}

      <Footer />
    </MainWrapper>
  );
};

export default Services;
