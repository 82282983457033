import { getDeviceData } from '../services/api';

export const isUserAppUpdated = () => {
  const deviceData = JSON.parse(getDeviceData());
  const version = deviceData && deviceData.version;
  return version && (Number(version) >= process.env.REACT_APP_NATIVE_VERSION);
};

export const isAndroid = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('Android');

export const isIOS = navigator?.userAgent && navigator.userAgent.includes('wv') && navigator.userAgent.includes('iOS');

export const STORE_ANDROID = 'https://link.guia.vet/dashboard?utm_campaign=lp_app&utm_source=lp_app';

export const STORE_IOS = 'https://link.guia.vet/dashboard?utm_campaign=lp_app&utm_source=lp_app';

export const STORE_ANDROID_UPGRADE_APP = 'https://play.google.com/store/apps/details?id=vet.guia';
export const STORE_ANDROID_LP = 'https://play.google.com/store/apps/details?id=vet.guia&pcampaignid=fdl_long&url=https://app.guia.vet/dashboard?utm_campaign%3Dlp_app%26utm_source%3Dlp_app';

export const STORE_IOS_UPGRADE_APP = 'https://apps.apple.com/us/app/guiavet/id1644755595';

export const isNative = navigator?.userAgent && navigator.userAgent.includes('wv') && (navigator.userAgent.includes('Android') || navigator.userAgent.includes('iOS'));

export const isMobile = screen.width < 900;

export const isTablet = screen.width > 900 && screen.width < 1440;

export const isDesktop = screen.width > 1440;

export const isDesktopOrTablet = isDesktop || isTablet;

export const HOME_URL = isNative ? '/dashboard' : '/pets';

export const BASE_URL = window.location.href.includes(process.env.REACT_APP_WEB) ? process.env.REACT_APP_WEB : process.env.REACT_APP_BUSINESS;

export const isAndroidBrowser = !navigator.userAgent.includes('wv') && navigator.userAgent.includes('Android');

export const isIOSBrowser = !navigator.userAgent.includes('wv') && navigator.userAgent.includes('iPhone' || 'iPad');

export const agentIsDesktop = !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
