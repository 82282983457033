/* eslint-disable no-unused-expressions */
import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatBRL, formatTextLimit } from '../../../../../utils/format';
import { ProductImageContainer, ProductTitle, ProductPrice, OldPrice, Address, Company, CompanyPhoto, DiscountChip, StyledBox } from './style';
import { ReactComponent as MapPinIcon } from '../../../../../assets/images/Services/map_pin.svg';

const VoucherProductCard = ({ user, name, voucherImage, priceWithDiscount, priceFull, discountPercentage, deliveryMode, address, shippingDetail }) => {
  const dispatch = useDispatch();
  const [companyPhotoHasLoaded, setCompanyPhotoHasLoaded] = useState(false);
  const [productPhotoHasLoaded, setProductPhotoHasLoaded] = useState(false);
  const [profileImgSrc, setProfileImgSrc] = useState(null);
  const [voucherImgSrc, setVoucherImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(user.profileImage);
    setProfileImgSrc(response);
  };

  const fetchVoucherImage = async () => {
    const response = await dispatch.Photo.getPhoto(voucherImage);
    setVoucherImgSrc(response);
  };

  useEffect(() => {
    !!user?.profileImage && fetchProfileImage();
    !!voucherImage && fetchVoucherImage();
  }, []);

  return (
    <>
      <StyledBox>
        <Grid item>
          {user?.profileImage && (<Skeleton variant='circular' width={30} height={30} sx={{ position: 'absolute', right: 15, top: -10, zIndex: 50 }} />)}
          <CompanyPhoto
            alt='companyPhoto'
            src={user && user.profileImage && profileImgSrc}
            onLoad={() => setCompanyPhotoHasLoaded(true)}
            sx={user?.profileImage && { visibility: companyPhotoHasLoaded ? 'visible' : 'hidden' }}
          />
        </Grid>

        <ProductImageContainer sx={{ margin: '5px 0' }}>
          {!productPhotoHasLoaded && voucherImage && <Skeleton variant='rectangular' width={145} height={136} sx={{ position: 'absolute', zIndex: 50 }} />}
          <img
            src={voucherImage && voucherImgSrc}
            alt='productPhoto'
            onLoad={() => setProductPhotoHasLoaded(true)}
            style={voucherImage && { visibility: productPhotoHasLoaded ? 'visible' : 'hidden' }}
          />
          {priceWithDiscount !== priceFull && (
          <DiscountChip>
            <h5>
              -
              {Math.trunc(discountPercentage)}
              %
            </h5>
            <sup>off</sup>
          </DiscountChip>
          )}
        </ProductImageContainer>
        <Grid container flexDirection='column' justifyContent='space-between' wrap='nowrap' sx={{ minHeight: '128px' }}>
          <Grid container justifyContent='flex-start'>
            <ProductTitle>{formatTextLimit(name, 60)}</ProductTitle>
          </Grid>
          <Grid container flexDirection='row' justifyContent='space-between' alignItems='flex-start' sx={{ padding: '0px 14px' }}>
            <Grid item>
              <ProductPrice>{formatBRL(priceWithDiscount)}</ProductPrice>
            </Grid>

            {priceWithDiscount !== priceFull && (
            <Grid item>
              <OldPrice>
                {formatBRL(priceFull)}
              </OldPrice>
            </Grid>
            )}
          </Grid>
        </Grid>
      </StyledBox>

      <Grid container flexDirection='row' alignItems='center' wrap='nowrap' sx={{ marginTop: '6px' }}>
        <Grid item sx={{ margin: '0 4px' }}>
          <MapPinIcon style={{ width: '12px', height: '14px' }} />
        </Grid>

        <Grid container wrap='nowrap' sx={{ width: '132px' }}>
          <Company>
            {(user && (user.firstName || user.username || user.email))}
            {' '}
            -
            {' '}
            <Address>
              {deliveryMode === 'pickup'
                ? address?.fullAddress
                : shippingDetail}
            </Address>
          </Company>
        </Grid>
      </Grid>
    </>
  );
};

export default VoucherProductCard;
