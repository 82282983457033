/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';

import { UtmTracker } from '../../services/utmTracker';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';
import { getParsedQueryString } from '../../utils/querystring';
import { HOME_URL, isNative } from '../../utils/mobile';
import { isAuthenticated, getToken, getDeviceData, getUtms } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const isBusinessUrl = window.location.href === `${process.env.REACT_APP_BUSINESS}/login`;

export const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const User = {
  state: {
    userData: {
      native: false,
      isAppUpdated: false,
    },
    userLoginData: {
      loginStep: 'sendAuthData',
      userAccess: '',
    },
    userAuthData: {
      userAccessValidationEmailToken: '',
      userJWT: '',
      userLogged: false,
    },
    lastTriggeredEvent: {},
    b2bFollowers: [],
    b2bFollowing: [],
    redirectFromLoginURL: '',
    botToOpen: '',
    videoconferenceData: {
      url: '',
      department: '',
    },
    vetQuestions: 0,
    modal: {
      appUpdateModal: {
        open: false,
      },
      termsModal: {
        open: false,
        context: '',
        data: {},
      },
      transferPetModal: {
        open: false,
        context: '',
        data: {},
      },
    },
    loading: {
      getUserProfileData: '',
      updateUserProfileData: '',
      requestTokenByEmail: '',
      validateEmailToken: '',
      acceptServiceTerms: '',
      refuseServiceTerms: '',
      listFollowing: '',
      listFollowers: '',
      getEventTrigger: '',
      getUrlTelemedicineCall: '',
      getUrlTriageCall: '',
      utmTrack: '',
      loginFacebook: '',
      loginGoogle: '',
    },
  },
  reducers: {
    SET_USER_DATA: (state, payload) => {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'userData', value: JSON.stringify(payload), from: 'web' } }));
      }
      localStorage.setItem('userData', JSON.stringify(payload));
      const { type, firstName, email, phone } = payload;

      const isB2B = type && type !== 'T' && type !== '';
      const isB2BProfileFilled = firstName && email && phone;
      const isB2CProfileFilled = firstName && email && phone;
      const isProfileFilled = isB2B ? !!isB2BProfileFilled : !!isB2CProfileFilled;

      return { ...state, userData: { ...state.userData, ...payload, isB2B, isProfileFilled }, userAuthData: { ...state.userAuthData, userLogged: true } };
    },
    SET_BOT_TO_OPEN: (state, payload) => ({ ...state, botToOpen: payload }),
    SET_APP_IS_UPDATED: (state, payload) => ({ ...state, userData: { ...state.userData, isAppUpdated: payload } }),
    SET_LOGIN_STEP: (state, payload) => ({ ...state, userLoginData: { ...state.userLoginData, loginStep: payload } }),
    SET_USER_ACCESS: (state, payload) => ({ ...state, userLoginData: { ...state.userLoginData, userAccess: payload } }),
    SET_USER_BH: (state, payload) => ({ ...state, userData: { ...state.userData, isUserFromBH: payload } }),
    SET_B2B_FOLLOWERS: (state, payload) => ({ ...state, b2bFollowers: payload }),
    SET_B2B_FOLLOWING: (state, payload) => ({ ...state, b2bFollowing: payload }),
    SET_EMAIL_ACCESS_VALIDATION_TOKEN: (state) => {
      const getUrlHashes = window.location.hash.substr(1);

      const hashes = getUrlHashes.split('&').reduce((res, item) => {
        const parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
      }, {});

      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'userAccessValidationEmailToken', value: hashes.accessToken, from: 'web' } }));
      }
      localStorage.setItem('userAccessValidationEmailToken', hashes.accessToken);

      return { ...state, userAuthData: { ...state.userAuthData, userJWT: '', userAccessValidationEmailToken: hashes.accessToken, userLogged: false } };
    },
    GET_AUTH_TOKEN: (state) => {
      const token = localStorage.getItem('userJWT');

      return { ...state, userAuthData: { userJWT: token } };
    },
    SET_AUTH_TOKEN: (state, payload) => {
      if (isNative) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'userJWT', value: payload, from: 'web' } }));
      }
      localStorage.setItem('userJWT', payload);

      return { ...state, userAuthData: { ...state.userAuthData, userJWT: payload, userLogged: true } };
    },
    LOGOUT_USER: (state, err) => {
      // eslint-disable-next-line react/destructuring-assignment

      if (err.response.status >= 401 && err.response.status <= 403) {
        if (isNative) {
          window.ReactNativeWebView?.postMessage('CLEAR_STORAGE');
        }

        localStorage.clear();

        Mixpanel.track(MIXPANEL_TAGS.SIGNOUT);
        Mixpanel.reset();

        if (location.href !== '/login') {
          location.href = '/login';
        }

        if (isBusinessUrl && err?.response?.status === 401) {
          window.location.href = `${process.env.REACT_APP_WEB}/landing`;
        }

        return {};
      }

      return { ...state };
    },
    SET_DEVICE_NATIVE: (state, payload) => ({ ...state, userData: { ...state.userData, native: payload } }),
    SET_EVENT_TRIGGER: (state, payload) => ({ ...state, userData: { ...state.userData, eventTrigger: payload } }),
    SET_LAST_EVENT_TRIGGER: (state, payload) => ({ ...state, lastTriggeredEvent: payload }),
    SET_VET_QUESTIONS: (state, payload) => ({ ...state, vetQuestions: payload }),
    SET_LOADING: (state, payload) => ({ ...state, loading: { ...state.loading, ...payload } }),
    SET_REDIRECT_FROM_LOGIN_URL: (state, payload) => ({ ...state, redirectFromLoginURL: payload }),
    SET_VIDEOCONFERENCE_DATA: (state, payload) => ({ ...state, videoconferenceData: payload }),
    SET_MODAL: (state, payload) => ({ ...state, modal: { ...state.modal, ...payload } }),
  },
  effects: (dispatch) => ({
    async getUserProfileData() {
      dispatch.User.SET_LOADING({ getUserProfileData: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/profile/detail`, headers());

        if (response && response.data) {
          dispatch.User.SET_LOADING({ getUserProfileData: 'fulfilled' });
          dispatch.User.SET_USER_DATA(response.data);

          if (response.data.eventTrigger) {
            dispatch.User.SET_LAST_EVENT_TRIGGER(response.data.eventTrigger);
          }

          UtmTracker.identify(response.data?.id);
          Mixpanel.alias(response.data?.username, response.data?.id);
          if (response.data?.email) {
            Mixpanel.register({ $email: response.data?.email });
            Mixpanel.alias(response.data?.email, response.data?.id);
          }
          if (response.data?.phone) {
            Mixpanel.alias(response.data?.phone, response.data?.id);
          }

          const { hasAcceptedServiceTerms } = response.data;

          if (!hasAcceptedServiceTerms) {
            window.ReactNativeWebView?.postMessage('SHOW_MODAL_ACCEPT_TERMS');
            dispatch.User.setModal({ termsModal: { open: true, context: 'acceptTerms' } });
          }
        }

        return response;
      } catch (err) {
        console.log(err);
        dispatch.User.SET_LOADING({ getUserProfileData: 'rejected' });
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    setDevice(userAgent) {
      const native = userAgent.includes('wv') && userAgent.includes('Android');

      dispatch.User.SET_DEVICE_NATIVE(native);
    },
    async listFollowers() {
      dispatch.User.SET_LOADING({ listFollowers: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/profile/list-followers`, headers());

        if (response && response.data) {
          dispatch.User.SET_LOADING({ listFollowers: 'fulfilled' });
          dispatch.User.SET_B2B_FOLLOWERS(response.data);
        }

        return response;
      } catch (err) {
        dispatch.User.SET_LOADING({ listFollowers: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async listFollowing() {
      dispatch.User.SET_LOADING({ listFollowing: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/profile/list-following`, headers());

        if (response && response.data) {
          dispatch.User.SET_LOADING({ listFollowing: 'fulfilled' });
          dispatch.User.SET_B2B_FOLLOWING(response.data);
        }

        return response;
      } catch (err) {
        dispatch.User.SET_LOADING({ listFollowing: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async updateUserProfileData(payload) {
      dispatch.User.SET_LOADING({ updateUserProfileData: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/profile/update`, payload, headers());

        if (response && response.data) {
          dispatch.User.SET_LOADING({ updateUserProfileData: 'fulfilled' });
          dispatch.User.SET_USER_DATA(response.data);
        }

        return response;
      } catch (err) {
        dispatch.User.SET_LOADING({ updateUserProfileData: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async requestTokenByEmail(payload) {
      dispatch.User.SET_LOADING({ requestTokenByEmail: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/code-validation/send-invite`, payload);

        if (response && response.data) {
          dispatch.User.SET_LOADING({ requestTokenByEmail: 'fulfilled' });
        }

        return response;
      } catch (err) {
        dispatch.User.SET_LOADING({ requestTokenByEmail: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async validateEmailToken() {
      dispatch.User.SET_LOADING({ validateEmailToken: 'loading' });
      dispatch.User.SET_EMAIL_ACCESS_VALIDATION_TOKEN();

      try {
        const response = await axios.post(`${baseURL}/code-validation/validate`, { token: localStorage.getItem('userAccessValidationEmailToken') });

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ validateEmailToken: 'fulfilled' });
          await dispatch.User.SET_AUTH_TOKEN(response.data.access);
        }

        return response;
      } catch (err) {
        dispatch.User.SET_LOADING({ validateEmailToken: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async acceptServiceTerms() {
      await dispatch.User.SET_LOADING({ acceptServiceTerms: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/accept-service-terms`, {}, headers());

        if (response && response.data) {
          await dispatch.User.SET_USER_DATA(response.data);
          await dispatch.User.SET_LOADING({ acceptServiceTerms: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ acceptServiceTerms: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async refuseServiceTerms() {
      await dispatch.User.SET_LOADING({ refuseServiceTerms: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/refuse-service-terms`, {}, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ refuseServiceTerms: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ refuseServiceTerms: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async inactivateAccount() {
      try {
        const response = await axios.get(`${baseURL}/auth/inactive`, headers());

        if (response && response.data) {
          dispatch.User.LOGOUT_USER();
        }

        return response;
      } catch (err) {
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getProfileAvatar(payload) {
      try {
        const response = await axios.get(`${baseURL}/photo/${payload}`, headers());

        return response;
      } catch (err) {
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async authRedirect(history) {
      const urlHasAccessToken = location.hash.includes('accessToken');
      const { pathname } = location;
      const isUserLogged = isAuthenticated();

      try {
        if (urlHasAccessToken) {
          const validateToken = await dispatch.User.validateEmailToken();

          const userHasValidAccessToken = validateToken && validateToken.data;
          const userAccessTokenIsExpired = validateToken && validateToken.response && validateToken.response.data.message.includes('expired code');

          if (userHasValidAccessToken) {
            return history.push(`${HOME_URL}${history.location.search}`);
          }

          if (userAccessTokenIsExpired) {
            const emailOrPhone = validateToken.response.data.message.split(' ').slice(-1)[0];

            return history.push(`/login#accessToken=expired&validation=${emailOrPhone}`);
          }
        } else if (isUserLogged) {
          if (location.search?.includes('openbot')) {
            const querystring = getParsedQueryString(location.search);

            dispatch.User.setBotToOpen(querystring.openbot);
          }

          if (pathname === '/' || pathname === '/login') {
            return history.push(`${HOME_URL}${history.location.search}`);
          }

          return history.push(pathname + history.location.search);
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }

      return null;
    },
    async loginFacebookCode(payload) {
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      const response = await axios.post(`${baseURL}/auth/facebook/login-code`, {
        ...payload,
        ...(extras && { extras }),
      }, headers());

      if (response && response.data) {
        await dispatch.User.SET_AUTH_TOKEN(response.data.access);
      }

      return response;
    },
    async loginFacebook(payload) {
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      const response = await axios.post(`${baseURL}/auth/facebook/login`, {
        ...payload,
        ...(extras && { extras }),
      }, headers());

      if (response && response.data) {
        await dispatch.User.SET_AUTH_TOKEN(response.data.access);
      }
      return response;
    },
    async loginGoogle(payload) {
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      const response = await axios.post(`${baseURL}/auth/google/login`, {
        ...payload,
        ...(extras && { extras }),
      }, headers());

      if (response && response.data) {
        await dispatch.User.SET_AUTH_TOKEN(response.data.access);
      }
      return response;
    },
    async loginApple(payload) {
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      const response = await axios.post(`${baseURL}/auth/apple/login`, {
        ...payload,
        ...(extras && { extras }),
      }, headers());

      if (response && response.data) {
        await dispatch.User.SET_AUTH_TOKEN(response.data.access);
      }
      return response;
    },
    async sendToken(payload) {
      await dispatch.User.SET_LOADING({ sendToken: 'loading' });
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      try {
        const response = await axios.post(`${baseURL}/login-code/send-token`, {
          ...payload,
          ...(extras && { extras }),
        });

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ sendToken: 'fulfilled' });
          dispatch.User.SET_USER_ACCESS(payload.userAccess);
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ sendToken: 'rejected' });
        console.log(err);
        if (err?.response?.status === 401) {
          window.ReactNativeWebView?.postMessage('ERROR_LOGIN_B2B');
        }
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async validateToken(payload) {
      await dispatch.User.SET_LOADING({ validateToken: 'loading' });
      const device = getDeviceData();
      let extras = {};

      if (device) {
        const deviceData = JSON.parse(device);
        extras = { ...deviceData, rawBody: device };
      }

      try {
        const response = await axios.post(`${baseURL}/login-code/validate-token`, {
          ...payload,
          ...(extras && { extras }),
        });

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ validateToken: 'fulfilled' });
          dispatch.User.SET_AUTH_TOKEN(response.data.access);
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ validateToken: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async logout() {
      try {
        location.href = '/login';
        Mixpanel.track(MIXPANEL_TAGS.SIGNOUT);
        Mixpanel.reset();
        window.ReactNativeWebView?.postMessage('HANDLE_LOGOUT');
        window.ReactNativeWebView?.postMessage('CLEAR_STORAGE');
        localStorage.clear();
        const response = await axios.get(`${baseURL}/auth/logout`, headers());
        return response;
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    async actionTrack(payload) {
      await dispatch.User.SET_LOADING({ actionTrack: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/action-track`, payload);

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ actionTrack: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ actionTrack: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async utmTrack() {
      await dispatch.User.SET_LOADING({ utmTrack: 'loading' });
      const utms = getUtms();

      try {
        if (utms) {
          const utmData = JSON.parse(utms);

          const response = await axios.post(`${baseURL}/utm-track`, utmData, headers());
          // localStorage.removeItem('utms');
          if (response && response.data) {
            await dispatch.User.SET_LOADING({ utmTrack: 'fulfilled' });
          }
        }

        return null;
      } catch (err) {
        await dispatch.User.SET_LOADING({ utmTrack: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async getRedirectUrl(hash) {
      await dispatch.User.SET_LOADING({ getRedirectUrl: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/get-redirect-url/${hash}`);

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ getRedirectUrl: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ getRedirectUrl: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async vetQuestionCount() {
      await dispatch.User.SET_LOADING({ vetQuestionCount: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/vet-question/count`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ vetQuestionCount: 'fulfilled' });
          dispatch.User.SET_VET_QUESTIONS(response.data.count);
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ vetQuestionCount: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async vetQuestionUse() {
      await dispatch.User.SET_LOADING({ vetQuestionUse: 'loading' });

      try {
        const response = await axios.post(`${baseURL}/vet-question/use`, {}, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ vetQuestionUse: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ vetQuestionUse: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async addressGetCepInfo(cep) {
      await dispatch.User.SET_LOADING({ addressGetCepInfo: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/address/get-cep-info/${cep}`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ addressGetCepInfo: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ addressGetCepInfo: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getUrlTelemedicineCall() {
      await dispatch.User.SET_LOADING({ getUrlTelemedicineCall: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/videoconference/get-url`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ getUrlTelemedicineCall: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ getUrlTelemedicineCall: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getUrlTriageCall(token) {
      await dispatch.User.SET_LOADING({ getUrlTriageCall: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/videoconference/triage/get-url?token=${token}`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ getUrlTriageCall: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ getUrlTriageCall: 'rejected' });
        console.log(err);
        return err;
      }
    },
    async getUrlTelemedicineCallDepartment(department) {
      await dispatch.User.SET_LOADING({ getUrlTelemedicineCallDepartment: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/videoconference/get-url/${department}`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ getUrlTelemedicineCallDepartment: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ getUrlTelemedicineCallDepartment: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async attendantsOnline() {
      await dispatch.User.SET_LOADING({ attendantsOnline: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/videoconference/attendants-online`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ attendantsOnline: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ attendantsOnline: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async deliveryAreaCheck() {
      await dispatch.User.SET_LOADING({ deliveryAreaCheck: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/delivery-area/check/${process.env.REACT_APP_DELIVERY_AREA}`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ deliveryAreaCheck: 'fulfilled' });
          await dispatch.User.SET_USER_BH(response.data.status);
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ deliveryAreaCheck: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getStateOptions() {
      await dispatch.User.SET_LOADING({ stateOptions: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/state/list`, headers());

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ stateOptions: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ stateOptions: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getCityOptions({ uf, name }) {
      await dispatch.User.SET_LOADING({ cityOptions: 'loading' });

      try {
        const response = await axios.get(`${baseURL}/city/list/${uf}`, {
          ...headers(),
          params: { name },
        });

        if (response && response.data) {
          await dispatch.User.SET_LOADING({ cityOptions: 'fulfilled' });
        }

        return response;
      } catch (err) {
        await dispatch.User.SET_LOADING({ cityOptions: 'rejected' });
        console.log(err);
        // await dispatch.User.LOGOUT_USER(err);
        return err;
      }
    },
    async getDiscountCouponBalance() {
      try {
        const url = `${baseURL}/discount-coupon/balance`;

        const response = await axios.get(url, { ...headers() });

        return response.data;
      } catch (error) {
        console.error(error);
        return error;
      }
    },
    setAppIsUpdated(payload) {
      dispatch.User.SET_APP_IS_UPDATED(payload);
    },
    setLoginStep(payload) {
      dispatch.User.SET_LOGIN_STEP(payload);
    },
    setLastEventTrigger(payload) {
      dispatch.User.SET_LAST_EVENT_TRIGGER(payload);
    },
    clearEventTrigger() {
      dispatch.User.SET_LAST_EVENT_TRIGGER({});
      dispatch.Appointments.clearPublicSchedule();
    },
    clearDataAndRedirect() {
      dispatch.User.LOGOUT_USER();
    },
    setModal(status) {
      dispatch.User.SET_MODAL(status);
    },
    setRedirectFromLoginURL(url) {
      dispatch.User.SET_REDIRECT_FROM_LOGIN_URL(url);
    },
    setVideoConferenceData(payload) {
      dispatch.User.SET_VIDEOCONFERENCE_DATA(payload);
    },
    setBotToOpen(payload) {
      dispatch.User.SET_BOT_TO_OPEN(payload);
    },
  }),
};
