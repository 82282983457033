import React from 'react';
import moment from 'moment';
import { InformationContainer } from './style';

const PetInformation = ({ sex, weight, breed, birthdate }) => {
  const isDashboardRoute = location.pathname.includes('dashboard');

  const getAge = (dateString) => {
    const today = moment();
    const birthDate = moment(dateString);
    const diffInYears = moment.duration(today.diff(birthDate)).asYears();

    return diffInYears.toFixed(1);
  };

  const breedMap = {
    M: 'Macho',
    MC: 'Macho cadastrado',
    F: 'Fêmea',
    FC: 'Fêmea castrada',
  };

  return (
    <InformationContainer isDashboardRoute={isDashboardRoute}>
      <div className='info sex'>
        <span className='info-title'>
          SEXO
        </span>
        <span className='info-content'>
          {(sex && breedMap[sex]) || '-'}
        </span>
      </div>
      <div className='info breed'>
        <span className='info-title'>
          RAÇA
        </span>
        <span className='info-content'>
          {(breed && breed.name) || '-'}
        </span>
      </div>
      <div className='info weight'>
        <span className='info-title'>
          PESO(KG)
        </span>
        <span className='info-content'>
          {weight || '-'}
        </span>
      </div>
      <div className='info age'>
        <span className='info-title'>
          IDADE
        </span>
        <span className='info-content'>
          { birthdate ? getAge(birthdate) : '-' }
        </span>
      </div>
    </InformationContainer>
  );
};

export default PetInformation;
