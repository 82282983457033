import React from 'react';
import { Card } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledCardContent, HealthControlButton } from './style';
import PetHeaderPreview from './PetHeaderPreview';
import { ReactComponent as HealthIcon } from '../../../../assets/images/PetCard/HealthControl.svg';

const PetCardPreview = ({ id, name, breed, avatarImage, healthy, user, permissions }) => {
  const history = useHistory();
  const askQuestionPermission = permissions !== undefined && permissions.hasOwnProperty('askQuestionToVet') && permissions.askQuestionToVet;
  const { isB2B } = useSelector((state) => state.User.userData);

  const redirectPetPage = () => {
    history.push(`/pet/${id}`);
  };

  return (
    <Card
      elevation={0}
      sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}
      onClick={redirectPetPage}
    >
      <StyledCardContent>
        <PetHeaderPreview
          id={id}
          breed={breed}
          name={name}
          avatarImage={avatarImage}
          healthy={healthy}
          user={user}
          permissions={permissions}
        />
        <HealthControlButton isB2B={isB2B} askQuestionPermission={askQuestionPermission} startIcon={<HealthIcon />}>Controle de saúde</HealthControlButton>
      </StyledCardContent>
    </Card>
  );
};

export default PetCardPreview;
