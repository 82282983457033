import styled from 'styled-components';
import { Box } from '@mui/material';

export const ModalBox = styled(Box)`
    &.MuiBox-root {
      background-color: white;
      margin-top: 3px;
      position: absolute;
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 700px;
      height: auto;
      padding: 20px 40px;
      overflow-y: auto;

      @media screen and (max-width: 736px) {
        width: 100%;
        margin-top: 60px;
        max-height: 90vh;
        top: 0;
        left: 0;
        transform: none;
      }

      @media screen and (max-height: 736px) and (min-width: 736px) {
        top: 45%;
      }

    & .MuiTypography-h6 {
        margin: 10px 0;
    }
`;

export const CloseModal = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ghostwhite;
    left: 90%;
    cursor: pointer;
    opacity: 0.4;
    transition: background-color .2s ease-in, fill .8s ease-in;

    &:hover {
        svg {
          fill: white;
        }

        background-color: black;
    }

    @media screen and (max-width: 736px) {
      left: 80%;
    }

    @media screen and (max-width: 500px) {
      left: 75%;
    }
`;

export const PetPhoto = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
`;
