import { Grid, Checkbox, FormControlLabel, FormGroup, Typography, TextField, Button } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { ReactComponent as AddIcon } from '../../../../../assets/images/Services/add.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/images/Services/trash.svg';

const WeekDayRow = ({ errors, readOnly, weekdayNumber, setFieldValue }) => {
  const dispatch = useDispatch();
  const range = useSelector((state) => state.Appointments.actualService.availability[weekdayNumber].range);

  const weekdayMap = {
    0: 'Segunda-feira',
    1: 'Terça-feira',
    2: 'Quarta-feira',
    3: 'Quinta-feira',
    4: 'Sexta-feira',
    5: 'Sábado',
    6: 'Domingo',
  };

  const addAvailability = () => dispatch.Appointments.addAvailability(weekdayNumber);
  const deleteAvailability = (index) => dispatch.Appointments.deleteAvailability({ weekDay: weekdayNumber, index });

  const checkToggleAvailability = () => {
    if (range.length > 0) {
      // for velha guarda kkk
      const size = range.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < size; i++) {
        deleteAvailability(0);
      }
    } else {
      dispatch.Appointments.addAvailability(weekdayNumber);
    }
  };

  return (
    <Grid container wrap='nowrap' direction='row' justifyContent='center' alignItems='flex-start' sx={{ boxShadow: 'inset 0px -1px 0px #E0E0E0' }} gap={3}>
      <FormGroup sx={{ width: '50%', padding: '0 16px' }}>
        <FormControlLabel
          control={(
            <Checkbox
              id={`availability[${weekdayNumber}]]`}
              name={`availability[${weekdayNumber}]`}
              disabled={readOnly}
              sx={{ visibility: !readOnly ? 'visible' : 'hidden' }}
              checked={range.length > 0}
              onChange={() => {
                checkToggleAvailability();
              }}
            />
          )}
          label={
            <Typography sx={{ width: '100px' }} variant='body2'>{weekdayMap[weekdayNumber]}</Typography>
          }
        />
      </FormGroup>

      <Grid container direction='column' gap={2} sx={{ marginTop: '0px', width: '100%' }}>
        {range && range.length ? (
          <>
            {range.map((period, index) => (
              <>
                <Grid container direction='row' wrap='nowrap' justifyContent='space-between' alignItems='center' sx={{ padding: '10px' }} gap={2}>
                  <Grid item>
                    <InputMask
                      mask='99:99'
                      onChange={(event) => {
                        setFieldValue(`periodStart-${weekdayNumber}-${index}-${period.period_start}`, event.target.value);
                        // eslint-disable-next-line no-param-reassign
                        range[index].period_start = event.target.value;
                      }}
                      maskplaceholder='_'
                      value={period.period_start}
                      disabled={readOnly}
                    >
                      {(inputProps) => (
                        <TextField
                          id={`availability[${weekdayNumber}].range[${index}].period_start`}
                          name={`availability[${weekdayNumber}].range[${index}].period_start`}
                          error={errors
                            && errors.availability
                            && errors.availability[weekdayNumber]
                            && errors.availability[weekdayNumber].range[index]
                            && !!errors.availability[weekdayNumber].range[index].period_start}
                          label='Hora Inicial'
                          disabled={readOnly}
                          sx={{ width: '130px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item>
                    <Typography variant='body2'>-</Typography>
                  </Grid>

                  <Grid item>
                    <InputMask
                      mask='99:99'
                      onChange={(event) => {
                        setFieldValue(`period_end-${weekdayNumber}-${index}-${period.period_end}`, event.target.value);
                        // eslint-disable-next-line no-param-reassign
                        range[index].period_end = event.target.value;
                      }}
                      maskplaceholder='_'
                      value={period.period_end}
                      disabled={readOnly}
                    >
                      {(inputProps) => (
                        <TextField
                          id={`availability[${weekdayNumber}].range[${index}].period_end`}
                          name={`availability[${weekdayNumber}].range[${index}].period_end`}
                          error={errors
                            && errors.availability
                            && errors.availability[weekdayNumber]
                            && errors.availability[weekdayNumber].range[index]
                            && !!errors.availability[weekdayNumber].range[index].period_end}
                          label='Hora Final'
                          disabled={readOnly}
                          sx={{ width: '130px', '& .MuiFormHelperText-root': { marginLeft: 0 } }}
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item>
                    {(!readOnly) && (
                      <Button
                        sx={{ color: '#6B48FF' }}
                        startIcon={<TrashIcon />}
                        onClick={() => { deleteAvailability(index); }}
                      />
                    )}
                  </Grid>

                  {(!readOnly) ? (
                    <Grid item>
                      <Button
                        sx={{ color: '#6B48FF', visibility: index === 0 ? 'visible' : 'hidden' }}
                        startIcon={<AddIcon />}
                        onClick={addAvailability}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  ) : <><Grid item><>&nbsp;</></Grid></>}

                </Grid>
              </>
            ))}
          </>
        ) : (
          <Grid item sx={{ width: '210px', padding: '18px 5px 8px 5px' }}>
            <Typography variant='body2' color='error'>Indisponível</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WeekDayRow;
