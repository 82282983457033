/* eslint-disable no-unused-expressions */
import { Avatar, Snackbar, Fade, Card, Divider, Grid, Typography, Chip, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import { PickersDay } from '@mui/lab';
import { ReactComponent as ArrowRight } from '../../../assets/images/Services/arrow_right.svg';
import { EventTitle, PartnerTitle, Calendar, AvailablePeriod, UnavaiablePeriods } from './style';
import { isAuthenticated } from '../../../services/api';
import Alert from '../../global/Alert';
import Loading from '../../global/Loading';

const PublicServicesSchedule = () => {
  const [dateSelected, setDateSelected] = useState(moment());
  const [isPeriodSelected, setIsPeriodSelected] = useState(false);
  const [month, setMonth] = useState(moment(dateSelected).month());
  const [calendarDates, setCalendarDates] = useState([]);
  const [successLink, setSuccessLink] = useState(false);

  const { publicListScheduleB2CToB2B: publicListScheduleB2CToB2BLoading } = useSelector((state) => state.Appointments.loading);
  const { publicListScheduleB2CToB2B } = useSelector((state) => state.Appointments) || [];
  const { name, user, description } = useSelector((state) => state.Appointments.actualPublicService);

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const isAvailabilityLoading = publicListScheduleB2CToB2BLoading !== 'fulfilled';

  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!user?.profileImage && fetchProfileImage();
  }, [user]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessLink(false);
  };

  const copyScheduleLink = () => {
    navigator.clipboard.writeText(window.location.href);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ clipboard: { content: window.location.href } }));
    setSuccessLink(true);
  };

  const handleSelectPeriod = (eventStart) => {
    setIsPeriodSelected(true);

    dispatch.Appointments.setPublicSchedule({ url: window.location.href, eventStart, userB2BId: params.id, serviceId: params.serviceId });

    const isUserLogged = isAuthenticated();

    if (isUserLogged) {
      history.push(`/services/create/${params.serviceId}`);
    } else {
      history.push('/login');
    }
  };

  const fetchPublicService = () => {
    const { id, serviceId } = params;

    const payload = { userId: id, serviceId };

    dispatch.Appointments.publicServiceDetail(payload);
  };

  const fetchDayScheduleList = (selectedDay) => {
    const payload = {
      userId: params.id,
      service: params.serviceId,
      startDate: moment(selectedDay).startOf('day').format(),
      endDate: moment(selectedDay).endOf('day').format(),
    };

    dispatch.Appointments.publicListScheduleB2CToB2B(payload);
  };

  useEffect(() => {
    if (calendarDates && calendarDates.length > 0) {
      const findDate = calendarDates.find((calendar) => calendar.hasTimeAvailable);
      if (findDate) {
        setDateSelected(moment(findDate.date).startOf('day').format());
        const buttonClick = '.badge-'.concat(moment(findDate.date).format('YYYY-MM-DD')).concat(' button');

        if (document.querySelector(buttonClick)) {
          setTimeout(() => { document.querySelector(buttonClick).click(); }, 500);
        }
      } else {
        // Caso nao exista disponibilidade clicar no botao de proximo
        setTimeout(() => { document.querySelector('button[title="Next month"]').click(); }, 500);
      }
    }
  }, [calendarDates]);

  useEffect(() => fetchDayScheduleList(dateSelected), [dateSelected]);

  useEffect(() => {
    fetchPublicService();
    window.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    const response = await dispatch.Appointments.publicB2CCalendarAvailability({
      userId: params.id,
      serviceId: params.serviceId,
      startDate: moment(dateSelected).startOf('month').format(),
      endDate: moment(moment(moment(moment(dateSelected).endOf('month')).add(1, 'day'))).startOf('day').format(),
    });

    if (response && response.data && response.data.length) {
      const days = response.data.map((availability) => ({ ...availability, date: moment(availability.date) }));

      setCalendarDates(days);
    }
  }, [month]);

  const redirectToLastPage = () => {
    if (isPeriodSelected) {
      return setIsPeriodSelected(false);
    }

    const path = [...location.pathname.split('/').slice(0, 3), ...location.pathname.split('/').slice(-1)].join('/');

    return history.push(path);
  };

  return (
    <Fade in>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
          spacing={1}
        >
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={successLink}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <div>
              <Alert onClose={handleClose}>
                Link da agenda copiado com sucesso
              </Alert>
            </div>
          </Snackbar>

          <Grid onClick={redirectToLastPage} container direction='row' alignItems='center' sx={{ padding: '0px 0px 18px 0', marginLeft: '5px' }}>
            <Grid item>
              <Button
                size='large'
                startIcon={<ArrowBackIcon sx={{ color: '#6B48FF' }} />}
                sx={{ color: '#6B48FF', padding: '0px', marginRight: '5px' }}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Card elevation={0} sx={{ padding: '17px 32px' }}>
              <Grid
                container
                direction='column'
                gap={2}
              >
                <Grid container justifyContent='space-between'>
                  <Typography variant='h6'>Novo agendamento</Typography>
                  <ShareIcon onClick={copyScheduleLink} sx={{ color: '#6B48FF', cursor: 'pointer' }} />
                </Grid>

                <Grid container direction='row' wrap='nowrap'>
                  <Avatar
                    sx={{ width: '56px', height: '56px' }}
                    src={user?.profileImage && profileImgSrc}
                    alt={user?.firstName}
                  />

                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    sx={{ width: '50%', marginLeft: '12px' }}
                  >
                    <PartnerTitle>{user && user.firstName}</PartnerTitle>
                    <EventTitle>{name}</EventTitle>
                  </Grid>

                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                      {description || ' '}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider />

                <Grid item>
                  <Calendar
                    variant='static'
                    value={dateSelected}
                    defaultCalendarMonth={moment(dateSelected)}
                    maxDate={moment().endOf('year')}
                    showDaysOutsideCurrentMonth={false}
                    outsideCurrentMonth={false}
                    onMonthChange={(monthDate) => {
                      setMonth(moment(monthDate).month());
                      setDateSelected(moment(monthDate).startOf('day').format());
                    }}
                    onChange={(newDate) => {
                      const nextDate = moment(newDate).startOf('day').format();
                      setDateSelected(nextDate);
                    }}
                    shouldDisableDate={(calendarDate) => {
                      const findDate = calendarDates.length && calendarDates.find((calendar) => moment(calendarDate).isSame(calendar.date));

                      const hasTimeAvailable = (findDate && !findDate.hasTimeAvailable);

                      return hasTimeAvailable;
                    }}
                    renderDay={(day, _value, DayComponentProps) => {
                      const renderedDay = moment(day);
                      const selected = moment(renderedDay).isSame(dateSelected);

                      return (
                        <PickersDay sx={selected && { backgroundColor: '#6B48FE', color: 'white' }} {...DayComponentProps} />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Card>

          </Grid>

          <Grid item>
            <Card elevation={0} sx={{ padding: '17px 16px' }}>
              <Grid container gap={3}>

                <Grid container direction='column' alignItems='center'>
                  <Typography variant='h6'>Horários livres</Typography>
                  <Typography variant='body2'>{moment(dateSelected).format('DD [de] MMMM [|] dddd')}</Typography>
                </Grid>

                {isAvailabilityLoading ? (
                  <Grid container direction='column' justifyContent='center' alignItems='center' sx={{ minHeight: '40vh' }}>
                    <Grid item>
                      <Loading />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container direction='column'>
                      {publicListScheduleB2CToB2B && publicListScheduleB2CToB2B.length ? publicListScheduleB2CToB2B
                        .map((service) => (
                          <AvailablePeriod
                            sx={{ '&.MuiListItem-root': { cursor: service.eventStatus === 'available' ? 'pointer' : 'default' } }}
                            onClick={service.eventStatus === 'available'
                              ? () => handleSelectPeriod(service.eventStart)
                              : undefined}
                          >

                            <Typography
                              sx={{ opacity: service.eventStatus === 'Pending' || service.eventStatus === 'unavailable' ? 0.3 : 1 }}
                            >
                              {moment(service.eventStart).utc().format('HH:mm')}
                            </Typography>

                            {service.eventStatus === 'Pending'
                              ? <Chip size='small' sx={{ color: 'white', background: '#6B48FE' }} label='Este é seu horário agendado' />
                              : service.eventStatus === 'available' && <ArrowRight />}
                          </AvailablePeriod>
                        )) : (
                          <Grid container justifyContent='center'>
                            <UnavaiablePeriods>
                              Nenhum horário disponível esse dia. Que tal
                              {' '}
                              <b>selecionar outra data</b>
                              ?
                            </UnavaiablePeriods>
                          </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default PublicServicesSchedule;
