/* eslint-disable no-unused-expressions */
import { Avatar, Chip, Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { PartnerAddress, PartnerDescription, PartnerTitle } from './style';
import { ReactComponent as MapPinIcon } from '../../../../assets/images/Services/map_pin.svg';

const ServicePartnerCard = ({ description, user, address, nextScheduling }) => {
  const dispatch = useDispatch();
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [profileImgSrc, setProfileImgSrc] = useState(null);

  const fetchProfileImage = async () => {
    const response = await dispatch.Photo.getPhoto(user.profileImage);
    setProfileImgSrc(response);
  };

  useEffect(() => {
    !!user?.profileImage && fetchProfileImage();
  }, []);

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      sx={{ width: '100%', cursor: 'pointer;' }}
      wrap='nowrap'
      gap={2}
    >
      <Grid item sx={{ position: 'relative' }}>
        <Skeleton variant='circular' width={40} height={40} sx={{ position: 'absolute', zIndex: 50 }} />
        <Avatar
          alt={user?.firstName || ''}
          src={profileImgSrc}
          onLoad={() => setImageHasLoaded(true)}
          sx={{ visibility: imageHasLoaded ? 'visible' : 'hidden' }}
        />
      </Grid>

      <Grid item>
        <Grid
          container
          direction='column'
          gap={0}
        >
          <PartnerTitle>{user?.firstName || ''}</PartnerTitle>
          <PartnerDescription>
            {description && description.length < 70
              ? description
              : (description && `${description.slice(0, 60)}...`) || ''}

          </PartnerDescription>

          <Grid
            container
            direction='row'
            alignItems='center'
            wrap='nowrap'
          >
            <MapPinIcon sx={{ marginRight: '10px' }} />
            <PartnerAddress>{(address?.street && (address.street.length < 35 ? address.street : `${address.street.slice(0, 35)}...`)) || 'Endereço não informado'}</PartnerAddress>
          </Grid>

          {nextScheduling ? (
            <Grid
              container
              direction='row'
              alignItems='center'
              gap={1}
              sx={{ marginTop: '10px' }}
            >
              <Chip label={moment(nextScheduling.eventStart).utc().format('DD/MM')} size='small' sx={{ backgroundColor: '#6B48FE', color: 'white' }} />
              <Typography color='#9E9E9E'>
                {`${moment(nextScheduling.eventStart).utc().format('HH:mm')} -
                  ${moment(nextScheduling.eventEnd).utc().format('HH:mm')}
                   (${moment.duration(moment(nextScheduling.eventEnd).utc().diff(moment(nextScheduling.eventStart).utc())).asMinutes()} min)`}
              </Typography>
            </Grid>
          ) : <></>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicePartnerCard;
