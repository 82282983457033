import { FormHelperText, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const ShrinkMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    width: 100%;
    white-space: pre-wrap;
  }
`;

export const HelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  }
`;

export const ButtonModal = styled.button`
    border: 0;
    margin: 0;
    outline: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background-color: transparent;
    color: ${(props) => (props.mainButton ? '#6B48FF' : '#707173')};
`;

export const Image = styled.img`
  object-fit: fill;
  width: 100%;
  height: 160px;
`;

export const ResponsibleSelect = styled(Select)`
    & .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
`;
