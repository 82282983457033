import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';

import circles from '../../../assets/images/ProtocolModal/circles.png';
import resultCardiacGood from '../../../assets/images/ProtocolModal/result_cardiac_good.jpg';
import resultCardiacBad from '../../../assets/images/ProtocolModal/result_cardiac_bad.jpg';
import resultBreastGood from '../../../assets/images/ProtocolModal/result_breast_good.jpg';
import resultBreastBad from '../../../assets/images/ProtocolModal/result_breast_bad.jpg';
import resultBodyEmaciated from '../../../assets/images/ProtocolModal/result_body_emaciated.jpg';
import resultBodyUnderweight from '../../../assets/images/ProtocolModal/result_body_underweight.jpg';
import resultBodyRegular from '../../../assets/images/ProtocolModal/result_body_regular.jpg';
import resultBodyOverweight from '../../../assets/images/ProtocolModal/result_body_overweight.jpg';
import resultBodyObese from '../../../assets/images/ProtocolModal/result_body_obese.jpg';
import AttachmentImage from '../../../assets/images/ProtocolModal/AttachmentMedia.png';

import { ProtocolImage } from '../ProtocolModal/style';
import { isMobile, isNative } from '../../../utils/mobile';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import ProtocolProgress from '../../PetManagement/ProtocolProgress';
import { ButtonModal, ErgonomicDescription, ErgonomicImage, ErgonomicTitle, ResultImage } from './style';

const resultMap = {
  heart_control: {
    good: resultCardiacGood,
    bad: resultCardiacBad,
  },
  breast_exam: {
    good: resultBreastGood,
    bad: resultBreastBad,
  },
  body_score: {
    emaciated: resultBodyEmaciated,
    underweight: resultBodyUnderweight,
    regular: resultBodyRegular,
    overweight: resultBodyOverweight,
    obese: resultBodyObese,
  },
};

const ResultPhoto = () => {
  const dispatch = useDispatch();
  const [protocolReceiptImage, setProtocolReceiptImage] = useState(AttachmentImage);
  const lastDoseReceiptImage = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastDoseReceiptImage);
  const type = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.type);
  const content = useSelector((state) => state.Modal.protocolResultDialog?.content);
  const result = useSelector((state) => state.Modal.protocolResultDialog?.result);
  const [types] = useState(['breast_exam', 'heart_control', 'body_score']);

  const handleUpload = () => document.getElementById('imgupload').click();

  const handleImage = async () => {
    if (lastDoseReceiptImage) {
      return window.open(protocolReceiptImage);
    }

    return handleUpload();
  };

  const fetchProtocolImage = async () => {
    const response = await dispatch.Photo.getPhoto(lastDoseReceiptImage);
    setProtocolReceiptImage(response);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!lastDoseReceiptImage && fetchProtocolImage();
  }, [lastDoseReceiptImage]);

  if (content && content === 'nutrition_ergonomics') {
    return (
      <ErgonomicImage>
        <ErgonomicTitle>Resultado</ErgonomicTitle>
        <ErgonomicDescription>
          Para garantir a ergonomia de seu pet o comedouro e bebedouro precisam ter entre
          {' '}
          {result?.minHeight}
          {' '}
          e
          {' '}
          {result?.maxHeight}
          {' '}
          cm de altura!
        </ErgonomicDescription>
        <ErgonomicDescription>Vasilhas mais fundas ficam melhores em alturas mais baixas, e mais rasas podem ficar mais altas</ErgonomicDescription>
        <img src={circles} alt='circles' />
      </ErgonomicImage>
    );
  }

  if (!lastDoseReceiptImage && content && !content.includes('vaccine') && types.includes(type)) {
    return <ResultImage src={content && result && resultMap[content][result]} alt='result' />;
  }

  return (
    <ProtocolImage>
      <img className={!lastDoseReceiptImage && 'placeholder'} onClick={handleImage} src={protocolReceiptImage} alt='attachmentImg' />
    </ProtocolImage>
  );
};

const ProtocolResultDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.Modal.protocolResultDialog?.open);
  const content = useSelector((state) => state.Modal.protocolResultDialog?.content);
  const [loading, setLoading] = useState(false);
  const protocolId = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.id);
  const lastDoseDate = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastDoseDate);
  const lastDoseName = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.lastDoseName);
  const nextDoseDate = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.nextDoseDate);
  const signatureRequested = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.signatureRequested);
  const showVaccinationCard = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.showVaccinationCard);
  const signatureRequired = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.signatureRequired);
  const signatureStatus = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.signatureStatus);
  const protocolMoment = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.moment);
  const progress = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.progress);
  const severity = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.severity);
  const countdown = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.countdown);
  const countdownUnity = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.countdownUnity);
  const hasFinishedProtocol = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.hasFinishedProtocol);
  const name = useSelector((state) => state.Animals.actualPet.protocols?.selectedProtocol?.name);
  // const userData = useSelector((state) => state.User.userData);
  const actualPet = useSelector((state) => state.Animals.actualPet);

  const protocolData = {
    lastDoseDate,
    lastDoseName,
    nextDoseDate,
    protocolMoment,
    progress,
    severity,
    countdown,
    countdownUnity,
    hasFinishedProtocol,
    name,
  };

  const showTime = () => (protocolMoment === 'medicines' || name === 'Petiscos' || name === 'Ração');

  const handleCloseDialog = () => dispatch.Modal.setResultDialog({ open: false });

  const handleEditProtocol = () => {
    Mixpanel.track(MIXPANEL_TAGS.PROTOCOL_ANIMAL_EDIT_CLICK, protocolData);

    setLoading(true);
    dispatch.Modal.setResultDialog({ open: false });
    dispatch.Modal.setDialog({ open: true, mode: 'edit', content });
    setLoading(false);
  };

  const handleRenewProtocol = () => {
    Mixpanel.track(MIXPANEL_TAGS.PROTOCOL_ANIMAL_RENEW_CLICK, protocolData);
    setLoading(true);
    dispatch.Modal.setResultDialog({ open: false });
    dispatch.Modal.setDialog({ open: true, mode: 'renew', content });
    setLoading(false);
  };

  const handleRequestSignature = async () => {
    handleCloseDialog();
    const getUserData = await dispatch.User.getUserProfileData();
    const { email, phone, firstName, address, cpfCnpj } = getUserData.data;

    const editPermission = actualPet.permissions && actualPet.permissions.editProfile;
    if (!editPermission) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: null }));
      return null;
    }

    if (getUserData && getUserData.data) {
      const isAddressFilled = address && address?.code && (address?.number !== null || address?.number !== undefined) && address?.city && address?.street && address?.district && address?.state;
      const isProfileFilled = firstName && email && phone && isAddressFilled && cpfCnpj;

      if (!isProfileFilled) {
        dispatch.Modal.setProfileDialog({ open: true, desc: 'Para gerar a carteirinha digital, precisamos de algumas informações do seu perfil' });
        return null;
      }

      const { furColor, furSize, breed, name: petName, sex, birthdate } = actualPet;
      const isPetFilled = furColor?.length && furSize && breed && petName && sex && birthdate;

      if (!isPetFilled) {
        dispatch.Modal.setPetDialog({ open: true });
        return null;
      }
      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'VIRTUAL_VACCINE_CARD', params: { requestSignature: protocolId } }));
    }
    return null;
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { minWidth: '350px', height: screen.height < 900 ? '95vh' : '80%' },
        '& .MuiDialog-container': { height: '100vh' } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={handleCloseDialog}
      scroll={isMobile ? 'paper' : 'body'}
    >
      <DialogContent sx={{ '&.MuiDialogContent-root': { padding: '60px 0px', position: 'relative', marginBottom: '10px' } }}>
        <Grid container direction='row' justifyContent='space-between' wrap='nowrap' sx={{ padding: '15px 25px' }}>
          <Grid item>
            <Typography variant='h6'>{(protocolMoment === 'medicines' && lastDoseName && lastDoseName) || name}</Typography>
          </Grid>

          <Grid item>
            <Close
              sx={{ position: 'absolute', cursor: 'pointer', right: '15px', top: '15px' }}
              onClick={handleCloseDialog}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' gap={2}>
          <Grid container flexDirection='column' justifyContent='flex-end' alignItems='flex-end' gap={2} sx={{ padding: '25px 25px' }}>
            <Grid item container flexDirection='row' justifyContent='flex-end'>
              <Typography sx={{ color: '#7E8192', marginRight: 1 }} variant='body2' align='right'>
                Última vez:
              </Typography>

              <Typography sx={{ color: '#7E8192', fontWeight: 'bold' }} variant='body2' align='right'>
                {`${lastDoseDate && moment(lastDoseDate).format(showTime ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY')}`}
              </Typography>
            </Grid>

            <Grid item>
              <ProtocolProgress progress={progress} severity={severity} countdown={countdown} countdownUnity={countdownUnity} nextDoseDate={nextDoseDate} hasFinishedProtocol={hasFinishedProtocol} />
            </Grid>
          </Grid>

          <Grid item sx={{ width: '90%' }}>
            <ResultPhoto />
          </Grid>

          <Grid container justifyContent='flex-end' sx={{ marginTop: '10px', padding: '0px 25px' }}>
            <Stack direction='row-reverse' spacing={2} mb={2}>
              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <>
                  {!hasFinishedProtocol && <ButtonModal mainButton onClick={handleRenewProtocol}>Renovar</ButtonModal>}
                  <ButtonModal mainButton onClick={handleEditProtocol} disabled={signatureRequested && signatureStatus === 'signed'} style={{ opacity: signatureRequested && signatureStatus === 'signed' ? 0.4 : 1 }}>Editar</ButtonModal>
                  <ButtonModal onClick={handleCloseDialog}>Cancelar</ButtonModal>
                </>
              )}
            </Stack>
            {showVaccinationCard && signatureRequired && !signatureRequested && isNative && (
              <Stack>
                <ButtonModal mainButton onClick={handleRequestSignature}>Solicitar assinatura</ButtonModal>
              </Stack>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProtocolResultDialog;
