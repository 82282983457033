import React, { useEffect, useLayoutEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../../components/Footer';
import { Home } from '../../components/Home';
import DashboardMenu from '../../components/Dashboard/DashboardMenu';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import PetAndProtocolModal from '../../components/Modals/PetAndProtocolModal';

import { isNative } from '../../utils/mobile';
import { isAuthenticated } from '../../services/api';
import { IntercomCustom } from '../../utils/intercom';
import { getParsedQueryString } from '../../utils/querystring';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

import { MainWrapper } from './style';

const Dashboard = () => {
  const isUserLogged = isAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();
  const isB2B = useSelector((state) => state.User.userData.isB2B);
  const eventTrigger = useSelector((state) => state.User.userData.eventTrigger);
  const countAnimals = useSelector((state) => state.User.userData.countAnimals);
  const botToOpen = useSelector((state) => state.User.botToOpen);

  const openChatBot = () => {
    const { search } = location;

    const querystring = getParsedQueryString(search);

    const chatBot = botToOpen || querystring?.openbot || localStorage.getItem('openbot');

    if (chatBot) {
      Mixpanel.track(MIXPANEL_TAGS.TRIAGE_BEGIN);
      if (!isNative) {
        setTimeout(() => {
          IntercomCustom.customButtonAndStart(chatBot);
          dispatch.User.setBotToOpen('');
          localStorage.removeItem('openbot');
        }, 500);
      }
    }
  };

  const checkEventRedirect = () => {
    if (eventTrigger && eventTrigger.type === 'redirect') {
      if (countAnimals) {
        history.push(`/services/create/${eventTrigger.payload.serviceId}`);
      } else {
        history.push('/pet/create');
      }
    }
  };

  useLayoutEffect(() => openChatBot(), []);
  useEffect(() => checkEventRedirect(), [eventTrigger]);

  useEffect(() => {
    if (isUserLogged) {
      if (!location.pathname.includes('/notifications')) {
        // dispatch.Notification.getSummary();
      }

      if (isB2B) {
        dispatch.Appointments.myServiceListCount();
      }
    }

    IntercomCustom.updateUserIntercomNative();
    dispatch.User.listFollowers();
    dispatch.User.listFollowing();
    Mixpanel.track(MIXPANEL_TAGS.DASHBOARD_VIEW);

    return () => {
      window.ReactNativeWebView?.postMessage('UNMOUNT_DASHBOARD_PAGE');
    };
  }, []);

  useLayoutEffect(() => {
    const checkDeliveryArea = async () => {
      await dispatch.User.deliveryAreaCheck();
    };
    checkDeliveryArea();
  }, []);

  return (
    <MainWrapper>
      <PetAndProtocolModal />
      <DashboardHeader />
      <DashboardMenu />
      <Home />
      <Footer />
    </MainWrapper>
  );
};

export default Dashboard;
