/* eslint-disable no-unused-vars */
import RedeemIcon from '@mui/icons-material/Redeem';
import { Fade, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TriageAlertDialog from '../../../pages/Telemedicine/TriageAlertDialog';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';
import { isNative } from '../../../utils/mobile';
import Loading from '../../global/Loading';
import ServiceCarousel from './ServiceCarousel';
import ServiceCategoryDialog from './ServiceCategoryDialog';
import ServiceEmptyCard from './ServiceEmptyCard';
import ServiceTypeCard from './ServiceTypeCard';
import { ServiceQuestions } from './ServiceTypeCard/style';

const ServicesDashboard = () => {
  const { listConnectedServices: listConnectedServicesLoading, listAllSchedulesB2C: listAllSchedulesB2CLoading } = useSelector((state) => state.Appointments.loading) || {};
  const { listConnectedServices, listAllSchedulesB2C } = useSelector((state) => state.Appointments) || {};
  const signingPlan = useSelector((state) => state.User.userData.signingPlan);
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);

  const vetQuestions = useSelector((state) => state.User.vetQuestions);
  const categories = [];
  const telemedicineCategory = 'Guiavet Telemedicina';
  const [open, setModalOpen] = useState(false);
  const [triageOpenModal, setTriageModalOpen] = useState(false);
  const activeEvents = listAllSchedulesB2C && listAllSchedulesB2C.length && listAllSchedulesB2C.filter((event) => event.eventStatus === 'Pending');
  const permissions = useSelector((state) => state?.FeatureSettings?.permissions) || {};

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = listConnectedServicesLoading !== 'fulfilled';
  const isSchedulesLoading = listAllSchedulesB2CLoading !== 'fulfilled';

  const redirectToServicePage = (category) => {
    const parseCategoryToUrl = category.trim().toLowerCase().replaceAll(' ', '-');

    history.push(`/services/partners/${parseCategoryToUrl}`);
  };

  useEffect(() => {
    const getAllConnectedServices = () => {
      dispatch.Appointments.listConnectedServices({});
    };

    const getAllSchedule = () => {
      dispatch.Appointments.listAllSchedulesB2C({
        startDate: moment().format(),
        endDate: moment().endOf('year').format(),
      });
    };

    const getTelemedicineQuestions = () => {
      dispatch.User.vetQuestionCount();
    };

    getAllConnectedServices();
    getAllSchedule();
    getTelemedicineQuestions();
  }, []);

  const checkCategoryAvailability = (category) => {
    const eventsWithCategory = listConnectedServices.filter((event) => event.category === category);
    const isAvailable = eventsWithCategory.length;

    return isAvailable;
  };

  const handleClickCard = (category) => {
    if (checkCategoryAvailability(category)) {
      redirectToServicePage(category);
    } else {
      setModalOpen(true);
    }
  };

  const handleClickCredentialed = () => {
    Mixpanel.track(MIXPANEL_TAGS.DASHBOARD_CREDENTIALED_CLICK);

    if (isNative) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'CREDENTIALED_NETWORK_SCREEN', params: null }));
    }
  };

  const openTriageChat = () => {
    // window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
    // IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });

    // if (isNative) {
    //   return false;
    // }
    // return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);

    window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'TRIAGE_SCREEN', params: { toStep: 'select-pet' } }));
    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
    // return history.push('/pricing');
  };

  const onClickServiceAgendaMessage = () => {
    Mixpanel.track(MIXPANEL_TAGS.SERVICE_AGENDA_MESSAGE_CLICK);
    openTriageChat();
  };

  const onClickServiceAgendaVideo = () => {
    Mixpanel.track(MIXPANEL_TAGS.SERVICE_AGENDA_VIDEO_CLICK);
    openTriageChat();
  };

  return (
    <>
      <ServiceCategoryDialog open={open} setModalOpen={setModalOpen} />
      <TriageAlertDialog open={triageOpenModal} setModalOpen={setTriageModalOpen} />
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        {activeEvents.length && (
        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
        >
          <Typography variant='h6'>Eventos agendados</Typography>
          <Typography variant='body2'>{activeEvents.length ? `Você tem ${activeEvents.length} agendamentos ativos` : 'Você não tem agendamentos ativos'}</Typography>
          {isSchedulesLoading ? (
            <Grid container justifyContent='center' alignItems='center' sx={{ minHeight: '30vh' }}>
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : (
            <>
              {activeEvents.length ? <ServiceCarousel events={activeEvents} /> : <ServiceEmptyCard />}
            </>
          )}
        </Grid>
        )}

        <Grid
          container
          direction='column'
          sx={{ padding: '0 24px', maxWidth: '1280px' }}
          gap={2}
        >
          {isLoading ? (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ minHeight: '50vh' }}
            >
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                direction='column'
              >
                <Typography variant='h6'>Agende novos serviços</Typography>
              </Grid>

              <Typography sx={{ fontSize: '10px' }}>Orientações via videoconferência apenas para membros</Typography>

              {vetQuestions >= 1 && signingPlan?.type === 'one_time' && (
                <ServiceQuestions>
                  <RedeemIcon sx={{ width: '14px', height: '14px', marginRight: '6px' }} />
                  <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                    VOCÊ POSSUI
                    {' '}
                    {vetQuestions && String(vetQuestions).padStart(2, '0')}
                    {' '}
                    PERGUNTA
                    {vetQuestions > 1 && 'S'}
                    {' '}
                    DE VÍDEO OU TEXTO
                  </Typography>
                </ServiceQuestions>
              )}

              {permissions?.askTriageView ? (
                <>
                  <Fade in>
                    <Grid item sx={{ marginBottom: '10px' }} onClick={onClickServiceAgendaMessage}>
                      <ServiceTypeCard telemedicine='text' category={telemedicineCategory} />
                    </Grid>
                  </Fade>

                  <Fade in>
                    <Grid item sx={{ marginBottom: '10px' }} onClick={onClickServiceAgendaVideo}>
                      <ServiceTypeCard telemedicine='video' category={telemedicineCategory} />
                    </Grid>
                  </Fade>
                </>
              ) : (<></>)}

              {permissions?.credentialedView ? (
                <Fade in>
                  <Grid item sx={{ marginBottom: '35px' }} onClick={handleClickCredentialed}>
                    <ServiceTypeCard telemedicine='promotion' category='Promotion' />
                  </Grid>
                </Fade>
              ) : (<></>)}

              {categories.map((category) => (
                <Fade in>
                  <Grid
                    item
                    onClick={() => handleClickCard(category)}
                  >
                    <ServiceTypeCard category={category} />
                  </Grid>
                </Fade>
              ))}
            </>
          )}

        </Grid>
      </Grid>
    </>
  );
};

export default ServicesDashboard;
