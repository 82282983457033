/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, Avatar, Grid, Typography, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import moment from 'moment';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { CloseModal, ModalBox } from './style';
import { getPetPlaceholder } from '../../../../../services/api';

const ScheduleModalContent = () => {
  const dispatch = useDispatch();

  const { scheduleAnimalList } = useSelector((state) => state.Appointments);
  const { serviceTypes } = useSelector((state) => state.Appointments.actualService);
  const { period } = useSelector((state) => state.Appointments.modal.scheduleModal.data);
  const [isLoading, setLoading] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('Agendar');

  const titleDate = `${moment(period).format('dddd')} - ${moment(period).format('DD [de] MMMM [de] YYYY')} às ${moment(period).utc().format('HH:mm')}`;

  const closeModal = () => dispatch.Appointments.setModal({ scheduleModal: { open: false, data: {} } });

  const [scheduleAnimalListAll, setScheduleAnimalListAll] = useState([]);

  const fetchAnimalImage = async () => {
    const animalList = [...scheduleAnimalList];
    await Promise.all(animalList.map(async (animal) => {
      const placeholder = getPetPlaceholder(animal);
      // eslint-disable-next-line no-param-reassign
      animal.animalImgSrc = (await dispatch.Photo.getPhoto(animal.avatarImage)) || placeholder;
    }));
    setScheduleAnimalListAll(animalList);
  };

  useEffect(() => {
    !!scheduleAnimalList?.length && fetchAnimalImage();
  }, [scheduleAnimalList]);

  const handlePetSearch = async (event, setFieldValue) => {
    const { target: { value } } = event;

    await dispatch.Appointments.getAnimalsListToSchedule({ name: value });

    const findAnimalByName = scheduleAnimalListAll.find((animal) => animal.name === value);

    if (findAnimalByName) {
      setFieldValue('animalId', findAnimalByName.id);
    }
  };

  const handlePetSelect = (event, option, setFieldValue) => {
    if (option) {
      setFieldValue('animalId', option.id);
    }
  };

  const fetchScheduleAnimals = (animalName) => {
    dispatch.Appointments.getAnimalsListToSchedule({ name: animalName });
  };

  const handleSchedule = async (formData) => {
    setLoading(true);

    setButtonMessage('Confirmando disponibilidade...');

    const { serviceTypeId, animalId: formDataAnimalId } = formData;

    const isPeriodAvailable = await dispatch.Appointments.verifyScheduleAvailability({
      startDate: moment(period).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-00:00]'),
      animal: formDataAnimalId,
      serviceTypeId,
    });

    if (isPeriodAvailable && isPeriodAvailable.data && isPeriodAvailable.data.serviceCanBeScheduled && !isPeriodAvailable.data.reason) {
      const payload = {
        serviceTypeId,
        animalId: formDataAnimalId,
        eventStart: moment(period).utcOffset(0).format('YYYY-MM-DD[T]HH:mm:ss[-00:00]'),
      };

      const createdSchedule = await dispatch.Appointments.createSchedule(payload);

      if (createdSchedule && createdSchedule.data) {
        setLoading(false);
        setButtonMessage('Agendado');
        closeModal();
      } else {
        dispatch.Appointments.setModal({ scheduleModal: { open: true, data: { error: 'Houve falha no agendamento. Tente novamente.' }, context: 'error' } });
        setTimeout(() => {
          setLoading(false);
          setButtonMessage('Agendar');
        }, 3000);
      }
    } else {
      dispatch.Appointments.setModal({ scheduleModal: { open: true, data: { error: isPeriodAvailable.data.reason }, context: 'error' } });
    }
  };

  useEffect(() => fetchScheduleAnimals(), []);

  return (
    <ModalBox>
      <CloseModal onClick={closeModal}><CloseIcon /></CloseModal>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: '0px 80px' }}
      >
        <Typography variant='h6' sx={{ marginTop: '20px' }}>Novo compromisso</Typography>

        <Typography variant='h6' sx={{ color: '#CCC3FE' }}>{titleDate}</Typography>

        <Formik
          validateOnChange
          validateOnBlur={false}
          initialValues={{}}
          onSubmit={handleSchedule}
          validationSchema={Yup.object().shape({
            animalId: Yup.string().required('Favor selecionar um pet'),
            serviceTypeId: Yup.string().required('Favor selecionar um tipo de serviço'),
          })}
        >
          {({ handleSubmit, setFieldValue, values, handleChange, errors }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Autocomplete
                blurOnSelect='touch'
                disablePortal
                name='animalId'
                openOnFocus
                freeSolo
                getOptionLabel={(option) => option.name}
                onChange={(event, option) => handlePetSelect(event, option, setFieldValue)}
                options={scheduleAnimalListAll}
                sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 }, marginTop: '34px' }}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.id}>
                    <Avatar
                      src={option.animalImgSrc}
                      alt={option.name}
                      sx={{ marginRight: '5px' }}
                    />
                    <div>{option.name}</div>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    id='animalId'
                    name='animalId'
                    error={!!errors.animalId}
                    helperText={errors.animalId ? errors.animalId : ''}
                    onChange={(event) => handlePetSearch(event, setFieldValue)}
                    label='Digite o nome do pet'
                    variant='outlined'
                    {...params}
                  />
                )}
              />

              <Grid container justifyContent='flex-start'>
                <FormHelperText>
                  Ainda não criou o perfil do pet?
                  {' '}
                  <a href='/pet/create' style={{ textDecoration: 'none', color: '#6B48FE' }}>Clique aqui</a>
                  {' '}
                  e cadastre um novo pet.
                  {' '}
                </FormHelperText>
              </Grid>

              <Grid container direction='column' justifyContent='center' alignItems='center'>
                <FormControl error={errors && errors.serviceTypeId} sx={{ width: '100%', '& .MuiFormHelperText-root': { marginLeft: 0 }, marginTop: '24px' }}>
                  <InputLabel id='demo-simple-select-helper-label'>Evento</InputLabel>
                  <Select
                    id='serviceTypeId'
                    name='serviceTypeId'
                    label='Evento'
                    value={values.serviceTypeId}
                    onChange={handleChange}
                  >
                    {serviceTypes && serviceTypes.length ? serviceTypes.map((service) => (
                      <MenuItem value={service.id}>
                        {service.name}
                        {' '}
                        -
                        {' '}
                        {service.serviceDurationMinutes}
                        {' '}
                        min.
                      </MenuItem>
                    )) : <></>}
                  </Select>
                  <FormHelperText>{errors.serviceTypeId ? errors.serviceTypeId : ''}</FormHelperText>
                </FormControl>

                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isLoading}
                  loadingPosition='start'
                  sx={{ width: '50%', margin: '24px 0', background: '#6B48FE', color: 'white', borderRadius: '20px', '&:hover': { background: '#6B48FE' } }}
                >
                  {buttonMessage}
                </LoadingButton>

              </Grid>
            </form>
          )}
        </Formik>

      </Grid>

    </ModalBox>
  );
};

export default ScheduleModalContent;
