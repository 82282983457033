import styled from 'styled-components';

import theme from '../../../../utils/theme';

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${({ warningSeverity }) => {
    switch (warningSeverity) {
      case 'regular':
        return theme.MEDIUM_WINTER;

      case 'warning':
        return theme.INFO_PRIMARY;

      case 'critical':
        return theme.WARNING_PRIMARY;

      case 'info':
        return theme.SKY_BLUE;

      default:
        return theme.MEDIUM_WINTER;
    }
  }};

  svg {
    fill: ${theme.WHITE};
  }

  margin: 0 15px;
`;
