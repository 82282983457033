/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getToken } from '../../services/api';

const baseURL = process.env.REACT_APP_API;

const headers = () => (
  getToken() && {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: 'arraybuffer',
  });

export const Photo = {
  state: {
  },
  reducers: {
  },
  effects: () => ({
    async getPhoto(uuid) {
      if (!uuid) return null;
      try {
        const response = await axios.get(`${baseURL}/photo/${uuid}`, headers());

        if (response && response.data) {
          return `data:${response.headers['content-type']};base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
        }

        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
  }),
};
