import styled from 'styled-components';
import { Grid, Button } from '@mui/material';
import { animations } from '../../utils/theme';

export const TeleOrientationButton = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    font-weight: 500;
    padding: 7px 15px;

    svg {
      padding: 5px 5px 5px 0px;
      width: 18px;
      height: 18px;
      min-height: 18px;
      min-width: 18px;
    }

    &:hover {
      background: white;
    }
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 120px;
`;

export const PetsWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 736px) {
    .MuiGrid-spacing-xs-6 {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const StyledGrid = styled(Grid)`
  animation: ${animations.fadeIn} 0.2s ease-in;
`;

export const ButtonGrid = styled(Grid)`
  &.MuiGrid-root {
    width: 100%;
  }
`;

export const AlertGrid = styled(Grid)`
  &.MuiGrid-root {
    width: 100%;
  }
`;

export const RefreshGrid = styled(Grid)`
   &.MuiGrid-root {
    width: 100%;
  }
`;

export const RefreshArea = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;

  @media screen and (max-width: 960px) {
    justify-content: space-between;
  }
`;
