/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { StyledBox, MenuLink } from './style';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const DashboardMenu = () => {
  const { isB2B } = useSelector((state) => state.User.userData);
  const { myServiceListCount } = useSelector((state) => state.Appointments);

  const isSameRoute = (path) => {
    if (window.location.pathname.includes(path) || window.location.pathname === '/pet') {
      return { isSame: true, path: window.location.pathname };
    }

    return { isSame: false, path: window.location.pathname };
  };

  const onClickMyPets = () => Mixpanel.track(MIXPANEL_TAGS.MENU_PET);

  const onClickNetwork = () => Mixpanel.track(MIXPANEL_TAGS.MENU_NETWORK);

  // const onClickVouchers = () => Mixpanel.track(MIXPANEL_TAGS.MENU_DISCOUNT);

  const onClickServices = () => Mixpanel.track(MIXPANEL_TAGS.MENU_SERVICES);

  // const onClickOrders = () => Mixpanel.track(MIXPANEL_TAGS.MENU_ORDERS);

  const onClickProfile = () => Mixpanel.track(MIXPANEL_TAGS.PROFILE_MENU);

  const onClickBusinessIntelligence = () => Mixpanel.track(MIXPANEL_TAGS.BUSINESS_INTELLIGENCE_MENU);

  const onClickStatements = () => Mixpanel.track(MIXPANEL_TAGS.STATEMENT_MENU);

  return (
    <StyledBox>
      <Container>
        <MenuLink route={isSameRoute('/pets')} to='/pets' onClick={onClickMyPets}>
          {isB2B ? 'Meus clientes' : 'Meus pets'}
        </MenuLink>

        <MenuLink route={isSameRoute('/network')} to='/network' onClick={onClickNetwork}>
          Minha rede
        </MenuLink>

        {!isB2B && (
          <>
            {/* <MenuLink route={isSameRoute('/vouchers')} to='/vouchers' onClick={onClickVouchers}>
              Descontos
            </MenuLink> */}

            <MenuLink route={isSameRoute('/services')} to='/services' onClick={onClickServices}>
              Serviços
            </MenuLink>

            {/* <MenuLink route={isSameRoute('/orders')} to='/orders' onClick={onClickOrders}>
              Pedidos
            </MenuLink> */}
          </>
        )}

        {isB2B && (
          <>
            <MenuLink route={isSameRoute('/business')} to='/business' onClick={onClickBusinessIntelligence}>
              Inteligência de Negócio
            </MenuLink>

            {/* <MenuLink route={isSameRoute('/appointments')} to={myServiceListCount ? '/appointments' : '/appointments/create'}>
              Agenda
            </MenuLink>

            <MenuLink route={isSameRoute('/statements')} to='/statements' onClick={onClickStatements}>
              Extrato e Resgate
            </MenuLink> */}

            <MenuLink route={isSameRoute('/profile')} to='/profile' onClick={onClickProfile}>
              Meu perfil
            </MenuLink>
          </>
        )}
      </Container>
    </StyledBox>
  );
};

export default DashboardMenu;
