import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { isMobile } from '../../../utils/mobile';

const UnattendedCallDialog = ({ open, setModalOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const nuvidioDepartment = useSelector((state) => state.User.videoconferenceData.department);
  const [videoconferenceUrl, setVideoconferenceUrl] = useState('');
  const [attendantsOnline, setAttendantsOnline] = useState(false);
  let checkAttendantsInterval;

  const handleCallAgain = () => {
    clearInterval(checkAttendantsInterval);
    history.push(nuvidioDepartment
      ? `/services/telemedicine-call?url=${videoconferenceUrl}`
      : '/services/telemedicine-call');
  };

  const handleSchedule = () => {
    clearInterval(checkAttendantsInterval);
    history.push('/services');
  };

  const checkAttendantsOnline = async () => {
    if (open) {
      const attendantsResponse = await dispatch.User.attendantsOnline();

      if (attendantsResponse && attendantsResponse.data) {
        if (attendantsResponse.data.status) {
          setAttendantsOnline(true);
        } else {
          setAttendantsOnline(false);
        }
      } else {
        setAttendantsOnline(false);
      }
    }
  };

  useEffect(() => {
    const fetchTelemedicineDepartment = async () => {
      if (nuvidioDepartment) {
        const telemedicineResponse = await dispatch.User.getUrlTelemedicineCallDepartment(nuvidioDepartment);

        if (telemedicineResponse && telemedicineResponse.data) {
          setVideoconferenceUrl(telemedicineResponse.data.videoconferenceLink);
        }
      }
    };

    fetchTelemedicineDepartment();
  }, []);

  useEffect(() => {
    checkAttendantsOnline();

    checkAttendantsInterval = setInterval(() => {
      checkAttendantsOnline();
    }, 10000);

    return () => {
      clearInterval(checkAttendantsInterval);
    };
  }, []);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': {
        minWidth: '350px',
      },
      '& .MuiDialog-container': {
        height: 'auto',
        paddingBottom: '10px',
        marginTop: isMobile ? '55px' : '150px',
      } }}
      fullScreen={isMobile}
      open={open}
      onBackdropClick={() => setModalOpen(false)}
    >
      <DialogTitle>
        <Grid container direction='row' justifyContent='flex-end'>
          <Grid item>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item>
            Não foi possível atender no momento
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography>Você pode tentar novamente em alguns instantes ou agendar um horário</Typography>
      </DialogContent>
      <DialogActions>
        {attendantsOnline && (
          <Button
            sx={{ color: 'black' }}
            onClick={handleCallAgain}
          >
            Tentar novamente
          </Button>
        )}

        <Button
          sx={{ color: '#6B48FF' }}
          onClick={handleSchedule}
        >
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnattendedCallDialog;
