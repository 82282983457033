import React from 'react';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UnavailableDaysModalContent from './UnavailableDaysModalContent';

const AppointmentCalendarModal = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.Appointments.modal.calendarModal) || {};
  const closeModal = () => dispatch.Appointments.setModal({ calendarModal: { open: false, context: '' } });

  return (
    <Modal
      open={open}
      onClose={closeModal}
    >
      <UnavailableDaysModalContent />
    </Modal>
  );
};

export default AppointmentCalendarModal;
