/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import placeholderDog from '../../../assets/images/placeholder-dog.svg';
import placeholderCat from '../../../assets/images/placeholder-cat.svg';

import { StyledPetAvatarCard, StyledAvatar } from './style';

const PetEditAvatar = () => {
  const dispatch = useDispatch();
  const { name, breed, avatarImage } = useSelector((state) => state.Animals.actualPet);
  const [initialName] = useState(name);
  const [initialBreed] = useState(breed);

  const placeholder = initialBreed && initialBreed.species && initialBreed.species.toLowerCase() === 'dog' ? placeholderDog : placeholderCat;

  const [animalImgSrc, setAnimalImgSrc] = useState(null);

  const fetchAnimalImage = async () => {
    const response = await dispatch.Photo.getPhoto(avatarImage);
    setAnimalImgSrc(response);
  };

  useEffect(() => {
    fetchAnimalImage();
  }, []);

  return (
    <StyledPetAvatarCard elevation={0}>
      <StyledAvatar src={animalImgSrc || placeholder} />
      <Typography mt={1} variant='h6'>{initialName}</Typography>
    </StyledPetAvatarCard>
  );
};

export default PetEditAvatar;
