/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable

import React, { useEffect } from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import theme from '../../utils/theme';
import { isDesktop, isNative, STORE_ANDROID_LP, STORE_IOS, agentIsDesktop, STORE_ANDROID_UPGRADE_APP, STORE_IOS_UPGRADE_APP, isTablet, isDesktopOrTablet } from '../../utils/mobile';

import ellipses from '../../assets/images/Landing/ellipses.svg';
import downloadIOS from '../../assets/images/Landing/download-ios.svg';
import logoHeaderDark from '../../assets/images/Header/logoHeaderDark.svg';
import downloadAndroid from '../../assets/images/Landing/download-android.svg';
import illustrationPhone from '../../assets/images/Landing/illustration-phone.png';
import illustrationMobile from '../../assets/images/Landing/illustration-mobile.png';
import { Mixpanel, MIXPANEL_TAGS } from '../../utils/mixpanel';

const LandingView = () => {
  const isBusinessUrl = window.location.href.includes(process.env.REACT_APP_BUSINESS) || window.location.href.includes(process.env.REACT_APP_SIGN);

  useEffect(() => {
    if (isBusinessUrl || isNative) {
      return;
    }
    Mixpanel.track(MIXPANEL_TAGS.LANDING_VIEW);
  }, [isBusinessUrl, isNative]);

  if (isBusinessUrl || isNative) {
    return null;
  }

  const handleOpenStore = (store) => {
    switch (store) {
      case 'Android':
        Mixpanel.track(MIXPANEL_TAGS.LANDING_PLAY_STORE_CLICK);
        window.location.href = agentIsDesktop ? STORE_ANDROID_LP : STORE_ANDROID_UPGRADE_APP;
        break;
      case 'iOS':
        Mixpanel.track(MIXPANEL_TAGS.LANDING_APP_STORE_CLICK);
        window.location.href = agentIsDesktop ? STORE_IOS : STORE_IOS_UPGRADE_APP;
        break;
      default:
    }
  };

  return (
    // eslint-disable-next-line react/jsx-one-expression-per-line
    <Stack
      bgcolor={theme.WHITE}
      alignItems='center'
      justifyContent='center'
      alignSelf='center'
      pt={5}
      top={0}
      minWidth='100vw'
    >

      <Grid container alignItems='center' justifyContent='center' alignSelf='center'>

        <Grid container display='flex' maxWidth={1366} px={isDesktopOrTablet ? 8 : 2} alignSelf='center' pt={isDesktopOrTablet ? 0 : 3}>
          <Grid item mb={isDesktopOrTablet ? 1 : 1.5} maxWidth={560} alignSelf='flex-start'>
            <a href='https://www.guia.vet/conteudos-especiais'>
              <img src={logoHeaderDark} alt='Logo escrito Guiavet' title='Ir para o blog' width={isDesktopOrTablet ? 170 : 94} />
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          display='flex'
          flexDirection={isDesktopOrTablet ? 'row' : 'column-reverse'}
          maxWidth={1366}
          px={isDesktopOrTablet ? 8 : 2}
          py={5}
          alignSelf='center'
          gap={isDesktopOrTablet ? 0 : 2.5}
        >

          <Grid item md={6} xs={12}>
            <Typography color={theme.GUIAVET} variant='h1' fontSize={isDesktopOrTablet ? 48 : 32}>
              Baixe nosso aplicativo
              {isDesktopOrTablet ? (<br />) : ' '}
              no seu celular
            </Typography>
            <Grid item py={isDesktopOrTablet  ? 7 : 3}>
              <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet ? 20 : 14} mb={2}>
                O <span style={{color: theme.GUIAVET}}>Guiavet Pet App</span> está disponível para os
                {isDesktopOrTablet ? (<br />) : ' '}
                dispositivos Android e IOS.
              </Typography>

              <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet ? 20 : 14}>
                Faça o download do nosso app no seu celular e
                {isDesktopOrTablet ? (<br />) : ' '}
                continue aproveitando todos os benefícios de
                {isDesktopOrTablet ? (<br />) : ' '}
                manter os cuidados com a saúde do seu pet onde
                {isDesktopOrTablet ? (<br />) : ' '}
                você estiver.
              </Typography>

              <ul style={{ listStyleType: 'disc', paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
              <li>
                <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet ? 20 : 14}>
                Mantenha os <span style={{color: theme.GUIAVET}}>protocolos de saúde</span> dos seus animais
                {isDesktopOrTablet ? (<br />) : ' '}
                sempre em dia;
                </Typography>
              </li>
              <li>
                <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet  ? 20 : 14}>
                Solicite a visita de um <span style={{color: theme.GUIAVET}}>médico-veterinário</span> na
                {isDesktopOrTablet ? (<br />) : ' '}
                sua casa;
                </Typography></li>
              <li>
                <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet  ? 20 : 14}>
                Cadastre a <span style={{color: theme.GUIAVET}}>Carteirinha de Vacinas Digital</span>
                {isDesktopOrTablet ? (<br />) : ' '}
                gratuitamente;
                </Typography>
              </li>
              <li>
                <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet  ? 20 : 14}>
                Guarde os <span style={{color: theme.GUIAVET}}>documentos do seu pet</span> sempre
                {isDesktopOrTablet ? (<br />) : ' '}
                em um único lugar!
                </Typography>
              </li>
            </ul>
            </Grid>

            <Typography color={theme.MEDIUM_NAVY} fontSize={isDesktopOrTablet ? 20 : 16} fontWeight={600} mb={2}>
              Baixe agora e aproveite
            </Typography>
            <Grid item md={6} xs={12} display='flex' gap={3}>
              <a>
                <img
                  src={downloadAndroid}
                  alt='Baixe na Google Play'
                  title='Baixe na Google Play'
                  width={isDesktopOrTablet ? 'auto' : '100%'}
                  onClick={() => handleOpenStore('Android')}
                />
              </a>
              <a>
                <img
                  src={downloadIOS}
                  alt='Baixe na App Store'
                  title='Baixe na App Store'
                  width={isDesktopOrTablet ? 'auto' : '100%'}
                  onClick={() => handleOpenStore('iOS')}
                />
              </a>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12} alignSelf='center'>
            <img
              src={isDesktopOrTablet ? illustrationPhone : illustrationMobile}
              alt='Smartphone exibindo as funcionalidades da Guiavet com 2 pets ao lado'
              width={isDesktop ? 780 : isTablet ? 580 : '100%'}
              style={{ position: isDesktopOrTablet ? 'absolute' : 'relative', zIndex: 99, top: 0 }}
            />

            {isDesktopOrTablet && (
              <img src={ellipses} alt='Ilustração de 3 círculos coloridos' width={500} style={{ position: 'relative', visibility: "hidden" }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default LandingView;
