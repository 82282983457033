/* eslint-disable consistent-return */
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import { Container } from '@material-ui/core';
import { TableContainer } from '@mui/material';
import { animations } from '../../../utils/theme';

export const StyledContainer = styled(Container)`
  margin-top: 10px;
  animation: ${animations.fadeIn} .2s ease-in;
`;

export const StyledTableContainer = styled(TableContainer)`
  animation: ${animations.fadeIn} .2s ease-in-out;
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    transition: background-color .1s ease-in, box-shadow .1s ease-in;
    &:hover {
      background-color: #f2f0fa;
      -webkit-box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 5px 4px 0px rgba(0,0,0,0.1);
    }
  }
`;

export const AppointmentControl = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EmptyNetwork = styled.img`
  width: 400px;
  height: 400px;
  animation: ${animations.fadeIn} .2s ease-in-out;

  @media screen and (max-width: 736px) {
      width: 250px;
      height: 250px;
    }
`;

export const TableMenu = styled.div`
  display: flex;
  width: 100px;
  margin-right: auto;
  flex-direction: column;
`;
