/* eslint-disable consistent-return */
import styled from 'styled-components';

export const Details = styled.div`
  width: 250px;
	display: ${({ hide }) => (hide ? 'none' : 'flex')};
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
	color: ${(props) => {
    switch (props.severity) {
      case 'regular':
        return '#3B87D3';

      case 'warning':
        return '#FFB400';

      case 'critical':
        return '#E36360';

      case 'success':
        return '#25BB2B';

      default:
        break;
    }
  }
};

    @media screen and (max-width: 736px) {
		&-btn {
			display: none;
		}
		width: 250px;
		margin-top: 15px;
    margin-left: auto;
	}
`;

export const Time = styled.span`
  margin: 0 15px 0;
  color: rgba(126, 129, 146, 1);
`;

export const ProgressLine = styled.div`
width: 150px;
height: 8px;
background-color: #C6CDD3;
border-radius: 10px;
`;

export const Status = styled.div`
width: ${(props) => `${props.progress}%`};
height: 8px;
border-radius: 10px;
background-color: ${(props) => {
    switch (props.severity) {
      case 'regular':
        return '#3B87D3';

      case 'warning':
        return '#FFB400';

      case 'critical':
        return '#E36360';

      case 'success':
        return '#25BB2B';

      default:
        break;
    }
  }
};
`;
