import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DashboardHeader from '../../../../components/Dashboard/DashboardHeader';
import DashboardMenu from '../../../../components/Dashboard/DashboardMenu';
import Footer from '../../../../components/Footer';
import { MainWrapper } from './style';
import PartnersDashboard from '../../../../components/Services/PartnersDashboard';
import Loading from '../../../../components/global/Loading';
import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';

const PartnersPage = () => {
  const [loading] = useState(false);

  useEffect(() => Mixpanel.track(MIXPANEL_TAGS.EVENT_PARTNERS_VIEW), []);

  return (
    <MainWrapper>
      {loading ? (
        <>
          <DashboardHeader />
          <DashboardMenu />
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '70vh' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
          <Footer />
        </>
      ) : (
        <PartnersDashboard />
      )}
    </MainWrapper>
  );
};

export default PartnersPage;
