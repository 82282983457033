import styled from 'styled-components';
import { Card, Typography, Button } from '@material-ui/core';
import theme from '../../../utils/theme';

export const ProfileCard = styled(Card)`
  margin-bottom: 100px;
`;

export const ProfileManageContent = styled.div`
  padding: 0 8%;

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 24px 0;
    display: block;
  }

  .row-submit {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;

    @media screen and (max-width: 739px) {
      margin-top: 10px;
      height: 80px;
    }
  }

  @media screen and (max-width: 739px) {
    padding: 0 2%;
  }
`;

export const ProfileRowColumn = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
	column-gap: 40px;
	height: 80px;

	@media screen and (max-width: 960px) {
    justify-content: space-around;
		flex-direction: column;
		width: 100%;
		height: ${({ isB2B }) => (isB2B ? '80px' : '160px')}
	}
`;

export const SaveButton = styled(Button)`
  background-color: ${theme.primaryButton} !important;
  color: ${theme.fontColorLight} !important;
  border-radius: ${theme.buttonRadius} !important;
  padding: 5px 40px !important;
`;

export const DisableAccount = styled.div`
  display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	min-height: 70px;
	width: 100%;
	margin-top: 20px;
`;

export const DisableButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.warningColor} !important;
  background-color: transparent !important;
  border: 1px solid ${theme.warningColor} !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;

export const DisableButtonModal = styled(DisableButton)`
  &.MuiButton-root {
  }

  width: 45%;

  @media screen and (max-width: 739px) {
    width: 50%;
  }
`;

export const ButtonControl = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 10px 20px !important;
`;

export const ConfirmButton = styled(Button)`
  color: ${theme.primaryButton} !important;
  padding: 10px 20px !important;
`;

export const RedButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.backgroundPaper} !important;
  background-color: ${theme.warningColor} !important;
  border: 1px solid ${theme.warningColor} !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;

export const PurpleButton = styled(Button)`
  border-radius: ${theme.buttonRadius} !important;
  color: ${theme.primaryButton} !important;
  background-color: transparent !important;
  border: 1px solid ${theme.primaryButton} !important;
  padding: 4px 20px !important;
  width: 180px;

  @media screen and (max-width: 739px) {
    width: 120px;
    padding: 5px 60px !important;
  }
`;

export const Text = styled(Typography)`
  color: ${theme.primaryButton};
  cursor: pointer;
  font-size: 14px !important;
  text-decoration: underline;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
	column-gap: 40px;
	min-height: 80px;
  margin-top: 8px;

	@media screen and (max-width: 960px) {
    align-items: flex-start;
    justify-content: space-around;
		flex-direction: column;
		width: 100%;
	}
`;
