import { Typography, CardContent } from '@mui/material';
import styled from 'styled-components';

export const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 322px;
    height: 65px;
    flex-shrink: 2;
    border: 4px dashed rgba(107, 72, 255, 0.37);
    opacity: 1.0;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
`;

export const ChoosePartnerText = styled(Typography)`
  &.MuiTypography-root {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    color: #6B48FF;
    opacity: 0.4;
    text-align: center;
  }
`;
