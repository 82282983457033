/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import CakeIcon from '@mui/icons-material/Cake';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import theme from '../../../../utils/theme';
import Loading from '../../../global/Loading';
import NotificationAvatar from '../NotificationAvatar';
import { ReactComponent as ChatIcon } from '../../../../assets/images/Notification/chat.svg';
import { ReactComponent as VaccineIcon } from '../../../../assets/images/Notification/vaccine.svg';
import { ReactComponent as MedicineIcon } from '../../../../assets/images/Notification/medicine.svg';
import { ReactComponent as AttendanceIcon } from '../../../../assets/images/Notification/attendance.svg';

import { StyledCardContent, Description, AcceptButton, RejectButton, ButtonControl, StyledCardContentNotification } from './style';

const DefaultNotification = ({ title, description, createDatetime, onClick }) => (
  <StyledCardContent sx={{ display: 'flex', flexDirection: 'row', padding: '0', marginRight: '5px', height: 'auto' }} onClick={onClick}>
    <NotificationAvatar warningSeverity='regular' icon={<NotificationsIcon />} />
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          {title}
        </Typography>
        <Typography variant='caption' color={theme.caption}>
          {moment(createDatetime).fromNow()}
        </Typography>
      </div>

      <Description variant='caption' color={theme.caption}>
        {description}
      </Description>
    </div>
  </StyledCardContent>
);

const BirthdayNotification = ({ title, description, createDatetime, onClick }) => (
  <StyledCardContent sx={{ display: 'flex', flexDirection: 'row', padding: '0', marginRight: '5px', height: 'auto' }} onClick={onClick}>
    <NotificationAvatar warningSeverity='regular' icon={<CakeIcon />} />
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          {title}
        </Typography>
        <Typography variant='caption' color={theme.caption}>
          {moment(createDatetime).fromNow()}
        </Typography>
      </div>

      <Description variant='caption' color={theme.caption}>
        {description}
      </Description>
    </div>
  </StyledCardContent>
);

const ProtocolAnimalNotification = ({ title, description, protocolMoment, warningSeverity, createDatetime, onClick }) => (
  <StyledCardContent sx={{ display: 'flex', flexDirection: 'row', padding: '0', marginRight: '5px', height: 'auto' }} onClick={onClick}>
    <NotificationAvatar warningSeverity={warningSeverity} icon={protocolMoment === 'preventive' ? <VaccineIcon /> : <MedicineIcon />} />
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          {title}
        </Typography>
        <Typography variant='caption' color={theme.caption}>
          {moment(createDatetime).fromNow()}
        </Typography>
      </div>

      <Description variant='caption' color={theme.caption}>
        {description}
      </Description>
    </div>
  </StyledCardContent>
);

const ConnectionInviteNotification = ({ title, description, createDatetime, id, inviteId, openDatetime, inviteActive, inviteAcceptDatetime, onClick }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const acceptInvite = async () => {
    setLoading(true);
    const response = await dispatch.Network.acceptInvite({ inviteId, notificationId: id });

    if (response && response.data) {
      onClick();
      return setLoading(false);
    }

    return setLoading(false);
  };

  const refuseInvite = () => {
    setLoading(true);
    const response = dispatch.Network.refuseInvite({ inviteId, notificationId: id });

    if (response && response.data) {
      onClick();
      return setLoading(false);
    }

    return setLoading(false);
  };

  return (
    <StyledCardContentNotification sx={{ padding: '0', marginRight: '5px', height: 'auto' }}>
      <div role='button' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0' }} onClick={onClick}>
        <NotificationAvatar warningSeverity='regular' icon={<ConnectWithoutContactIcon />} />
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='body1'>
              {title}
            </Typography>
            <Typography variant='caption' color={theme.caption}>
              {moment(createDatetime).fromNow()}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Description variant='caption' color={theme.caption}>
              {description}
            </Description>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        { inviteAcceptDatetime || !inviteActive || openDatetime ? <></> : (
          !loading ? (
            <ButtonControl>
              <RejectButton type='button' onClick={refuseInvite}>
                Recusar
              </RejectButton>
              <AcceptButton type='button' onClick={acceptInvite} $main>
                Aceitar
              </AcceptButton>
            </ButtonControl>
          ) : (
            <div>
              <Loading size={25} />
            </div>
          )
        )}
      </div>
    </StyledCardContentNotification>
  );
};

const AnnouncementNotification = ({ title, description, createDatetime, onClick }) => (
  <StyledCardContent sx={{ display: 'flex', flexDirection: 'row', padding: '0', marginRight: '5px', height: 'auto' }} onClick={onClick}>
    <NotificationAvatar warningSeverity='info' icon={<ChatIcon />} />
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          {title}
        </Typography>
        <Typography variant='caption' color={theme.caption}>
          {moment(createDatetime).fromNow()}
        </Typography>
      </div>

      <Description variant='caption' color={theme.caption}>
        {description}
      </Description>
    </div>
  </StyledCardContent>
);

const CredentialedRatingNotification = ({ title, description, createDatetime, onClick }) => (
  <StyledCardContent sx={{ display: 'flex', flexDirection: 'row', padding: '0', marginRight: '5px', height: 'auto' }} onClick={onClick}>
    <NotificationAvatar warningSeverity='regular' icon={<AttendanceIcon style={{ stroke: 'white', fill: 'transparent' }} />} />
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='body1'>
          {title}
        </Typography>
        <Typography variant='caption' color={theme.caption}>
          {moment(createDatetime).fromNow()}
        </Typography>
      </div>

      <Description variant='caption' color={theme.caption}>
        {description}
      </Description>
    </div>
  </StyledCardContent>
);

const NotificationCard = ({
  id,
  inviteId,
  inviteActive,
  inviteAcceptDatetime,
  type,
  title,
  protocolMoment,
  description,
  warningSeverity,
  openDatetime,
  createDatetime,
  onClick,
}) => {
  const renderNotification = () => {
    switch (type) {
      case 'AN':
        return (
          <AnnouncementNotification
            title={title}
            description={description}
            protocolMoment={protocolMoment}
            warningSeverity={warningSeverity}
            createDatetime={createDatetime}
            onClick={onClick}
          />
        );
      case 'PA':
        return (
          <ProtocolAnimalNotification
            title={title}
            description={description}
            protocolMoment={protocolMoment}
            warningSeverity={warningSeverity}
            createDatetime={createDatetime}
            onClick={onClick}
          />
        );
      case 'CI':
        return (
          <ConnectionInviteNotification
            id={id}
            title={title}
            description={description}
            createDatetime={createDatetime}
            inviteId={inviteId}
            inviteActive={inviteActive}
            inviteAcceptDatetime={inviteAcceptDatetime}
            openDatetime={openDatetime}
            onClick={onClick}
          />
        );
      case 'AB':
        return (
          <BirthdayNotification
            title={title}
            description={description}
            createDatetime={createDatetime}
            onClick={onClick}
          />
        );
      case 'CR':
        return (
          <CredentialedRatingNotification
            title={title}
            description={description}
            createDatetime={createDatetime}
            onClick={onClick}
          />
        );
      default:
        return (
          <DefaultNotification
            title={title}
            description={description}
            createDatetime={createDatetime}
            onClick={onClick}
          />
        );
    }
  };

  return (
    <>
      {renderNotification()}
    </>
  );
};

export default NotificationCard;
