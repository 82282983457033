/* eslint-disable no-unused-vars */
import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TeleOrientationIcon } from '../../../assets/images/PetCard/communication-dark.svg';
import { ReactComponent as HealthIcon } from '../../../assets/images/PetCard/HealthControl.svg';
import PetHeader from './PetHeader';
import PetInformation from './PetInformation';
import { HealthControlButton, StyledCardContent, StyledPetCard, TeleOrientationButton } from './style';
// import { isNative } from '../../../utils/mobile';
import { Mixpanel, MIXPANEL_TAGS } from '../../../utils/mixpanel';

const PetCard = ({ id, name, sex, weight, breed, avatarImage, healthy, birthdate, user }) => {
  const history = useHistory();
  const { isB2B } = useSelector((state) => state.User.userData);
  const isDefaultPlan = useSelector((state) => state.User.userData.signingPlan?.defaultPlan);
  const permissions = useSelector((state) => state?.FeatureSettings?.permissions) || {};

  const redirectPetPage = () => {
    history.push(`/pet/${id}`);
  };

  const handlePetQuestion = () => {
    // window.ReactNativeWebView?.postMessage('INTERCOM_BOT_INITIALIZED_WEB');
    // IntercomCustom.signinIntercom({ bot: 'chat-triage-app' });
    // if (isNative) {
    //   return false;
    // }
    // return setTimeout(() => IntercomCustom.customButtonAndStart('chat-triage-app'), 200);
    window.ReactNativeWebView?.postMessage(JSON.stringify({ OPEN_NATIVE_SCREEN: 'TRIAGE_SCREEN', params: { toStep: 'select-pet' } }));

    Mixpanel.track(MIXPANEL_TAGS.MESSAGE_TOP_MENU);
  };

  return (
    <StyledPetCard
      elevation={0}
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <StyledCardContent elevation={0}>
        <PetHeader
          id={id}
          breed={breed}
          name={name}
          avatarImage={avatarImage}
          healthy={healthy}
          user={user}
        />
        <PetInformation
          sex={sex}
          weight={weight}
          breed={breed}
          birthdate={birthdate}
        />
        {!isB2B && permissions?.askTriageView === true ? (
          <TeleOrientationButton onClick={handlePetQuestion}>
            <TeleOrientationIcon />
            <Typography variant='button'>Perguntar ao vet</Typography>
          </TeleOrientationButton>
        ) : <></>}

        <HealthControlButton onClick={redirectPetPage} startIcon={<HealthIcon />}>Controle de saúde</HealthControlButton>
      </StyledCardContent>
    </StyledPetCard>
  );
};

export default PetCard;
