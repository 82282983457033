/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBox, ButtonControl, ButtonModal, DisconnectTextControl, CustomLink } from './style';

const UpdateTermsContent = () => {
  const dispatch = useDispatch();
  const { native } = useSelector((state) => state.User.userData);

  const logoutUser = () => dispatch.User.logout();

  const openTermsLink = (link) => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ openUrlOnModal: { content: link } }));
  };

  return (
    <>
      <ModalBox>
        <Typography id='desconectar-contato' variant='h6' component='h2'>
          Atualização na política de privacidade e termos de serviço
        </Typography>

        <DisconnectTextControl>
          <Typography id='enviar-convite' variant='body1'>
            Você concorda com a nossa
            <CustomLink onClick={() => openTermsLink('https://www.guia.vet/politica-de-privacidade')} target={native ? '_self' : '_blank'}> nova política de privacidade e termos de serviço</CustomLink>
            ?
          </Typography>
        </DisconnectTextControl>
        <ButtonControl>
          <ButtonModal style={{ marginRight: '42px' }} type='button' onClick={logoutUser}>Não concordo</ButtonModal>
          <ButtonModal style={{ marginRight: '25px' }} type='submit' mainButton onClick={undefined}>Concordo</ButtonModal>
        </ButtonControl>
      </ModalBox>
    </>
  );
};

export default UpdateTermsContent;
