/* eslint-disable arrow-parens */
import { init } from '@rematch/core';
import { User } from './models/user';
import { Animals } from './models/animals';
import { Network } from './models/network';
import { Notification } from './models/notification';
import { Business } from './models/business';
import { Appointments } from './models/appointments';
import { Vouchers } from './models/vouchers';
import { SigningPlans } from './models/signingPlans';
import { Photo } from './models/photo';
import { Modal } from './models/modal';
import { isNative } from '../utils/mobile';
import { FeatureSettings } from './models/featureSettings';
import { Referral } from './models/referral';
import { DiscountCoupon } from './models/discountCoupon';

const customMiddleware = () => (next) => (action) => {
  const type = action.type.split('/').slice(-1)[0];
  const typeReplace = type?.replaceAll('_', '');
  const token = localStorage.getItem('userJWT');

  if (!action.type.toLowerCase().includes('loading') && isNative && (typeReplace === typeReplace.toUpperCase())) {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ updateStorage: { key: 'userJWT', value: token, from: 'web' } }));
    window.ReactNativeWebView?.postMessage(JSON.stringify({ updateRematch: { action, from: 'web' } }));
  }
  return next(action);
};

const store = init({
  redux: {
    middlewares: [customMiddleware],
  },
  models: {
    User,
    Animals,
    Network,
    Notification,
    Business,
    Appointments,
    Vouchers,
    SigningPlans,
    Photo,
    Modal,
    FeatureSettings,
    Referral,
    DiscountCoupon,
  },
});

export default store;
