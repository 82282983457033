/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';

import { Mixpanel, MIXPANEL_TAGS } from '../../../../utils/mixpanel';
import whatsappIcon from '../../../../assets/images/Network/whatsapp.png';
import placeholderDog from '../../../../assets/images/placeholder-dog.svg';
import placeholderCat from '../../../../assets/images/placeholder-cat.svg';

import { StyledTableRow, SendMessageTableCell, PetTableContent, CustomerTableContent } from './style';

const DueProtocolTableRow = ({ customerName, customerPhone, customerPhoto, animalName, animalId, animalBreed, animalAge, animalSpecies, animalPhoto, protocol, product, appliedDatetime, nextDoseDatetime }) => {
  const dispatch = useDispatch();
  const placeholder = animalSpecies && animalSpecies.toLowerCase() === 'dog' ? placeholderDog : placeholderCat;
  const [customerImgSrc, setCustomerImgSrc] = useState(null);
  const [animalImgSrc, setAnimalImgSrc] = useState(null);

  const fetchAnimalImage = async () => {
    const response = await dispatch.Photo.getPhoto(animalPhoto);
    setAnimalImgSrc(response);
  };

  const fetchCustomerImage = async () => {
    const response = await dispatch.Photo.getPhoto(customerPhoto);
    setCustomerImgSrc(response);
  };

  useEffect(() => {
    !!customerPhoto && fetchCustomerImage();
    !!animalPhoto && fetchAnimalImage();
  }, []);

  const redirectPetPage = () => {
    if (animalId) {
      window.open(`/pet/${animalId}`);
    }
  };

  const openWhatsapp = () => {
    Mixpanel.track(MIXPANEL_TAGS.REPORT_CONTACT_CLIENT_CLICK);
    if (customerPhone) {
      window.open(`https://wa.me/+55${customerPhone}`);
    }
  };

  const parseDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : '-');
  const parseAge = (age) => {
    if (age) {
      return Number(age).toFixed(1);
    }

    return false;
  };

  const speciesMap = {
    DOG: 'Cachorro',
    CAT: 'Gato',
  };

  return (
    <StyledTableRow>
      <TableCell title={customerName || ''}>
        {customerName ? (
          <CustomerTableContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              alt={customerName}
              src={customerImgSrc}
              sx={{ marginRight: '10px' }}
            />
            {customerName || '-'}
          </CustomerTableContent>
        ) : <></>}
      </TableCell>
      <TableCell onClick={redirectPetPage} style={{ cursor: 'pointer' }} title={animalName || ''}>
        {animalName ? (
          <PetTableContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar
              alt={animalName}
              src={animalImgSrc || placeholder}
              sx={{ marginRight: '10px' }}
            />
            {animalName || '-'}
          </PetTableContent>
        ) : <></>}
      </TableCell>
      <TableCell>{(animalSpecies && speciesMap[animalSpecies]) || '-'}</TableCell>
      <TableCell title={animalBreed || ''}>{animalBreed || '-'}</TableCell>
      <TableCell>{(animalAge && parseAge(animalAge)) || '-'}</TableCell>
      <TableCell title={protocol || ''}>{protocol || '-'}</TableCell>
      <TableCell title={product || ''}>{product || '-'}</TableCell>
      <TableCell>{parseDate(appliedDatetime)}</TableCell>
      <TableCell>{parseDate(nextDoseDatetime)}</TableCell>
      <SendMessageTableCell onClick={openWhatsapp}>
        {customerPhone ? <img src={whatsappIcon} height='30px' width='30px' alt='whatsappIcon' /> : '-'}
      </SendMessageTableCell>
    </StyledTableRow>
  );
};

export default DueProtocolTableRow;
