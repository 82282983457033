import { Avatar, Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 161px;
    min-height: 280px;
    background-color: #f2f2f2;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
  }
`;

export const ProductImageContainer = styled(Box)`
  &.MuiBox-root {
    position: relative;
    width: 145px;
    height: 136px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    object-fit: cover;
    width: 145px;
    height: 136px;
    border-radius: 8px;
  }
`;

export const ProductTitle = styled(Typography)`
  &.MuiTypography-root {
    color: #6B48FF;
    font-weight: 500;
    font-size: 14px;
    padding: 0 14px;
  }
`;

export const ProductPrice = styled(Typography)`
  &.MuiTypography-root {
    color: #343A40;
    font-weight: 700;
    font-size: 13px;
  }
`;

export const OldPrice = styled(Typography)`
  &.MuiTypography-root {
    color: #343A40;
    font-weight: 400;
    font-size: 9px;
    text-decoration: line-through;
  }
`;

export const Address = styled(Typography)`
  &.MuiTypography-root {
    display: inline;
    color: #000000;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    width: 150px;
  }
`;

export const Company = styled(Address)`
  &.MuiTypography-root {
    font-weight: 700;
  }
`;

export const DiscountChip = styled(Box)`
  &.MuiBox-root {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 41px;
    height: 17px;
    background-color: #FF4874;
    border-radius: 10px;
    color: white;
    bottom: 10px;
    right: 10px;
    padding: 2px 2px;

    h5 {
      font-size: 10px;
      font-weight: 700;
    }

    sup {
      font-size: 8px;
    }
  }
`;

export const CompanyPhoto = styled(Avatar)`
  &.MuiAvatar-root {
    position: absolute;
    right: 15px;
    top: -10px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    z-index: 10;
  }
`;
