import styled from 'styled-components';
import { Grid } from '@mui/material';

export const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    position: relative;
    width: 98%;
    display: flex;
    align-items: center;
    padding: 0;
    background: linear-gradient(360deg, rgba(251, 239, 210, 0.54) 5.52%, #FFFFFF 97.41%);
    height: 276px;
  }
`;
