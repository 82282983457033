import styled from 'styled-components';

import theme from '../../../utils/theme';

export const ButtonModal = styled.button`
  border: 0;
  margin: 0;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  background-color: transparent;
  color: ${(props) => (props.mainButton ? theme.primaryButton : theme.fontColorFooter)};
`;
