/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../global/Loading';
import NotificationCard from './NotificationCard';
import { MIXPANEL_TAGS, Mixpanel } from '../../../utils/mixpanel';
import EmptyNotificationImage from '../../../assets/images/Notification/empty_notification.svg';

import { LoadingContainer, StyledContainer, StyledGridContainer, StyledGridItem, EmptyNotification } from './style';

const NotificationCardList = () => {
  const [page, setPage] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const { notificationData } = useSelector((state) => state.Notification);
  const { listAll } = useSelector((state) => state.Notification.loading);
  const dispatch = useDispatch();
  const observer = useRef();
  const isLoading = listAll !== 'fulfilled';
  const history = useHistory();
  const notificationSummary = useSelector((state) => state.Notification.summary);

  const markNotificationsAsRead = () => {
    const unreadNotificationIds = notificationData.filter((item) => !item.readDatetime).map((item) => item.id);
    if (unreadNotificationIds.length) {
      dispatch.Notification.markAsRead({ notifications: unreadNotificationIds, allNotifications: notificationData });
    }
  };

  const fetchNotifications = async (force = false) => {
    if (force) {
      const response = await dispatch.Notification.listAll({ page: 0 });

      const responseIsEmpty = response && !response.data.length;

      if (responseIsEmpty) {
        setHasMoreItems(false);
      }
    } else if (hasMoreItems) {
      const response = await dispatch.Notification.listAll({ page });

      const responseIsEmpty = response && !response.data.length;

      if (responseIsEmpty) {
        setHasMoreItems(false);
      }

      if (response && response.data) {
        setFirstLoading(false);
        setPage(page + 1);
      }
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNotifications();
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [page],
  );

  const onClickNotification = (notificationProps) => {
    dispatch.Notification.open({ id: notificationProps?.id });

    const { animalId = '', type } = notificationProps;

    Mixpanel.track(MIXPANEL_TAGS.NOTIFICATIONS_CARD_CLICK, notificationProps);

    const isBusinessUrl = window.location.href.includes(process.env.REACT_APP_BUSINESS);

    if (type === 'AN') {
      history.push('/announcements', { notification: notificationProps });
      return;
    }

    if (!animalId && isBusinessUrl && type === 'PA') {
      history.push('/business');
    } else {
      const notificationTypes = {
        AB: `/pet/${animalId}`,
        VC: `/pet/${animalId}`,
        CI: '/network',
        PA: `/pet/${animalId}`,
        PV: '/vouchers',
        SS: '/services',
        BT: '/statements',
        VO: '/orders',
        SP: '/profile',
        VQ: '/services',
      };

      if (notificationTypes[type]) {
        history.push(notificationTypes[type]);
      }

      if (type === 'CR' && !notificationProps?.inAppMessage?.resolved) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ openRatingModal: true, inAppMessage: notificationProps.inAppMessage }));
      }
    }
  };

  useEffect(() => {
    dispatch.Notification.clearNotificationList();
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/notifications') && notificationSummary.count > 0) {
      fetchNotifications(true);
    }
  }, [, notificationSummary]);

  useEffect(() => {
    if (notificationData?.length) {
      markNotificationsAsRead();
    }
  }, [notificationData]);

  return (
    <>
      <StyledContainer>
        {isLoading && firstLoading ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            sx={{ marginTop: '250px' }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Loading />
            </Grid>
          </Grid>
        ) : (
          <>
            {notificationData.length ? (
              <StyledGridContainer
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                gap={2}
                boxShadow={0}
                mt={2}
              >
                {notificationData.map(({ id, ...notificationProps }, i) => {
                  const isLastElement = notificationData.length === i + 1;

                  return (
                    <StyledGridItem unRead={notificationProps?.readDatetime} key={id} {...{ ref: isLastElement ? lastElementRef : undefined }} item xs>
                      <NotificationCard id={id} {...notificationProps} onClick={() => onClickNotification(Object.assign(notificationProps, { id }))} />
                    </StyledGridItem>
                  );
                })}

                {listAll === 'loading' ? (
                  <LoadingContainer>
                    <Loading size={30} />
                  </LoadingContainer>
                ) : null}
              </StyledGridContainer>
            ) : (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                sx={{ minHeight: '70vh' }}
              >
                <EmptyNotification src={EmptyNotificationImage} alt='Empty notification' />
                <Typography variant='h5'>Ainda não há notificações</Typography>
                <Typography variant='body2'>Acesse esta página mais tarde</Typography>
              </Grid>
            )}
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default NotificationCardList;
