import { Grid } from '@mui/material';
import React from 'react';
import { PartnerAddress, PartnerDescription, PartnerTitle } from './style';
import { ReactComponent as MapPinIcon } from '../../../../assets/images/Services/map_pin.svg';
import { formatTextLimit } from '../../../../utils/format';

const PublicServicePartnerCard = ({ name, description, address }) => (
  <Grid
    container
    direction='row'
    justifyContent='flex-start'
    alignItems='flex-start'
    sx={{ width: '100%', padding: '0 16px', cursor: 'pointer;' }}
    wrap='nowrap'
    gap={2}
  >
    <Grid item>
      <Grid
        container
        direction='column'
        gap={0}
      >
        <PartnerTitle>
          {name || ''}
        </PartnerTitle>
        <PartnerDescription>
          {(description && formatTextLimit(description, 80)) || ''}
        </PartnerDescription>

        <Grid
          container
          direction='row'
          alignItems='center'
          wrap='nowrap'
        >
          <MapPinIcon sx={{ marginRight: '10px' }} />
          <PartnerAddress>{(address?.street && formatTextLimit(address.street, 45)) || 'Endereço não informado'}</PartnerAddress>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default PublicServicePartnerCard;
